import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Row, OverlayTrigger, Tooltip} from "react-bootstrap";
import { ArrowLeft, Plus } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import api from "../../../../../service/api";
import AddCourseStudent from "./AddCourseStudent";
import Courses from "./Courses";
import CourseStudents from "./CourseStudents";
import { tableColumns } from "./data";
import { SnackbarAlert } from "../../../../../components/SnackbarAlert";

const ManageStudent = () => {
    const { id } = useParams(); // invoice id

    const [invoice, setInvoice] = useState({});
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [invoiceCourseId, setInvoiceCourseId] = useState("");
    const [addStudent, setAddStudent] = useState(false);
    const [invoiceCourseStudent, setInvoiceCourseStudent] = useState([]);
    const [invoiceCourseDetails, setInvoiceCourseDetails] = useState({});
    const [changeStudentData, setChangeStudentData] = useState();
    const [studentFin, setStudentFin] = useState(null);

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    /** Get Invoice */
    const getInvoice = async (id) => {
        try {
            setLoading(true);
            const response = await api.get(`tms/invoices/${id}`);
            setInvoice(response?.data);
            setLoading(false);
        } catch (error) {
            setError(error?.response?.data?.message);
        }
    };

    /** Get Invoice Course Students */
    const getInvoiceCourseStudent = async (invoiceCourseId, page = {}) => {
        try {
            setLoading(true);
            const responseStudent = await api.get(
                `tms/invoice-courses/${invoiceCourseId}/invoice-course-students`,
                page
            );
            const responseCourse = await api.get(
                `tms/invoice-courses/${invoiceCourseId}`
            );
            setInvoiceCourseDetails(responseCourse?.data);
            setInvoiceCourseStudent(responseStudent);
            setInvoiceCourseId(invoiceCourseId);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    /** change/replace student */
    const changeStudent = async (invoiceCourseId, invoiceCourseStudentId) => {
        try {
            setLoading(true);
            const responseStudent = await api.get(
                `tms/invoice-courses/${invoiceCourseId}/invoice-course-students/${invoiceCourseStudentId}`
            );
            setChangeStudentData(responseStudent.data);
            setAddStudent(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    /**  Use Effect  */
    useEffect(() => {
        getInvoice(id);
    }, [id]);
    return (
        <>
            <Helmet title="Manage students" />
            <Container fluid className="p-0">
                <h3>Manage students</h3>
                <Card>
                    {error && (
                        <Alert className="my-3" variant="danger">
                            <div className="alert-message">{error}</div>
                        </Alert>
                    )}
                    <Card.Body>
                        {loading && <div>Loading...</div>}

                        {!loading && invoiceCourseId === "" && (
                            <>
                                <Row>
                                    <Col md={12}>
                                        <Courses
                                            invoice={invoice}
                                            getInvoiceCourseStudent={
                                                getInvoiceCourseStudent
                                            }
                                            getInvoice={getInvoice}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}

                        {!loading && invoiceCourseId !== "" && (
                            <Row>
                                <Col md={12}>
                                    {!addStudent && (
                                        <>
                                            <div>
                                                <Row>
                                                    <Col md={6}>
                                                        <h4>
                                                            {
                                                                invoiceCourseDetails
                                                                    ?.course
                                                                    ?.name
                                                            }
                                                        </h4>

                                                        {invoice?.department?.toLowerCase() ===
                                                            "attc" && (
                                                            <ul>
                                                                {invoiceCourseDetails?.trade_category &&(
                                                                    <li>
                                                                        Trade:
                                                                        {
                                                                            invoiceCourseDetails?.trade_category
                                                                        }
                                                                    </li>
                                                                )}
                                                                {invoiceCourseDetails?.type_of_trade && (
                                                                    <li>
                                                                        Trade
                                                                        category:
                                                                        {
                                                                            invoiceCourseDetails?.type_of_trade
                                                                        }
                                                                    </li>
                                                                )}
                                                                {invoiceCourseDetails?.application_option && (
                                                                    <li>
                                                                        Application:
                                                                        {
                                                                            invoiceCourseDetails?.application_option
                                                                        }
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        )}
                                                    </Col>
                                                    <Col md={6}>
                                                        {invoiceCourseDetails?.total_student >
                                                            invoiceCourseDetails?.registered_student &&
                                                            invoiceCourseDetails?.allowed_students >
                                                                invoiceCourseDetails?.registered_student && (
                                                                <Button
                                                                    className=" float-end  ms-3  "
                                                                    onClick={() => {
                                                                        setStudentFin(null);
                                                                        setAddStudent(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    <Plus
                                                                        size={
                                                                            15
                                                                        }
                                                                    ></Plus>{" "}
                                                                    Add Student
                                                                </Button>
                                                            )}
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id={`tooltip-top`}>Return</Tooltip>}
                                                            >
                                                            <Button
                                                                variant="info"
                                                                className="float-end  ms-3  "
                                                                onClick={() => {
                                                                    setInvoiceCourseId(
                                                                        ""
                                                                    );
                                                                }}
                                                            >
                                                                <ArrowLeft
                                                                    size={15}
                                                                ></ArrowLeft>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className="float-none">
                                                <hr />
                                                {!loading && (
                                                    <CourseStudents
                                                        columns={tableColumns}
                                                        rawData={
                                                            invoiceCourseStudent
                                                        }
                                                        invoiceCourseId={
                                                            invoiceCourseId
                                                        }
                                                        getInvoiceApi={
                                                            getInvoice
                                                        }
                                                        invoiceId={id}
                                                        getInvoiceCourseStudentApi={
                                                            getInvoiceCourseStudent
                                                        }
                                                        changeStudentApi={
                                                            changeStudent
                                                        }
                                                        setAddStudent={setAddStudent}
                                                        setStudentFin={setStudentFin}
                                                        setNotif={setNotif}
                                                    />
                                                )}
                                            </div>
                                        </>
                                    )}
                                    {addStudent && (
                                        <>
                                            <AddCourseStudent
                                                invoiceCourseId={
                                                    invoiceCourseId
                                                }
                                                setAddStudent={setAddStudent}
                                                getInvoiceApi={getInvoice}
                                                invoiceId={id}
                                                getInvoiceCourseStudentApi={
                                                    getInvoiceCourseStudent
                                                }
                                                changeStudentData={
                                                    changeStudentData
                                                }
                                                setChangeStudentData={
                                                    setChangeStudentData
                                                }
                                                studentFin={studentFin}
                                                setNotif={setNotif}
                                            />
                                        </>
                                    )}
                                </Col>
                            </Row>
                        )}
                    </Card.Body>
                    <SnackbarAlert notif={notif} setNotif={setNotif} />
                </Card>
            </Container>
        </>
    );
};
export default ManageStudent;
