const tableColumns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Actions",
      accessor: "actions"
    }
];

export { tableColumns };
  