import React, { useState, useEffect } from "react";
import { Table, Form, Card, Button } from "react-bootstrap";
import { FileMinus, Trash, Plus, Save, X } from "react-feather";
import api from "../../../../../service/api";
import { SnackbarAlert } from "../../../../../components/SnackbarAlert";
import { useNavigate } from "react-router-dom";
import { Controller } from "react-hook-form";
import { DeleteModal } from "../../../../../components/DeleteModal";
import { AutoComplete } from "antd";
import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";

const CompanyAddressSection = ({
    address,
    addressId,
    permission,
    control,
    setValue,
    selectedCompany,
    getCompanyData,
    adhocInvoice,
}) => {
    const [editedContactId, setEditedContactId] = useState(null);
    const [editedAddressData, setEditedAddressData] = useState(null);
    const [newAddress, setNewAddress] = useState({
        address: "",
    });
    const [modalDelete, setModalDelete] = useState({
        values: [],
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const [isAddingContact, setIsAddingContact] = useState(false); // Track whether adding a new contact
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const handleEditClick = (contactData) => {
        setEditedContactId(contactData.sequence);
        setEditedAddressData(contactData);
    };

    const handleSaveClick = async (companyId) => {
        // console.log(editedAddressData);return;
        try {
            const response = await api.post(
                `tms/company-address-edit/${companyId}`,
                editedAddressData
            );
            if (response.status === "ok") {
                setEditedContactId(null);
                await getCompanyData(selectedCompany);
                setNotif({
                    notifMsg: "Company address updated successfully",
                    open: true,
                    severity: "success",
                });
            } else {
                setNotif({
                    notifMsg: response.message,
                    open: true,
                    severity: "danger",
                });
            }
        } catch (error) {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    const handleCancelClick = () => {
        setEditedContactId(null);
        setEditedAddressData(null);
    };

    const handleNewAddressChange = (field, value) => {
        setNewAddress({
            ...newAddress,
            [field]: value,
        });
    };

    const handleAddNewAddress = async () => {
        try {
            // Assuming company_id is known or stored somewhere
            const companyId = selectedCompany; // Replace with the actual company_id value

            // Append company_id to newContact
            const contactWithCompanyId = {
                ...newAddress,
                company_id: companyId,
            };

            const response = await api.post(
                "tms/company-address",
                contactWithCompanyId
            );
            if (response.status === "ok") {
                await getCompanyData(selectedCompany);
                setNewAddress({
                    address: "",
                });
                setNotif({
                    notifMsg: "New company address added successfully",
                    open: true,
                    severity: "success",
                });
                setIsAddingContact(false); // Hide the input table after adding a contact
                // You can refresh the contact list or perform other actions here
            } else {
                setNotif({
                    notifMsg: response.message,
                    open: true,
                    severity: "danger",
                });
            }
        } catch (error) {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    const toggleAddingAddress = () => {
        // Toggle the state to show/hide the contact input table
        setIsAddingContact(!isAddingContact);
    };
    const openDeleteModal = (id) => {
        setModalDelete({
            id: id,
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            severity: "danger",
        });
    };
    const onDelete = async (contactId) => {
        try {
            // Implement the logic to delete the contact using the contactId
            await api.delete(`tms/companies/delete-contact/${contactId}`, {});
            await getCompanyData(selectedCompany);
            // After successful deletion, you can update the contact list or take other actions as needed
        } catch (error) {
            // Handle errors if the deletion fails
            setNotif({
                notifMsg: "Failed to delete contact",
                open: true,
                severity: "danger",
            });
        }
    };

    // Render the table rows with editable fields for address
    const contactRows = address?.map((data, key) => (
        <tr key={key}>
            <td>
                <Controller
                    name="company_address"
                    control={control}
                    render={({ field }) => (
                        <Form.Check
                            type="radio"
                            label={key + 1}
                            {...field}
                            // defaultChecked={data?.default ?? false} // Check the radio button if is default address
                            onChange={() => {
                                setValue(field.name, data?.sequence);
                                setValue("default_address", data.address);
                            }}
                        />
                    )}
                />
            </td>
            <td>
                {editedContactId === data.sequence ? (
                    <Form.Control
                        // type="text"
                        as="textarea"
                        rows={6}
                        value={editedAddressData?.address || ""}
                        onChange={(e) => {
                            setEditedAddressData({
                                ...editedAddressData,
                                address: e.target.value,
                            });
                        }}
                    />
                ) : (
                    <AutoGrowTextarea
                        fieldValue={data.address}
                        disabled
                        readOnly={true}
                    />
                )}
            </td>

            {permission?.includes("COMPANY-EDIT") && (
                <td>
                    {editedContactId === data.sequence ? (
                        <>
                            <Button
                                variant="success"
                                size="sm"
                                onClick={() => handleSaveClick(addressId)}
                            >
                                <Save size="18" />
                            </Button>
                            <Button
                                variant="light"
                                size="sm"
                                onClick={handleCancelClick}
                            >
                                <X size="18" />
                            </Button>
                        </>
                    ) : (
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={() => handleEditClick(data)}
                        >
                            <FileMinus size="18" />
                        </Button>
                    )}
                    {/* <Button
                        variant="danger"
                        size="sm"
                        onClick={() => openDeleteModal(data?.id)}
                    >
                        <Trash size="18" />
                    </Button> */}
                </td>
            )}
        </tr>
    ));

    return (
        <div>
            <label className="form-label">Company address </label>
            {adhocInvoice && (
                <Controller
                    name="default_address"
                    control={control}
                    defaultValue={adhocInvoice?.company_addr}
                    render={({ field }) => (
                        <Form.Control
                            // type="text"
                            as="textarea"
                            rows={4}
                            {...field}
                            placeholder="Address"
                            disabled
                            // value={defaultData?.address}
                        />
                    )}
                />
            )}
            <br></br>
            {selectedCompany && (
                <Table responsive striped bordered>
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Address</th>
                            {permission?.includes("COMPANY-EDIT") && (
                                <th>Action</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>{contactRows}</tbody>
                </Table>
            )}
            {isAddingContact ? (
                <Table responsive striped bordered>
                    <thead>
                        <tr>
                            <th>Address</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <Form.Control
                                    // type="text"
                                    as="textarea"
                                    placeholder="Address"
                                    value={newAddress.address}
                                    onChange={(e) => {
                                        handleNewAddressChange(
                                            "address",
                                            e.target.value
                                        );
                                    }}
                                />
                            </td>
                            <td>
                                <Button
                                    variant="success"
                                    size="sm"
                                    onClick={handleAddNewAddress}
                                >
                                    <Save size="18" />
                                </Button>
                                <Button
                                    variant="light"
                                    size="sm"
                                    onClick={toggleAddingAddress}
                                >
                                    <X size="18" />
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            ) : (
                selectedCompany && (
                    <Button variant="primary" onClick={toggleAddingAddress}>
                        <Plus /> Add address
                    </Button>
                )
            )}
            <DeleteModal
                modalInfo={modalDelete}
                setModalInfo={setModalDelete}
                api={onDelete}
            />
            {/* SnackbarAlert */}
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </div>
    );
};

export default CompanyAddressSection;
