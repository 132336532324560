import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { faWindowClose, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AutoGrowTextarea } from "../../../components/AutoGrowTextarea";
import { convertToDecimalWithComma } from "../../../utils/utility";

export const AddEditQuotationItems = ({
    values,
    errors,
    touched,
    handleBlur,
    handleFormChangeItems,
    removeFields,
    addFields,
    handleFormChangeDiscount,
}) => {
    return (
        <>
            <Row className="mb-2">
                <Col md={1}>S/N</Col>
                <Col md={2}>Item</Col>
                <Col md={3}>Description</Col>
                <Col md={1}>Quantity</Col>
                <Col md={2}>Rate (S$)</Col>
                <Col md={2}>Amount (S$)</Col>
            </Row>
            <hr style={{ border: "1px solid" }} />
            {values.quotation_items.length ? (
                values.quotation_items.map((input, index) => {
                    return (
                        <Row key={index}>
                            <Col md={1}>{index + 1}</Col>
                            <Col md={2}>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name="item"
                                        id="item"
                                        value={
                                            values.quotation_items[index]?.item
                                        }
                                        isInvalid={Boolean(
                                            errors.quotation_items &&
                                                touched.quotation_items &&
                                                errors.quotation_items[index]
                                                    ?.item &&
                                                touched.quotation_items[index]
                                                    ?.item
                                        )}
                                        onChange={(event) => {
                                            handleFormChangeItems(index, event);
                                        }}
                                    />
                                    {errors.quotation_items &&
                                        errors.quotation_items[index]?.item && (
                                            <Form.Control.Feedback type="invalid">
                                                Item is a required field
                                            </Form.Control.Feedback>
                                        )}
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <AutoGrowTextarea
                                        name="description"
                                        id="description"
                                        fieldValue={
                                            values.quotation_items[index]
                                                ?.description
                                        }
                                        handleBlur={handleBlur}
                                        index={index}
                                        handleFormChangeItems={
                                            handleFormChangeItems
                                        }
                                        isInvalid={Boolean(
                                            errors.quotation_items &&
                                                touched.quotation_items &&
                                                errors.quotation_items[index]
                                                    ?.description &&
                                                touched.quotation_items[index]
                                                    ?.description
                                        )}
                                    />
                                    {errors.quotation_items &&
                                        errors.quotation_items[index]
                                            ?.description && (
                                            <Form.Control.Feedback type="invalid">
                                                Description is a required field
                                            </Form.Control.Feedback>
                                        )}
                                </Form.Group>
                            </Col>
                            <Col md={1}>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="number"
                                        name="quantity"
                                        id="quantity"
                                        min="0"
                                        value={
                                            values.quotation_items[index]
                                                ?.quantity
                                        }
                                        isInvalid={Boolean(
                                            errors.quotation_items &&
                                                touched.quotation_items &&
                                                errors.quotation_items[index]
                                                    ?.quantity &&
                                                touched.quotation_items[index]
                                                    ?.quantity
                                        )}
                                        onChange={(event) => {
                                            handleFormChangeItems(index, event);
                                        }}
                                    />
                                    {errors.quotation_items &&
                                        errors.quotation_items[index]
                                            ?.quantity && (
                                            <Form.Control.Feedback type="invalid">
                                                Quantity is a required field
                                            </Form.Control.Feedback>
                                        )}
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="number"
                                        name="rate"
                                        id="rate"
                                        min="0"
                                        value={
                                            values.quotation_items[index]?.rate
                                        }
                                        isInvalid={Boolean(
                                            errors.quotation_items &&
                                                touched.quotation_items &&
                                                errors.quotation_items[index]
                                                    ?.rate &&
                                                touched.quotation_items[index]
                                                    ?.rate
                                        )}
                                        onChange={(event) => {
                                            handleFormChangeItems(index, event);
                                        }}
                                    />
                                    {errors.quotation_items &&
                                        errors.quotation_items[index]?.rate && (
                                            <Form.Control.Feedback type="invalid">
                                                Rate is a required field
                                            </Form.Control.Feedback>
                                        )}
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                {convertToDecimalWithComma(
                                    values.quotation_items[index]?.amount
                                )}
                            </Col>
                            <Col md={1}>
                                <Button
                                    variant="primary"
                                    onClick={() => removeFields(index)}
                                    className=""
                                >
                                    <FontAwesomeIcon icon={faWindowClose} />
                                </Button>
                            </Col>
                        </Row>
                    );
                })
            ) : (
                <Row index="1234">
                    <Col md={12} className="text-center">
                        Add a new item to create a quotation
                    </Col>
                </Row>
            )}
            <Button variant="secondary" onClick={addFields} className="mt-3">
                <FontAwesomeIcon icon={faPlusSquare} /> Add new item
            </Button>
            <hr />
            <Row className="mt-3">
                <Col md={4}></Col>
                <Col md={4}></Col>
                <Col md={4}>
                    <Row className="mt-3">
                        <Col md={6}>
                            <h4>Subtotal</h4>
                        </Col>
                        <Col md={6}>
                            <h4 className="text-end">
                                S$
                                {convertToDecimalWithComma(values.subtotal)}
                            </h4>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={6}>
                            <h4>GST</h4>
                        </Col>
                        <Col md={6}>
                            <h4 className="text-end">7%</h4>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={6}>
                            <h4>Discount</h4>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Control
                                    type="number"
                                    name="discount"
                                    id="discount"
                                    min="0"
                                    value={values.discount}
                                    onChange={(event) => {
                                        handleFormChangeDiscount(event);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={6}>
                            <h4>Total (SGD)</h4>
                        </Col>
                        <Col md={6}>
                            <h4 className="text-end">
                                S$
                                {convertToDecimalWithComma(values.total)}
                            </h4>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
