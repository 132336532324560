import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Edit, Eye, Trash } from "react-feather";

const ListButton = ({ id, openDeleteModal }) => {
    /** Permissions */
    const { data } = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = data?.permissions;
    /** Permissions */
    const navigate = useNavigate();
    return (
        <>
            {permission?.includes("COMPANY-EDIT") && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Edit company</Tooltip>}
                >
                    <span>
                        <a
                            href={`/tms/companies/${id}`}
                            target="_blank"
                            rel="noreferrer"
                            className="me-1"
                        >
                            <span>
                                <Edit size="20" color="#6c757d" />
                            </span>
                        </a>
                    </span>
                </OverlayTrigger>
            )}
            {permission?.includes("COMPANY-DELETE") && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Delete company</Tooltip>}
                >
                    <span>
                        <Trash
                            style={{ cursor: "pointer" }}
                            className="me-1 text-danger"
                            size="20"
                            onClick={() => openDeleteModal(id)}
                        />
                    </span>
                </OverlayTrigger>
            )}
            {permission?.includes("COMPANY-READ") && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>View company</Tooltip>}
                >
                    <span>
                        <a
                            href={`/tms/companies/view/${id}`}
                            target="_blank"
                            rel="noreferrer"
                            className="me-1"
                        >
                            <span>
                                <Eye size="20" color="#6c757d" />
                            </span>
                        </a>
                    </span>
                </OverlayTrigger>
            )}
        </>
    );
};

export default ListButton;
