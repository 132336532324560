import React from "react";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";

export const FileUpload = ({ files, setFiles, name, className = null }) => {
    return (
        <FilePond
            files={files}
            className={className}
            onupdatefiles={setFiles}
            allowMultiple={true}
            maxFiles={3}
            name={name}
            labelIdle='Drag & drop your files or <span class="filepond--label-action">browse</span>'
        />
    );
};
