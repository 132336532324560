import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import api from "../../../service/api";
import { SnackbarAlert } from "../../../components/SnackbarAlert.js";

export const AddEditProject = () => {
  let { id } = useParams();
  const helmetTitle = `${id === "add" ? "Add" : "Edit"} project`;
  return (
    <React.Fragment>
      <Helmet title={helmetTitle} />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{helmetTitle}</h1>

        <Row>
          <Col lg="12">
            <FormRow />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const FormRow = () => {
  const [editProject, setEditProject] = useState();
  const [initValues, setInitialValues] = useState({
    name: "",
    project_code: "",
    person_in_charge: "",
    contract_number: "",
    details: "",
    project_cost: "",
    submit: false,
  });
  const [notif, setNotif] = useState({
    notifMsg: "",
    open: false,
    severity: "success",
  });

  const navigate = useNavigate();
  let { id } = useParams();

  const schema = Yup.object().shape({
    name: Yup.string().required(),
    project_code: Yup.string().required(),
    person_in_charge: Yup.string().required(),
    contract_number: Yup.string().required(),
    details: Yup.string().required(),
    project_cost: Yup.string().required(),
  });

  const submitAddProject = (values) => {
    return api.post("projects", {
      name: values.name,
      project_code: values.project_code,
      person_in_charge: values.person_in_charge,
      contract_number: values.contract_number,
      details: values.details,
      project_cost: values.project_cost,
    });
  };

  const submitEditProject = (values) => {
    try {
      return api.put(`projects/${id}`, {
        name: values.name,
        project_code: values.project_code,
        person_in_charge: values.person_in_charge,
        contract_number: values.contract_number,
        details: values.details,
        project_cost: values.project_cost,
      });
    } catch {
      setNotif({
        notifMsg: "Something went wrong with the server",
        open: true,
        severity: "danger",
      });
    }
  };

  useEffect(() => {
    if (id !== "add") {
      const getProject = async () => {
        try {
          const response = await api.get(`projects/${id}`, {});

          setEditProject(response.data);
        } catch (error) {
          setNotif({
            notifMsg: "Something went wrong with the server",
            open: true,
            severity: "danger",
          });
        }
      };
      getProject();
    }
  }, [id]);

  useEffect(() => {
    if (editProject) {
      setInitialValues({
        name: editProject.name,
        project_code: editProject.project_code,
        person_in_charge: editProject.person_in_charge,
        contract_number: editProject.contract_number,
        details: editProject.details,
        project_cost: editProject.project_cost,
        submit: false,
      });
    }
  }, [editProject]);

  return (
    <>
      <Card>
        <Card.Body>
          <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={schema}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                id !== "add"
                  ? await submitEditProject(values)
                  : await submitAddProject(values);

                navigate("/intellidocs/project", {
                  state: {
                    open: true,
                    notifMsg: `Successfully ${
                      id === "add" ? "added" : "edited"
                    } project`,
                    severity: "success",
                  },
                });
              } catch (error) {
                const message = error.message || "Something went wrong";

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
                setNotif({
                  notifMsg: "Something went wrong with the server",
                  open: true,
                  severity: "danger",
                });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert className="my-3" variant="danger">
                    <div className="alert-message">{errors.submit}</div>
                  </Alert>
                )}
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={values.name}
                        isInvalid={Boolean(touched.name && errors.name)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.name && (
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Project code</Form.Label>
                      <Form.Control
                        type="text"
                        name="project_code"
                        placeholder="Person code"
                        value={values.project_code}
                        isInvalid={Boolean(
                          touched.project_code && errors.project_code
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.project_code && (
                        <Form.Control.Feedback type="invalid">
                          Project code is a required field
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Person incharge</Form.Label>
                      <Form.Control
                        type="text"
                        name="person_in_charge"
                        placeholder="Person incharge"
                        value={values.person_in_charge}
                        isInvalid={Boolean(
                          touched.person_in_charge && errors.person_in_charge
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.person_in_charge && (
                        <Form.Control.Feedback type="invalid">
                          Person incharge is a required field
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Contract number</Form.Label>
                      <Form.Control
                        type="text"
                        name="contract_number"
                        placeholder="Contract number"
                        value={values.contract_number}
                        isInvalid={Boolean(
                          touched.contract_number && errors.contract_number
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.contract_number && (
                        <Form.Control.Feedback type="invalid">
                          Contact number is a required field
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Details</Form.Label>
                      <Form.Control
                        type="text"
                        name="details"
                        placeholder="Details"
                        value={values.details}
                        isInvalid={Boolean(
                          touched.details && errors.details
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.details && (
                        <Form.Control.Feedback type="invalid">
                          Details is a required field
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Project cost (S$)</Form.Label>
                      <Form.Control
                        type="text"
                        name="project_cost"
                        placeholder="Project cost"
                        value={values.project_cost}
                        isInvalid={Boolean(
                          touched.project_cost && errors.project_cost
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.project_cost && (
                        <Form.Control.Feedback type="invalid">
                          Project cost is a required field
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  variant="secondary"
                  className="me-2"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
      <SnackbarAlert notif={notif} setNotif={setNotif} />
    </>
  );
};
