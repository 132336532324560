import { combineReducers } from "redux";
import companyReducer from "./companyReducer";
import courseReducer from "./courseReducer";
import userReducer from "./userReducer";

const reducers = combineReducers({
  companies: companyReducer,
  courses: courseReducer,
  user: userReducer,
});

export default reducers;
