import { Briefcase, BookOpen, Layers, Users, Key } from "react-feather";

const IntelliDocsItems = [
    {},
    {
        pages: [
            {
                href: "/intellidocs/portfolio",
                title: "Settings",
                children: [
                    {
                        href: "/intellidocs/portfolio",
                        icon: BookOpen,
                        title: "Portfolio",
                    },
                    {
                        href: "/intellidocs/programme",
                        icon: Briefcase,
                        title: "Programme",
                    },
                    {
                        href: "/intellidocs/project",
                        icon: Layers,
                        title: "Project",
                    },
                    {
                        href: "/intellidocs/users",
                        icon: Users,
                        title: "Users",
                    },
                    {
                        href: "/intellidocs/roles",
                        icon: Briefcase,
                        title: "Roles",
                    },
                    {
                        href: "/intellidocs/permissions",
                        icon: Key,
                        title: "Permissions",
                    },
                ],
            },
        ],
    },
];

export default IntelliDocsItems;
