import React, { useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";

import Wrapper from "../../../components/Wrapper";
import Sidebar from "../../../components/sidebar/Sidebar";
import Main from "../../../components/Main";
import Navbar from "../../../components/navbar/NavbarComponent";
import Content from "../../../components/Content";

import { Settings, FolderPlus } from "react-feather";

import { LoadingContext } from "../../../App";

const IntelliBuildLayout = ({ children }) => {
    //
    // States
    //

    const setIsLoadingActive = useContext(LoadingContext);

    const menuItems = [
        {
            pages: [
                {
                    href: "",
                    icon: Settings,
                    title: "Settings",
                    children: [
                        {
                            href: "/intellibuild/projects",
                            icon: FolderPlus,
                            title: "Projects",
                        },
                    ],
                },
            ],
        },
    ];

    //
    // Functions
    //

    //
    // UseEffects
    //

    useEffect(() => {}, [setIsLoadingActive]);

    return (
        <React.Fragment>
            <Wrapper>
                {menuItems && (
                    <>
                        <Sidebar title="IntelliBuild" items={menuItems} />
                        <Main>
                            <Navbar />
                            <Content>
                                {children}
                                <Outlet />
                            </Content>
                        </Main>
                    </>
                )}
            </Wrapper>
        </React.Fragment>
    );
};

export default IntelliBuildLayout;
