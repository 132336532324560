import React, { useState } from "react";
import { Badge, Button, Col, Form, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Filter, Plus, Search } from "react-feather";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import moment from "moment";
import api from "../../../../service/api";
import { addDefaultOption } from "../../../../utils/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import { UserDateFormat } from "../../../../utils/dateFormat";

const SearchFunc = ({ setSearchValue, searchValue, search, excel}) => {
    // const navigate = useNavigate();
    // const [hasMore, setHasMore] = useState(true);
    // const handleLoadMore = async (search, { page }) => {
    //     if (!search) {
    //         return {
    //             options: [],
    //             hasMore: false,
    //         };
    //     }

    //     const response = await api.get("tms/companies-options", {
    //         search: search,
    //         page: page,
    //     });

    //     const { options: newOptions, hasMore: newHasMore } = response;

    //     setHasMore(newHasMore);

    //     return {
    //         options: newOptions,
    //         hasMore: newHasMore,
    //         additional: {
    //             page: page + 1,
    //         },
    //     };
    // };
    const DateFormat = UserDateFormat()?.toUpperCase()
    return (
        <>
            <Row className="g-2 mb-2">
                <Col md={9} xs={12} className="px-3">
                    <Row className="d-flex flex-row">
                        <Col md={5} sm={12} className="p-md-0">
                            <DatePicker
                                id="from-date-picker"
                                name="from_date"
                                className={`form-control`}
                                placeholder="From date"
                                defaultValue={ searchValue?.start_date ? moment(searchValue?.start_date) : null }
                                // defaultValue={moment().startOf('month')}
                                // allowClear={false}
                                onChange={(event) => {
                                    // console.log(moment(event).format("YYYY-MM-DD"));
                                    setSearchValue({
                                        ...searchValue,
                                        start_date: event ? moment(event).format("YYYY-MM-DD") : null,
                                    });
                                }}
                                style={{minHeight: '2.5rem'}}
                                format={DateFormat}
                            />
                            {/* <Form.Control
                                type="month"
                                name="start_date"
                                min={`${minSelectableYear}-01`}
                                max={`${new Date().getFullYear() + 1 }-12`}
                                size="lg"
                                onChange={(event) => {
                                    setSearchValue({
                                        ...searchValue,
                                        start_date: event.target.value,
                                    });
                                }}
                            /> */}
                        </Col>
                        <Col md={2} className="d-flex align-items-center justify-content-center m-0 p-0">
                            <Badge bg='info' className="d-flex align-items-center justify-content-center text-center w-100 h-100 mx-2 mx-md-0">
                                To
                            </Badge>
                        </Col>
                        <Col md={5} className="p-md-0">
                            <DatePicker
                                id="to-date-picker"
                                name="to_date"
                                className={`form-control`}
                                placeholder="To date"
                                // defaultValue={moment().endOf('month')}
                                // allowClear={false}
                                onChange={(event) => {
                                    // console.log(moment(event).format("YYYY-MM-DD"), event);
                                    setSearchValue({
                                        ...searchValue,
                                        end_date: event ? moment(event).format("YYYY-MM-DD") : null,
                                    });
                                }}
                                style={{minHeight: '2.5rem'}}
                                format={DateFormat}
                            />
                            {/* <Form.Control
                                type="month"
                                name="start_date"
                                min={`${minSelectableYear}-01`}
                                max={`${new Date().getFullYear() + 1}-12`}
                                size="lg"
                                onChange={(event) => {
                                    setSearchValue({
                                        ...searchValue,
                                        end_date: event.target.value,
                                    });
                                }}
                            /> */}
                        </Col>
                    </Row>
                </Col>
                <Col md={12} className="d-flex align-items-center justify-content-center justify-content-md-end mt-2">
                    <Button
                        className="float-start me-1"
                        variant="info"
                        type="submit"
                        size="lg"
                        onClick={() => search({ page: 1 })}
                    >
                        <Search className="feather" />
                    </Button>
                    {/* <Button
                        className="float-start me-1"
                        variant="info"
                        type="button"
                        size="lg"
                        onClick={() => excel(searchValue)}
                    >
                        <FontAwesomeIcon
                            icon={faFileExcel}
                        />
                    </Button> */}
                </Col>
            </Row>
        </>
    );
};
export default SearchFunc;
