import * as yup from "yup";

export const schema = yup.object().shape({
    company_id: yup.string().required("Company is required"),
    sales_user_id: yup.object().shape({
        label: yup.string().required("sales person  is required"),
        value: yup.string().required("sales person is required"),
    }),
    location: yup.object().shape({
        label: yup.string().required("location  is required"),
        value: yup.string().required("location  is required"),
    }),
    // items: yup.array().of(
    //     yup.object().shape({
    //         course_code: yup.object().shape({
    //             label: yup.string().required("Course Code is required"),
    //             value: yup.string().required("Course Code is required"),
    //         }),
    //         trade_option: yup.object().shape({
    //             label: yup.string().required("Type of trade is required"),
    //             value: yup.string().required("Type of trade is required"),
    //         }),
    //         application_option: yup.object().shape({
    //             label: yup.string().required("Application option is required"),
    //             value: yup.string().required("Application option is required"),
    //         }),
    //         trade_category: yup.object().shape({
    //             label: yup.string().required("Trade category is required"),
    //             value: yup.string().required("Trade category is required"),
    //         }),
    //         course: yup.object().shape({
    //             label: yup.string().required("Course is required"),
    //             value: yup.string().required("Course is required"),
    //         }),
    //         student_names: yup.string().required("Student name is required"),
    //     })
    // ),
});
