import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Button } from "react-bootstrap";

const Page401 = () => (
  <React.Fragment>
    <Helmet title="401 Error" />
    <div className="text-center my-auto">
      <h1 className="display-1 fw-bold">401</h1>
      <p className="h1">Unauthorized access.</p>
      <Link to={"/tms"}>
        <Button className="mt-5" variant="primary" size="lg">
            Return to Dashboard
        </Button>
      </Link>
      
    </div>  
  </React.Fragment>
);

export default Page401;
