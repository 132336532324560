import React, { useState } from "react";
import { Button, OverlayTrigger } from "react-bootstrap";
import { Trash } from "react-feather";
import { DeleteModal } from "../../../../../components/DeleteModal";
import { SnackbarAlert } from "../../../../../components/SnackbarAlert";
import api from "../../../../../service/api";

const PaymentHistory = ({ payments, getPayments, getInvoice, invoiceId }) => {
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const openDeleteModal = (id) => {
        setModalInfo({
            id: id,
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            severity: "danger",
        });
    };
    /** delete payment  */
    const deletePayment = async (paymentId) => {
        try {
            await api.delete(`tms/invoices/${invoiceId}/payments/${paymentId}`);
            getPayments(invoiceId);
            getInvoice(invoiceId);
            setNotif({
                id: invoiceId,
                notifMsg: "Deleted successfully",
                open: true,
                severity: "success",
            });
        } catch (error) {
            setNotif({
                id: invoiceId,
                notifMsg: error.message,
                open: true,
                severity: "danger",
            });
        }
    };
    return (
        <>
            {payments?.length > 0 && (
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Ref no</th>
                            <th>Payment Method</th>
                            <th>Supporting Documents</th>
                            <th>Remarks</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments?.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item?.amount}</td>
                                <td>{item?.created_at}</td>
                                <td>{item?.reference_num}</td>
                                <td>{item?.payment_method}</td>
                                {/* <td>{item?.attachments[0]?.file_name}</td> */}
                                <td>
                                    {item?.supporting_documents.map((data) => {
                                        return (
                                            <>
                                            <a
                                                href={
                                                    data?.link
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {data?.file_name}
                                            </a><br/>
                                            </>
                                        )
                                    })}
                                </td>
                                <td>{item?.remarks}</td>
                                <td>
                                    {item?.type == 'manual' && item?.invoice?.status?.toUpperCase() != "CANCELLED" && (<Button
                                        variant="danger"
                                        className="me-1 mb-1"
                                        onClick={() => openDeleteModal(item.id)}
                                    >
                                        <Trash className="feather" />
                                    </Button>)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            {payments?.length === 0 && (
                <div className="text-danger">No payment yet</div>
            )}

            <DeleteModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={deletePayment}
            />
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export default PaymentHistory;
