// import { LOADING, SUCCEED, FAILED, CLEAR } from "../action-types";
const userReducer = (state = [], { type, payload }) => {
    switch (type) {
        case "USER_LOADING":
            return { loading: true, error: null, data: [] };

        case "USER_SUCCEED":
            return { loading: false, error: null, data: payload };

        case "USER_FAILED":
            return { loading: false, error: payload, data: [] };

        case "USER_CLEAR":
            return { loading: false, error: null, data: [] };
        default:
            return state;
    }
};
export default userReducer;
