import { Badge } from "react-bootstrap";

const tableColumns = [
    {
        Header: "S.no",
    },
    {
        Header: "Name",
        accessor: "name",
    },
    {
        Header: "FIN",
        accessor: "fin",
    },
    {
        Header: "Sector",
        accessor: "sector",
    },
    {
        Header: "Work permit number",
        accessor: "work_permit_number",
    },
    {
        Header: "Expire date",
        accessor: "work_permit_expire_date",
    },
    {
        Header: "Company",
        accessor: "company.name",
    },
    {
        Header: "Sponsor company",
        id: "sponsor_company",
        accessor: (data) => {
            return (
                <div>
                    {data?.self_registration && (
                        <Badge bg="primary">SELF</Badge>
                    )}
                    {!data?.self_registration && (
                        <div>{data?.sponsor_company?.name}</div>
                    )}
                </div>
            );
        },
    },
    {
        Header: "Course",
        id: "course_information",
        accessor: (data) => {
            return (
                <div>
                    {data?.type_of_trade?.toUpperCase() === "CET" && "CET"}
                    {data?.type_of_trade?.toUpperCase() !== "CET" && (
                        <div>{data?.course?.name}</div>
                    )}
                </div>
            );
        },
    },
    {
        Header: "Trade category",
        accessor: "trade_category",
    },
    {
        Header: "Type of trade",
        accessor: "type_of_trade",
    },
    {
        Header: "Application option",
        accessor: "application_option",
    },
    {
        Header: "Application date",
        accessor: "date",
    },
    {
        Header: "Invoice number",
        accessor: "invoice_no",
    },
    {
        Header: "Payment mode",
        accessor: "payment_mode",
    },

    {
        Header: "Status",
        accessor: "status",
    },
    {
        Header: "Actions",
        accessor: "actions",
    },
];

export { tableColumns };
