import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import BatchDetails from "../CommonComponents/BatchDetails";
import ResultBatchStudent from "./ResultBatchStudent";
import api from "../../../service/api";
import { tableColumns } from "./data";
import { ArrowLeft } from "react-feather";
const Result = () => {
    /** Get path from summary page  */
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const path = urlParams.get("path");

    let redirectPath = "/tms/batches/waiting-for-result";
    if (path) {
        redirectPath = `/tms/batches/${path}`;
    }
    /** Get path from summary page  */

    let { id } = useParams();
    const navigate = useNavigate();

    const [classStudents, setClassStudents] = useState([]);

    const [batch, setBatch] = useState({});
    const [loading, setLoading] = useState(false);

    const resultStudentApi = useCallback(async () => {
        setLoading(true);
        const response = await api.get(`tms/batch-students/get-batch-students/${id}`, {});
        setClassStudents(response.data);
        setLoading(false);
    }, [id]);
    const getBatch = async () => {
        const response = await api.get(`tms/batches/${id}`, {});
        setBatch(response.data);
    };

    useEffect(() => {
        setLoading(true);
        resultStudentApi();
        getBatch();
        setLoading(false);
    }, []); //  eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <Helmet title="Class result" />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h1 className="h3 mb-3">Class - Result </h1>
                    </Col>
                    <Col md={6} className="text-end">
                        <Button
                            className="m-2"
                            onClick={() => navigate(redirectPath)}
                        >
                            <ArrowLeft className="feather" />
                        </Button>
                    </Col>
                </Row>
                {loading && <div>Loading...</div>}
                {!loading &&
                    Object.keys(batch).length &&
                    batch?.id !== "" &&
                    classStudents.length > 0 && (
                        <Row>
                            <Col md="4" xl="3">
                                <BatchDetails
                                    batch={batch}
                                    type="result"
                                    getBatchStudentsApi={resultStudentApi}
                                    getBatchApi={getBatch}
                                />
                            </Col>
                            <Col md="8" xl="9">
                                <ResultBatchStudent
                                    data={classStudents}
                                    columns={tableColumns}
                                    batch={batch}
                                    getBatchStudentsApi={resultStudentApi}
                                    isSummaryPage={path} // summary page
                                />
                            </Col>
                        </Row>
                    )}
                {classStudents.length === 0 && (
                    <Alert className="my-3" variant="warning">
                        <div className="alert-message">
                            No students under this batch/class
                        </div>
                    </Alert>
                )}
            </Container>
        </React.Fragment>
    );
};

export default Result;
