import { Alert } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";

import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import api from "../../../../../service/api";
import FormRow from "./FormRow";
const AddEditAdditional = () => {
    let { id } = useParams();
    let { action } = useParams();

    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [AdditionalInvoice, setAdditionalInvoice] = useState();
    const [AdditionalMainInvoice, setAdditionalMainInvoice] = useState();
    const [courseFee, setCourseFee] = useState();
    const [invoiceStudent, setInvoiceStudent] = useState();
    const [mainInvoiceStudent, setMainInvoiceStudent] = useState();
    const [defaultStudentsData, setDefaultStudentsData] = useState();
    const [configValues, setConfigValues] = useState({
        tradeOptions: [],
        tradeCategory: [],
        tradeApplication: [],
        atoCourses: [],
        attcCourses: [],
        companies: [],
        departments: [],
        location: [],
        all_adhoc_additional: [],
    });

    const setCourseData = (data) => {
        const formatAto = data
            .filter((data, index) => {
                return data?.type?.toUpperCase() === "ATO";
            })
            .map((data, index) => {
                return { value: data.id, label: data.name };
            });
        const formatAttc = data
            .filter((data, index) => {
                return data?.type?.toUpperCase() === "ATTC";
            })
            .map((data, index) => {
                return { value: data.id, label: data.name };
            });
        const { atoCourses, attcCourses } = { ...configValues };
        atoCourses.push(...formatAto);
        attcCourses.push(...formatAttc);
        setConfigValues({ atoCourses, attcCourses });
    };

    const setCompanyData = (data) => {
        const formatData = data.map((data, index) => {
            return { value: data.id, label: data.name };
        });
        const { companies } = { ...configValues };
        companies.push(...formatData);
        setConfigValues(companies);
    };

    /** use effect */
    useEffect(() => {
        const getConfig = async () => {

            try {
                setLoading(true);
                const configResponse = await api.get(
                    `tms/sales-quotations/config`,
                    {}
                );
                const courseResponse = await api.get(`tms/courses?all=1`, {});
                // const adhocResponse = await api.get("tms/invoice/get-ad-hoc-additional-invoice-item");
                const adhocResponse = await api.post("tms/invoice/get-ad-hoc-invoice-item");
                const courseFeeResponse = await api.get("tms/invoice/get-add-invoice-item");

                setCourseData(courseResponse?.data);
                setCourseFee(courseFeeResponse.data);
                // setConfigValues({ ...configValues, ...configResponse.data });
                setConfigValues({ ...configValues, ...configResponse.data, ...adhocResponse.data });

                setLoading(false);
            } catch (error) {
                setError(error?.message);
            }
        };

        if (id && id !== "add" && action && action !== "add") {
            // Editing Additional
            const getQuotation = async () => {
                try {
                    let additional_invoice_id = action;
                    let main_invoice_students = id;

                    setLoading(true);
                    const response = await api.get(
                        `tms/invoices/${additional_invoice_id}`,
                        {}
                    );

                    const response_students = await api.get(
                        `tms/invoice-students/${additional_invoice_id}`
                    );
                    const response_main_invoice_students = await api.get(
                        `tms/invoice-students/${main_invoice_students}`
                    );
                   
                    let default_students_data = response_students.data.map((data, index) => {
                        return { 
                            enrolment_id: data.id, 
                            name: data?.student?.name, 
                            fin: data?.student?.fin, 
                            invoice_course_id: data?.invoice_course_id,
                             data: data };
                    })

                    

                    setDefaultStudentsData(default_students_data);
                    setInvoiceStudent(response_students?.data);
                    setMainInvoiceStudent(response_main_invoice_students?.data)
                    setAdditionalInvoice(response.data);
                    setAdditionalMainInvoice(response.data);
                    setLoading(false);
                } catch (error) { }
            };

            getQuotation();
        }
        if (id && id !== "add" && action && action === "add") {
            // Creating Additional
            const getQuotation = async () => {
                try {
                    setLoading(true);
                    let main_invoice_students = id;
                    const response = await api.get(
                        `tms/invoices/${main_invoice_students}`,
                        {}
                    );
                    const response_students = await api.get(
                        `tms/invoice-students/${main_invoice_students}`
                    );
                    const response_main_invoice_students = await api.get(
                        `tms/invoice-students/${main_invoice_students}`
                    );
                    console.log(response_students.data)

                    setInvoiceStudent(response_students.data);
                    setMainInvoiceStudent(response_main_invoice_students?.data)
                    setAdditionalMainInvoice(response.data);
                    setLoading(false);
                } catch (error) { }
            };

            getQuotation();
        }
        getConfig();
    }, [id]); //  eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <Helmet title="Sales > Additional invoice" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">
                    {action !== "add" ? "Edit" : "Create"} additional invoice ({AdditionalInvoice?.number ? AdditionalInvoice?.number : AdditionalMainInvoice?.number})
                </h1>
                <Card className="m-0 p-0">
                    <Card.Body>
                        {error && (
                            <Alert className="my-3" variant="danger">
                                <div className="alert-message">{error}</div>
                            </Alert>
                        )}

                        {loading && <div>Loading...</div>}
                        {!loading && (
                            <FormRow
                                configValues={configValues}
                                AdditionalInvoice={AdditionalInvoice}
                                AdditionalMainInvoice={AdditionalMainInvoice}
                                courseFee={courseFee}
                                setConfigValues={setConfigValues}
                                invoiceStudent={invoiceStudent}
                                mainInvoiceStudent={mainInvoiceStudent}
                                defaultStudentsData={defaultStudentsData}
                                
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

export default AddEditAdditional;
