import api from "../../service/api";
import { LOADING, SUCCEED, FAILED } from "./../action-types";

const getCompanyAll = (search) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
    });
    try {
      const response = await api.get("tms/companies", {
        ...search,
      });
      dispatch({
        type: SUCCEED,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: FAILED,
        payload: error,
      });
    }
  };
};

const getCompany = (id) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
    });
    try {
      const response = await api.get(`tms/companies/${id}`, {});

      dispatch({
        type: SUCCEED,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FAILED,
        payload: error,
      });
    }
  };
};

export { getCompanyAll, getCompany };
