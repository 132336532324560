import React from "react";

import { Card, Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Controller } from "react-hook-form";

import { STATUS } from "../../../config";

import "react-datepicker/dist/react-datepicker.css";

export const AddEditBasic = ({
    control,
    dateRange,
    errors,
    register,
    setDateRange,
}) => {
    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title tag="h5" className="mb-0">
                        Basic
                    </Card.Title>
                </Card.Header>
                <Card.Body className="pt-0">
                    <Row>
                        <Col md="12">
                            <Row>
                                <Col md="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="projectCode">
                                            Project code *
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="projectCode"
                                            isInvalid={Boolean(
                                                errors?.projectCode
                                            )}
                                            {...register("projectCode")}
                                        />
                                        {errors?.projectCode && (
                                            <Form.Control.Feedback type="invalid">
                                                Project code is a required field
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="projectName">
                                            Project name *
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="projectName"
                                            isInvalid={Boolean(
                                                errors?.projectName
                                            )}
                                            {...register("projectName")}
                                        />
                                        {errors?.projectName && (
                                            <Form.Control.Feedback type="invalid">
                                                Project name is a required field
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="claimName">
                                            Claim name
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="claimName"
                                            {...register("claimName")}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="status">
                                            Status *
                                        </Form.Label>
                                        <Form.Select
                                            name="status"
                                            id="status"
                                            isInvalid={Boolean(errors?.status)}
                                            {...register("status")}
                                        >
                                            <option></option>
                                            {STATUS.map((data, index) => {
                                                return (
                                                    <option
                                                        key={index}
                                                        value={data}
                                                    >
                                                        {data}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                        {errors?.status && (
                                            <Form.Control.Feedback type="invalid">
                                                Status name is a required field
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="duration">
                                            Duration *
                                        </Form.Label>
                                        <Controller
                                            name="duration"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({
                                                field: { onChange },
                                            }) => (
                                                <DatePicker
                                                    selectsRange={true}
                                                    startDate={dateRange[0]}
                                                    endDate={dateRange[1]}
                                                    className={`w-100 form-control ${
                                                        errors?.duration &&
                                                        "is-invalid"
                                                    }`}
                                                    onChange={(dates) => {
                                                        console.log(dates);
                                                        // Set the values of the date picker
                                                        setDateRange(dates);

                                                        // Set the date values of the form
                                                        onChange(dates);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors?.duration && (
                                            <div className="invalid-feedback d-block">
                                                Duration name is a required
                                                field
                                            </div>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="cctvLink">
                                            CCTV link
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="cctvLink"
                                            {...register("cctvLink")}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <small>* Required fields</small>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};
