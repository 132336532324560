import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { faWindowClose, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AutoGrowTextarea } from "../../../components/AutoGrowTextarea";
import { FileUpload } from "../../../components/FileUpload";
import { convertToDecimalWithComma } from "../../../utils/utility";

export const AddEditExpenseItems = ({
    values,
    errors,
    touched,
    handleBlur,
    handleFormChangeItems,
    addFields,
    removeFields,
    files,
    setFiles,
    expenseTypes,
    deleteUploadedFiles,
}) => {
    //
    // Functions
    //

    const updateFormState = (key, value) => {
        setFiles((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    return (
        <>
            <Row className="mb-2">
                <Col md={1} style={{ width: "5.333%" }}>
                    S/N
                </Col>
                <Col md={3}>Expense type</Col>
                <Col md={3}>Description</Col>
                <Col md={2}>Expense item date</Col>
                <Col md={2}>Amount (S$)</Col>
            </Row>
            <hr style={{ border: "1px solid" }} />
            {values?.expense_items.length ? (
                values.expense_items.map((input, index, row) => {
                    return (
                        <React.Fragment key={index}>
                            <Row>
                                <Col md={1} style={{ width: "5.333%" }}>
                                    {index + 1}
                                </Col>
                                <Col md={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Select
                                            name="expense_type"
                                            id="expense_type"
                                            value={
                                                values.expense_items[index]
                                                    ?.expense_type
                                            }
                                            isInvalid={Boolean(
                                                errors.expense_items &&
                                                    touched.expense_items &&
                                                    errors.expense_items[index]
                                                        ?.expense_type &&
                                                    touched.expense_items[index]
                                                        ?.expense_type
                                            )}
                                            onChange={(event) => {
                                                handleFormChangeItems(
                                                    index,
                                                    event
                                                );
                                            }}
                                        >
                                            <option value="default"></option>
                                            {expenseTypes &&
                                                expenseTypes.map(
                                                    (data, index) => {
                                                        return (
                                                            <option
                                                                key={index}
                                                                value={
                                                                    data.value
                                                                }
                                                            >
                                                                {data.label}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                        </Form.Select>
                                        {errors.expense_items &&
                                            errors.expense_items[index]
                                                ?.expense_type && (
                                                <Form.Control.Feedback type="invalid">
                                                    Expense type is a required
                                                    field
                                                </Form.Control.Feedback>
                                            )}
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group className="mb-3">
                                        <AutoGrowTextarea
                                            name="description"
                                            id="description"
                                            fieldValue={
                                                values.expense_items[index]
                                                    ?.description
                                            }
                                            handleBlur={handleBlur}
                                            index={index}
                                            handleFormChangeItems={
                                                handleFormChangeItems
                                            }
                                            isInvalid={Boolean(
                                                errors.expense_items &&
                                                    touched.expense_items &&
                                                    errors.expense_items[index]
                                                        ?.description &&
                                                    touched.expense_items[index]
                                                        ?.description
                                            )}
                                        />
                                        {errors.expense_items &&
                                            errors.expense_items[index]
                                                ?.description && (
                                                <Form.Control.Feedback type="invalid">
                                                    Description is a required
                                                    field
                                                </Form.Control.Feedback>
                                            )}
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            type="date"
                                            name="expense_item_date"
                                            id="expense_item_date"
                                            value={
                                                values.expense_items[index]
                                                    ?.expense_item_date
                                            }
                                            onBlur={handleBlur}
                                            index={index}
                                            isInvalid={Boolean(
                                                errors.expense_items &&
                                                    touched.expense_items &&
                                                    errors.expense_items[index]
                                                        ?.expense_item_date &&
                                                    touched.expense_items[index]
                                                        ?.expense_item_date
                                            )}
                                            onChange={(event) => {
                                                handleFormChangeItems(
                                                    index,
                                                    event
                                                );
                                            }}
                                        />
                                        {errors.expense_items &&
                                            errors.expense_items[index]
                                                ?.expense_item_date && (
                                                <Form.Control.Feedback type="invalid">
                                                    Expense item date is a
                                                    required field
                                                </Form.Control.Feedback>
                                            )}
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            type="number"
                                            name="amount"
                                            id="amount"
                                            min="0"
                                            value={
                                                values.expense_items[index]
                                                    ?.amount
                                            }
                                            isInvalid={Boolean(
                                                errors.expense_items &&
                                                    touched.expense_items &&
                                                    errors.expense_items[index]
                                                        ?.amount &&
                                                    touched.expense_items[index]
                                                        ?.amount
                                            )}
                                            onChange={(event) => {
                                                handleFormChangeItems(
                                                    index,
                                                    event
                                                );
                                            }}
                                        />
                                        {errors.expense_items &&
                                            errors.expense_items[index]
                                                ?.amount && (
                                                <Form.Control.Feedback type="invalid">
                                                    Amount is a required field
                                                </Form.Control.Feedback>
                                            )}
                                    </Form.Group>
                                </Col>
                                <Col md={1}>
                                    <Button
                                        variant="primary"
                                        onClick={() => removeFields(index)}
                                        className=""
                                    >
                                        <FontAwesomeIcon icon={faWindowClose} />
                                    </Button>
                                </Col>
                                <Col md={12}>
                                    <FileUpload
                                        name={index}
                                        files={files[index]}
                                        setFiles={(fileItems) =>
                                            updateFormState(
                                                index,
                                                fileItems.map(
                                                    (fileItem) => fileItem.file
                                                )
                                            )
                                        }
                                    />
                                    {values.expense_items[index]?.attachments &&
                                        values.expense_items[
                                            index
                                        ]?.attachments.map(
                                            (element, fileIndex) => {
                                                return (
                                                    <React.Fragment
                                                        key={fileIndex}
                                                    >
                                                        {fileIndex === 0 && (
                                                            <>
                                                                Uploaded: <br />
                                                            </>
                                                        )}
                                                        <Button
                                                            href={
                                                                element.full_path
                                                            }
                                                            target="_blank"
                                                            variant="link"
                                                            className="m-0 px-0"
                                                        >
                                                            {element.file_name}
                                                        </Button>
                                                        <Button
                                                            variant="primary"
                                                            onClick={() =>
                                                                deleteUploadedFiles(
                                                                    index,
                                                                    element.id,
                                                                    values
                                                                        .expense_items[
                                                                        index
                                                                    ]
                                                                        ?.attachments,
                                                                    element.full_path
                                                                )
                                                            }
                                                            className="ms-2"
                                                            size="sm"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faWindowClose
                                                                }
                                                            />
                                                        </Button>
                                                        <br />
                                                    </React.Fragment>
                                                );
                                            }
                                        )}
                                </Col>
                            </Row>
                            {index + 1 !== row.length && <hr />}
                        </React.Fragment>
                    );
                })
            ) : (
                <Row index="1234">
                    <Col md={12} className="text-center">
                        Add a new item to create an expense
                    </Col>
                </Row>
            )}
            <Button variant="secondary" onClick={addFields} className="mt-3">
                <FontAwesomeIcon icon={faPlusSquare} /> Add new item
            </Button>
            <hr />
            <Row className="mt-3">
                <Col md={4}></Col>
                <Col md={4}></Col>
                <Col md={4}>
                    <Row className="mt-3">
                        <Col md={6}>
                            <h4>Total (SGD)</h4>
                        </Col>
                        <Col md={6}>
                            <h4 className="text-end">
                                S$
                                {convertToDecimalWithComma(values.total)}
                            </h4>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
