import React, { useState, useEffect } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import api from "../../../service/api";
import { AddEditDeliveryOrderDetails } from "./AddEditDeliveryOrderDetails";
import { AddEditDeliveryOrderItems } from "./AddEditDeliveryOrderItems";

export const AddEditDeliveryOrder = ({ project }) => {
    const navigate = useNavigate();
    let { id, action, actiondo } = useParams();

    const schema = Yup.object().shape({
        order_date: Yup.string().required(),
        order_to: Yup.string().required(),
        total: Yup.string().required(),
        delivery_order_items: Yup.array().of(
            Yup.object().shape({
                item: Yup.string().required(),
                description: Yup.string().required(),
                quantity: Yup.string().required(),
                rate: Yup.string().required(),
                amount: Yup.string().required(),
            })
        ),
    });

    //
    // States
    //

    const [deliveryOrders, setDeliveryOrders] = useState();
    // eslint-disable-next-line no-unused-vars
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [inputFields, setInputFields] = useState({
        order_date: "",
        order_to: "fonda",
        gst: "7",
        subtotal: 0,
        total: 0,
        delivery_order_items: [
            {
                item: "",
                description: "",
                quantity: "",
                rate: "",
                amount: 0,
            },
        ],
    });

    //
    // Functions
    //

    const addFields = () => {
        let newfield = {
            item: "",
            description: "",
            quantity: "",
            rate: "",
            amount: 0,
        };

        setInputFields({
            ...inputFields,
            delivery_order_items: [
                ...inputFields.delivery_order_items,
                newfield,
            ],
        });
    };

    const removeFields = (index) => {
        let data = [...inputFields.delivery_order_items];
        data.splice(index, 1);

        calculateRemovedFields(data);

        setInputFields({
            ...inputFields,
            delivery_order_items: data,
        });
    };

    const handleFormChangeItems = (index, event) => {
        let data = [...inputFields.delivery_order_items];
        data[index][event.target.name] = event.target.value;

        calculateTotalAmount(index, event);

        setInputFields({
            ...inputFields,
            delivery_order_items: data,
        });
    };

    const handleFormChangeDetails = (event) => {
        setInputFields({
            ...inputFields,
            order_date: event.target.value,
        });
    };

    const calculateTotalAmount = (index, event = null) => {
        let data = [...inputFields.delivery_order_items];
        data[index][event.target.name] = event.target.value;

        const itemTotalAmount =
            inputFields["delivery_order_items"][index]["quantity"] *
            inputFields["delivery_order_items"][index]["rate"];

        inputFields["delivery_order_items"][index]["amount"] = itemTotalAmount;

        let quotationItemsTotal = data.reduce(
            (total, current) => +total + +current.amount,
            0
        );

        const finalTotal = computeFinalTotal(quotationItemsTotal);
        inputFields["subtotal"] = quotationItemsTotal;
        inputFields["total"] = finalTotal > 0 ? finalTotal : 0;
    };

    const calculateRemovedFields = (data) => {
        let quotationItemsTotal = data.reduce(
            (total, current) => +total + +current.amount,
            0
        );

        const finalTotal = computeFinalTotal(quotationItemsTotal);

        inputFields["total"] = finalTotal > 0 ? finalTotal : 0;
    };

    const computeFinalTotal = (total) => {
        const gstRate = 7;

        return (gstRate / 100) * total + total;
    };

    const submitCreateDeliveryOrder = (values) => {
        return api.post("delivery-orders", values);
    };

    const submitEditDeliveryOrder = (values) => {
        try {
            return api.put(`delivery-orders/${actiondo}`, values);
        } catch {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    //
    // useEffects
    //

    useEffect(() => {
        if (actiondo !== undefined && actiondo === "create") {
            const getQuotation = async () => {
                try {
                    const response = await api.get(`quotations/${action}`, {});
                    setDeliveryOrders(response.data);
                } catch (error) {
                    setNotif({
                        notifMsg: "Something went wrong with the server",
                        open: true,
                        severity: "danger",
                    });
                }
            };
            getQuotation();
        } else {
            const getDevlieryOrders = async () => {
                try {
                    const response = await api.get(
                        `delivery-orders/${actiondo}`,
                        {}
                    );
                    setDeliveryOrders(response.data);
                } catch (error) {
                    setNotif({
                        notifMsg: "Something went wrong with the server",
                        open: true,
                        severity: "danger",
                    });
                }
            };
            getDevlieryOrders();
        }
    }, [action, actiondo]);

    useEffect(() => {
        if (deliveryOrders) {
            setInputFields({
                order_date: deliveryOrders.order_date || "",
                order_to:
                    deliveryOrders.quotation_to || deliveryOrders.order_to,
                gst: deliveryOrders.gst,
                subtotal: deliveryOrders.subtotal,
                total: deliveryOrders.total,
                delivery_order_items:
                    deliveryOrders.quotation_items ||
                    deliveryOrders.delivery_order_items,
            });
        }
    }, [deliveryOrders]);

    return (
        <>
            <Card className="shadow-none mb-0">
                <Card.Header className="shadow-none pb-0">
                    <Card.Title tag="h5">
                        {actiondo === "create" ? "Create" : "Edit"} delivery
                        order
                    </Card.Title>
                </Card.Header>
                <Card.Body className="pb-0">
                    <Formik
                        enableReinitialize
                        initialValues={inputFields}
                        validationSchema={schema}
                        onSubmit={async (
                            values,
                            { setErrors, setStatus, setSubmitting }
                        ) => {
                            try {
                                actiondo === "create"
                                    ? await submitCreateDeliveryOrder(values)
                                    : await submitEditDeliveryOrder(values);

                                navigate(
                                    `/intellidocs/process/quotations/${id}/${action}/do`,
                                    {
                                        state: {
                                            open: true,
                                            notifMsg: `Successfully ${
                                                action === "create"
                                                    ? "created"
                                                    : "updated"
                                            } quotation`,
                                            severity: "success",
                                        },
                                    }
                                );
                            } catch (error) {
                                const message =
                                    error.message || "Something went wrong";

                                setStatus({ success: false });
                                setErrors({ submit: message });
                                setSubmitting(false);
                                setNotif({
                                    notifMsg:
                                        "Something went wrong with the server",
                                    open: true,
                                    severity: "danger",
                                });
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            touched,
                            values,
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                {errors.submit && (
                                    <Alert className="my-3" variant="danger">
                                        <div className="alert-message">
                                            {errors.submit}
                                        </div>
                                    </Alert>
                                )}
                                <h6>Order items</h6>
                                <div className="bg-light p-4 rounded mb-3">
                                    <Row className="mb-3">
                                        <Col md={3}>
                                            <Form.Label>
                                                Delivery date
                                            </Form.Label>{" "}
                                            <br />
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    type="date"
                                                    name="order_date"
                                                    id="order_date"
                                                    value={
                                                        values.order_date || ""
                                                    }
                                                    isInvalid={Boolean(
                                                        errors &&
                                                            touched &&
                                                            errors.order_date &&
                                                            touched.order_date
                                                    )}
                                                    onBlur={handleBlur}
                                                    onChange={
                                                        handleFormChangeDetails
                                                    }
                                                />
                                                {errors &&
                                                    errors?.order_date && (
                                                        <Form.Control.Feedback type="invalid">
                                                            Order date is a
                                                            required field
                                                        </Form.Control.Feedback>
                                                    )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <AddEditDeliveryOrderItems
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        handleBlur={handleBlur}
                                        handleFormChangeItems={
                                            handleFormChangeItems
                                        }
                                        removeFields={removeFields}
                                        addFields={addFields}
                                    />
                                </div>
                                <h6>Order details</h6>
                                <div className="bg-light p-4 rounded">
                                    <AddEditDeliveryOrderDetails
                                        project={project}
                                    />
                                </div>
                                <Card.Footer className="text-center pb-0">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className="me-2"
                                        size="lg"
                                    >
                                        <FontAwesomeIcon icon={faPaperPlane} />{" "}
                                        Save
                                    </Button>
                                    <Button
                                        variant="link"
                                        onClick={() =>
                                            navigate(
                                                `/intellidocs/process/quotations/${id}/${action}/do`
                                            )
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </Card.Footer>
                            </Form>
                        )}
                    </Formik>
                </Card.Body>
            </Card>
        </>
    );
};
