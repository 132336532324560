import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import PendingStudentList from "../PendingStudentList";
import { tableColumns } from "../PendingStudentData";
import { SnackbarAlert } from "../../../../components/SnackbarAlert";
import api from "../../../../service/api";

const AddStudentToClass = () => {
    let { id } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [pendingStudents, setPendingStudent] = useState([]);
    const [rerender, setRerender] = useState([]);
    const [batchLists, setBatchLists] = useState([]);
    const [pendingSearch, setPendingSearch] = useState({
        name: "",
        date: "",
        fin: "",
    });
    const [batch, setBatch] = useState([]);
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const getBatchDetail = async () => {
        try {
            setLoading(true);
            const response = await api.get(`tms/batches/getbatch/${id}`, {});
            setBatch(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const getPendingStudents = async () => {
        try {
            setLoading(true);
            const responseData = await api.get(
                // "tms/enrolments?all=1&status=enrolled",
                `tms/enrolments?all=1&status=enrolled&sort_by_application_date=1&course_id=${
                    batch?.course_id ?? ""
                }&trade_category=${batch?.trade_category ?? ""}`,
                {
                    ...pendingSearch,
                    course_id: batch?.course_id ?? "",
                    trade_category: batch?.trade_category ?? "",
                }
            );
            setPendingStudent(responseData.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const getBatchList = async () => {
        try {
            const responseData = await api.get(
                "tms/batches?all=1&except_completed=1",
                {
                    ...pendingSearch,
                }
            );
            setBatchLists(responseData.data);
        } catch (error) {}
    };

    useEffect(() => {
        getBatchList();
    }, []); //  eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Object.keys(batch).length) {
            getPendingStudents();
        }
    }, [batch, rerender]);

    useEffect(() => {
        getBatchDetail();
    }, [rerender]);

    return (
        <React.Fragment>
            <Helmet title="Pending class allocation" />
            <Container fluid className="p-0">
                <Row>
                    <Col md="10">
                        <h1 className="h3 mb-3">
                            Pending class allocation student list
                        </h1>
                    </Col>
                    <Col md="2">
                        <Button
                            variant="info"
                            className="my-2 float-end"
                            size="lg"
                            onClick={() => navigate(`/tms/batches/pending`)}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                    </Col>
                </Row>
                <Card>
                    {loading && <div>Loading...</div>}
                    {!loading && (
                        <PendingStudentList
                            columns={tableColumns}
                            data={pendingStudents}
                            batchLists={batchLists}
                            getPendingStudentsApi={getPendingStudents}
                            setPendingSearch={setPendingSearch}
                            getBatchDetail={getBatchDetail}
                            pendingSearch={pendingSearch}
                            batch={batch}
                            setRerender={setRerender}
                            rerender={rerender}
                            setNotif={setNotif}
                        />
                    )}
                </Card>
            </Container>

            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </React.Fragment>
    );
};

export default AddStudentToClass;
