import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { addDefaultOption } from "../../../utils/utility";

const AddEditCourseDetails = ({
    configValues,
    setFieldValue,
    editEnrolment,
    values,
    errors,
    isDisabled,
}) => {
    return (
        <>
            <div className="card-title h5 mt-3 mb-3" tag="h5">
                Course details
            </div>

            <Row>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Trade Category</Form.Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={addDefaultOption(
                                configValues?.tradeCategory
                            )}
                            name="trade_category"
                            onChange={(value) =>
                                setFieldValue("trade_category", value.value)
                            }
                            value={
                                editEnrolment?.trade_category &&
                                configValues?.tradeCategory?.filter(
                                    (option) => {
                                        return (
                                            option.label ===
                                            editEnrolment?.trade_category
                                        );
                                    }
                                )
                            }
                            isDisabled={isDisabled}
                        />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Type of trade</Form.Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={addDefaultOption(
                                configValues?.tradeOptions
                            )}
                            name="type_of_trade"
                            onChange={(value) =>
                                setFieldValue("type_of_trade", value.value)
                            }
                            value={
                                editEnrolment?.type_of_trade &&
                                configValues?.tradeOptions?.filter(
                                    (option) => {
                                        return (
                                            option.label ===
                                            editEnrolment?.type_of_trade
                                        );
                                    }
                                )
                            }
                            isDisabled={isDisabled}
                        />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Application Option </Form.Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={addDefaultOption(
                                configValues?.tradeApplication
                            )}
                            name="application_option"
                            //onBlur={() => setFieldTouched("application_option", true)}
                            onChange={(value) => {
                                setFieldValue(
                                    "application_option",
                                    value.value
                                );
                                setFieldValue(
                                    "application_option_original",
                                    value.value
                                );
                            }}
                            value={
                                values?.application_option_original &&
                                configValues?.tradeApplication?.filter(
                                    (option) => {
                                        return (
                                            option?.value ===
                                            values?.application_option_original
                                        );
                                    }
                                )
                            }
                            isDisabled={isDisabled}
                        />
                    </Form.Group>
                </Col>
            </Row>
            {!(values.type_of_trade === "CET") && (
                <Row>
                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Course</Form.Label>
                            <Select
                                className="is-invalid react-select-container"
                                classNamePrefix="react-select"
                                options={addDefaultOption(
                                    values?.type?.toLowerCase() === "ato"
                                        ? configValues?.atoCourses
                                        : configValues?.attcCourses
                                )}
                                name="course_id"
                                onChange={(value) =>
                                    setFieldValue("course_id", value.value)
                                }
                                defaultValue={
                                    editEnrolment?.course?.name && [
                                        {
                                            label: editEnrolment?.course?.name,
                                            value: editEnrolment?.course?.id,
                                        },
                                    ]
                                }
                                isDisabled={isDisabled}
                            />
                            {!!errors.course_id && (
                                <Form.Control.Feedback type="invalid">
                                    Course is required
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default AddEditCourseDetails;
