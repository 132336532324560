// setInitialValues.js

import { commonBeforeGSTAmount } from "../../../../../utils/utility";

export const setInitialValues = (
    AdditionalInvoice,
    configValues,
    setValue,
    setSelectedCompany,
    additionalCourses,
    AdditionalMainInvoice
) => {

    const companyOption = {
        label: AdditionalMainInvoice?.company?.name,
        value: AdditionalMainInvoice?.company?.id,
    };

    setSelectedCompany(AdditionalMainInvoice?.company?.id);

    setValue("company_id", companyOption.value, { shouldValidate: false });
    const locationOption = configValues?.location?.find(
        (option) => option.value === AdditionalMainInvoice?.location
    );
    if (locationOption) {
        setValue("location", locationOption);
    }
    setValue("sales_user_id", {
        label: AdditionalMainInvoice?.sales_user?.name,
        value: AdditionalMainInvoice?.sales_user?.id,
    });
    // console.log(adhocInvoice.adhoc_type)
    setValue("update_address", AdditionalInvoice?.update_address);
    setValue("address", AdditionalInvoice?.company_addr);

    const companyContactId = AdditionalInvoice?.company_contact_id;
    if (companyContactId) {
        setValue("company_contact_person", companyContactId);
    }

    // Initialize an empty array to store the initial items
    const initialItems = AdditionalInvoice?.invoice_courses.map((item) => {

        const applicationOptionConfig = configValues?.tradeApplication?.find(
            (option) => option.value === item?.application_option
        );

        const tradeCategoryOptionConfig = configValues?.tradeCategory?.find(
            (option) => option.value === item?.trade_category
        );

        const courseCodeOptionConfig = additionalCourses?.adhoc?.find(
            (option) => option.value === item?.addtnl_invoice_item_code
        );
        console.log(additionalCourses, item.addtnl_invoice_item_code);
        const tradeOptionConfig = configValues?.tradeOptions?.find(
            (option) => option.value === item?.type_of_trade
        );

        return {
            id: item?.id,
            invoice_course_id: item.id,
            total_student: item?.total_student,
            price:
                AdditionalInvoice?.adhoc_type !== "no_gst"
                    ? commonBeforeGSTAmount(
                        parseFloat(item?.price),
                        AdditionalInvoice?.gst
                    )
                    : parseFloat(item?.price),
            original_price: parseFloat(item?.price),
            item_type: "additional",
            trade_option: tradeOptionConfig || null,
            course_code: courseCodeOptionConfig || null,
            application_option: applicationOptionConfig || null,
            trade_category: tradeCategoryOptionConfig || null,
            course: {
                label: item?.course?.name || "",
                value: item?.course?.id || "",
            },
            // students: item?.invoice_course_students,
            additional_invoice_students: item?.invoice_course_students,

        };
    });

    setValue("items", initialItems); // Use setValue to set initial values
    setValue("no_gst", AdditionalInvoice?.no_gst);
};
