const tableColumns = [
    {
        Header: "S.no",
        width: "60",
        sticky: "left",
    },
    {
        Header: "Actions",
        width: "200",
        sticky: "left",
        accessor: "actions",
    },
    {
        Header: "Invoice No",
        accessor: "number",
        sticky: "left",
        width: "150",
        getProps: () => {
            return {
                style: {
                    whiteSpace: 'nowrap!important',
                },
            };
        },
    },
    {
        Header: "Invoice Type",
        accessor: "invoice_type",
        sticky: "left",
        width: "120",
    },
    {
        Header: "Company",
        width: "200",
        accessor: "company.name",
        sticky: "left",
    },
    // {
    //     Header: "Main Invoice",
    //     width: "120",
    //     accessor: (data) => {
    //         return <>{data?.main_invoice?.number}</>;
    //     },
    // },
    {
        Header: "Course",
        width: "300",
        id: "",
        accessor: (data) => {
          return (
            <div>
                <ul className="list-unstyled">
                    
                    {
                        data?.invoice_courses.map((course, index) => {
                          return <>
                           <li style={{ listStyleType: "none" }}>{course?.course?.name}</li>
                          </>
                          
                        })
                    }
                    
                </ul>
                
            </div>
          );
        },
    },
    // {
    //     Header: "Item codes & trade category",
    //     width: "500",
    //     id: "",
    //     accessor: (data) => {
    //         let value = data.invoice_courses;
    //         const itemCodes = value
    //                 ? value
    //                     .filter(
    //                         (item) =>
    //                         item.addtnl_invoice_item_code ||
    //                         (item.course_fee && item.course_fee.course_code)
    //                     )
    //                     .reduce((accumulator, item) => {
    //                         if (item) {
    //                             const key = item.addtnl_invoice_item_code
    //                                 ? item.addtnl_invoice_item_code + (item.trade_category ? " - " + item.trade_category : "" )
    //                                 : item.course_fee.course_code + (item.trade_category ? " - " + item.trade_category : "" );

    //                             if (accumulator.hasOwnProperty(key)) {
    //                                 accumulator[key] += 1;
    //                             } else {
    //                                 accumulator[key] = 1;
    //                             }
    //                         }

    //                         return accumulator;
    //                     }, {})
    //                 : "";
                
    //         const result = Object.entries(itemCodes).map(([key, count]) => {
    //             return `${count} x ${key}`;
    //         });

    //         return (
    //             <td className="list-unstyled">
    //                 {result.map((item) => (
    //                     <div>{item}</div>
    //                 ))}
    //             </td>
    //         );
    //     },
    // },
    // {
    //     Header: "Item codes",
    //     width: "150",
    //     accessor: "invoice_courses",
    // },
    // {
    //     Header: "Trade category",
    //     width: "450",
    //     id: "",
    //     accessor: (data) => {
    //       return (
    //         <div>
    //             {
                    
    //             }
    //             <ul className="list-unstyled">
                    
    //                 {
    //                     data?.invoice_courses.map((course, index) => {
    //                       return <>
    //                        <li style={{ listStyleType: "none" }}>{course?.trade_category}</li>
    //                       </>
                          
    //                     })
    //                 }
                    
    //             </ul>
                
    //         </div>
    //       );
    //     },
    // },
    {
        Header: "Invoice Amount",
        accessor: "total_amount",
    },
    {
        Header: "Total paid",
        id: "total_paid",
        accessor: "total_paid_amount",
    },

    {
        Header: "Remaining amount",
        width: "160",
        accessor: "remaining_amount",
    },
    // {
    //     Header: "Cut of date",
    //     accessor: "cut_of_date",
    // },
    {
        Header: "Status",
        width: "175",
        accessor: (data) => {
            return <><p style={{color:(data?.status.toLowerCase() === 'overpaid') ? 'red' : ''}}>{data?.status}</p></>
        }
    },
    {
        Header: "Enrolment status",
        width: "155",
        accessor: (data) => {
            return <><p>{data?.sync_enrolment ? "Enrolled" : "Not enrolled"}</p></>
        }
    },
    {
        Header: "Overpaid",
        width: "100",
        accessor: (data) => {
            return <>{ data?.overpaid ? "Yes" : "No" }</>
        },
    },
    {
        Header: "Created at",
        width: "165",
        accessor: "date",
    },
    {
        Header: "Sales person",
        accessor: "sales_user.name",
      },
      {
        Header: "Created by",
        accessor: "created_by.name",
      },
    
];

export { tableColumns };
