import React, { useEffect, useState, useCallback } from "react";
import { Container, Card, Col, Form, Row, Button, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { SnackbarAlert } from "../../../components/SnackbarAlert.js";
import api from "../../../service/api";

export const AddEditApplication = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const add_page = id === 'add' ? true : false
    const schema = Yup.object().shape({
        name         : Yup.string().required('This field is required'),
    });

    // States
    const [application, setApplication] = useState([]);
    const [notif, setNotif] = useState({
        notifMsg  : "",
        open      : false,
        severity  : "success",
    });
    const [initValues, setInitialValues] = useState({
        app_id: '',
        name: '',
        description: ''
    });

    // Functions
    const getApplication = useCallback(async () => {
        const response = await api.get('apps/'+id, {});
        setApplication(response.data);
    }, [id]);

    // UseEffects
    useEffect(() => {
        if (!add_page) getApplication();
    }, [add_page, getApplication])

    useEffect(() => {
        if (application) {
            setInitialValues({
                name        : application.name,
            })
        }
    },[application])

    const submitForm = (values, actions) => {
        let subscribe = add_page
                        ? api.post('apps', values)
                        : api.put('apps/' + id, values)
        
        subscribe.then(response => {
            if (!response.success) {
                setNotif({
                    notifMsg: response.message,
                    open: true,
                    severity: "danger",
                });
            }
            if (response.status === 'ok') {
                setNotif({
                    notifMsg: 'Successfully saved',
                    open: true,
                    severity: "success",
                });

                if (add_page) {
                    navigate("/applications")
                }
            }
        })
        .catch(error => {
            actions.setSubmitting(false)
            setNotif({
                notifMsg: error.message,
                open: true,
                severity: "danger",
            });
        })
    }

    return (
        <>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">{ add_page ? 'Add application' : 'Edit application'}</h1>
                <Card>
                    <Card.Body>
                        <Formik
                            enableReinitialize
                            initialValues={initValues}
                            validationSchema={schema}
                            onSubmit={(values, actions) => submitForm(values, actions)}
                        >
                            {
                                ({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        {errors.submit && (
                                        <Alert className="my-3" variant="danger">
                                            <div className="alert-message">{errors.submit}</div>
                                        </Alert>
                                        )}

                                        <Row>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        value={values.name}
                                                        isInvalid={Boolean(touched.name && errors.name)}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                    />
                                                    {!!touched.name && (
                                                        <Form.Control.Feedback type="invalid">
                                                        {errors.name}
                                                        </Form.Control.Feedback>
                                                    )}
                                                   
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={{ span: 3, offset: 9 }} className="text-end">
                                                <Button
                                                    variant="secondary"
                                                    className="me-2"
                                                    onClick={() => navigate(-1)}
                                                    >
                                                    Back
                                                </Button>
                                                <Button variant="primary" type="submit">
                                                    Submit
                                                </Button>
                                            </Col>
                                        </Row> 
                                    </Form>    
                                )
                            }
                              
                        </Formik>   
                    </Card.Body>
                </Card>
                <SnackbarAlert notif={notif} setNotif={setNotif} />
            </Container>
        </>
    )
}