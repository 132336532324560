import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../../../service/api";
import { Card, Container, Alert, Row, Col, Button } from "react-bootstrap";
import { Lists } from "./List";
import { tableColumns } from "./data.js";
import { SnackbarAlert } from "../../../../components/SnackbarAlert";
import Search from "./Search";
import { Plus } from "react-feather";
import { useSelector } from "react-redux";
const Quotation = () => {
    /** Permissions */
    const permissionData = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = permissionData?.data?.permissions;
    /** Permissions */

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [companies, setCompanies] = useState([]);
    const [error, setError] = useState();
    const [salesPersons, setSalesPersons] = useState();
    const location = useLocation();
    const state = location.state;
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [searchValue, setSearchValue] = useState({
        number: "",
        company: "",
        status: "",
		start_date: "",
        end_date: "",
        salesperson: "",
    });
    const search = (page) => {
        const searchParam = { ...searchValue, ...page };
        // const searchParam = { ...page };
        getQuotations(searchParam);
    };

    const getQuotations = useCallback(async (search) => {
        try {
            setLoading(true);
            const response = await api.get("tms/sales-quotations", search);
            setData(response);
            setError();
        } catch (error) {
            if(error.response.status === 401)
                navigate('/tms/401')
            else{
                setLoading(false);
                setError(error.message);
                setData([]);
            }
        }
        setLoading(false);
    }, []);

    const getCompany = useCallback(async () => {
        try {
            setLoading(true);
            const response = await api.get("tms/companies?all=1");
            setCompanyData(response.data);
            setError();
        } catch (error) {
            setLoading(false);
            setError(error.message);
            setData([]);
        }
        setLoading(false);
    }, []);

    const setCompanyData = (data) => {
        const formatData = data.map((data, index) => {
            return { value: data.id, label: data.name };
        });
        setCompanies(formatData);
    };

    //
    // useEffects
    //
    useEffect(() => {
        /**
         * set page number
         */
        let pageNo = "";
        if (state?.page) {
            pageNo = state?.page;
        }
        getQuotations({ page: pageNo });
        // getCompany("");
    }, []); //  eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state?.open) {
            setNotif({
                notifMsg: state.notifMsg,
                open: state.open,
                severity: state.severity,
            });
        }
        window.history.replaceState(null, "");
    }, [state]); //  eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const getSalesPerson = async () => {
            try {
                const companyResponse = await api.get(`tms/get-sales-persons`);
                setSalesPersons(companyResponse);
            } catch (error) {}
        };
        getSalesPerson();
    }, []);
    

    return (
        <React.Fragment>
            <Helmet title="Sales > Quotation" />

            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Sales quotations</h1>
                {error && (
                    <Card.Header>
                        <Alert className="my-3" variant="danger">
                            <div className="alert-message">{error}</div>
                        </Alert>
                    </Card.Header>
                )}
                <Card>
                    <Card.Body className="mt-0">
                        <Row className={`mb-${permission?.includes("QUOTATION-CREATE") ? 2 : 4}`}>
                            <Col>
                                <Search
                                    setSearchValue={setSearchValue}
                                    searchValue={searchValue}
                                    companies={companies}
                                    search={search}
                                    salesPersons ={salesPersons}
                                />
                            </Col>
                        </Row>
                        {permission?.includes("QUOTATION-CREATE") && (
                            <Row className="mb-2 d-flex justify-content-end">
                                <Col md={2}>
                                    <Button
                                        className="float-end"
                                        onClick={() =>
                                            navigate("/tms/quotations/add")
                                        }
                                        variant="success"
                                        type="button"
                                        size="lg"
                                    >
                                        <Plus size={15} />
                                        Add
                                    </Button>
                                </Col>
                            </Row>
                        )}

                        {loading && <div>Loading...</div>}
                        {!loading && data?.data && (
                            <Lists
                                columns={tableColumns}
                                rawData={data}
                                quotationApi={search}
                                setLoading={setLoading}
                                setError={setError}
                                setData={setData}
                                setNotif={setNotif}
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </React.Fragment>
    );
};

export default Quotation;
