import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";

import { Card, Container, Alert, Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import { Lists } from "./List";

import { tableColumns } from "./data.js";

import Search from "./Search";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import download from "downloadjs";
import api from "../../../../../service/api";
import { SnackbarAlert } from "../../../../../components/SnackbarAlert";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { Plus } from "react-feather";

const Invoice = ({ type, title }) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [companies, setCompanies] = useState([]);
    const [error, setError] = useState();
    const [salesPersons, setSalesPersons] = useState();
    const location = useLocation();
    const state = location.state;
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [searchValue, setSearchValue] = useState({
        number: "",
        company: "",
        status: "",
		// month: "",
        invoice_type: "",
        start_date: "",
        end_date: "",
        salesperson: "",
        enrollment_status: "",
        requested_director_approval: "",
        signed_director_approval: "",
        other_invoice: ""
    });
    const search = (page) => {
        const searchParam = { ...searchValue, ...page };
        // const searchParam = { ...page };
        getQuotations(searchParam);
    };

    const getQuotations = useCallback(async (search) => {
        try {
            setLoading(true);
            const response = await api.get("tms/invoices", search);
            setData(response);
            setError();
        } catch (error) {
            setLoading(false);
            setError(error.message);
            setData([]);
        }
        setLoading(false);
    }, []);

    const getCompany = useCallback(async () => {
        try {
            setLoading(true);
            const response = await api.get("tms/companies?all=1");
            setCompanyData(response.data);
            setError();
        } catch (error) {
            setLoading(false);
            setError(error.message);
            setData([]);
        }
        setLoading(false);
    }, []);

    const setCompanyData = (data) => {
        const formatData = data.map((data, index) => {
            return { value: data.id, label: data.name };
        });

        setCompanies(formatData);
    };

    const generateInvoice = async (sapFormat = false, version = 1) => {
        try {
            setLoading(true);

            let excelFilename = `sales-` + (getSapPrefix(sapFormat)) + `invoices-${new Date()
                .toISOString()
                .slice(0, 10)}`;
            
            const sapPrefix = getSapPrefix(sapFormat);
            const suffix = sapFormat ? `${sapPrefix}excel-invoices/${version}` : `${sapPrefix}excel-invoices`;
            
            const response = await api.getpdf(
                `tms/export-${suffix}`,
                searchValue
            );

            if (response.size !== 0) {
                excelFilename = sapFormat ? excelFilename+'-version-'+version : excelFilename
                download(response, excelFilename, "application/pdf");
            } else {
                setError("No data to be exported");
            }
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
        setLoading(false);
    };

    const getSapPrefix = (isSAPFormat) => {
        return isSAPFormat ? 'sap-' : '';
    };

    const getSalesPerson = async () => {
        try {
            const companyResponse = await api.get(`tms/get-sales-persons`);
            setSalesPersons(companyResponse);
        } catch (error) {}
    };

    //
    // useEffects
    //
    useEffect(() => {
        /**
         * set page number
         */
        let pageNo = "";
        if (state?.page) {
            pageNo = state?.page;
        }
        getQuotations({ page: pageNo });
        getSalesPerson();
        // getCompany("");
    }, []); //  eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state?.open) {
            setNotif({
                notifMsg: state.notifMsg,
                open: state.open,
                severity: state.severity,
            });
        }
        window.history.replaceState(null, "");
    }, [state]); //  eslint-disable-line react-hooks/exhaustive-deps

    let helmentTitle = (title?.toUpperCase() === 'INVOICE SUMMARY') ? "Reports > Invoice summary" : "Sales > Invoice";

    return (
        <React.Fragment>
            <Helmet title={helmentTitle} />

            <Container fluid className="p-0">
                <h1 className="h3 mb-3">{title}</h1>
                {error && (
                    <Card.Header>
                        <Alert className="my-3" variant="danger">
                            <div className="alert-message">{error}</div>
                        </Alert>
                    </Card.Header>
                )}
                <Card>
                    <Card.Body className="mt-0">
                        <Row className="">
                            <Search
                                setSearchValue={setSearchValue}
                                searchValue={searchValue}
                                companies={companies}
                                search={search}
                                type={type}
                                generateInvoice={generateInvoice}
                                generateInvoice2={generateInvoice}
                                salesPersons={salesPersons}
                            />
                        </Row>
                        {title?.toUpperCase() !== 'INVOICE SUMMARY' && (
                        <Row className="mb-2 mx-2 d-flex justify-content-end">   
                            <Col className="col-auto d-flex justify-content-end p-0">
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id={`tooltip-top`}>Ad-hoc invoice</Tooltip>}
                            >
                                <Button
                                    className="float-end mt-1 mx-1"
                                    onClick={() =>
                                        navigate("/tms/ad-hoc/add")
                                    }
                                    variant="success"
                                    type="button"
                                    size="lg"
                                >
                                    <Plus size={15} />
                                    Ad-hoc invoice
                                </Button>
                            </OverlayTrigger>
                            </Col>
                        </Row>
                        )}
                        {loading && <div>Loading...</div>}
                        {!loading && data?.data && (
                            <Lists
                                columns={tableColumns}
                                rawData={data}
                                invoiceApi={search}
                                type={type}
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </React.Fragment>
    );
};

export default Invoice;
