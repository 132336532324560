import React, { useEffect, useState } from "react";

import { Badge, Col, Form, Row, Table } from "react-bootstrap";
import { addDefaultOption } from "../../../utils/utility";
import { AsyncPaginate } from "react-select-async-paginate";
import Select from "react-select";
import api from "../../../service/api";

const AddEditCompanyDetails = ({
    setFieldValue,
    values,
    configValues,
    errors,
    isHidden,
}) => {
    //
    // States
    //
    // console.log(values)
    const [rerender, setRerender] = useState(false);
    const [selectedCompanyRaw, setSelectedCompanyRaw] = useState([]);

    const [hasMoreCompany, setHasMoreCompany] = useState(true);
    const [hasMoreSponsorCompany, setHasMoreSponsorCompany] = useState(true);

    const [sponsorCompany, setSponsorCompany] = useState();
    const [tempSponsorCompany, setTempSponsorCompany] = useState();
    const [workerCompany, setWorkerCompany] = useState();
    const [hasSponsor, setHasSponsor] = useState(false);
    const [isEditable, setIsEditable] = useState(false);

    //
    // Functions
    //

    const handleLoadMoreCompany = async (search, { page }) => {
        if (!search) {
            return {
                options: [],
                hasMore: false,
            };
        }

        setRerender(false);

        const response = await api.get("tms/companies-options", {
            search: search,
            page: page,
        });

        const { options: newOptions, hasMore: newHasMore } = response;

        setHasMoreCompany(newHasMore);

        return {
            options: newOptions,
            hasMore: newHasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    const handleLoadMoreSponsorCompany = async (search, { page }) => {
        if (!search) {
            return {
                options: [selectedCompanyRaw],
                hasMore: false,
            };
        }

        const response = await api.get("tms/companies-options", {
            search: search,
            page: page,
        });

        const { options: newOptions, hasMore: newHasMore } = response;

        setHasMoreSponsorCompany(newHasMore);

        return {
            options: newOptions,
            hasMore: newHasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    //
    // UseEffects
    //
    const getSponsorCompany = async (companyParam = null) => {
        const response = await api.get(
            `tms/companies/${companyParam ?? values.sponsor_company_id}`,
            {}
        );
        setSponsorCompany(response.data);
        
    };
    useEffect(() => {
        //reverse logic for displaying fieldbox for sponsor_company
        setHasSponsor(values?.self_registration ? false : true)
        setIsEditable(values?.sponsor_company_id ? false : true)
    }, []);

    useEffect(() => {
        if (values?.sponsor_company_id) {
            setTempSponsorCompany(values?.sponsor_company_id);
            getSponsorCompany(tempSponsorCompany);
        } else {
            setSponsorCompany({})
        }
    }, [values.sponsor_company_id]);

    useEffect(() => {
        if (tempSponsorCompany && values?.registration_type?.toLocaleLowerCase() === 'company') {
            getSponsorCompany(tempSponsorCompany);
        }
    }, [values.registration_type])

    useEffect(() => {
        const getWorkerCompany = async () => {
            const response = await api.get(
                `tms/companies/${values.company_id}`,
                {}
            );

            setWorkerCompany(response.data);
        };

        if (values.company_id) {
            getWorkerCompany();
        } else {
            getWorkerCompany([]);
        }
    }, [values.company_id]);

    return (
        <>
            <div className="card-title h5 mt-3 mb-3" tag="h5">
                Sponsor company
            </div>
            <Row className="mb-3">
                {/* <Col md={3}>
                    <Form.Group className="mb-3">
                        <Form.Label>Type</Form.Label>
                        <Select
                            isClearable
                            className="is-invalid react-select-container"
                            classNamePrefix="react-select "
                            options={addDefaultOption(
                                configValues?.departments
                            )}
                            name="type"
                            onChange={(value) => {
                                setFieldValue("type", value.value);
                            }}
                            isSearchable="true"
                            defaultValue={
                                values?.type && [
                                    {
                                        label: values.type?.toUpperCase(),
                                        value: values.type,
                                    },
                                ]
                            }
                        />

                        {!!errors.type && (
                            <Form.Control.Feedback type="invalid">
                                {errors.type}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col> */}
                {!isHidden && (
                    <>
                <Col md={3}>
                    <Form.Group className="mb-3">
                        <Form.Label>Company</Form.Label>

                        <AsyncPaginate
                            isClearable
                            name="company_id"
                            loadOptions={handleLoadMoreCompany}
                            hasMore={hasMoreCompany}
                            additional={{
                                page: 1,
                            }}
                            onChange={(value) => {
                                setFieldValue(
                                    "company_id",
                                    value ? value?.value : ""
                                );
                                setSelectedCompanyRaw(value);
                                setRerender(true);
                            }}
                            debounceTimeout={1000}
                        />

                        {!!errors?.company_id && (
                            <Form.Control.Feedback type="invalid">
                                Company is required
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group className="mb-3">
                        <Form.Label>Registration Type</Form.Label>
                        <Select
                            isClearable
                            className="is-invalid react-select-container"
                            classNamePrefix="react-select"
                            options={addDefaultOption(
                                configValues?.registrationOption
                            )}
                            name="registration_type"
                            onChange={(value) => {
                                setFieldValue(
                                    "registration_type",
                                    value ? value?.value : ""
                                );

                                if (
                                    !value ||
                                    value?.value?.toLocaleLowerCase() === "self"
                                ) {
                                    setTempSponsorCompany(sponsorCompany)
                                    setFieldValue("sponsor_company_id", "");
                                }
                            }}
                            defaultValue={
                                values?.registration_type && [
                                    {
                                        label: values?.registration_type,
                                        value: values?.registration_type,
                                    },
                                ]
                            }
                        />
                        {!!errors?.registration_type && (
                            <Form.Control.Feedback type="invalid">
                                Registration type is required
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
                    </>
                )}

                {values?.registration_type?.toLocaleLowerCase() === "company" &&
                    rerender && (
                        <Col md={3}>
                            <Form.Group className="mb-3">
                                <Form.Label>Sponsor company</Form.Label>

                                <AsyncPaginate
                                    isClearable
                                    name="sponsor_company_id"
                                    loadOptions={handleLoadMoreSponsorCompany}
                                    hasMore={hasMoreSponsorCompany}
                                    additional={{
                                        page: 1,
                                    }}
                                    onChange={(value) => {
                                        setFieldValue(
                                            "sponsor_company_id",
                                            value ? value?.value : ""
                                        );
                                    }}
                                    debounceTimeout={1000}
                                />
                            </Form.Group>
                        </Col>
                    )}
            </Row>

            {Boolean(sponsorCompany) && isHidden && (
                <Row>
                    {!isEditable && hasSponsor && (
                        <Col className="mb-2 align-self-center m-0">
                            <h5 className="m-0">
                                {sponsorCompany?.name}{" "}
                                <Badge>{sponsorCompany.uen}</Badge>
                            </h5>
                        </Col>
                    )}
                    {/* {console.log("hasSponsor: ", hasSponsor, " isEditable: ", isEditable,"sponsor_company_id: ",values?.sponsor_company_id)} */}
                    {hasSponsor && isEditable && (
                        <Col className="mb-2">
                            <Form.Group className="mb-3">
                                <Form.Label>Sponsor company</Form.Label>

                                <AsyncPaginate
                                    isClearable
                                    name="sponsor_company_id"
                                    loadOptions={handleLoadMoreSponsorCompany}
                                    hasMore={hasMoreSponsorCompany}
                                    additional={{
                                        page: 1,
                                    }}
                                    onChange={(value) => {
                                        setFieldValue(
                                            "sponsor_company_id",
                                            value ? value?.value : ""
                                        );
                                    }}
                                    debounceTimeout={1000}
                                />
                            </Form.Group>
                        </Col>
                    )}
                    
                    <Col className="mb-2">
                        <Form.Group className="mb-3">
                            <Form.Label>Registration Type</Form.Label>
                            <Select
                                isClearable
                                className="is-invalid react-select-container"
                                classNamePrefix="react-select"
                                options={configValues?.registrationOption}
                                name="registration_type"
                                onChange={(value) => {
                                    setFieldValue(
                                        "registration_type",
                                        value?.value ?? null
                                    );
                                    setHasSponsor(true)
                                    if (
                                        !value ||
                                        value?.value?.toLocaleLowerCase() === "self"
                                    ) {
                                        setFieldValue("sponsor_company_id", "");
                                        setHasSponsor(false)
                                    }
                                    if(!value ||
                                        value?.value?.toLocaleLowerCase() === "company"){
                                        setFieldValue("sponsor_company_id", tempSponsorCompany)
                                    }
                                }}
                                defaultValue={
                                    values?.self_registration ?
                                        {
                                            label: "Self",
                                            value: "self",
                                        }
                                    :
                                        {
                                            label: "Company",
                                            value: "company",
                                        }
                                }
                            />
                            {/* {!!errors?.registration_type && (
                                <Form.Control.Feedback type="invalid">
                                    Registration type is required
                                </Form.Control.Feedback>
                            )} */}
                        </Form.Group>
                    </Col>
                    <Col className="mb-2">
                        <Form.Group className="mb-3">
                            <Form.Label>Worker company</Form.Label>

                            <AsyncPaginate
                                isClearable
                                name="company_id"
                                loadOptions={handleLoadMoreCompany}
                                hasMore={hasMoreCompany}
                                additional={{
                                    page: 1,
                                }}
                                value={
                                    {
                                        label: workerCompany?.name,
                                        value: workerCompany?.id
                                    }
                                }
                                onChange={(value) => {
                                    setFieldValue(
                                        "company_id",
                                        value ? value?.value : ""
                                    );
                                }}
                                debounceTimeout={1000}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <label className="form-label">Contact persons</label>
                        <Table responsive striped bordered>
                            <thead>
                                <tr>
                                    <th>S/N</th>
                                    <th>Name</th>
                                    <th>Contact number</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sponsorCompany?.contacts?.length ? (
                                    sponsorCompany?.contacts?.map(
                                        (data, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{key + 1}</td>
                                                    <td>{data?.name}</td>
                                                    <td>
                                                        {data?.contact_number}
                                                    </td>
                                                    <td>{data?.email}</td>
                                                </tr>
                                            );
                                        }
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan={4}>No contacts</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default AddEditCompanyDetails;
