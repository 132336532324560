import api from "../../service/api";
import { LOADING, SUCCEED, FAILED, CLEAR } from "../action-types";

const getAllCourse = (search) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
    });
    try {
      const response = await api.get("tms/courses", {
        ...search,
      });
      dispatch({
        type: SUCCEED,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: FAILED,
        payload: error,
      });
    }
  };
};

const getCourse = (id) => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR,
    });
    dispatch({
      type: LOADING,
    });
    try {
      const response = await api.get(`tms/courses/${id}`, {});

      dispatch({
        type: SUCCEED,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FAILED,
        payload: error,
      });
    }
  };
};

export { getAllCourse, getCourse };
