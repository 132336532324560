import React, { useState, useContext, useCallback, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";
import { tableColumns } from "./tableDataRequisition.js";
import { ActionRouteContext } from "../Documents/Documents";
import { AddEditRequisition } from "./AddEditRequisition.js";
import { ViewRequisition } from "./ViewRequisition.js";
import api from "../../../service/api";
import { RequisitionListing } from "./RequisitionListing.js";
import { LoadingContext } from "../../../App.js";
import { useParams } from "react-router-dom";

export const Requisition = ({ project }) => {
    let { id } = useParams();

    //
    // States
    //
    const [requisition, setRequisition] = useState([]);
    const action = useContext(ActionRouteContext);
    const setIsLoadingActive = useContext(LoadingContext);

    //
    // Functions
    //

    const getRequisitions = useCallback(
        async (param = null) => {
            const response = await api.get("requisitions", {
                project_id: id,
                ...param,
            });

            if (response) {
                setRequisition(response);
                setIsLoadingActive(false);
            }
        },
        [setIsLoadingActive, id]
    );

	// 
	// UseEffects
	// 

    useEffect(() => {
        setIsLoadingActive(true);
        getRequisitions();
    }, [setIsLoadingActive, getRequisitions]);

    return (
        <React.Fragment>
            {project && (
                <Helmet title={`${project.name} > Requistion`} />
            )}
            <Container fluid className="p-0">
                {
                    // Requisition View List
                    action === "list" && requisition && requisition.data ? (
                        <RequisitionListing
                            module="requisitions"
                            columns={tableColumns}
                            rawData={requisition}
                            parentApi={getRequisitions}
                        />
                    ) : // Create Edit Requisition
                    action === "addEdit" ? (
                        <AddEditRequisition project={project} />
                    ) : (
                        <ViewRequisition />
                    )
                }
            </Container>
        </React.Fragment>
    );
};
