import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { Col, Row, Form } from "react-bootstrap";
import Select from "react-select";
import { addDefaultOption, getLocalStorageValue, getSessionStorageValue, setLocalStorageValue, setSessionStorageValue } from "../../../../utils/utility";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./ValidationSchema";
import { ConfirmModal } from "../../../../components/ConfirmModal";
import api from "../../../../service/api";
import { SnackbarAlert } from "../../../../components/SnackbarAlert";
import { useSelector } from "react-redux";
import { current } from "@reduxjs/toolkit";
import ReactDatePicker from "react-datepicker";
import { UserDateFormat } from "../../../../utils/dateFormat";
import moment from "moment";


const FormRow = ({ configValues, quotation, courseFee }) => {
    let { id } = useParams();
    const navigate = useNavigate();
    const { data } = useSelector((state) => (state.user ? state.user : state));
    const permission = data?.permissions;
    const userFullName = data?.full_name;
    const currentDate = new Date();
    let displayDate = currentDate.toISOString().substring(0,10)
    const [hasMoreCompany, setHasMoreCompany] = useState(true);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [courseOptions, setCourseOptions] = useState(null);
    const [date, setDate] = useState(currentDate)
    // const [savedLocation, setSavedLocation] = useState({});
    const courseFeeRef = useRef(null);
    const [fee, setFee] = useState(null);
    const DateFormat = UserDateFormat();

    const [modalInfo, setModalInfo] = useState({
        values: [],
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
    });
    const createRoadshow = async (data) => {
        setIsSubmitting(true);
        try {
            const response = await api.post("tms/roadshow", data);
            if (response.status === "ok" || response.success) {
                navigate("/tms/roadshow", {
                    state: {
                        open: true,
                        notifMsg: response.message,
                        severity: "success",
                    },
                });
            } else {
                setNotif({
                    notifMsg: response.message,
                    open: true,
                    severity: "danger",
                });
            }
        } catch (error) {
            setNotif({
                notifMsg: error?.response?.data?.message,
                open: true,
                severity: "danger",
            });
        }
        setIsSubmitting(false);
    }

    useEffect(() => {
        setValue("sales_person_name", userFullName);
        setValue('date', currentDate);
        const savedLocation = getLocalStorageValue('roadshow-location', null);
        setValue('location', savedLocation);
    },[id, userFullName])

    useEffect(() => {
        if(selectedDepartment === 'ATTC'){
            courseFeeRef.current.value = selectedCourse ? fee ?? 0 : 0;
            setValue("course_fee", fee)
        }else{
            courseFeeRef.current.value = selectedCourse ? configValues?.form_courses[selectedCourse] ?? 0 : 0;
        }
    },[selectedCourse])

    useEffect(() => {
        if (selectedDepartment) {
            let newOptions = null;
            if (selectedDepartment === 'ATO') {
                newOptions = addDefaultOption(configValues?.form_courses);
            } else {
                newOptions = addDefaultOption(configValues?.form_item_codes );
            }
            setCourseOptions(newOptions);
        }
    }, [selectedDepartment])

    return (
        <>
            <form>
                <Row>
                    <Col md={6}>
                        <div className="mb-3">
                            <label htmlFor="sales_person_name" className="form-label">
                                Sales person name
                            </label>
                            <Controller
                                name="sales_person_name"
                                control={control}
                                render={({ field }) => (
                                    <>
                                         <Form.Control
                                            className={`react-select-container ${errors?.sales_person_name
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            isInvalid={
                                                errors?.sales_person_name
                                                    ? true
                                                    : false
                                            }
                                            type="text"
                                            name={field.name}
                                            defaultValue={userFullName}
                                            // isInvalid={
                                            //     errors?.name
                                            //         ? true
                                            //         : false
                                            // }
                                            onChange={(event) => {
                                                setValue(
                                                    "sales_person_name",
                                                    event.target.value,
                                                );
                                            }}
                                        />

                                        {!!errors.sales_person_name && (
                                            <div className="text-danger text-sm">
                                                Sales person name is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Col>

                    {/* Location */}
                    <Col md={6}>
                        <div className="mb-3">
                            <label htmlFor="location" className="form-label">
                                Location
                            </label>
                            <Controller
                                name="location"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            className={`react-select-container ${errors?.location
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            isInvalid={
                                                errors?.location
                                                    ? true
                                                    : false
                                            }
                                            // className={`react-select-container ${errors?.location
                                            //     ? "is-invalid"
                                            //     : ""
                                            //     }`}
                                            classNamePrefix="react-select"
                                            options={addDefaultOption(
                                                configValues?.location
                                            )}
                                            isClearable={true}
                                            value={field?.value}
                                            onChange={(selectedOption) => {
                                                setValue(
                                                "location",
                                                selectedOption
                                                );
                                                setLocalStorageValue('roadshow-location', selectedOption);
                                            }}
                                        />
                                        {!!errors?.location && (
                                            <div className="invalid-feedback">
                                                Location is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Col>

                    {/* Date */}
                    <Col md={6}>
                        <div className="mb-3">
                            <label htmlFor="type_of_trade" className="form-label">
                                Date
                            </label>
                            <Form.Group className="mb-3">
                                <ReactDatePicker
                                    name="date"
                                    selected={date}
                                    className={`form-control`}
                                    onChange={(date) => {
                                        setDate(date)
                                        setValue("date", date);
                                    }}
                                    dateFormat={DateFormat}
                                    placeholderText={DateFormat?.toUpperCase()}
                                />
                                {/* <Form.Control
                                    className={`react-select-container ${errors?.date
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    isInvalid={
                                        errors?.date
                                            ? true
                                            : false
                                    }
                                    type="date"
                                    name="date"
                                    defaultValue={displayDate}
                                    // isInvalid={
                                    //     errors?.date
                                    //         ? true
                                    //         : false
                                    // }
                                    onChange={(selectedOption) => {
                                        setValue(
                                            "date",
                                            selectedOption?.target?.value
                                        );
                                    }}
                                />
                                {!!errors?.date && (
                                    <Form.Control.Feedback type="invalid">
                                        Date is required
                                    </Form.Control.Feedback>
                                )} */}
                            </Form.Group>
                        </div>
                    </Col>

                    {/* Customer name */}
                    <Col md={6}>
                        <div className="mb-3">
                            <label htmlFor="customer_name" className="form-label">
                                Customer name
                            </label>
                            <Controller
                                className={`react-select-container ${errors?.customer_name
                                    ? "is-invalid"
                                    : ""
                                }`}
                                isInvalid={
                                    errors?.customer_name
                                        ? true
                                        : false
                                }
                                name="customer_name"
                                control={control}
                                render={({ field }) => (
                                    <>
                                         <Form.Control
                                            type="text"
                                            name={field.name}
                                            onChange={(event) => {
                                                setValue(
                                                    "customer_name",
                                                    event.target.value,
                                                );
                                            }}
                                        />

                                        {!!errors.customer_name && (
                                            <div className="text-danger text-sm">
                                                Customer name is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Col>
                    {/* <Col md={6}>
                        <div className="mb-3">
                            <label htmlFor="company_id" className="form-label">
                                Customer
                            </label>
                            {id === "add" && (
                                <Controller
                                    name="company_id"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <AsyncPaginate
                                                isClearable
                                                name={field.name}
                                                loadOptions={
                                                    handleLoadMoreCompany
                                                }
                                                hasMore={hasMoreCompany}
                                                additional={{
                                                    page: 1,
                                                }}
                                                onChange={(value) => {
                                                    handleCustomerChange(value);
                                                }}
                                            />

                                            {!!errors.company_id && (
                                                <div className="text-danger text-sm">
                                                    Customer is required
                                                </div>
                                            )}
                                        </>
                                    )}
                                />
                            )}
                        </div>
                    </Col> */}

                    {/* Customer FIN */}
                    <Col md={6}>
                        <div className="mb-3">
                            <label htmlFor="customer_fin" className="form-label">
                                Customer FIN
                            </label>
                            <Controller
                                name="customer_fin"
                                control={control}
                                render={({ field }) => (
                                    <>
                                         <Form.Control
                                            className={`react-select-container ${errors?.customer_fin
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            isInvalid={
                                                errors?.customer_fin
                                                    ? true
                                                    : false
                                            }
                                            type="text"
                                            name={field.name}
                                            // defaultValue={}
                                            // isInvalid={
                                            //     errors?.reference_number
                                            //         ? true
                                            //         : false
                                            // }
                                            onChange={(event) => {
                                                setValue(
                                                    "customer_fin",
                                                    event.target.value,
                                                );
                                            }}
                                        />

                                        {!!errors.customer_fin && (
                                            <div className="text-danger text-sm">
                                                Customer FIN is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Col>

                    {/* Type of trade */}
                    {/* <Col md={6}>
                        <div className="mb-3">
                            <label htmlFor="type_of_trade" className="form-label">
                                Type of trade
                            </label>
                            <Controller
                                name="type_of_trade"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            className={`react-select-container ${errors?.type_of_trade
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            classNamePrefix="react-select"
                                            options={addDefaultOption(
                                                configValues?.tradeOptions 
                                            )}
                                            isClearable={true}
                                            value={field.value?.value}
                                            onChange={(selectedOption) => {
                                                setValue(
                                                    "type_of_trade",
                                                    selectedOption?.value
                                                );
                                            }}
                                            {...field}
                                        />
                                        {!!errors?.type_of_trade && (
                                            <div className="invalid-feedback">
                                                Type of trade is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Col> */}

                    {/* Department */}
                    <Col md={6}>
                        <div className="mb-3">
                            <label htmlFor="department" className="form-label">
                                Department
                            </label>
                            <Controller
                                name="department"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            className={`react-select-container ${errors?.department
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            isInvalid={
                                                errors?.department
                                                    ? true
                                                    : false
                                            }
                                            classNamePrefix="react-select"
                                            options={addDefaultOption(
                                                configValues?.departments
                                            )}
                                            isClearable={true}
                                            value={field.value}
                                            onChange={(selectedOption) => {
                                                setValue(
                                                    "department",
                                                    selectedOption
                                                );
                                                setSelectedDepartment(selectedOption?.value);
                                            }}
                                        />
                                        {!!errors?.department && (
                                            <div className="invalid-feedback">
                                                Department is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Col>

                    {/* Course */}
                    <Col md={6}>
                        <div className="mb-3">
                            <label htmlFor="course" className="form-label">
                                {selectedDepartment === 'ATTC' ? 'Item Code' : 'Course'}
                            </label>
                            <Controller
                                name="course"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            className={`react-select-container ${errors?.course
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            isInvalid={
                                                errors?.course
                                                    ? true
                                                    : false
                                            }
                                            classNamePrefix="react-select"
                                            options={courseOptions}
                                            isClearable={true}
                                            value={field.value}
                                            isDisabled={!selectedDepartment}
                                            onChange={(selectedOption) => {
                                                setValue(
                                                    "course",
                                                    selectedOption
                                                );
                                                setSelectedCourse(selectedOption?.value);
                                                setFee(selectedOption?.fee)
                                                setValue(
                                                    'course_fee',
                                                    configValues?.courseFees[selectedOption?.value]
                                                )
                                            }}
                                            // {...field}
                                        />
                                        {!!errors?.course && (
                                            <div className="invalid-feedback">
                                                Course is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Col>

                    {/* Course fee */}
                    <Col md={6}>
                        <div className="mb-3">
                            <label htmlFor="course_fee" className="form-label">
                                Course fee
                            </label>
                            <Controller
                                name="course_fee"
                                control={control}
                                render={({ field }) => (
                                    <>
                                         <Form.Control
                                            className={`react-select-container ${errors?.course_fee
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            isInvalid={
                                                errors?.course_fee
                                                    ? true
                                                    : false
                                            }
                                            type="number"
                                            name="course_fee"
                                            defaultValue={0}
                                            onChange={(event) => {
                                                setValue(
                                                    "course_fee",
                                                    event.target.value,
                                                );
                                            }}
                                            ref={courseFeeRef}
                                        />

                                        {!!errors.course_fee && (
                                            <div className="text-danger text-sm">
                                                Course fee is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Col>

                    {/* Registration fee */}
                    <Col md={6}>
                        <div className="mb-3">
                            <label htmlFor="registration_fee" className="form-label">
                                Registration fee
                            </label>
                            <Controller
                                name="registration_fee"
                                control={control}
                                render={({ field }) => (
                                    <>
                                         <Form.Control
                                            type="number"
                                            name="registration_fee"
                                            defaultValue={0}
                                            onChange={(event) => {
                                                setValue(
                                                    "registration_fee",
                                                    event.target.value,
                                                );
                                            }}
                                        />

                                        {!!errors.registration_fee && (
                                            <div className="text-danger text-sm">
                                                Registration fee is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Col>

                    {/* Discount */}
                    <Col md={6}>
                        <div className="mb-3">
                            <label htmlFor="company_id" className="form-label">
                                Discount
                            </label>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <>
                                         <Form.Control
                                            type="number"
                                            name="discount"
                                            defaultValue={0}
                                            // placeholder={isOPVisible ? Number(availableCreditNotes ?? 0).toFixed(2) : Number(invoice?.remaining_amount).toFixed(2)}
                                            // isInvalid={
                                            //     errors?.amount
                                            //         ? true
                                            //         : false
                                            // }
                                            onChange={(event) => {
                                                setValue(
                                                    "discount",
                                                    event.target.value,
                                                );
                                            }}
                                            // ref={amountInputRef}
                                        />

                                        {!!errors.discount && (
                                            <div className="text-danger text-sm">
                                                Name is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Col>
                </Row>

                <hr />

                {id === "add" && !isSubmitting && (
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            handleSubmit(async (data) => {
                                setModalInfo({
                                    values: data,
                                    notifMsg:
                                        "Are you sure you want to create this record?",
                                    open: true,
                                    severity: "success",
                                    onConfirm: () => createRoadshow(data),
                                });
                            })();
                        }}
                        disabled={isSubmitting}
                    >
                        Create
                    </button>
                )}

                {/* {id !== "add" && !isSubmitting && (
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            setModalInfo({
                                values: getValues(), // Pass the current form values
                                notifMsg:
                                    "Are you sure you want to update this record?",
                                open: true,
                                severity: "success",
                                onConfirm: () => updateRoadshow(getValues()), // Call the update function when confirmed
                            });
                        }}
                        disabled={isSubmitting}
                    >
                        Update
                    </button>
                )} */}
                {!isSubmitting && (
                <button
                    type="button"
                    size="lg"
                    onClick={() => navigate("/tms/roadshow")}
                    className="btn btn-danger mx-2"
                >
                    Cancel
                </button>
                )}

                {isSubmitting && (
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                )}
            </form>
            {/* <ConfirmModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={createSaving}
            /> */}
            <ConfirmModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={modalInfo.onConfirm} // Call the update function when confirmed
            />

            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export default FormRow;