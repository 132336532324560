import React, { useEffect, useState, useContext } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../service/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { Formik } from "formik";
import * as Yup from "yup";
import { FileUpload } from "../../../components/FileUpload";
import { FileOverlay } from "../../../components/FileOverlay";
import { LoadingContext } from "../../../App";

export const ViewRequisition = () => {
    const navigate = useNavigate();
    let { id, action } = useParams();

    const schema = Yup.object().shape({
        status: Yup.string().required(),
    });

    //
    // States
    //

    const setIsLoadingActive = useContext(LoadingContext);
    const [inputFields, setInputFields] = useState({
        status: "",
        remarks: "",
    });
    // eslint-disable-next-line no-unused-vars
    const [requisition, setRequisition] = useState();
    // eslint-disable-next-line no-unused-vars
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [files, setFiles] = useState([]);
    const [deleteFiles, setDeleteFiles] = useState([]);
    const [deleteFilesPath, setDeleteFilePath] = useState([]);

    // Functions

    const submitEditRequisition = (values) => {
        try {
            const formData = new FormData();

            formData.append("status", values.status);
            formData.append("remarks", values.remarks || "");

            files.forEach((item) => {
                formData.append("attachments[]", item.file);
            });

            for (let key in deleteFiles) {
                formData.append(`delete_files[${key}]`, deleteFiles[key]);
            }

            for (let key in deleteFilesPath) {
                formData.append(
                    `delete_files_path[${key}]`,
                    deleteFilesPath[key]
                );
            }

            return api.post(`update-requisitions/${action}`, formData);
        } catch {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    const deleteUploadedFiles = (fileId, data, fullPath) => {
        const newData = data.filter((item) => {
            return item.id !== fileId;
        });

        requisition.attachments = newData;

        setDeleteFiles([...deleteFiles, fileId]);
        setDeleteFilePath([...deleteFilesPath, fullPath]);
        setRequisition({
            ...requisition,
        });
    };

    // UseEffects

    useEffect(() => {
        if (action !== undefined && action !== "create") {
            setIsLoadingActive(true);
            const getRequisition = async () => {
                try {
                    const response = await api.get(
                        `requisitions/${action}`,
                        {}
                    );

                    setRequisition(response.data);
                    setIsLoadingActive(false);
                    setInputFields({
                        status: response.data.status,
                        remarks: response.data.remarks,
                    });
                } catch (error) {
                    setIsLoadingActive(false);
                    setNotif({
                        notifMsg: "Something went wrong with the server",
                        open: true,
                        severity: "danger",
                    });
                }
            };
            getRequisition();
        }
    }, [action, setIsLoadingActive]);

    return (
        <Card className="shadow-none mb-0">
            <Card.Header className="shadow-none pb-0">
                <Card.Title tag="h5">
                    <Row className="mb-3">
                        <Col md={6}>View requisition</Col>
                        {requisition && requisition.quotation && (
                            <Col md={6} className="text-end">
                                <Button
                                    variant="primary"
                                    onClick={() =>
                                        navigate(
                                            `/intellidocs/process/quotations/${id}/${requisition.quotation_id}/view`
                                        )
                                    }
                                >
                                    {requisition.quotation.code}
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Card.Title>
            </Card.Header>
            <Card.Body className="py-0">
                <h6>Requisition items</h6>
                <div className="bg-light p-4 rounded mb-3">
                    <Row className="mb-2">
                        <Col md={1}>S/N</Col>
                        <Col md={2}>Item</Col>
                        <Col md={4}>Description</Col>
                        <Col md={2}>Quantity</Col>
                        <Col md={2}>Unit</Col>
                    </Row>
                    <hr style={{ border: "1px solid" }} />

                    {requisition &&
                        requisition.requisition_items.length &&
                        requisition.requisition_items.map((input, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Row className="mb-3">
                                        <Col md={1}>{index + 1}</Col>
                                        <Col md={2}>{input.item}</Col>
                                        <Col md={4}>{input.description}</Col>
                                        <Col md={2}>{input.quantity}</Col>
                                        <Col md={2}>{input.unit}</Col>
                                    </Row>
                                </React.Fragment>
                            );
                        })}
                </div>
                <>
                    <h6>Requisition details</h6>
                    <div className="bg-light p-4 rounded mb-3 mb-3">
                        {requisition && (
                            <>
                                <Row>
                                    <Col md={6}>
                                        <Row className="mb-3">
                                            <Col
                                                md={6}
                                                className="font-weight-bold"
                                            >
                                                Requisition code
                                            </Col>
                                            <Col md={6}>{requisition.code}</Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                md={6}
                                                className="font-weight-bold"
                                            >
                                                Requestor:
                                            </Col>
                                            <Col md={6}>
                                                {requisition.requestor}
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col
                                                md={6}
                                                className="font-weight-bold"
                                            >
                                                Quotation link:
                                            </Col>
                                            <Col md={6}>
                                                {requisition.quotation.code}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={6}>
                                        <Row>
                                            <Col
                                                md={6}
                                                className="font-weight-bold"
                                            >
                                                Request date:
                                            </Col>
                                            <Col md={6}>
                                                {requisition.request_date}
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col
                                                md={6}
                                                className="font-weight-bold"
                                            >
                                                Purpose:
                                            </Col>
                                            <Col md={6}>
                                                {requisition.purpose}
                                            </Col>
                                        </Row>
                                        {!requisition.attachments_support && (
                                            <Row className="mt-3">
                                                <Col
                                                    md={6}
                                                    className="font-weight-bold"
                                                >
                                                    Supporting documents:
                                                </Col>
                                                <Col md={6}>
                                                    {requisition.attachments_support.map(
                                                        (element, index) => {
                                                            return (
                                                                <FileOverlay
                                                                    key={index}
                                                                    index={
                                                                        index
                                                                    }
                                                                    fileName={
                                                                        element.file_name
                                                                    }
                                                                    fullPath={
                                                                        element.full_path
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                </Row>
                            </>
                        )}
                    </div>
                    <Formik
                        enableReinitialize
                        initialValues={inputFields}
                        validationSchema={schema}
                        onSubmit={async (
                            values,
                            { setErrors, setStatus, setSubmitting }
                        ) => {
                            try {
                                await submitEditRequisition(values);

                                navigate(
                                    `/intellidocs/process/requisitions/${id}`,
                                    {
                                        state: {
                                            open: true,
                                            notifMsg: `Successfully updated requisition status`,
                                            severity: "success",
                                        },
                                    }
                                );
                            } catch (error) {
                                const message =
                                    error.message || "Something went wrong";

                                setStatus({ success: false });
                                setErrors({ submit: message });
                                setSubmitting(false);
                                setNotif({
                                    notifMsg:
                                        "Something went wrong with the server",
                                    open: true,
                                    severity: "danger",
                                });
                            }
                        }}
                    >
                        {({
                            errors,
                            handleChange,
                            handleSubmit,
                            touched,
                            values,
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                {errors.submit && (
                                    <Alert className="my-3" variant="danger">
                                        <div className="alert-message">
                                            {errors.submit}
                                        </div>
                                    </Alert>
                                )}
                                <h6>Requisition status</h6>
                                <div className="bg-light p-4 rounded">
                                    <Row className="mt-3">
                                        <Col
                                            md={3}
                                            className="font-weight-bold"
                                        >
                                            Status:
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3">
                                                <Form.Select
                                                    name="status"
                                                    id="status"
                                                    value={values.status}
                                                    isInvalid={Boolean(
                                                        errors.status &&
                                                            touched.status
                                                    )}
                                                    onChange={handleChange}
                                                >
                                                    <option value="Pending for approval">
                                                        Pending for approval
                                                    </option>
                                                    <option value="Approved by manager">
                                                        Approved by manager
                                                    </option>
                                                    <option value="Pending from account">
                                                        Pending from account
                                                    </option>
                                                    <option value="Completed">
                                                        Completed
                                                    </option>
                                                </Form.Select>
                                                {errors.status && (
                                                    <Form.Control.Feedback type="invalid">
                                                        Expense type is a
                                                        required field
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col
                                            md={3}
                                            className="font-weight-bold"
                                        >
                                            Remarks:
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    name="remarks"
                                                    as="textarea"
                                                    type="text"
                                                    className="mb-3"
                                                    value={values.remarks || ""}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>{" "}
                                    <Row className="mt-3">
                                        <Col
                                            md={3}
                                            className="font-weight-bold"
                                        >
                                            Attachments: (Optional)
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <FileUpload
                                                    files={files}
                                                    setFiles={setFiles}
                                                />
                                                {requisition &&
                                                    requisition.attachments.map(
                                                        (element, index) => {
                                                            return (
                                                                <React.Fragment
                                                                    key={index}
                                                                >
                                                                    {index ===
                                                                        0 && (
                                                                        <>
                                                                            Uploaded:{" "}
                                                                            <br />
                                                                        </>
                                                                    )}
                                                                    <Button
                                                                        href={
                                                                            element.full_path
                                                                        }
                                                                        target="_blank"
                                                                        variant="link"
                                                                        className="m-0 px-0"
                                                                    >
                                                                        {
                                                                            element.file_name
                                                                        }
                                                                    </Button>
                                                                    <Button
                                                                        variant="primary"
                                                                        onClick={() =>
                                                                            deleteUploadedFiles(
                                                                                element.id,
                                                                                requisition.attachments,
                                                                                element.full_path
                                                                            )
                                                                        }
                                                                        className="ms-2"
                                                                        size="sm"
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faWindowClose
                                                                            }
                                                                        />
                                                                    </Button>
                                                                    <br />
                                                                </React.Fragment>
                                                            );
                                                        }
                                                    )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                                <Card.Footer className="text-center bg-none pb-0">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className="me-2"
                                        size="lg"
                                    >
                                        <FontAwesomeIcon icon={faPaperPlane} />{" "}
                                        Save
                                    </Button>
                                    <Button
                                        variant="link"
                                        onClick={() =>
                                            navigate(
                                                `/intellidocs/process/requisitions/${id}`
                                            )
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </Card.Footer>
                            </Form>
                        )}
                    </Formik>
                </>
            </Card.Body>
        </Card>
    );
};
