import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { addDefaultOption } from "../../../../../utils/utility";
import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";
import { Alert } from "react-bootstrap";

import StudentListMainInvoice from "./StudentListMainInvoice";
import InvoiceStudentsList from "./InvoiceStudentsList";
import { useParams } from "react-router-dom";

const CourseInformation = ({
    control,
    index,
    configValues,
    handleCourseChange,
    errors,
    setValue,
    invoiceStudent,
    handleFormInputStudTableChange,
    setStudentSelected,
    indexOf,
    mainInvoiceStudent,
    defaultStudentsData,
    additionalCourses,
    invoiceCourseId,
    sessionOptional
}) => {
    let { action } = useParams();
    return (
        <table width="100%">
            <tbody>
                <tr>
                    <td width="35%">
                        <div className="">
                            <label
                                htmlFor={`items[${index}].course_code`}
                                className="form-label"
                            >
                                Item code
                            </label>
                            <div className=" quotation_min_height">

                                <Controller
                                    name={`items[${index}].course_code`}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                className="react-select-container "
                                                classNamePrefix="react-select"
                                                options={addDefaultOption(
                                                    additionalCourses?.adhoc
                                                )}
                                                isClearable={true}
                                                name={field.name}
                                                onChange={(selectedOption) => {
                                                    field.onChange(
                                                        selectedOption
                                                    );
                                                    handleCourseChange(
                                                        selectedOption?.value,
                                                        index
                                                    );
                                                }}
                                                value={field.value}
                                                isDisabled={action!=='add'}
                                            />
                                        </>
                                    )}
                                />
                                {errors &&
                                    errors.items &&
                                    errors.items[index] &&
                                    errors.items[index].course_code && (
                                        <div className="text-danger mt-2 text-sm">
                                            Item code is required
                                        </div>
                                    )}
                            </div>
                        </div>
                    </td>
                    <td width="35%">
                        <div className="">
                            <label
                                htmlFor={`items[${index}].trade_option`}
                                className="form-label"
                            >
                                Type of trade
                            </label>
                            <div className=" quotation_min_height">
                                <Controller
                                    name={`items[${index}].trade_option`}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                options={addDefaultOption(
                                                    configValues?.tradeOptions
                                                )}
                                                name={field.name}
                                                isClearable={true}
                                                onChange={(selectedOption) => {
                                                    field.onChange(
                                                        selectedOption
                                                    );
                                                    // handleDropDownChange(index);
                                                }}
                                                value={field.value}
                                                isDisabled={action!=='add'}
                                            // onMenuClose={() => {
                                            //     handleDropDownChange(index);
                                            // }}
                                            />
                                        </>
                                    )}
                                />
                                {errors &&
                                    errors.items &&
                                    errors.items[index] &&
                                    errors.items[index].trade_option && (
                                        <div className="text-danger text-sm">
                                            Type of trade is required
                                        </div>
                                    )}
                            </div>
                        </div>
                    </td>
                    <td width="35%">
                        <div className="">
                            <label
                                htmlFor={`items[${index}].application_option`}
                                className="form-label"
                            >
                                Application option
                            </label>
                            <div className=" quotation_min_height">
                                <Controller
                                    name={`items[${index}].application_option`}
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                isClearable={true}
                                                options={addDefaultOption(
                                                    configValues?.tradeApplication
                                                )}
                                                {...field}
                                                onChange={(selectedOption) => {
                                                    field.onChange(
                                                        selectedOption
                                                    );
                                                    // handleDropDownChange(index);
                                                }}
                                                isDisabled={action!=='add'}
                                            // onMenuClose={() => {
                                            //     handleDropDownChange(index);
                                            // }}
                                            />
                                        </>
                                    )}
                                />
                                {errors &&
                                    errors.items &&
                                    errors.items[index] &&
                                    errors.items[index].application_option && (
                                        <div className="text-danger text-sm">
                                            Application option is required
                                        </div>
                                    )}
                            </div>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <div>
                            <label
                                htmlFor={`items[${index}].trade_category`}
                                className="form-label"
                            >
                                Trade category
                            </label>
                            <div className=" quotation_min_height">
                                <Controller
                                    name={`items[${index}].trade_category`}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                isClearable={true}
                                                options={addDefaultOption(
                                                    configValues?.tradeCategory
                                                )}
                                                {...field}
                                                onChange={(selectedOption) => {
                                                    field.onChange(
                                                        selectedOption
                                                    );
                                                    // handleDropDownChange(index);
                                                }}
                                                isDisabled={action!=='add'}
                                            // onMenuClose={() => {
                                            //     handleDropDownChange(index);
                                            // }}
                                            />
                                        </>
                                    )}
                                />
                                {errors &&
                                    errors.items &&
                                    errors.items[index] &&
                                    errors.items[index].trade_category && (
                                        <div className="text-danger text-sm">
                                            Trade category is required
                                        </div>
                                    )}
                            </div>
                        </div>
                    </td>
                    <td colSpan={2}>
                        <div>
                            <label
                                htmlFor={`items[${index}].course`}
                                className="form-label"
                            >
                                Course
                            </label>
                            <div className=" quotation_min_height">
                                <Controller
                                    name={`items[${index}].course`}
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                isClearable={true}
                                                options={addDefaultOption(
                                                    configValues.attcCourses
                                                )}
                                                {...field}
                                                onChange={(selectedOption) => {
                                                    field.onChange(
                                                        selectedOption
                                                    );
                                                    // handleDropDownChange(index);
                                                }}
                                                isDisabled={action!=='add'}
                                            // onMenuClose={() => {
                                            //     handleDropDownChange(index);
                                            // }}
                                            />
                                        </>
                                    )}
                                />
                                {errors &&
                                    errors.items &&
                                    errors.items[index] &&
                                    errors.items[index].course && (
                                        <div className="text-danger text-sm">
                                            Course is required
                                        </div>
                                    )}
                            </div>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td colSpan={3}>

                        <div className="mb-3">
                            <h5>Main Invoice student list</h5>

                            {invoiceStudent?.length === 0 && (
                                <Alert className="my-3" variant="success">
                                    <div className="alert-message">
                                        No students
                                    </div>
                                </Alert>
                            )}

                            {invoiceStudent?.length > 0 && (
                                <>

                                    <InvoiceStudentsList
                                        control={control}
                                        setValue={setValue}
                                        invoiceStudentSelected={invoiceStudent}
                                        mainInvoiceStudent={mainInvoiceStudent}
                                        indexOf={indexOf}
                                        setStudentSelected={setStudentSelected}
                                        setStudentTextbox={
                                            handleFormInputStudTableChange
                                        }
                                        defaultStudentsData={
                                            defaultStudentsData
                                        }
                                        invoiceCourseId={invoiceCourseId}
                                        sessionOptional={sessionOptional}
                                    />
                                </>
                            )}
                            {errors &&
                                errors.items &&
                                errors.items[index] &&
                                errors.items[index].students && (
                                    <div className="text-danger text-sm">
                                        Students is required
                                    </div>
                                )}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default CourseInformation;
