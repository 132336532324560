import React, { useState, useEffect } from "react";
import { Alert, Button, Card, Form } from "react-bootstrap";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import api from "../../../service/api";
import { AddEditRequisitionDetails } from "./AddEditRequisitionDetails";
import { AddEditRequisitionItems } from "./AddEditRequisitionItems";

export const AddEditRequisition = ({ project }) => {
    const navigate = useNavigate();
    let { id, action } = useParams();
    const [loading, setLoading] = useState(false);

    const schema = Yup.object().shape({
        requestor: Yup.string().required(),
        request_date: Yup.string().required(),
        purpose: Yup.string().required(),
        requisition_items: Yup.array().of(
            Yup.object().shape({
                item: Yup.string().required(),
                description: Yup.string().required(),
                quantity: Yup.string().required(),
                unit: Yup.string().required(),
            })
        ),
    });

    //
    // States
    //
    const [requisition, setRequisition] = useState();
    const [quotations, setQuotations] = useState();
    const [intelliDocsUser, setIntelliDocsUser] = useState();
    const [quotationSelect, setQuotationSelect] = useState();
    // eslint-disable-next-line no-unused-vars
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [inputFields, setInputFields] = useState({
        requestor: "",
        request_date: "",
        purpose: "",
        supporting_documents: "",
        quotation_id: "",
        requisition_items: [
            {
                item: "",
                description: "",
                quantity: "",
                unit: "",
            },
        ],
    });
    const [files, setFiles] = useState([]);
    const [deleteFiles, setDeleteFiles] = useState([]);
    const [deleteFilesPath, setDeleteFilePath] = useState([]);

    //
    // Functions
    //

    const handleFormChangeItems = (index, event) => {
        let data = [...inputFields.requisition_items];
        data[index][event.target.name] = event.target.value;

        calculateTotalAmount(data);

        setInputFields({
            ...inputFields,
            requisition_items: data,
        });
    };

    const handleFormChangeQuotation = (event, values) => {
        setInputFields({
            ...values,
            quotation_id: event ? event.value : "",
        });
    };

    const addFields = () => {
        let newfield = {
            item: "",
            description: "",
            quantity: "",
            unit: "",
        };

        setInputFields({
            ...inputFields,
            requisition_items: [...inputFields.requisition_items, newfield],
        });
    };

    const removeFields = (index) => {
        let data = [...inputFields.requisition_items];
        data.splice(index, 1);

        calculateTotalAmount(data);

        setInputFields({
            ...inputFields,
            requisition_items: data,
        });
    };

    const calculateTotalAmount = (data) => {
        const totalAmount = data.reduce(
            (total, current) => +total + +current.amount,
            0
        );

        inputFields["total"] = totalAmount;
    };

    const submitCreateRequisition = (values) => {
        try {
            const formData = arrangeFormData(values);
            return api.post("requisitions", formData);
        } catch {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    const submitRequisition = (values) => {
        try {
            const formData = arrangeFormData(values);

            return api.post(`update-requisitions/${action}`, formData);
        } catch {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    const arrangeFormData = (values) => {
        const formData = new FormData();

        formData.append("requestor", values.requestor);
        formData.append("request_date", values.request_date);
        formData.append("purpose", values.purpose);
        formData.append("portfolio_id", project.portfolio_id);
        formData.append("programme_id", project.programme_id);
        formData.append("project_id", id);
        formData.append("quotation_id", values.quotation_id);

        for (let key in values.requisition_items) {
            let items;
            items = values.requisition_items[key];

            formData.append(`requisition_items[${key}][item]`, items.item);

            items.id &&
                formData.append(`requisition_items[${key}][id]`, items.id);
            formData.append(
                `requisition_items[${key}][description]`,
                items.description
            );
            formData.append(
                `requisition_items[${key}][quantity]`,
                items.quantity
            );
            formData.append(`requisition_items[${key}][unit]`, items.unit);
        }

        files.forEach((item) => {
            formData.append(`supporting_documents[]`, item.file);
        });

        for (let key in deleteFiles) {
            formData.append(`delete_files[${key}]`, deleteFiles[key]);
        }

        for (let key in deleteFilesPath) {
            formData.append(`delete_files_path[${key}]`, deleteFilesPath[key]);
        }

        return formData;
    };

    const deleteUploadedFiles = (fileId, data, fullPath) => {
        const newData = data.filter((item) => {
            return item.id !== fileId;
        });

        requisition.attachments_support = newData;

        setDeleteFiles([...deleteFiles, fileId]);
        setDeleteFilePath([...deleteFilesPath, fullPath]);
        setRequisition({
            ...requisition,
        });
    };

    //
    // useEffect
    //

    useEffect(() => {
        if (action !== "create") {
            const getExpense = async () => {
                try {
                    const response = await api.get(
                        `requisitions/${action}`,
                        {}
                    );

                    setRequisition(response.data);
                } catch (error) {
                    setNotif({
                        notifMsg: "Something went wrong with the server",
                        open: true,
                        severity: "danger",
                    });
                }
            };
            getExpense();
        }
    }, [action]);

    useEffect(() => {
        if (requisition) {
            setInputFields({
                requestor: requisition.requestor,
                request_date: requisition.request_date,
                purpose: requisition.purpose,
                supporting_documents: requisition.supporting_documents,
                requisition_items: requisition.requisition_items,
                quotation_id: requisition.quotation_id,
            });
        }
    }, [requisition]);

    useEffect(() => {
        const getQuotations = async () => {
            try {
                const response = await api.get("quotation-options", {
                    project_id: id,
                    quotation_id: requisition && requisition?.quotation_id,
                });

                const mappedQuotation = (quotations = response.data) => {
					const array = [];
					setLoading(false);
			
					quotations &&
						quotations.map((data, index) => {
							return array.push({
								value: data.id,
								label: data.code,
							});
						});
			
					if (requisition && requisition?.quotation_id) {
						const quotationSelect = array.find((obj) => {
							return obj.value === requisition?.quotation_id;
						});
			
						setQuotationSelect(quotationSelect);
					}
			
					setLoading(true);
					return array;
				};

                setQuotations(mappedQuotation);
            } catch (error) {
                setNotif({
                    notifMsg: "Something went wrong with the server",
                    open: true,
                    severity: "danger",
                });
            }
        };

        getQuotations();
    }, [id, action, requisition]);

    useEffect(() => {
        const getUsers = async () => {
            try {
                const response = await api.get("intellidocs-users", {});
                setIntelliDocsUser(response.data);
            } catch (error) {
                setNotif({
                    notifMsg: "Something went wrong with the server",
                    open: true,
                    severity: "danger",
                });
            }
        };

        getUsers();
    }, []);

    return (
        <Card className="shadow-none mb-0">
            <Card.Header className="shadow-none">
                <Card.Title tag="h5" className="mb-0">
                    {action === "create" ? "Create" : "Edit"} requisition
                </Card.Title>
            </Card.Header>
            <Card.Body className="pb-0">
                {loading && (
                    <Formik
                        enableReinitialize
                        initialValues={inputFields}
                        validationSchema={schema}
                        onSubmit={async (
                            values,
                            { setErrors, setStatus, setSubmitting }
                        ) => {
                            try {
                                action === "create"
                                    ? await submitCreateRequisition(values)
                                    : await submitRequisition(values);

                                navigate(
                                    `/intellidocs/process/requisitions/${id}`,
                                    {
                                        state: {
                                            open: true,
                                            notifMsg: `Successfully ${
                                                action === "create"
                                                    ? "created"
                                                    : "updated"
                                            } expense`,
                                            severity: "success",
                                        },
                                    }
                                );
                            } catch (error) {
                                const message =
                                    error.message || "Something went wrong";

                                setStatus({ success: false });
                                setErrors({ submit: message });
                                setSubmitting(false);
                                setNotif({
                                    notifMsg:
                                        "Something went wrong with the server",
                                    open: true,
                                    severity: "danger",
                                });
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            touched,
                            values,
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                {errors.submit && (
                                    <Alert className="my-3" variant="danger">
                                        <div className="alert-message">
                                            {errors.submit}
                                        </div>
                                    </Alert>
                                )}
                                <h6>Requisitions items</h6>
                                <div className="bg-light p-3 mb-3 rounded">
                                    <AddEditRequisitionItems
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        handleBlur={handleBlur}
                                        handleFormChangeItems={
                                            handleFormChangeItems
                                        }
                                        addFields={addFields}
                                        removeFields={removeFields}
                                    />
                                </div>
                                <h6>Requisition details</h6>
                                <div className="bg-light p-3 mb-3 rounded">
                                    <AddEditRequisitionDetails
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        handleBlur={handleBlur}
                                        handleFormChangeDetails={handleChange}
                                        files={files}
                                        setFiles={setFiles}
                                        requisition={requisition}
                                        intelliDocsUser={intelliDocsUser}
                                        deleteUploadedFiles={
                                            deleteUploadedFiles
                                        }
                                        quotations={quotations}
                                        quotationSelect={quotationSelect}
                                        handleFormChangeQuotation={
                                            handleFormChangeQuotation
                                        }
                                        loading={loading}
                                    />
                                </div>
                                <Card.Footer className="text-center shadow-none pb-0">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className="me-2"
                                        size="lg"
                                    >
                                        <FontAwesomeIcon icon={faPaperPlane} />{" "}
                                        Save
                                    </Button>
                                    <Button
                                        variant="link"
                                        onClick={() =>
                                            navigate(
                                                `/intellidocs/process/requisitions/${id}`
                                            )
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </Card.Footer>
                            </Form>
                        )}
                    </Formik>
                )}
            </Card.Body>
        </Card>
    );
};
