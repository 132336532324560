import React, { useCallback, useEffect, useState } from "react";

import { Button, Col, Form, Row } from "react-bootstrap";
import { Search } from "react-feather";
import { AsyncPaginate } from "react-select-async-paginate";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";

import { UserDateFormat } from "../../../utils/dateFormat";
import { addDefaultOption } from "../../../utils/utility";
import api from "../../../service/api";

const SearchEnrolment = ({
    setSearchValue,
    searchValue,
    search,
    defaultConfig,
    resetSearch,
    isPendingStudents = false,
    attcTestDate = false,
}) => {
    const DateFormat = UserDateFormat();
    const [advanceSearch, setAdvanceSearch] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [configValues, setConfigValues] = useState({
        tradeOptions: [],
        tradeCategory: [],
        tradeApplication: [],
        paymentMode: [],
        additionalTraining: [],
        atoCourses: [],
        attcCourses: [],
        nationality: [],
        companies: [],
        registrationOption: [],
        workPermits: [],
        cetHide: [],
    });

    const setCourseData = (data) => {
        const formatAto = data
            .filter((data, index) => {
                return data.type?.toLowerCase() === "ato";
            })
            .map((data, index) => {
                return { value: data.id, label: data.name };
            });

        const formatAttc = data
            .filter((data, index) => {
                return data?.type?.toLowerCase() === "attc";
            })
            .map((data, index) => {
                return { value: data.id, label: data.name };
            });

        const { atoCourses, attcCourses } = { ...configValues };

        atoCourses.push(...formatAto);
        attcCourses.push(...formatAttc);
        setConfigValues({ atoCourses, attcCourses });
    };

    const [salesPersons, setSalesPersons] = useState();

    const setCompanyData = (data) => {
        const formatData = data.map((data, index) => {
            return { value: data.id, label: data.name };
        });

        const { companies } = { ...configValues };
        companies.push(...formatData);
        setConfigValues(companies);
    };

    const getConfig = useCallback(async () => {
        try {
            const configResponse = await api.get(`tms/enrolments/config`, {});
            const courseResponse = await api.get(`tms/courses?all=1`, {});
            const companyResponse = await api.get(`tms/get-sales-persons`, {});
            setSalesPersons(companyResponse);
            // const companyResponse = await api.get(`tms/companies?all=1`, {});

            setCourseData(courseResponse?.data);

            // setCompanyData(companyResponse?.data);

            setConfigValues({ ...configValues, ...configResponse.data });
            setLoading(false);
        } catch (error) {}
    }, []);

    useEffect(() => {
        getConfig();
    }, [getConfig]);

    const [hasMore, setHasMore] = useState(true);

    const handleLoadMore = async (search, { page }) => {
        if (!search) {
            return {
                options: [],
                hasMore: false,
            };
        }

        const response = await api.get("tms/companies-options", {
            search: search,
            page: page,
        });

        const { options: newOptions, hasMore: newHasMore } = response;

        setHasMore(newHasMore);

        return {
            options: newOptions,
            hasMore: newHasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    const handleChange = event => {
        setIsChecked(event.target.checked);
        setSearchValue({
            ...searchValue,
            hide_cet:
            event.target.checked,
        });
      };

    //   const [startDate, setStartDate] = useState(new Date());
    //   const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const onChange = (dates) => {
        const [start, end] = dates;
        start ? setStartDate(start) : setStartDate(null);
        end ? setEndDate(end) : setEndDate(null);
     
        setSearchValue({
            ...searchValue,
            start_date: start ? moment(start).format("YYYY-MM-DD") : '',
            end_date: end ? moment(end).format("YYYY-MM-DD") : '',
        });
       

    };
    
    return (
        <>
            {loading && "Loading..."}
            {!loading && (
                <>
                    <Row>
                        
                        <Col md={3}>
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            className={`form-control form-control-lg`}
                            size="lg"
                            selectsRange  
                            dateFormat={DateFormat}
                            placeholderText={`Select date range`}   
                            isClearable={true}
                        />
                            
                        </Col>
                        
                        {/* <Col md={3}>
                            <AsyncPaginate
                                isClearable
                                loadOptions={handleLoadMore}
                                hasMore={hasMore}
                                placeholder="Search sponsor company"
                                additional={{
                                    page: 1,
                                }}
                                onChange={(value) => {
                                    setSearchValue({
                                        ...searchValue,
                                        sponsor_company_id: value
                                            ? value?.value
                                            : "",
                                    });
                                }}
                                styles={{
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                    }),
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <AsyncPaginate
                                isClearable
                                loadOptions={handleLoadMore}
                                hasMore={hasMore}
                                placeholder="Search salesperson"
                                additional={{
                                    page: 1,
                                }}
                                onChange={(value) => {
                                    setSearchValue({
                                        ...searchValue,
                                        company_id: value
                                            ? value?.value
                                            : "",
                                    });
                                }}
                                styles={{
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                    }),
                                }}
                            />
                        </Col> */}

                        <Col md={3}>
                            <Select
                                className="is-invalid react-select-container"
                                classNamePrefix="react-select"
                                options={addDefaultOption(salesPersons)}
                                name="salesperson"
                                placeholderText={`Select salesperson`} 
                                onChange={(value) => {
                                    setSearchValue({
                                        ...searchValue,
                                        salesperson: value
                                            ? value?.value
                                            : "",
                                    });
                                }}
                                defaultValue={
                                    salesPersons?.filter(
                                        (option) => {
                                            return (
                                                option.label ===
                                                salesPersons?.label
                                            );
                                        }
                                    )
                                }
                            />
                        </Col>

                        
                        <Col md={3}>
                            <Button
                                size="lg"
                                variant="info"
                                className="me-1"
                                onClick={() => search({ page: 1 })}
                            >
                                <Search className="feather" />
                            </Button>
                            {(searchValue.fin !== "" ||
                                searchValue.registration_type !== "" ||
                                searchValue.date !== "") && (
                                <Button
                                    size="lg"
                                    variant="warning"
                                    className="me-1"
                                    onClick={() => resetSearch()}
                                >
                                    Reset
                                </Button>
                            )}
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

export default SearchEnrolment;
