import React, { useEffect, useState } from "react";
import Modal from "react-overlays/Modal";
import {
    Alert,
    Card,
    Col,
    Form,
    Row,
    OverlayTrigger,
    Tooltip,
    Spinner,
} from "react-bootstrap";
import { Formik } from "formik";
import Select from "react-select";

import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";

const CreditNoteWithdrawModal = ({
    showModal,
    onHideModal,
    loading,
    cnPayloadData,
    handleFormChangeRemarks,
    handleWithdrawSubmit,
    isSubmitting,
}) => {
    // Backdrop JSX code
    const renderBackdrop = (props) => <div className="backdrop" {...props} />;

    return (
        <>
            <Modal
                className="modal modal_withdraw_cn"
                show={showModal}
                onHide={onHideModal}
                renderBackdrop={renderBackdrop}
            >
                <div className="">
                    <div className="modal-header">
                        <div className="modal-title">Withdraw Credit Note</div>
                        <div>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-top`}>Close</Tooltip>
                                }
                            >
                                <span
                                    className="close-button"
                                    onClick={onHideModal}
                                >
                                    x
                                </span>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="modal-body">
                        <Card>
                            <Card.Body>
                                {loading && <div>Loading...</div>}
                                {!loading && (
                                    <Formik>
                                        {({
                                            errors,
                                            handleSubmit,
                                            values,
                                            setFieldValue,
                                        }) => (
                                            <Form
                                                noValidate
                                                onSubmit={handleSubmit}
                                            >
                                                {errors.submit && (
                                                    <Alert
                                                        className="my-3"
                                                        variant="danger"
                                                    >
                                                        <div className="alert-message">
                                                            {errors.submit}
                                                        </div>
                                                    </Alert>
                                                )}

                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>
                                                                Remarks
                                                                (required)
                                                            </Form.Label>
                                                            <AutoGrowTextarea
                                                                name="remarks"
                                                                className="mt-10"
                                                                handleFormChangeDetails={(
                                                                    event
                                                                ) => {
                                                                    handleFormChangeRemarks(
                                                                        event
                                                                    );
                                                                }}
                                                                fieldValue={
                                                                    cnPayloadData?.remarks
                                                                }
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                )}
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="modal-footer-withdraw-cn p-2 d-flex justify-content-end">
                        {!isSubmitting && (
                            <>
                                <button
                                    className="btn-danger me-2"
                                    onClick={onHideModal}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={
                                        !cnPayloadData?.remarks
                                            ? "secondary-button"
                                            : "primary-button"
                                    }
                                    // onClick={handleWithdrawSubmit}
                                    onClick={() => { if (window.confirm('Are you sure you wish withdraw this credit note?')) handleWithdrawSubmit() } }
                                    disabled={!cnPayloadData?.remarks}
                                >
                                    Withdraw
                                </button>
                            </>
                        )}
                        {isSubmitting && (
                            <Spinner animation="border" role="status" className="m-2">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CreditNoteWithdrawModal;
