import React, { useState } from "react";

import { useTable, usePagination, useSortBy } from "react-table";
import {
    Table,
    Alert,
} from "react-bootstrap";
import styled from "styled-components";
import { SnackbarAlert } from "../../../../components/SnackbarAlert";
// import { UserDateFormat } from "../../../../utils/dateFormat";

const Lists = ({
    columns,
    rawData,
}) => {
    /** Permissions */
    // const permissionData = useSelector((state) => {
    //     return state.user ? state.user : state;
    // });
    // const permission = permissionData?.data?.permissions;
    // const params = useParams();

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const {data} = rawData;
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        allColumns,
        page,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: 100,
            },
        },
        useSortBy,
        usePagination
    );


    // const [error, setError] = useState();

    // const DateFormat = UserDateFormat();

    const Styles = styled.div`
        .table {
            .th,
            .td {
                background-color: #fff;
                overflow: hidden;
                border-top: none;
            }

            &.sticky {
                overflow: scroll;
                .header,
                .footer {
                    position: sticky;
                    z-index: 1;
                    width: fit-content;
                }
            }
        }
        .table > :not(:first-child) {
            border-top: none;
        }
    `;

    return (
        <>
            {data.length > 0 && (
                <>
                    <Styles>
                        <Table responsive striped bordered {...getTableProps()}>
                            {/* <thead>
                                {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps()}
                                        className="text-nowrap"
                                    >
                                        {column.render('Header')}
                                        
                                    </th>
                                    ))}
                                </tr>
                                ))}
                            </thead> */}
                            <tbody {...getTableBodyProps()}>
                                {page.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} key={i}>
                                            {row.cells.map((cell, index) => (
                                            <td {...cell.getCellProps()} className={`text-nowrap 'font-weight-bold'}`} key={index}>
                                                {cell?.column?.id === 'table-label' ? <b>{cell.render("Cell")}</b> : cell.render("Cell")}
                                            </td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Styles>
                </>
            )}
            {data.length === 0 && (
                <Alert variant="warning">
                    <div className="alert-message">No data</div>
                </Alert>
            )}
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export { Lists };
