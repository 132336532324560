import React from "react";
import { Col, Row } from "react-bootstrap";

export const AddEditBilling = ({ project }) => {
    return (
        <>
            <Row>
                <Col md={6}>
                    <h4 className="mb-3">Revenue from:</h4>
                    <Row className="mx-3 mt-3">
                        <Col md={4} className="font-weight-bold">
                            Company name
                        </Col>
                        <Col md={6}>Intellect Engineering Pte Ltd</Col>
                    </Row>
                    <Row className="mx-3 mt-3">
                        <Col md={4} className="font-weight-bold">
                            Fax
                        </Col>
                        <Col md={5}>(65) 6278 7359</Col>
                    </Row>
                    <Row className="mx-3 mt-3">
                        <Col md={4} className="font-weight-bold">
                            Contact number
                        </Col>
                        <Col md={5}>(65) 6661 0671</Col>
                    </Row>
                    <Row className="mx-3 mt-3">
                        <Col md={4} className="font-weight-bold">
                            Address
                        </Col>
                        <Col md={5}>4 Tuas View Square, 637577 Singapore</Col>
                    </Row>
                </Col>
                {project.portfolio && (
                    <Col md={6}>
                        <h4 className="mb-3">Revenue to:</h4>
                        <Row className="mx-3 mt-3">
                            <Col md={4} className="font-weight-bold">
                                Company name
                            </Col>
                            <Col md={6}>{project.portfolio.name || "-"}</Col>
                        </Row>
                        <Row className="mx-3 mt-3">
                            <Col md={4} className="font-weight-bold">
                                Email
                            </Col>
                            <Col md={5}>{project.portfolio.email || "-"}</Col>
                        </Row>
                        <Row className="mx-3 mt-3">
                            <Col md={4} className="font-weight-bold">
                                Contact number
                            </Col>
                            <Col md={5}>
                                {project.portfolio.contact_number || "-"}
                            </Col>
                        </Row>
                        <Row className="mx-3 mt-3">
                            <Col md={4} className="font-weight-bold">
                                Address
                            </Col>
                            <Col md={5}>{project.portfolio.address || "-"}</Col>
                        </Row>
                    </Col>
                )}
            </Row>
        </>
    );
};
