import React from "react";
import { Badge, Table } from "react-bootstrap";

const InvoiceInfo = ({ invoice }) => {
  return (
    <>
      <Table size="sm" className="my-2 table table-bordered">
        <tbody>
          <tr>
            <th>Invoice Number</th>
            <td>{invoice?.number}</td>
          </tr>
          <tr>
            <th>Company</th>
            <td>{invoice?.company?.name}</td>
          </tr>
          <tr>
            <th>Invoice Amount</th>
            <td>S$ {invoice?.total_amount}</td>
          </tr>
          <tr>
            <th>Total Paid</th>
            <td>S$ {(Number(invoice?.total_paid_amount).toFixed(2)) ?? 0.00}</td>
          </tr>
          <tr>
            <th>Pending Payment</th>
            <td>S$ {invoice?.remaining_amount}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td>
              <Badge bg={(invoice?.status?.toLowerCase() == 'overpaid') ? "warning" : "success"}>{invoice?.status}</Badge>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default InvoiceInfo;
