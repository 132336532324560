const tableData = [
    {
        name: "class-saftey-course-001",
        test_date: "2022-05-10",
        trainer: "Kannan",
        invigilator: "chong",
        students: "25",
        status: "Training going on",
    },
    {
        name: "class-saftey-course-002",
        test_date: "2022-05-10",
        trainer: "Kannan",
        invigilator: "chong",
        students: "13",
        status: "Waiting for result",
    },
    {
        name: "class-saftey-course-003",
        test_date: "2022-05-10",
        trainer: "Kannan",
        invigilator: "chong",
        students: "0",
        status: "Pending student allocation",
    },
];

const tableColumns = [
    {
        Header: "S.no",
    },
    {
        Header: "Actions",
        accessor: "actions",
    },
    {
        Header: "Name",
        accessor: "name",
    },
    {
        Header: "Test date",
        accessor: "test_date",
        Cell: ({ value }) => <div className="text-nowrap">{value}</div>,
    },
    // {
    //     Header: "Location",
    //     accessor: "location",
    // },
    {
        Header: "Test venue",
        // accessor: "test_venue",
        accessor: (data) => {
            return <>{data?.test_venue?.join(', ') || '-'}</>
        }
    },
    {
        Header: "Training venue",
        // accessor: "training_venue",
        accessor: (data) => {
            return <>{data?.training_venue?.join(', ') || '-'}</>
        }
    },
    {
        Header: "Course",
        id: "course_id",
        accessor: (data) => {
            return <>{data?.course?.name || "-"}</>;
        },
    },
    {
        Header: "Trade category",
        id: "trade_category",
        accessor: (data) => {
            return <>{data?.trade_category || "-"}</>;
        },
    },
    {
        Header: "Total students",
        accessor: "total_students",
    },
    {
        Header: "Status",
        accessor: "status",
    },
    
];

export { tableData, tableColumns };
