const tableColumns = [
  {
    Header: "S.no",
  },
  {
    Header: "Quotation number",
    accessor: "number",
  },
  {
    Header: "Invoice number",
    accessor: "invoice.number",
  },
  {
    Header: "Company",
    accessor: "company.name",
  },
  {
    Header: "Amount",
    accessor: "total_amount",
  },
  {
    Header: "Sales person",
    accessor: "sales_user.name",
  },
  {
    Header: "Created by",
    accessor: "created_by.name",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Created on",
    accessor: "created_at",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];

export { tableColumns };
