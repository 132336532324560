import React from "react";
import { Alert, Card, Table } from "react-bootstrap";
import { useTable, useExpanded } from "react-table";

const ViewBatchStudentList = ({
    data,
    columns,
    batch,
    renderRowSubComponent,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // selectedFlatRows,
        visibleColumns,
        // state: { selectedRowIds },
        // state: { expanded },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                hiddenColumns: columns
                    .filter((col) => col.show === false)
                    .map((col) => col.accessor),
            },
        },

        useExpanded
    );

    return (
        <Card>
            <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                    Class students
                </Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
                {data.length === 0 && (
                    <Alert className="my-3" variant="warning">
                        <div className="alert-message">
                            No students under this batch/class
                        </div>
                    </Alert>
                )}
                {data.length > 0 && (
                    <Table responsive striped bordered {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <React.Fragment key={row.getRowProps().key}>
                                        <tr>
                                            {row.cells.map((cell, index) => {
                                                if (index === 1) {
                                                    return (
                                                        <td key={index}>
                                                            {parseInt(
                                                                cell.row.id
                                                            ) + 1}
                                                        </td>
                                                    );
                                                }
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                        {row.isExpanded ? (
                                            <tr>
                                                <td
                                                    colSpan={
                                                        visibleColumns.length
                                                    }
                                                >
                                                    {renderRowSubComponent({
                                                        row,
                                                    })}
                                                </td>
                                            </tr>
                                        ) : null}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </Table>
                )}
            </Card.Body>
        </Card>
    );
};

export default ViewBatchStudentList;
