const tableColumns = [
	{
		Header: "S/N",
		accessor: "serial",
	},
	{
		Header: "Quotation code",
		accessor: "code",
	},
	{
		Header: "From",
		accessor: "quotation_from",
	},
	{
		Header: "To",
		accessor: "quotation_to",
	},
	{
		Header: "Quotation date",
		accessor: "quotation_date",
	},
	{
		Header: "Total (S$)",
		accessor: "total",
	},
	{
		Header: "Actions",
		accessor: "action ",
	},
];

export { tableColumns };
