import React from "react";

import { Bar } from "react-chartjs-2";




const TypeofTradeChart = ({ summaryData }) => {

      
    // let labels = Object.keys(summaryData);
    const labels = ['Type of trade'];
    let formatArray = [];;
    const getRandomColor = () => {
        var letters = "0123456789ABCDEF";
        var color = "#";
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    Object.keys(summaryData["ATTC"])?.forEach((item) => {
        let tempDataSet = {
            label: item,
            data: [summaryData["ATTC"][item]],
            backgroundColor: getRandomColor(),
        };
        formatArray.push(tempDataSet);
    });
    const data = {
        labels,
        datasets: formatArray,
    };

    const options = {
        plugins: {
          datalabels: {
            display: true,
            color: "black",
            align: "end",
            anchor: "end",
            font: { size: "30" }
          }
        }
      };

    return <Bar options={options} data={data}  />;
};
export default TypeofTradeChart;
