import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTable, usePagination, useSortBy } from "react-table";
import {
    Container,
    Card,
    Row,
    Col,
    Form,
    Button,
    Table,
    Pagination
} from "react-bootstrap";
import { Edit2, Trash } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { tableColumns } from "./tableData";
import { useNavigate } from "react-router-dom";
import api from "../../../service/api";
import { SnackbarAlert } from "../../../components/SnackbarAlert.js";
import { DeleteModal } from "../../../components/DeleteModal.js";

export const Applications = () => {
    const navigate = useNavigate();
    const [applications, setApplications] = useState([]);
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns: tableColumns,
            data: applications,
            initialState: { pageIndex: 0 },
        },
        useSortBy,
        usePagination
    );

    const getApplicationsApi = useCallback(async () => {
        const getApplications = async() => {
            const response = await api.get('apps', {})
            setApplications(response.data)
        }
        getApplications()
    }, [])

     useEffect(() => {
        getApplicationsApi();
    }, [getApplicationsApi])

    const openDeleteModal = (id) => {
        setModalInfo({
            id: id,
            notifMsg: "Are you sure you want to delete this app?",
            open: true,
            severity: "danger",
        });
    };

    const deleteApplication = (id) => {
        api.delete('apps/' + id, {})
            .then(response => {
                if (response.status === 'ok' || response.success) {
                    setNotif({
                        notifMsg: 'Successfully deleted',
                        open: true,
                        severity: "success",
                    });
                   getApplicationsApi()
                }
        })
    }

    return (
        <React.Fragment>
            <Helmet title="Applications" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Applications</h1>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={6}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Control
                                            className="d-inline-block"
                                            type="text"
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Button
                                            variant="success"
                                            className="me-1 mb-1"
                                        >
                                            Search
                                        </Button>
                                    </Col>
                                </Row>    
                            </Col>
                            <Col md={6}>
                                <Button
                                    variant="primary"
                                    className="me-1 float-end"
                                    onClick={() => navigate("/applications/add")}
                                >
                                    Add application
                                </Button>
                            </Col>
                        </Row>
                    </Card.Header>
                        <Card.Body>
                            <Table striped bordered {...getTableProps()}>
                                <thead>
                                    { headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th
                                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                                >
                                                    {column.render("Header")}
                                                    {column.render("Header") !== "Actions" && (
                                                        <span>
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ? (
                                                            <FontAwesomeIcon
                                                                icon={faSortUp}
                                                                className="ms-2"
                                                            />
                                                            ) : (
                                                            <FontAwesomeIcon
                                                                icon={faSortDown}
                                                                className="ms-2"
                                                            />
                                                            )
                                                        ) : (
                                                            <FontAwesomeIcon icon={faSort} className="ms-2" />
                                                        )}
                                                        </span>
                                                    )}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell, index, row) => {
                                                if (index + 1 === row.length) {
                                                    return (
                                                        <td key={index}>
                                                            <a className="me-1" href={() => false} onClick={() => navigate('/applications/'+cell.row.original.id)}>
                                                                <Edit2 className="align-middle me-1" size={18} color="blue" />
                                                            </a>
                                                            <a className="me-1" href={() => false} onClick={() => openDeleteModal(cell.row.original.id)}>
                                                                <Trash className="align-middle me-1" size={18} color="red" />
                                                            </a>
                                                        </td>
                                                    );
                                                }
                                                return (
                                                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                );
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        <Row>
                            <Col md="6">
                            <span className="mx-2">
                                Page{" "}
                                <strong>
                                {pageIndex + 1} of {pageOptions.length}
                                </strong>
                            </span>
                            <span className="ms-3 me-2">Show:</span>
                            <Form.Select
                                className="d-inline-block w-auto"
                                value={pageSize}
                                onChange={(e) => {
                                setPageSize(Number(e.target.value));
                                }}
                            >
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                                ))}
                            </Form.Select>

                            <span className="ms-3 me-2">Go to page:</span>
                            <Form.Control
                                className="d-inline-block"
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(page);
                                }}
                                style={{ width: "75px" }}
                            />
                            </Col>
                            <Col md="6">
                            <Pagination className="float-end">
                                <Pagination.First
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                                />
                                <Pagination.Prev
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                                />
                                <Pagination.Next
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                                />
                                <Pagination.Last
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                                />
                            </Pagination>
                            </Col>
                        </Row>
                    </Card.Body>   
                </Card>
                <SnackbarAlert notif={notif} setNotif={setNotif} />
                <DeleteModal
                    modalInfo={modalInfo}
                    setModalInfo={setModalInfo}
                    api={deleteApplication}
                />
            </Container>
        </React.Fragment>
    )
}
