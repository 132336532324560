import React, { useEffect, useState } from "react";
import {
    Alert,
    Badge,
    Button,
    Card,
    Col,
    Container,
    Row,
} from "react-bootstrap";
import { ArrowLeft } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../../service/api";

import _ from "lodash";
import { formatDateTimeString } from "../../../utils/utility";

const Enrolment = ({ data }) => {
    return (
        <>
            <table className="table table-striped">
                <tbody>
                    <tr>
                        <th colSpan={2}>
                            <h5>Student details</h5>
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <th width="20%">Name</th>
                                        <td width="30%">
                                            {data?.name || "-"}
                                        </td>
                                        <th width="20%">Fin</th>
                                        <td width="30%">
                                            {data?.fin || "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Passport</th>
                                        <td width="30%">
                                            {data?.passport || "-"}
                                        </td>
                                        <th width="20%">Nationality</th>
                                        <td width="30%">
                                            {data?.nationality || "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Gender</th>
                                        <td width="30%">
                                            {data?.gender || "-"}
                                        </td>
                                        <th width="20%">Date of birth</th>
                                        <td width="30%">
                                            {data?.date_of_birth || "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Sector</th>
                                        <td width="30%">
                                            {data?.sector
                                                ? _.startCase(
                                                      _.toLower(
                                                          data?.sector
                                                      )
                                                  )
                                                : "-"}
                                        </td>
                                        <th width="20%">Work permit number</th>
                                        <td width="30%">
                                            {data?.work_permit_number ||
                                                "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="20%">
                                            Work permit expiry date
                                        </th>
                                        <td width="30%">
                                            {data?.work_permit_expire_date || "-"}
                                        </td>
                                        <th width="20%">
                                            Work permit category
                                        </th>
                                        <td width="30%">
                                            {data?.work_permit_category || "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="20%">
                                            Eligibility period for AN
                                        </th>
                                        <td width="30%">
                                            {data?.eligibility_period_for_an ||
                                                "-"}
                                        </td>
                                        <th width="20%">Identification type</th>
                                        <td width="30%">
                                            {data?.identification_type || "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="20%">
                                            Coretrade/Multi-skilling/Direct R1
                                            Reg. No.
                                        </th>
                                        <td width="30%">
                                            {data?.coretrade_multi_skill_direct ||
                                                "-"}
                                        </td>
                                        <th width="20%">Remarks</th>
                                        <td width="30%">
                                            {data?.remarks || "-"}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <th colSpan={2}>
                            <h5>Course details</h5>
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <table className="table table-bordered">
                                <tbody>
                                    {data?.type?.toLowerCase() === "attc" && (
                                        <tr>
                                            <th width="20%">Trade category</th>
                                            <td width="30%">
                                                {data?.trade_category || "-"}
                                            </td>
                                            <th width="20%">Type of trade</th>
                                            <td width="30%">
                                                {data?.type_of_trade || "-"}
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        {data?.type?.toLowerCase() ===
                                            "attc" && (
                                            <>
                                                <th width="20%">
                                                    Application option
                                                </th>
                                                <td width="30%">-</td>
                                            </>
                                        )}
                                        <th width="20%">Course</th>
                                        <td width="30%">
                                            {data?.course?.name || "-"}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <th colSpan={2}>
                            <h5>Company details</h5>
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <th width="20%">Type</th>
                                        <td width="30%">
                                            {data?.type?.toUpperCase()}
                                        </td>
                                        <th width="20%">Registration type</th>
                                        <td width="30%">
                                            {data?.self_registration
                                                ? "SELF"
                                                : "Company"}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th width="20%">Company</th>
                                        <td width="30%">
                                            {data?.company?.name || "-"}
                                        </td>
                                        {!data?.self_registration && (
                                            <>
                                                <th width="20%">
                                                    Sponsor company
                                                </th>
                                                <td width="30%">
                                                    {data?.sponsor_company
                                                        ?.name || "-"}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <th colSpan={2}>
                            <h5>Payment details</h5>
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <th width="20%">Course fee</th>
                                        <td width="30%">
                                            {data?.course_fee || "-"}
                                        </td>
                                        <th width="20%">Additional fee</th>
                                        <td width="30%">
                                            {data?.additional_fee || "-"}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th width="20%">Discount fee</th>
                                        <td width="30%">
                                            {data?.discount_fee || "-"}
                                        </td>
                                        <th width="20%">Additional training</th>
                                        <td width="30%">
                                            {data?.additional_training  || "-"}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th width="20%">Payment mode</th>
                                        <td width="30%">
                                            {data?.payment_mode || "-"}
                                        </td>
                                        <th width="20%">No. of days</th>
                                        <td width="30%">
                                            {data?.no_session || "-"}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th width="20%">Reference date</th>
                                        <td width="30%">
                                            {data?.reference_date || "-"}
                                        </td>
                                        <th width="20%">Reference number</th>
                                        <td width="30%">
                                            {data?.reference_no || "-"}
                                        </td>
                                    </tr>
                                    {data?.payment_mode === "cheque" && (
                                        <tr>
                                            <th width="20%">Cheque number</th>
                                            <td width="30%">
                                                {data?.cheque_number || "-"}
                                            </td>
                                            <th width="20%">Cheque date</th>
                                            <td width="30%">
                                                {data?.cheque_date || "-"}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    {(data?.invoice_id) && (
                        <>
                        <tr>
                            <th colSpan={2}>
                                <h5>Invoice details</h5>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th width="20%">Invoice number</th>
                                            <td width="30%">
                                            <a
                                                href={`/tms/invoices/view/${data?.invoice_id}?option=view&type=operation`}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="me-1"
                                            >
                                                {data?.invoice_no ?? "-"}
                                            </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        </>
                    )}
                    
                </tbody>
            </table>
        </>
    );
};

const Activities = ({ histories }) => {
    return (
        <>
            <h5 className="h4 mb-3">Enrolment timeline</h5>
            <ul className="timeline">
                {histories?.map((history, index) => {
                    return (
                        <li className="timeline-item mb-3" key={index}>
                            <strong style={{whiteSpace: "pre-wrap"}}>{history.status}</strong>
                            <p className="mt-0 mb-0 pt-0">
                                Date: {formatDateTimeString(history?.date, null, "YYYY-MM-DD, hh:mm a") ?? '-'}
                            </p>
                            <p className="mt-0  mb-0 pt-0">
                                User: {history?.user ?? '-'}
                            </p>
                        </li>
                    );
                })}
            </ul>
        </>
    );
};

const EnrolmentDetails = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [enrolment, setEnrolment] = useState();
    const [loading, setLoading] = useState(false);
    const { state } = useLocation();

    const getEnrolment = async (id) => {
        try {
            const response = await api.get(`tms/enrolments/${id}`, {});
            setEnrolment(response.data);
        } catch (error) {}
    };

    useEffect(() => {
        setLoading(true);
        getEnrolment(id);
        setLoading(false);
    }, [id]); //  eslint-disable-line react-hooks/exhaustive-deps

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const path = urlParams.get("status");

    return (
        <React.Fragment>
            <Helmet title="Enrolment details" />
            <Container fluid className="p-0">
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={6}>
                                <h1 className="h3 mb-3">Enrolment details </h1>
                            </Col>
                            <Col md={6} className="text-end">
                                {path !== "" && (
                                    <Button
                                        className="m-2"
                                        onClick={() =>
                                            navigate(`/tms/enrolments/${path}`)
                                        }
                                    >
                                        <ArrowLeft className="feather" />
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-0">
                        {loading && <div>Loading...</div>}
                        {!loading && enrolment && (
                            <Row>
                                <Col md={8}>
                                    <Enrolment data={enrolment} />
                                </Col>
                                <Col md={4}>
                                    <h4>Status:</h4>
                                    <Badge>{enrolment.status}</Badge>
                                    <hr />
                                    {enrolment?.history && (
                                        <Activities
                                            histories={enrolment?.history}
                                        />
                                    )}
                                    {enrolment?.history?.length === 0 ||
                                        (enrolment?.history === null && (
                                            <Alert
                                                className="my-3"
                                                variant="danger"
                                            >
                                                <div className="alert-message">
                                                    No timeline
                                                </div>
                                            </Alert>
                                        ))}
                                </Col>
                            </Row>
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};
export default EnrolmentDetails;
