const tableColumns = [
    {
        Header: "S.no",
        accessor: "sno",
    },
    {
        Header: "Name",
        accessor: "name",
    },
    //   {
    //     Header: "Address",
    //     accessor: "address",
    //   },
    {
        Header: "Customer code",
        accessor: "customer_code",
    },
    {
        Header: "UEN",
        accessor: "uen",
    },
    {
        Header: "Last Modified By",
        accessor: "user.first_name",
        Cell: ({ value }) => value ? value : "-", 
    },

    {
        Header: "Actions",
        accessor: "actions",
    },
];

export { tableColumns };
