import React, { useState } from "react";
import { Form, FormCheck} from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Edit2 } from "react-feather";
import api from "../../../service/api";
import { SnackbarAlert } from "../../../components/SnackbarAlert";

const ListButton = ({ app_id, cell }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    /** Permissions */
    const { data } = useSelector((state) => {
        return state.user ? state.user : state;
    });

    const handleUserDeactivation = async (e) => {
        const targetValue = e.target.checked;
        try {
            const userId = data?.id;
            const response = await api.put(`users/deactivate/${cell.id}`, {userId, targetValue});
            setNotif({
                id: cell.id,
                notifMsg: targetValue ? "Reactivated succussfully" : "Deactivated successfully",
                open: true,
                severity: "success",
            });
        } catch (error) {
            e.target.checked = !targetValue;
            setNotif({
                id: cell.id,
                notifMsg: error.message,
                open: true,
                severity: "danger",
            });
        }
    };

    const permission = data?.permissions;
    return (
        <>
            {/* USER DEACTIVATE BUTTON */}
            { permission?.includes("USER-DEACTIVATE") && (
                <Form
                    title="Deactivate"
                >
                    <FormCheck
                        type="switch"
                        id={"user-deactivate-" + cell.id}
                        defaultChecked={cell.is_active}
                        onChange={handleUserDeactivation}
                    />
                </Form>
            )}

            {/* USER EDIT BUTTON */}
            { permission?.includes("USER-EDIT") && (
                <>
                    <a className="me-1"
                        href={() => false}
                        onClick={() => navigate(location.pathname +'/'+ cell.id, {
                            state: {
                                app_id : app_id
                        }})}
                        title="Edit"
                    >
                        <Edit2 className="align-middle me-1" size={18} color="blue" />
                    </a>
                </>
                
            )}
            
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export default ListButton;
