import React, { useState, useContext } from "react";

import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Edit2, Trash } from "react-feather";
import { useNavigate } from "react-router-dom";

import { DeleteModal } from "../../../../components/DeleteModal";
import { SnackbarAlert } from "../../../../components/SnackbarAlert";
import api from "../../../../service/api";
import { RerenderContext } from "./Project";

export const ProjectListButtons = ({ row }) => {
    const navigate = useNavigate();
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    // eslint-disable-next-line no-unused-vars
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [setRerender, rerender] = useContext(RerenderContext);

    const openDeleteModal = (id) => {
        setModalInfo({
            id: id,
            notifMsg: "Are you sure you want to void this item?",
            open: true,
            severity: "danger",
        });
    };

    const deleteApi = async (id) => {
        try {
            await api.delete(`intellibuild/project-set-ups/${id}`, {});

            setRerender(!rerender);
            setNotif({
                notifMsg: "Delete successful",
                open: true,
                severity: "danger",
            });
        } catch (error) {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    return (
        <>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-top`}>Edit project</Tooltip>}
            >
                <Button
                    variant="outline-primary"
                    className="me-1 mb-1"
                    onClick={() =>
                        navigate(`/intellibuild/projects/${row.original.id}`)
                    }
                >
                    <Edit2 className="align-middle" size={18} />
                </Button>
            </OverlayTrigger>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-top`}>Delete project</Tooltip>}
            >
                <Button
                    variant="outline-primary"
                    className="me-1 mb-1"
                    onClick={() => openDeleteModal(row.original.id)}
                >
                    <Trash className="align-middle" size={18} />
                </Button>
            </OverlayTrigger>

            {/* Modal Components */}

            <SnackbarAlert notif={notif} setNotif={setNotif} />
            <DeleteModal
                api={deleteApi}
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
            />
        </>
    );
};
