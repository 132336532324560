const STATUS = ["Pending", "In progress", "Completed", "Closed"];

const BUDGET_TREND = [
    {
        id: "preliminary",
        trend: "Preliminary",
    },
    {
        id: "substructure",
        trend: "Substructure",
    },
    {
        id: "superstructure",
        trend: "Superstructure",
    },
    {
        id: "architectural",
        trend: "Architectural",
    },
    {
        id: "mAndE",
        trend: "M & E",
    },
    {
        id: "externalWork",
        trend: "External work",
    },
];

export { STATUS, BUDGET_TREND };
