import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Edit, Trash } from "react-feather";

const ListButton = ({ id, openDeleteModal }) => {
    const navigate = useNavigate();
    /** Permissions */
    const { data } = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = data?.permissions;
    return (
        <>
            {permission?.includes("COURSE-EDIT") && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Edit course</Tooltip>}
                >
                    <span>
                        <a
                            href={`/tms/courses/${id}`}
                            target="_blank"
                            rel="noreferrer"
                            className="me-1"
                        >
                            <span>
                                <Edit size="20" color="#6c757d" />
                            </span>
                        </a>
                    </span>
                </OverlayTrigger>
            )}
            {permission?.includes("COURSE-DELETE") && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Delete course</Tooltip>}
                >
                    <span>
                        <Trash
                            style={{ cursor: "pointer" }}
                            className="me-1 text-danger"
                            size="20"
                            onClick={() => openDeleteModal(id)}
                        />
                    </span>
                </OverlayTrigger>
            )}
        </>
    );
};

export default ListButton;
