import { Formik } from "formik";
import { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import api from "../../service/api";
import { SnackbarAlert } from "../../components/SnackbarAlert.js";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const Password = (props) => {
    const { data } = props;
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const navigate = useNavigate();
    const { signOut } = useAuth();

    const submitForm = (values, { setErrors }) => {
        const params = {};
        params.password = values?.new_password;
        params.password_confirmation = values?.confirm_password;

        api.put(`users/${data?.id}`, params)
            .then((response) => {
                if (!response.success) {
                    setNotif({
                        notifMsg: response.message,
                        open: true,
                        severity: "danger",
                    });
                }
                if (response.status === "ok") {
                    setNotif({
                        notifMsg: "Successfully saved",
                        open: true,
                        severity: "success",
                    });

                    setTimeout(() => {
                        setNotif({
                            notifMsg: "Please login again",
                            open: true,
                            severity: "success",
                        });
                    }, 2000);

                    setTimeout(() => {
                        try {
                            signOut();
                            navigate("/");
                        } catch (error) {}
                    }, 4000);
                }
            })
            .catch((error) => {
                setNotif({
                    notifMsg: error.message,
                    open: true,
                    severity: "danger",
                });
            });
    };

    return (
        <>
            <Container>
                <Card>
                    <Card.Header>
                        <Card.Title className="mb-0">Password</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Formik
                            initialValues={{
                                new_password: "",
                                confirm_password: "",
                            }}
                            enableReinitialize
                            validationSchema={Yup.object().shape({
                                new_password: Yup.string()
                                    .required("New password is required")
                                    .min(
                                        6,
                                        "New password must be atleast 6 characters"
                                    ),
                                confirm_password: Yup.string()
                                    .oneOf(
                                        [Yup.ref("new_password"), null],
                                        "Confirm password doesn't match"
                                    )
                                    .required("Confirm password is required"),
                            })}
                            onSubmit={(values, { setErrors }) =>
                                submitForm(values, { setErrors })
                            }
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                touched,
                                values,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    New password
                                                </Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    name="new_password"
                                                    size="lg"
                                                    placeholder="New password"
                                                    value={
                                                        values?.new_password ||
                                                        ""
                                                    }
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={Boolean(
                                                        touched.new_password &&
                                                            errors.new_password
                                                    )}
                                                />
                                                {!!touched.new_password && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.new_password}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Confirm password
                                                </Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    name="confirm_password"
                                                    size="lg"
                                                    placeholder="Confirm password"
                                                    value={
                                                        values?.confirm_password ||
                                                        ""
                                                    }
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={Boolean(
                                                        touched.confirm_password &&
                                                            errors.confirm_password
                                                    )}
                                                />
                                                {!!touched.confirm_password && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {
                                                            errors.confirm_password
                                                        }
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <div className="d-flex flex-row align-items-center p-2">
                                                <Button
                                                    variant="primary"
                                                    size="lg"
                                                    type="submit"
                                                    className="col-md-3 "
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Card.Body>
                </Card>
            </Container>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export default Password;
