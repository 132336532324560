import React, { useEffect, useContext, useState, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";
import { DeliveryOrderListing } from "./DeliveryOrderListing";
import { tableColumns } from "./tableDataDeliveryOrder.js";
import { AddEditDeliveryOrder } from "./AddEditDeliveryOrder";
import { ViewDeliveryOrder } from "./ViewDeliveryOrder";
import { ActionRouteContext } from "../Documents/Documents";
import api from "../../../service/api";
import { LoadingContext } from "../../../App.js";

export const DeliveryOrder = ({ project }) => {
    //
    // States
    //
	
    const action = useContext(ActionRouteContext);
    const setIsLoadingActive = useContext(LoadingContext);
    const [deliveryOrder, setDeliveryOrder] = useState([]);

    //
    // Functions
    //

    const getDevlieryOrders = useCallback(
        async (param = null) => {
            const response = await api.get("delivery-orders", { ...param });

            if (response) {
                setDeliveryOrder(response);
                setIsLoadingActive(false);
            }
        },
        [setIsLoadingActive]
    );

    //
    // useEffects
    //

    useEffect(() => {
        setIsLoadingActive(true);
        getDevlieryOrders();
    }, [getDevlieryOrders, setIsLoadingActive]);

    return (
        <React.Fragment>
            {project && (
                <Helmet
                    title={`${project.name} > Devliery Order`}
                />
            )}
            <Container fluid className="p-0">
                {
                    // Expense View List
                    action === "deliveryOrder" &&
                    deliveryOrder &&
                    deliveryOrder.data ? (
                        <DeliveryOrderListing
                            module="delivery orders"
                            columns={tableColumns}
                            rawData={deliveryOrder}
                            parentApi={getDevlieryOrders}
                        />
                    ) : // Create Edit Expense
                    action === "addEditDeliveryOrder" ? (
                        <AddEditDeliveryOrder project={project} />
                    ) : (
                        <ViewDeliveryOrder project={project} />
                    )
                }
            </Container>
        </React.Fragment>
    );
};
