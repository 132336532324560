import React, { useEffect, useState, useCallback } from "react";
import { Container, Card, Col, Form, Row, Button, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { SnackbarAlert } from "../../../components/SnackbarAlert.js";
import api from "../../../service/api";

export const AddEditPermission = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const add_page = id === 'add' ? true : false
    const schema = Yup.object().shape({
        app_id       : Yup.string().required('This field is required'),
        slug         : Yup.string().required('This field is required'),
        name         : Yup.string().required('This field is required'),
        description  : Yup.string().required('This field is required'),
    });

    // States
    const [applications, setApplications] = useState([]);
    const [permission, setPermission] = useState([]);
    const [categories, setCategories] = useState([]);
    const [notif, setNotif] = useState({
        notifMsg  : "",
        open      : false,
        severity  : "success",
    });
    const [initValues, setInitialValues] = useState({
        app_id: '',
        name: '',
        description: ''
    });

    // Functions
    const getApplications = useCallback(async () => {
        const response = await api.get('apps', {});
        setApplications(response.data);
    }, []);

    const getPermission = useCallback(async () => {
        const response = await api.get('permissions/'+id, {});
        setPermission(response.data);
    }, [id]);

    // UseEffects
    useEffect(() => {
        getApplications();
        if (!add_page) getPermission();
    }, [getApplications, getPermission, add_page])

    useEffect(() => {
            if (permission) {
                setInitialValues({
                    app_id      : permission.app_id,
                    slug        : permission.slug,
                    name        : permission.name,
                    description : permission.description,
                    category    : permission.category
                })
            }
    }, [permission])
    
    useEffect(() => {
        if (initValues.app_id) {
            let selected_app = applications.find(app => app.id === initValues.app_id) 
            if (selected_app) {
                let categories = selected_app.setting.permission_category
                setCategories(categories)
            } else {
                setCategories([])
            }
        } else {
            setCategories([])
        }
    }, [initValues, applications])

    const applicationChangeHandler = (e, setFieldValue) => {
        let selected_app_id = e.target.value

        if (selected_app_id) {
            setFieldValue("app_id", selected_app_id)
            
            let selected_app = applications.find(app => app.id === selected_app_id) 
            if (selected_app.setting !== null) {
                let categories = selected_app.setting.permission_category
                setCategories(categories)
            } else {
                setCategories([])
            }
        } 
    }

    const submitForm = (values, actions) => {
        let subscribe = add_page
                        ? api.post('permissions', values)
                        : api.put('permissions/' + id, values)
        
        subscribe.then(response => {
            if (!response.success) {
                setNotif({
                    notifMsg: response.message,
                    open: true,
                    severity: "danger",
                });
            }
            if (response.status === 'ok') {
                setNotif({
                    notifMsg: 'Successfully saved',
                    open: true,
                    severity: "success",
                });

                if (add_page) {
                    navigate("/permissions")
                }
            }
        })
        .catch(error => {
            actions.setSubmitting(false)
            setNotif({
                notifMsg: error.message,
                open: true,
                severity: "danger",
            });
        })
    }

    return (
        <>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">{ add_page ? 'Add permission' : 'Edit permission'}</h1>
                <Card>
                    <Card.Body>
                        <Formik
                            enableReinitialize
                            initialValues={initValues}
                            validationSchema={schema}
                            onSubmit={(values, actions) => submitForm(values, actions)}
                        >
                            {
                                ({ errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        {errors.submit && (
                                        <Alert className="my-3" variant="danger">
                                            <div className="alert-message">{errors.submit}</div>
                                        </Alert>
                                        )}

                                        <Row>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        value={values.name}
                                                        isInvalid={Boolean(touched.name && errors.name)}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                    />
                                                    {!!touched.name && (
                                                        <Form.Control.Feedback type="invalid">
                                                        {errors.name}
                                                        </Form.Control.Feedback>
                                                    )}
                                                   
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Description</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="description"
                                                        value={values.description}
                                                        isInvalid={Boolean(touched.description && errors.description)}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}   
                                                    />
                                                    {!!touched.description && (
                                                        <Form.Control.Feedback type="invalid">
                                                        {errors.description}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                             <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Application</Form.Label>
                                                    <Form.Select
                                                        name="app_id"
                                                        value={values.app_id}
                                                        isInvalid={Boolean(touched.app_id && errors.app_id)}
                                                        onBlur={handleBlur}
                                                        onChange={(e) => applicationChangeHandler(e, setFieldValue)} 
                                                    >
                                                        <option value="">Choose an option</option>
                                                        {
                                                            applications.map( (app, index) => {
                                                                return (
                                                                    <option key={index} value={app.id}>{app.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                    {/* {!!touched.app_id && (
                                                        <Form.Select.Feedback type="invalid">
                                                        {errors.app_id}
                                                        </Form.Select.Feedback>
                                                    )}
                                                    */}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Category</Form.Label>
                                                    <Form.Select
                                                        name="category"
                                                        value={values.category}
                                                        isInvalid={Boolean(touched.category && errors.category)}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                    >
                                                        <option value="">Choose an option</option>
                                                        {
                                                            categories.sort().map((category, index) => {
                                                                return (
                                                                    <option key={index} value={category}>{category.toUpperCase()}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                   
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                             <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Slug <small>(application-category-any)</small></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="slug"
                                                        value={values.slug}
                                                        isInvalid={Boolean(touched.slug && errors.slug)}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                    />
                                                    {!!touched.slug && (
                                                        <Form.Control.Feedback type="invalid">
                                                        {errors.slug}
                                                        </Form.Control.Feedback>
                                                    )}
                                                   
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={{ span: 3, offset: 9 }} className="text-end">
                                                <Button
                                                    variant="secondary"
                                                    className="me-2"
                                                    onClick={() => navigate(-1)}
                                                    >
                                                    Back
                                                </Button>
                                                <Button variant="primary" type="submit">
                                                    Submit
                                                </Button>
                                            </Col>
                                        </Row> 
                                    </Form>    
                                )
                            }
                              
                        </Formik>   
                    </Card.Body>
                </Card>
                <SnackbarAlert notif={notif} setNotif={setNotif} />
            </Container>
        </>
    )
}