const tableAMColumns = [
    {
        Header: "S.no",
    },
    {
        Header: "Name",
        accessor: (data) => {
            return <>{data?.enrolment?.name}</>;
        },
    },
    {
        Header: "FIN",
        accessor: (data) => {
            return <>{data?.enrolment?.fin}</>;
        },
    },
    {
        Header: "AM",
        id: "attendance_am",

        accessor: (data) => {
            return (
                <div>
                    {data?.attendance_am !== "" &&
                        data?.attendance_am !== null &&
                        data?.attendance_am?.charAt(0).toUpperCase() +
                            data?.attendance_am?.slice(1)}
                </div>
            );
        },
    },
];

const tablePMColumns = [
    {
        Header: "S.no",
    },
    {
        Header: "Name",
        accessor: (data) => {
            return <>{data?.enrolment?.name}</>;
        },
    },
    {
        Header: "FIN",
        accessor: (data) => {
            return <>{data?.enrolment?.fin}</>;
        },
    },
    {
        Header: "PM",
        id: "attendance_pm",
        accessor: (data) => {
            return (
                <div>
                    <div>
                        {data?.attendance_pm !== "" &&
                            data?.attendance_pm !== null &&
                            data?.attendance_pm?.charAt(0).toUpperCase() +
                                data?.attendance_pm?.slice(1)}
                    </div>
                </div>
            );
        },
    },
];

export { tableAMColumns, tablePMColumns };
