import React, { useEffect, useState } from "react";

import { Badge, Button, Card, Col, Row, Table } from "react-bootstrap";
import { tableColumns } from "./SendEmailData";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeft } from "react-feather";

import SendEmailList from "./SendEmailList";
import api from "../../../service/api";
import { Helmet } from "react-helmet-async";

const SendEmail = () => {
    let { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [students, setStudents] = useState({});
    const [batch, setBatch] = useState([]);
    const [search, setSearch] = useState({
        name: "",
        fin: "",
    });
    const navigate = useNavigate();

    const getStudents = async (search = null) => {
        let params = {
            batch_id: id,
        };

        if (search) {
            params = { ...params, ...search };
        }

        try {
            const response = await api.get(
                `tms/enrollment-emails/get-batch-students`,
                params
            );
            setStudents(response.data);
        } catch (error) {}
    };

    const getBatchDetail = async () => {
        try {
            const response = await api.get(`tms/batches/${id}`, {});
            setBatch(response.data);
        } catch (error) {}
    };

    useEffect(() => {
        setLoading(true);
        getStudents();
        setLoading(false);
        getBatchDetail();
    }, []); //  eslint-disable-line react-hooks/exhaustive-deps

    return (
            <Card>
                <Helmet title={"Batch/class > Summmary > Send email"} />
                <Card.Header>
                    <Card.Title tag="h5">
                        Send email to students
                        <div className="float-end">
                            <Button
                                className="primary"
                                onClick={() => {
                                    navigate("/tms/batches/summary");
                                }}
                            >
                                <ArrowLeft size={15}></ArrowLeft>
                            </Button>
                        </div>
                    </Card.Title>
                </Card.Header>
                <Card.Body className="pt-0">
                    <Row>
                        <Col md={6}>
                            <Table bordered className="mb-4" size="sm">
                                <tbody>
                                    <tr>
                                        <th>Name:</th>
                                        <td>{batch?.name || "-"}</td>
                                    </tr>
                                    {/* <tr>
                                        <th>Location:</th>
                                        <td>{batch?.location_display || batch?.location || "-"}</td>
                                    </tr> */}
                                    <tr>
                                        <th>Test venue:</th>
                                        <td>{batch?.test_venue?.join(', ') || "-"}</td>
                                    </tr>
                                    <tr>
                                        <th>Training venue:</th>
                                        <td>{batch?.training_venue?.join(', ') || "-"}</td>
                                    </tr>
                                    <tr>
                                        <th>Test date:</th>
                                        <td>{batch?.test_date || "-"}</td>
                                    </tr>
                                    <tr>
                                        <th>Status:</th>
                                        <td>
                                            {batch?.status ? (
                                                <Badge bg="primary">
                                                    {batch?.status}
                                                </Badge>
                                            ) : (
                                                "-"
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    {Boolean(!loading && Object.keys(students).length) &&
                        Object.values(students).map((data, key) => {
                            return (
                                <React.Fragment key={key}>
                                    <SendEmailList
                                        columns={tableColumns}
                                        data={data.students}
                                        sponsorCompany={
                                            Object.keys(students)[key]
                                        }
                                        getStudents={getStudents}
                                        setSearch={setSearch}
                                        search={search}
                                        batch={batch}
                                        information={data}
                                    />
                                </React.Fragment>
                            );
                        })}
                </Card.Body>
            </Card>
    );
};

export default SendEmail;
