const tableColumns = [
    {
        Header: "S.no",
        width: "60",
        sticky: "left",
    },
    {
        Header: "Actions",
        width: "100",
        sticky: "left",
        accessor: "actions",
    },
    {
        Header: "Course code",
        accessor: "course_code",
        sticky: "left",
        width: "50",
        getProps: () => {
            return {
                style: {
                    whiteSpace: 'nowrap',
                },
            };
        },
    },
    {
        Header: "Fee",
        accessor: "fee",
        sticky: "left",
        width: "120",
    },
    {
        Header: "Type of Trade",
        width: "200",
        accessor: "type_of_trade",
    },
    {
        Header: "Application option",
        width: "300",
        accessor: "application_option",
    },
    {
        Header: "Course name",
        width: "300",
        accessor: "course",
    },
    {
        Header: "Trade category",
        width: "165",
        accessor: "trade_category",
    },
    {
        Header: "id",
        accessor: "id",
        hidden: true,
    },
    {
        Header: "changes",
        accessor: "changes",
        hidden: true,
    }
];

export { tableColumns };
