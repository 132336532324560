import React from "react";

import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";
import api from "../../service/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faGoogle } from "@fortawesome/free-brands-svg-icons";

function SignIn() {
    const { signIn } = useAuth();

    const getGoogleAPI = async (e) => {
        console.log("lakjsdf", e);
        e.preventDefault();
        e.stopPropagation();
        
        try {
            const result = await api.get("sso/get_redirect_url", {});
            console.log("result: ", result);
            const googleSignInUrl = result?.data?.redirect_url;
    
            if (googleSignInUrl) {
                // Open the URL in a new window
                const googleSignInWindow = window.open(googleSignInUrl, "_self");
                
                // Listen for messages from the new window
                const handleMessage = (event) => {
                    console.log("event: ", event);
                    // Ensure the message is from the expected origin
                    if (event.origin === window.location.origin) {
                        // Handle the message data (response from the new window)
                        const responseData = event.data;
                        console.log('Received response from the new window:', responseData);
    
                        // Perform further actions with the response as needed
    
                        // Remove the event listener after receiving the message
                        window.removeEventListener('message', handleMessage);
    
                        // Close the new window (optional)
                        googleSignInWindow.close();
                    }
                };
    
                // Attach the event listener for messages
                window.addEventListener('message', handleMessage);
            }
        } catch(e) {
            console.error(e);
        }
    };

    return (
        <Formik
            initialValues={{
                email: "",
                password: "",
                submit: false,
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email("Must be a valid email")
                    .max(255)
                    .required("Email is required"),
                password: Yup.string()
                    .max(255)
                    .required("Password is required"),
            })}
            onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
            ) => {
                try {
                    // console.log("submitting form");
                    // return;
                    await signIn(values.email, values.password);
                } catch (error) {
                    const message = error.message || "Something went wrong";

                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setSubmitting(false);
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
            }) => (
                <Form onSubmit={handleSubmit}>
                    {errors.submit && (
                        <Alert className="my-3" variant="danger">
                            <div className="alert-message">{errors.submit}</div>
                        </Alert>
                    )}

                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            size="lg"
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            value={values.email}
                            isInvalid={Boolean(touched.email && errors.email)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {!!touched.email && (
                            <Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            size="lg"
                            type="password"
                            name="password"
                            placeholder="Enter your password"
                            value={values.password}
                            isInvalid={Boolean(
                                touched.password && errors.password
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {!!touched.password && (
                            <Form.Control.Feedback type="invalid">
                                {errors.password}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>

                    <div className="text-end mt-3">
                        <Button
                            type="submit"
                            variant="primary"
                            size="lg"
                            disabled={isSubmitting}
                        >
                            Sign in
                        </Button>

                        <Button
                            className="ms-2"
                            type="button"
                            variant="primary"
                            size="lg"
                            onClick={getGoogleAPI}
                        >
                            {/* <FontAwesomeIcon icon={}
                            className="align-middle"
                            size="sm"
                            style={{ cursor: 'pointer', marginBottom: '1px' }}
                            /> */}
                            <span>
                                Google Sign in
                            </span>
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default SignIn;
