import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import Select from "react-select";
import { addDefaultOption, formatNumberWithCommas } from "../../../../utils/utility";
import { yupResolver } from "@hookform/resolvers/yup";
import { ConfirmModal } from "../../../../components/ConfirmModal";
import api from "../../../../service/api";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { SnackbarAlert } from "../../../../components/SnackbarAlert";
import ReactDatePicker from "react-datepicker";
import { UserDateFormat } from "../../../../utils/dateFormat";


const PaymentFormRow = ({ configValues, roadshowData, loading, setLoading, notif, setNotif }) => {
    let { id } = useParams();
    const navigate = useNavigate();
    const { data } = useSelector((state) => (state.user ? state.user : state));
    const permission = data?.permissions;
    // const currentDate = new Date().toISOString().substr(0, 10);
    const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [date, setDate] = useState(new Date())
    const DateFormat = UserDateFormat();
    const [paymentMode, setPaymentMode] = useState(null)

    /** validatiob schema */
    const [formSchema, setFormSchema] = useState(
        yup.object().shape({
            date: yup.date().required(),
            payment_mode: yup.object().required(),
            payment_option: yup.object().required(),
            amount: yup.number().required(),
            installment_time: selectedPaymentOption === "INSTALLMENT" ? yup.number().required() : yup.number(),
        })
    )

    const [modalInfo, setModalInfo] = useState({
        values: [],
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues,
    } = useForm({
        resolver: yupResolver(formSchema),
    });

    const savePayment = async (data) => {
        setIsSubmitting(true);
        try {
            const response = await api.post(`tms/roadshow/save/payment/${id}`, data);
            if (response.status === "ok" || response.success) {
                setLoading(true)
                setNotif({
                    open: true,
                    notifMsg: response.message,
                    severity: "success",
                });
                // navigate("/tms/roadshow", {
                //     state: {
                //         open: true,
                //         notifMsg: response.message,
                //         severity: "success",
                //     },
                // });
            } else {
                setNotif({
                    notifMsg: response.message,
                    open: true,
                    severity: "danger",
                });
            }
        } catch (error) {
            setNotif({
                notifMsg: error?.response?.data?.message,
                open: true,
                severity: "danger",
            });
        }
        setLoading(false);
        setIsSubmitting(false);
    }


    useEffect(() => {
        setValue('date', date);
    },[id])

    useEffect(() => {
        // Updating the schema to set different_customer_id as required
        setFormSchema(yup.object().shape({
            date: yup.date().required(),
            payment_mode: yup.object().required(),
            payment_option: yup.object().required(),
            amount: yup.number().required(),
            installment_time: selectedPaymentOption === "INSTALLMENT" ? yup.string().required() : yup.string(),
          }));
        }, [selectedPaymentOption]);

    return (
        <>
            <form>
                <Row>
                    {/* Date */}
                    <Col md={6}>
                        <div className="mb-3">
                            <label htmlFor="type_of_trade" className="form-label">
                                Date
                            </label>
                            <Form.Group className="mb-3">
                                <ReactDatePicker
                                    name="date"
                                    selected={date}
                                    className={`form-control`}
                                    onChange={(date) => {
                                        setDate(date)
                                        setValue("date", date);
                                    }}
                                    showTimeSelect={false}
                                    dateFormat={DateFormat}
                                    placeholderText={DateFormat?.toUpperCase()}
                                />
                                {/* <Form.Control
                                    className={`react-select-container ${errors?.date
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    isInvalid={
                                        errors?.date
                                            ? true
                                            : false
                                    }
                                    type="date"
                                    name="date"
                                    defaultValue={currentDate}
                                    onChange={(selectedOption) => {
                                        setValue(
                                            "date",
                                            selectedOption?.target?.value
                                        );
                                    }}
                                />
                                {!!errors?.date && (
                                    <Form.Control.Feedback type="invalid">
                                        Date is required
                                    </Form.Control.Feedback>
                                )} */}
                            </Form.Group>
                        </div>
                    </Col>

                    {/* Payment mode */}
                    <Col md={6}>
                        <div className="mb-3">
                            <label htmlFor="payment_mode" className="form-label">
                                Payment mode
                            </label>
                            <Controller
                                name="payment_mode"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            className={`react-select-container ${errors?.payment_mode
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            classNamePrefix="react-select"
                                            options={addDefaultOption(
                                                configValues?.paymentMode 
                                            )}
                                            isClearable={true}
                                            value={field?.value}
                                            onChange={(selectedOption) => {
                                                setValue(
                                                    "payment_mode",
                                                    selectedOption
                                                );
                                                setPaymentMode(selectedOption?.value)
                                                if(selectedOption?.value?.toUpperCase() === 'CASH'){
                                                    setValue('reference_number', null)
                                                }
                                            }}
                                            // {...field}
                                        />
                                        {!!errors?.payment_mode && (
                                            <div className="invalid-feedback">
                                                Payment mode is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Col>

                    {/* Payment option */}
                    <Col md={6}>
                        <div className="mb-3">
                            <label htmlFor="payment_option" className="form-label">
                                Payment option
                            </label>
                            <Controller
                                name="payment_option"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            className={`react-select-container ${errors?.payment_option
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            isInvalid={
                                                errors?.payment_option
                                                    ? true
                                                    : false
                                            }
                                            classNamePrefix="react-select"
                                            options={addDefaultOption(
                                                configValues?.paymentOptions
                                            )}
                                            isClearable={true}
                                            value={field.value}
                                            onChange={(selectedOption) => {
                                                setValue(
                                                    "payment_option",
                                                    selectedOption
                                                );
                                                setSelectedPaymentOption(selectedOption?.value);
                                            }}
                                        />
                                        {!!errors?.payment_option && (
                                            <div className="invalid-feedback">
                                                Payment option is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Col>

                    {selectedPaymentOption === 'INSTALLMENT' && (
                        <Col md={6}>
                            <div className="mb-3">
                                <label htmlFor="installment_time" className="form-label">
                                    Installment time
                                </label>
                                <Controller
                                    name="installment_time"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <Form.Control
                                                className={`react-select-container ${errors?.installment_time
                                                    ? "is-invalid"
                                                    : ""
                                                }`}
                                                isInvalid={
                                                    errors?.installment_time
                                                        ? true
                                                        : false
                                                }
                                                type="number"
                                                step={1}
                                                name={field.name}
                                                onChange={(event) => {
                                                    setValue(
                                                        "installment_time",
                                                        event.target.value,
                                                    );
                                                }}
                                                required
                                            />

                                            {!!errors.installment_time && (
                                                <div className="text-danger text-sm">
                                                    Installment time is required
                                                </div>
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                        </Col>
                    )}


                    <Col md={6}>
                        <div className="mb-3">
                            <label htmlFor="company_id" className="form-label">
                                Amount
                            </label>
                            <Controller
                                name="amount"
                                control={control}
                                render={({ field }) => (
                                    <>
                                         <Form.Control
                                            className={`react-select-container ${errors?.amount
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            isInvalid={
                                                errors?.amount
                                                    ? true
                                                    : false
                                            }
                                            type="number"
                                            name="amount"
                                            // defaultValue={0}
                                            onChange={(event) => {
                                                setValue(
                                                    "amount",
                                                    event.target.value,
                                                );
                                            }}
                                            // ref={amountInputRef}
                                        />

                                        {!!errors.amount && (
                                            <div className="text-danger text-sm">
                                                Amount is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Col>
                    {paymentMode?.toUpperCase() !== 'CASH' && (          
                        <Col md={6}>
                            <div className="mb-3">
                                <label htmlFor="reference_number" className="form-label">
                                    Reference number
                                </label>
                                <Controller
                                    name="reference_number"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <Form.Control
                                                className={`react-select-container ${errors?.reference_number
                                                    ? "is-invalid"
                                                    : ""
                                                }`}
                                                isInvalid={
                                                    errors?.reference_number
                                                        ? true
                                                        : false
                                                }
                                                type="text"
                                                name={field.name}
                                                onChange={(event) => {
                                                    setValue(
                                                        "reference_number",
                                                        event.target.value,
                                                    );
                                                }}
                                            />

                                            {!!errors.reference_number && (
                                                <div className="text-danger text-sm">
                                                    Reference number is required
                                                </div>
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                        </Col>
                    )}
                </Row>

                {!isSubmitting && (
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            handleSubmit(async (data) => {
                                setModalInfo({
                                    values: data,
                                    notifMsg:
                                        "Are you sure you want to save this payment?",
                                    open: true,
                                    severity: "success",
                                    onConfirm: () => savePayment(data),
                                });
                            })();
                        }}
                        disabled={isSubmitting}
                    >
                        Add payment
                    </button>
                )}

                {!isSubmitting && (
                    <button
                        type="button"
                        size="lg"
                        onClick={() => navigate("/tms/roadshow")}
                        className="btn btn-danger mx-2"
                    >
                        Back
                    </button>
                )}

                {isSubmitting && (
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                )}
            </form>

            <hr />

            {roadshowData?.payments?.length > 0 && (
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Ref no</th>
                            <th>Payment method</th>
                            <th>Payment option</th>
                            <th>Installment time</th>
                            {/* <th>Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {roadshowData.payments?.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td> S$ {formatNumberWithCommas(item?.amount) ?? '-'}</td>
                                <td>{item?.date ?? '-'}</td>
                                <td>{item?.reference_number ?? '-'}</td>
                                <td>{item?.payment_mode ?? '-'}</td>
                                <td>{item?.payment_option ?? '-'}</td>
                                <td>{item?.installment_time ?? '-'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {(roadshowData?.payment_history?.length <= 0 || !roadshowData?.payment_history) && (
                <div className="text-danger">No payments yet</div>
            )}

            <ConfirmModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={modalInfo.onConfirm} // Call the update function when confirmed
            />

            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export default PaymentFormRow;