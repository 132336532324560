import { Badge } from "react-bootstrap";

const batchStudentTableColumns = [
    {
        Header: "S.no",
    },
    {
        Header: "Name",
        accessor: "enrolment.name",
    },
    {
        Header: "FIN",
        accessor: "enrolment.fin",
    },
    {
        Header: "Sponsor company",
        id: "enrolment.sponsor_company.name",
        accessor: (data) => {
            return (
                <div>
                    {data?.enrolment?.self_registration ? (
                        <Badge bg="primary">SELF</Badge>
                    ) : (
                        !data?.enrolment?.self_registration && (
                            <div>
                                {data?.enrolment?.sponsor_company?.name || "-"}
                            </div>
                        )
                    )}
                </div>
            );
        },
    },
    {
        Header: "Type of trade",
        id: "enrolment.type_of_trade",
        accessor: (data) => {
            return <>{data?.enrolment?.type_of_trade || "-"}</>;
        },
    },
    {
        Header: "Application option",
        id: "enrolment.application_option",
        accessor: (data) => {
            return <>{data?.enrolment?.application_option || "-"}</>;
        },
    },
    {
        Header: "Training date",
        id: "training_date",
        accessor: (data) => {
            return (
                <table className="table table-bordered">
                    <tbody>
                        {data.student?.training_dates?.map((item, index) => (
                            <tr key={index}>
                                <td>{item.date}</td>
                                <td>{item?.session?.join()}</td>
                                <td>{item?.training_type}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        },
    },
];

export { batchStudentTableColumns };
