import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Container,
    Card,
    Row,
    Col,
    Form,
    Button
} from "react-bootstrap";
import api from "../../../service/api";
import { RolesTable } from "./RolesTable";
import { SnackbarAlert } from "../../../components/SnackbarAlert";
import { pressEnterToSearch } from "../../../utils/utility";
//import { AppContext } from "../../Tms/Guard/TmsAppGuard";

export const Roles = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const app = props.app;
    const [roles, setRoles] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [filter, setFilter] = useState({
        app_id : app ? app.id : null,
        search : '',
    });
    const [notif, setNotif] = useState({
        notifMsg  : "",
        open      : false,
        severity  : "success",
    });
    const getRolesApi = useCallback(async () => {
        const getRoles = async () => {
            const response = await api.get('roles', filter)
            setRoles(response.data)
        }
        getRoles()
    }, [filter]);

    useEffect(() => {
       getRolesApi()
    }, [getRolesApi])

    const search = () => {
        setFilter((prevState) => ({
        ...prevState,
            search: searchValue,
        }));
    }

    const deleteRole = (id) => {
        api.delete('roles/' + id, {})
            .then(response => {
                if (response.status === 'ok' || response.success) {
                    setNotif({
                        notifMsg: 'Successfully deleted',
                        open: true,
                        severity: "success",
                    });
                    getRolesApi()
                }
        })
    }
    
    return (
        <React.Fragment>
            <Helmet title="Configuration > Roles" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Roles</h1>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={6}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Control
                                            className="d-inline-block"
                                            type="text"
                                            onChange={(e) => setSearchValue(e.target.value)}
                                            onKeyDown={(e) => pressEnterToSearch(e, search)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Button
                                            variant="success"
                                            className="me-1 mb-1"
                                            onClick={() => search()}
                                        >
                                            Search
                                        </Button>
                                    </Col>
                                </Row>    
                            </Col>
                            <Col md={6}>
                                <Button
                                    variant="primary"
                                    className="me-1 float-end"
                                    onClick={() => navigate(location.pathname+"/add")}
                                >
                                    Add role
                                </Button>
                            </Col>
                            
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <RolesTable
                            roles={roles}
                            app_id={app?.id}
                            deleteAction={deleteRole}
                        />
                    </Card.Body>
                </Card>    
            
            </Container>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </React.Fragment>    
            
    )
}