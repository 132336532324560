import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import FormRow from "./FormRow";

const AddEditCourse = () => {
    let { id } = useParams();
    const helmetTitle = `${id === "add" ? "Add" : "Edit"} course`;
    return (
        <React.Fragment>
            <Helmet title={helmetTitle}/>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">
                    {helmetTitle}
                </h1>

                <Row>
                    <Col lg="12">
                        <FormRow id={id} />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default AddEditCourse;
