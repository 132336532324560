import React, { useState } from "react";
import { Badge, Button, Col, Form, Row } from "react-bootstrap";
import { Filter } from "react-feather";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import api from "../../../../service/api";
import { addDefaultOption } from "../../../../utils/utility";
import { minSelectableYear } from "../../../../utils/EnrolmentSummaryConstants";
import { DatePicker } from "antd";
import moment from "moment";

const Search = ({ setSearchValue, companies, searchValue, search , salesPersons}) => {
    const [hasMore, setHasMore] = useState(true);
    const handleLoadMore = async (search, { page }) => {
        if (!search) {
            return {
                options: [],
                hasMore: false,
            };
        }

        const response = await api.get("tms/companies-options", {
            search: search,
            page: page,
        });

        const { options: newOptions, hasMore: newHasMore } = response;

        setHasMore(newHasMore);

        return {
            options: newOptions,
            hasMore: newHasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    return (
        <>
            <Row className="d-flex justify-content-md-between">
                <Col md={6} lg={2} className="mb-3">
                    <Form.Control
                        type="text"
                        name="number"
                        placeholder="Quotation no"
                        size="lg"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                number: event.target.value,
                            });
                        }}
                    />
                </Col>
                <Col md={6} lg={2} className="mb-3">
                    <Form.Control
                        type="text"
                        name="invoice_number"
                        placeholder="Invoice no"
                        size="lg"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                invoice_number: event.target.value,
                            });
                        }}
                    />
                </Col>
                <Col md={6} lg={2} className="mb-3">
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={addDefaultOption([
                            {
                                value: "APPROVED",
                                label: "Approve",
                            },
                            {
                                value: "DRAFT",
                                label: "Draft",
                            },
                            {
                                value: "PENDING",
                                label: "Pending",
                            },
                            {
                                value: "REJECTED",
                                label: "Rejected",
                            },
                        ])}
                        name="status"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                status: event.value,
                            });
                        }}
                    />
                </Col>
                <Col md={6} lg={2} className="mb-3">
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={addDefaultOption(salesPersons)}
                        name="salesperson"
                        placeholder="Search salesperson"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                salesperson: event.value,
                            });
                        }}
                    />
                </Col>
                <Col md={6} lg={4} className="mb-3">
                    <AsyncPaginate
                        isClearable
                        loadOptions={handleLoadMore}
                        hasMore={hasMore}
                        placeholder="Search company"
                        additional={{
                            page: 1,
                        }}
                        onChange={(value) => {
                            setSearchValue({
                                ...searchValue,
                                company: value ? value?.value : "",
                            });
                        }}
                        debounceTimeout={1000}
                    />
                </Col>
                <Col md={6} lg={4} className="mb-3 px-md-4">
                    <Row className="flex-row">
                        <Col md={5} className="p-md-0">
                            <DatePicker
                                id="from-date-picker"
                                name="from_date"
                                className={`form-control`}
                                placeholder="From date"
                                // defaultValue={moment().startOf('month')}
                                // allowClear={false}
                                onChange={(event) => {
                                    setSearchValue({
                                        ...searchValue,
                                        start_date: event ? moment(event).format("YYYY-MM-DD") : null,
                                    });
                                }}
                                style={{minHeight: '2.5rem'}}
                            />
                            {/* <Form.Control
                                type="month"
                                name="start_date"
                                min={`${minSelectableYear}-01`}
                                max={`${new Date().getFullYear() + 1 }-12`}
                                size="lg"
                                onChange={(event) => {
                                    setSearchValue({
                                        ...searchValue,
                                        start_date: event.target.value,
                                    });
                                }}
                            /> */}
                        </Col>
                        <Col md={2} className="d-flex align-items-center justify-content-center m-0 p-0">
                            <Badge bg='info' className="d-flex align-items-center justify-content-center text-center w-100 h-100 mx-2 mx-md-0">
                                To
                            </Badge>
                        </Col>
                        <Col md={5} className="p-md-0">
                            <DatePicker
                                id="to-date-picker"
                                name="to_date"
                                className={`form-control`}
                                placeholder="To date"
                                // defaultValue={moment().endOf('month')}
                                // allowClear={false}
                                onChange={(event) => {
                                    setSearchValue({
                                        ...searchValue,
                                        end_date: event ? moment(event).format("YYYY-MM-DD") : null,
                                    });
                                }}
                                style={{minHeight: '2.5rem'}}
                            />
                            {/* <Form.Control
                                type="month"
                                name="start_date"
                                min={`${minSelectableYear}-01`}
                                max={`${new Date().getFullYear() + 1}-12`}
                                size="lg"
                                onChange={(event) => {
                                    setSearchValue({
                                        ...searchValue,
                                        end_date: event.target.value,
                                    });
                                }}
                            /> */}
                        </Col>
                    </Row>
                </Col>
                <Col md={2} lg={1} className="mb-3 d-flex justify-content-center align-items-center d-md-block text-md-end">
                    <Button size="lg" variant="info" onClick={() => search()}>
                        <Filter size={15} />
                    </Button>
                </Col>
            </Row>
        </>
    );
};
export default Search;
