import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Box, Edit2, Eye, Trash } from "react-feather";
import { useNavigate } from "react-router-dom";
import { SubModuleButtons } from "./SubModuleButtons";

export const IntellidocsButtons = ({
    module,
    cellId,
    projectId = null,
    openDeleteModal,
    parentModule = null,
    subModule = null,
}) => {
    const navigate = useNavigate();

    return (
        <>
            {module === "delivery orders" || module === "received payments" ? (
                <SubModuleButtons
                    module={module}
                    cellId={cellId}
                    projectId={projectId}
                    openDeleteModal={openDeleteModal}
                    parentModule={parentModule}
                    subModule={subModule}
                />
            ) : (
                <>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                Edit {module.slice(0, -1)}
                            </Tooltip>
                        }
                    >
                        <Button
                            variant="outline-primary"
                            className="me-1 mb-1"
                            onClick={() =>
                                navigate(
                                    `/intellidocs/process/${module}/${projectId}/${cellId}`
                                )
                            }
                        >
                            <Edit2 className="align-middle" size={18} />
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                View {module.slice(0, -1)}
                            </Tooltip>
                        }
                    >
                        <Button
                            variant="outline-primary"
                            className="me-1 mb-1"
                            onClick={() =>
                                navigate(
                                    `/intellidocs/process/${module}/${projectId}/${cellId}/view`
                                )
                            }
                        >
                            <Eye className="align-middle" size={18} />
                        </Button>
                    </OverlayTrigger>
                    {module === "quotations" && (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    Manage delivery order
                                </Tooltip>
                            }
                        >
                            <Button
                                variant="outline-primary"
                                className="me-1 mb-1"
                                onClick={() =>
                                    navigate(
                                        `/intellidocs/process/${module}/${projectId}/${cellId}/do`
                                    )
                                }
                            >
                                <Box className="align-middle" size={18} />
                            </Button>
                        </OverlayTrigger>
                    )}
                    {module === "revenues" && (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    Manage received payment
                                </Tooltip>
                            }
                        >
                            <Button
                                variant="outline-primary"
                                className="me-1 mb-1"
                                onClick={() =>
                                    navigate(
                                        `/intellidocs/process/${module}/${projectId}/${cellId}/do`
                                    )
                                }
                            >
                                <Box className="align-middle" size={18} />
                            </Button>
                        </OverlayTrigger>
                    )}
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                Delete {module.slice(0, -1)}
                            </Tooltip>
                        }
                    >
                        <Button
                            variant="outline-primary"
                            className="me-1 mb-1"
                            onClick={() => openDeleteModal(cellId)}
                        >
                            <Trash className="align-middle" size={18} />
                        </Button>
                    </OverlayTrigger>
                </>
            )}
        </>
    );
};
