import React, { useCallback, useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Container, Alert, Button, Col, Form, Row, Badge } from "react-bootstrap";
import { tableColumns } from "./EnrolmentAdjustment/Data.js";
import { SnackbarAlert } from "../../../components/SnackbarAlert.js";
import api from "../../../service/api.js";
import { useSelector } from "react-redux";
import { Search } from "react-feather";
import { Lists } from "./EnrolmentAdjustment/ListEnrolmentAdjustment.js";
import { DatePicker } from "antd";
import moment from "moment";
import { UserDateFormat } from "../../../utils/dateFormat.js";
import { minSelectableYear, maxYearRange } from "../../../utils/EnrolmentSummaryConstants.js";
import {
    faFileExcel,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import download from "downloadjs";




const EnrolmentAdjustment = ({ status, title }) => {
    /** Permissions */
    const permissionData = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = permissionData?.data?.permissions;

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [defaultConfig, setDefaultConfig] = useState();
    const { state } = useLocation();
    const previousSearchValue = state?.previousSearchValue
        ? state?.previousSearchValue
        : null;
    const [rerender, setRerender] = useState();

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const [toDatePickerValue, setToDatePickerValue] = useState(moment().format("YYYY"));
    const [fromDatePickerValue, setFromDatePickerValue] = useState(moment().format("YYYY"));
    const [searchButtonStatus, setSearchButtonStatusValue] = useState(true);
    const [hiddenSubgroups, setHiddenSubgroups] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const searchFields = {
        start_date: toDatePickerValue,
        end_date: fromDatePickerValue,
    };
    const [searchValue, setSearchValue] = useState(
        previousSearchValue ? previousSearchValue : searchFields
    );
    const search = (page) => {
        const searchParam = { ...searchValue, ...page };
        setSearchValue(searchParam);
        getEnrolments(searchParam);
    };
    const resetSearch = () => {
        setSearchValue(searchFields);
        getEnrolments(searchFields);
    };
    
    const getEnrolments = useCallback(async (search) => {
        setLoading(true);
        try {
            const range = getYearsOutsideRange(search.start_date, search.end_date);
            setHiddenSubgroups(range);

            const response = await api.get("tms/enrolments/get-adjustment", search);
            // console.log("response data:", response.data)
            setData(response);
            setError();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error.message);
            setData([]);
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        getEnrolments(searchValue);
    }, [getEnrolments, rerender]); //  eslint-disable-line react-hooks/exhaustive-deps
    
    const getConfig = useCallback(async () => {
        const configResponse = await api.get(`tms/enrolments/config`, {});
        setDefaultConfig(configResponse.data);
    }, []);

    useEffect(() => {
        getConfig();
    }, [getConfig]); //  eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state?.open) {
            setNotif({
                notifMsg: state.notifMsg,
                open: state.open,
                severity: state.severity,
            });
        }
        window.history.replaceState(null, "");
    }, [state]); //  eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSearchButtonStatusValue((moment(toDatePickerValue).diff(moment(fromDatePickerValue), 'years') <= (maxYearRange-1) && moment(fromDatePickerValue).isSameOrBefore(moment(toDatePickerValue))));
    }, [toDatePickerValue, fromDatePickerValue]);

    function getYearsOutsideRange(startYear, endYear) {
        const currentYear = new Date().getFullYear();
        const allYears = Array.from({ length: currentYear - (minSelectableYear-1) }, (_, index) => (minSelectableYear + index).toString());
      
        // Filter out years between startYear and endYear
        const yearsOutsideRange = allYears.filter(year => year < startYear || year > endYear);
      
        return yearsOutsideRange;
      }


    function handleToDateChange(date){
        const searchParam = { ...searchValue,  end_date: date};
        setSearchValue(searchParam);
        setToDatePickerValue(date);
    }

    function handleFromDateChange(date){
        const searchParam = { ...searchValue,  start_date: date};
        setSearchValue(searchParam);
        setFromDatePickerValue(date);
    }

    const generateEnrolmentStatisticReports = async (search) => {
        setIsSubmitting(true);
        try {
            const response = await api.getexcel(
                "tms/enrolments/export-enrolment-adjustment",
                search
            );

            if (response.type.includes('application/json')) {
                const jsonData = JSON.parse(await response.text());
                // console.log(jsonData);
                if (jsonData.success){
                    setNotif({
                        notifMsg: jsonData.message,
                        open: true,
                        severity: "success",
                    });
                }
                else{
                    setNotif({
                        notifMsg: jsonData.message,
                        open: true,
                        severity: "danger",
                    });
                }
            }else if (response.size !== 0) {
                console.log(response);
                download(response, "Enrolment_adjustment_"+search.start_date+"_to_"+search.end_date, "application/xlsx");
            } else {
                setError("No data to be exported.");
            }
        } catch (error) {
            setError(error.message);
        }
        setIsSubmitting(false);
    };

    return (
        <React.Fragment>
            <Helmet title="Statistic > Enrolment adjustment" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Enrolment adjustment</h1>
                <Card>
                    <Card.Header>
                        {error && (
                            <Alert className="my-3" variant="danger">
                                <div className="alert-message">{error}</div>
                            </Alert>
                        )}

                        <Col md={6}>
                            <Row className="d-flex align-items-center justify-content-start">
                                <Col md={3} className="pe-md-0">
                                    <Form.Group>
                                        <DatePicker
                                            id="from-date-picker"
                                            name="from_date"
                                            className={`form-control`}
                                            disabledDate={d => !d || moment(d).isAfter(moment()) || moment(d).isBefore(`${minSelectableYear}`)}
                                            picker="year"
                                            placeholder="From date"
                                            defaultValue={moment()}
                                            // allowClear={false}
                                            onChange={(date) => {
                                                handleFromDateChange(moment(date).format("YYYY"));
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={1} className="m-0 p-0 d-flex align-items-center justify-content-center">
                                    <Badge bg='info' className="text-center d-flex align-items-center justify-content-center w-100 h-100 mx-2 mx-md-0" style={{ minHeight: '2rem' }}>
                                        To
                                    </Badge>
                                </Col>
                                <Col md={3} className="ps-md-0">
                                    <Form.Group >
                                        <DatePicker
                                            id="to-date-picker"
                                            name="to_date"
                                            disabledDate={d => !d || moment(d).isAfter(moment(fromDatePickerValue).add(maxYearRange, 'years')) || moment(d).isBefore(moment(fromDatePickerValue).subtract(maxYearRange, 'years')) || moment(d).isBefore(`${minSelectableYear}`) || moment(d).isAfter(moment())}
                                            picker="year"
                                            className={`form-control`}
                                            placeholder="To date"
                                            defaultValue={moment()}
                                            // allowClear={false}
                                            onChange={(date) => {
                                                handleToDateChange(moment(date).format("YYYY"));
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={2} className="d-flex align-items-center justify-content-center justify-content-md-start mt-2 mt-md-0">
                                    <Button
                                        className="float-start me-1"
                                        variant="info"
                                        type="submit"
                                        size="lg"
                                        onClick={() => search()}
                                        disabled={!searchButtonStatus}
                                    >
                                        <Search className="feather" />
                                    </Button>

                                    {!isSubmitting && (
                                        <Button
                                            className="float-end"
                                            variant="info"
                                            type="submit"
                                            size="lg"
                                            onClick={() =>
                                                generateEnrolmentStatisticReports(searchValue)
                                            }
                                            disabled={!searchButtonStatus}
                                        >
                                            <FontAwesomeIcon
                                                icon={faFileExcel}
                                            />
                                        </Button>
                                    )}

                                    {isSubmitting && (
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Col>

                    </Card.Header>
                    <Card.Body>
                        {loading && <div>Loading...</div>}
                        {/* {JSON.stringify(data) } */}
                        {!loading && data?.data && (
                            <><Lists
                                columns={tableColumns}
                                rawData={data}
                                enrolmentApi={search}
                                status={status}
                                searchValue={searchValue}
                                rerender={rerender}
                                setRerender={setRerender}
                                outsideYearRange={hiddenSubgroups}
                            />
                            </>
                        )}
                    </Card.Body>
                </Card>
            </Container>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </React.Fragment>
    );
};

export default EnrolmentAdjustment;
