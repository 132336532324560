import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Card,
    Container,
    Row,
    Col,
    Form,
    Alert,
} from "react-bootstrap";

import { Lists } from "./List";

import { tableColumns } from "./data.js";
import { SnackbarAlert } from "../../../components/SnackbarAlert";
import api from "../../../service/api";
import { useSelector } from "react-redux";
import { Plus } from "react-feather";
import { pressEnterToSearch } from "../../../utils/utility";

const Companies = () => {
    /** Permissions */
    const { data } = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = data?.permissions;
    /** Permissions */
    const navigate = useNavigate();
    const [companies, setCompanies] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    // const { getCompanyAll } = useActions();
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const [searchValue, setSearchValue] = useState("");
    const getCompanyAll = async (search) => {
        try {
            setLoading(true);
            const response = await api.get("tms/companies", {
                ...search,
            });
            setCompanies(response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error?.response?.data?.message);
        }
    };

    const getCompaniesData = useCallback((search) => {
        getCompanyAll(search);
    }, []); //  eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getCompanyAll();
    }, []); //  eslint-disable-line react-hooks/exhaustive-deps

    const searchCompany = () => {
        getCompanyAll({
            search: searchValue,
        });
    };
    // const { data, error, loading } = useSelector((state) => state.companies);

    return (
        <React.Fragment>
            <Helmet title="Configuration > Company" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Companies</h1>
                <Card>
                    <Card.Header>
                        {error && (
                            <Alert className="my-3" variant="danger">
                                <div className="alert-message">{error}</div>
                            </Alert>
                        )}
                        <Row>
                            <Col md={6}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Control
                                            className="d-inline-block"
                                            type="text"
                                            value={searchValue}
                                            onChange={(e) =>
                                                setSearchValue(e.target.value)
                                            }
                                            onKeyDown={(e) => pressEnterToSearch(e, searchCompany)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Button
                                            variant="success"
                                            className="me-1 mb-1"
                                            onClick={searchCompany}
                                        >
                                            Search
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            {permission?.includes("COMPANY-CREATE") && (
                                <Col md={6}>
                                    <Button
                                        variant="success"
                                        className="me-1 float-end"
                                        onClick={() =>
                                            navigate("/tms/companies/add")
                                        }
                                    >
                                        <Plus size={15} /> Add
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        {loading && <div>Loading...</div>}
                        {companies && companies.data && !loading && (
                            <Lists
                                columns={tableColumns}
                                rawData={companies}
                                companyApi={getCompaniesData}
                                searchValue={searchValue} 
                            />
                        )}
                    </Card.Body>
                </Card>
                <SnackbarAlert notif={notif} setNotif={setNotif} />
            </Container>
        </React.Fragment>
    );
};

export default Companies;
