import React, { useState, useEffect, useRef } from "react";
import {
    useTable,
    usePagination,
    useSortBy,
    useBlockLayout,
} from "react-table";
import {
    Table,
    Row,
    Col,
    Alert,
    Pagination,
    Button,
    Form,
    Card,
} from "react-bootstrap";
import api from "../../../service/api";
import { DeleteModal } from "../../../components/DeleteModal";
import { SnackbarAlert } from "../../../components/SnackbarAlert";
import ListButton from "./ListButton";
import { WithdrawModal } from "../../../components/WithdrawModal";
import {
    faFileExcel,
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker } from "antd";
import moment from "moment";
import { UserDateFormat } from "../../../utils/dateFormat";
import download from "downloadjs";
import { useSelector } from "react-redux";
import { useSticky } from "react-table-sticky";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { scrollButtonFunction } from "../../../utils/utility";
import DoubleScrollbar from "react-double-scrollbar";
import { ReEnrollModal } from "../../../components/ReEnrollModal";
import { AsyncPaginate } from "react-select-async-paginate";

const Lists = ({
    columns,
    rawData,
    enrolmentApi,
    status,
    searchValue,
    setRerender,
    rerender,
}) => {
    /** Permissions */
    const permissionData = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = permissionData?.data?.permissions;
    const params = useParams();

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });

    const [modalWithdraw, setModalWithdraw] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
        fromSummary: false,
    });

    const [modalReEnroll, setModalReEnroll] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });

    const [hasMore, setHasMore] = useState(true);

    const handleLoadMore = async (search, { page }) => {
        if (!search) {
            return {
                options: [],
                hasMore: false,
            };
        }

        const response = await api.get("tms/companies-options", {
            search: search,
            page: page,
        });

        const { options: newOptions, hasMore: newHasMore } = response;

        setHasMore(newHasMore);

        return {
            options: newOptions,
            hasMore: newHasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    const [loading, setLoading] = useState(true);
    const [exportSearchValue, setExportSearchValue] = useState({
        company: '',
        from_date: '',
        to_date: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { meta, links, data } = rawData;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: {
            pageSize,
            canNextPage,
            canPreviousPage,
            totalPage,
            currentPage,
            firstPage,
            lastPage,
        },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: meta?.per_page,
                canNextPage: links?.next !== null,
                canPreviousPage: links?.prev !== null,
                totalPage: meta?.last_page,
                currentPage: meta?.current_page,
                firstPage: meta?.first,
                lastPage: meta?.last_page,
            },
        },
        useSortBy,
        usePagination,
        useBlockLayout,
        useSticky
    );

    const gotoPage = (page) => {
        enrolmentApi({
            page,
        });
    };

    const deleteCompany = async (id) => {
        try {
            await api.delete(`tms/enrolments/${id}`, {});
            setNotif({
                id: id,
                notifMsg: "Deleted successfully",
                open: true,
                severity: "success",
            });
            enrolmentApi();
        } catch (error) {
            setNotif({
                id: id,
                notifMsg: error?.response?.data?.message,
                open: true,
                severity: "danger",
            });
        }
    };

    const withdrawEnrolment = async (id, data, fromSummary) => {
        try {
            await api.post(`tms/enrolments/withdrawal/${id}/${fromSummary}`, data);
            setNotif({
                id: id,
                notifMsg: "Withdrawal successfully",
                open: true,
                severity: "success",
            });
            setRerender(!rerender);
        } catch (error) {
            setNotif({
                id: id,
                notifMsg: error?.response?.data?.message,
                open: true,
                severity: "danger",
            });
        }
    };

    const reEnrollEnrolment = async (id, data) => {
        try {
            await api.post(`tms/enrolments/enroll_student/${id}`, data);
            setNotif({
                id: id,
                notifMsg: "Enrolled successfully",
                open: true,
                severity: "success",
            });
            setRerender(!rerender);
        } catch (error) {
            setNotif({
                id: id,
                notifMsg: error?.response?.data?.message,
                open: true,
                severity: "danger",
            });
        }
    };

    const openDeleteModal = (id) => {
        setModalInfo({
            id: id,
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            severity: "danger",
        });
    };

    const openWithdrawModal = (id, fromSummary) => {
        setModalWithdraw({
            id: id,
            notifMsg: "",
            open: true,
            severity: "danger",
            fromSummary: fromSummary
        });
    };

    const openReEnrollModal = (id) => {
        setModalReEnroll({
            id: id,
            notifMsg: "Are you sure you want to enroll this item?",
            open: true,
            severity: "danger",
        });
    };

    const [error, setError] = useState();

    const generateEnrolmentReports = async () => {
        setIsSubmitting(true);
        try {
            const response = await api.getexcel(
                "tms/export-excel-enrolment",
                exportSearchValue
            );
            if (response.type.includes('application/json')) {
                const jsonData = JSON.parse(await response.text());
                // console.log(jsonData);
                if (jsonData.success){
                    setNotif({
                        notifMsg: jsonData.message,
                        open: true,
                        severity: "success",
                    });
                }
                else{
                    setNotif({
                        notifMsg: jsonData.message,
                        open: true,
                        severity: "danger",
                    });
                }
                setError(null)
            }else if (response.size !== 0) {
                let currentDate = new Date();
                let formattedDate = currentDate.toISOString().replace(/[-T:]/g, "_").slice(0, -5); // Format the date as YYYY_MM_DD_HH_mm_ss
                download(response, "enrolment_report_"+formattedDate, "application/xlsx");
                setError(null)
            } else {
                setError("No data to be exported.");
            }
        } catch (error) {
            setError(error?.response?.data?.message);
        }
        setLoading(false);
        setIsSubmitting(false);
    };

    const DateFormat = UserDateFormat();

    const Styles = styled.div`
        .table {
            .th,
            .td {
                background-color: #fff;
                overflow: hidden;
                border-top: none;
            }

            &.sticky {
                overflow: scroll;
                .header,
                .footer {
                    position: sticky;
                    z-index: 1;
                    width: fit-content;
                }
            }
        }
        .table > :not(:first-child) {
            border-top: none;
        }
    `;

    return (
        <>
            {data.length > 0 && (
                <>
                    <Row className="fixed-header">
                        {error && (
                            <Card.Header>
                                <Alert className="my-3" variant="danger">
                                    <div className="alert-message">{error}</div>
                                </Alert>
                            </Card.Header>
                        )}
                        <Col md={
                                status === "summary" &&
                                permission?.includes(
                                    "ENROLMENT-REPORT-SUMMARY"
                                )
                                    ? 6
                                    : (totalPage > 1 ? 6 : 12)
                            }
                            xs={12}>
                            <div className="scroll-buttons d-flex justify-content-center justify-content-md-start my-3">
                                <Button
                                    onClick={scrollButtonFunction('.table', false, "auto") }
                                    variant="secondary"
                                    size="md"
                                >
                                    <FontAwesomeIcon
                                        icon={faArrowAltCircleLeft}
                                    />
                                </Button>
                                <Button
                                    variant="secondary"
                                    size="md"
                                    onClick={scrollButtonFunction('.table', true,  "auto") }
                                    className="mx-3 info"
                                >
                                    <FontAwesomeIcon
                                        icon={faArrowAltCircleRight}
                                    />
                                </Button>
                            </div>
                        </Col>
                        {totalPage > 1 && (
                            <Col
                                md={
                                    status === "summary" &&
                                    permission?.includes(
                                        "ENROLMENT-REPORT-SUMMARY"
                                    )
                                        ? 6
                                        : 6
                                }
                                xs={12}
                                className="d-flex justify-content-center justify-content-md-end"
                            >
                                <Pagination className="float-end">
                                    <Pagination.First
                                        onClick={() =>
                                            gotoPage(firstPage)
                                        }
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPage(currentPage - 1)
                                        }
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPage(currentPage + 1)
                                        }
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() =>
                                            gotoPage(lastPage)
                                        }
                                        disabled={!canNextPage}
                                    />
                                </Pagination>
                                <span className="mx-2 float-end">
                                    Page {""}
                                    <strong>
                                        {currentPage} of {totalPage}
                                    </strong>
                                </span>
                            </Col>
                        )}
                        {totalPage > 1 && (
                            <Col md={12}>
                                <Row>
                                    {(status === "summary" || status === 'report') &&
                                        permission?.includes(
                                            "ENROLMENT-REPORT-SUMMARY"
                                        ) && (
                                            <>
                                                <Col md={3} className="my-md-0 my-3">
                                                    <AsyncPaginate
                                                        isClearable
                                                        loadOptions={handleLoadMore}
                                                        hasMore={hasMore}
                                                        placeholder="Search company"
                                                        additional={{
                                                            page: 1,
                                                        }}
                                                        onChange={(value) => {
                                                            setExportSearchValue({
                                                                ...exportSearchValue,
                                                                company: value
                                                                    ? value.map(item => item.value) : "",
                                                            });
                                                        }}
                                                        styles={{
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                zIndex: 9999,
                                                            }),
                                                        }}
                                                        isMulti
                                                        debounceTimeout={1000}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group className="mb-3">
                                                        <DatePicker
                                                            name="from_date"
                                                            selected={
                                                                new Date(
                                                                    moment(
                                                                        DateFormat?.toUpperCase()
                                                                    ).format(
                                                                        "YYYY-MM-DD"
                                                                    )
                                                                )
                                                            }
                                                            className={`form-control`}
                                                            onChange={(
                                                                dates
                                                            ) => {
                                                                setExportSearchValue({
                                                                    ...exportSearchValue,
                                                                    from_date:
                                                                        dates ===
                                                                        null
                                                                            ? null
                                                                            : moment(
                                                                                  dates
                                                                              ).format(
                                                                                  "YYYY-MM-DD"
                                                                              ),
                                                                });
                                                            }}
                                                            dateFormat={
                                                                DateFormat
                                                            }
                                                            placeholder="From date"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group className="mb-3">
                                                        <DatePicker
                                                            name="to_date"
                                                            selected={
                                                                new Date(
                                                                    moment(
                                                                        DateFormat?.toUpperCase()
                                                                    ).format(
                                                                        "YYYY-MM-DD"
                                                                    )
                                                                )
                                                            }
                                                            className={`form-control`}
                                                            onChange={(
                                                                dates
                                                            ) => {
                                                                setExportSearchValue({
                                                                    ...exportSearchValue,
                                                                    to_date:
                                                                        dates ===
                                                                        null
                                                                            ? null
                                                                            : moment(
                                                                                  dates
                                                                              ).format(
                                                                                  "YYYY-MM-DD"
                                                                              ),
                                                                });
                                                            }}
                                                            dateFormat={
                                                                DateFormat
                                                            }
                                                            placeholder="To date"
                                                            maxDate={moment().toDate()}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    {!isSubmitting && (
                                                        <Button
                                                            className="float-end"
                                                            variant="info"
                                                            type="submit"
                                                            size="lg"
                                                            onClick={() =>
                                                                generateEnrolmentReports()
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faFileExcel}
                                                            />
                                                        </Button>
                                                    )}

                                                    {isSubmitting && (
                                                        <div class="spinner-border float-end" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                    )}
                                                    
                                                </Col>
                                            </>
                                        )}
                                </Row>
                            </Col>
                        )}
                    </Row>

                    <Styles>
                        <div className="table-responsive">
                            <DoubleScrollbar>
                            <br></br>
                            <Table
                                striped
                                bordered
                                {...getTableProps()}
                                className="table table-bordered table-layout-fixed table-striped table-to-scroll"
                            >
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            {...headerGroup.getHeaderGroupProps()}
                                            className="tr"
                                        >
                                            {headerGroup.headers.map(
                                                (column) => (
                                                    <th
                                                        width={column.width}
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                        className="th"
                                                    >
                                                        {column.render(
                                                            "Header"
                                                        )}
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody
                                    {...getTableBodyProps()}
                                    className="body"
                                >
                                    {page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr
                                                {...row.getRowProps()}
                                                className="tr"
                                            >
                                                {row.cells.map(
                                                    (cell, index, row) => {
                                                        if (index === 0) {
                                                            return (
                                                                <td
                                                                    {...cell.getCellProps()}
                                                                    key={index}
                                                                    className="td"
                                                                >
                                                                    {currentPage >
                                                                    1
                                                                        ? (currentPage -
                                                                              1) *
                                                                              pageSize +
                                                                          i +
                                                                          1
                                                                        : i + 1}
                                                                </td>
                                                            );
                                                        }
                                                        if (index === 1) {
                                                            return (
                                                                <td
                                                                    {...cell.getCellProps()}
                                                                    key={index}
                                                                    className="td"
                                                                >
                                                                    <ListButton
                                                                        status={
                                                                            status
                                                                        }
                                                                        id={
                                                                            cell
                                                                                .row
                                                                                .original
                                                                                .id
                                                                        }
                                                                        openDeleteModal={
                                                                            openDeleteModal
                                                                        }
                                                                        openWithdrawModal={
                                                                            openWithdrawModal
                                                                        }
                                                                        openReEnrollModal={
                                                                            openReEnrollModal
                                                                        }
                                                                        cell={
                                                                            cell
                                                                        }
                                                                        searchValue={
                                                                            searchValue
                                                                        }
                                                                        enrollStudent={
                                                                            reEnrollEnrolment
                                                                        }
                                                                    />
                                                                </td>
                                                            );
                                                        }
                                                        return (
                                                            <td
                                                                {...cell.getCellProps()}
                                                                className="td"
                                                            >
                                                                {cell.render(
                                                                    "Cell"
                                                                )}
                                                            </td>
                                                        );
                                                    }
                                                )}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            </DoubleScrollbar>
                        </div>
                    </Styles>
                </>
            )}

            {data.length === 0 && (
                <Alert variant="warning">
                    <div className="alert-message">No enrolment</div>
                </Alert>
            )}
            <DeleteModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={deleteCompany}
            />
            <WithdrawModal
                modalInfo={modalWithdraw}
                setModalInfo={setModalWithdraw}
                api={withdrawEnrolment}
            />
            <ReEnrollModal
                modalInfo={modalReEnroll}
                setModalInfo={setModalReEnroll}
                api={reEnrollEnrolment}
            />
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export { Lists };
