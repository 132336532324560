import React, { useEffect, useState } from "react";

import { Badge, Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";

import { AutoGrowTextareaV2 } from "../../../components/version-two-components/AutoGrowTextareaV2";
import { ErrorMessage } from "@hookform/error-message";
import { SnackbarAlert } from "../../../components/SnackbarAlert";
import api from "../../../service/api";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

export const SendEmailResultPreviewModal = ({ modalInfo, setModalInfo }) => {
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const schema = yup.object().shape({
        to: yup
            .array()
            .min(1, "This field is required")
            .required("This field is required")
            .nullable(),
        subject_result: yup.string().required("This field is required"),
        preview_result_temp: yup.string().required("This field is required"),
    });

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    //
    // States
    //
    const [sendTo, setSendTo] = useState({});
    const [sendTocc, setSendTocc] = useState({});
    const [sentFrom, setsentFrom] = useState({});
    const [loading, setLoading] = useState(false);

    //
    // Functions
    //
    const closeModal = () => {
        setModalInfo({
            ...modalInfo,
            open: false,
        });
        reset({});
    };

    const sendEmail = async (data) => {
        try {
            setLoading(!loading);

            const selectedStudents = modalInfo?.selectedRows.map(
                (d) => d?.enrolment?.id
            );

            let toClone = [...data?.to];
            let ccClone = [...data?.cc];
            const toData = toClone?.map((d) => d?.value);
            const ccData = ccClone?.map((d) => d?.value);

            data.enrollments = selectedStudents;
            data.batch_id = modalInfo?.data?.batch_id;
            data.self_registration = modalInfo?.data?.self_registration;
            data.sponsor_company_id = modalInfo?.data?.sponsor_company_id;
            data.to = toData;
            data.cc = ccData;

            const response = await api.post(
                `tms/enrollment-emails/send-email-result`,
                data
            );

            if (response.status === "ok") {
                setLoading(!loading);
                setNotif({
                    notifMsg: "Send email successful",
                    open: true,
                    severity: "success",
                });
                closeModal();
            }
        } catch (error) {}
    };

    const formatSender = (data) => {
        let temp = {};

        temp = data?.map((item) => {
            return {
                value: item?.value,
                label: `${item?.label} (${item?.value})`,
            };
        });

        return temp;
    };

    //
    // UseEffects
    //

    useEffect(() => {
        const data = modalInfo?.data;

        setSendTo(formatSender(data?.send_to));
        setSendTocc(formatSender(data?.send_cc));
        setsentFrom(formatSender(data?.send_from));

        reset({
            from: data?.send_from ? data?.send_from[0] : "",
            to: "",
            subject_result: data?.subject_result || "",
            cc: "",
            preview_result_temp: data?.preview_result_temp || "",
        });
    }, [modalInfo?.data]);

    // const [file, setFile] = useState();
    

    // function fileChange(event) {
    //     setFile(event.target.files[0])
    //     console.log(event.target.files[0]);
    // }

    // function fileSubmit(event) {
    //     if (!file) {
    //         alert("Please add attachment");
    //         return;
    //     }else {
    //         const data = new FormData();
    //         data.append('file', file);
    //         data.append('fileName', file.name);
    //         data.append('student_id', event);
            
    //         const response = api.postUpload(
    //             `tms/enrolments/results-attachment`,
    //             data
    //         );
    //         if (response.status === "ok") {
    //             setLoading(!loading);
    //             setNotif({
    //                 notifMsg: "Attachment successfully uploaded",
    //                 open: true,
    //                 severity: "success",
    //             });
    //         }
    //     }
    // }
    
    return (
        <>
            <Modal
                show={modalInfo.open}
                onHide={() => closeModal()}
                size="xl"
                centered
            >
                <Modal.Header closeButton>Preview email result</Modal.Header>
                <Modal.Body className="my-2">
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="taskName"
                                    className="font-weight-bold"
                                >
                                    From
                                </Form.Label>

                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="from"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <Select
                                            classNamePrefix="react-select"
                                            options={sentFrom}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="taskName"
                                    className="font-weight-bold"
                                >
                                    To
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="to"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <Select
                                            classNamePrefix="react-select"
                                            options={sendTo}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="to"
                                    render={({ message }) => (
                                        <small className="text-danger">
                                            {message}
                                        </small>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="taskName"
                                    className="font-weight-bold"
                                >
                                    cc
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="cc"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <Select
                                            classNamePrefix="react-select"
                                            options={sendTocc}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>

                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="taskName"
                                    className="font-weight-bold"
                                >
                                    Subject
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="subject_result"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <Form.Control
                                            type="text"
                                            value={value || ""}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="subject_result"
                                    render={({ message }) => (
                                        <small className="text-danger">
                                            {message}
                                        </small>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="taskName"
                                    className="font-weight-bold"
                                >
                                    Introduction message
                                </Form.Label>

                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="preview_result_temp"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <AutoGrowTextareaV2
                                            fieldValue={value}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            className="new-line"
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="preview_result_temp"
                                    render={({ message }) => (
                                        <small className="text-danger">
                                            {message}
                                        </small>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        {modalInfo.selectedRows && (
                            <Col>
                                <Form.Label
                                    htmlFor="taskName"
                                    className="font-weight-bold"
                                >
                                    Students
                                </Form.Label>

                                <Table bordered responsive striped>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Name</th>
                                            <th>Trade Level</th>
                                            <th>Result</th>
                                            <th>File upload</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {modalInfo.selectedRows.map(
                                            (data, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{key + 1}</td>
                                                        <td>
                                                            {
                                                                data?.enrolment
                                                                    ?.name
                                                            }
                                                        </td>
                                                        <td>
                                                            {data?.enrolment?.type_of_trade || null}
                                                        </td>
                                                        <td>
                                                            {data?.enrolment
                                                                ?.result}
                                                        </td>
                                                        <td>
                                                        {data?.enrolment?.result_attachment ? (
                                                            <a href={data?.enrolment?.result_attachment} target="_blank" rel="noreferrer">
                                                            <FontAwesomeIcon icon={faFileExcel}/>
                                                            </a>
                                                        ) : ""}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        )}
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="taskName"
                                    className="font-weight-bold"
                                >
                                    Body message
                                </Form.Label>
                                <div>
                                        <Table bordered className="mb-4" size="sm">
                                        <thead>
                                            <tr>
                                                <th>Test Result-(Passed)</th>
                                                <th>Re-test procedures (Failed/NC)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <b><u>Coretrade:</u></b>
                                                    </p>
                                                    <p>
                                                        Passed candidates can print the Statement of Registration at www.bca.gov.sg/CWRS
                                                    </p>
                                                    <p>
                                                        <b><u>SEC(K)/Validation Test:</u></b>
                                                    </p>
                                                    <p>
                                                        SEC(K)/Validation Test Certificate's are only issued in ecopy by BCA. A copy will be forwarded to your registered email upon issued by BCA (need not call ATTC to enquire)
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        You are strongly advised to submit your retest application as soon as possible, while your memory of the trade is still fresh in your mind.
                                                    </p>
                                                    <p>
                                                        All retest applications are to be completed with the following documents,
                                                    </p>
                                                    <p>
                                                        1) Application Form duly filled<br />
                                                        2) Valid Acknowledgment Notice (if applicable)<br />
                                                        3) Clear Work Permit Copy<br />
                                                        4) Clear Passport Copy<br />
                                                        5) MOM Foreign Worker Details Printout.
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table><br />
                                    * Candidate(s) who passed the HEO & BMPO test are advised to retain a copy of the result slip (to be used as a proof of the trade that you are tested in).<br />
                                    * Candidate(s) who failed the test may request for an email copy of the result slip and weakness report.<br />
                                    * No hardcopy result slips or certificates will be issued to all test candidates.
                                    <br /> <br />
                                    Thank you & have a good day! <br /> <br />
                                    Best Regards, <br /> <br />
                                    Fonda Global Engineering Pte Ltd
                                    <br />
                                    <span className="text_danger">
                                        (BCA Approved Training and Testing
                                        Centre)
                                    </span>
                                    <br />
                                    No. 45 Sungei Kadut Loop Singapore 729495
                                    <br />
                                    Tel: 6515 5775
                                    <br />
                                    Fax: 6278 7359
                                    <br />
                                    Web: <a href="http://www.fondacoretrade.com.sg">www.fondacoretrade.com.sg</a>
                                    <br />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col md={12} className="text-center">
                            <Button
                                className="me-2"
                                variant="primary"
                                type="submit"
                                onClick={handleSubmit(sendEmail)}
                            >
                                Send
                            </Button>
                            <Button variant="link" onClick={() => closeModal()}>
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};
