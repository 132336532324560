import React, { useState } from "react";

import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import * as Yup from "yup";

export const AddEditBudget = ({
    errors,
    getValues,
    register,
    setTrends,
    setYupContractValue,
    setYupWorkingBudget,
    trends,
    yupContractValue,
    yupWorkingBudget,
    storeLockValue,
}) => {
    //
    // States
    //

    const [newTrend, setNewTrend] = useState("");
    const contractValues = getValues("contractValue");
    const lockBudgetValue = getValues("lockBudget");
    const workingBudgetValues = getValues("workingBudget");

    //
    // Functions
    //

    const addFields = () => {
        const newTrendCased = _.camelCase(newTrend);
        let newfield = {
            id: newTrendCased,
            trend: newTrend,
            contract_value: "",
            working_budget: "",
        };

        let newTrendValidation = { [newTrendCased]: Yup.string().required() };

        setYupContractValue({ ...yupContractValue, ...newTrendValidation });
        setYupWorkingBudget({ ...yupWorkingBudget, ...newTrendValidation });
        setTrends([...trends, newfield]);
        setNewTrend("");
    };

    const removeTrend = (index) => {
        let data = [...trends];
        data.splice(index, 1);

        setTrends([...data]);
    };

    return (
        <Card>
            <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                    Budget
                </Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
                <Row className="mb-2">
                    <Col md={3}>Trend *</Col>
                    <Col className="text-end" md={4}>
                        Contract value *
                    </Col>
                    <Col className="text-end" md={4}>
                        Working budget *
                    </Col>
                    <Col md={1}></Col>
                </Row>
                <hr />
                {trends.map((data, index) => {
                    return !storeLockValue ? (
                        <Row key={index}>
                            <Col md={3}>{data.trend}</Col>
                            <Col className="text-end" md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        className="text-end"
                                        type="number"
                                        id={`${data.id}ContractValue`}
                                        isInvalid={Boolean(
                                            errors?.contractValue &&
                                                errors?.contractValue[data.id]
                                        )}
                                        {...register(
                                            `contractValue.${data.id}`
                                        )}
                                    />
                                    {errors?.contractValue &&
                                        errors?.contractValue[data.id] && (
                                            <Form.Control.Feedback type="invalid">
                                                {data.trend} is a required field
                                            </Form.Control.Feedback>
                                        )}
                                </Form.Group>
                            </Col>
                            <Col className="text-end" md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        className="text-end"
                                        type="number"
                                        id={`${data.id}WorkingBudget`}
                                        isInvalid={Boolean(
                                            errors?.workingBudget &&
                                                errors?.workingBudget[data.id]
                                        )}
                                        {...register(
                                            `workingBudget.${data.id}`
                                        )}
                                    />
                                    {errors?.workingBudget &&
                                        errors?.workingBudget[data.id] && (
                                            <Form.Control.Feedback type="invalid">
                                                {data.trend} is a required field
                                            </Form.Control.Feedback>
                                        )}
                                </Form.Group>
                            </Col>
                            <Col md={1}>
                                <Button
                                    variant="primary"
                                    onClick={() => removeTrend(index)}
                                >
                                    <FontAwesomeIcon icon={faWindowClose} />
                                </Button>
                            </Col>
                        </Row>
                    ) : (
                        <Row key={index}>
                            <Col className="mb-2" md={3}>
                                {data.trend}
                            </Col>
                            <Col className="mb-2 text-end" md={4}>
                                {contractValues[data.id]}
                            </Col>
                            <Col className="mb-2 text-end" md={4}>
                                {workingBudgetValues[data.id]}
                            </Col>
                            <Col className="mb-2" md={1}></Col>
                        </Row>
                    );
                })}
                <Row>
                    <Col md={12}>
                        <small>* Required fields</small>
                    </Col>
                </Row>
                {!storeLockValue && (
                    <Row className="mt-2">
                        <Col md={7}>
                            <Form.Check
                                type="switch"
                                id="lockBudget"
                                label="Lock budget"
                                {...register("lockBudget")}
                            />
                            <small>
                                <i>
                                    Onced saved, budget will be locked
                                    permanently
                                </i>
                            </small>
                        </Col>
                        <Col md={5} className="d-flex justify-content-end">
                            <>
                                <Form.Control
                                    type="text"
                                    id="checkbox"
                                    className="d-inline me-2 w-50"
                                    onChange={(event) =>
                                        setNewTrend(event.target.value)
                                    }
                                    value={newTrend}
                                />
                                <Button
                                    variant="secondary"
                                    className="m-0"
                                    onClick={addFields}
                                    disabled={!newTrend}
                                >
                                    <FontAwesomeIcon icon={faPlusSquare} /> Add
                                    new trend
                                </Button>
                            </>
                        </Col>
                    </Row>
                )}
            </Card.Body>
        </Card>
    );
};
