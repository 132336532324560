import React from "react";

import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Edit, Eye, FileMinus, Trash, Slash, RotateCw } from "react-feather";

const ListButton = ({ status, id, openDeleteModal, cell, searchValue, openWithdrawModal, openReEnrollModal }) => {
    const { data } = useSelector((state) => {
        return state.user ? state.user : state;
    });

    const permission = data?.permissions;
    const navigate = useNavigate();
    return (
        <>
            {(status === "pending" || status === "summary" || status === "pending-confirmation") && (
                <>
                    {permission?.includes("ENROLLMENT-EDIT") &&
                        (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Edit</Tooltip>}
                            >
                                <a
                                    href={`/tms/enrolments/${id}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="me-1"
                                >
                                    <span>
                                        <Edit size="20" />
                                    </span>
                                </a>
                            </OverlayTrigger>
                        )}
                </>
            )}
            {status === 'summary' && (
                <>
                    {permission?.includes("WITHDRAW-STUDENT") && cell.row.original.can_withdraw &&
                        (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Withdraw Student</Tooltip>}
                            >
                                <span className="me-1 text-warning">
                                    <Slash 
                                        onClick={() => openWithdrawModal(id, true)}
                                        size="20" 
                                    />
                                </span>
                            </OverlayTrigger>
                    )}
                </>
            )}
            {(status === "enrolled"  || status === "pending") && (
                <>
                    {permission?.includes("ENROLLMENT-EDIT") &&
                        (cell.row.original.invoice_id === "" ||
                            cell.row.original.invoice_id === null) && (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Withdraw</Tooltip>}
                            >
                                <span>
                                    <FileMinus
                                        style={{ cursor: "pointer" }}
                                        className="me-1 text-warning"
                                        size="20"
                                        onClick={() => openWithdrawModal(id, false)}
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                </>
            )}
            {(status === "enrolled" || status === "pending") && (
                <>
                    {permission?.includes("ENROLLMENT-DELETE") &&
                        (cell.row.original.invoice_id === "" ||
                            cell.row.original.invoice_id === null) && (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Delete</Tooltip>}
                            >
                                <span>
                                    <Trash
                                        style={{ cursor: "pointer" }}
                                        className="me-1 text-danger"
                                        size="20"
                                        onClick={() => openDeleteModal(id)}
                                    />
                                </span>
                            </OverlayTrigger>
                        )}
                </>
            )}
            {permission?.includes("RE-ENROLL-STUDENT") && cell.row.original.status?.toUpperCase() === 'WITHDRAW' &&
                (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Enroll Student</Tooltip>}
                    >
                        <span className="me-1 text-success">
                            <RotateCw 
                                onClick={() => openReEnrollModal(id)}
                                size="20" 
                            />
                        </span>
                    </OverlayTrigger>
            )}

            {permission?.includes("ENROLLMENT-READ") && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>View</Tooltip>}
                >
                    <a
                        href={`/tms/enrolments/view/${id}?status=${status}`}
                        target="_blank"
                        rel="noreferrer"
                        className="me-1"
                    >
                        <span>
                            <Eye size="20" />
                        </span>
                    </a>
                </OverlayTrigger>
            )}
        </>
    );
};

export default ListButton;
