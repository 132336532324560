import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../../service/api";
import { AsyncPaginate } from "react-select-async-paginate";
import { Col, Row, Form } from "react-bootstrap";
import Select from "react-select";
import { addDefaultOption } from "../../../../../utils/utility";
import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";
import ContactSection from "./ContactSection";
import { useSelector } from "react-redux";
import ItemRow from "./ItemRow";
import { ConfirmModal } from "../../../../../components/ConfirmModal";
import { schema } from "./validationSchema";
import { SnackbarAlert } from "../../../../../components/SnackbarAlert";
import { setInitialValues } from "./setInitialValues";
import CompanyAddressSection from "./CompanyAddressSection";
import EditIStudentItemRow from "./EditIStudentItemRow";
const EditStudentRowForm = ({ configValues, quotation, courseFee }) => {
    let { method, id } = useParams();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const { data } = useSelector((state) => (state.user ? state.user : state));
    const permission = data?.permissions;
    const [defaultCompanyOption, setDefaultCompanyOption] = useState({});
    const isDraft = method?.toUpperCase() == 'DRAFT-EDIT';

    const [modalInfo, setModalInfo] = useState({
        values: [],
        notifMsg: "",
        open: false,
        severity: "success",
    });
    /** Notification state */
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const [hasMoreCompany, setHasMoreCompany] = useState(true);
    const [salespersons, setSalespersons] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [address, setAddress] = useState([]);
    const [addressId, setAddressId] = useState([]);
    const [isUpdateAddressChecked, setIsUpdateAddressChecked] = useState(false);
    const [defaultContactData, setDefaultContactData] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [canChangeTradeCategory, setCanChangeTradeCategory] = useState(false);

    const handleLoadMoreCompany = async (search, { page }) => {
        if (!search) {
            return {
                options: [],
                hasMore: false,
            };
        }
        const response = await api.get("tms/companies-options", {
            search: search,
            page: page,
        });
        const { options: newOptions, hasMore: newHasMore } = response;
        setHasMoreCompany(newHasMore);
        return {
            options: newOptions,
            hasMore: newHasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    const getCompanySalesPerson = async (companyId) => {
        if (!companyId) {
            return;
        }
        const response = await api.get(`tms/companies-sales/${companyId}`, {});

        const groupedOptions = [
            {
                label: "Default",
                options: [],
            },
            {
                label: "Current User",
                options: [],
            },
            {
                label: "All Salesperson",
                options: [],
            },
        ];

        ["default", "current_user", "all"].forEach((groupKey, index) => {
            if (response.data.hasOwnProperty(groupKey)) {
                groupedOptions[index].options = Object.entries(
                    response.data[groupKey]
                ).map(([key, item]) => ({
                    label: item.label,
                    value: item.value,
                }));
            }
        });

        setSalespersons(groupedOptions);
    };

    const getCompanyData = async (companyId) => {
        try {
            const response = await api.get(`tms/companies/${companyId}`);
            setValue("address", response?.data?.full_address);
            setContacts(response?.data?.contacts);
            setAddress(response?.data?.addtnl_address);
            setAddressId(response?.data?.id);
            let default_contact_person = response?.data?.contacts.find(
                (data) => data.id === quotation?.company_contact_id
            );
            let default_contact_person_data = [];
            default_contact_person_data.name = default_contact_person.name;
            default_contact_person_data.contact_number =
                default_contact_person.contact_number;

            setDefaultContactData(default_contact_person_data);
        } catch (error) {
            // Handle errors here
        }
    };

    const setCompanyDataEmpty = () => {
        setValue("address", "");
        setContacts([]);
        setSalespersons([]);
        setIsUpdateAddressChecked(false);
    };

    const onSubmit = async (data) => {
        try {
            setModalInfo({
                values: data,
                notifMsg: "Are you sure you want to create this quotation?",
                open: true,
                severity: "success",
            });
        } catch (error) {
            // Handle errors here
        }
    };

    // CLONING QUOTATION
    const cloneQuotation = async (values) => {
        try {
            setIsSubmitting(true);
            values.is_clone = true;
            const response = await api.post("tms/sales-quotations", values);
            if (response.status === "ok") {
                navigate("/tms/quotations", {
                    state: {
                        open: true,
                        notifMsg: response.message,
                        severity: "success",
                    },
                });
            } else {
                setNotif({
                    notifMsg: response.message,
                    open: true,
                    severity: "danger",
                });
            }
        } catch (error) {
            setIsSubmitting(false);
            setNotif({
                notifMsg: error?.response?.data?.message,
                open: true,
                severity: "danger",
            });
        }
        setIsSubmitting(false);
    };


    /** Update Quotation */
    const updateQuotation = async (values) => {

        try {
            setIsSubmitting(true);

            let response = null;
            if (values?.edit_trade_category) {
                response = await api.put(`tms/sales-quotations/update-trade-category/${id}`,values);
            } else {
                response = await api.put(`tms/sales-quotations/${id}`,values);
            }

            if (response.status === "ok") {
                navigate("/tms/quotations", {
                    state: {
                        open: true,
                        notifMsg: response.message,
                        severity: "success",
                    },
                });
            } else {
                setNotif({
                    notifMsg: response.message,
                    open: true,
                    severity: "danger",
                });
            }
        } catch (error) {
            setIsSubmitting(false);
            setNotif({
                notifMsg: error?.response?.data?.message,
                open: true,
                severity: "danger",
            });
            // setError(error?.response?.data?.message);
        }
        setIsSubmitting(false);
    };


    const handleButtonClick = (formValues, notifMsg, severity = "success") => {
        setModalInfo({
            values: formValues,
            notifMsg,
            open: true,
            severity,
            onConfirm: () => updateQuotation(formValues),
        });
    };

    useEffect(() => {
        if (selectedCompany) {
            getCompanyData(selectedCompany);
            getCompanySalesPerson(selectedCompany);
        }
    }, [selectedCompany]);

    useEffect(() => {
        setInitialValues(
            quotation,
            id,
            configValues,
            setValue,
            setSelectedCompany
        );
        if (quotation?.company?.id && quotation?.company?.name) {
            setDefaultCompanyOption({
                value: quotation?.company?.id,
                label: quotation?.company?.name
            })
        }
    }, [quotation, configValues, id]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={4}>
                        <div className="mb-3">
                            <label htmlFor="company_id" className="form-label">
                                Customer
                            </label>
                            {!isDraft && (
                                <>
                                    <br />
                                    {quotation?.company.name +
                                        (quotation?.company?.customer_code
                                            ? " (" +
                                              quotation?.company
                                                  ?.customer_code +
                                              ")"
                                            : "")}
                                </>
                            )}
                            {isDraft && (
                                <Controller
                                    name="company_id"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <AsyncPaginate
                                                isClearable
                                                name={field.name}
                                                value={defaultCompanyOption}
                                                loadOptions={
                                                    handleLoadMoreCompany
                                                }
                                                hasMore={hasMoreCompany}
                                                additional={{
                                                    page: 1,
                                                }}
                                                onChange={(value) => {
                                                    setValue(
                                                        "company_id",
                                                        value?.value,
                                                        { shouldValidate: true }
                                                    ); // Trigger validation manually
                                                    // getCompanySalesPerson(
                                                    //     value?.value
                                                    // );
                                                    setSelectedCompany(
                                                        value?.value
                                                    );
                                                    setDefaultCompanyOption({
                                                        value: value?.value,
                                                        label: value?.label,
                                                    });
                                                    setValue(
                                                        "company_address",
                                                        null
                                                    );
                                                    setValue(
                                                        "default_address",
                                                        ""
                                                    );
                                                    if (!value?.value) {
                                                        setCompanyDataEmpty();
                                                    }
                                                }}
                                                debounceTimeout={1000}
                                            />

                                            {!!errors.company_id && (
                                                <div className="text-danger text-sm">
                                                    Customer is required
                                                </div>
                                            )}
                                        </>
                                    )}
                                />
                            )}
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <label htmlFor="location" className="form-label">
                                Location
                            </label>
                            <Controller
                                name="location"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            className={`react-select-container ${
                                                errors?.location
                                                    ? "is-invalid"
                                                    : ""
                                            }`}
                                            classNamePrefix="react-select"
                                            options={addDefaultOption(
                                                configValues?.location
                                            )}
                                            isClearable={true}
                                            value={field.value?.value}
                                            onChange={(selectedOption) => {
                                                setValue(
                                                    "location",
                                                    selectedOption?.value
                                                );
                                            }}
                                            {...field}
                                        />
                                        {!!errors?.location && (
                                            <div className="invalid-feedback">
                                                Location is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <label htmlFor="location" className="form-label">
                                Sales person
                            </label>
                            <Controller
                                name="sales_user_id"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            className={`react-select-container ${
                                                errors?.sales_user_id
                                                    ? "is-invalid"
                                                    : ""
                                            }`}
                                            classNamePrefix="react-select"
                                            options={salespersons}
                                            isClearable={true}
                                            value={field.value?.value}
                                            onChange={(selectedOption) => {
                                                setValue(
                                                    field.name,
                                                    selectedOption?.value
                                                );
                                            }}
                                            {...field}
                                        />

                                        {!!errors?.sales_user_id && (
                                            <div className="invalid-feedback">
                                                Sales person is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        {selectedCompany && (
                            <CompanyAddressSection
                                address={address}
                                addressId={addressId}
                                permission={permission}
                                control={control}
                                setValue={setValue}
                                configValues={configValues}
                                selectedCompany={selectedCompany}
                                getCompanyData={getCompanyData}
                                quotation={quotation}
                                errors={errors}
                                getValues={getValues}
                            />
                        )}
                    </Col>
                    <Col md={8}>
                        {selectedCompany && (
                            <ContactSection
                                contacts={contacts}
                                permission={permission}
                                control={control}
                                setValue={setValue}
                                configValues={configValues}
                                selectedCompany={selectedCompany}
                                getCompanyData={getCompanyData}
                                quotation={quotation}
                                defaultContactData={defaultContactData}
                                errors={errors}
                                getValues={getValues}
                            />
                        )}
                    </Col>
                </Row>
                <EditIStudentItemRow
                    items={items}
                    setItems={setItems}
                    setValue={setValue}
                    getValues={getValues}
                    control={control}
                    configValues={configValues}
                    courseFee={courseFee}
                    errors={errors}
                    quotation={quotation}
                    method={method}
                    canChangeTradeCategory={canChangeTradeCategory}
                    setCanChangeTradeCategory={setCanChangeTradeCategory}
                />
                <hr />

                {method === "clone" && !isSubmitting && (
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            handleSubmit(async (data) => {
                                setModalInfo({
                                    values: data,
                                    notifMsg:
                                        "Are you sure you want to clone this quotation?",
                                    open: true,
                                    severity: "success",
                                    onConfirm: () => cloneQuotation(data),
                                });
                            })();
                        }}
                    >
                        Clone
                    </button>
                )}

                {(method === "edit" || method === 'trade-category-edit') && !isSubmitting && (
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            const formValues = getValues()
                            if (method == 'trade-category-edit') {
                                formValues.edit_trade_category = true;
                            }
                            handleButtonClick(
                                formValues,
                                "Are you sure you want to update this quotation?"
                            )
                        }}
                        disabled={method == 'trade-category-edit' && !canChangeTradeCategory}
                    >
                        Update
                    </button>
                )}

                {method === "draft-edit" && !isSubmitting && (
                    <>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                const formValues = getValues();
                                formValues.is_draft = true;
                                formValues.confirm_draft = true;
                                handleButtonClick(
                                    formValues,
                                    "Are you sure you want to update and confirm this quotation?"
                                );
                            }}
                        >
                            Update and confirm
                        </button>

                        <button
                            type="button"
                            className="btn btn-warning ms-2"
                            onClick={() => {
                                const formValues = getValues();
                                formValues.is_draft = true;
                                formValues.confirm_draft = false;
                                handleButtonClick(
                                    formValues,
                                    "Are you sure you want to update this draft quotation?",
                                    'warning'
                                );
                            }}
                        >
                            Update draft
                        </button>
                    </>
                )}

                {!isSubmitting && (
                    <button
                        type="button"
                        size="lg"
                        onClick={() => navigate("/tms/quotations")}
                        className="btn btn-danger mx-2"
                    >
                        Cancel
                    </button>
                )}

                {method == 'trade-category-edit' && !canChangeTradeCategory && (
                    <p className="text-danger">Can't change trade category due to price change.</p>
                )}

                {isSubmitting && (
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                )}
            </form>

            <ConfirmModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={modalInfo.onConfirm} // Call the update function when confirmed
            />

            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export default EditStudentRowForm;
