const tableColumns = [
    {
        Header: "S.no",
        accessor: "sno",
    },
    {
        Header: "Name",
        accessor: "name",
    },

    {
        Header: "Description",
        accessor: "description",
    },
    {
        Header: "Status",
        accessor: "status",
    },
    {
        Header: "Type",
        accessor: "type",
    },

    {
        Header: "Actions",
        accessor: "actions",
    },
];

export { tableColumns };
