import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../../service/api";
import { AsyncPaginate } from "react-select-async-paginate";
import { Col, Row, Form } from "react-bootstrap";
import Select from "react-select";
import { addDefaultOption } from "../../../../../utils/utility";
import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";
import ContactSection from "./ContactSection";
import { useSelector } from "react-redux";
import ItemRow from "./ItemRow";
import { ConfirmModal } from "../../../../../components/ConfirmModal";
import { schema } from "./validationSchema";
import { SnackbarAlert } from "../../../../../components/SnackbarAlert";
import { setInitialValues } from "./setInitialValues";
import CompanyAddressSection from "./CompanyAddressSection";
const FormRow = ({
    configValues,
    AdditionalMainInvoice,
    AdditionalInvoice,
    courseFee,
    setConfigValues,
    invoiceStudent,
    mainInvoiceStudent,
    defaultStudentsData,
}) => {
    let { id } = useParams();
    let { action } = useParams();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const { data } = useSelector((state) => (state.user ? state.user : state));
    const permission = data?.permissions;

    const [modalInfo, setModalInfo] = useState({
        values: [],
        notifMsg: "",
        open: false,
        severity: "success",
    });
    /** Notification state */
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });


    const [salespersons, setSalespersons] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [additionalType, setAdditionalType] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [address, setAddress] = useState([]);
    const [addressId, setAddressId] = useState([]);
    const [userChangedAdditionalType, setUserChangedAdditionalType] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [defaultContactData, setDefaultContactData] = useState();
    const [additionalCourses, setAdditionalCourses] = useState();
    const additionalTypeOptions = addDefaultOption([
        { label: "No GST", value: "no_gst" },
        { label: "With GST", value: "with_gst" },
    ]);
    const getCompanySalesPerson = async (companyId) => {
        if (!companyId) {
            return;
        }
        const response = await api.get(`tms/companies-sales/${companyId}`, {});

        const groupedOptions = [
            {
                label: "Default",
                options: [],
            },
            {
                label: "Current User",
                options: [],
            },
            {
                label: "All Salesperson",
                options: [],
            },
        ];

        ["default", "current_user", "all"].forEach((groupKey, index) => {
            if (response.data.hasOwnProperty(groupKey)) {
                groupedOptions[index].options = Object.entries(
                    response.data[groupKey]
                ).map(([key, item]) => ({
                    label: item.label,
                    value: item.value,
                }));
            }
        });

        setSalespersons(groupedOptions);
    };

    const getCompanyData = async (companyId) => {
        try {
            const response = await api.get(`tms/companies/${companyId}`);
            setValue("address", response?.data?.full_address);
            setContacts(response?.data?.contacts);
            setAddress(response?.data?.addtnl_address);
            setAddressId(response?.data?.id);
            let default_contact_person = response?.data?.contacts.find(
                (data) => data.id === AdditionalMainInvoice?.company_contact_id
            );
            let default_contact_person_data = [];
            default_contact_person_data.id = AdditionalMainInvoice?.company_contact_id;
            default_contact_person_data.name = default_contact_person.name;
            default_contact_person_data.contact_number = default_contact_person.contact_number;
            setDefaultContactData(default_contact_person_data);
            // console.log(defaultContactData);
        } catch (error) {
            // Handle errors here
        }
    };

    const getAdhocData = async (watchAdhoc) => {
        try {
            let value = { adhoc_type: watchAdhoc };
            const response = await api.post(
                "tms/invoice/get-ad-hoc-invoice-item",
                value
            );
            setAdditionalCourses(response.data);

        } catch (error) {
            // Handle errors here
        }
    };

    const onSubmit = async (data) => {
        try {
            setModalInfo({
                values: data,
                notifMsg:
                    "Are you sure you want to create this ad-hoc invoice?",
                open: true,
                severity: "success",
            });
        } catch (error) {
            // Handle errors here
        }
    };

    const createAdditional = async (values) => {
        setIsSubmitting(true);
        try {
            values.additional_type =
                additionalType !== undefined ? additionalType : null;
            const response = await api.post(
                `tms/invoice/additional-invoice/${id}`,
                values
            );

            if (response.status === "ok") {
                navigate("/tms/invoices", {
                    state: {
                        open: true,
                        notifMsg: response.message,
                        severity: "success",
                    },
                });
            } else {
                setNotif({
                    notifMsg: response.message,
                    open: true,
                    severity: "danger",
                });
            }
        } catch (error) {

            setNotif({
                notifMsg: error?.response?.data?.message,
                open: true,
                severity: "danger",
            });
        }
        setIsSubmitting(false);
    };

    /** Update Quotation */
    const updateAdditional = async (values) => {
        setIsSubmitting(true);

        try {

            values.additional_type =
                additionalType !== undefined ? additionalType : null;

            const response = await api.post(
                `tms/invoice/update-additional/${action}`,
                values
            );
            if (response.status === "ok") {
                navigate("/tms/invoices", {
                    state: {
                        open: true,
                        notifMsg: response.message,
                        severity: "success",
                    },
                });
            } else {
                setNotif({
                    notifMsg: response.message,
                    open: true,
                    severity: "danger",
                });
            }
        } catch (error) {
            setNotif({
                notifMsg: error?.response?.data?.message,
                open: true,
                severity: "danger",
            });
            // setError(error?.response?.data?.message);
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        if (selectedCompany) {
            getCompanyData(selectedCompany);
            getCompanySalesPerson(selectedCompany);
        }
    }, [selectedCompany]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                getAdhocData(AdditionalInvoice?.adhoc_type);
                const additionalCoursesConfig = additionalTypeOptions?.filter(
                    (option) => option.value === AdditionalInvoice?.adhoc_type
                )[0];
                setAdditionalType(AdditionalInvoice?.adhoc_type);
                setValue('additional_type', additionalCoursesConfig);
            } catch (error) {
                // Handle errors here
            }
        };

        fetchData();
    }, [AdditionalInvoice, id]);


    useEffect(() => {

        setInitialValues(
            AdditionalInvoice,
            configValues,
            setValue,
            setSelectedCompany,
            additionalCourses,
            AdditionalMainInvoice
        );
    }, [AdditionalInvoice, AdditionalMainInvoice, additionalType, configValues, additionalCourses]);

    let adhocTypeLabel = 'With GST'
    if (AdditionalInvoice?.adhoc_type === "no_gst") {
        adhocTypeLabel = 'No GST'
    }


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={3}>
                        <div className="mb-3">
                            <label htmlFor="adhoc_type" className="form-label">
                                Additional type
                            </label>


                            {action === 'add' && <Controller
                                name="additional_type"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            className={`react-select-container ${errors?.additional_type
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            classNamePrefix="react-select"
                                            options={additionalTypeOptions}
                                            // isClearable={true}
                                            value={field.value?.value}
                                            {...field}
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption);
                                                setUserChangedAdditionalType(true);
                                                if (selectedOption === null) {
                                                    setAdditionalType(null); // Set adhocType to null or an appropriate default value
                                                } else {
                                                    getAdhocData(
                                                        selectedOption.value
                                                    );
                                                    setAdditionalType(
                                                        selectedOption.value
                                                    );

                                                }
                                                // console.log(selectedOption.value)
                                            }}
                                        />
                                        {!!errors?.location && (
                                            <div className="invalid-feedback">
                                                additional is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />}
                            {action !== 'add' &&
                                <>
                                    <br />
                                    {adhocTypeLabel}
                                </>
                            }
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className="mb-3">
                            <label htmlFor="company_id" className="form-label">
                                Customer
                            </label>
                            <>

                                <br />
                                {AdditionalInvoice?.company?.name
                                    ? AdditionalInvoice?.company?.name
                                    : AdditionalMainInvoice?.company?.name}
                            </>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className="mb-3">
                            <label htmlFor="location" className="form-label">
                                Location
                            </label>
                            <Controller
                                name="location"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            className={`react-select-container ${errors?.location
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            classNamePrefix="react-select"
                                            options={addDefaultOption(
                                                configValues?.location
                                            )}
                                            isClearable={true}
                                            value={field.value?.value}
                                            onChange={(selectedOption) => {
                                                setValue(
                                                    "location",
                                                    selectedOption?.value
                                                );
                                            }}
                                            {...field}
                                            isDisabled={action !== 'add'}
                                        />
                                        {!!errors?.location && (
                                            <div className="invalid-feedback">
                                                Location is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className="mb-3">
                            <label htmlFor="location" className="form-label">
                                Salesperson
                            </label>
                            <Controller
                                name="sales_user_id"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            className={`react-select-container ${errors?.sales_user_id
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            classNamePrefix="react-select"
                                            options={salespersons}
                                            isClearable={true}
                                            value={field.value?.value}
                                            onChange={(selectedOption) => {
                                                setValue(
                                                    field.name,
                                                    selectedOption?.value
                                                );
                                            }}
                                            {...field}
                                            isDisabled={action !== 'add'}
                                        />

                                        {!!errors?.sales_user_id && (
                                            <div className="invalid-feedback">
                                                Salesperson is required
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <CompanyAddressSection
                            address={address}
                            addressId={addressId}
                            permission={permission}
                            control={control}
                            setValue={setValue}
                            configValues={configValues}
                            selectedCompany={selectedCompany}
                            getCompanyData={getCompanyData}
                            AdditionalInvoice={AdditionalInvoice}
                            AdditionalMainInvoice={AdditionalMainInvoice}
                        />
                    </Col>
                    <Col md={8}>
                        <ContactSection
                            contacts={contacts}
                            permission={permission}
                            control={control}
                            setValue={setValue}
                            configValues={configValues}
                            selectedCompany={selectedCompany}
                            getCompanyData={getCompanyData}
                            AdditionalInvoice={AdditionalInvoice}
                            defaultContactData={defaultContactData}
                        />
                    </Col>
                </Row>
                <ItemRow
                    items={items}
                    setItems={setItems}
                    setValue={setValue}
                    getValues={getValues}
                    control={control}
                    configValues={configValues}
                    courseFee={courseFee}
                    errors={errors}
                    additionalType={additionalType}
                    AdditionalInvoice={AdditionalInvoice}
                    AdditionalMainInvoice={AdditionalMainInvoice}
                    reset={reset}
                    invoiceStudent={invoiceStudent}
                    mainInvoiceStudent={mainInvoiceStudent}
                    defaultStudentsData={defaultStudentsData}
                    userChangedAdditionalType={userChangedAdditionalType}
                    additionalCourses={additionalCourses}
                />
                <hr />

                {action === "add" && !isSubmitting && (
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            handleSubmit(async (data) => {
                                setModalInfo({
                                    values: data,
                                    notifMsg:
                                        "Are you sure you want to create this additional invoice?",
                                    open: true,
                                    severity: "success",
                                    onConfirm: () => createAdditional(data),
                                });
                            })();
                        }}
                        disabled={isSubmitting}
                    >
                        Create
                    </button>
                )}

                {action !== "add" && !isSubmitting && (
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            setModalInfo({
                                values: getValues(), // Pass the current form values
                                notifMsg:
                                    "Are you sure you want to update this additional invoice?",
                                open: true,
                                severity: "success",
                                onConfirm: () => updateAdditional(getValues()), // Call the update function when confirmed
                            });
                        }}
                        disabled={isSubmitting}
                    >
                        Update
                    </button>
                )}
                
                {!isSubmitting && (
                <button
                    type="button"
                    size="lg"
                    onClick={() => navigate("/tms/invoices")}
                    className="btn btn-danger mx-2"
                >
                    Cancel
                </button>
                )}
                
                {isSubmitting && (
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                )}
            </form>

            <ConfirmModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={modalInfo.onConfirm} // Call the update function when confirmed
            />

            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export default FormRow;
