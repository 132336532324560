// Guards
import AuthGuard from "../../components/guards/AuthGuard";

import IntelliSuiteLayout from "./Layout/IntelliSuiteLayout";

// Users
import { Users } from "./Users/Users";
import { AddEditUser } from "./Users/AddEditUser";

//Roles
import { Roles } from "./Roles/Roles";
import { AddEditRole } from "./Roles/AddEditRole";

//Applications
import { Applications } from "./Applications/Applications";
import { AddEditApplication } from "./Applications/AddEditApplication";

//Permissions
import { Permissions } from "./Permissions/Permissions";
import { AddEditPermission } from "./Permissions/AddEditPermission";
import Profile from "../Profile/Profile";

const IntelliSuiteRoutes = {
    path: "/",
    element: (
        <AuthGuard>
            <IntelliSuiteLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: "users",
            element: (
                <AuthGuard>
                    <Users />
                </AuthGuard>
            ),
        },
        {
            path: "users/:id",
            element: (
                <AuthGuard>
                    <AddEditUser />
                </AuthGuard>
            ),
        },
        {
            path: "roles",
            element: (
                <AuthGuard>
                    <Roles />
                </AuthGuard>
            ),
        },
        {
            path: "roles/:id",
            element: (
                <AuthGuard>
                    <AddEditRole />
                </AuthGuard>
            ),
        },
        {
            path: "permissions",
            element: (
                <AuthGuard>
                    <Permissions />
                </AuthGuard>
            ),
        },
        {
            path: "permissions/:id",
            element: (
                <AuthGuard>
                    <AddEditPermission />
                </AuthGuard>
            ),
        },
        {
            path: "applications",
            element: (
                <AuthGuard>
                    <Applications />
                </AuthGuard>
            ),
        },
        {
            path: "applications/:id",
            element: (
                <AuthGuard>
                    <AddEditApplication />
                </AuthGuard>
            ),
        },
        {
            path: "profile",
            element: (
                <AuthGuard>
                    <Profile />
                </AuthGuard>
            ),
        },
    ],
};

export default IntelliSuiteRoutes;
