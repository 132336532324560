import React from "react";
import { Form, Col, Row } from "react-bootstrap";

export const AddEditQuotationDetails = ({
    values,
    errors,
    touched,
    handleBlur,
    handleFormChangeDetails,
}) => {
    return (
        <>
            <Row className="mb-3">
                <Col md={3}>
                    <Form.Group className="">
                        <Form.Group>
                            <Form.Label>Quotation date</Form.Label> <br />
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="date"
                                    name="quotation_date"
                                    id="quotation_date"
                                    value={values.quotation_date}
                                    isInvalid={Boolean(
                                        errors &&
                                            touched &&
                                            errors.quotation_date &&
                                            touched.quotation_date
                                    )}
                                    onBlur={handleBlur}
                                    onChange={handleFormChangeDetails}
                                />
                                {errors && errors?.quotation_date && (
                                    <Form.Control.Feedback type="invalid">
                                        Quotation date is a required field
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Form.Group>
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
};
