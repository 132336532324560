import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { addDefaultOption, formatCsvStudentNames, formatStudentNames } from "../../../../../utils/utility";
import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";
import VerifiedStudentsTable from "./VerifiedStudentsTable";

const CourseInformation = ({
    control,
    index,
    configValues,
    handleCourseChange,
    errors,
    setValue,
    handleDropDownChange,
    updateCourseCode,
    getValues

}) => {
    const [studentArrays, setStudentArrays] = useState([]);
    const [selectedOption, setSelectedOption] = useState("studentNames");
    const verifyStudents = (index) => {
        // Get the value from the student_names input box
        const studentNames = getValues(`items[${index}].student_names`);
        if (!studentNames || studentNames.trim() === '') {
            return;
        }
        // Split the string by lines and create an array of objects
        const students = formatStudentNames(studentNames); 

        // Update the studentArrays in the component state
        setStudentArrays((prevStudentArrays) => {
            const newStudentArrays = [...prevStudentArrays];
            newStudentArrays[index] = students;
            return newStudentArrays;
        });


    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const fileContent = event.target.result;

                // Parse the file content manually or using a different library
                // Here is a simple example assuming the file is in CSV format
                const rows = fileContent.split('\n');

                const studentsFromExcel = formatCsvStudentNames(rows);

                // Update the studentArrays in the component state
                setStudentArrays((prevStudentArrays) => {
                    const newStudentArrays = [...prevStudentArrays];
                    newStudentArrays[index] = studentsFromExcel;
                    return newStudentArrays;
                });

                // Update the form's value for items[${index}].student
                setValue(`items[${index}].students`, studentsFromExcel);
                // Do whatever you need to do with the array of objects here
            };

            reader.readAsText(file);
        }
    };
    const handleOptionChange = (option) => {
        // Clear student information when switching options
        setStudentArrays((prevStudentArrays) => {
            const newStudentArrays = [...prevStudentArrays];
            newStudentArrays[index] = [];
            return newStudentArrays;
        });

        // Update selected option
        setSelectedOption(option);
    };

    const handleStudentNameChange = (index, studentIndex, event) => {
        const newName = event.target.value;
        setStudentArrays((prevStudentArrays) => {
            const newStudentArrays = [...prevStudentArrays];
            newStudentArrays[index][studentIndex].name = newName;
            return newStudentArrays;
        });

    };

    const handleStudentIdNumberChange = (index, studentIndex, event) => {
        const newIdNumber = event.target.value;
        setStudentArrays((prevStudentArrays) => {
            const newStudentArrays = [...prevStudentArrays];
            newStudentArrays[index][studentIndex].id_number = newIdNumber;
            return newStudentArrays;
        });

    };

    const removeStudent = (index, studentIndex) => {
        const shouldRemove = window.confirm("Are you sure you want to remove this student?");

        if (shouldRemove) {
            setStudentArrays((prevStudentArrays) => {
                const newStudentArrays = [...prevStudentArrays];
                newStudentArrays[index].splice(studentIndex, 1);
                return newStudentArrays;
            });

        }
    };
    useEffect(() => {
        // This effect will run whenever studentArrays changes
        setValue(`items[${index}].students`, studentArrays[index]);
    }, [studentArrays, setValue, index]);

    return (
        <table width="100%">
            <tbody>
                <tr>
                    <td width="35%">
                        <div className="">
                            <label
                                htmlFor={`items[${index}].course_code`}
                                className="form-label"
                            >
                                Item code
                            </label>
                            <div className=" quotation_min_height">
                                <Controller
                                    name={`items[${index}].course_code`}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                className="react-select-container "
                                                classNamePrefix="react-select"
                                                options={addDefaultOption(
                                                    configValues?.course_code
                                                )}
                                                isClearable={true}
                                                read
                                                name={field.name}
                                                onChange={(selectedOption) => {
                                                    field.onChange(
                                                        selectedOption
                                                    );
                                                    handleCourseChange(
                                                        selectedOption?.value,
                                                        index
                                                    );
                                                }}
                                                value={field.value}
                                            />
                                        </>
                                    )}
                                />
                                {errors &&
                                    errors.items &&
                                    errors.items[index] &&
                                    errors.items[index].course_code && (
                                        <div className="text-danger mt-2 text-sm">
                                            Item code is required
                                        </div>
                                    )}
                            </div>
                        </div>
                    </td>
                    <td width="35%">
                        <div className="">
                            <label
                                htmlFor={`items[${index}].trade_option`}
                                className="form-label"
                            >
                                Type of trade
                            </label>
                            <div className=" quotation_min_height">
                                <Controller
                                    name={`items[${index}].trade_option`}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                options={addDefaultOption(
                                                    configValues?.tradeOptions
                                                )}
                                                name={field.name}
                                                isClearable={true}
                                                onChange={(selectedOption) => {
                                                    field.onChange(
                                                        selectedOption
                                                    );
                                                    handleDropDownChange(index);
                                                }}
                                                value={field.value}
                                                onMenuClose={() => {
                                                    handleDropDownChange(index);
                                                }}
                                            />
                                        </>
                                    )}
                                />
                                {errors &&
                                    errors.items &&
                                    errors.items[index] &&
                                    errors.items[index].trade_option && (
                                        <div className="text-danger text-sm">
                                            Type of trade is required
                                        </div>
                                    )}
                            </div>
                        </div>
                    </td>
                    <td width="35%">
                        <div className="">
                            <label
                                htmlFor={`items[${index}].application_option`}
                                className="form-label"
                            >
                                Application option
                            </label>
                            <div className=" quotation_min_height">
                                <Controller
                                    name={`items[${index}].application_option`}
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                isClearable={true}
                                                options={addDefaultOption(
                                                    configValues?.tradeApplication
                                                )}
                                                {...field}
                                                onChange={(selectedOption) => {
                                                    field.onChange(
                                                        selectedOption
                                                    );
                                                    handleDropDownChange(index);
                                                }}
                                                onMenuClose={() => {
                                                    handleDropDownChange(index);
                                                }}
                                            />
                                        </>
                                    )}
                                />
                                {errors &&
                                    errors.items &&
                                    errors.items[index] &&
                                    errors.items[index].application_option && (
                                        <div className="text-danger text-sm">
                                            Application option is required
                                        </div>
                                    )}
                            </div>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <div>
                            <label
                                htmlFor={`items[${index}].trade_category`}
                                className="form-label"
                            >
                                Trade category
                            </label>
                            <div className=" quotation_min_height">
                                <Controller
                                    name={`items[${index}].trade_category`}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                isClearable={true}
                                                options={addDefaultOption(
                                                    configValues?.tradeCategory
                                                )}
                                                {...field}
                                                onChange={(selectedOption) => {
                                                    field.onChange(
                                                        selectedOption
                                                    );
                                                    handleDropDownChange(index);
                                                }}
                                                onMenuClose={() => {
                                                    handleDropDownChange(index);
                                                }}
                                            />
                                        </>
                                    )}
                                />
                                {errors &&
                                    errors.items &&
                                    errors.items[index] &&
                                    errors.items[index].trade_category && (
                                        <div className="text-danger text-sm">
                                            Trade category is required
                                        </div>
                                    )}
                            </div>
                        </div>
                    </td>
                    <td colSpan={2}>
                        <div>
                            <label
                                htmlFor={`items[${index}].course`}
                                className="form-label"
                            >
                                Course
                            </label>
                            <div className=" quotation_min_height">
                                <Controller
                                    name={`items[${index}].course`}
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                isClearable={true}
                                                options={addDefaultOption(
                                                    configValues.attcCourses
                                                )}
                                                {...field}
                                                onChange={(selectedOption) => {
                                                    field.onChange(
                                                        selectedOption
                                                    );
                                                    handleDropDownChange(index);
                                                }}
                                                onMenuClose={() => {
                                                    handleDropDownChange(index);
                                                }}
                                            />
                                        </>
                                    )}
                                />
                                {errors &&
                                    errors.items &&
                                    errors.items[index] &&
                                    errors.items[index].course && (
                                        <div className="text-danger text-sm">
                                            Course is required
                                        </div>
                                    )}
                            </div>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td colSpan={1}>

                        <div className="mb-3">
                            <label className="form-label">Choose input method:</label>
                            <div>
                                <label className="mx-2">
                                    <input
                                        type="radio"
                                        name={`inputMethod_${index}`}
                                        value="studentNames"
                                        checked={selectedOption === "studentNames"}
                                        onChange={() => handleOptionChange("studentNames")}
                                    />
                                    {""} Student names
                                </label>
                                <label className="mx-2 ">
                                    <input
                                        type="radio"
                                        name={`inputMethod_${index}`}
                                        value="excel"
                                        checked={selectedOption === "excel"}
                                        onChange={() => handleOptionChange("excel")}
                                    />
                                    {""} Excel(CSV)
                                </label>
                            </div>
                        </div>

                        {selectedOption === 'studentNames' && <div className="mb-3 ">
                            <label
                                htmlFor={`items[${index}].student_names`}
                                className="form-label"
                            >
                                Student name and Fin No. (separated by comma)
                            </label>
                            <Controller
                                name={`items[${index}].student_names`}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <AutoGrowTextarea
                                            name={`items[${index}].student_names`}
                                            id={field.id}
                                            fieldValue={field.value}
                                            handleBlur={field.onBlur}
                                            isInvalid={!!errors.student_names}
                                            handleFormChangeDetails={
                                                field.onChange
                                            }
                                            width="300px" // Set the desired width
                                        />

                                    </>
                                )}
                            />
                            <br />
                            <button className="btn btn-sm btn-info" onClick={(e) => {
                                e.preventDefault(); // Prevent the default form submission
                                verifyStudents(index);
                            }}>Verify</button>
                        </div>
                        }
                        {selectedOption === 'excel' && <div className="mb-3">
                            <label htmlFor={`fileInput_${index}`} className="form-label">
                                Choose Excel File
                            </label>
                            <input
                                type="file"
                                id={`fileInput_${index}`}
                                onChange={handleFileChange}
                                accept=".xlsx, .xls, .csv"
                                style={{ width: "300px" }}
                            />

                        </div>
                        }
                        {errors &&
                            errors.items &&
                            errors.items[index] &&
                            errors.items[index].student_names && (
                                <div className="text-danger text-sm">
                                    Students is required
                                </div>
                            )}

                    </td>
                    <td colSpan={2} valign="top">
                        <div className="px-2">
                            <strong>Verified students:</strong>
                            <VerifiedStudentsTable
                                studentArray={studentArrays[index] || []}
                                onNameChange={(studentIndex, e) => handleStudentNameChange(index, studentIndex, e)}
                                onIdNumberChange={(studentIndex, e) => handleStudentIdNumberChange(index, studentIndex, e)}
                                onRemove={(studentIndex) => removeStudent(index, studentIndex)}
                                showRemove ={true}
                            />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default CourseInformation;
