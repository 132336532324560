import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import Wrapper from "../../../components/Wrapper";
import Sidebar from "../../../components/sidebar/Sidebar";
import Main from "../../../components/Main";
import Navbar from "../../../components/navbar/NavbarComponent";
import Content from "../../../components/Content";
import Footer from "../../../components/Footer";

import { SnackbarAlert } from "../../../components/SnackbarAlert";
import { Briefcase, Codesandbox, Key, Settings, Users } from "react-feather";
import { useSelector } from "react-redux";

const IntelliSuiteLayout = ({ children }) => {
    const { data } = useSelector((state) => {
        return state.user ? state.user : state;
    });

    const location = useLocation();
    const state = location.state;
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    useEffect(() => {
        if (state?.authorizationOpen) {
            setNotif({
                notifMsg: state.notifMsg,
                open: state.authorizationOpen,
                severity: state.severity,
            });
        }
        window.history.replaceState(null, "");
    }, [state]); //  eslint-disable-line react-hooks/exhaustive-deps

    let appListArray = [];
    if (data && data.apps) {
        if (data.apps.length > 0) {
            appListArray = data.apps.map((item) => {
                return item?.name?.toUpperCase();
            });
        }
    }

    // const pages = [
    //     {
    //         href: "/intellidocs",
    //         icon: Briefcase,
    //         title: "IntelliDocs",
    //     },
    //     {
    //         href: "/tms",
    //         icon: Briefcase,
    //         title: "TMS",
    //     },
    //     {
    //         href: "/intellibuild",
    //         icon: Briefcase,
    //         title: "IntelliBuild",
    //     },
    //     {
    //         href: "/settings",
    //         icon: Settings,
    //         title: "Settings",
    //         children: [
    //             {
    //                 href: "/users",
    //                 icon: Users,
    //                 title: "Users",
    //             },
    //             {
    //                 href: "/roles",
    //                 icon: Briefcase,
    //                 title: "Roles",
    //             },
    //             {
    //                 href: "/permissions",
    //                 icon: Key,
    //                 title: "Permissions",
    //             },
    //             {
    //                 href: "/applications",
    //                 icon: Codesandbox,
    //                 title: "Applications",
    //             },
    //         ],
    //     },
    // ];
    const pages = [];
    if (appListArray?.includes("INTELLITMS")) {
        pages.push({
            href: "/tms",
            icon: Briefcase,
            title: "TMS",
        });
    }
    if (appListArray?.includes("INTELLIDOCS")) {
        pages.push({
            href: "/intellidocs",
            icon: Briefcase,
            title: "Intellidocs",
        });
    }
    if (appListArray?.includes("INTELLIBUILD")) {
        pages.push({
            href: "/intellibuild",
            icon: Briefcase,
            title: "intellibuild",
        });
    }
    if (appListArray?.includes("INTELLISUITE")) {
        pages.push({
            href: "/settings",
            icon: Settings,
            title: "Settings",
            children: [
                {
                    href: "/users",
                    icon: Users,
                    title: "Users",
                },
                {
                    href: "/roles",
                    icon: Briefcase,
                    title: "Roles",
                },
                {
                    href: "/permissions",
                    icon: Key,
                    title: "Permissions",
                },
                {
                    href: "/applications",
                    icon: Codesandbox,
                    title: "Applications",
                },
            ],
        });
    }
    const IntelliSuiteItems = [
        {
            title: "",
            pages: pages,
        },
    ];
    return (
        <React.Fragment>
            <Wrapper>
                <Sidebar title="IntelliSuite" items={IntelliSuiteItems} />
                <Main>
                    <Navbar />
                    <Content>
                        {children}
                        <Outlet />
                    </Content>
                    <Footer />
                </Main>
            </Wrapper>
            {state?.authorizationOpen && (
                <SnackbarAlert notif={notif} setNotif={setNotif} />
            )}
        </React.Fragment>
    );
};

export default IntelliSuiteLayout;
