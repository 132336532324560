import React, { useState, useCallback, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Search from "./Search";
import api from "../../../../../service/api";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Alert, Button, Card, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { tableColumns } from "./TableColumns";
import { SnackbarAlert } from "../../../../../components/SnackbarAlert";
import { Lists } from "./List";
import { addDefaultOption, toUpperInput } from "../../../../../utils/utility";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "../Add-Edit/ValidationSchema";
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate } from "react-router-dom";

const CourseFeeList = ({ type, title }) => {

    const location = useLocation();
    const state = location.state;
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [feeId, setFeeId] = useState(null)
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState()
    const [method, setMethod] = useState(null)
    const [courseFees, setCourseFees] = useState([])
    const [config, setConfig] = useState()
    const [selectedValue, setSelectedValue] = useState()
    const [history, setHistory] = useState([])
    const [submit, isSubmit] = useState(false)
    const { data } = useSelector((state) => (state.user ? state.user : state));
    const permission = data?.permissions;
    
    const search = (page) => {
        const searchParam = { ...searchValue, ...page };
        // const searchParam = { ...page };
        getCourseFees(searchParam);
    };

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const [searchValue, setSearchValue] = useState({
        type_of_trade: "",
        application_option: "",
        course_code: "",
        course: "",
        trade_category: ""
    });

    function resetValues (){
        setValue("course_code", "")
        setValue("course_id", "")
        setValue("trade_category", "")
        setValue("application_option", "")
        setValue("type_of_trade", "")
        setValue("fee", 0)
        setSelectedValue(null)
        setSelectedCourse(null)
        setSelected(initialState);
    };

    const getCourseFees = useCallback(async (search) => {
        try {
            setLoading(true);
            const response = await api.get("tms/course_fee", search);
            setCourseFees(response);
            setError();
            setLoading(false);
        } catch (error) {
            setCourseFees([]);
            // console.log(error)
            if(error.response.status === 401){
                //if unauthorized
                navigate("/tms/401")
            }
            else{
                //if other errors
                setNotif({
                    notifMsg: error.response.data.message,
                    open: true,
                    severity: "danger",
                });
                setLoading(false)
            }
        }
        
    })

    const getCourseFeeConfig = useCallback(async (search) => {
        try {
            setLoading(true);
            const response = await api.get("tms/config/course_fee", search);
            setConfig(response?.data);
            setError();
            setLoading(false);
        } catch (error) {
            setCourseFees([]);
            setError(error.message);
        }
    })
    
    useEffect(() => {
        /**
         * set page number
         */
        let pageNo = "";
        if (state?.page) {
            pageNo = state?.page;
        }
        getCourseFees({ ...searchValue, page: pageNo });
        getCourseFeeConfig()
    }, []);

    /** Modal popup */
    const setModalPopUp = (status, type="") => {
        if(status === false){
            reset()
        }
        setMethod(type);
        setShow(status);
        setModalLoading(true);
        setModalLoading(false);
    };

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const AddEditCourseFee = async(data)=> {
        isSubmit(true)
        const delay = ms => new Promise(res => setTimeout(res, ms));
        setLoading(true)
        if(method === "edit"){
            try{
                const response = await api.put(
                    `tms/course_fee/${feeId}`,
                    data
                );
                if (response.status === "ok") {
                    setNotif({
                        notifMsg: "Course Fee updated successfully",
                        open: true,
                        severity: "success",
                    });
                    await delay(2000)
                    getCourseFees(null);
                    getCourseFeeConfig()
                    setShow(false)
                    isSubmit(false)
                    setLoading(false)
                }
            } catch (error) {
                if(error.response.status === 401){
                    //if unauthorized
                    navigate("/tms/401")
                }
                else{
                    //if other errors
                    setNotif({
                        notifMsg: error.response.data.message,
                        open: true,
                        severity: "danger",
                    });
                    setLoading(false)
                }
            }
        }
        if(method === "add"){
            console.log(data);
            try{
                const response = await api.post(
                    "tms/course_fee",
                    data
                );
                if (response.status === "ok") {
                    setNotif({
                        notifMsg: "Course Fee added successfully",
                        open: true,
                        severity: "success",
                    });
                    await delay(2000)
                    getCourseFees(null);
                    getCourseFeeConfig()
                    setShow(false)
                    isSubmit(false)
                    setLoading(false)
                }
            } catch (error) {
                if(error.response.status === 401){
                    //if unauthorized
                    navigate("/tms/401")
                }
                else{
                    //if other errors
                    setNotif({
                        notifMsg: error.response.data.message,
                        open: true,
                        severity: "danger",
                    });
                    setLoading(false)
                }
            }
        }
        
    }

    
    const [selectedCourse, setSelectedCourse] = useState(null);
    const handleCourseSelectOnChange = (selectedOption) => {
        setSelectedCourse(selectedOption);
        setValue("course_id", selectedOption?.value);
    };

    const initialState = {
        type_of_trade: null,
        application_option: null,
        trade_category: null
    };
    const [selected, setSelected] = useState(initialState);

    const handleSelectOnChange = (key) => (selectedOption) => {
        setSelected(prevState => ({
            ...prevState,
            [key]: selectedOption
        }));
        setValue(key, selectedOption?.value);
    };

    return (
        <React.Fragment>
            <Helmet title="Configuration > Course Fee" />
            {!permission.includes('FEE-READ') && (
                <Alert className="my-3" variant="warning">
                    <div className="alert-message">
                        You don't have permission to access this page
                    </div>
                </Alert>
            )}

            {permission.includes('FEE-READ') && (
                <Container fluid className="p-0">
                    <h1 className="h3 mb-3">{title}</h1>
                    {error && (
                        <Card.Header>
                            <Alert className="my-3" variant="danger">
                                <div className="alert-message">{error}</div>
                            </Alert>
                        </Card.Header>
                    )}
                    <Card>
                        <Card.Body className="mt-0">
                            <Row className="mb-5">
                                <Search
                                    setModalPopUp={setModalPopUp}
                                    setSearchValue={setSearchValue}
                                    searchValue={searchValue}
                                    search={search}
                                    config={config}
                                    resetValues={resetValues}
                                />
                            </Row>
                            
                            {loading && <div>Loading...</div>}
                            {!loading && courseFees?.data && (
                                <Lists
                                    columns={tableColumns}
                                    rawData={courseFees}
                                    courseFeeApi={search}
                                    setModalPopUp={setModalPopUp}
                                    setValue={setValue}
                                    courseList={config?.course}
                                    setSelectedCourse={setSelectedCourse}
                                    setFeeId={setFeeId}
                                    setHistory={setHistory}
                                    selected = {selected}
                                    setSelected = {setSelected}
                                />
                            )}
                        </Card.Body>
                    </Card>
                </Container>
            )}

            {/* Modal */}
            <Modal
                show={show}
                onHide={() => {
                    setModalPopUp(false);
                }}
                animation={false}
            >
            <Modal.Header>
                <h3 className="text-uppercase">{method} COURSE FEE</h3>
            </Modal.Header>
                {modalLoading && <div>Loading...</div>}
                { method === "view" && (
                    <>
                    <Modal.Body>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Attribute</th>
                                    <th>Original</th>
                                    <th>Changes</th>
                                </tr>
                            </thead>
                            <tbody>
                               { history.length < 0 || history.length === 0 && (
                                    <tr>    
                                        <td colSpan={3}>No changes</td>
                                    </tr>
                                )}
                                {history.length > 0 && (
                                    <>
                                        {history.map((item, index) => (
                                            <>
                                                <tr key={index}>
                                                    <th colSpan={3}>{item?.created_at}</th>
                                                </tr>
                                                {Object.entries(item.changes).map(([attribute, changes]) => {
                                                    if (attribute !== 'created_at' && attribute !== 'id' && attribute !== 'updated_by') {
                                                        return (
                                                            <tr key={attribute}>
                                                                <td>{attribute === 'course_id' ? "course" : attribute}</td>
                                                                <td>{changes?.original ?? '-'}</td>
                                                                <td>{changes?.changed ?? '-'}</td>
                                                            </tr>
                                                        )}
                                                    
                                                })}
                                            </>
                                        ))}
                                    </>
                                    
                                )}
                            </tbody>
                        </table>
                            
                    </Modal.Body>
                    </>
                )}
                {!modalLoading && method !== "view" && (
                    <>
                        <Modal.Body className="text-left m-3">
                            <Form onSubmit={handleSubmit(AddEditCourseFee)}>
                                <Form.Group className="mb-3">
                                    <Form.Label><strong>Coures Code</strong></Form.Label>
                                        <Controller
                                            name="course_code"
                                            control={control}
                                            render={({field}) => (
                                                <Form.Control
                                                    name="course_code"
                                                    placeholder="Course Code"
                                                    onInput={toUpperInput}
                                                    value={field?.value}
                                                    onChange={(event) => {
                                                        setValue("course_code", event.target.value)
                                                    }}
                                                    plaintext={method === 'view' ? true : false} 
                                                    readOnly={method === 'view' ? true : false}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="course_code"
                                            render={({ message }) => (
                                                <small className="text-danger">{message}</small>
                                            )}
                                        />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label><strong>Type of Trade</strong></Form.Label>

                                    { <Select
                                        isClearable
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={addDefaultOption(config?.type_of_trade)}
                                        name="type_of_trade" 
                                        value={selected.type_of_trade}
                                        onChange={handleSelectOnChange('type_of_trade')}
                                        isDisabled={method === 'view'}
                                    /> }
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label><strong>Application Option</strong></Form.Label>
                                    { <Select
                                        isClearable
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={addDefaultOption(config?.application_option)}
                                        name="application_option" 
                                        value={selected.application_option}
                                        onChange={handleSelectOnChange('application_option')}
                                        isDisabled={method === 'view'}
                                    /> }

                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label><strong>Course</strong></Form.Label>
                                    <Select
                                        isClearable
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={addDefaultOption(config?.course)}
                                        value={selectedCourse}
                                        name="course_id"
                                        onChange={handleCourseSelectOnChange}
                                        isDisabled={method === 'view'}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label><strong>Trade Category</strong></Form.Label>
                                    { <Select
                                        isClearable
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={addDefaultOption(config?.trade_category)}
                                        name="trade_category" 
                                        value={selected.trade_category}
                                        onChange={handleSelectOnChange('trade_category')}
                                        isDisabled={method === 'view'}
                                    /> }
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label><strong>Fee</strong></Form.Label>
                                        <Controller
                                            name="fee"
                                            control={control}
                                            render={({field}) => (
                                                <>  
                                                    <Form.Control
                                                        type="number"
                                                        name={field.name}
                                                        value={field?.value}
                                                        onChange={(event) => {
                                                            setValue("fee", event.target.value);
                                                        }}
                                                        plaintext={method === 'view' ? true : false} 
                                                        readOnly={method === 'view' ? true : false}
                                                    />
                                                </>
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="fee"
                                            render={({ message }) => (
                                                <small className="text-danger">{message}</small>
                                            )}
                                        />
                                </Form.Group>
                                <Row>
                                    <Col className="d-flex justify-content-end">
                                        <Button
                                            type="submit"
                                            variant="success"
                                            hidden={method === 'view' ? true : false}
                                            className="mx-1"
                                            disabled={submit}
                                        >
                                            Submit
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            onClick={() => {
                                                resetValues()
                                                setModalPopUp(false, "");
                                            }}
                                            className="mx-1"
                                        >
                                            Close
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>
                    </>
                )}
            </Modal>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </React.Fragment>
    );
};

export default CourseFeeList;
