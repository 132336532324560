import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { RefreshCw } from "react-feather";
import { Formik } from "formik";
import moment from "moment";
import api from "../../../service/api";
import { useParams } from "react-router-dom";
import { SnackbarAlert } from "../../../components/SnackbarAlert";
import { ExpensesSection } from "./ExpensesSection";
import { RevenueSection } from "./RevenueSection";

export const Dashboard = () => {
    let startDate = moment().format("yyyy-MM");
    // eslint-disable-next-line no-unused-vars
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    let { id } = useParams();

    //
    // States
    //

    // eslint-disable-next-line no-unused-vars
    const [inputFields, setInputFields] = useState({
        start_date: startDate,
    });
    const [expenseDetails, setExpenseDetails] = useState({});
    const [revenueDetails, setRevenueDetails] = useState({});

    //
    // Functions
    //

    const getDashboardDetails = useCallback(
        async (date) => {
            try {
                const response = await api.get("dashboard-details", {
                    project_id: id,
                    start_date: date,
                });

                setExpenseDetails(response.expense);
                setRevenueDetails(response.revenue);
            } catch {
                setNotif({
                    notifMsg: "Something went wrong with the server",
                    open: true,
                    severity: "danger",
                });
            }
        },
        [id]
    );

    //
    // UseEffects
    //

    useEffect(() => {
        getDashboardDetails(startDate);
    }, [id, startDate, getDashboardDetails]);

    return (
        <>
            <div className="p-4 rounded bg-light">
                <Row className="mb-2 mb-xl-3">
                    <Col xs="6" className="d-none text-start d-sm-block">
                        <h3>Dashboard</h3>
                    </Col>

                    <Col xs="5" className="ms-auto text-end mt-n1">
                        <Formik
                            initialValues={inputFields}
                            onSubmit={(values) =>
                                getDashboardDetails(values.start_date)
                            }
                        >
                            {({ handleChange, handleSubmit, values }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Control
                                        type="month"
                                        name="start_date"
                                        min="2022-01"
                                        max="2050-12"
                                        value={values.start_date || ""}
                                        onChange={handleChange}
                                        style={{
                                            width: "50%",
                                            display: "inline",
                                        }}
                                    />
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="shadow-sm ms-2"
                                    >
                                        <RefreshCw className="feather" />
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>

                <ExpensesSection dashboardDetails={expenseDetails} />
                <RevenueSection dashboardDetails={revenueDetails} />
            </div>

            {/* Supporting components */}

            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};
