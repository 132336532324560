import axios from "axios";
import { objectToQueryString } from "../utils/url";
import CookieService from "./CookieService";

let _baseURL = "";

var base_url = window.location.origin;

if (base_url.includes("localhost")) {
    _baseURL = process.env.REACT_APP_DEV_API;
} else if (base_url.includes("stg")) {
    _baseURL = process.env.REACT_APP_DEV_STAGING;
} else {
    _baseURL = process.env.REACT_APP_PROD_API;
}

let defaults = {
    apiURL: _baseURL,
};
console.log(_baseURL);
const forbiddenError = 403;

class api {
    // GET request
    async get(url, params) {
        const options = {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: CookieService.get("authToken")
                    ? `Bearer ${CookieService.get("authToken")}`
                    : "",
            },
            paramsSerializer: objectToQueryString,
            params: params,
        };

        if (!CookieService.get("authToken") && url !== "users/verification" && url !== 'sso/get_redirect_url' && url !== 'sso/login') {
            CookieService.remove("authToken");
            window.location.href = "/auth/sign-in";
        }

        return await axios
            .get(`${defaults?.apiURL}${url}`, options)
            .then((response) => {
                return response?.data;
            })
            .catch((error) => {
                if (error.response?.data?.error_code === forbiddenError) {
                    CookieService.remove("authToken");
                    // window.location.href = "/auth/sign-in";
                }

                throw error;
            });
    }

    // GET request
    async getpdf(url, params) {
        const options = {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: CookieService.get("authToken")
                    ? `Bearer ${CookieService.get("authToken")}`
                    : "",
            },
            paramsSerializer: objectToQueryString,
            params: params,
            responseType: "blob",
        };

        if (!CookieService.get("authToken")) {
            CookieService.remove("authToken");
            window.location.href = "/auth/sign-in";
        }

        return await axios
            .get(`${defaults.apiURL}${url}`, options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response.data.error_code === forbiddenError) {
                    CookieService.remove("authToken");
                    window.location.href = "/auth/sign-in";
                }

                throw error;
            });
    }

    // GET request
    async getexcel(url, params) {
        const options = {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: CookieService.get("authToken")
                    ? `Bearer ${CookieService.get("authToken")}`
                    : "",
            },
            paramsSerializer: objectToQueryString,
            params: params,
            responseType: "blob",
        };

        if (!CookieService.get("authToken")) {
            CookieService.remove("authToken");
            window.location.href = "/auth/sign-in";
        }

        return await axios
            .get(`${defaults.apiURL}${url}`, options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response.data.error_code === forbiddenError) {
                    CookieService.remove("authToken");
                    window.location.href = "/auth/sign-in";
                }

                throw error;
            });
    }

    // POST request
    async post(url, data) {
        const options = {
            headers: {
                // "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                Authorization: CookieService.get("authToken")
                    ? `Bearer ${CookieService.get("authToken")}`
                    : "",
            },
            paramsSerializer: objectToQueryString,
        };

        if (
            !CookieService.get("authToken") &&
            url !== "users/verification" &&
            url !== "login"
        ) {
            CookieService.remove("authToken");
            window.location.href = "/auth/sign-in";
        }

        return await axios
            .post(`${defaults.apiURL}${url}`, data, options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response.data.error_code === forbiddenError) {
                    CookieService.remove("authToken");
                    // window.location.href = "/auth/sign-in";
                }

                throw error;
            });
    }

    // PUT request
    async put(url, data) {
        const options = {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: CookieService.get("authToken")
                    ? `Bearer ${CookieService.get("authToken")}`
                    : "",
            },
            paramsSerializer: objectToQueryString,
        };

        if (!CookieService.get("authToken")) {
            CookieService.remove("authToken");
            window.location.href = "/auth/sign-in";
        }

        return await axios
            .put(`${defaults.apiURL}${url}`, data, options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response.data.error_code === forbiddenError) {
                    CookieService.remove("authToken");
                    window.location.href = "/auth/sign-in";
                }

                //return error.response.data;
                throw error;
            });
    }

    // DELETE request
    async delete(url, data) {
        const options = {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: CookieService.get("authToken")
                    ? `Bearer ${CookieService.get("authToken")}`
                    : "",
            },
            paramsSerializer: objectToQueryString,
        };

        if (!CookieService.get("authToken")) {
            CookieService.remove("authToken");
            window.location.href = "/auth/sign-in";
        }

        return await axios
            .delete(`${defaults.apiURL}${url}`, options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response.data.error_code === forbiddenError) {
                    CookieService.remove("authToken");
                    window.location.href = "/auth/sign-in";
                }

                throw error;
                // throw new Error(error);
            });
    }

    // POST upload request
    async postUpload(url, data) {
        const options = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                Authorization: CookieService.get("authToken")
                    ? `Bearer ${CookieService.get("authToken")}`
                    : "",
            },
            
            paramsSerializer: objectToQueryString,
        };

        if (
            !CookieService.get("authToken") &&
            url !== "users/verification" &&
            url !== "login"
        ) {
            CookieService.remove("authToken");
            window.location.href = "/auth/sign-in";
        }

        return await axios
            .post(`${defaults.apiURL}${url}`, data, options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response.data.error_code === forbiddenError) {
                    CookieService.remove("authToken");
                    // window.location.href = "/auth/sign-in";
                }

                throw error;
            });
    }

    async postZip(url, data) {

        const options = {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: CookieService.get("authToken")
                    ? `Bearer ${CookieService.get("authToken")}`
                    : "",
            },
            paramsSerializer: objectToQueryString,
            // params: {data},
            responseType: "blob",
        };

        if (
            !CookieService.get("authToken") &&
            url !== "users/verification" &&
            url !== "login"
        ) {
            CookieService.remove("authToken");
            window.location.href = "/auth/sign-in";
        }

        return await axios
            .post(`${defaults.apiURL}${url}`, data, options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (error.response.data.error_code === forbiddenError) {
                    CookieService.remove("authToken");
                    // window.location.href = "/auth/sign-in";
                }

                throw error;
            });
    }
    
}

export default new api();
