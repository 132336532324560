// setInitialValues.js

import { commonBeforeGSTAmount } from "../../../../../utils/utility";

export const setInitialValues = (
    quotation,
    id,
    configValues,
    setValue,
    setSelectedCompany
) => {
    if (!quotation || id === "") {
        return; // No need to proceed if quotation or id is not available
    }

    // Preselect the company by setting its option
    const companyOption = {
        label: quotation?.company?.name,
        value: quotation?.company?.id,
    };
    setSelectedCompany(quotation?.company?.id);

    setValue("company_id", companyOption.value, { shouldValidate: false });
    const locationOption = configValues?.location?.find(
        (option) => option.value === quotation?.location
    );

    if (locationOption) {
        setValue("location", locationOption);
    }
    setValue("sales_user_id", {
        label: quotation?.sales_user?.name,
        value: quotation?.sales_user?.id,
    });

    setValue("address", quotation?.company_addr);
    setValue("no_gst", quotation?.no_gst);
    const companyContactId = quotation.company_contact_id;
    if (companyContactId) {
        setValue("company_contact_person", companyContactId);
    }

    // Initialize an empty array to store the initial items
    const initialItems = quotation.sales_quotation_course.map((item) => {
        const applicationOptionConfig = configValues.tradeApplication?.find(
            (option) => option.value === item.application_option
        );

        const tradeCategoryOptionConfig = configValues.tradeCategory?.find(
            (option) => option.value === item.trade_category
        );

        const courseCodeOptionConfig = configValues.course_code?.find(
            (option) => option.value === item?.course_code?.id
        );
        const tradeOptionConfig = configValues.tradeOptions?.find(
            (option) => option.value === item.type_of_trade
        );

        return {
            id: item.id,
            total_student: item.total_student,
            price:
                item.item_type !== "discount"
                    ? commonBeforeGSTAmount(
                        parseFloat(item.price),
                        quotation.gst
                    )
                    : parseFloat(item.price),
            original_price: parseFloat(item.price),
            item_type: item.item_type,
            trade_option: tradeOptionConfig || null,
            course_code: courseCodeOptionConfig || null,
            application_option: applicationOptionConfig || null,
            trade_category: tradeCategoryOptionConfig || null,
            course: {
                label: item?.course?.name || "",
                value: item?.course?.id || "",
            },
            student_names: item.student_names,
            discount_item: item.discount_item_name,
        };
    });

    setValue("items", initialItems); // Use setValue to set initial values
};
