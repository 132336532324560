import React, { useState, createContext } from "react";

import LoadingOverlay from "react-loading-overlay";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";

import routes from "./routes";
import ChartJsDefaults from "./utils/ChartJsDefaults";
import { store } from "./state";
import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import { AuthProvider } from "./contexts/JWTContext";

export const LoadingContext = createContext();

const App = () => {
    const content = useRoutes(routes);
    const [isLoadingActive, setIsLoadingActive] = useState(false);

    LoadingOverlay.propTypes = undefined;

    return (
        <HelmetProvider>
            <Helmet
                titleTemplate="%s | IntelliTMS"
                defaultTitle="IntelliTMS"
            />
            <Provider store={store}>
                <LoadingContext.Provider
                    value={[isLoadingActive, setIsLoadingActive]}
                >
                    <LoadingOverlay active={isLoadingActive} spinner>
                        <ThemeProvider>
                            <SidebarProvider>
                                <LayoutProvider>
                                    <ChartJsDefaults />
                                    <AuthProvider>{content}</AuthProvider>
                                </LayoutProvider>
                            </SidebarProvider>
                        </ThemeProvider>
                    </LoadingOverlay>
                </LoadingContext.Provider>
            </Provider>
        </HelmetProvider>
    );
};

export default App;
