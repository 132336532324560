import moment from "moment";

export const convertToDecimalWithComma = (number) => {
    const decimal = parseFloat(number).toFixed(2);
    return Number(decimal).toLocaleString("en");
};

export const addDefaultOption = (array = []) => {
    return [{ value: "", label: "Select..." }, ...array];
};

export const scrollToTop = () => {
    window.scrollTo(0, 0);
};

export const roundToTwoDecimalDigits = (number) => {
    // Check if the input is a valid number
    if (typeof number === "number" && !isNaN(number)) {
        // If it's a valid number, round it to two decimal places
        return Number(number.toFixed(2));
    } else {
        // If it's not a valid number, return an error value or handle it as needed
        return null; // You can return null or another appropriate value
    }
};

export const truncateDecimalPlaces = (number, decimalPlaces = 6) => {
    // Check if the input is a valid number
    if (typeof number === "number" && !isNaN(number)) {
        return Number(number.toString().slice(0, number.toString().indexOf('.') + (decimalPlaces + 1)));
    } else {
        return null; // You can return null or another appropriate value
    }
}

export const formatNumberWithCommas = (number) => {
    // Round to two decimal places
    const formattedNumber = parseFloat(number).toFixed(2);
    // Use regex to add commas as thousands separators
    return formattedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatNumberWithCommasNoDecimal = (number) => {
    // Convert to Int
    const formattedNumber = parseInt(number);
    // Use regex to add commas as thousands separators
    return formattedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const calculateCommonSubtotal = (price, totalStudents) => {
    return totalStudents * price;
};

export const calculateCommonBasedBeforeGSTSubtotal = (
    price,
    gstRate,
    totalStudents,
    noGST
) => {
    let beforeGST = truncateDecimalPlaces(price / (1 + gstRate / 100));
    return totalStudents * (noGST ? price : beforeGST);
};

export const beforeGSTAmount = (priceValue, gstRate, noGST) => {
    if (noGST) {
        return parseFloat(priceValue);
    }
    return parseFloat(priceValue) / (1 + gstRate / 100);
};
export const totalGST = (subTotal, gstRate, noGST) => {
    if (noGST) {
        return 0;
    }
    return (subTotal / 100) * gstRate;
};

export const commonBeforeGSTAmount = (priceValue, gstRate) => {
    return truncateDecimalPlaces(
        parseFloat(priceValue) / (1 + gstRate / 100)
    );
};
export const commonAfterGSTAmount = (priceValue, gstRate) => {
    let gst = (parseFloat(priceValue) / 100) * gstRate;
    let total = gst + priceValue;
    return truncateDecimalPlaces(total);
};

export const listTableNumberFormat = (cell, value) => {
    return (
        <td {...cell.getCellProps()} className="td">
            S${formatNumberWithCommas(parseFloat( value))}
        </td>
    );
};

// export const listItemCodes = (cell, value) => {
//     const itemCodes = value
//       ? value
//           .filter(
//             (item) =>
//               item.addtnl_invoice_item_code ||
//               (item.course_fee && item.course_fee.course_code)
//           )
//           .map((item) =>
//             item.addtnl_invoice_item_code
//               ? item.addtnl_invoice_item_code
//               : item.course_fee.course_code
//           )
//           .join(', ')
//       : '';
  
//     return (
//       <td {...cell.getCellProps()} className="td">
//         {itemCodes}
//       </td>
//     );
// };


export const scrollButtonFunction = (
    selector = '.table-container',
    toRight = true,
    scrollBehavior = 'smooth',
    scrollAmount = 100
  ) => () => {
    const container = document.querySelector(selector)?.closest('div');
  
    if (container) {
      const currentScroll = container.scrollLeft;
      const targetScroll = toRight ? currentScroll + scrollAmount : currentScroll - scrollAmount;
  
      container.scrollTo({
        left: targetScroll,
        behavior: scrollBehavior,
      });
    }
  };
  

export const pressEnterToSearch = (event, searchFunction) => {
    if (event.key === 'Enter') {
        searchFunction();
    }
}

export const toUpperInput = (event) => {
    // https://stackoverflow.com/a/49904823
    let p = event.target.selectionStart;
    event.target.value= event.target.value.toUpperCase();
    event.target.setSelectionRange(p, p);
}

export const formatStudentNames = (studentNames) => {
    // Split the string by lines and create an array of objects
    return studentNames
        .split('\n') // Assuming each row is on a new line
        .filter((row) => row && row.trim() !== '') // Filter out empty or undefined rows
        .map((row) => {
            const words = row.trim().split(' ');
            return {
                name: words.slice(0, -1).join(' '),
                id_number: words.slice(-1)[0].replace(/[^a-zA-Z0-9 ]/g, ''),
            };
        })
        .filter((student) => student.name && student.id_number);
}

export const formatCsvStudentNames = (rows) => {
    return rows
        .slice(1) // Skip the first row (header)
        .map((row) => {
            // Check if the row is not empty
            if (row.trim() === '') {
                return null; // Skip empty rows
            }

            const [name, idNumber, _] = row.split(',');

            // Check if name and idNumber are defined
            if (name !== undefined && idNumber !== undefined) {
                return {
                    name: name.trim(),
                    id_number: idNumber.trim(),
                };
            } else {
                return null; // Skip rows with missing data
            }
        })
        .filter((student) => student !== null); // Remove null entries
}


/**
 * Function to receive datetime string with a format of:
 * 'd-m-Y H:i:s' and converts the time to display am and pm
 * 
 * Default initialFormat == "DD-MM-YYYY HH:mm:ss";
 * Default outputFormat == "YYYY-MM-DD hh:mm:ss a";
 * 
 * @param {string} inputString 
 * @param {string} initialFormat
 * @param {string} outputFormat
 * @returns string
 */
export const formatDateTimeString = (inputString, initialFormat = null, outputFormat = null) => {
    const defaultInitialFormat = "DD-MM-YYYY HH:mm:ss";
    const defaultOutputFormat = "YYYY-MM-DD hh:mm:ss a";

    return moment(moment(inputString, initialFormat ?? defaultInitialFormat)).format(outputFormat ?? defaultOutputFormat);
};

export const setLocalStorageValue = (key, value) => {
try {
    const newValue = (() => {
    if (typeof value === 'string') return value
    return JSON.stringify(value)
    })()
    localStorage.setItem(key, newValue);
} catch (error) {
    console.error("Error storing value in local storage:", error);
}
};

export const removeLocalStorageValue = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error("Error removing value from local storage:", error);
    }
};

export const getLocalStorageValue = (key, defaultValue) => {
    try {
        const storedValue = localStorage.getItem(key);
        if (storedValue) {
        try {
            return JSON.parse(storedValue);
        } catch {
            return storedValue
        }
        }
        return defaultValue;
    } catch (error) {
        return defaultValue;
    }
};

export const getAndDeleteLocalStorageValue = (key, defaultValue) => {
    try {
      const storedValue = localStorage.getItem(key);
      if (storedValue) {
        localStorage.removeItem(key);
        try {
          return JSON.parse(storedValue);
        } catch {
          return storedValue
        }
      }
      return defaultValue;
    } catch (error) {
      return defaultValue;
    }
  };

export const tradeCategoryDisplay = ( values ) => {
    return (
        <>
            {values?.item_code && (
                <p><b>Item code:</b> {values.item_code}</p>
            )}
            {values?.type_of_trade && (
                <p><b>Type of trade:</b> {values.type_of_trade}</p>
            )}
            {values?.application_option && (
                <p><b>Application option:</b> {values.application_option}</p>
            )}
            {values?.trade_category && (
                <p><b>Trade category:</b> {values.trade_category}</p>
            )}
            {values?.course_name && (
                <p><b>Course:</b> {values.course_name}</p>
            )}
        </>
    );
};