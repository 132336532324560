import React from "react";

import { Dropdown } from "react-bootstrap";

import { Settings, User } from "react-feather";

import defaultImage from "../../assets/img/default.png";

import useAuth from "../../hooks/useAuth";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const NavbarUser = () => {
    const { data, loading } = useSelector((state) => {
        return state.user;
    });

    const navigate = useNavigate();
    const { signOut } = useAuth();

    return (
        <Dropdown className="nav-item" align="end">
            <span className="d-inline-block d-sm-none">
                <Dropdown.Toggle as="a" className="nav-link">
                    <Settings size={18} className="align-middle" />
                </Dropdown.Toggle>
            </span>
            <span className="d-none d-sm-inline-block">
                <Dropdown.Toggle as="a" className="nav-link">
                    <img
                        src={defaultImage}
                        className="avatar img-fluid rounded-circle me-1"
                        alt="Default"
                    />
                    {!loading && (
                        <span className="text-dark">{data?.full_name}</span>
                    )}
                </Dropdown.Toggle>
            </span>
            <Dropdown.Menu drop="end">
                <Dropdown.Item onClick={() => navigate("/profile")}>
                    <User size={18} className="align-middle me-2" />
                    Profile
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                    onClick={async () => {
                        try {
                            await signOut();

                            navigate("/");
                        } catch (error) {
                            // const message = error.message || "Something went wrong";
                        }
                    }}
                >
                    Sign out
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default NavbarUser;
