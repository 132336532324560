import { Badge } from "react-bootstrap";
import { formatNumberWithCommas } from "../../../../utils/utility";

const tableColumns = [
    {
        Header: "S.no",
        width: "70",
        sticky: "left",
    },
    {
        Header: "Student Name",
        accessor: (data) => {
            return <div className="text-nowrap">{data?.invoice_student_name || data?.student_name || "-"}</div>;
        },
        // accessor: "name",
        width: "500",
    },
    {
        Header: "Fin",
        accessor: (data) => {
            return <div className="text-nowrap">{data?.invoice_student_fin || data?.fin || "-"}</div>;
        },
        // accessor: "name",
        width: "500",
    },
    {
        Header: "Payment Status",
        accessor: (data) => {
            return <div className="text-nowrap">{data?.payment_status || "-"}</div>;
        },
        // accessor: "name",
        width: "500",
    },
    {
        Header: "Invoice Number",
        accessor: (data) => {
            return <div className="text-nowrap">{data?.invoice_number || "-"}</div>;
        },
        // accessor: "name",
        width: "500",
    },
    {
        Header: "Sponsor Company",
        accessor: (data) => {
            return <div className="text-nowrap">{data?.sponsor_company || "-"}</div>;
        },
        // accessor: "name",
        width: "500",
    },
    {
        Header: "Enrollment Status",
        accessor: (data) => {
            return <div className="text-nowrap">{data?.enrollment_status || "-"}</div>;
        },
        // accessor: "name",
        width: "500",
    },
    {
        Header: "Remaining Amount",
        accessor: (data) => {
            return <div className="text-nowrap">{data?.remaining_amount ? formatNumberWithCommas(data?.remaining_amount) : 0.00}</div>;
        },
        // accessor: "name",
        width: "500",
    },
    {
        Header: "Date",
        accessor: (data) => {
            return <div className="text-nowrap">{data?.date || "-"}</div>;
        },
        // accessor: "name",
        width: "500",
    },
];

export { tableColumns };
