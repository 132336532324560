import React, { useCallback, useEffect, useState } from "react";
import { Alert, Card, Container } from "react-bootstrap";
import api from "../../../service/api";
import { Lists } from "./List";
import { tableColumns } from "./data";
import { Helmet } from "react-helmet-async";
import SearchRenewal from "../Schedules/SearchRenewal";
const RenewalSent = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [defaultConfig, setDefaultConfig] = useState();
  const getEnrolments = useCallback(async (search) => {
    setLoading(true);
    try {
      const response = await api.get("tms/renewals?status=sent", search);
      setData(response);
      setError();
    } catch (error) {
      setLoading(false);
      setError(error.message);
      setData([]);
    }

    setLoading(false);
  }, []);

  const [searchValue, setSearchValue] = useState({
    fin: "",
    date: "",
    registration_type: "",
  });
  const search = (page) => {
    const searchParam = { ...searchValue, ...page };
    getEnrolments(searchParam);
  };
  const resetSearch = () => {
    setSearchValue({
      ...searchValue,
      fin: "",
      date: "",
      registration_type: "",
    });
    getEnrolments(searchValue);
  };
  //
  // useEffects
  //

  useEffect(() => {
    getEnrolments(searchValue);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getConfig = async () => {
      const configResponse = await api.get(`tms/enrolments/config`, {});
      setDefaultConfig(configResponse.data);
    };
    getConfig();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <React.Fragment>
      <Helmet title="Renewals > Renewal notification sent" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Renewal program </h1>
        <Card className="m-0 p-0">
          {error && (
            <Card.Header>
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{error}</div>
              </Alert>
            </Card.Header>
          )}
          <Card.Body>
            <SearchRenewal
              search={search}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              defaultConfig={defaultConfig}
              resetSearch={resetSearch}
            />

            {loading && <div>Loading...</div>}
            {!loading && data?.data && (
              <Lists
                columns={tableColumns}
                rawData={data}
                enrolmentApi={search}
                status="sent"
              />
            )}
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default RenewalSent;
