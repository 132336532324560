import React, { useEffect, useCallback, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import Wrapper from "../../../components/Wrapper";
import Sidebar from "../../../components/sidebar/Sidebar";
import Main from "../../../components/Main";
import Navbar from "../../../components/navbar/NavbarComponent";
import Content from "../../../components/Content";
import api from "../../../service/api";
import {
    Briefcase,
    BookOpen,
    Target,
    Calendar,
    Grid,
    Settings,
    Users,
    Key,
    Sliders,
    DollarSign,
    PieChart,
    Clock,
    Activity,
    Feather,
    Truck,
    CreditCard,
} from "react-feather";
import { useSelector } from "react-redux";
import { HelperButtons } from "../../../components/HelperButtons";

const TmsLayout = ({ children }) => {
    const navigate = useNavigate();
    const [finance_link, setFinanceLink] = useState(null);
    const [training_team_link, setTrainingTeamLink] = useState(null);
    
    const { data } = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = data?.permissions;

    useEffect(() => {
        if (data && data.apps) {
            if (data.apps.length > 0) {
                const app = data.apps.filter(
                    (app) => app.name.toUpperCase() === "INTELLITMS"
                );
                if (app?.length === 0) {
                    navigate("/", {
                        state: {
                            authorizationOpen: true,
                            notifMsg: `There is no access for TMS`,
                            severity: "danger",
                        },
                    });
                }
            }
        }
        getLinks();

    }, [data, navigate]);
    
    const getLinks = useCallback(async () => {
        try{
            const response = await api.get('get-links')
            setFinanceLink(response['Links'].FINANCE)
            setTrainingTeamLink(response['Links'].TRAINING_TEAM)
        }catch (error){
            console.error('Error retrieving links: ',error)
        }
    }, []);

    const menuLinks = {
        statistic: [
            {
                href: "/tms/statistic/enrolment-adjustment",
                title: "Enrolment adjustment",
                slug: "ENROLMENT-REPORT-SUMMARY",
            },
            {
                href: "/tms/statistic/sales-revenue",
                title: "Sales revenue",
                slug: "SALES-REVENUE",
            },
            {
                href: "/tms/statistic/paid-company",
                title: "Paid company",
                slug: "PAID-COMPANY",
            },
            {
                href: "/tms/statistic/all-invoice-generated",
                title: "Invoice generated",
                slug: "INVOICE-GENERATED",
            },
            {
                href: "/tms/statistic/enrollments-not-paid",
                title: "Payment Reminder",
                slug: "PAYMENT-PENDING",
            },
            {
                href: "/tms/statistic/monthly-report-cet-coretrade",
                title: "Monthly Report",
                slug: "MONTHLY-REPORT",
            },
        ],
        enrolments: [
            {
                href: "/tms/enrolments/enrolled",
                title: "Registration",
                slug: "ENROLLMENT-READ",
            },
            // {
            //     href: "/tms/batches/add-student-to-class",
            //     title: "Add student to class",
            //     slug: "BATCH-STUDENT-ALLOCATE",
            // },
            {
                href: "/tms/enrolments/class-allocated",
                title: "Class allocated",
                slug: "ENROLLMENT-READ",
            },
            {
                href: "/tms/enrolments/pending-confirmation",
                title: "Pending confirmation",
                slug: "ENROLLMENT-READ",
            },
            {
                href: "/tms/enrolments/training",
                title: "Training going on",
                slug: "ENROLLMENT-READ",
            },
            {
                href: "/tms/enrolments/waiting-for-result",
                title: "Waiting for result",
                slug: "ENROLLMENT-READ",
            },
            {
                href: "/tms/enrolments/completed",
                title: "Completed",
                slug: "ENROLLMENT-READ",
            },
            {
                href: "/tms/enrolments/summary",
                title: "Summary",
                slug: "ENROLLMENT-SUMMARY",
            },
            {
                href: "/tms/enrolments/mark-results",
                title: "Mark results",
                slug: "MARK-RESULTS",
            },
        ],
        batches: [
            {
                href: "/tms/batches/pending",
                title: "Class",
                slug: "BATCH-READ",
            },
            {
                href: "/tms/batches/attendance",
                title: "Attendance",
                slug: "ATTENDANCE-MARK",
            },
            {
                href: "/tms/batches/waiting-for-result",
                title: "Result",
                slug: "BATCH-RESULT",
            },
            {
                href: "/tms/batches/completed",
                title: "Completed",
                slug: "BATCH-COMPLETED",
            },
            {
                href: "/tms/batches/summary",
                title: "Summary",
                slug: "BATCH-SUMMARY",
            },
        ],
        schedules: [
            {
                href: '/tms/schedules/calendar',
                title: 'Scheduling calendar',
                slug: 'SCHEDULE-READ',
            },
            // {
            //     href: "/tms/schedules",
            //     title: "Test dates",
            //     slug: "SCHEDULE-READ",
            // },
            // {
            //     href: "/tms/schedules/training-dates",
            //     title: "Training dates",
            //     slug: "SCHEDULE-READ",
            // },
        ],
        quotations: [
            {
                href: "/tms/quotations",
                title: "Quotation",
                slug: "QUOTATION-READ",
            },
            {
                href: "/tms/invoices",
                title: "Invoice",
                slug: "INVOICE-READ",
            },
            {
                href: "/tms/sales-director-credit-notes",
                title: "Credit note request ",
                slug: "SALES-DIRECTOR",
            },
            {
                href: "/tms/credit-term-approval",
                title: "Credit term approval",
                slug: "SALES-DIRECTOR"
            },
        ],
        finance: [
            {
                href: "/tms/finance-director-credit-notes",
                title: "Credit note request",
                slug: "FINANCE-VIEW-CREDITNOTES",
            },
            {
                href: "/tms/approved-credit-notes",
                title: "Approved credit notes",
                slug: "FINANCE-VIEW-APPROVED-CREDITNOTES",
            },
        ],
        roadshow: [
            {
                href: "/tms/roadshow",
                title: "Roadshow list",
                slug: "ROADSHOW-READ",
            },
        ],
        reports: [
            {
                href: "/tms/payments",
                title: "Payments summary",
                slug: "PAYMENT-REPORT-SUMMARY",
            },
            {
                href: "/tms/summary-credit-notes",
                title: "Credit note summary",
                slug: "CREDIT-NOTE-SUMMARY",
            },
            {
                href: "/tms/invoice-report",
                title: "Invoice summary",
                slug: "INVOICE-REPORT-SUMMARY",
            },
            {
                href: "/tms/student-change-histories",
                title: "Changes summary",
                slug: "STUDENT-CHANGE-HISTORY-SUMMARY",
            },
            {
                href: "/tms/enrolments/summary/report",
                title: "Enrolment summary",
                slug: "ENROLMENT-REPORT-SUMMARY",
            },
            {
                href: "/tms/sales-summary-by-document-type",
                title: "Sales summary by document type",
                slug: "SALES-SUMMARY-BY-DOCUMENT-TYPE",
            },
        ],
        renewals: [
            {
                href: "/tms/renewals/list",
                title: "Renewal list",
                slug: "RENEAL-PROGRAM",
            },
            {
                href: "/tms/renewals/sent-list",
                title: "Renewal notification sent",
                slug: "RENEAL-PROGRAM",
            },
            // {
            //     href: "/tms/renewals/reject-list",
            //     title: "Renewal rejected",
            //     slug: "RENEAL-PROGRAM",
            // },
        ],
        configuration: [
            {
                href: "/tms/companies",
                icon: Target,
                title: "Company",
                slug: "COMPANY-READ",
            },
            {
                href: "/tms/courses",
                icon: BookOpen,
                title: "Course",
                slug: "COURSE-READ",
            },
            {
                href: "/tms/users",
                icon: Users,
                title: "Users",
                slug: "USER-READ",
            },
            {
                href: "/tms/roles",
                icon: Briefcase,
                title: "Roles",
                slug: "ROLE-READ",
            },
            {
                href: "/tms/permissions",
                icon: Key,
                title: "Permissions",
                slug: "PERMISSION-READ",
            },
            {
                href: "/tms/course_fees",
                icon: CreditCard,
                title: "Course Fees",
                slug: "FEE-READ",
            },

        ],
        user_guide: [
            {
                href: training_team_link,
                title: "Training team",
                slug: "TRAINING-USER-GUIDE",
                child_external_link: true,
                
            },
            {
                href: finance_link,
                title: "Finance",
                slug: "FINANCE-USER-GUIDE",
                child_external_link: true,
            },
        ],
    };

    const statisticItems = {
        href: "/enrolment",
        icon: Activity,
        title: "Statistic",
        children: menuLinks?.statistic?.filter((item, index) => {
            if (permission?.includes(item.slug)) {
                return item;
            }
            return null;
        }),
    };

    const enrolmentItems = {
        href: "/enrolment",
        icon: Users,
        title: "Enrolments",
        children: menuLinks?.enrolments?.filter((item, index) => {
            if (permission?.includes(item.slug)) {
                return item;
            }
            return null;
        }),
    };

    const batchItems = {
        href: "/batches",
        icon: Grid,
        title: "Batches/class",
        children: menuLinks?.batches?.filter((item, index) => {
            if (permission?.includes(item.slug)) {
                return item;
            }
            return null;
        }),
    };

    const scheduleItems = {
        href: "/tms/schedules",
        icon: Clock,
        title: "Schedules",
        children: menuLinks?.schedules?.filter((item, index) => {
            if (permission?.includes(item.slug)) {
                return item;
            }
            return null;
        }),
    };
    const quotationItems = {
        href: "/quotations",
        icon: Target,
        title: "Sales",
        children: menuLinks?.quotations?.filter((item, index) => {
            if (permission?.includes(item.slug)) {
                return item;
            }
            return null;
        }),
    };
    const financeItems = {
        href: "/finance",
        icon: DollarSign,
        title: "Finance",
        children: menuLinks?.finance?.filter((item, index) => {
            if (permission?.includes(item.slug)) {
                return item;
            }
            return null;
        }),
    };

    const reportItems = {
        href: "/reports",
        icon: PieChart,
        title: "Reports",
        children: menuLinks?.reports?.filter((item, index) => {
            if (permission?.includes(item.slug)) {
                return item;
            }
            return null;
        }),
    };

    const renewalItems = {
        href: "/renewals",
        icon: Target,
        title: "Renewals",
        children: menuLinks?.renewals?.filter((item, index) => {
            if (permission?.includes(item.slug)) {
                return item;
            }
            return null;
        }),
    };

    const configurationItems = {
        href: "/configuration",
        icon: Settings,
        title: "Configuration",
        children: menuLinks?.configuration?.filter((item, index) => {
            if (permission?.includes(item.slug)) {
                return item;
            }
            return null;
        }),
    };

    const UserGuideItems = {
        href: "/user_guide",
        icon: BookOpen,
        title: "User guide",
        external_link: true,
        children: menuLinks?.user_guide?.filter((item, index) => {
            if (permission?.includes(item.slug)) {
                return item;
            }
            return null;
        }),
    };

    const roadshow = {
        href: "/roadshow",
        icon: Truck,
        title: "Roadshow",
        external_link: true,
        children: menuLinks?.roadshow?.filter((item, index) => {
            if (permission?.includes(item.slug)) {
                return item;
            }
            return null;
        }),
    };

    const pages = [];

    pages.push({
        href: "/tms",
        icon: Sliders,
        title: "Dashboard",
    });

    if (
        statisticItems.hasOwnProperty("href") &&
        statisticItems.hasOwnProperty("children") &&
        statisticItems?.children?.length > 0
    ) {
        pages.push(statisticItems);
    }

    if (
        enrolmentItems.hasOwnProperty("href") &&
        enrolmentItems.hasOwnProperty("children") &&
        enrolmentItems?.children?.length > 0
    ) {
        pages.push(enrolmentItems);
    }

    if (
        batchItems.hasOwnProperty("href") &&
        batchItems.hasOwnProperty("children") &&
        batchItems?.children?.length > 0
    ) {
        pages.push(batchItems);
    }

    if (
        scheduleItems.hasOwnProperty("href") &&
        scheduleItems.hasOwnProperty("children") &&
        scheduleItems?.children?.length > 0
    ) {
        pages.push(scheduleItems);
    }

    if (
        quotationItems.hasOwnProperty("href") &&
        quotationItems.hasOwnProperty("children") &&
        quotationItems?.children?.length > 0
    ) {
        pages.push(quotationItems);
    }

    if (
        roadshow.hasOwnProperty("href") &&
        roadshow.hasOwnProperty("children") &&
        roadshow?.children?.length > 0
    ) {
        pages.push(roadshow);
    }

    if (
        financeItems.hasOwnProperty("href") &&
        financeItems.hasOwnProperty("children") &&
        financeItems?.children?.length > 0
    ) {
        pages.push(financeItems);
    }
    if (
        reportItems.hasOwnProperty("href") &&
        reportItems.hasOwnProperty("children") &&
        reportItems?.children?.length > 0
    ) {
        pages.push(reportItems);
    }

    if (
        renewalItems.hasOwnProperty("href") &&
        renewalItems.hasOwnProperty("children") &&
        renewalItems?.children?.length > 0
    ) {
        pages.push(renewalItems);
    }

    if (
        configurationItems.hasOwnProperty("href") &&
        configurationItems.hasOwnProperty("children") &&
        configurationItems?.children?.length > 0
    ) {
        pages.push(configurationItems);
    }

    if (
        UserGuideItems.hasOwnProperty("href") &&
        UserGuideItems.hasOwnProperty("children") &&
        UserGuideItems?.children?.length > 0
    ) {
        pages.push(UserGuideItems);
    }
    // pages.push({
    //     href: "https://docs.google.com/presentation/d/1-RKscGzvNp7qSpWPYaVLEbLULnLDCq54/edit?usp=drive_link&ouid=110790854632106066828&rtpof=true&sd=true",
    //     icon: Feather,
    //     title: "ATTC user guide",
    // });

    // pages.push({
    //     href: "https://docs.google.com/presentation/d/1-RKscGzvNp7qSpWPYaVLEbLULnLDCq54/edit?usp=drive_link&ouid=110790854632106066828&rtpof=true&sd=true",
    //     icon: Feather,
    //     title: "Finance user guide",
    // });
    const TmsItems = [
        {
            title: "",
            pages: pages,
        },
    ];
    return (
        <React.Fragment>
            <Wrapper>
                {data?.permissions?.length > 0 && (
                    <>
                        <Sidebar
                            title="intelliTMS"
                            items={TmsItems}
                            app="tms"
                        />
                        <Main>
                            <Navbar />
                            <Content>
                                {children}
                                <Outlet />
                            </Content>
                        </Main>
                        <HelperButtons />
                    </>
                )}
            </Wrapper>
        </React.Fragment>
    );
};
export default TmsLayout;
