import React, { useState, useEffect } from "react";
import { Alert, Button, Card, Form } from "react-bootstrap";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import api from "../../../service/api";
import { AddEditExpenseDetails } from "./AddEditExpenseDetails";
import { AddEditExpenseItems } from "./AddEditExpenseItems";
import _ from "lodash";

export const AddEditExpense = ({ project }) => {
    const navigate = useNavigate();
    let { id, action } = useParams();

    const schema = Yup.object().shape({
        // user: Yup.string().required(),
        expense_date: Yup.string().required(),
        is_recurring: Yup.string(),
        interval: Yup.string()
            .nullable()
            .when("is_recurring", {
                is: "Yes",
                then: (schema) => schema.required(),
            }),
        start_date: Yup.string()
            .nullable()
            .when("is_recurring", {
                is: "Yes",
                then: (schema) => schema.required(),
            }),
        end_date: Yup.string()
            .nullable()
            .when("is_recurring", {
                is: "Yes",
                then: (schema) => schema.required(),
            }),
        expense_items: Yup.array().of(
            Yup.object().shape({
                expense_type: Yup.string().required(),
                expense_item_date: Yup.string().required(),
                description: Yup.string().required(),
                amount: Yup.string().required(),
            })
        ),
    });

    //
    // States
    //
    const [editExpenses, setEditExpenses] = useState();
    const [revenue, setRevenue] = useState();
    const [revenueSelect, setRevenueSelect] = useState();
    const [expenseTypes, setExpenseTypes] = useState();
    const [intelliDocsUser, setIntelliDocsUser] = useState();
    const [loading, setLoading] = useState(false);
    const [deleteFiles, setDeleteFiles] = useState([]);
    const [deleteFilesPath, setDeleteFilePath] = useState([]);

    // eslint-disable-next-line no-unused-vars
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [inputFields, setInputFields] = useState({
        user: "",
        expense_date: "",
        is_recurring: "Yes",
        interval: "",
        start_date: "",
        end_date: "",
        total: 0,
        revenue_id: "",
        expense_items: [
            {
                expense_type: "",
                description: "",
                expense_item_date: "",
                attachments: "",
                amount: "",
            },
        ],
    });
    const [files, setFiles] = useState({ 0: [] });

    //
    // Functions
    //

    const handleFormChangeItems = (index, event, isFile = false) => {
        let data = [...inputFields.expense_items];
        let fileData = [];

        if (isFile) {
            _.forIn(event.target.files, function (value, key) {
                if (key !== "length" && key !== "item") {
                    fileData = [...fileData, value.name];
                }
            });

            data[index]["attachment_files"] = fileData;
        }

        data[index][event.target.name] = event.target.value;

        calculateTotalAmount(data);

        setInputFields({
            ...inputFields,
            expense_items: data,
        });
    };

    const addFields = () => {
        let newfield = {
            expense_type: "",
            expense_item_date: "",
            description: "",
            attachments: "",
            amount: "",
        };

        setInputFields({
            ...inputFields,
            expense_items: [...inputFields.expense_items, newfield],
        });
    };

    const removeFields = (index) => {
        let data = [...inputFields.expense_items];
        data.splice(index, 1);

        calculateTotalAmount(data);

        setInputFields({
            ...inputFields,
            expense_items: data,
        });
    };

    const calculateTotalAmount = (data) => {
        const totalAmount = data.reduce(
            (total, current) => +total + +current.amount,
            0
        );

        inputFields["total"] = totalAmount;
    };

    const submitCreateExpense = (values) => {
        const formData = arrangeFormData(values);

        return api.post("expenses", formData);
    };

    const submitEditExpense = (values) => {
        try {
            const formData = arrangeFormData(values);

            return api.post(`update-expenses/${action}`, formData);
        } catch {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    const arrangeFormData = (values) => {
        const formData = new FormData();

        formData.append("is_recurring", values.is_recurring);
        formData.append("start_date", values.start_date || "");
        formData.append("end_date", values.end_date || "");
        formData.append("interval", values.interval);
        formData.append("total", values.total);
        formData.append("user", values.user);
        formData.append("expense_date", values.expense_date);
        formData.append("portfolio_id", project.portfolio_id);
        formData.append("programme_id", project.programme_id);
        formData.append("project_id", id);
        formData.append("revenue_id", values.revenue_id || "");

        for (let key in deleteFiles) {
            formData.append(`delete_files[${key}]`, deleteFiles[key]);
        }

        for (let key in deleteFilesPath) {
            formData.append(`delete_files_path[${key}]`, deleteFilesPath[key]);
        }

        for (let key in values.expense_items) {
            let items;
            items = values.expense_items[key];

            formData.append(
                `expense_items[${key}][expense_type]`,
                items.expense_type
            );
            formData.append(
                `expense_items[${key}][expense_item_date]`,
                items.expense_item_date
            );
            items.id && formData.append(`expense_items[${key}][id]`, items.id);
            formData.append(
                `expense_items[${key}][description]`,
                items.description
            );
            formData.append(`expense_items[${key}][amount]`, items.amount);

            for (let fileKey in files) {
                let fileItems;
                fileItems = files[fileKey];

                fileItems.forEach((item) => {
                    formData.append(
                        `expense_items[${key}][attachments][]`,
                        item
                    );
                });
            }
        }

        return formData;
    };

    const handleFormChangeRevenue = (event, values) => {
        setInputFields({
            ...values,
            revenue_id: event ? event.value : "",
        });
    };

    const deleteUploadedFiles = (index, fileId, data, fullPath) => {
        const newData = data.filter((item) => {
            return item.id !== fileId;
        });

        inputFields.expense_items[index].attachments = newData;

        setDeleteFiles([...deleteFiles, fileId]);
        setDeleteFilePath([...deleteFilesPath, fullPath]);
        setInputFields({
            ...inputFields,
        });
    };

    //
    // useEffects
    //

    useEffect(() => {
        if (action !== undefined && action !== "create") {
            const getExpense = async () => {
                try {
                    const response = await api.get(`expenses/${action}`, {});

                    setEditExpenses(response.data);
                } catch (error) {
                    setNotif({
                        notifMsg: "Something went wrong with the server",
                        open: true,
                        severity: "danger",
                    });
                }
            };
            getExpense();
        }
    }, [action]);

    useEffect(() => {
        if (editExpenses) {
            setInputFields({
                user: editExpenses.user,
                expense_date: editExpenses.expense_date,
                is_recurring: editExpenses.is_recurring,
                interval: editExpenses.interval,
                start_date: editExpenses.start_date,
                end_date: editExpenses.end_date,
                total: editExpenses.total,
                expense_items: editExpenses.expense_items,
                revenue_id: editExpenses.revenue_id,
            });
        }
    }, [editExpenses]);

    useEffect(() => {
        const getExpenseType = async () => {
            try {
                const response = await api.get("expense-type/config", {});
                setExpenseTypes(response.data);
            } catch (error) {
                setNotif({
                    notifMsg: "Something went wrong with the server",
                    open: true,
                    severity: "danger",
                });
            }
        };

        const getUsers = async () => {
            try {
                const response = await api.get("intellidocs-users", {});
                setIntelliDocsUser(response.data);
            } catch (error) {
                setNotif({
                    notifMsg: "Something went wrong with the server",
                    open: true,
                    severity: "danger",
                });
            }
        };

        getUsers();
        getExpenseType();
    }, []);

    useEffect(() => {
        const getRevenue = async () => {
            try {
                const response = await api.get("revenue-options", {
                    project_id: id,
                    revenue_id: editExpenses && editExpenses?.revenue_id,
                });

                const mappedRevenue = (revenue = response.data) => {
					const array = [];
					setLoading(false);
			
					revenue &&
						revenue.map((data, index) => {
							return array.push({
								value: data.id,
								label: data.code,
							});
						});
			
					if (editExpenses && editExpenses?.revenue_id) {
						const revenueSelect = array.find((obj) => {
							return obj.value === editExpenses?.revenue_id;
						});
			
						setRevenueSelect(revenueSelect);
					}
			
					setLoading(true);
					return array;
				};

                setRevenue(mappedRevenue);
            } catch (error) {
                setNotif({
                    notifMsg: "Something went wrong with the server",
                    open: true,
                    severity: "danger",
                });
            }
        };

        getRevenue();
    }, [id, action, editExpenses]);

    return (
        <>
            <Card className="shadow-none mb-0">
                <Card.Header className="shadow-none mb-0 pb-0">
                    <Card.Title tag="h5">
                        {action === "create" ? "Create" : "Edit"} expense
                    </Card.Title>
                </Card.Header>
                <Card.Body className="pb-0">
                    <Formik
                        enableReinitialize
                        initialValues={inputFields}
                        validationSchema={schema}
                        onSubmit={async (
                            values,
                            { setErrors, setStatus, setSubmitting }
                        ) => {
                            try {
                                action === "create"
                                    ? await submitCreateExpense(values)
                                    : await submitEditExpense(values);

                                navigate(
                                    `/intellidocs/process/expenses/${id}`,
                                    {
                                        state: {
                                            open: true,
                                            notifMsg: `Successfully ${
                                                action === "create"
                                                    ? "created"
                                                    : "updated"
                                            } expense`,
                                            severity: "success",
                                        },
                                    }
                                );
                            } catch (error) {
                                const message =
                                    error.message || "Something went wrong";

                                setStatus({ success: false });
                                setErrors({ submit: message });
                                setSubmitting(false);
                                setNotif({
                                    notifMsg:
                                        "Something went wrong with the server",
                                    open: true,
                                    severity: "danger",
                                });
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            touched,
                            values,
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                {errors.submit && (
                                    <Alert className="my-3" variant="danger">
                                        <div className="alert-message">
                                            {errors.submit}
                                        </div>
                                    </Alert>
                                )}
                                <h6>Expense items</h6>
                                <div className="bg-light p-4 rounded mb-3">
                                    <AddEditExpenseItems
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        handleBlur={handleBlur}
                                        handleFormChangeItems={
                                            handleFormChangeItems
                                        }
                                        addFields={addFields}
                                        removeFields={removeFields}
                                        files={files}
                                        setFiles={setFiles}
                                        expenseTypes={expenseTypes}
                                        deleteUploadedFiles={
                                            deleteUploadedFiles
                                        }
                                    />
                                </div>
                                <h6>Expense details</h6>
                                <div className="bg-light p-4 mb-3 rounded">
                                    <AddEditExpenseDetails
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        intelliDocsUser={intelliDocsUser}
                                        loading={loading}
                                        handleFormChangeRevenue={
                                            handleFormChangeRevenue
                                        }
                                        revenue={revenue}
                                        revenueSelect={revenueSelect}
                                    />
                                </div>
                                <Card.Footer className="text-center pb-0">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className="me-2"
                                        size="lg"
                                    >
                                        <FontAwesomeIcon icon={faPaperPlane} />{" "}
                                        Save
                                    </Button>
                                    <Button
                                        variant="link"
                                        onClick={() =>
                                            navigate(
                                                `/intellidocs/process/expenses/${id}`
                                            )
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </Card.Footer>
                            </Form>
                        )}
                    </Formik>
                </Card.Body>
            </Card>
        </>
    );
};
