const tableHeader = [
    {
        Header: "S.no",
        accessor: "sequence_no",
    },
    {
        Header: "Name",
        accessor: "full_name",
    },
    {
        Header: "Email",
        accessor: "email",
    },
    {
        Header: "Role(s)",
        accessor: "roles",
    },
    {
        Header: "Actions",
        accessor: "actions",
    },
];

export { tableHeader };
