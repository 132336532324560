import React, { useEffect, useState, useCallback } from "react";
import api from "../../../service/api";
import {
    Container,
    Col,
    Form,
    Row,
    Button,
    Alert,
    Tab,
    Nav,
} from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { SnackbarAlert } from "../../../components/SnackbarAlert.js";
import Select from "react-select";
import { addDefaultOption } from "../../../utils/utility";

export const AddEditUser = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { app_id } = location.state;
    const add_page = id === "add" ? true : false;
    const schema = Yup.object().shape({
        first_name: Yup.string().required("This field is required"),
        last_name: Yup.string().required("This field is required"),
        email: Yup.string()
            .email("Invalid email address")
            .required("This field is required"),
    });

    //States
    const [user, setUser] = useState({});
    const [roles, setRoles] = useState([]);
    const [prettyRoles, setPrettyRoles] = useState([]);
    const [applications, setApplications] = useState([]);
    const [prettyApps, setPrettyApps] = useState([]);
    const [initValues, setInitialValues] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        department: "",
        sap_id: "",
    });
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    // handlechange
    const handleChangeSelect = (options, values) => {
        const selectedDepartment = options.map((option) => {
            return option.value;
        });
        setInitialValues(() => ({
            first_name: values?.first_name,
            last_name: values?.last_name,
            email: values?.email,
            password: values?.password,
            department: selectedDepartment,
            sap_id: values?.sap_id,
        }));
    };

    //Functions
    const getUser = useCallback(async () => {
        const response = await api.get("users/" + id, {});
        setUser(response.data);
    }, [id]);
    const getApplications = useCallback(async () => {
        const response = await api.get("apps", { app_id: app_id });
        setApplications(response.data);
    }, [app_id]);

    const getRoles = useCallback(async () => {
        const response = await api.get("roles", {});
        setRoles(response.data);
    }, []);

    //UseEffects
    useEffect(() => {
        if (!add_page) getUser();
        getRoles();
        getApplications();
    }, [add_page, getUser, getRoles, getApplications]);

    useEffect(() => {
        if (user) {
            setInitialValues({
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                department: user?.department,
                sap_id: user?.sap_id,
            });
        }
    }, [user]);

    useEffect(() => {
        let pretty_roles = [];
        let pretty_apps = [];
        if (applications) {
            applications?.forEach((app) => {
                let obj = {
                    id: app.id,
                    name: app.name,
                    checked: false,
                };

                if (user) {
                    //EDIT
                    if (!add_page) {
                        user?.apps?.forEach((user_app) => {
                            if (app.id === user_app.id) {
                                obj.checked = true;
                            }
                        });
                    }
                }

                pretty_apps.push(obj);

                pretty_roles[app.name] = [];
                if (roles) {
                    roles?.forEach((role) => {
                        if (role.app_id === app.id) {
                            let obj1 = {
                                id: role.id,
                                name: role.name,
                                app_id: role.app_id,
                                disabled: true,
                                checked: false,
                            };

                            //EDIT
                            if (user) {
                                if (!add_page) {
                                    user?.roles?.forEach((user_role) => {
                                        if (role.id === user_role.id) {
                                            obj1.checked = true;
                                        }
                                        if (role.app_id === user_role.app_id) {
                                            obj1.disabled = false;
                                        }
                                    });
                                }
                            }

                            pretty_roles[app.name].push(obj1);
                        }
                    });
                }
            });
        }
        setPrettyRoles(pretty_roles);
        setPrettyApps(pretty_apps);
    }, [applications, roles, add_page, user]);

    const submitForm = (values, actions) => {
        let subscribe = add_page
            ? api.post("users", values)
            : api.put("users/" + id, values);

        subscribe
            .then((response) => {
                if (!response.success) {
                    setNotif({
                        notifMsg: response.message,
                        open: true,
                        severity: "danger",
                    });
                }
                if (response.status === "ok") {
                    setNotif({
                        notifMsg: "Successfully saved",
                        open: true,
                        severity: "success",
                    });

                    if (add_page) {
                        navigate("/users");
                    }
                }
            })
            .catch((error) => {
                actions.setSubmitting(false);
                setNotif({
                    notifMsg: error.message,
                    open: true,
                    severity: "danger",
                });
            });
    };

    const submitAppRoles = () => {
        // Get all checked apps
        let app_ids = prettyApps
            .filter((app) => {
                return app.checked === true;
            })
            .map((item) => {
                return item.id;
            });

        // Get all checked roles
        let role_ids = [];
        Object.keys(prettyRoles).forEach((key) => {
            prettyRoles[key].filter((role) => {
                if (role.checked) {
                    role_ids.push(role.id);
                }
                return true;
            });
        });

        let body = {
            app_ids: app_ids,
            role_ids: role_ids,
        };

        api.post("users/" + id + "/addrole", body)
            .then((response) => {
                if (!response.success) {
                    setNotif({
                        notifMsg: response.message,
                        open: true,
                        severity: "danger",
                    });
                }
                if (response.status === "ok") {
                    setNotif({
                        notifMsg: "Successfully saved",
                        open: true,
                        severity: "success",
                    });
                }
            })
            .catch((error) => {
                setNotif({
                    notifMsg: error.message,
                    open: true,
                    severity: "danger",
                });
            });
    };
    const applicationChangeHandler = (event, app_name, app_index) => {
        let checkedValue = event.target.checked;

        //APPS
        let temp_apps = [...prettyApps];
        temp_apps[app_index].checked = checkedValue;
        setPrettyApps(temp_apps);

        //ROLES
        let temp_roles = { ...prettyRoles };
        let temp_role = [...temp_roles[app_name]];
        temp_role.forEach((role) => {
            role.disabled = checkedValue ? false : true;
            role.checked = false;
        });
        setPrettyRoles(temp_roles);
    };

    const roleChangeHandler = (event, app_name, role_index) => {
        let checkedValue = event.target.checked;

        let temp_roles = { ...prettyRoles };
        let temp_role = [...temp_roles[app_name]];
        temp_role[role_index].checked = checkedValue;
        setPrettyRoles(temp_roles);
    };
    const BasicInformationTab = () => {
        return (
            <Formik
                enableReinitialize
                initialValues={initValues}
                validationSchema={schema}
                onSubmit={(values, actions) => submitForm(values, actions)}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        {errors.submit && (
                            <Alert className="my-3" variant="danger">
                                <div className="alert-message">
                                    {errors.submit}
                                </div>
                            </Alert>
                        )}

                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>First name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="first_name"
                                        value={values.first_name}
                                        isInvalid={Boolean(
                                            touched.first_name &&
                                                errors.first_name
                                        )}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                    {!!touched.first_name && (
                                        <Form.Control.Feedback type="invalid">
                                            {errors.first_name}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Last name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="last_name"
                                        value={values.last_name}
                                        isInvalid={Boolean(
                                            touched.last_name &&
                                                errors.last_name
                                        )}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                    {!!touched.last_name && (
                                        <Form.Control.Feedback type="invalid">
                                            {errors.last_name}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={values.email}
                                        isInvalid={Boolean(
                                            touched.email && errors.email
                                        )}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                    {!!touched.email && (
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                {add_page && (
                                    <Form.Group className="mb-3">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            value={values.password}
                                            isInvalid={Boolean(
                                                touched.password &&
                                                    errors.password
                                            )}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>SAP ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="sap_id"
                                        value={values.sap_id}
                                        isInvalid={Boolean(
                                            touched.sap_id && errors.sap_id
                                        )}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                    {!!touched.sap_id && (
                                        <Form.Control.Feedback type="invalid">
                                            {errors.sap_id}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>

                        {applications?.filter((item) => {
                            if (item?.name?.toUpperCase() === "INTELLITMS") {
                                return item;
                            }
                            return null;
                        }).length > 0 && (
                            <Row>
                                <Col md={12}>
                                    <Form.Label>Department (tms)</Form.Label>
                                    <Select
                                        className="is-invalid react-select-container"
                                        classNamePrefix="react-select "
                                        options={addDefaultOption([
                                            { label: "ATO", value: "ATO" },
                                            { label: "ATTC", value: "ATTC" },
                                        ])}
                                        name="department"
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={true}
                                        onChange={(event) =>
                                            handleChangeSelect(event, values)
                                        }
                                        defaultValue={values?.department?.map(
                                            (department) => {
                                                return {
                                                    value: department?.toUpperCase(),
                                                    label: department?.toUpperCase(),
                                                };
                                            }
                                        )}
                                    />
                                </Col>
                            </Row>
                        )}
                        <Row className="mt-3">
                            <Col
                                md={{ span: 3, offset: 9 }}
                                className="text-end"
                            >
                                <Button
                                    variant="secondary"
                                    className="me-2"
                                    onClick={() => navigate(-1)}
                                >
                                    Back
                                </Button>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        );
    };

    const AppRolesPermissionTab = () => {
        return (
            <>
                <Row>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <h4 className="mb-3">Applications</h4>
                            {prettyApps.map((app, i) => {
                                return (
                                    <Form.Group key={app.id} className="mb-2">
                                        <Form.Check
                                            type="checkbox"
                                            id={app.id}
                                            label={app.name}
                                            checked={app.checked}
                                            onChange={(e) =>
                                                applicationChangeHandler(
                                                    e,
                                                    app.name,
                                                    i
                                                )
                                            }
                                            disabled={app.id === app_id}
                                        />
                                    </Form.Group>
                                );
                            })}
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mb-3">
                            <h4 className="mb-3">Roles</h4>
                            {Object.keys(prettyRoles).map((key, i) => (
                                <div key={i}>
                                    <h5>{key}</h5>
                                    {prettyRoles[key].map((role, i2) => {
                                        return (
                                            <Form.Group
                                                key={role.id}
                                                className="mb-2"
                                            >
                                                <Form.Check
                                                    type="checkbox"
                                                    id={role.id}
                                                    label={role.name}
                                                    disabled={role.disabled}
                                                    checked={role.checked}
                                                    onChange={(e) =>
                                                        roleChangeHandler(
                                                            e,
                                                            key,
                                                            i2
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        );
                                    })}
                                </div>
                            ))}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 3, offset: 9 }} className="text-end">
                        <Button
                            variant="secondary"
                            className="me-2"
                            onClick={() => navigate(-1)}
                        >
                            Back
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => submitAppRoles()}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </>
        );
    };

    return (
        <>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">
                    {add_page ? "Add user" : "Edit user"}
                </h1>
                <div className="tab">
                    <Tab.Container defaultActiveKey="basic_information">
                        <Nav variant="tabs">
                            <Nav.Item>
                                <Nav.Link
                                    eventKey="basic_information"
                                    onClick={() => null}
                                >
                                    Basic information
                                </Nav.Link>
                            </Nav.Item>
                            {!add_page && (
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="roles_and_permission"
                                        onClick={() => null}
                                    >
                                        App, Roles and Permission
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="basic_information">
                                <BasicInformationTab />
                            </Tab.Pane>
                            <Tab.Pane eventKey="roles_and_permission">
                                <AppRolesPermissionTab />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
                <SnackbarAlert notif={notif} setNotif={setNotif} />
            </Container>
        </>
    );
};
