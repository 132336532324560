import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Eye, FilePlus, Check, User} from "react-feather";

import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthContext } from "../../../../../contexts/JWTContext";
import { setLocalStorageValue } from "../../../../../utils/utility";

const ListButton = ({ id, currentPage, type, setShow, setModalType, setModalLoading, setModalData,setInvoiceId}) => {
    const navigate = useNavigate();
    const location = useLocation();
    /** permissions */
    const { data } = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = data?.permissions;

    const setModalPopUp = (status, id = "", type="APPROVAL") => {
        setModalLoading(true);
        setModalType(type);
        setShow(status);
        setInvoiceId(id);
        setModalLoading(false);
        setModalData({
            remarks: "",
            user_id: AuthContext?.user?.id ?? null
        })
    };

    return (
        <>
            {permission?.includes("INVOICE-READ") && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>View invoice</Tooltip>}
                >
                    <span>
                        <a
                            href={`/tms/invoices/view/${id}?option=view?page=${currentPage}&type=${type}`}
                            target="_blank"
                            rel="noreferrer"
                            className="me-1"
                            onClick={() => setLocalStorageValue('invoice-back-button-redirect', location?.pathname ?? null)}
                        >
                            <span>
                                <Eye size="20" color="#6c757d" />
                            </span>
                        </a>
                    </span>
                </OverlayTrigger>
            )}
            {permission?.includes("INVOICE-MANAGE-STUDENT") && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Manage students</Tooltip>}
                >
                    <span>
                        <a
                            href={`/tms/invoices/manage-students/${id}?page=${currentPage}`}
                            target="_blank"
                            rel="noreferrer"
                            className="me-1"
                        >
                            <span>
                                <User size="20" color="#6c757d" />
                            </span>
                        </a>
                    </span>
                </OverlayTrigger>
            )}
            {permission?.includes("SALES-DIRECTOR") && (
                // <Button
                //     variant="success"
                //     className="ms-3"
                //     
                // >
                //     Approval
                // </Button>
                <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Approve</Tooltip>}
                >
                    <span>
                        <a
                            className="me-1"
                            onClick={() => {
                                setModalPopUp(true, id);
                            }}
                        >
                            <span>
                                <Check size="20" color="#6c757d" />
                            </span>
                        </a>
                    </span>
                </OverlayTrigger>
            )}
        </>
    );
};
export default ListButton;
