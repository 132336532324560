import React, { useCallback, useEffect, useState } from "react";

import { Badge, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { SnackbarAlert } from "../../../../components/SnackbarAlert";

import api from "../../../../service/api";
import SearchEnrolment from "../../CommonComponents/SearchEnrolment";
import ResultBulkStudent from "../../Results/ResultBulkStudent";

export const EnrolledMarkResults = () => {
    const [classStudents, setClassStudents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const previousSearchValue = state?.previousSearchValue
        ? state?.previousSearchValue
        : null;

    const searchFields = {
        name: "",
        fin: "",
        date: "",
        registration_type: "",
        status: "",
        type: "",
        trade_category: "",
        type_of_trade: "",
        application_option: "",
        course_id: "",
        company_id: "",
        result_date: "",
    };

    const { state } = useLocation();
    const [searchValue, setSearchValue] = useState(
        previousSearchValue ? previousSearchValue : searchFields
    );

    const search = (page) => {
        const searchParam = { ...searchValue, ...page };
        setSearchValue(searchParam);
        resultStudentApi(searchParam);
    };

    const resetSearch = () => {
        setSearchValue(searchFields);
        resultStudentApi(searchFields);
    };

    const [defaultConfig, setDefaultConfig] = useState();
    const tableColumns = [
        {
            Header: "S.no",
            width: "60",
            sticky: "left",
        },
        {
            Header: "Name",
            accessor: "name",
            width: "200",
            sticky: "left",
        },
        {
            Header: "FIN",
            accessor: "fin",
            width: "200",
            sticky: "left",
        },
        {
            Header: "Sponsor Company",
            accessor: "sponsor_company",
            Cell: ({ row }) => {
                return (
                    <div className="text-start">
                        {row.original?.sponsor_company ? (
                            row.original.sponsor_company.name
                        ) : (
                            <Badge bg="primary">SELF</Badge>
                        )}
                    </div>
                );
            },
            width: "200",
        },
        {
            Header: "Course",
            accessor: "course",
            Cell: ({ row }) => {
                return (
                    <div className="text-start">
                        {row.original?.course ? row.original.course.name : "-"}
                    </div>
                );
            },
            width: "200",
        },
        {
            Header: "Trade Category",
            accessor: "trade_category",
            width: "200",
        },
        {
            Header: "Type of Trade",
            accessor: "type_of_trade",
            width: "200",
        },
        {
            Header: "Batch",
            accessor: "batch",
            Cell: ({ row }) => {
                return (
                    <div className="text-end">
                        {row.original?.batch?.batch
                            ? row.original.batch.batch.name
                            : "-"}
                    </div>
                );
            },
            width: "200",
        },

        {
            Header: "Test date",
            accessor: "test_date",
            width: "200",
            Cell: ({ row }) => {
                return (
                    <div className="text-end">
                        {row.original?.batch?.batch
                            ? row.original.batch.batch.test_date
                            : row.original.attc_test_date}
                    </div>
                );
            },
        },
    ];

    const resultStudentApi = useCallback(async (search) => {
        setLoading(true);

        try {
            const response = await api.get(`tms/waiting-for-result?all=1`, {
                ...search,
            });

            if (response.status === "ok") {
                setClassStudents(response.data);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        resultStudentApi();
    }, []); //  eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <Helmet title="Enrolments > Mark results" />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h1 className="h3 mb-3">Mark results</h1>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <SearchEnrolment
                            search={search}
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                            defaultConfig={defaultConfig}
                            resetSearch={resetSearch}
                            attcTestDate
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="12" xl="12">
                        <ResultBulkStudent
                            data={classStudents}
                            columns={tableColumns}
                            batch={[]}
                            getBatchStudentsApi={resultStudentApi}
                            isSummaryPage="summary"
                            setNotif={setNotif}
                            loading={loading}
                            searchValue={searchValue}
                        />
                    </Col>
                </Row>
            </Container>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </React.Fragment>
    );
};
