// import { LOADING, SUCCEED, FAILED, CLEAR } from "../action-types";
const courseReducer = (state = [], { type, payload }) => {
  switch (type) {
    case "COURSE_LOADING":
      return { loading: true, error: null, data: [] };

    case "COURSE_SUCCEED":
      return { loading: false, error: null, data: payload };

    case "COURSE_FAILED":
      return { loading: false, error: payload, data: [] };

    case "COURSE_CLEAR":
      return { loading: false, error: null, data: [] };
    default:
      return state;
  }
};
export default courseReducer;
