import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

import { Helmet } from "react-helmet-async";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { PlusCircle, Search } from "react-feather";

import { TablePagination } from "../../ShareComponents/TablePagination";
import { tableColumns } from "./tableColumnProject";
import { SnackbarAlert } from "../../../../components/SnackbarAlert";
import api from "../../../../service/api";
import { LoadingContext } from "../../../../App";

export const RerenderContext = createContext();

export const Project = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    //
    // States
    //

    const setIsLoadingActive = useContext(LoadingContext);
    const [projects, setProjects] = useState();
    const [rerender, setRerender] = useState(false);
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [searchValue, setSearchValue] = useState({
        project_code: "",
        status: "",
    });

    //
    // Functions
    //

    const getProjectList = useCallback(async (param = null) => {
        const response = await api.get("intellibuild/project-set-ups", {
            ...param,
        });

        response && setProjects(response);
        setIsLoadingActive(false);
    }, []);

    const search = (page) => {
        const searchParam = { ...searchValue, ...page };
        getProjectList(searchParam);
    };

    //
    // UseEffects
    //

    useEffect(() => {
        setIsLoadingActive(true);
        getProjectList();
    }, [getProjectList, rerender]);

    useEffect(() => {
        if (state?.open) {
            setNotif({
                notifMsg: state.notifMsg,
                open: state.open,
                severity: state.severity,
            });
        }
        window.history.replaceState(null, "");
    }, [state]);

    return (
        <RerenderContext.Provider value={[setRerender, rerender]}>
            <React.Fragment>
                <Helmet title="Project list" />
                <Container fluid className="p-0">
                    <h1 className="h3 mb-4">Project list</h1>
                    <Row>
                        <Col md={10}>
                            <Row>
                                <Col md={3}>
                                    <Form.Control
                                        type="text"
                                        className="d-inline-block"
                                        placeholder="Project code"
                                        onChange={(e) =>
                                            setSearchValue({
                                                ...searchValue,
                                                project_code: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Control
                                        className="d-inline-block"
                                        type="text"
                                        placeholder="Status"
                                        onChange={(e) =>
                                            setSearchValue({
                                                ...searchValue,
                                                status: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col md={3}>
                                    <Button
                                        variant="primary"
                                        className="me-1 mb-1"
                                        onClick={() => search({ page: 1 })}
                                    >
                                        <Search className="feather" />
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={2}>
                            <h1 className="h3 mb-3">
                                {" "}
                                <Button
                                    variant="primary"
                                    className="m-1 float-end mb-2"
                                    onClick={() =>
                                        navigate(`/intellibuild/projects/add`)
                                    }
                                >
                                    <PlusCircle className="feather" />
                                </Button>
                            </h1>
                        </Col>
                    </Row>
                    {projects && (
                        <TablePagination
                            columns={tableColumns}
                            module="projects"
                            parentApi={getProjectList}
                            project="intellibuild"
                            rawData={projects}
                        />
                    )}
                </Container>
            </React.Fragment>

            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </RerenderContext.Provider>
    );
};
