import React from "react";

const ChangeStudentDataDetails = ({ changeStudentData }) => {
    return (
        <>
            <h4>Current Student</h4>
            <table className="table table-bordered">
                <tbody>
                    <tr>
                        <td width="10%">Name</td>
                        <td>{changeStudentData?.student?.name}</td>
                        <td width="10%">NRIC/FIN</td>
                        <td>{changeStudentData?.student?.fin}</td>
                        <td width="10%">Passport</td>
                        <td>{changeStudentData?.student?.passport}</td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};
export default ChangeStudentDataDetails;
