const tableColumns = [
    {
        Header: "S/N",
        accessor: "serial",
    },
    {
        Header: "Expense code",
        accessor: "code",
    },
    {
        Header: "Expense date",
        accessor: "expense_date",
        class: "text-end",
    },
    {
        Header: "Is recurring",
        accessor: "is_recurring",
    },
    {
        Header: "Total (S$)",
        accessor: "total",
        class: "text-end",
    },
    {
        Header: "Status",
        accessor: "status",
    },
    {
        Header: "Actions",
        accessor: "action ",
    },
];

export { tableColumns };
