import { Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";

import api from "../../../../../service/api";
import { SnackbarAlert } from "../../../../../components/SnackbarAlert";
import ChangeStudentDataDetails from "./ChangeStudentDataDetails";
import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";
import { FileUpload } from "../../../../../components/FileUpload";
import { addDefaultOption } from "../../../../../utils/utility";

const AddCourseStudent = ({
    invoiceCourseId,
    setAddStudent,
    getInvoiceCourseStudentApi,
    getInvoiceApi,
    invoiceId,
    changeStudentData,
    setChangeStudentData,
    studentFin = null,
    setNotif,
}) => {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [getFin, setGetFin] = useState(false);

    const defaultObject = {
        name: "",
        email: "",
        passport: "",
        nationality: "",
        gender: "",
        date_of_birth: "",
        fin: studentFin ?? "",
        work_permit_number: "",
        work_permit_expire_date: "",
        // work_permit_category: "",
        identification_type: "",
        sector: "",
        change_student_remarks: "",
        contact_number: "",
        eligibility_period_for_an: "",
        coretrade_multi_skill_direct: "",
    };
    const [initValues, setInitValues] = useState(defaultObject);

    const [files, setFiles] = useState([]);

    const [configValues, setConfigValues] = useState({
        nationality: [],
        workPermits : [],
        identification_type: [
            { value: "EP", label: "EP" },
            { value: "WP", label: "WP" },
            { value: "SVP", label: "SVP" },
            { value: "S-PASS", label: "S Pass" },
            { value: "SINGAPOREAN", label: "Singaporean" },
            { value: "SINGAPORE-PR", label: "Singapore PR" },
            { value: "OTHERS", label: "Others" },
        ],
        sector: [
            { value: "CONSTRUCTION", label: "Construction" },
            { value: "SERVICE", label: "Service" },
            { value: "MANUFACTURING", label: "Manufacturing" },
            {
                value: "MARINE",
                label: "Marine",
            },
            {
                value: "LANDSCAPING",
                label: "Landscaping",
            },
            {
                value: "PROCESS-MAINTENANCE",
                label: "Process maintenance",
            },
            {
                value: "OTHERS",
                label: "Others",
            },
        ],
        gender: [],
    });
    /** Notification state */
    // const [notif, setNotif] = useState({
    //     notifMsg: "",
    //     open: false,
    //     severity: "success",
    // });

    /** validatiob schema */
    const schema = Yup.object().shape({
        name: Yup.string().required(),
        fin: Yup.string().required(),
        passport: Yup.string().required(),
        nationality: Yup.string().required(),
    });

    /** HandleInputChange */
    const handleInputChange = (event) => {
        const data = { ...initValues };
        data[event.target.name] = event.target.value;
        setInitValues({ ...data });
    };

    /** HandleInputChange */
    const handleInputSelectChange = (event, name) => {
        const data = { ...initValues };
        data[name] = event.value;
        setInitValues({ ...data });
    };

    /** Get Student by Fin */
    const getStudent = async () => {
        if (initValues?.fin !== "") {
            try {
                const response = await api.get(
                    `tms/student-search?fin=${initValues?.fin}`
                );
                if (response?.data.length === 0) {
                    setInitValues({ ...defaultObject, fin: initValues?.fin });
                } else {
                    setInitValues({ ...response?.data });
                }
                if (!studentFin){
                    setNotif({
                        notifMsg:
                            response?.data.length === 0
                                ? "student not in our system"
                                : "Already have this students",
                        open: true,
                        severity:
                            response?.data.length === 0 ? "danger" : "success",
                    });
                }
            } catch (error) {
                setNotif({
                    notifMsg: error?.message,
                    open: true,
                    severity: "danger",
                });
                setError(error?.message);
            }
        }
        setGetFin(true);
    };

    /**
     * Create students
     */
    const createOrUpdate = async (values, isUpdate = false) => {
        const data = formatFormData(values, isUpdate);
        try {
            const response = await api.post(
                `tms/invoice-courses/${invoiceCourseId}/invoice-course-students`,
                data
            );
            if (response.status === 'ok') {
                const successMessage = isUpdate
                    ? "Successfully updated student information"
                    : "Successfully added the student";
    
                setNotif({
                    notifMsg: successMessage,
                    open: true,
                    severity: "success",
                });
            }
            closeStudentForm();
        } catch (error) {
            setError(error?.response?.data?.message);
        }
    };

    /** format the student data using formdata */
    const formatFormData = (values, updateStudent = false) => {
        const formData = new FormData();
        formData.append("fin", values?.fin);
        formData.append("name", values?.name);
        formData.append("email", values?.email || "");
        formData.append("nationality", values?.nationality || "");
        formData.append("gender", values?.gender);
        formData.append("passport", values?.passport);
        // formData.append("work_permit_category", values?.work_permit_category);
        formData.append("identification_type", values?.identification_type);
        formData.append("sector", values?.sector);
        formData.append("date_of_birth", values?.date_of_birth);
        formData.append("contact_number", values?.contact_number);
        formData.append(
            "eligibility_period_for_an", 
            values?.eligibility_period_for_an
        );
        formData.append(
            "coretrade_multi_skill_direct", 
            values?.coretrade_multi_skill_direct
        );
        formData.append(
            "work_permit_expire_date",
            values?.work_permit_expire_date
        );
        formData.append("work_permit_number", values?.work_permit_number);
        formData.append(
            "work_permit_number",
            values?.work_permit_number
        );
        formData.append("date_of_birth", values?.date_of_birth);
        const changeStudentId =
            changeStudentData?.id !== "" && changeStudentData?.id !== undefined
                ? changeStudentData?.id
                : "";

        formData.append("change_course_student_id", changeStudentId);
        formData.append("update_student_info", Boolean(updateStudent));
        formData.append(
            "change_student_remarks",
            values?.change_student_remarks ?? null
        );
        if (changeStudentId !== "") {
            files.forEach((item) => {
                formData.append("attachments[]", item.file);
            });
        }

        return formData;
    };
    /**
     * Trigger the course students list and close the student forms
     */
    const closeStudentForm = () => {
        getInvoiceCourseStudentApi(invoiceCourseId);
        getInvoiceApi(invoiceId);
        setAddStudent(false);
        setChangeStudentData();
    };

    /** use effect */
    useEffect(() => {
        const getConfig = async () => {
            try {
                setLoading(true);
                const configResponse = await api.get(
                    `tms/enrolments/config`,
                    {}
                );
                setConfigValues({ ...configValues, ...configResponse.data });
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        getConfig();

        if (studentFin){
            getStudent();
        }
    }, []); //  eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initValues}
                validationSchema={schema}
                onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                ) => {
                    try {
                        await createOrUpdate(values, !!studentFin);
                        setSubmitting(true);
                    } catch (error) {
                        const message =
                            error?.message || "Something went wrong";
                        setNotif({
                            notifMsg: message,
                            open: true,
                            severity: "danger",
                        });
                    }
                }}
            >
                {({ errors, handleSubmit, values, setFieldValue }) =>
                    !loading && (
                        <>
                            <Form noValidate onSubmit={handleSubmit}>
                                {error && (
                                    <Alert className="my-3" variant="danger">
                                        <div className="alert-message">
                                            {error}
                                        </div>
                                    </Alert>
                                )}

                                {changeStudentData && (
                                    <>
                                        <ChangeStudentDataDetails
                                            changeStudentData={
                                                changeStudentData
                                            }
                                        />
                                        <h4 className="mt-3 mb-3">
                                            New student
                                        </h4>
                                    </>
                                )}
                                {!changeStudentData && (
                                    <h4 className="mt-3 mb-3">
                                        {(!studentFin) ? 'Add new student' : 'Update student information'}
                                    </h4>
                                )}
                                <Row>
                                    <Col md={2}>FIN/NRIC</Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3">
                                            <Form.Control
                                                type="text"
                                                name="fin"
                                                defaultValue={studentFin}
                                                readOnly={!!studentFin}
                                                isInvalid={
                                                    errors?.fin ? true : false
                                                }
                                                onChange={(event) => {
                                                    handleInputChange(event);
                                                }}
                                            />
                                            {!!errors?.fin && (
                                                <Form.Control.Feedback type="invalid">
                                                    FIN/NRIC is required
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>

                                    {!studentFin && (
                                        <Col md={6}>
                                            <Button
                                                variant="info"
                                                onClick={getStudent}
                                                className="md-12"
                                            >
                                                Get
                                            </Button>
                                            {"   "}
                                            {!getFin && (
                                                <Button
                                                    variant="danger"
                                                    className="md-12"
                                                    onClick={closeStudentForm}
                                                >
                                                    Cancel
                                                </Button>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                                {getFin && (
                                    <>
                                        <Row>
                                            <Col md={2}>Name</Col>
                                            <Col md={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        isInvalid={
                                                            errors?.name
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(event) => {
                                                            handleInputChange(
                                                                event
                                                            );
                                                        }}
                                                        value={initValues?.name}
                                                    />
                                                    {!!errors?.name && (
                                                        <Form.Control.Feedback type="invalid">
                                                            name is required
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>Email</Col>
                                            <Col md={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        name="email"
                                                        value={
                                                            initValues?.email
                                                        }
                                                        isInvalid={
                                                            errors?.email
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(event) => {
                                                            handleInputChange(
                                                                event
                                                            );
                                                        }}
                                                    />
                                                    {!!errors?.email && (
                                                        <Form.Control.Feedback type="invalid">
                                                            email is required
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={2}>Passport</Col>
                                            <Col md={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        name="passport"
                                                        isInvalid={
                                                            errors?.passport
                                                                ? true
                                                                : false
                                                        }
                                                        value={
                                                            initValues?.passport
                                                        }
                                                        onChange={(event) => {
                                                            handleInputChange(
                                                                event
                                                            );
                                                        }}
                                                    />
                                                    {!!errors?.passport && (
                                                        <Form.Control.Feedback type="invalid">
                                                            Passport is required
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>Gender</Col>
                                            <Col md={3}>
                                                <Select
                                                    className="is-invalid react-select-container mb-3"
                                                    classNamePrefix="react-select"
                                                    options={addDefaultOption(
                                                        configValues?.gender
                                                    )}
                                                    name="gender"
                                                    onChange={(event) => {
                                                        handleInputSelectChange(
                                                            event,
                                                            "gender"
                                                        );
                                                    }}
                                                    defaultValue={configValues?.gender?.filter(
                                                        (option) => {
                                                            return (
                                                                option.label ===
                                                                initValues?.gender
                                                            );
                                                        }
                                                    )}
                                                />

                                                {!!errors?.gender && (
                                                    <Form.Control.Feedback type="invalid">
                                                        Gender is required
                                                    </Form.Control.Feedback>
                                                )}
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={2}>
                                                Date of birth
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control
                                                        type="date"
                                                        name="date_of_birth"
                                                        onChange={(event) => {
                                                            handleInputChange(
                                                                event,
                                                                "date_of_birth"
                                                            );
                                                        }}
                                                        value={initValues?.date_of_birth}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>Nationality</Col>
                                            <Col md={3}>
                                                <Select
                                                    className="is-invalid react-select-container mb-3"
                                                    classNamePrefix="react-select"
                                                    options={addDefaultOption(
                                                        configValues?.nationality
                                                    )}
                                                    name="nationality"
                                                    onChange={(event) => {
                                                        handleInputSelectChange(
                                                            event,
                                                            "nationality"
                                                        );
                                                    }}
                                                    defaultValue={configValues?.nationality?.filter(
                                                        (option) => {
                                                            return (
                                                                option.label ===
                                                                initValues?.nationality
                                                            );
                                                        }
                                                    )}
                                                />

                                                {!!errors?.nationality && (
                                                    <Form.Control.Feedback type="invalid">
                                                        Nationality is required
                                                    </Form.Control.Feedback>
                                                )}
                                            </Col>
                                            
                                        </Row>

                                        <Row>
                                        <Col md={2}>
                                                Identification type{" "}
                                            </Col>
                                            <Col md={3}>
                                                <Select
                                                    className="is-invalid react-select-container mb-3"
                                                    classNamePrefix="react-select"
                                                    options={addDefaultOption(
                                                        configValues?.identification_type
                                                    )}
                                                    name="identification_type"
                                                    onChange={(event) => {
                                                        handleInputSelectChange(
                                                            event,
                                                            "identification_type"
                                                        );
                                                    }}
                                                    defaultValue={
                                                        initValues?.identification_type &&
                                                        configValues?.identification_type?.filter(
                                                            (option) => {
                                                                return (
                                                                    option.value ===
                                                                    initValues?.identification_type
                                                                );
                                                            }
                                                        )
                                                    }
                                                />
                                            </Col>
                                            <Col md={2}>
                                                    Sector{" "}
                                                </Col>
                                                <Col md={3}>
                                                    <Select
                                                        className="is-invalid react-select-container mb-3"
                                                        classNamePrefix="react-select"
                                                        options={addDefaultOption(
                                                            configValues?.sector
                                                        )}
                                                        name="sector"
                                                        onChange={(event) => {
                                                            handleInputSelectChange(
                                                                event,
                                                                "sector"
                                                            );
                                                        }}
                                                        defaultValue={
                                                            initValues?.sector &&
                                                            configValues?.sector?.filter(
                                                                (option) => {
                                                                    return (
                                                                        option.value ===
                                                                        initValues?.sector
                                                                    );
                                                                }
                                                            )
                                                        }
                                                    />
                                                </Col>
                                            
                                        </Row>

                                        <Row>
                                            <Col md={2}>Work permit number</Col>
                                            <Col md={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        name="work_permit_number"
                                                        isInvalid={
                                                            errors?.work_permit_number
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(event) => {
                                                            handleInputChange(
                                                                event
                                                            );
                                                        }}
                                                        value={
                                                            initValues?.work_permit_number ?? ''
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                Work permit expiry date
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control
                                                        type="date"
                                                        name="work_permit_expire_date"
                                                        onChange={(event) => {
                                                            handleInputChange(
                                                                event
                                                            );
                                                        }}
                                                        value={
                                                            initValues?.work_permit_expire_date
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>

                                            
                                        </Row>
                                        <Row>
                                            <Col md={2}>Contact number</Col>
                                            <Col md={3}>
                                            <Form.Group className="mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        name="contact_number"
                                                        onChange={(event) => {
                                                            handleInputChange(
                                                                event,
                                                                "contact_number"
                                                            );
                                                        }}
                                                        value={initValues?.contact_number}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                            Coretrade/Multi-skilling/Direct R1 Reg. No.
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        name="coretrade_multi_skill_direct"
                                                        onChange={(event) => {
                                                            handleInputChange(
                                                                event,
                                                                "coretrade_multi_skill_direct"
                                                            );
                                                        }}
                                                        value={
                                                            initValues?.coretrade_multi_skill_direct
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            

                                            
                                        </Row>

                                        <Row>
                                            <Col md={2}>
                                                Eligibility period for AN
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        name="eligibility_period_for_an"
                                                       onChange={(event) => {
                                                            handleInputChange(
                                                                event,
                                                                "eligibility_period_for_an"
                                                            );
                                                        }}
                                                        value={
                                                            initValues?.eligibility_period_for_an
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        {changeStudentData && (
                                            <>
                                                <hr />
                                                <h6>Remarks</h6>
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group className="mb-3">
                                                            <AutoGrowTextarea
                                                                fieldValue={
                                                                    values?.change_student_remarks
                                                                }
                                                                name="change_student_remarks"
                                                                handleFormChangeDetails={
                                                                    handleInputChange
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Group className="mb-3">
                                                            <FileUpload
                                                                className="fileupload-bg"
                                                                files={files}
                                                                setFiles={
                                                                    setFiles
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}

                                        <hr />
                                        <Row>
                                            <Col md={3}>
                                                <Button
                                                    variant="primary"
                                                    size="lg"
                                                    className="col-md-12"
                                                    type="submit"
                                                >
                                                    {changeStudentData && (
                                                        <span>
                                                            Replace Student
                                                        </span>
                                                    )}
                                                    {!changeStudentData && !studentFin && (
                                                        <span>Add Student</span>
                                                    )}
                                                    {studentFin && (
                                                        <span>Update Info</span>
                                                    )}
                                                </Button>
                                            </Col>
                                            <Col md={3}>
                                                <Button
                                                    variant="danger"
                                                    size="lg"
                                                    className="col-md-12"
                                                    onClick={() => {
                                                        closeStudentForm();
                                                    }}
                                                    type="button"
                                                >
                                                    Cancel
                                                </Button>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Form>
                        </>
                    )
                }
            </Formik>
            {/* <SnackbarAlert notif={notif} setNotif={setNotif} /> */}
        </>
    );
};
export default AddCourseStudent;
