import * as yup from "yup";

export const schema = yup.object().shape({
    application_option: yup.string().nullable(),
    type_of_trade: yup.string().nullable(),
    course_id: yup.string().nullable(),
    trade_category: yup.string().nullable(),
    course_code: yup.string().required(),
    fee: yup.number().min(0).required(),
});
