// Guards
import AuthGuard from "../../components/guards/AuthGuard";
import IntelliBuild from "./Layout/IntelliBuildLayout";
import { AddEditProject } from "./Settings/Project/AddEdit/AddEditProject";
import { Project } from "./Settings/Project/Project";

const IntelliBuildRoutes = {
    path: "/intellibuild",
    element: (
        <AuthGuard>
            <IntelliBuild />
        </AuthGuard>
    ),
    children: [
        {
            path: "projects",
            element: (
                <AuthGuard>
                    <Project />,
                </AuthGuard>
            ),
        },
        {
            path: "projects/:action",
            element: (
                <AuthGuard>
                    <AddEditProject />,
                </AuthGuard>
            ),
        },
    ],
};

export default IntelliBuildRoutes;
