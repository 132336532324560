import React, { useState } from "react";
import { Alert, Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { useTable, useRowSelect } from "react-table";
import Select from "react-select";
import api from "../../../service/api";
import { addDefaultOption } from "../../../utils/utility";

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        );
    }
);
const AttendanceBatchStudent = ({
    data,
    columns,
    batch,
    getBatchStudentsApi,
    attendanceDate,
    defaultAccordion,
}) => {
    const [error, setError] = useState();
    const [attendanceType, setAttendanceType] = useState("");
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        // state: { selectedRowIds },
    } = useTable(
        {
            columns,
            data,
        },
        useRowSelect,
        (hooks) => {
            batch?.status?.toLowerCase() === "training going on" &&
                hooks.visibleColumns.push((columns) => [
                    {
                        id: "selection",

                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <div>
                                <IndeterminateCheckbox
                                    {...getToggleAllRowsSelectedProps()}
                                />
                            </div>
                        ),
                        Cell: ({ row }) => (
                            <div>
                                <IndeterminateCheckbox
                                    {...row.getToggleRowSelectedProps()}
                                />
                            </div>
                        ),
                    },
                    ...columns,
                ]);
        }
    );

    const onChange = (options) => {
        setAttendanceType(options?.value);
    };
    const createAttendance = async () => {
        const students = selectedFlatRows.map((d) => {
            return {
                student_id: d.original.student_id,
                enrolment_id: d.original.enrolment_id,
                id: d.original.id,
            };
        });

        if (attendanceType === "") {
            alert("select data");
            return;
        }
        const postData = {
            students: students,
            attendance: attendanceType,
            batch_id: batch.id,
            session: attendanceDate?.session,
            date: attendanceDate?.date,
        };

        try {
            await api.post("tms/batch-student-attendances/create", postData);

            getBatchStudentsApi(
                batch.id,
                attendanceDate?.session,
                attendanceDate?.date,
                defaultAccordion
            );
        } catch (error) {
            setError(error);
        }
    };

    return (
        <Card>
            <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                    Class students
                    {attendanceDate && attendanceDate?.date && (
                        <span className="p-2">
                            : {attendanceDate?.date}{" "}
                            {attendanceDate?.session?.toUpperCase()}
                        </span>
                    )}
                </Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
                {error && (
                    <Alert className="my-3" variant="danger">
                        <div className="alert-message">{error}</div>
                    </Alert>
                )}

                {selectedFlatRows.map((d) => d.original.student_id).length >
                    0 && (
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Select
                                    className="is-invalid react-select-container"
                                    classNamePrefix="react-select "
                                    options={addDefaultOption([
                                        { value: "present", label: "PRESENT" },
                                        { value: "absent", label: "ABSENT" },
                                    ])}
                                    name="attendance"
                                    isClearable={true}
                                    onChange={onChange}
                                    defaultValue={[
                                        {
                                            value: attendanceType,
                                            label: attendanceType?.toUpperCase(),
                                        },
                                    ]}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Button
                                variant="info"
                                size="lg"
                                onClick={createAttendance}
                            >
                                Mark attendance
                            </Button>
                        </Col>
                    </Row>
                )}

                {data.length === 0 && (
                    <Alert className="my-3" variant="warning">
                        <div className="alert-message">
                            No students under this batch/class
                        </div>
                    </Alert>
                )}
                {data.length > 0 && (
                    <Table responsive striped bordered {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell, index) => {
                                            if (index === 1) {
                                                return (
                                                    <td key={index}>
                                                        {parseInt(cell.row.id) +
                                                            1}
                                                    </td>
                                                );
                                            }
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                )}
            </Card.Body>
        </Card>
    );
};

export default AttendanceBatchStudent;
