
import React, { useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import Modal from "react-overlays/Modal";
import {
    Alert,
    Card,
    Col,
    Form,
    Row,
} from "react-bootstrap";
import Select from "react-select";

import {
    addDefaultOption,
} from "../../../../../utils/utility";
import StudentList from "./StudentList";
import { TableColumns } from "./TableColumns";

const AdditionalInvoiceModal = ({
    loading,
    invoiceStudent, showModal,
    handleClose, renderBackdrop, 
    invoice, configValues,
    handleFormSelectChange, handleFormInputTypeChange,
    initValues, setSelectedUser, 
    handleFormInputStudTableChange, no_gst, handleSuccess,
    handleChangeGSTAmount,handleFormSelectTypeChange
}) => {

    return (
        <>
            <Modal
                className="modal modal_additional"
                show={showModal}
                onHide={handleClose}
                renderBackdrop={renderBackdrop}
            >
                <div>
                    <div className="modal-header">
                        <div className="modal-title">Additional Invoice</div>
                        <div>
                            <span
                                className="close-button"
                                onClick={handleClose}
                            >
                                x
                            </span>
                        </div>
                    </div>
                    <div className="modal-desc">
                        <Card>
                            <Card.Body>
                                {loading && <div>Loading...</div>}
                                {!loading && invoice && (
                                    <Formik>
                                        {({
                                            errors,
                                            handleSubmit,
                                            values,
                                            setFieldValue,
                                        }) => (
                                            <Form
                                                noValidate
                                                onSubmit={handleSubmit}
                                            >
                                                {errors.submit && (
                                                    <Alert
                                                        className="my-3"
                                                        variant="danger"
                                                    >
                                                        <div className="alert-message">
                                                            {errors.submit}
                                                        </div>
                                                    </Alert>
                                                )}

                                                <Row>
                                                    <Col md={4}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>
                                                                Type
                                                            </Form.Label>
                                                            <Select
                                                                className="react-select-container"
                                                                classNamePrefix="react-select"
                                                                options={addDefaultOption(
                                                                    configValues?.additional
                                                                )}
                                                                name="type"
                                                                onChange={(
                                                                    value
                                                                ) => {
                                                                    handleFormSelectTypeChange(
                                                                        "type",
                                                                        value
                                                                    );
                                                                    // setFieldValue("trade_category", value.value);
                                                                    handleFormInputTypeChange(
                                                                        value.value
                                                                    );
                                                                }}
                                                                defaultValue={
                                                                    values?.additional &&
                                                                    configValues?.additional?.filter(
                                                                        (
                                                                            option
                                                                        ) => {
                                                                            return (
                                                                                option.label ===
                                                                                configValues?.additional
                                                                            );
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>
                                                                Trade Category
                                                            </Form.Label>
                                                            <Select
                                                                className="react-select-container"
                                                                classNamePrefix="react-select"
                                                                options={addDefaultOption(
                                                                    configValues?.tradeCategory
                                                                )}
                                                                name="trade_category"
                                                                onChange={(
                                                                    value
                                                                ) => {
                                                                    handleFormSelectChange(
                                                                        "trade_category",
                                                                        value
                                                                    );
                                                                    // setFieldValue("trade_category", value.value);
                                                                }}
                                                                defaultValue={
                                                                    values?.trade_category &&
                                                                    configValues?.tradeCategory?.filter(
                                                                        (
                                                                            option
                                                                        ) => {
                                                                            return (
                                                                                option.label ===
                                                                                configValues?.trade_category
                                                                            );
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>
                                                                Type of trade
                                                            </Form.Label>
                                                            <Select
                                                                className="react-select-container"
                                                                classNamePrefix="react-select"
                                                                options={addDefaultOption(
                                                                    configValues?.tradeOptions
                                                                )}
                                                                name="type_of_trade"
                                                                onChange={(
                                                                    value
                                                                ) => {
                                                                    handleFormSelectChange(
                                                                        "type_of_trade",
                                                                        value
                                                                    );
                                                                    // setFieldValue("type_of_trade", value.value);
                                                                }}
                                                                defaultValue={
                                                                    values?.type_of_trade &&
                                                                    configValues?.tradeOptions?.filter(
                                                                        (
                                                                            option
                                                                        ) => {
                                                                            return (
                                                                                option.label ===
                                                                                configValues?.type_of_trade
                                                                            );
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>
                                                                Application
                                                                option{" "}
                                                            </Form.Label>
                                                            <Select
                                                                className="react-select-container"
                                                                classNamePrefix="react-select"
                                                                options={addDefaultOption(
                                                                    configValues?.tradeApplication
                                                                )}
                                                                name="application_option"
                                                                onChange={(
                                                                    value
                                                                ) => {
                                                                    handleFormSelectChange(
                                                                        "application_option",
                                                                        value
                                                                    );
                                                                    // setFieldValue("application_option", value.value);
                                                                }}
                                                                defaultValue={
                                                                    values?.application_option &&
                                                                    configValues?.tradeApplication?.filter(
                                                                        (
                                                                            option
                                                                        ) => {
                                                                            return (
                                                                                option?.value ===
                                                                                configValues?.application_option
                                                                            );
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        {!(
                                                            initValues.type_of_trade ===
                                                            "CET"
                                                        ) && (
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>
                                                                    Course
                                                                </Form.Label>
                                                                <Select
                                                                    className="is-invalid react-select-container"
                                                                    classNamePrefix="react-select"
                                                                    options={addDefaultOption(
                                                                        configValues?.attcCourses
                                                                    )}
                                                                    name="course_id"
                                                                    onChange={(
                                                                        value
                                                                    ) => {
                                                                        handleFormSelectChange(
                                                                            "course_id",
                                                                            value
                                                                        );
                                                                        // setFieldValue("course_id", value.value);
                                                                    }}
                                                                    defaultValue={
                                                                        configValues
                                                                            ?.course
                                                                            ?.name && [
                                                                            {
                                                                                label: configValues
                                                                                    ?.course
                                                                                    ?.name,
                                                                                value: configValues
                                                                                    ?.course
                                                                                    ?.id,
                                                                            },
                                                                        ]
                                                                    }
                                                                />
                                                                {!!errors.course_id && (
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Course
                                                                        is
                                                                        required
                                                                    </Form.Control.Feedback>
                                                                )}
                                                            </Form.Group>
                                                        )}
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>
                                                                Unit Price{" "}
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                disabled
                                                                name="course_fee"
                                                                value={
                                                                    initValues.course_fee
                                                                }
                                                                // onChange={(value) => {
                                                                //         handleFormInputChange("course_fee", value.target.value);
                                                                //     }}
                                                            />
                                                            <small>
                                                                {initValues.gst_type && ( 
                                                                    <>
                                                                    Before GST 
                                                                    </> 
                                                                )} 
                                                                {!initValues.gst_type && ( 
                                                                    <>
                                                                    After GST 
                                                                    </> 
                                                                )} {initValues.original_price}
                                                            </small>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>
                                                                Subtotal{" "}
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                disabled
                                                                name="sub_total"
                                                                defaultValue={
                                                                    initValues.sub_total
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={8}>
                                                        <h5>
                                                            Registered student
                                                            list
                                                        </h5>

                                                        {invoiceStudent?.length ===
                                                            0 && (
                                                            <Alert
                                                                className="my-3"
                                                                variant="success"
                                                            >
                                                                <div className="alert-message">
                                                                    No students
                                                                </div>
                                                            </Alert>
                                                        )}
                                                        {invoiceStudent?.length >
                                                            0 && (
                                                            <StudentList
                                                                setSelectedUser={
                                                                    setSelectedUser
                                                                }
                                                                setStudentTextbox={
                                                                    handleFormInputStudTableChange
                                                                }
                                                                data={
                                                                    invoiceStudent
                                                                }
                                                                columns={
                                                                    TableColumns
                                                                }
                                                            />
                                                        )}
                                                    </Col>
                                                    <Col md={4}>
                                                        <hr />
                                                        {/* <Form.Group className="mb-3">
                                                        Total tax amount {initValues.gst}
                                                            % :{" "}
                                                            {
                                                                initValues.gst_amount
                                                            }
                                                        </Form.Group> */}
                                                        <Form.Group className="mb-3">
                                                        
                                                            {
                                                                <>
                                                                    <Row>
                                                                        <Col md={4}>
                                                                            <Form.Label>
                                                                            Total tax amount {initValues.gst_type ? 0 : initValues.gst}
                                                                                % :{" "}
                                                                            </Form.Label>
                                                                        </Col>
                                                                        <Col md={4}>
                                                                        {!initValues.gst_type && (
                                                                            <Form.Control
                                                                                type="number"
                                                                                value={initValues.gst_amount}
                                                                                readOnly={initValues.gst ? false : true}
                                                                                step="0.01"
                                                                                onChange={(e) => {
                                                                                    handleChangeGSTAmount(
                                                                                        e.target.value
                                                                                    );
                                                                                }}
                                                                                
                                                                            />
                                                                        )}
                                                                        </Col>
                                                                    </Row>
                                                               
                                                                </>
                                                            }
                                                        </Form.Group>
                                                        {!initValues.gst_type && (
                                                            <Form.Group className="mb-3">
                                                                <Form.Check
                                                                    className="mb-2"
                                                                    label="Apply no GST"
                                                                    inline
                                                                    name="no_gst"
                                                                    type="checkbox"
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        no_gst(
                                                                            event
                                                                        );
                                                                    }}
                                                                />
                                                            </Form.Group>
                                                        )}
                                                        <Form.Group className="mb-3">
                                                            Total amount : {""}
                                                            {
                                                                initValues.total_amount
                                                            }
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                )}
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="modal-footer">
                        <button
                            className="secondary-button"
                            onClick={handleClose}
                        >
                            Close
                        </button>
                        <button
                            className="primary-button"
                            onClick={handleSuccess}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Modal>
        </>
            
    )
    
}

export default AdditionalInvoiceModal