import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Alert } from "react-bootstrap";
import api from "../../../service/api";
import { SnackbarAlert } from "../../../components/SnackbarAlert";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import { addDefaultOption } from "../../../utils/utility";

const FormRow = ({ id }) => {
    const [loading, setLoading] = useState(false);
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [initValues, setInitialValues] = useState({
        name: "",
        description: "",
        fee: "",
        status: "",
        type: "",
    });

    const [configValues, setConfigValues] = useState();

    const navigate = useNavigate();

    const schema = Yup.object().shape({
        name: Yup.string().required(),
        type: Yup.string().required(),
        status: Yup.string().required(),
    });

    //
    // Functions
    //
    const create = (values) => {
        try {
            return api.post("tms/courses", values);
        } catch {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    const update = (values) => {
        try {
            return api.put(`tms/courses/${id}`, values);
        } catch {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    useEffect(() => {
        if (id && id !== "add") {
            const getCourse = async () => {
                try {
                    setLoading(true);
                    const response = await api.get(`tms/courses/${id}`, {});
                    await setInitialValues(response.data);
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                }
            };
            getCourse();
        }
    }, [id]);

    useEffect(() => {
        const getConfig = async () => {
            try {
                const configResponse = await api.get(`tms/courses/config`, {});
                await setConfigValues(configResponse.data);
            } catch (error) {}
        };

        getConfig();
    }, []);

    return (
        <>
            <Card>
                <Card.Body>
                    {loading && <div>Loading...</div>}
                    {!loading && (
                        <Formik
                            enableReinitialize
                            initialValues={initValues}
                            validationSchema={schema}
                            onSubmit={async (
                                values,
                                { setErrors, setStatus, setSubmitting }
                            ) => {
                                try {
                                    id !== "add"
                                        ? await update(values)
                                        : await create(values);

                                    navigate("/tms/courses", {
                                        state: {
                                            open: true,
                                            notifMsg: `Successfully ${
                                                id === "add"
                                                    ? "added"
                                                    : "edited"
                                            } project`,
                                            severity: "success",
                                        },
                                    });
                                } catch (error) {
                                    const message =
                                        error.message || "Something went wrong";

                                    setStatus({ success: false });
                                    setErrors({ submit: message });
                                    setSubmitting(false);
                                    setNotif({
                                        notifMsg:
                                            "Something went wrong with the server",
                                        open: true,
                                        severity: "danger",
                                    });
                                }
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                touched,
                                values,
                                setFieldValue,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    {errors.submit && (
                                        <Alert
                                            className="my-3"
                                            variant="danger"
                                        >
                                            <div className="alert-message">
                                                {errors.submit}
                                            </div>
                                        </Alert>
                                    )}
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    placeholder="Name"
                                                    value={values.name}
                                                    isInvalid={Boolean(
                                                        touched.name &&
                                                            errors.name
                                                    )}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {!!touched.name && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.name}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Description
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="description"
                                                    placeholder="Description"
                                                    value={values.description}
                                                    isInvalid={Boolean(
                                                        touched.description &&
                                                            errors.description
                                                    )}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {!!touched.description && (
                                                    <Form.Control.Feedback type="invalid">
                                                        Description code is a
                                                        required field
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>type</Form.Label>

                                                <Select
                                                    className="is-invalid react-select-container"
                                                    classNamePrefix="react-select"
                                                    options={addDefaultOption(
                                                        configValues?.departments
                                                    )}
                                                    name="type"
                                                    onChange={(value) =>
                                                        setFieldValue(
                                                            "type",
                                                            value.value
                                                        )
                                                    }
                                                    // isSearchable="true"
                                                    defaultValue={
                                                        initValues?.type !==
                                                            "" && [
                                                            {
                                                                label: initValues?.type,
                                                                value: initValues?.type,
                                                            },
                                                        ]
                                                    }
                                                />

                                                {!!errors.type && (
                                                    <Form.Control.Feedback type="invalid">
                                                        Type is a required field
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Status</Form.Label>
                                                <select
                                                    className=" form-control"
                                                    placeholder="Course type"
                                                    name="status"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.status}
                                                >
                                                    <option>select</option>
                                                    <option value="active">
                                                        Active
                                                    </option>
                                                    <option value="inactive">
                                                        In-active
                                                    </option>
                                                </select>
                                                {!!touched.status && (
                                                    <Form.Control.Feedback type="invalid">
                                                        Status is a required
                                                        field
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Fees</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="fees"
                                                    placeholder="Fees"
                                                    value={values.fees}
                                                    isInvalid={Boolean(
                                                        touched.fees &&
                                                            errors.fees
                                                    )}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                                {!!touched.fees && (
                                                    <Form.Control.Feedback type="invalid">
                                                        Fees is a required field
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <div className="d-flex flex-row align-items-center p-2">
                                        <Button
                                            variant="primary"
                                            size="lg"
                                            type="submit"
                                            className="col-md-3 "
                                        >
                                            Save
                                        </Button>

                                        <Button
                                            variant="danger"
                                            size="lg"
                                            onClick={() =>
                                                navigate("/tms/courses")
                                            }
                                            className="m-3"
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </Card.Body>
            </Card>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export default FormRow;
