import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container, Alert, Row, Col, Button, Modal, Form } from "react-bootstrap";
import { SnackbarAlert } from "../../../../components/SnackbarAlert.js";
import { useLocation, useNavigate } from "react-router-dom";
import { Plus } from "react-feather";
import api from "../../../../service/api.js";
import { useSelector } from "react-redux";
// import download from "downloadjs";
import { tableColumns } from "./List/TableColumns.js";
import Search from "./List/Search.js";
import { AutoGrowTextarea } from "../../../../components/AutoGrowTextarea.js";
import { AuthContext } from "../../../../contexts/JWTContext.js";
import { Lists } from "./List/Lists.js";


const CreditTerm = ({ type, title }) => {
    const navigate = useNavigate();
    const [creditTerms, setCreditTerms] = useState([]);
    const [invoiceId, setInvoiceId] = useState(null);
    const [error, setError] = useState();
    const [salesPersons, setSalesPersons] = useState([]);
    const [loading, setLoading] = useState(true);
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const location = useLocation();
    const state = location.state;
    const { data } = useSelector((state) => (state.user ? state.user : state));
    const permission = data?.permissions;

    const [searchValue, setSearchValue] = useState({
        number: "",
        company: "",
        status: "",
		// month: "",
        invoice_type: "",
        start_date: "",
        salesperson: "",
        enrollment_status: "",
        requested_director_approval: true,
        signed_director_approval: false,
    });

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

    const search = (page) => {
        const searchParam = { ...searchValue, ...page };
        // const searchParam = { ...page };
        getCreditTerm(searchParam);
    };
    
    const [show, setShow] = useState(false);
    const [modalType, setModalType] =useState('APPROVAL');
    const [modalLoading, setModalLoading] = useState(false);
    const [modalData, setModalData] = useState({
        remarks: "",
        user_id: AuthContext?.user?.id ?? null
    });
    const setModalPopUp = (status, invoiceId = "", type="APPROVAL") => {
        setModalLoading(true);
        setModalType(type);
        setShow(status);
        setInvoiceId(invoiceId);
        setModalLoading(false);
        setModalData({
            remarks: "",
            user_id: AuthContext?.user?.id ?? null
        })
    };

    const allowStudentToRegister = async () => {
        try {
            await api.post(
                `tms/invoices/allowed-to-register-students/${invoiceId}`,
                modalData
            );
            setNotif({
                notifMsg: "Successfully  allowed to register students ",
                open: true,
                severity: "success",
            });
            let pageNo = "";
            if (state?.page) {
                pageNo = state?.page;
            }
            await delay(2000);
            await getCreditTerm({...searchValue, page: pageNo});
        } catch (error) {
            setError(error?.response?.data?.message);
            
        }
    };

    const getCreditTerm = useCallback(async (search) => {
        try {
            setLoading(true);
            const response = await api.get("tms/invoices", search);
            setCreditTerms(response);
            setError();
        } catch (error) {
            setLoading(false);
            setError(error.message);
            setCreditTerms([]);
        }
        setLoading(false);
    })

    const getSalesPerson = async () => {
        try {
            const companyResponse = await api.get(`tms/get-sales-persons`);
            setSalesPersons(companyResponse);
        } catch (error) {}
    };

    useEffect(() => {
        /**
         * set page number
         */
        let pageNo = "";
        if (state?.page) {
            pageNo = state?.page;
        }
        // getConfig();
        getCreditTerm({ ...searchValue, page: pageNo });
        getSalesPerson()
    }, []);
    
    let helmentTitle = "Invoice > " + title;

    return (
        <React.Fragment>
            <Helmet title={helmentTitle} />
            {!permission.includes('SALES-DIRECTOR') && (
                <Alert className="my-3" variant="warning">
                    <div className="alert-message">
                        You don't have permission to access this page
                    </div>
                </Alert>
            )}

            {permission.includes('SALES-DIRECTOR') && (
                <Container fluid className="p-0">
                    <h1 className="h3 mb-3">{title}</h1>
                    {error && (
                        <Card.Header>
                            <Alert className="my-3" variant="danger">
                                <div className="alert-message">{error}</div>
                            </Alert>
                        </Card.Header>
                    )}
                    <Card>
                        <Card.Body className="mt-0">
                            <Row className="mb-5">
                                <Search
                                    setSearchValue={setSearchValue}
                                    searchValue={searchValue}
                                    search={search}
                                    type={type}
                                    salesPersons={salesPersons}
                                />
                            </Row>
                            
                            {loading && <div>Loading...</div>}
                            {!loading && creditTerms?.data && (
                                <Lists
                                    columns={tableColumns}
                                    rawData={creditTerms}
                                    invoiceApi={search}
                                    permission={permission}
                                    setShow = {setShow}
                                    setModalType = {setModalType}
                                    setModalLoading = {setModalLoading}
                                    setModalData={setModalData}
                                    setInvoiceId={setInvoiceId}
                                />
                            )}
                        </Card.Body>
                    </Card>
                </Container>
            )}
            <SnackbarAlert notif={notif} setNotif={setNotif} />

            <Modal
                show={show && modalType === 'APPROVAL'}
                onHide={() => {
                    setModalPopUp(false, "");
                }}
                style={{zIndex:99999}}
            >
                <Modal.Header>
                    <h3>Approval to allow student</h3>
                </Modal.Header>
                {modalLoading && <div>Loading...</div>}
                {!modalLoading && (
                    <>
                        <Modal.Body className="text-left m-3">
                            <Form.Group className="mb-3">
                                <Form.Label>Remarks</Form.Label>
                                <AutoGrowTextarea
                                    type="textarea"
                                    name="remarks"
                                    handleFormChangeDetails={(event) => {
                                        setModalData({
                                            ...modalData,
                                            remarks: event.target.value,
                                        });
                                    }}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="success"
                                onClick={() => {
                                    allowStudentToRegister();
                                    setModalPopUp(false, "");
                                }}
                            >
                                Approve
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setModalPopUp(false, "");
                                }}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </>
                )}
            </Modal>
        </React.Fragment>
    );
};

export default CreditTerm;
