import React, { useEffect, useState, useContext, useCallback } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import Wrapper from "../../../components/Wrapper";
import Sidebar from "../../../components/sidebar/Sidebar";
import Main from "../../../components/Main";
import Navbar from "../../../components/navbar/NavbarComponent";
import Content from "../../../components/Content";

import IntelliDocsItems from "../SideBar/IntelliDocsItems";

import api from "../../../service/api";

import { LoadingContext } from "../../../App";
import { useSelector } from "react-redux";

const IntelliDocsLayout = ({ children }) => {
    //
    // States
    //
    const navigate = useNavigate();
    const { data } = useSelector((state) => {
        return state.user ? state.user : state;
    });
    //const permission = data?.permissions;

    useEffect(() => {
        if (data && data.apps) {
            if (data.apps.length > 0) {
                const app = data.apps.filter(
                    (app) => app.name.toUpperCase() === "INTELLIDOCS"
                );
                if (app?.length === 0) {
                    navigate("/", {
                        state: {
                            authorizationOpen: true,
                            notifMsg: `There is no access for intellidocs`,
                            severity: "danger",
                        },
                    });
                }
            }
        }
    }, [data, navigate]);

    const [menuItems, setMenuItems] = useState([]);
    const setIsLoadingActive = useContext(LoadingContext);

    //
    // Functions
    //

    const getProjectMenus = useCallback(async () => {
        try {
            const projectMenus = await api.get(`project-menus`, {});
            const arrangeProjectMenu = {};

            projectMenus.forEach((data) => {
                const portfolioProgramme = `${data.portfolio.name} - ${data.programme.name}`;

                if (!arrangeProjectMenu[portfolioProgramme]) {
                    arrangeProjectMenu[portfolioProgramme] = {
                        href: "/process",
                        title: portfolioProgramme,
                        children: [
                            {
                                href: `/intellidocs/process/dashboard/${data.id}`,
                                title: data.name,
                            },
                        ],
                    };
                } else {
                    arrangeProjectMenu[portfolioProgramme]["children"].push({
                        href: `/intellidocs/process/dashboard/${data.id}`,
                        title: data.name,
                    });
                }
            });

            IntelliDocsItems[0]["pages"] = Object.values(arrangeProjectMenu);
            setMenuItems(IntelliDocsItems);
            setIsLoadingActive(false);
        } catch (error) {}
    }, [setIsLoadingActive]);

    //
    // UseEffects
    //

    useEffect(() => {
        setIsLoadingActive(true);
        getProjectMenus();
    }, [getProjectMenus, setIsLoadingActive]);

    return (
        <React.Fragment>
            <Wrapper>
                {menuItems && (
                    <>
                        <Sidebar title="IntelliDocs" items={menuItems} />
                        <Main>
                            <Navbar />
                            <Content>
                                {children}
                                <Outlet />
                            </Content>
                        </Main>
                    </>
                )}
            </Wrapper>
        </React.Fragment>
    );
};

export default IntelliDocsLayout;
