import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
    Calendar,
    CheckCircle,
    CheckSquare,
    Edit,
    Eye,
    Mail,
    Trash,
    File,
    UserPlus,
} from "react-feather";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import api from "../../../service/api";
import download from "downloadjs";

const ListButton = ({
    status,
    id,
    openDeleteModal,
    batchName,
    title = null,
}) => {
    const navigate = useNavigate();
    /** Permissions */
    const { data } = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const [error, setError] = useState();
    const permission = data?.permissions;
    const generateReport = async () => {
        try {
            const response = await api.getexcel(
                `tms/batches/enrolment-report/${id}`
            );
            if (response.size !== 0) {
                download(response, "Enrolment_result", "application/xlsx");
            } else {
                setError("No data to be exported");
            }
        } catch (error) {
            setError(error.message);
        }
    };

    /** Permissions */
    return (
        <>
            {(status === "pending" || status === "summary") && (
                <>
                    {permission?.includes("BATCH-EDIT") && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                        >
                            <a
                                href={`/tms/batches/${id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="me-1"
                            >
                                <span>
                                    <Edit size="18" color="#6c757d" />
                                </span>
                            </a>
                        </OverlayTrigger>
                    )}
                    {permission?.includes("BATCH-DELETE") && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete</Tooltip>}
                        >
                            <span>
                                <Trash
                                    style={{ cursor: "pointer" }}
                                    className="feather me-1 mb-1 text-danger"
                                    onClick={() => openDeleteModal(id)}
                                />
                            </span>
                        </OverlayTrigger>
                    )}
                    {permission?.includes("BATCH-STUDENT-ALLOCATE") && (
                        <>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip> Add training dates</Tooltip>}
                            >
                                <a
                                    href={`/tms/batches/student-allocate/${id}?status=${status}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="me-1"
                                >
                                    <span>
                                        <Calendar size="18" color="#6c757d" />
                                    </span>
                                </a>
                            </OverlayTrigger>

                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Add students</Tooltip>}
                            >
                                <span>
                                    <a
                                        href={`/tms/batches/add-student-to-class/${id}?status=${status}&batchId=${id}&batchName=${batchName}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="me-1"
                                    >
                                        <span>
                                            <UserPlus
                                                size="20"
                                                color="#6c757d"
                                            />
                                        </span>
                                    </a>
                                </span>
                            </OverlayTrigger>
                        </>
                    )}
                </>
            )}
            {status?.toLowerCase() === "training" && (
                <>
                    {permission?.includes("ATTENDANCE-MARK") && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Attendance</Tooltip>}
                        >
                            <span>
                                <a
                                    href={`/tms/batches/attendance/${id}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="me-1"
                                >
                                    <span>
                                        <CheckSquare
                                            size="20"
                                            color="#6c757d"
                                        />
                                    </span>
                                </a>
                            </span>
                        </OverlayTrigger>
                    )}
                </>
            )}
            {(status?.toLowerCase() === "waiting-for-result" ||
                status?.toLowerCase() === "summary") && (
                <>
                    {permission?.includes("BATCH-RESULT") && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Results</Tooltip>}
                        >
                            <span>
                                <a
                                    href={`/tms/batches/results/${id}?path=${status}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="me-1"
                                >
                                    <span>
                                        <CheckCircle
                                            size="20"
                                            color="#6c757d"
                                        />
                                    </span>
                                </a>
                            </span>
                        </OverlayTrigger>
                    )}
                </>
            )}
            {status.toLowerCase() === "completed" && (
                <>
                    {permission?.includes("BATCH-COMPLETED") && (
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Batch completed</Tooltip>}
                        >
                            <span>
                                <a
                                    href={`/tms/batches/students/${id}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="me-1"
                                >
                                    <span>
                                        <Eye size="20" color="#6c757d" />
                                    </span>
                                </a>
                            </span>
                        </OverlayTrigger>
                    )}
                </>
            )}
            {title === "Summary" && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Send email</Tooltip>}
                >
                    <a
                        href={`/tms/batches/send-email/${id}`}
                        target="_blank"
                        rel="noreferrer"
                        className="me-1"
                    >
                        <span>
                            <Mail size="18" color="#6c757d" />
                        </span>
                    </a>
                </OverlayTrigger>
            )}
            {title === "Summary" && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Enrolment Report</Tooltip>}
                >
                    <span>
                        <File
                            style={{ cursor: "pointer" }}
                            className="feather me-1 mb-1"
                            onClick={generateReport}
                        />
                    </span>
                </OverlayTrigger>
            )}
        </>
    );
};
export default ListButton;
