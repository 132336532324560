import React, { useEffect, useState } from "react";
import Modal from "react-overlays/Modal";
import {
    Alert,
    Card,
    Col,
    Form,
    Row,
    OverlayTrigger,
    Tooltip,
    Spinner,
} from "react-bootstrap";
import { Formik } from "formik";
import Select from "react-select";

import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";

const ChangeCnTypeReasonModal = ({
    showModal,
    onHideModal,
    loading,
    cnPayloadData,
    setCnPayloadData,
    handleFormChangeRemarks,
    handleModalSubmit,
    isSubmitting,
}) => {
    // Backdrop JSX code
    const renderBackdrop = (props) => <div className="backdrop" {...props} />;

    return (
        <>
            <Modal
                className="modal modal_withdraw_cn"
                show={showModal}
                onHide={onHideModal}
                renderBackdrop={renderBackdrop}
            >
                <div className="">
                    <div className="modal-header">
                        <div className="modal-title">
                            Change Credit Note Reason
                        </div>
                        <div>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-top`}>Close</Tooltip>
                                }
                            >
                                <span
                                    className="close-button"
                                    onClick={onHideModal}
                                >
                                    x
                                </span>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="modal-body">
                        <Card>
                            <Card.Body>
                                {loading && <div>Loading...</div>}
                                {!loading && (
                                    <Formik>
                                        {({
                                            errors,
                                            handleSubmit,
                                            values,
                                            setFieldValue,
                                        }) => (
                                            <Form
                                                noValidate
                                                onSubmit={handleSubmit}
                                            >
                                                {errors.submit && (
                                                    <Alert
                                                        className="my-3"
                                                        variant="danger"
                                                    >
                                                        <div className="alert-message">
                                                            {errors.submit}
                                                        </div>
                                                    </Alert>
                                                )}

                                                <Row className="d-flex justify-content-center">
                                                    {/* <Col md={6}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>
                                                                Type
                                                            </Form.Label>
                                                            <Form.Select
                                                                name="type"
                                                                className="form-control"
                                                                onChange={(
                                                                    data
                                                                ) => {
                                                                    setCnPayloadData({
                                                                        ...cnPayloadData,
                                                                        type: data.target.value
                                                                    })
                                                                }}
                                                                value={cnPayloadData?.type || ''}
                                                                disabled={true}
                                                            >
                                                                <option value="cancel-course">
                                                                    Cancel
                                                                    course
                                                                </option>
                                                                <option value="withdraw-student">
                                                                    Withdraw
                                                                    student
                                                                </option>
                                                                <option value="discount">
                                                                    Discount
                                                                </option>
                                                                <option value="overcharge">
                                                                    Overcharge
                                                                </option>
                                                                <option value="cancel-invoice">
                                                                    Cancel
                                                                    invoice
                                                                </option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col> */}
                                                    {cnPayloadData?.reason && (
                                                        <Col md={6}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>
                                                                    Reason
                                                                </Form.Label>
                                                                <Form.Select
                                                                    name="reason"
                                                                    className="form-control"
                                                                    onChange={(
                                                                        data
                                                                    ) => {
                                                                        setCnPayloadData({
                                                                            ...cnPayloadData,
                                                                            reason: data.target.value
                                                                        })
                                                                    }}
                                                                    value={cnPayloadData?.reason || ''}
                                                                >
                                                                    <option value="refund">
                                                                        Refund
                                                                    </option>
                                                                    <option value="offset">
                                                                        Offset
                                                                    </option>
                                                                    <option value="no-action">
                                                                        No Action
                                                                    </option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                )}
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="modal-footer-withdraw-cn p-2 d-flex justify-content-end">
                        {!isSubmitting && (
                            <>
                                <button
                                    className="btn-danger me-2"
                                    onClick={onHideModal}
                                >
                                    Cancel
                                </button>
                                
                                <button
                                    className={"primary-button"}
                                    // onClick={handleModalSubmit}
                                    onClick={() => { if (window.confirm("Are you sure you wish change this credit note's reason?")) handleModalSubmit() } }
                                >
                                    Change
                                </button>
                            </>
                        )}
                        {isSubmitting && (
                            <Spinner
                                animation="border"
                                role="status"
                                className="m-2"
                            >
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </Spinner>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ChangeCnTypeReasonModal;
