import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { Button, Card, Container, Row, Col, Alert } from "react-bootstrap";

import { tableColumns } from "./Data.js";
import { Lists } from "./Lists.js";
import api from "../../../service/api.js";
import BatchSearch from "./Form/BatchSearch.js";
import { PlusCircle } from "react-feather";
import { useSelector } from "react-redux";
const Batch = ({ status, title }) => {
    /** Permissions */
    const permissionData = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = permissionData?.data?.permissions;
    /** Permissions */

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [defaultConfig, setDefaultConfig] = useState();
    const [searchValue, setSearchValue] = useState({
        name: "",
        test_date: "",
        location: "",
        status: status,
    });

    const search = (page) => {
        const searchParam = { ...searchValue, ...page };
        getBatches(searchParam);
    };

    const getBatches = useCallback(async (search) => {
        setLoading(true);
        try {
            const response = await api.get("tms/batches", search);
            setData(response);
            setError();
        } catch (error) {
            setLoading(false);
            setError(error.message);
            setData([]);
        }
        setLoading(false);
    }, []);

    //
    // useEffects
    //

    useEffect(() => {
        getBatches(searchValue);
    }, [getBatches]); //  eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const updateConfigValue = async () => {
            const configResponse = await api.get(`tms/batches/config`, {});
            setDefaultConfig(configResponse.data);
        };
        updateConfigValue();
    }, []);

    const helmetTitle = "Batches/class > " + title.charAt(0).toUpperCase() + title.slice(1);

    return (
        <React.Fragment>
            <Helmet title={helmetTitle} />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">{title}</h1>
                <Card>
                    <Card.Header>
                        {error && (
                            <Alert className="my-3" variant="danger">
                                <div className="alert-message">{error}</div>
                            </Alert>
                        )}
                        <Row>
                            <Col md={12}>
                                <BatchSearch
                                    searchValue={searchValue}
                                    search={search}
                                    setSearchValue={setSearchValue}
                                    defaultConfig={defaultConfig}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-0">
                            {status === "pending" &&
                                permission?.includes("BATCH-CREATE") && (
                                    <>
                                        <Col md={10}></Col>
                                        <Col md={2} className="text-end">
                                            <Button
                                                variant="info"
                                                size="lg"
                                                className="me-1 float-end"
                                                onClick={() =>
                                                    navigate("/tms/batches/add")
                                                }
                                            >
                                                <PlusCircle className="feather" />
                                            </Button>
                                        </Col>
                                    </>
                                )}
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-0">
                        {loading && <div>Loading...</div>}
                        {!loading && data?.data && (
                            <Lists
                                batchApi={getBatches}
                                columns={tableColumns}
                                rawData={data}
                                status={status}
                                title={title}
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

export default Batch;
