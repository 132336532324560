const paymentMethodOptions = [
    {
        label: "Cash",
        value: "CASH",
    },
    {
        label: "Cheque",
        value: "CHEQUE",
    },
    {
        label: "Nets",
        value: "NETS",
    },
    {
        label: "Paynow",
        value: "PAY-NOW",
    },
    {
        label: "Giro",
        value: "GIRO",
    },
    {
        label: "Fund Transfer",
        value: "FUND-TRANSFER",
    },
    {
        label: "Offset",
        value: "OFFSET",
    },
];

export { paymentMethodOptions };