const tableColumns = [
    {
        id: "table-label",
        // Header: "header-to-be-hidden",
        width: "250",
        sticky: "left",
        colSpan: 5,
        accessor: 'label',
    },
    {
        id: "table-value",
        // Header: "header2-to-be-hidden",
        width: "250",
        sticky: "left",
        colSpan: 5,
        accessor: 'value',
    },
    
];

export { tableColumns };