import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Check, DollarSign, Eye, Download, XSquare, Repeat } from "react-feather";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import download from "downloadjs";
import api from "../../../../../service/api";
import { setLocalStorageValue } from "../../../../../utils/utility";
import CreditNoteWithdrawModal from "./CreditNoteWithdrawModal";
import { SnackbarAlert } from "../../../../../components/SnackbarAlert";
import ChangeCnTypeReasonModal from "./ChangeCnTypeReasonModal";
export default function ListButton({ cell, currentPage, status, linkReroute, creditNoteApi}) {
    const navigate = useNavigate();
    const location = useLocation();
    // const [withdrawCnData, setWithdrawCnData] = useState({
    //     cn_id: null,
    //     remarks: null,
    // });
    const [cnPayloadData, setCnPayloadData] = useState({
        cn_id: null,
        type: null,
        reason: null,
        remarks: null,
    });

    const [showModal, setShowModal] = useState(false);
    const [showTypeReasonModal, setTypeReasonModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleFormChangeRemarks = (event) => {
        setCnPayloadData({
            ...cnPayloadData,
            remarks: event.target.value,
        });
    };

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const handleWithdrawSubmit = async() => {
        setIsSubmitting(true);
        try {
            const response = await api.put(
                `tms/credit-notes/withdraw/${cnPayloadData.cn_id}`,
                cnPayloadData
            );

            if (response.success) {
                setShowModal(false);
                setNotif({
                    notifMsg: response.message ?? "Successful",
                    open: true,
                    severity: "success",
                });
                setTimeout(creditNoteApi, 2500);
            } else {
                setIsSubmitting(false);
                setNotif({
                    notifMsg: response.message,
                    open: true,
                    severity: "danger",
                });
            }
        } catch (error) {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
        setIsSubmitting(false);
        setShowModal(false);
    }

    const handleChangeTypeReasonSubmit = async() => {
        setIsSubmitting(true);
        try {
            const response = await api.put(
                `tms/credit-notes/change-type-reason/${cnPayloadData.cn_id}`,
                cnPayloadData
            );

            if (response.success) {
                setShowModal(false);
                setNotif({
                    notifMsg: response.message ?? "Successful",
                    open: true,
                    severity: "success",
                });
                setTimeout(creditNoteApi, 2500);
            } else {
                setIsSubmitting(false);
                setNotif({
                    notifMsg: response.message,
                    open: true,
                    severity: "danger",
                });
            }
        } catch (error) {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
        setIsSubmitting(false);
        setShowModal(false);
    }

    const onModalHide = () => {
        setShowModal(false);
        setTypeReasonModal(false);
        setCnPayloadData({
            cn_id: null,
            type: null,
            reason: null,
            remarks: null,
        })
    }

    const setReroute = linkReroute ?? null

    /** download */
    const downloadPDF = async (id, credit_note_number) => {
        const response = await api.getpdf("tms/generate-credit-note-pdf/" + id);
        download(response, credit_note_number, "application/pdf");
    };

    /** permissions */
    const { data } = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = data?.permissions;

    return (
        <>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip>View credit note</Tooltip>}
            >
                <span>
                    <a
                        href={`/tms/credit-note-view/${setReroute}/${cell.row.original.id}?option=view&page=${currentPage}&status=${status}`}
                        target="_blank"
                        rel="noreferrer"
                        className="me-1"
                        onClick={() => setLocalStorageValue('cn-back-button-redirect', setReroute ?? null)}
                    >
                        <span>
                            <Eye size="20" color="#6c757d" />
                        </span>
                    </a>
                </span>
            </OverlayTrigger>

            {status?.toUpperCase() === "SUMMARY" && (
                <>
                    {permission?.includes("CREDITNOTE-WITHDRAW") &&
                        cell?.row?.original?.status_key?.toUpperCase() == "PENDING" &&
                    (<>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Withdraw</Tooltip>}
                        >
                        <span>
                            <XSquare size="20" color="#6c757d" onClick={() => {
                                // setWithdrawCnData({cn_id: cell?.row?.original?.id, remarks: null});
                                setCnPayloadData({
                                    ...cnPayloadData,
                                    cn_id: cell?.row?.original?.id,
                                    type: null,
                                    reason: null,
                                    remarks: null
                                });
                                setShowModal(true);
                            }}/>
                        </span>
                        </OverlayTrigger>
                    </>)}

                    {permission?.includes("CREDITNOTE-CHANGE-TYPE-REASON") &&
                        cell?.row?.original?.status_key != "WITHDRAW" &&
                        cell?.row?.original?.status_key != "REJECTED" &&
                        cell?.row?.original?.reason?.toUpperCase() == 'NO-ACTION' &&
                    (<>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Change reason</Tooltip>}
                        >
                        <span>
                            <Repeat size="20" color="#6c757d" onClick={() => {
                                setCnPayloadData({
                                    ...cnPayloadData,
                                    cn_id: cell?.row?.original?.id,
                                    type: cell?.row?.original?.type,
                                    reason: cell?.row?.original?.reason,
                                    remarks: null
                                });
                                setTypeReasonModal(true);
                            }}/>
                        </span>
                        </OverlayTrigger>
                    </>)}

                    <CreditNoteWithdrawModal
                        showModal={showModal}
                        cnPayloadData={cnPayloadData}
                        onHideModal={onModalHide}
                        handleFormChangeRemarks={handleFormChangeRemarks}
                        handleWithdrawSubmit={handleWithdrawSubmit}
                        isSubmitting={isSubmitting}
                    />

                    <ChangeCnTypeReasonModal
                        showModal={showTypeReasonModal}
                        cnPayloadData={cnPayloadData}
                        setCnPayloadData={setCnPayloadData}
                        onHideModal={onModalHide}
                        // handleFormChangeRemarks={handleFormChangeRemarks}
                        handleModalSubmit={handleChangeTypeReasonSubmit}
                        isSubmitting={isSubmitting}
                    />

                    <SnackbarAlert notif={notif} setNotif={setNotif} />
                </>
            )}

            {cell.row.original.status_key === "PENDING" &&
                cell.row.original.sales_director_status === null &&
                status?.toUpperCase() !== "SUMMARY" &&
                permission?.includes("SALES-DIRECTOR") && (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Approve</Tooltip>}
                    >
                        <span>
                            <a
                                href={`/tms/credit-note-view/${setReroute}/${cell.row.original.id}?option=approval&page=${currentPage}&status=${status}&approval_type=sales_director`}
                                // target="_blank"
                                rel="noreferrer"
                                className="me-1"
                            >
                                <span>
                                    <Check size="20" color="#6c757d" />
                                </span>
                            </a>
                        </span>
                    </OverlayTrigger>
                )}

            {cell.row.original.status_key === "PENDING" &&
                cell.row.original.sales_director_status?.toUpperCase() ===
                    "APPROVED" &&
                cell.row.original.finance_director_status === null &&
                status?.toUpperCase() !== "SUMMARY" &&
                permission?.includes("FINANCE-DIRECTOR") && (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Approve</Tooltip>}
                    >
                        <span>
                            <a
                                href={`/tms/credit-note-view/${setReroute}/${cell.row.original.id}?option=approval&page=${currentPage}&status=${status}&approval_type=finance_director`}
                                // target="_blank"
                                rel="noreferrer"
                                className="me-1"
                            >
                                <span>
                                    <Check size="20" color="#6c757d" />
                                </span>
                            </a>
                        </span>
                    </OverlayTrigger>
                )}
            {/* <Button
                variant="secondary"
                className="me-1 mb-1"
                onClick={() =>
                    downloadPDF(cell.row.original.id, cell.row.original.number)
                }
            >
                <Download className="feather" />
            </Button> */}
            {cell.row.original.status_key === "APPROVED" &&
                cell.row.original.sales_director_status?.toUpperCase() ===
                    "APPROVED" &&
                cell.row.original.finance_director_status === "APPROVED" && (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Download</Tooltip>}
                    >
                        <Download
                            style={{ cursor: "pointer" }}
                            size="20"
                            onClick={() =>
                                        downloadPDF(cell.row.original.id, cell.row.original.number)
                                    }
                        />
                    </OverlayTrigger>
                )}
            
            {cell?.row?.original?.status_key === "APPROVED" &&
                cell.row.original?.refund?.toUpperCase() === "YES" &&
                status?.toUpperCase() !== "SUMMARY" &&
                cell?.row?.original?.reason.toUpperCase() === 'REFUND' &&
                (cell.row.original?.refund_status === "" ||
                    cell.row.original?.refund_status === null) &&
                permission?.includes("CREDITNOTE-REFUND") && (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Refund</Tooltip>}
                    >
                        <span>
                            <a
                                href={`/tms/credit-note-refund/${cell.row.original.id}?option=approval&page=${currentPage}&status=${status}&approval_type=finance_director`}
                                target="_blank"
                                rel="noreferrer"
                                className="me-1"
                            >
                                <span>
                                    <DollarSign size="20" color="#6c757d" />
                                </span>
                            </a>
                        </span>
                    </OverlayTrigger>
                )}
        </>
    );
}
