import React, { useState, useEffect, useContext } from "react";
import {
    Button,
    Card,
    Col,
    Row,
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../service/api";
import download from "downloadjs";
import { Download } from "react-feather";
import { convertToDecimalWithComma } from "../../../utils/utility";
import { LoadingContext } from "../../../App";
import { SnackbarAlert } from "../../../components/SnackbarAlert.js";

export const ViewQuotation = ({ project }) => {
    const navigate = useNavigate();
    let { id, action } = useParams();

    //
    // States
    //

    const setIsLoadingActive = useContext(LoadingContext);
    // eslint-disable-next-line no-unused-vars
    const [quotation, setQuotation] = useState();
    // eslint-disable-next-line no-unused-vars
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    //
    // Functions
    //

    const downloadPdf = async () => {
        const responsePdf = await api.getpdf(
            `generate-quotation-pdf/${action}`,
            {}
        );

        if (responsePdf)
            download(
                responsePdf,
                quotation?.code || "pdf-file",
                "application/pdf"
            );
    };

    //
    // UseEffects
    //

    useEffect(() => {
        if (action !== undefined && action !== "create") {
            setIsLoadingActive(true);
            const getExpense = async () => {
                try {
                    const response = await api.get(`quotations/${action}`, {});

                    setQuotation(response.data);
                    setIsLoadingActive(false);
                } catch (error) {
                    setIsLoadingActive(false);
                    setNotif({
                        notifMsg: "Something went wrong with the server",
                        open: true,
                        severity: "danger",
                    });
                }
            };
            getExpense();
        }
    }, [action, setIsLoadingActive]);

    return (
        <>
            <Card className="shadow-none mb-0">
                <Card.Header className="shadow-none pb-0">
                    <Card.Title tag="h5">
                        <Row className="mb-3">
                            <Col md={6}>View quotation</Col>
                            <Col md={6} className="text-end">
                                {quotation && quotation.requisition && (
                                    <>
                                        <Button
                                            variant="primary"
                                            onClick={() =>
                                                navigate(
                                                    `/intellidocs/process/requisitions/${id}/${quotation.requisition.id}/view`
                                                )
                                            }
                                            className="mr-3"
                                        >
                                            {quotation.requisition.code}
                                        </Button>
                                    </>
                                )}
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            Download quotation
                                        </Tooltip>
                                    }
                                >
                                    <Button
                                        variant="outline-primary"
                                        className="me-1 ms-1"
                                        onClick={() => downloadPdf()}
                                    >
                                        <Download
                                            className="align-middle"
                                            size={18}
                                        />
                                    </Button>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </Card.Title>
                </Card.Header>
                <Card.Body className="py-0">
                    <h6>Quotation items</h6>
                    <div className="bg-light p-4 rounded mb-3">
                        <Row className="mb-2">
                            <Col md={1}>S/N</Col>
                            <Col md={2}>Item</Col>
                            <Col md={4}>Description</Col>
                            <Col md={1}>Quantity</Col>
                            <Col md={2}>Rate (S$)</Col>
                            <Col md={2}>Amount (S$)</Col>
                        </Row>
                        <hr style={{ border: "1px solid" }} />
                        {quotation &&
                            quotation.quotation_items.length &&
                            quotation.quotation_items.map((input, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Row className="mb-3">
                                            <Col md={1}>{index + 1}</Col>
                                            <Col md={2}>{input.item}</Col>
                                            <Col md={4}>
                                                {input.description}
                                            </Col>
                                            <Col md={1}>{input.quantity}</Col>
                                            <Col md={2}>{input.rate}</Col>
                                            <Col md={2}>{input.amount}</Col>
                                        </Row>
                                    </React.Fragment>
                                );
                            })}
                        {quotation && (
                            <Row className="mt-3">
                                <Col md={4}></Col>
                                <Col md={4}></Col>
                                <Col md={4}>
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <h4>Subtotal</h4>
                                        </Col>
                                        <Col md={6}>
                                            <h4 className="text-end">
                                                S$
                                                {convertToDecimalWithComma(
                                                    quotation.subtotal
                                                )}
                                            </h4>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <h4>GST</h4>
                                        </Col>
                                        <Col md={6}>
                                            <h4 className="text-end">
                                                {quotation.gst}%
                                            </h4>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <h4>Discount</h4>
                                        </Col>
                                        <Col md={6}>
                                            <h4 className="text-end">
                                                S$
                                                {convertToDecimalWithComma(
                                                    quotation.discount
                                                )}
                                            </h4>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <h4>Total</h4>
                                        </Col>
                                        <Col md={6}>
                                            <h4 className="text-end">
                                                S$
                                                {convertToDecimalWithComma(
                                                    quotation.total
                                                )}
                                            </h4>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </div>
                    <h6>Quotation details</h6>
                    <div className="bg-light p-4 rounded mb-3">
                        <Row className="mb-3">
                            <Col md={2} className="font-weight-bold">
                                Quotation code
                            </Col>
                            <Col md={6}>{quotation?.code}</Col>
                        </Row>
                        <Row>
                            <Col md={2} className="font-weight-bold">
                                Date
                            </Col>
                            <Col md={6}>{quotation?.quotation_date}</Col>
                        </Row>
                    </div>
                    <h6>Billing details</h6>
                    <div className="bg-light p-4 rounded">
                        {quotation && project && (
                            <>
                                <Row className="my-4">
                                    <Col md={6}>
                                        <h4 className="mb-3 mx-4">
                                            Quotation from:
                                        </h4>
                                        <Row className="mx-3 mt-3">
                                            <Col
                                                md={4}
                                                className="font-weight-bold"
                                            >
                                                Company name
                                            </Col>
                                            <Col md={6}>
                                                Intellect Engineering Pte Ltd
                                            </Col>
                                        </Row>
                                        <Row className="mx-3 mt-3">
                                            <Col
                                                md={4}
                                                className="font-weight-bold"
                                            >
                                                Fax
                                            </Col>
                                            <Col md={5}>(65) 6278 7359</Col>
                                        </Row>
                                        <Row className="mx-3 mt-3">
                                            <Col
                                                md={4}
                                                className="font-weight-bold"
                                            >
                                                Contact number
                                            </Col>
                                            <Col md={5}>(65) 6661 0671</Col>
                                        </Row>
                                        <Row className="mx-3 mt-3">
                                            <Col
                                                md={4}
                                                className="font-weight-bold"
                                            >
                                                Address
                                            </Col>
                                            <Col md={5}>
                                                4 Tuas View Square, 637577
                                                Singapore
                                            </Col>
                                        </Row>
                                    </Col>
                                    {project.portfolio && (
                                        <Col md={6}>
                                            <h4 className="mb-3 mx-4">
                                                Quotation to:
                                            </h4>
                                            <Row className="mx-3 mt-3">
                                                <Col
                                                    md={4}
                                                    className="font-weight-bold"
                                                >
                                                    Business name
                                                </Col>
                                                <Col md={5}>
                                                    {project.portfolio.name ||
                                                        "-"}
                                                </Col>
                                            </Row>
                                            <Row className="mx-3 mt-3">
                                                <Col
                                                    md={4}
                                                    className="font-weight-bold"
                                                >
                                                    Email
                                                </Col>
                                                <Col md={5}>
                                                    {project.portfolio.email ||
                                                        "-"}
                                                </Col>
                                            </Row>
                                            <Row className="mx-3 mt-3">
                                                <Col
                                                    md={4}
                                                    className="font-weight-bold"
                                                >
                                                    Contact number
                                                </Col>
                                                <Col md={5}>
                                                    {project.portfolio
                                                        .contact_number || "-"}
                                                </Col>
                                            </Row>
                                            <Row className="mx-3 mt-3">
                                                <Col
                                                    md={4}
                                                    className="font-weight-bold"
                                                >
                                                    Address
                                                </Col>
                                                <Col md={5}>
                                                    {project.portfolio
                                                        .address || "-"}
                                                </Col>
                                            </Row>
                                        </Col>
                                    )}
                                </Row>
                            </>
                        )}
                    </div>
                    <Card.Footer className="text-center pb-0">
                        <Button
                            variant="link"
                            onClick={() =>
                                navigate(
                                    `/intellidocs/process/quotations/${id}`
                                )
                            }
                        >
                            Cancel
                        </Button>
                    </Card.Footer>
                </Card.Body>
            </Card>

            {/* Supporting components */}

            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};
