import React, { useState } from "react";
import { Button, Form, FormLabel, Row } from "react-bootstrap";
import Select from "react-select";
import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";
import api from "../../../../../service/api";
import { addDefaultOption } from "../../../../../utils/utility";
import { useNavigate } from "react-router-dom";

const ApprovalForm = ({ invoiceId, getQuotation, setLoading, system_remarks }) => {
    const navigate = useNavigate();
    
    const [errorMessage, setErrorMessage] = useState(null)
    const [approvalInput, setApprovalInput] = useState({
        status: "",
        remarks: "",
    });

    /** update quotation status */
    const quotationStatusUpdate = async () => {
        try {
            setLoading(true);
            await api.post(
                `tms/sales-quotations/status/${invoiceId}`,
                approvalInput
            );
            getQuotation(invoiceId);
            setLoading(false);
        } catch (error) {
            getQuotation(invoiceId);
            if(error.response.status === 422){
                //if payload "status" is empty
                setLoading(false)
                alert(error.response.data.message)
            }else if(error.response.status === 401){
                //if unauthorized
                navigate("/tms/401")
            }
            else{
                //if other errors
                setLoading(false)
                alert(error.response.data.message)
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault()
        if(approvalInput.status.length === 0){
            setErrorMessage('Please select a status')
            setLoading(false)
        }else{
            setLoading(true)
            quotationStatusUpdate()
        }
        
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
                <FormLabel><h4>System Remarks</h4></FormLabel>
                <Row className="mb-3">
                    <strong>{system_remarks ?? '-'}</strong>
                </Row>
                <Form.Label>Status</Form.Label>
                <Select
                    className="is-invalid react-select-container"
                    classNamePrefix="react-select"
                    options={addDefaultOption([
                        {
                            value: "APPROVED",
                            label: "Approved",
                        },
                        {
                            value: "REJECTED",
                            label: "Reject",
                        },
                    ])}
                    name="status"
                    onChange={(event) => {
                        setApprovalInput({
                            ...approvalInput,
                            status: event.value,
                        });
                    }}
                />
                {errorMessage !== null && (
                    <span className="pt-1"><strong className="text-danger">{errorMessage}</strong></span>
                )}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Remarks</Form.Label>
                <AutoGrowTextarea
                    type="textarea"
                    name="remarks"
                    handleFormChangeDetails={(event) => {
                        setApprovalInput({
                            ...approvalInput,
                            remarks: event.target.value,
                        });
                    }}
                />
            </Form.Group>

            <Button
                variant="primary"
                className="col-md-12"
                size="lg"
                // onClick={quotationStatusUpdate}
                type="submit"
            >
                Save
            </Button>
        </Form>
    );
};
export default ApprovalForm;
