const tableColumns = [
    {
        Header: "S/N",
        accessor: "serial",
    },
    {
        Header: "Requisition code",
        accessor: "code",
    },
    {
        Header: "Requestor",
        accessor: "requestor",
    },
    {
        Header: "Request date",
        accessor: "request_date",
    },
    {
        Header: "Status",
        accessor: "status",
    },
    {
        Header: "Actions",
        accessor: "action",
    },
];

export { tableColumns };
