import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "react-bootstrap";

const tableColumns = [
    {
        Header: "S.no",
        sticky: "left",
        width: "70",
    },
    {
        Header: "Name",
        id: "name",
        accessor: (data) => {
            return <>{data?.enrolment?.name || "-"}</>;
        },
        sticky: "left",
    },
    {
        Header: "FIN",
        id: "fin",
        accessor: (data) => {
            return <>{data?.enrolment?.fin || "-"}</>;
        },
        sticky: "left",
    },
    {
        Header: "Result",
        id: "result",
        accessor: (data) => {
            return <>{data?.enrolment?.result || "-"}</>;
        },
    },
    {
        Header: "Actions",
        width: "106",
        id: "attachment",
    },
    {
        Header: "Training dates",
        id: "training_dates",
        accessor: (data) => {
            return data?.training_dates?.map((date, id) => {
                return (
                    <Badge bg="primary" className="text-wrap">
                        {date}
                    </Badge>
                );
            });
        },
    },
    {
        Header: "Email sent on",
        id: "enrolment.email_sent_on",
        accessor: (data) => {
            return <>{data?.enrolment?.email_sent_on || "-"}</>;
        },
        width: "180",
    },
    {
        Header: "Sector",
        id: "sector",
        accessor: (data) => {
            return <>{data?.enrolment?.sector || "-"}</>;
        },
    },
    {
        Header: "Identification type",
        id: "identification_type",
        accessor: (data) => {
            return <>{data?.enrolment?.identification_type || "-"}</>;
        },
        width: "210",
    },
    {
        Header: "Expire date",
        id: "work_permit_expire_date",
        accessor: (data) => {
            return <>{data?.enrolment?.work_permit_expire_date || "-"}</>;
        },
    },
    {
        Header: "Sponsor company",
        id: "enrolment.sponsor_company.name",
        accessor: (data) => {
            return (
                <div>
                    {data?.enrolment?.self_registration ? (
                        <Badge bg="primary">SELF</Badge>
                    ) : (
                        !data?.self_registration && (
                            <div>
                                {data?.enrolment?.sponsor_company?.name || "-"}
                            </div>
                        )
                    )}
                </div>
            );
        },
        width: "280",
    },
    {
        Header: "Course",
        id: "enrolment.course.name",
        accessor: (data) => {
            return (
                <div>
                    {data?.enrolment?.type_of_trade?.toUpperCase() === "CET" &&
                        "CET"}
                    {data?.enrolment?.type_of_trade?.toUpperCase() !==
                        "CET" && (
                        <div>{data?.enrolment?.course?.name || "-"}</div>
                    )}
                </div>
            );
        },
        width: "280",
    },
    {
        Header: "Trade category",
        id: "enrolment.trade_category",
        accessor: (data) => {
            return <>{data?.enrolment?.trade_category || "-"}</>;
        },
        width: "280",
    },
    {
        Header: "Type of trade",
        id: "enrolment.type_of_trade",
        accessor: (data) => {
            return <>{data?.enrolment?.type_of_trade || "-"}</>;
        },
    },
    {
        Header: "Application option",
        id: "enrolment.application_option",
        accessor: (data) => {
            return <>{data?.enrolment?.application_option || "-"}</>;
        },
        width: "200",
    },
    {
        Header: "Application date",
        id: "enrolment.date",
        accessor: (data) => {
            return <>{data?.enrolment?.date || "-"}</>;
        },
    },
    {
        Header: "Invoice number",
        accessor: "enrolment.invoice_no",
        accessor: (data) => {
            return <>{data?.enrolment?.invoice_no || "-"}</>;
        },
    },
];

export { tableColumns };
