import React from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarNav from "./SidebarNav";
import Logo from "../../assets/img/fonda-logo.png";

const Sidebar = ({ title, items, showFooter = true, app = "" }) => {
    const { isOpen } = useSidebar();
    let dashboardUrl = "/";
    if (app !== "") {
        dashboardUrl = `/${app?.toLowerCase()}`;
    }
    return (
        <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
            <div className="sidebar-content">
                <PerfectScrollbar>
                    <a className="sidebar-brand" href={dashboardUrl}>
                        <img
                            src={Logo}
                            alt="Fonda logo"
                            style={{
                                maxWidth: "55px",
                            }}
                        />{" "}
                        <span className="align-middle me-3">{title}</span>
                    </a>

                    <SidebarNav items={items} />
                </PerfectScrollbar>
            </div>
        </nav>
    );
};

export default Sidebar;
