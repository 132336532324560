import React, { useCallback, useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import api from "../../../../../service/api";
import FormRow from "./FormRow";
import { SnackbarAlert } from "../../../../../components/SnackbarAlert";
import "./../../../../../assets/css/common.css";
const CreditNoteRequest = () => {
    const { id } = useParams(); // invoice id
    const [invoiceStudent, setInvoiceStudent] = useState();
    const [invoice, setInvoice] = useState();
    const [formConfig, setFormConfig] = useState();
    const [loading, setLoading] = useState(false);

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    /** get the invoice */
    const getInvoice = useCallback(async (id) => {
        try {
            setLoading(true);
            // const response = await api.get(`tms/invoice-students/${id}`);
            const response = await api.get(`tms/invoice-students/${id}`);
            const invoiceResponse = await api.get(`tms/invoices/${id}`);
            const configs = await api.get(`tms/invoices/get-config`);
            setInvoiceStudent(response.data);
            setInvoice(invoiceResponse.data);
            setFormConfig(configs.form.fields);
            setLoading(false);
        } catch (error) {}
    }, []);

    /**  To get quotation */
    useEffect(() => {
        getInvoice(id);
    }, [id, getInvoice]);

    return (
        <React.Fragment>
            <Helmet title="Sales > Credit note request" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Credit notes request</h1>
                <Card className="m-0 p-0">
                    <Card.Body>
                        {loading && <div>Loading...</div>}
                        {!loading && invoiceStudent && (
                            <FormRow
                                invoiceStudent={invoiceStudent}
                                invoiceId={id}
                                invoice={invoice}
                                setNotif={setNotif}
                                formConfig={formConfig}
                                setLoading={setLoading}
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </React.Fragment>
    );
};

export default CreditNoteRequest;
