// Guards
import AuthGuard from "../../components/guards/AuthGuard";
import IntelliDocsLayout from "./Layout/IntelliDocsLayout";
import { Portfolio } from "./Portfolio/Portfolio";
import { AddEditPortfolio } from "./Portfolio/AddEditPortfolio";
import { Programme } from "./Programme/Programme";
import { AddEditProgramme } from "./Programme/AddEditProgramme";
import { Project } from "./Project/Project";
import { AddEditProject } from "./Project/AddEditProject";
import { Documents } from "./Documents/Documents";

import { IntelliDocsUsers } from "./Settings/IntelliDocsUsers";
import { IntelliDocsRoles } from "./Settings/IntelliDocsRoles";
import { IntelliDocsPermissions } from "./Settings/IntelliDocsPermissions";

//Shared
import { AddEditUser } from "../IntelliSuite/Users/AddEditUser";
import { AddEditRole } from "../IntelliSuite/Roles/AddEditRole";
import { AddEditPermission } from "../IntelliSuite/Permissions/AddEditPermission";

const IntelliDocsRoutes = {
    path: "/intellidocs",
    element: (
        <AuthGuard>
            <IntelliDocsLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: "portfolio",
            element: (
                <AuthGuard>
                    <Portfolio />,
                </AuthGuard>
            ),
        },
        {
            path: "portfolio/:id",
            element: (
                <AuthGuard>
                    <AddEditPortfolio />,
                </AuthGuard>
            ),
        },
        {
            path: "programme",
            element: (
                <AuthGuard>
                    <Programme />,
                </AuthGuard>
            ),
        },
        {
            path: "programme/:id",
            element: (
                <AuthGuard>
                    <AddEditProgramme />,
                </AuthGuard>
            ),
        },
        {
            path: "project",
            element: (
                <AuthGuard>
                    <Project />,
                </AuthGuard>
            ),
        },
        {
            path: "project/:id",
            element: (
                <AuthGuard>
                    <AddEditProject />,
                </AuthGuard>
            ),
        },

        // Dashboard Routes
        {
            path: "process/dashboard/:id",
            element: (
                <AuthGuard>
                    <Documents tabPage="dashboard" />
                </AuthGuard>
            ),
        },

        // Expenses Routes
        {
            path: "process/expenses/:id",
            element: (
                <AuthGuard>
                    <Documents tabPage="expenses" />
                </AuthGuard>
            ),
        },
        {
            path: "process/expenses/:id/:action",
            element: (
                <AuthGuard>
                    <Documents tabPage="expenses" action="addEdit" />
                </AuthGuard>
            ),
        },
        {
            path: "process/expenses/:id/:action/view",
            element: (
                <AuthGuard>
                    <Documents tabPage="expenses" action="viewExpense" />
                </AuthGuard>
            ),
        },

        // Requisition Routes
        {
            path: "process/requisitions/:id",
            element: (
                <AuthGuard>
                    <Documents tabPage="requisitions" />
                </AuthGuard>
            ),
        },
        {
            path: "process/requisitions/:id/:action",
            element: (
                <AuthGuard>
                    <Documents tabPage="requisitions" action="addEdit" />
                </AuthGuard>
            ),
        },
        {
            path: "process/requisitions/:id/:action/view",
            element: (
                <AuthGuard>
                    <Documents
                        tabPage="requisitions"
                        action="viewRequisition"
                    />
                </AuthGuard>
            ),
        },

        // Quotation Routes
        {
            path: "process/quotations/:id",
            element: (
                <AuthGuard>
                    <Documents tabPage="quotations" />
                </AuthGuard>
            ),
        },
        {
            path: "process/quotations/:id/:action",
            element: (
                <AuthGuard>
                    <Documents tabPage="quotations" action="addEdit" />
                </AuthGuard>
            ),
        },
        {
            path: "process/quotations/:id/:action/view",
            element: (
                <AuthGuard>
                    <Documents tabPage="quotations" action="viewQuotation" />
                </AuthGuard>
            ),
        },

        // Delivery Order Routes
        {
            path: "process/quotations/:id/:action/do",
            element: (
                <AuthGuard>
                    <Documents tabPage="quotations" action="deliveryOrder" />
                </AuthGuard>
            ),
        },
        {
            path: "process/quotations/:id/:action/do/:actiondo",
            element: (
                <AuthGuard>
                    <Documents
                        tabPage="quotations"
                        action="addEditDeliveryOrder"
                    />
                </AuthGuard>
            ),
        },
        {
            path: "process/quotations/:id/:action/do/:actiondo/view",
            element: (
                <AuthGuard>
                    <Documents
                        tabPage="quotations"
                        action="viewDeliveryOrder"
                    />
                </AuthGuard>
            ),
        },

        // Revenue routes
        {
            path: "process/revenues/:id",
            element: (
                <AuthGuard>
                    <Documents tabPage="revenues" />
                </AuthGuard>
            ),
        },
        {
            path: "process/revenues/:id/:action",
            element: (
                <AuthGuard>
                    <Documents tabPage="revenues" action="addEdit" />
                </AuthGuard>
            ),
        },
        {
            path: "process/revenues/:id/:action/view",
            element: (
                <AuthGuard>
                    <Documents tabPage="revenues" action="viewRevenue" />
                </AuthGuard>
            ),
        },

        // Received Payment Routes
        {
            path: "process/revenues/:id/:action/do",
            element: (
                <AuthGuard>
                    <Documents tabPage="revenues" action="receivedPayment" />
                </AuthGuard>
            ),
        },
        {
            path: "process/revenues/:id/:action/do/:actiondo",
            element: (
                <AuthGuard>
                    <Documents
                        tabPage="revenues"
                        action="addEditReceivedPayment"
                    />
                </AuthGuard>
            ),
        },
        {
            path: "process/revenues/:id/:action/do/:actiondo/view",
            element: (
                <AuthGuard>
                    <Documents
                        tabPage="revenues"
                        action="viewReceivedPayment"
                    />
                </AuthGuard>
            ),
        },

        // Users / Roles / Permissions

        {
            path: "users",
            element: (
                <AuthGuard>
                    <IntelliDocsUsers />
                </AuthGuard>
            ),
        },
        {
            path: "users/:id",
            element: (
                <AuthGuard>
                    <AddEditUser />
                </AuthGuard>
            ),
        },
        {
            path: "roles",
            element: (
                <AuthGuard>
                    <IntelliDocsRoles />
                </AuthGuard>
            ),
        },
        {
            path: "roles/:id",
            element: (
                <AuthGuard>
                    <AddEditRole />
                </AuthGuard>
            ),
        },
        {
            path: "permissions",
            element: (
                <AuthGuard>
                    <IntelliDocsPermissions />
                </AuthGuard>
            ),
        },
        {
            path: "permissions/:id",
            element: (
                <AuthGuard>
                    <AddEditPermission />
                </AuthGuard>
            ),
        },
    ],
};

export default IntelliDocsRoutes;
