import React, {useState} from 'react';
import { Table, Row, Col, Pagination, Form } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useTable, usePagination } from "react-table";
import { tableHeader } from "./tableHeader";
import { Edit2, Trash } from "react-feather";
import { DeleteModal } from "../../../components/DeleteModal.js";

export const RolesTable = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const columns = tableHeader;
    const data = props.roles;
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }
        },
        usePagination
    );
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });

    const openDeleteModal = (id) => {
        setModalInfo({
            id: id,
            notifMsg: "Are you sure you want to delete this role?",
            open: true,
            severity: "danger",
        });
    };

    return (
        <>
            <Table striped bordered {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th>
                                {column.render("Header")}
                            </th>
                        ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {data && data.length < 1 && 
                        <tr className='text-center'>
                            <td colSpan={5}>No records found</td>
                        </tr>
                    }
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell, index, row) => {
                                    if (cell.column.id === 'sequence_no') {
                                       return <td key={cell.column.id}>{cell.row.index + 1}</td>
                                    }

                                    if (index + 1 === row.length) {
                                        return (
                                            <td key={index}>
                                                <a className="me-1" href={() => false} onClick={() => navigate(location.pathname+'/'+cell.row.original.id)}>
                                                    <Edit2 className="align-middle me-1" size={18} color="blue" />
                                                </a>
                                                <a className="me-1" href={() => false} onClick={() => openDeleteModal(cell.row.original.id)}>
                                                    <Trash className="align-middle me-1" size={18} color="red" />
                                                </a>   
                                            </td>
                                        );
                                    }
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                    );
                                })}
                            </tr>
                           
                        )
                    })}
                </tbody>
            </Table>
            <Row>
                <Col md="6">
                <span className="mx-2">
                    Page{" "}
                    <strong>
                    {pageIndex + 1} of {pageOptions.length}
                    </strong>
                </span>
                <span className="ms-3 me-2">Show:</span>
                <Form.Select
                    className="d-inline-block w-auto"
                    value={pageSize}
                    onChange={(e) => {
                    setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                        {pageSize}
                    </option>
                    ))}
                </Form.Select>

                <span className="ms-3 me-2">Go to page:</span>
                <Form.Control
                    className="d-inline-block"
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(page);
                    }}
                    style={{ width: "75px" }}
                />
                </Col>
                <Col md="6">
                    <Pagination className="float-end">
                        <Pagination.First
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        />
                        <Pagination.Prev
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        />
                        <Pagination.Next
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        />
                        <Pagination.Last
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        />
                    </Pagination>
                </Col>
            </Row>
            <DeleteModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={props.deleteAction}
            />
           
        </>
    )
}