import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Container,
    Card,
    Row,
    Col,
    Form,
    Button
} from "react-bootstrap";
import api from "../../../service/api";
import { PermissionsTable } from "./PermissionsTable";
import { SnackbarAlert } from "../../../components/SnackbarAlert.js";
import { pressEnterToSearch } from "../../../utils/utility.js";
//import { AppContext } from "../../Tms/Guard/TmsAppGuard";

export const Permissions = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    //const app = useContext(AppContext);
    const app = props.app
    const [permissions, setPermissions] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [filter, setFilter] = useState({
        app_id : app ? app.id : null,
        search : '',
    });
    const [notif, setNotif] = useState({
        notifMsg  : "",
        open      : false,
        severity  : "success",
    });
    const getPermissionsApi = useCallback(async () => {
        const getPermissions = async () => {
            const response = await api.get('permissions', filter)
            setPermissions(response.data)
        }
        getPermissions()
    }, [filter]);

    useEffect(() => {
        getPermissionsApi();
    }, [getPermissionsApi])

    const search = () => {
        setFilter((prevState) => ({
        ...prevState,
            search: searchValue,
        }));
    }

    const deletePermission = (id) => {
        api.delete('permissions/' + id, {})
            .then(response => {
                if (response.status === 'ok' || response.success) {
                    setNotif({
                        notifMsg: 'Successfully deleted',
                        open: true,
                        severity: "success",
                    });
                    getPermissionsApi()
                }
        })
    }

    return (
        <React.Fragment>
             <Helmet title="Configuration > Permissions" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Permissions</h1>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={6}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Control
                                            className="d-inline-block"
                                            type="text"
                                            onChange={(e) => setSearchValue(e.target.value)}
                                            onKeyDown={(e) => pressEnterToSearch(e, search)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Button
                                            variant="success"
                                            className="me-1 mb-1"
                                            onClick={() => search()}
                                        >
                                            Search
                                        </Button>
                                    </Col>
                                </Row>    
                            </Col>
                            <Col md={6}>
                                <Button
                                    variant="primary"
                                    className="me-1 float-end"
                                    onClick={() => navigate(location.pathname+"/add")}
                                >
                                    Add permission
                                </Button>
                            </Col>
                            
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <PermissionsTable
                            permissions={permissions}
                            app_id={app?.id}
                            deleteAction={deletePermission}
                        />
                    </Card.Body>
                </Card>    
            </Container>
             <SnackbarAlert notif={notif} setNotif={setNotif} />
        </React.Fragment>
    )
}

