import { useSelector } from "react-redux";

/** Permissions */
export const UserDateFormat = () => {
    const userData = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const setting = userData?.data?.setting || {};
    if (setting !== null && Object.hasOwn(setting, "date_javascript")) {
        return setting?.date_javascript;
    } else {
        return "yyyy-MM-dd";
    }
};
