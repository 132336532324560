import React, { useState } from "react";

import { Row, Col, Button, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";

import api from "../../../service/api";
import DynamicTable from "../../../components/version-two-components/DynamicTable";
import { SendEmailPreviewModal } from "./SendEmailPreviewModal";
import { SendEmailResultPreviewModal } from "./SendEmailResultPreviewModal";
import EmailListTable from "./EmailListTable";
import { SnackbarAlert } from "../../../components/SnackbarAlert";

const SendEmailList = ({
    columns,
    data,
    information,
    sponsorCompany,
    batch,
    getStudents,
}) => {
    let { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState("");
    const [modalInfo, setModalInfo] = useState({
        open: false,
        data: "",
    });
    const [resultmodalInfo, setResultModalInfo] = useState({
        open: false,
        data: "",
    });

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const sendEmailToStudents = async () => {
        try {
            setLoading(true);

            const selectedStudents = selectedRows.map((d) => d?.enrolment?.id);

            const response = await api.post(`tms/enrollment-emails/send-email`, {
                enrollments: selectedStudents,
                batch_id: id,
                sponsor_company_id: information?.sponsor_company_id,
                preview_temp: information?.preview_temp,
            });

            // console.log("THIS IS RES: ", response);
            
            if (response.status === "ok") {
                setNotif({
                    notifMsg: "Send email successful",
                    open: true,
                    severity: "success",
                });
            }

            setLoading(false);
        } catch (error) {
            console.error(error);
            setNotif({
                notifMsg: "Something went wrong in the server.",
                open: true,
                severity: "danger",
            });
        }
    };

    const sendEmailResultToStudents = async () => {
        try {
            setLoading(true);

            const selectedStudents = selectedRows.map((d) => d?.enrolment?.id);

            await api.post(`tms/enrollment-emails/send-email`, {
                enrollments: selectedStudents,
                batch_id: id,
                sponsor_company_id: information?.sponsor_company_id,
                preview_temp: information?.preview_temp,
            });

            setLoading(false);
        } catch (error) {}
    };

    return (
        <>
            {loading && <div>Loading...</div>}
            {!loading && (
                <>
                    <Row className="mb-3">
                        <h6>{sponsorCompany}</h6>
                    </Row>
                    {selectedRows.length > 0 && batch.status !== "Completed" && (
                        <>
                            <Row className="mb-2">
                                <Col md={6}>
                                    <Button
                                        variant="info"
                                        onClick={() => sendEmailToStudents()}
                                        className="me-2"
                                    >
                                        Send email
                                    </Button>
                                    <Button
                                        variant="warning"
                                        onClick={() =>
                                            setModalInfo({
                                                open: true,
                                                data: information,
                                                selectedRows: selectedRows,
                                            })
                                        }
                                    >
                                        Preview Confirmation
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                    {selectedRows.length > 0 &&
                        batch.status === "Completed" &&
                        batch.course_id && (
                            <>
                                <Row className="mb-2">
                                    <Col md={6}>
                                        <Button
                                            variant="info"
                                            onClick={() =>
                                                sendEmailResultToStudents()
                                            }
                                            className="me-2"
                                        >
                                            Send Result
                                        </Button>
                                        <Button
                                            variant="warning"
                                            onClick={() =>
                                                setResultModalInfo({
                                                    open: true,
                                                    data: information,
                                                    selectedRows: selectedRows,
                                                })
                                            }
                                        >
                                            Preview email Result
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                    {data.length === 0 && (
                        <Alert className="my-3" variant="warning">
                            <div className="alert-message">No students</div>
                        </Alert>
                    )}
                    <EmailListTable
                        dataPagination={{
                            data: data,
                            meta: "",
                            links: "",
                        }}
                        columns={columns}
                        className="table-layout-fixed"
                        setSelectedRows={setSelectedRows}
                        isPaginated
                        withCheckbox
                        getStudents={getStudents}
                    />
                    <SendEmailPreviewModal
                        modalInfo={modalInfo}
                        setModalInfo={setModalInfo}
                    />
                    <SendEmailResultPreviewModal
                        modalInfo={resultmodalInfo}
                        setModalInfo={setResultModalInfo}
                    />
                    <SnackbarAlert notif={notif} setNotif={setNotif} />
                </>
            )}
        </>
    );
};

export default SendEmailList;
