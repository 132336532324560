const dataSummary = [
    {
        Header: "S.no",
    },
    {
        Header: "Invoice Number",
        accessor: "invoice.number",
    },

    {
        Header: "Customer",
        accessor: "invoice.company.name",
    },
    // {
    //     Header: "Student",
    //     id: "Student",
    //     accessor: (data) => {
    //         return data?.invoice_course_students?.students?.map(
    //             (student, index) => {
    //                 return (
    //                     <div key={index}>
    //                         {index + 1}. {student}
    //                     </div>
    //                 );
    //             }
    //         );
    //     },
    // },
    {
        Header: "Request type",
        accessor: (data) => {
            return (
                <div style={{ textTransform: 'capitalize' }}>
                    {data.type}
                </div>
            );
        },
    },
    {
        Header: "Reason",
        accessor: (data) => {
            return (
                <div style={{ textTransform: 'capitalize' }}>
                    {data.reason}
                </div>
            );
        },
    },
    {
        Header: "Requested by",
        id: "requested_by",
        accessor: (data) => {
            return (
                <div>
                    {data.user?.name}
                    <br /> {new Date(data.created_at).toISOString().split('T')[0].split('-').reverse().join('-')}
                </div>
            );
        },
    },

    // {
    //     Header: "Refund request",
    //     id: "refund",
    //     accessor: (data) => {
    //         return <>{data?.refund.toUpperCase() === "YES" ? "Yes" : "No"}</>;
    //     },
    // },
    {
        Header: "Refund status",
        accessor: "refund_status",
    },
    {
        Header: "Sales Director Status",
        accessor: "sales_director_status",
    },
    {
        Header: "Sales Director Approved Date",
        accessor: "sales_director_approved_date",
        width: "300",
        getProps: () => {
            return {
                style: {
                    whiteSpace: 'nowrap!important',
                },
            };
        },
    },
    {
        Header: "Finance Director Status",
        accessor: "finance_director_status",
    },
    {
        Header: "Finance Director Approved Date",
        accessor: "finance_director_approved_date",
        width: "300",
        getProps: () => {
            return {
                style: {
                    whiteSpace: 'nowrap!important',
                },
            };
        },
    },
    {
        Header: "CN Status",
        accessor: "status",
    },
    {
        Header: "Action",
        accessor: "action",
    },
];

export { dataSummary };
