import { Alert } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";

import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import api from "../../../../../service/api";
import FormRow from "./FormRow";
const AddEditAdhoc = () => {
    let { id } = useParams();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [adhocInvoice, setAdhocInvoice] = useState();
    const [courseFee, setCourseFee] = useState();
    const [configValues, setConfigValues] = useState({
        tradeOptions: [],
        tradeCategory: [],
        tradeApplication: [],
        atoCourses: [],
        attcCourses: [],
        companies: [],
        departments: [],
        location: [],
        all_adhoc_additional: [],
    });

    const setCourseData = (data) => {
        const formatAto = data
            .filter((data, index) => {
                return data?.type?.toUpperCase() === "ATO";
            })
            .map((data, index) => {
                return { value: data.id, label: data.name };
            });
        const formatAttc = data
            .filter((data, index) => {
                return data?.type?.toUpperCase() === "ATTC";
            })
            .map((data, index) => {
                return { value: data.id, label: data.name };
            });
        const { atoCourses, attcCourses } = { ...configValues };
        atoCourses.push(...formatAto);
        attcCourses.push(...formatAttc);
        setConfigValues({ atoCourses, attcCourses });
    };

    const setCompanyData = (data) => {
        const formatData = data.map((data, index) => {
            return { value: data.id, label: data.name };
        });
        const { companies } = { ...configValues };
        companies.push(...formatData);
        setConfigValues(companies);
    };

    /** use effect */
    useEffect(() => {
        const getConfig = async (adhocType) => {
            try {
                setLoading(true);
                const configResponse = await api.get(
                    `tms/sales-quotations/config`,
                    {}
                );
              
                const courseResponse = await api.get(`tms/courses?all=1`, {});
                // const adhocResponse = await api.get("tms/invoice/get-ad-hoc-additional-invoice-item");
                const adhocResponse = await api.post("tms/invoice/get-ad-hoc-invoice-item",{adhoc_type : adhocType});
                const courseFeeResponse = await api.get("tms/invoice/get-add-invoice-item");
                
                setCourseData(courseResponse?.data);    
                setCourseFee(courseFeeResponse.data);
                // setConfigValues({ ...configValues, ...configResponse.data });
                setConfigValues({ ...configValues, ...configResponse.data, ...adhocResponse.data });
               
                setLoading(false);
            } catch (error) {
                setError(error?.message);
            }
        };
        if (id && id !== "add") {
            const getQuotation = async () => {
                try {
                    setLoading(true);
                    const response = await api.get(
                        `tms/invoices/${id}`,
                        {}
                    );
                    setAdhocInvoice(response.data);
                    getConfig(response?.data?.adhoc_type);
                    setLoading(false);
                } catch (error) {}
            };
            getQuotation();
        } 
        
        if (id && id === 'add') {
            getConfig("");
        }
        
    }, [id]); //  eslint-disable-line react-hooks/exhaustive-deps
    const helmetTitle = `${id !== "add" ? "Edit" : "Create"} adhoc`;
    return (
        <React.Fragment>
            <Helmet title={helmetTitle} />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">
                    {helmetTitle}
                </h1>
                <Card className="m-0 p-0">
                    <Card.Body>
                        {error && (
                            <Alert className="my-3" variant="danger">
                                <div className="alert-message">{error}</div>
                            </Alert>
                        )}
                        {loading && <div>Loading...</div>}
                        {!loading && (
                            <FormRow
                                configValues={configValues}
                                adhocInvoice={adhocInvoice}
                                courseFee={courseFee}
                                setConfigValues={setConfigValues}
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

export default AddEditAdhoc;
