import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Search } from "react-feather";
import Select from "react-select";
import { UserDateFormat } from "../../../../utils/dateFormat";
import DatePicker from "react-datepicker";
import moment from "moment";
import { addDefaultOption } from "../../../../utils/utility";
import api from "../../../../service/api";
const BatchSearch = ({
    setSearchValue,
    search,
    defaultConfig,
    searchValue,
}) => {
    const DateFormat = UserDateFormat();
    const [advanceSearch, setAdvanceSearch] = useState(true);
    const [loading, setLoading] = useState(false);
    const [configValues, setConfigValues] = useState({
        tradeOptions: [],
        tradeCategory: [],
        tradeApplication: [],
        paymentMode: [],
        additionalTraining: [],
        atoCourses: [],
        attcCourses: [],
        nationality: [],
        companies: [],
        registrationOption: [],
        workPermits: [],
    });

    const setCourseData = (data) => {
        const formatAto = data
            .filter((data, index) => {
                return data.type?.toLowerCase() === "ato";
            })
            .map((data, index) => {
                return { value: data.id, label: data.name };
            });

        const formatAttc = data
            .filter((data, index) => {
                return data?.type?.toLowerCase() === "attc";
            })
            .map((data, index) => {
                return { value: data.id, label: data.name };
            });

        const { atoCourses, attcCourses } = { ...configValues };

        atoCourses.push(...formatAto);
        attcCourses.push(...formatAttc);
        setConfigValues({ atoCourses, attcCourses });
    };

    // const setCompanyData = (data) => {
    //     const formatData = data.map((data, index) => {
    //         return { value: data.id, label: data.name };
    //     });

    //     const { companies } = { ...configValues };
    //     companies.push(...formatData);
    //     setConfigValues(companies);
    // };

    const getConfig = useCallback(async () => {
        try {
            const configResponse = await api.get(`tms/enrolments/config`, {});
            const courseResponse = await api.get(`tms/courses?all=1`, {});
            // const companyResponse = await api.get(`tms/companies?all=1`, {});
            setCourseData(courseResponse?.data);
            // setCompanyData(companyResponse?.data);
            setConfigValues({ ...configValues, ...configResponse.data });
            setLoading(false);
        } catch (error) {}
    }, []);

    useEffect(() => {
        getConfig();
    }, [getConfig]);

    return (
        <>
            <Row>
                <Col md={3}>
                    <Form.Control
                        className="d-inline-block mb-3"
                        size="lg"
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={searchValue.name}
                        onChange={(e) => {
                            setSearchValue({
                                ...searchValue,
                                name: e.target.value,
                            });
                        }}
                    />
                </Col>
                <Col md={3}>
                    <DatePicker
                        selected={
                            searchValue?.test_date !== "" &&
                            searchValue?.test_date !== null &&
                            new Date(searchValue?.test_date)
                        }
                        className={`d-inline-block form-control form-control-lg mb-3`}
                        size="lg"
                        onChange={(dates) => {
                            setSearchValue({
                                ...searchValue,
                                test_date:
                                    dates === null
                                        ? null
                                        : moment(dates).format("YYYY-MM-DD"),
                            });
                        }}
                        dateFormat={DateFormat}
                        placeholderText="Select test date"
                        isClearable={
                            searchValue?.test_date !== "" &&
                            searchValue?.test_date !== null &&
                            true
                        }
                    />
                </Col>
                {/* <Col md={3}>
                    <Select
                        className="is-invalid react-select-container"
                        classNamePrefix="react-select"
                        options={addDefaultOption(defaultConfig?.location)}
                        name="location"
                        placeholder="Select location"
                        onChange={(value) => {
                            setSearchValue({
                                ...searchValue,
                                location: value?.value,
                            });
                        }}
                        isClearable={true}
                    />
                </Col> */}
                <Col md={3}>
                    <Select
                        className="is-invalid react-select-container mb-3"
                        classNamePrefix="react-select"
                        options={addDefaultOption(defaultConfig?.location)}
                        name="test_venue"
                        placeholder="Select test venue"
                        onChange={(value) => {
                            setSearchValue({
                                ...searchValue,
                                test_venue: value?.value,
                            });
                        }}
                        isClearable={true}
                    />
                </Col>
                <Col md={3}>
                    <Select
                        className="is-invalid react-select-container mb-3"
                        classNamePrefix="react-select"
                        options={addDefaultOption(defaultConfig?.location)}
                        name="training_venue"
                        placeholder="Select training venue"
                        onChange={(value) => {
                            setSearchValue({
                                ...searchValue,
                                training_venue: value?.value,
                            });
                        }}
                        isClearable={true}
                    />
                </Col>
                {/* <Col md={3}>
                    <Form.Group className="mb-3">
                        <Select
                            className="is-invalid react-select-container"
                            classNamePrefix="react-select"
                            options={addDefaultOption(
                                configValues?.departments
                            )}
                            name="type"
                            onChange={(value) => {
                                setSearchValue({
                                    ...searchValue,
                                    type: value.value,
                                });
                            }}
                            isSearchable="true"
                            placeholder="Select type"
                            value={
                                searchValue?.type &&
                                configValues?.departments?.filter(
                                    (option) => {
                                        return (
                                            option.value ===
                                            searchValue?.type
                                        );
                                    }
                                )
                            }
                        />
                    </Form.Group>
                </Col> */}
                <Col md={3}>
                    <Form.Group>
                        <Select
                            className="react-select-container mb-3"
                            classNamePrefix="react-select"
                            options={addDefaultOption(
                                configValues?.tradeCategory
                            )}
                            name="trade_category"
                            onChange={(value) => {
                                setSearchValue({
                                    ...searchValue,
                                    trade_category:
                                        value.value,
                                });
                            }}
                            placeholder="Select trade category"
                            value={
                                searchValue?.trade_category &&
                                configValues?.tradeCategory?.filter(
                                    (option) => {
                                        return (
                                            option.value ===
                                            searchValue?.trade_category
                                        );
                                    }
                                )
                            }
                        />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group>
                        <Select
                            className="react-select-container mb-3"
                            classNamePrefix="react-select"
                            options={addDefaultOption(
                                configValues?.tradeOptions
                            )}
                            name="type_of_trade"
                            onChange={(value) => {
                                setSearchValue({
                                    ...searchValue,
                                    type_of_trade:
                                        value.value,
                                });
                            }}
                            placeholder="Select type of trade"
                            value={
                                searchValue?.type_of_trade &&
                                configValues?.tradeOptions?.filter(
                                    (option) => {
                                        return (
                                            option.value ===
                                            searchValue?.type_of_trade
                                        );
                                    }
                                )
                            }
                        />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group>
                        <Select
                            className="react-select-container mb-3"
                            classNamePrefix="react-select"
                            options={addDefaultOption(
                                configValues?.tradeApplication
                            )}
                            name="application_option"
                            onChange={(value) => {
                                setSearchValue({
                                    ...searchValue,
                                    application_option:
                                        value.value,
                                });
                            }}
                            placeholder="Select application"
                            value={
                                searchValue?.application_option &&
                                configValues?.tradeApplication?.filter(
                                    (option) => {
                                        return (
                                            option.value ===
                                            searchValue?.application_option
                                        );
                                    }
                                )
                            }
                        />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group>
                        <Select
                            className="is-invalid react-select-container mb-3"
                            classNamePrefix="react-select"
                            options={addDefaultOption(
                                searchValue?.type?.toLowerCase() ===
                                    "ato"
                                    ? configValues?.atoCourses
                                    : configValues?.attcCourses
                            )}
                            name="course_id"
                            onChange={(value) => {
                                setSearchValue({
                                    ...searchValue,
                                    course_id: value.value,
                                });
                            }}
                            placeholder="Select course"
                            value={
                                searchValue?.type?.toLowerCase() ===
                                "ato"
                                    ? searchValue?.course_id &&
                                        configValues?.atoCourses?.filter(
                                            (option) => {
                                                return (
                                                    option.value ===
                                                    searchValue?.course_id
                                                );
                                            }
                                        )
                                    : searchValue?.course_id &&
                                        configValues?.attcCourses?.filter(
                                            (option) => {
                                                return (
                                                    option.value ===
                                                    searchValue?.course_id
                                                );
                                            }
                                        )
                            }
                        />
                    </Form.Group>
                </Col>
                <Col md={12} className="d-flex justify-content-end">
                    <Button
                        variant="info"
                        size="lg"
                        className="me-1 mb-1"
                        onClick={() => {
                            search({ page: 1 });
                        }}
                    >
                        <Search className="feather" />
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default BatchSearch;
