import React, { useState } from "react";

import { useTable, usePagination, useSortBy } from "react-table";
import { useParams } from "react-router-dom";
import {
    Table,
    Row,
    Col,
    Alert,
    Pagination,
    Button,
    Form,
    Card,
} from "react-bootstrap";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker } from "antd";
import moment from "moment";
import download from "downloadjs";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { DeleteModal } from "../../../../components/DeleteModal";
import { SnackbarAlert } from "../../../../components/SnackbarAlert";
import { WithdrawModal } from "../../../../components/WithdrawModal";
import api from "../../../../service/api";
import { UserDateFormat } from "../../../../utils/dateFormat";

const Lists = ({
    columns,
    rawData,
    searchApi,
    status,
    searchValue,
    setRerender,
    rerender,
}) => {
    /** Permissions */
    const permissionData = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = permissionData?.data?.permissions;
    const params = useParams();

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const [loading, setLoading] = useState(true);
    
    const { 
        meta,
        data,
        links,
    } = rawData;
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: {
            pageSize,
            canNextPage,
            canPreviousPage,
            totalPage,
            currentPage,
            firstPage,
            lastPage,
            total,
        },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: meta?.per_page,
                // pageSize: meta?.per_page,
                canNextPage: meta?.next_page_url !== null ? true : false,
                canPreviousPage: meta?.prev_page_url !== null ? true : false,
                totalPage: meta?.last_page,
                currentPage: meta?.current_page,
                firstPage: 1,
                lastPage: meta?.last_page,
                total: meta?.total,
            },
        },
        useSortBy,
        usePagination
    );
    const gotoPage = (page) => {
        searchApi({
            page,
        });
    };

    const [error, setError] = useState();

    const Styles = styled.div`
        .table {
            .th,
            .td {
                background-color: #fff;
                overflow: hidden;
                border-top: none;
            }

            &.sticky {
                overflow: scroll;
                .header,
                .footer {
                    position: sticky;
                    z-index: 1;
                    width: fit-content;
                }
            }
        }
        .table > :not(:first-child) {
            border-top: none;
        }
    `;

    return (
        <>
            {data.length > 0 && (
                <>
                    {totalPage > 1 && (
                        <Row>
                            {error && (
                                <Card.Header>
                                    <Alert className="my-3" variant="danger">
                                        <div className="alert-message">
                                            {error}
                                        </div>
                                    </Alert>
                                </Card.Header>
                            )}
                            <Col md="6">
                                <span className="mx-2">
                                    Page {""}
                                    <strong>
                                        {currentPage} of {totalPage}
                                    </strong>
                                </span>
                            </Col>
                            <Col md="6" className="d-flex justify-content-end">
                                <Pagination className="float-end">
                                    <Pagination.First
                                        onClick={() => gotoPage(firstPage)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPage(currentPage - 1)
                                        }
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPage(currentPage + 1)
                                        }
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(lastPage)}
                                        disabled={!canNextPage}
                                    />
                                </Pagination>
                            </Col>
                        </Row>
                    )}
                    
                    <Styles>
                    <Table responsive striped bordered {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps()
                                            )}
                                        >
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell, index, row) => {
                                            if (index === 0) {
                                                return (
                                                    <td key={index}>
                                                        {currentPage > 1
                                                            ? (currentPage -
                                                                  1) *
                                                                  pageSize +
                                                              i +
                                                              1
                                                            : i + 1}
                                                    </td>
                                                );
                                            }
                                           
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    </Styles>

                    {totalPage > 1 && (
                        <Row className="mt-2">
                            <Col md="6">
                                <span className="mx-2">
                                    Page {""}
                                    <strong>
                                        {currentPage} of {totalPage}
                                    </strong>
                                </span>
                            </Col>
                            <Col md="6">
                                <Pagination className="float-end">
                                    <Pagination.First
                                        onClick={() => gotoPage(firstPage)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPage(currentPage - 1)
                                        }
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPage(currentPage + 1)
                                        }
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(lastPage)}
                                        disabled={!canNextPage}
                                    />
                                </Pagination>
                            </Col>
                        </Row>
                    )}
                </>
            )}
            {data.length === 0 && (
                <Alert variant="warning">
                    <div className="alert-message">No pending payments</div>
                </Alert>
            )}
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export { Lists };
