import React, { useState } from "react";

import { useTable, usePagination, useSortBy } from "react-table";
import { useParams } from "react-router-dom";
import {
    Table,
    Row,
    Col,
    Alert,
    Pagination,
    Button,
    Form,
    Card,
} from "react-bootstrap";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker } from "antd";
import moment from "moment";
import download from "downloadjs";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { DeleteModal } from "../../../../components/DeleteModal";
import { SnackbarAlert } from "../../../../components/SnackbarAlert";
import { WithdrawModal } from "../../../../components/WithdrawModal";
import api from "../../../../service/api";
import { UserDateFormat } from "../../../../utils/dateFormat";

const Lists = ({
    columns,
    rawData,
    enrolmentApi,
    status,
    searchValue,
    setRerender,
    rerender,
}) => {
    /** Permissions */
    const permissionData = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = permissionData?.data?.permissions;
    const params = useParams();

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });

    const [modalWithdraw, setModalWithdraw] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });

    const [loading, setLoading] = useState(true);
    const [DateValue, setDateValue] = useState(true);

    const { 
        per_page,
        // current_page, 
        total, 
        // last_page, 
        data,
        // prev_page_url,
        // next_page_url,
    } = rawData;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: {
            pageSize,
            // canNextPage,
            // canPreviousPage,
            totalPage,
            // currentPage,
            // firstPage,
            // lastPage,
        },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: per_page,
                // pageSize: meta?.per_page,
                // canNextPage: next_page_url !== null ? true : false,
                // canPreviousPage: prev_page_url !== null ? true : false,
                totalPage: total,
                // currentPage: current_page,
                // firstPage: 1,
                // lastPage: last_page,
            },
        },
        useSortBy,
        usePagination
    );
    const gotoPage = (page) => {
        enrolmentApi({
            page,
        });
    };

    const deleteCompany = async (id) => {
        try {
            await api.delete(`tms/enrolments/${id}`, {});
            setNotif({
                id: id,
                notifMsg: "Deleted successfully",
                open: true,
                severity: "success",
            });
            enrolmentApi();
        } catch (error) {
            setNotif({
                id: id,
                notifMsg: error.message,
                open: true,
                severity: "danger",
            });
        }
    };
    const withdrawEnrolment = async (id, data) => {
        try {
            await api.post(`tms/enrolments/withdrawal/${id}`, data);
            setNotif({
                id: id,
                notifMsg: "Withdrawal successfully",
                open: true,
                severity: "success",
            });
            setRerender(!rerender);
        } catch (error) {
            setNotif({
                id: id,
                notifMsg: error.message,
                open: true,
                severity: "danger",
            });
        }
    };

    const openDeleteModal = (id) => {
        setModalInfo({
            id: id,
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            severity: "danger",
        });
    };
    const openWithdrawModal = (id) => {
        setModalWithdraw({
            id: id,
            notifMsg: "",
            open: true,
            severity: "danger",
        });
    };
    const [error, setError] = useState();
    const generateEnrolmentReports = async () => {
        try {
            const response = await api.getexcel(
                "tms/export-excel-enrolment",
                DateValue
            );
            if (response.type.includes('application/json')) {
                const jsonData = JSON.parse(await response.text());
                // console.log(jsonData);
                if (jsonData.success){
                    setNotif({
                        notifMsg: jsonData.message,
                        open: true,
                        severity: "success",
                    });
                }
                else{
                    setNotif({
                        notifMsg: jsonData.message,
                        open: true,
                        severity: "danger",
                    });
                }
            }else if (response.size !== 0) {
                download(response, "Enrolment_summary", "application/xlsx");
            } else {
                setError("No data to be exported.");
            }
        } catch (error) {
            setError(error.message);
        }
        setLoading(false);
    };

    const DateFormat = UserDateFormat();

    const Styles = styled.div`
        .table {
            .th,
            .td {
                background-color: #fff;
                overflow: hidden;
                border-top: none;
            }

            &.sticky {
                overflow: scroll;
                .header,
                .footer {
                    position: sticky;
                    z-index: 1;
                    width: fit-content;
                }
            }
        }
        .table > :not(:first-child) {
            border-top: none;
        }
    `;

    return (
        <>
            {data.length > 0 && (
                <>
                    {totalPage > 1 && (
                        <Row>
                            {error && (
                                <Card.Header>
                                    <Alert className="my-3" variant="danger">
                                        <div className="alert-message">
                                            {error}
                                        </div>
                                    </Alert>
                                </Card.Header>
                            )}
                            {/* <Col md="6">
                                <span className="mx-2">
                                    Page {""}
                                    <strong>
                                        {currentPage} of {totalPage}
                                    </strong>
                                </span>
                            </Col> */}
                            <Col md={5}>
                                {status === "summary" &&
                                    permission?.includes(
                                        "ENROLMENT-REPORT-SUMMARY"
                                    ) && (
                                        <Row>
                                            <Col md={5}>
                                                <Form.Group className="mb-3">
                                                    <DatePicker
                                                        name="from_date"
                                                        selected={
                                                            new Date(
                                                                moment(
                                                                    DateFormat?.toUpperCase()
                                                                ).format(
                                                                    "YYYY-MM-DD"
                                                                )
                                                            )
                                                        }
                                                        className={`form-control`}
                                                        onChange={(dates) => {
                                                            setDateValue({
                                                                ...DateValue,
                                                                from_date:
                                                                    dates ===
                                                                    null
                                                                        ? null
                                                                        : moment(
                                                                              dates
                                                                          ).format(
                                                                              "YYYY-MM-DD"
                                                                          ),
                                                            });
                                                        }}
                                                        dateFormat={DateFormat}
                                                        placeholder="From date"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            -
                                            <Col md={5}>
                                                <Form.Group className="mb-3">
                                                    <DatePicker
                                                        name="to_date"
                                                        selected={
                                                            new Date(
                                                                moment(
                                                                    DateFormat?.toUpperCase()
                                                                ).format(
                                                                    "YYYY-MM-DD"
                                                                )
                                                            )
                                                        }
                                                        className={`form-control`}
                                                        onChange={(dates) => {
                                                            setDateValue({
                                                                ...DateValue,
                                                                to_date:
                                                                    dates ===
                                                                    null
                                                                        ? null
                                                                        : moment(
                                                                              dates
                                                                          ).format(
                                                                              "YYYY-MM-DD"
                                                                          ),
                                                            });
                                                        }}
                                                        dateFormat={DateFormat}
                                                        placeholder="To date"
                                                        maxDate={moment().toDate()}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={1}>
                                                <Button
                                                    className="float-end"
                                                    variant="info"
                                                    type="submit"
                                                    size="lg"
                                                    onClick={() =>
                                                        generateEnrolmentReports()
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faFileExcel}
                                                    />
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                            </Col>
                            {/* <Col md="1">
                                <Pagination className="float-end">
                                    <Pagination.First
                                        onClick={() => gotoPage(firstPage)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPage(currentPage - 1)
                                        }
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPage(currentPage + 1)
                                        }
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(lastPage)}
                                        disabled={!canNextPage}
                                    />
                                </Pagination>
                            </Col> */}
                        </Row>
                    )}

                    <Styles>
                    <Table responsive striped bordered {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps()
                                            )}
                                        >
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell, index, row) => {
                                            if (index === 0) {
                                                return (
                                                    <td key={index}>
                                                        {/* {currentPage > 1
                                                            ? (currentPage -
                                                                  1) *
                                                                  pageSize +
                                                              i +
                                                              1
                                                            : i + 1} */}
                                                            { i + 1 }
                                                    </td>
                                                );
                                            }
                                           
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    </Styles>

                    {/* {totalPage > 1 && (
                        <Row className="mt-2">
                            <Col md="6">
                                <span className="mx-2">
                                    Page {""}
                                    <strong>
                                        {currentPage} of {totalPage}
                                    </strong>
                                </span>
                            </Col>
                            <Col md="6">
                                <Pagination className="float-end">
                                    <Pagination.First
                                        onClick={() => gotoPage(firstPage)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPage(currentPage - 1)
                                        }
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPage(currentPage + 1)
                                        }
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(lastPage)}
                                        disabled={!canNextPage}
                                    />
                                </Pagination>
                            </Col>
                        </Row>
                    )} */}
                </>
            )}
            {data.length === 0 && (
                <Alert variant="warning">
                    <div className="alert-message">No data</div>
                </Alert>
            )}
            <DeleteModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={deleteCompany}
            />
            <WithdrawModal
                modalInfo={modalWithdraw}
                setModalInfo={setModalWithdraw}
                api={withdrawEnrolment}
            />
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export { Lists };
