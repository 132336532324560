import { Badge } from "react-bootstrap";
import { formatNumberWithCommas } from "../../../../utils/utility";

const tableColumns = [
    {
        Header: "S.no",
        width: "70",
        sticky: "left",
    },
    {
        Header: "Sponsor company",
        accessor: (data) => {
            return <div>{data?.company_name || "-"}</div>;
        },
        // accessor: "name",
        width: "500",
        sticky: "left",
    },
    {
        Header: "Sub total",
        accessor: (data) => {
            return <div>{data?.total_amount ? formatNumberWithCommas(data?.total_amount) : 0.00}</div>;
        },
        // accessor: "total",
        width: "500",
        sticky: "left",
    },
    
];

export { tableColumns };
