import { formatNumberWithCommas } from "../../../../utils/utility";
import { minSelectableYear } from "../../../../utils/EnrolmentSummaryConstants";

const currentYear = new Date().getFullYear();
// const minSelectableYear = 2017;

// This is to dynamically generate the sub columns based on the current year
const generateYearColumns = (year) => {
    return [
        {
            id: `beforeGST${year}`,
            Header: `Before GST`,
            width: "150",
            sticky: "left",
            subgroup: `${year}`,
            accessor: (data) => <div>{"S$ "+formatNumberWithCommas(data[`before_gst_${year}`] ?? 0)}</div>,
        },
        {
            id: `bcaFee${year}`,
            Header: `BCA fee`,
            width: "150",
            sticky: "left",
            subgroup: `${year}`,
            accessor: (data) => <div>{"S$ "+formatNumberWithCommas(data[`bca_fees_sum_${year}`] ?? 0)}</div>,
        },
        {
            id: `material${year}`,
            Header: `Material`,
            width: "150",
            sticky: "left",
            subgroup: `${year}`,
            accessor: (data) => <div>{"S$ "+formatNumberWithCommas(data[`material_${year}`] ?? 0)}</div>,
        },
    ];
};

const tableColumns = [
    {
        id: "enrolmentStatusCol",
        Header: "Enrolment Status",
        width: "250",
        sticky: "left",
        rowSpan: 2,
        colSpan: 5,
        accessor: 'label',
    },
    ...Array.from({ length: currentYear - minSelectableYear + 1 }, (_, index) => {
        const year = 2017 + index;
        return {
            id: `${year}`,
            Header: `${year}`,
            width: "500",
            sticky: "left",
            subgroup: `${year}`,
            columns: generateYearColumns(year),
        };
    }),
];

export { tableColumns };