import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FileText } from "react-feather";

export const FileOverlay = ({ index, fileName, fullPath }) => {
    return (
        <OverlayTrigger
            key={index}
            placement="top"
            overlay={<Tooltip id={"tooltip-top"}>{fileName}</Tooltip>}
        >
            <FileText
                key={index}
                onClick={() => window.open(fullPath, "_blank")}
                variant="link"
                className="px-0"
                color="#E5A54B"
                style={{ cursor: "pointer" }}
            />
        </OverlayTrigger>
    );
};
