import React from "react";
import { Form, Col, FloatingLabel, Row } from "react-bootstrap";

export const AddEditRevenueDetails = ({
    values,
    errors,
    touched,
    handleBlur,
    handleFormChangeDetails,
    project,
}) => {
    return (
        <>
            {project && (
                <>
                    <Row className="mb-3">
                        <Col md={3}>
                            <Form.Group className="">
                                <Form.Group>
                                    <Form.Label>Revenue date</Form.Label> <br />
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            type="date"
                                            name="revenue_date"
                                            id="revenue_date"
                                            value={values.revenue_date}
                                            isInvalid={Boolean(
                                                errors &&
                                                    touched &&
                                                    errors.revenue_date &&
                                                    touched.revenue_date
                                            )}
                                            onBlur={handleBlur}
                                            onChange={handleFormChangeDetails}
                                        />
										{console.log(errors)}
                                        {errors && errors?.revenue_date && (
                                            <Form.Control.Feedback type="invalid">
                                                Revenue date is a required field
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Form.Group>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={4}>
                            <Form.Group className="mb-1">
                                <Form.Label>Is recurring</Form.Label> <br />
                                <Form.Check
                                    checked={values.is_recurring === "Yes"}
                                    className="mb-2"
                                    id="is_recurring"
                                    inline
                                    label="Yes"
                                    name="is_recurring"
                                    onChange={handleFormChangeDetails}
                                    type="radio"
                                    value="Yes"
                                />
                                <Form.Check
                                    checked={values.is_recurring === "No"}
                                    className="mb-2"
                                    id="is_recurring"
                                    inline
                                    label="No"
                                    name="is_recurring"
                                    onChange={handleFormChangeDetails}
                                    type="radio"
                                    value="No"
                                />
                            </Form.Group>
                        </Col>
                        {values.is_recurring === "Yes" && (
                            <Row>
                                <Col md={4}>
                                    <Form.Group className="mb-1">
                                        <Form.Label>Frequency</Form.Label>
                                        <Form.Select
                                            name="interval"
                                            value={values.interval || ""}
                                            isInvalid={Boolean(
                                                errors &&
                                                    touched &&
                                                    errors.interval &&
                                                    touched.interval
                                            )}
                                            onBlur={handleBlur}
                                            onChange={handleFormChangeDetails}
                                        >
                                            <option />
                                            <option value="monthly">
                                                Monthly
                                            </option>
                                            <option value="yearly">
                                                Yearly
                                            </option>
                                        </Form.Select>
                                        {errors && errors.interval && (
                                            <Form.Control.Feedback type="invalid">
                                                Interval is a required field
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <Form.Group>
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Start date"
                                                    className="mb-3"
                                                >
                                                    <Form.Control
                                                        type="date"
                                                        name="start_date"
                                                        value={
                                                            values.start_date ||
                                                            ""
                                                        }
                                                        isInvalid={Boolean(
                                                            errors &&
                                                                touched &&
                                                                errors.start_date &&
                                                                touched.start_date
                                                        )}
                                                        onBlur={handleBlur}
                                                        onChange={
                                                            handleFormChangeDetails
                                                        }
                                                    />
                                                    {errors &&
                                                        errors?.start_date && (
                                                            <Form.Control.Feedback type="invalid">
                                                                Start date is a
                                                                required field
                                                            </Form.Control.Feedback>
                                                        )}
                                                </FloatingLabel>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="End date"
                                                    className="mb-3"
                                                >
                                                    <Form.Control
                                                        type="date"
                                                        name="end_date"
                                                        value={
                                                            values.end_date ||
                                                            ""
                                                        }
                                                        isInvalid={Boolean(
                                                            errors &&
                                                                touched &&
                                                                errors.end_date &&
                                                                touched.end_date
                                                        )}
                                                        onBlur={handleBlur}
                                                        onChange={
                                                            handleFormChangeDetails
                                                        }
                                                    />
                                                    {errors &&
                                                        errors?.end_date && (
                                                            <Form.Control.Feedback type="invalid">
                                                                End date is a
                                                                required field
                                                            </Form.Control.Feedback>
                                                        )}
                                                </FloatingLabel>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </Row>
                </>
            )}
        </>
    );
};
