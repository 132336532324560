import React, { useState, useEffect } from "react";

import { useTable, usePagination, useSortBy } from "react-table";
import { useParams } from "react-router-dom";
import {
    Table,
    Row,
    Col,
    Alert,
    Pagination,
    Button,
    Form,
    Card,
} from "react-bootstrap";
import { faStickyNote } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker } from "antd";
import moment from "moment";
import download from "downloadjs";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { DeleteModal } from "../../../../components/DeleteModal";
import { SnackbarAlert } from "../../../../components/SnackbarAlert";
import { WithdrawModal } from "../../../../components/WithdrawModal";
import api from "../../../../service/api";
import { UserDateFormat } from "../../../../utils/dateFormat";

const Lists = ({
    columns,
    rawData,
    enrolmentApi,
    status,
    searchValue,
    setRerender,
    rerender,
    outsideYearRange,
}) => {
    /** Permissions */
    const permissionData = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = permissionData?.data?.permissions;
    const params = useParams();

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });

    const [modalWithdraw, setModalWithdraw] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });

    const [loading, setLoading] = useState(true);
    const [DateValue, setDateValue] = useState(true);
    const { meta, links, data} = rawData;
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        allColumns,
        page,
        state: {
            pageSize,
            canNextPage,
            canPreviousPage,
            totalPage,
            currentPage,
            firstPage,
            lastPage,
        },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: 100,
                // pageSize: meta?.per_page,
                // canNextPage: links?.next !== null ? true : false,
                // canPreviousPage: links?.prev !== null ? true : false,
                // totalPage: meta?.last_page,
                // currentPage: meta?.current_page,
                // firstPage: meta?.first,
                // lastPage: meta?.last_page,
            },
        },
        useSortBy,
        usePagination
    );
    const gotoPage = (page) => {
        enrolmentApi({
            page,
        });
    };


    const [error, setError] = useState();

    const DateFormat = UserDateFormat();

    const Styles = styled.div`
        .table {
            .th,
            .td {
                background-color: #fff;
                overflow: hidden;
                border-top: none;
            }

            &.sticky {
                overflow: scroll;
                .header,
                .footer {
                    position: sticky;
                    z-index: 1;
                    width: fit-content;
                }
            }
        }
        .table > :not(:first-child) {
            border-top: none;
        }
    `;

    useEffect(()=>{
        // console.log("hidden list component GROUPS: ", outsideYearRange);
        allColumns.map((column) => {
            outsideYearRange.includes(column.subgroup) ? setColumnVisibility(column.id, true) : setColumnVisibility(column.id, false)
        });
    }, [outsideYearRange]);

    const setColumnVisibility = (columnId, visibility) => {
        allColumns.forEach((column) => {
          if (column.id === columnId) {
            column.toggleHidden(visibility);
          }
        });
    };

    return (
        <>
            {data.length > 0 && (
                <>
                    {totalPage > 1 && (
                        <Row>
                            {error && (
                                <Card.Header>
                                    <Alert className="my-3" variant="danger">
                                        <div className="alert-message">
                                            {error}
                                        </div>
                                    </Alert>
                                </Card.Header>
                            )}
                            <Col md="6">
                                <span className="mx-2">
                                    Page {""}
                                    <strong>
                                        {currentPage} of {totalPage}
                                    </strong>
                                </span>
                            </Col>
                            <Col md="1">
                                <Pagination className="float-end">
                                    <Pagination.First
                                        onClick={() => gotoPage(firstPage)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPage(currentPage - 1)
                                        }
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPage(currentPage + 1)
                                        }
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(lastPage)}
                                        disabled={!canNextPage}
                                    />
                                </Pagination>
                            </Col>
                        </Row>
                    )}

                    <Styles>
                    <Table responsive striped bordered {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps()}
                                    className="text-nowrap"
                                >
                                    {column.render('Header')}
                                    
                                </th>
                                ))}
                            </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                const isGrandTotal = row.original.status.toUpperCase() === 'GRAND-TOTAL';
                                return (
                                    <tr {...row.getRowProps()} key={i}>
                                        {row.cells.map((cell, index) => (
                                        <td {...cell.getCellProps()} className={`text-nowrap ${isGrandTotal ? 'font-weight-bold' : ''}`} key={index}>
                                            {isGrandTotal && <b>{cell.render("Cell")}</b>}
                                            {!isGrandTotal && cell.render("Cell")}
                                        </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    </Styles>

                    {totalPage > 1 && (
                        <Row className="mt-2">
                            <Col md="6">
                                <span className="mx-2">
                                    Page {""}
                                    <strong>
                                        {currentPage} of {totalPage}
                                    </strong>
                                </span>
                            </Col>
                            <Col md="6">
                                <Pagination className="float-end">
                                    <Pagination.First
                                        onClick={() => gotoPage(firstPage)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPage(currentPage - 1)
                                        }
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPage(currentPage + 1)
                                        }
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(lastPage)}
                                        disabled={!canNextPage}
                                    />
                                </Pagination>
                            </Col>
                        </Row>
                    )}
                </>
            )}
            {data.length === 0 && (
                <Alert variant="warning">
                    <div className="alert-message">No data</div>
                </Alert>
            )}
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export { Lists };
