import React from "react";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";

// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";

import IntelliSuiteRoutes from "./modules/IntelliSuite/routes";
import IntelliDocsRoutes from "./modules/IntelliDocs/routes";
import TmsRoutes from "./modules/Tms/routes";
import IntelliBuildRoutes from "./modules/IntelliBuild/routes";

const routes = [
    // Grouped routes for IntelliSuite
    IntelliSuiteRoutes,

    // Grouped routes for IntelliDocs
    IntelliDocsRoutes,

    // Grouped routes for Tms
    TmsRoutes,

    // Grouped routes fro Intellibuild
    IntelliBuildRoutes,
    {
        path: "auth",
        element: <AuthLayout />,
        children: [
            {
                path: "sign-in",
                element: <SignIn />,
            },
            {
                path: "sign-up",
                element: <SignUp />,
            },
            {
                path: "404",
                element: <Page404 />,
            },
            {
                path: "500",
                element: <Page500 />,
            },
        ],
    },
    {
        path: "sso",
        element: <AuthLayout />,
        children: [
            {
                path: "authorize",
                element: <SignIn />,
            },
        ],
    },
    {
        path: "*",
        element: <AuthLayout />,
        children: [
            {
                path: "*",
                element: <Page404 />,
            },
        ],
    },
];

export default routes;
