const tableColumns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Person incharge",
    accessor: "person_in_charge",
  },
  {
    Header: "Details",
    accessor: "details",
  },
  {
    Header: "Projects",
    accessor: "projects",
  },
  {
    Header: "Actions",
    accessor: "actions"
  }
];

export { tableColumns };
