import { tradeCategoryDisplay } from "../../../../utils/utility";

const tableColumns = [
    {
        Header: "S.no",
    },

    {
        Header: "Invoice number",
        accessor: "invoice.number",
    },
    {
        Header: "Existing student",
        id: "existing_student",
        accessor: (data) => {
            return (
                <>
                    {data?.students?.existing?.name} <br />
                    {data?.students?.existing?.fin} <br />
                </>
            );
        },
    },

    {
        Header: "New student",
        id: "new_student",
        accessor: (data) => {
            return (
                <>
                    {data?.students?.new?.name} <br />
                    {data?.students?.new?.fin} <br />
                </>
            );
        },
    },

    {
        Header: "Old values",
        id: "old_values",
        accessor: (data) => tradeCategoryDisplay(data.old_values),
    },

    {
        Header: "New values",
        id: "new_values",
        accessor: (data) => tradeCategoryDisplay(data.new_values),
    },

    {
        Header: "Remarks",
        accessor: "remarks",
    },
    {
        Header: "Created by",
        accessor: "user.name",
    },

    {
        Header: "Date",
        accessor: (data) => {
            return new Date(data.created_at).toLocaleDateString("en-GB").split('/').join('-'); 
        }
    },
];

export { tableColumns };
