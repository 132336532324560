import React, { useEffect, useState } from "react";
import { Alert, Card, Container, Form } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../../../service/api";
import PaymentFormRow from "./PaymentFormRow";
import { useSelector } from "react-redux";
import { SnackbarAlert } from "../../../../components/SnackbarAlert";

const RoadshowPayment = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [courseFee, setCourseFee] = useState();
    const { data } = useSelector((state) => (state.user ? state.user : state));
    const permission = data?.permissions;
    const [roadshowData, setRoadshowData] = useState([]);
    const location = useLocation();
    const state = location.state;

    const getRoadshowData = async() => {
        const roadshowData = await api.get(`tms/roadshow/${id}`, {});
        if (roadshowData.data) {
            setRoadshowData(roadshowData.data);
        } else {
            setRoadshowData([]);
        }
    }

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const [configValues, setConfigValues] = useState({
        paymentMode: [],
        paymentOptions: [],
    });

    /** use effect */
    useEffect(() => {
        const getConfig = async () => {
            try {
                setLoading(true);
                const configResponse = await api.get(
                    `tms/roadshow/payment/config`,
                    {}
                );
                setConfigValues({ ...configValues, ...configResponse.data });
                setLoading(false);
            } catch (error) {
                setError(error.message);
            }
        };
        getConfig();
    }, [id]);

    useEffect(() => {
        getRoadshowData();
    },[loading]);

    useEffect(() => {
        if (state?.open) {
            setNotif({
                notifMsg: state.notifMsg,
                open: state.open,
                severity: state.severity,
            });
        }
        window.history.replaceState(null, "");
    }, [state]); 

    const helmetTitle = `Payment`;
    return (
        <React.Fragment>
            <Helmet title={"Roadshow > " + helmetTitle} />
            {!permission.includes('ROADSHOW-CREATE-PAYMENT') && (
                <Alert className="my-3" variant="warning">
                    <div className="alert-message">
                        You don't have permission to access this page
                    </div>
                </Alert>
            )}
            {permission.includes('ROADSHOW-CREATE-PAYMENT') && (
                <Container fluid className="p-0">
                    <h1 className="h3 mb-3">
                        {helmetTitle} for record number {roadshowData ? roadshowData.number + " - " + roadshowData.customer_name + " (" + roadshowData.customer_fin + ")" : ""}
                    </h1>
                    <Card className="m-0 p-0">
                        <Card.Body>
                            {error && (
                                <Alert className="my-3" variant="danger">
                                    <div className="alert-message">{error}</div>
                                </Alert>
                            )}
                            {loading && <div>Loading...</div>}
                            {!loading && (
                                <PaymentFormRow
                                    configValues={configValues}
                                    roadshowData={roadshowData}
                                    loading={loading}
                                    setLoading={setLoading}
                                    notif={notif}
                                    setNotif={setNotif}
                                />
                            )}
                        </Card.Body>
                    </Card>
                </Container>
            )}
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </React.Fragment>
    );
};

export default RoadshowPayment;