import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../service/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { Formik } from "formik";
import * as Yup from "yup";
import { FileUpload } from "../../../components/FileUpload";
import { FileOverlay } from "../../../components/FileOverlay";
import { convertToDecimalWithComma } from "../../../utils/utility";
import { LoadingContext } from "../../../App";
import { SnackbarAlert } from "../../../components/SnackbarAlert";

export const ViewExpense = () => {
    const navigate = useNavigate();
    let { id, action } = useParams();

    const schema = Yup.object().shape({
        status: Yup.string().required(),
    });

    //
    // States
    //

    const setIsLoadingActive = useContext(LoadingContext);
    const [inputFields, setInputFields] = useState({
        status: "",
        remarks: "",
        attachments: [],
    });
    const [expenses, setExpenses] = useState();
    const [expenseTypes, setExpenseTypes] = useState();
    // eslint-disable-next-line no-unused-vars
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [files, setFiles] = useState([]);
    const [deleteFiles, setDeleteFiles] = useState([]);
    const [deleteFilesPath, setDeleteFilePath] = useState([]);

    //
    // Functions
    //

    const submitEditExpense = (values) => {
        try {
            const formData = new FormData();

            formData.append("status", values.status);
            formData.append("remarks", values.remarks || "");

            for (let key in deleteFiles) {
                formData.append(`delete_files[${key}]`, deleteFiles[key]);
            }

            for (let key in deleteFilesPath) {
                formData.append(
                    `delete_files_path[${key}]`,
                    deleteFilesPath[key]
                );
            }

            files.forEach((item) => {
                formData.append("attachments[]", item.file);
            });

            return api.post(`update-expenses/${action}`, formData);
        } catch {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    const deleteUploadedFiles = (fileId, data, fullPath) => {
        const newData = data.filter((item) => {
            return item.id !== fileId;
        });

        expenses.attachments = newData;

        setDeleteFiles([...deleteFiles, fileId]);
        setDeleteFilePath([...deleteFilesPath, fullPath]);
        setExpenses({
            ...expenses,
        });
    };

    //
    // useEffects
    //

    useEffect(() => {
        if (action !== undefined && action !== "create") {
            setIsLoadingActive(true);
            const getExpense = async () => {
                try {
                    const response = await api.get(`expenses/${action}`, {});
                    setExpenses(response.data);
                    setIsLoadingActive(false);
                    setInputFields({
                        status: response.data.status,
                        remarks: response.data.remarks,
                    });
                } catch (error) {
                    setIsLoadingActive(false);
                    setNotif({
                        notifMsg: "Something went wrong with the server",
                        open: true,
                        severity: "danger",
                    });
                }
            };
            getExpense();
        }
    }, [action, setIsLoadingActive]);

    useEffect(() => {
        if (action) {
            const getExpenseType = async () => {
                try {
                    const response = await api.get("expense-type/config", {});
                    setExpenseTypes(response.data);
                } catch {
                    setNotif({
                        notifMsg: "Something went wrong with the server",
                        open: true,
                        severity: "danger",
                    });
                }
            };

            getExpenseType();
        }
    }, [action]);

    return (
        <>
            <Card className="shadow-none mb-0">
                <Card.Header className="shadow-none">
                    <Card.Title tag="h5">
                        <Row className="mb-3">
                            <Col md={6}>View expense</Col>
                            {expenses && expenses.revenue && (
                                <Col md={6} className="text-end">
                                    <Button
                                        variant="primary"
                                        onClick={() =>
                                            navigate(
                                                `/intellidocs/process/revenues/${id}/${expenses.revenue_id}/view`
                                            )
                                        }
                                    >
                                        {expenses.revenue.code}
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Card.Title>
                </Card.Header>
                <Card.Body className="py-0">
                    <h6>Expense items</h6>
                    <div className="bg-light p-4 rounded mb-3">
                        <Row className="mb-2">
                            <Col md={1}>S/N</Col>
                            <Col md={3}>Expense type</Col>
                            <Col md={3}>Description</Col>
                            <Col md={3}>Attachments</Col>
                            <Col md={2}>Amount (S$)</Col>
                        </Row>
                        <hr style={{ border: "1px solid" }} />
                        {expenses &&
                            expenses.expense_items.length &&
                            expenses.expense_items.map((input, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Row className="mb-3">
                                            <Col md={1}>{index + 1}</Col>
                                            <Col md={3}>
                                                {expenseTypes &&
                                                    expenseTypes.filter(
                                                        function (account) {
                                                            return (
                                                                account.value ===
                                                                input.expense_type
                                                            );
                                                        }
                                                    )[0].label}
                                            </Col>
                                            <Col md={3}>
                                                {input.description}
                                            </Col>
                                            <Col md={3}>
                                                {input.attachments.map(
                                                    (element, index) => {
                                                        return (
                                                            <FileOverlay
                                                                key={index}
                                                                index={index}
                                                                fileName={
                                                                    element.file_name
                                                                }
                                                                fullPath={
                                                                    element.full_path
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                            </Col>
                                            <Col md={2}>
                                                {convertToDecimalWithComma(
                                                    input.amount
                                                )}
                                            </Col>
                                            <Col md={1}></Col>
                                        </Row>
                                    </React.Fragment>
                                );
                            })}
                        {expenses && (
                            <Row className="mt-3">
                                <Col md={4}></Col>
                                <Col md={4}></Col>
                                <Col md={4}>
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <h4>Total (SGD)</h4>
                                        </Col>
                                        <Col md={6}>
                                            <h4 className="text-end">
                                                S$
                                                {convertToDecimalWithComma(
                                                    expenses.total
                                                )}
                                            </h4>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </div>
                    {expenses && (
                        <>
                            <h6>Expense details</h6>
                            <div className="bg-light p-4 rounded mb-3">
                                <Row>
                                    <Col md={6}>
                                        <Row className="mb-3">
                                            <Col
                                                md={5}
                                                className="font-weight-bold"
                                            >
                                                Expense code
                                            </Col>
                                            <Col md={5}>{expenses?.code}</Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                md={5}
                                                className="font-weight-bold"
                                            >
                                                User:
                                            </Col>
                                            <Col md={5}>{expenses.user}</Col>
                                        </Row>
                                        {expenses && expenses.revenue && (
                                            <>
                                                <Row className="mt-3">
                                                    <Col
                                                        md={5}
                                                        className="font-weight-bold"
                                                    >
                                                        Revenue link:
                                                    </Col>
                                                    <Col md={5}>
                                                        {expenses.revenue.code}
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </Col>
                                    <Col md={6}>
                                        <Row>
                                            <Col
                                                md={5}
                                                className="font-weight-bold"
                                            >
                                                Is recurring:
                                            </Col>
                                            <Col md={5}>
                                                {expenses.is_recurring}
                                            </Col>
                                        </Row>
                                        {expenses.is_recurring === "Yes" && (
                                            <>
                                                <Row className="mt-3">
                                                    <Col
                                                        md={5}
                                                        className="font-weight-bold"
                                                    >
                                                        Interval:
                                                    </Col>
                                                    <Col md={5}>
                                                        {expenses.interval}
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col
                                                        md={5}
                                                        className="font-weight-bold"
                                                    >
                                                        Start date:
                                                    </Col>
                                                    <Col md={5}>
                                                        {expenses.start_date}
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col
                                                        md={5}
                                                        className="font-weight-bold"
                                                    >
                                                        End date:
                                                    </Col>
                                                    <Col md={5}>
                                                        {expenses.end_date}
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                            <Formik
                                enableReinitialize
                                initialValues={inputFields}
                                validationSchema={schema}
                                onSubmit={async (
                                    values,
                                    { setErrors, setStatus, setSubmitting }
                                ) => {
                                    try {
                                        await submitEditExpense(values);

                                        navigate(
                                            `/intellidocs/process/expenses/${id}`,
                                            {
                                                state: {
                                                    open: true,
                                                    notifMsg: `Successfully updated expense status`,
                                                    severity: "success",
                                                },
                                            }
                                        );
                                    } catch (error) {
                                        const message =
                                            error.message ||
                                            "Something went wrong";

                                        setStatus({ success: false });
                                        setErrors({ submit: message });
                                        setSubmitting(false);
                                        setNotif({
                                            notifMsg:
                                                "Something went wrong with the server",
                                            open: true,
                                            severity: "danger",
                                        });
                                    }
                                }}
                            >
                                {({
                                    errors,
                                    handleChange,
                                    handleSubmit,
                                    touched,
                                    values,
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        {errors.submit && (
                                            <Alert
                                                className="my-3"
                                                variant="danger"
                                            >
                                                <div className="alert-message">
                                                    {errors.submit}
                                                </div>
                                            </Alert>
                                        )}
                                        <h6>Expense status</h6>
                                        <div className="bg-light p-4 rounded">
                                            <Row className="mt-3">
                                                <Col
                                                    md={2}
                                                    className="font-weight-bold"
                                                >
                                                    Status:
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Select
                                                            name="status"
                                                            id="status"
                                                            value={
                                                                values.status
                                                            }
                                                            isInvalid={Boolean(
                                                                errors.status &&
                                                                    touched.status
                                                            )}
                                                            onChange={
                                                                handleChange
                                                            }
                                                        >
                                                            <option value="Pending for approval">
                                                                Pending for
                                                                approval
                                                            </option>
                                                            <option value="Approved by manager">
                                                                Approved by
                                                                manager
                                                            </option>
                                                            <option value="Pending from account">
                                                                Pending from
                                                                account
                                                            </option>
                                                            <option value="Completed">
                                                                Completed
                                                            </option>
                                                        </Form.Select>
                                                        {errors.status && (
                                                            <Form.Control.Feedback type="invalid">
                                                                Expense type is
                                                                a required field
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col
                                                    md={2}
                                                    className="font-weight-bold"
                                                >
                                                    Remarks:
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Control
                                                            name="remarks"
                                                            as="textarea"
                                                            type="text"
                                                            className="mb-3"
                                                            value={
                                                                values.remarks ||
                                                                ""
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col
                                                    md={2}
                                                    className="font-weight-bold"
                                                >
                                                    Attachments: (Optional)
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="mb-3">
                                                        <FileUpload
                                                            files={files}
                                                            setFiles={setFiles}
                                                        />
                                                        {expenses &&
                                                            expenses.attachments.map(
                                                                (
                                                                    element,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <React.Fragment
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {index ===
                                                                                0 && (
                                                                                <>
                                                                                    Uploaded:{" "}
                                                                                    <br />
                                                                                </>
                                                                            )}
                                                                            <Button
                                                                                href={
                                                                                    element.full_path
                                                                                }
                                                                                target="_blank"
                                                                                variant="link"
                                                                                className="m-0 px-0"
                                                                            >
                                                                                {
                                                                                    element.file_name
                                                                                }
                                                                            </Button>
                                                                            <Button
                                                                                variant="primary"
                                                                                onClick={() =>
                                                                                    deleteUploadedFiles(
                                                                                        element.id,
                                                                                        expenses.attachments,
                                                                                        element.full_path
                                                                                    )
                                                                                }
                                                                                className="ms-2"
                                                                                size="sm"
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    icon={
                                                                                        faWindowClose
                                                                                    }
                                                                                />
                                                                            </Button>
                                                                            <br />
                                                                        </React.Fragment>
                                                                    );
                                                                }
                                                            )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Card.Footer className="text-center pb-0">
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                className="me-2"
                                                size="lg"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPaperPlane}
                                                />{" "}
                                                Save
                                            </Button>
                                            <Button
                                                variant="link"
                                                onClick={() =>
                                                    navigate(
                                                        `/intellidocs/process/expenses/${id}`
                                                    )
                                                }
                                            >
                                                Cancel
                                            </Button>
                                        </Card.Footer>
                                    </Form>
                                )}
                            </Formik>
                        </>
                    )}
                </Card.Body>
            </Card>

            {/* Supporting components */}

            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};
