const tableColumns = [
    {
        Header: "S.no",
    },
    {
        Header: "Name",
        accessor: "enrolment.name",
    },
    {
        Header: "FIN",
        accessor: "enrolment.fin",
    },
    {
        Header: "Type of trade",
        accessor: "enrolment.type_of_trade",
    },
    {
        Header: "Result",
        accessor: "enrolment.result",
    },
    {
        Header: "Result date",
        accessor: "enrolment.result_date",
    },
];

export { tableColumns };
