import React, { useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";

import { Button, Table, Row, Col, Alert, Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";

import { Edit, Trash } from "react-feather";
import api from "../../../../../service/api";
import { SnackbarAlert } from "../../../../../components/SnackbarAlert";
import { DeleteModal } from "../../../../../components/DeleteModal";
import { useSelector } from "react-redux";

const CourseStudents = ({
    columns,
    rawData,
    getInvoiceCourseStudentApi,
    invoiceCourseId,
    getInvoiceApi,
    invoiceId,
    changeStudentApi,
    setAddStudent,
    setStudentFin,
    setNotif,
}) => {
    /** modal popup */
    // const [show, setShow] = useState(false);
    // const [modalLoading, setModalLoading] = useState(false);

    /**cancelled student */
    // const [reasons, setReasons] = useState();
    // const [modalData, setModalData] = useState({
    //     reasons: "",
    //     refund: false,
    // });
    //const [courseStudentId, setCourseStudentId] = useState();
    // const [popupType, setPopupType] = useState("");

    // const [notif, setNotif] = useState({
    //     notifMsg: "",
    //     open: false,
    //     severity: "success",
    // });
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });

    /** Permissions */
    const permissionData = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = permissionData?.data?.permissions;

    /** Cancel popup */
    // const setModalPopUp = (status, courseStudentId = "") => {
    //     setShow(status);
    //     setCourseStudentId(courseStudentId);
    // };

    /** cancel student api */
    // const cancelStudent = async () => {
    //     try {
    //         await api.put(
    //             `tms/invoice-courses/${invoiceCourseId}/invoice-course-students/${courseStudentId}`,
    //             modalData
    //         );
    //         await getInvoiceCourseStudentApi(invoiceCourseId);
    //         setModalPopUp(false, "");
    //         setPopupType("");
    //         setNotif({
    //             notifMsg: "successfully cancelled",
    //             open: true,
    //             severity: "success",
    //         });
    //     } catch (error) {
    //         setNotif({
    //             notifMsg: error?.response?.data?.message,
    //             open: true,
    //             severity: "danger",
    //         });
    //     }
    // };

    /** cancel student api */
    // const createCreditNote = async () => {
    //     try {
    //         await api.post(
    //             `tms/create-credit-notes/${courseStudentId}`,
    //             modalData
    //         );
    //         setModalPopUp(false, "");
    //         setPopupType("");
    //         setNotif({
    //             notifMsg: "successfully added credit notes",
    //             open: true,
    //             severity: "success",
    //         });
    //     } catch (error) {
    //         setNotif({
    //             notifMsg: error?.response?.data?.message,
    //             open: true,
    //             severity: "danger",
    //         });
    //     }
    // };

    /**
     *  pagination data
     */
    const { meta, links, data } = rawData;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: {
            pageSize,
            canNextPage,
            canPreviousPage,
            totalPage,
            currentPage,
            firstPage,
            lastPage,
        },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: meta?.per_page,
                canNextPage: links?.next !== null ? true : false,
                canPreviousPage: links?.prev !== null ? true : false,
                totalPage: meta?.last_page,
                currentPage: meta?.current_page,
                firstPage: meta?.first,
                lastPage: meta?.last_page,
            },
        },
        useSortBy,
        usePagination
    );
    const gotoPage = (page) => {
        getInvoiceCourseStudentApi(invoiceCourseId, {
            page,
        });
    };

    /**
     *  delete student
     */
    const deleteStudent = async (id) => {
        try {
            await api.delete(
                `tms/invoice-courses/${invoiceCourseId}/invoice-course-students/${id}`,
                {}
            );
            setNotif({
                id: id,
                notifMsg: "Deleted successfully",
                open: true,
                severity: "success",
            });
            getInvoiceCourseStudentApi(invoiceCourseId);
            getInvoiceApi(invoiceId);
            setStudentFin(null);
        } catch (error) {
            setNotif({
                id: id,
                notifMsg: error.message,
                open: true,
                severity: "danger",
            });
        }
    };

    /**
     * Modal popup
     */

    const openDeleteModal = (id) => {
        setModalInfo({
            id: id,
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            severity: "danger",
        });
    };
    return (
        <>
            {data.length > 0 && (
                <Row>
                    {totalPage > 1 && (
                        <Row>
                            <Col md="6">
                                <span className="mx-2">
                                    Page
                                    <strong>
                                        {currentPage} of {totalPage}
                                    </strong>
                                </span>
                            </Col>
                            <Col md="6">
                                <Pagination className="float-end">
                                    <Pagination.First
                                        onClick={() => gotoPage(firstPage)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPage(currentPage - 1)
                                        }
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPage(currentPage + 1)
                                        }
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(lastPage)}
                                        disabled={!canNextPage}
                                    />
                                </Pagination>
                            </Col>
                        </Row>
                    )}
                    <Table responsive striped bordered {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps()
                                            )}
                                        >
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell, index, row) => {
                                            if (index === 0) {
                                                return (
                                                    <td key={index}>
                                                        {currentPage > 1
                                                            ? (currentPage -
                                                                  1) *
                                                                  pageSize +
                                                              i +
                                                              1
                                                            : i + 1}
                                                    </td>
                                                );
                                            }
                                            if (index + 1 === row.length) {
                                                return (
                                                    <td key={index}>
                                                        {cell.row.original.status_key?.toUpperCase() ===
                                                            "PENDING" && (
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id={`tooltip-top`}>Edit Student</Tooltip>}
                                                            >
                                                                <Button
                                                                    variant="primary"
                                                                    className="me-1 mb-1"
                                                                    onClick={() => {
                                                                        setStudentFin(cell.row.original.student.fin ?? null);
                                                                        setAddStudent(true);
                                                                    }}
                                                                >
                                                                    <Edit className="feather" />
                                                                </Button>
                                                            </OverlayTrigger>
                                                        )}
                                                        
                                                        {/* {cell.row.original.status_key?.toUpperCase() ===
                                                            "PENDING" && (
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id={`tooltip-top`}>Remove Student</Tooltip>}
                                                            >
                                                                <Button
                                                                    variant="danger"
                                                                    className="me-1 mb-1"
                                                                    onClick={() =>
                                                                        openDeleteModal(
                                                                            cell
                                                                                .row
                                                                                .original
                                                                                .id
                                                                        )
                                                                    }
                                                                >
                                                                    <Trash className="feather" />
                                                                </Button>
                                                            </OverlayTrigger>
                                                        )} */}
                                                        {permission?.includes("INVOICE-REPLACE-STUDENT") && 
                                                            (
                                                                (cell.row.original?.invoice?.is_date_expired?.toUpperCase() === "YES"
                                                                || cell.row.original?.invoice?.status_key?.toUpperCase() === "PAID"
                                                                || cell.row.original?.invoice?.sales_director_approval !== null) &&
                                                                cell.row.original?.can_replace === true
                                                            )
                                                            && (
                                                                <Button
                                                                    variant="success"
                                                                    className="me-1 mb-1"
                                                                    onClick={() =>{
                                                                        setStudentFin(null)
                                                                        changeStudentApi(
                                                                            invoiceCourseId,
                                                                            cell
                                                                                .row
                                                                                .original
                                                                                .id
                                                                        )
                                                                    }}
                                                                >
                                                                    Replace
                                                                    student
                                                                </Button>
                                                        )}
                                                    </td>
                                                );
                                            }
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    {totalPage > 1 && (
                        <Row>
                            <Col md="6">
                                <span className="mx-2">
                                    Page
                                    <strong>
                                        {currentPage} of {totalPage}
                                    </strong>
                                </span>
                            </Col>
                            <Col md="6">
                                <Pagination className="float-end">
                                    <Pagination.First
                                        onClick={() => gotoPage(firstPage)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPage(currentPage - 1)
                                        }
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPage(currentPage + 1)
                                        }
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(lastPage)}
                                        disabled={!canNextPage}
                                    />
                                </Pagination>
                            </Col>
                        </Row>
                    )}
                </Row>
            )}
            {data.length === 0 && (
                <Alert variant="warning">
                    <div className="alert-message">No students</div>
                </Alert>
            )}
            <DeleteModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={deleteStudent}
            />
            {/* <SnackbarAlert notif={notif} setNotif={setNotif} /> */}
        </>
    );
};

export default CourseStudents;
