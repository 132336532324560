import React, { useState } from "react";
import { Card, Accordion, Button, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { DeleteModal } from "../../../components/DeleteModal";
import { SnackbarAlert } from "../../../components/SnackbarAlert";
import api from "../../../service/api";

const BatchDetails = ({
    batch,
    type,
    attendanceApi,
    getBatchStudentsApi,
    getBatchApi,
    defaultAccordion,
}) => {
    const navigate = useNavigate();
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
        type: "",
    });

    const markAsCompleteAllocation = async (id) => {
        try {
            await api.post(`tms/batches/update-status/${id}`, {
                status: "training",
            });
            setNotif({
                id: id,
                notifMsg: "status updated successfully",
                open: true,
                severity: "success",
            });
            // await getBatchStudentsApi();
            // await getBatchApi();
            navigate(`/tms/batches/attendance`);
        } catch (error) {
            setNotif({
                id: id,
                notifMsg: error?.message,
                open: true,
                severity: "danger",
            });
        }
    };

    const markAsCompleteTraining = async (id) => {
        try {
            await api.post(`tms/batches/update-status/${id}`, {
                status: "waiting-for-result",
            });
            setNotif({
                id: id,
                notifMsg: "status updated successfully",
                open: true,
                severity: "success",
            });
            // await getBatchStudentsApi();
            //await getBatchApi();
            navigate(`/tms/batches/waiting-for-result`);
        } catch (error) {
            setNotif({
                id: id,
                notifMsg: error?.message,
                open: true,
                severity: "danger",
            });
        }
    };
    const markAsCompletedResult = async (id) => {
        try {
            await api.post(`tms/batches/update-status/${id}`, {
                status: "completed",
            });
            setNotif({
                id: id,
                notifMsg: "status updated successfully",
                open: true,
                severity: "success",
            });
            // await getBatchStudentsApi();
            await getBatchApi();
        } catch (error) {
            setNotif({
                id: id,
                notifMsg: error?.message,
                open: true,
                severity: "danger",
            });
        }
    };

    const openConfirmationModalForAllocation = () => {
        setModalInfo({
            id: batch.id,
            notifMsg:
                "Are you sure you want to mark complete allocation? Once click confirm then it will transfer to training section",
            open: true,
            severity: "danger",
            type: markAsCompleteAllocation,
        });
    };

    const openConfirmationModalForTraining = () => {
        setModalInfo({
            id: batch.id,
            notifMsg:
                "Are you sure you want to mark complete training? Once click confirm then it will transfer to training section",
            open: true,
            severity: "danger",
            type: markAsCompleteTraining,
        });
    };

    const openConfirmationModalForCompleted = () => {
        setModalInfo({
            id: batch.id,
            notifMsg:
                "Are you sure you want to mark as complete result update? Once click confirm then it will transfer to completed/archive",
            open: true,
            severity: "danger",
            type: markAsCompletedResult,
        });
    };

    return (
        <>
            <Card>
                <Card.Body className="text-left">
                    <Card.Title tag="h5" className="mb-0">
                        {batch?.name}
                        {type}
                    </Card.Title>
                    <ul className="list-unstyled mb-0 mt-2">
                        <li className="mb-2">
                            Type: {batch?.type?.toUpperCase()}{" "}
                        </li>
                        <li className="mb-2">Test date: {batch?.test_date}</li>
                        <li className="mb-2">Test venue: {batch?.test_venue?.join(', ') || "-"}</li>
                        <li className="mb-2">Training venue: {batch?.training_venue?.join(', ') || "-"}</li>
                        <li className="mb-2">
                            Status: <Badge>{batch?.status}</Badge>{" "}
                        </li>
                    </ul>
                </Card.Body>

                {batch?.status_key?.toLowerCase() === "pending" && (
                    <>
                        <hr className="my-0" />
                        <Card.Body className="text-left">
                            <Card.Title tag="h5" className="mb-0">
                                <Button
                                    variant="info"
                                    onClick={openConfirmationModalForAllocation}
                                >
                                    Mark as finished allocation
                                </Button>
                            </Card.Title>
                        </Card.Body>
                    </>
                )}

                {batch?.status_key?.toLowerCase() === "training" && (
                    <>
                        <hr className="my-0" />
                        <Card.Body className="text-left">
                            <Card.Title tag="h5" className="mb-0">
                                <Button
                                    variant="info"
                                    onClick={openConfirmationModalForTraining}
                                >
                                    Mark as completed training
                                </Button>
                            </Card.Title>
                        </Card.Body>
                    </>
                )}
                {batch?.status_key?.toLowerCase() === "waiting-for-result" && (
                    <>
                        <hr className="my-0" />
                        <Card.Body className="text-left">
                            <Card.Title tag="h5" className="mb-0">
                                <Button
                                    variant="info"
                                    onClick={openConfirmationModalForCompleted}
                                >
                                    Mark as completed
                                </Button>
                            </Card.Title>
                        </Card.Body>
                    </>
                )}
                <hr className="my-0" />
                <Card.Body>
                    <Card.Title tag="h5">Training dates</Card.Title>

                    {batch?.training_dates && (
                        <Accordion defaultActiveKey={defaultAccordion} flush>
                            {batch?.training_dates.map(
                                (training_date, accordionIndex) => {
                                    return (
                                        <Accordion.Item
                                            eventKey={`traningAccordian-${accordionIndex}`}
                                            key={`trainingdate-${accordionIndex}`}
                                            className="bg-white"
                                        >
                                            <Accordion.Header>
                                                {accordionIndex + 1} .{" "}
                                                {training_date.date}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <ul className="list-unstyled mb-0 mt-2">
                                                    <li className="mb-2">
                                                        {" "}
                                                        {training_date.time}
                                                    </li>
                                                    <li className="mb-2">
                                                        Trainer{" "}
                                                        {accordionIndex + 1}
                                                    </li>
                                                    <li className="mb-2">
                                                        {
                                                            training_date?.training_type
                                                        }
                                                    </li>
                                                </ul>

                                                <table className="table table-bordered text-center">
                                                    <tbody>
                                                        <tr>
                                                            {/* {type ===
                                                                "attendance" &&
                                                                training_date?.map(
                                                                    (
                                                                        session,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <td
                                                                                className="bg-secondary text-light"
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() => {
                                                                                    attendanceApi(
                                                                                        batch.id,
                                                                                        session,
                                                                                        training_date.date,
                                                                                        `traningAccordian-${accordionIndex}`
                                                                                    );
                                                                                }}
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {session?.toUpperCase()}
                                                                            </td>
                                                                        );
                                                                    }
                                                                )}
                                                            {type !==
                                                                "attendance" &&
                                                                training_date?.session?.map(
                                                                    (
                                                                        session,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <td
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {session?.toUpperCase()}
                                                                            </td>
                                                                        );
                                                                    }
                                                                )} */}
                                                            {training_date?.am &&
                                                                type?.toLowerCase() ===
                                                                    "attendance" && (
                                                                    <>
                                                                        <td
                                                                            className="bg-secondary text-light"
                                                                            style={{
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={() => {
                                                                                attendanceApi(
                                                                                    batch.id,
                                                                                    "AM",
                                                                                    training_date.date,
                                                                                    `traningAccordian-${accordionIndex}`
                                                                                );
                                                                            }}
                                                                        >
                                                                            AM
                                                                        </td>
                                                                    </>
                                                                )}
                                                            {training_date?.am &&
                                                                type?.toLowerCase() ===
                                                                    "attendance" && (
                                                                    <>
                                                                        <td
                                                                            className="bg-secondary text-light"
                                                                            style={{
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={() => {
                                                                                attendanceApi(
                                                                                    batch.id,
                                                                                    "PM",
                                                                                    training_date.date,
                                                                                    `traningAccordian-${accordionIndex}`
                                                                                );
                                                                            }}
                                                                        >
                                                                            PM
                                                                        </td>
                                                                    </>
                                                                )}
                                                            {training_date?.am &&
                                                                type?.toLowerCase() !==
                                                                    "attendance" && (
                                                                    <>
                                                                        <td>
                                                                            AM
                                                                        </td>
                                                                    </>
                                                                )}
                                                            {training_date?.am &&
                                                                type?.toLowerCase() !==
                                                                    "attendance" && (
                                                                    <>
                                                                        <td>
                                                                            PM
                                                                        </td>
                                                                    </>
                                                                )}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                }
                            )}
                        </Accordion>
                    )}
                </Card.Body>

                <hr className="my-0" />
                <Card.Body>
                    <Card.Title tag="h5">Invigilators</Card.Title>
                    {batch?.invigilators && (
                        <ul className="list-unstyled mb-0">
                            {batch?.invigilators.map((invigilator, index) => {
                                return (
                                    <li
                                        key={`invigilators-${index}`}
                                        className="pb-2"
                                    >
                                        <div className="d-flex justify-content-start">
                                            <div>{index + 1})</div>{" "}
                                            <div className="m-1"></div>
                                            <div>{invigilator?.name}</div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </Card.Body>
            </Card>
            <DeleteModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={
                    modalInfo?.type
                    // ?
                    // : markAsCompleteAllocation
                }
            />
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};
export default BatchDetails;
