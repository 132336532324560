import { Button } from "bootstrap";
import React, { useCallback, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Download } from "react-feather";
import download from "downloadjs";
import api from "../../../../../service/api";

const InvoiceCreditNotes = ({ creditNotes }) => {

    const [formConfig, setFormConfig] = useState();
    
    const downloadPDF = async (id, credit_note_number) => {
        const response = await api.getpdf("tms/generate-credit-note-pdf/" + id);
        download(response, credit_note_number, "application/pdf");
    };

    /** get the invoice */
    const getInvoice = useCallback(async () => {
        try {
            const configs = await api.get(`tms/invoices/get-config`);
            setFormConfig(configs.form.fields);
        } catch (error) {
            console.error(error);
        }
    }, [creditNotes]);

    // /**  To get quotation */
    useEffect(() => {
        getInvoice();
    }, [creditNotes, getInvoice]);

    return (
        <Table responsive bordered size="sm" striped>
            <thead>
                <tr>
                    <th>CN number</th>

                    <th>Remarks/Requested by</th>

                    <th>Requested refund</th>

                    <th>Sales/Finance Director</th>

                    <th>Status </th>
                </tr>
            </thead>
            <tbody>
                {creditNotes.map((creditNote, index) => {
                    return (
                        <tr key={index}>
                            <td style={{ verticalAlign: "top" }}>
                                {creditNote?.number}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                                <Table responsive bordered size="sm">
                                    <tbody>
                                        <tr>
                                            <td>Type</td>
                                            <td>{formConfig?.reason?.options.find(option => option.value === creditNote?.type)?.display || ''}</td>
                                        </tr>
                                        <tr>
                                            <td>Reason</td>
                                            <td>{formConfig?.reason?.options.find(option => option.value === creditNote?.reason)?.display || ''}</td>
                                        </tr>
                                        {creditNote?.invoice_course_students
                                            ?.students?.length > 0 && (
                                            <tr>
                                                <td>Students</td>
                                                <td>
                                                    {creditNote?.invoice_course_students?.students?.map(
                                                        (student, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                >
                                                                    {index + 1}.{" "}
                                                                    {student}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td>Remarks</td>
                                            <td>{creditNote?.remarks}</td>
                                        </tr>
                                        <tr>
                                            <td>Requested by</td>
                                            <td>{creditNote?.user?.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Requested on</td>
                                            <td>{creditNote.created_at
                                                    ? new Date(creditNote.created_at).toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'}).replace(/\//g, '-').replace(',', ', ')
                                                    : "-"
                                                }</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                                <table className="table">
                                    <tr>
                                        <td colSpan={2}>
                                            {creditNote?.refund?.toUpperCase() ===
                                            "YES"
                                                ? "Yes"
                                                : "No"}
                                        </td>
                                    </tr>
                                    <tr>
                                                <td>Amount</td>
                                                <td>${creditNote?.amount}</td>
                                            </tr>
                                            <tr>
                                                <td>Test fee</td>
                                                <td>
                                                    $
                                                    {
                                                        creditNote?.deduction
                                                            ?.test_fee
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Admin fee</td>
                                                <td>
                                                    $
                                                    {
                                                        creditNote?.deduction
                                                            ?.admin_fee
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>BCA fee</td>
                                                <td>
                                                    $
                                                    {
                                                        creditNote?.deduction
                                                            ?.bca_fee
                                                    }
                                                </td>
                                            </tr>
                                    {/* {creditNote?.refund?.toUpperCase() ===
                                        "YES" && (
                                        <>
                                            <tr>
                                                <td>Amount</td>
                                                <td>${creditNote?.amount}</td>
                                            </tr>
                                            <tr>
                                                <td>Test fee</td>
                                                <td>
                                                    $
                                                    {
                                                        creditNote?.deduction
                                                            ?.test_fee
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Admin fee</td>
                                                <td>
                                                    $
                                                    {
                                                        creditNote?.deduction
                                                            ?.admin_fee
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>BCA fee</td>
                                                <td>
                                                    $
                                                    {
                                                        creditNote?.deduction
                                                            ?.bca_fee
                                                    }
                                                </td>
                                            </tr>
                                        </>
                                    )} */}
                                </table>
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                                {creditNote?.sales_director_status === null && (
                                    <div>-</div>
                                )}
                                {creditNote?.sales_director_status !== null && (
                                    <>
                                        <h6>Sales director status</h6>
                                        <Table responsive bordered size="sm">
                                            <tbody>
                                                <tr>
                                                    <td>Status</td>
                                                    <td>
                                                        {
                                                            creditNote?.sales_director_status
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Date</td>
                                                    <td>
                                                        {
                                                            creditNote?.sales_director?.date
                                                                ? new Date(creditNote?.sales_director?.date).toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'}).replace(/\//g, '-').replace(',', ', ')
                                                                : "-"
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Remarks</td>
                                                    <td>
                                                        {
                                                            creditNote
                                                                ?.sales_director
                                                                ?.remarks
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </>
                                )}
                                <hr className="mt-1 mb-2" />

                                {creditNote?.finance_director_status ===
                                    null && <div>-</div>}
                                {creditNote?.finance_director_status && (
                                    <>
                                        <h6>Finance director status</h6>
                                        <Table responsive bordered size="sm">
                                            <tbody>
                                                <tr>
                                                    <td>Status</td>
                                                    <td>
                                                        {
                                                            creditNote?.finance_director_status
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Date</td>
                                                    <td>
                                                        {creditNote?.finance_director?.date
                                                            ? new Date(creditNote?.finance_director?.date).toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'}).replace(/\//g, '-').replace(',', ', ')
                                                            : "-"
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Remarks</td>
                                                    <td>
                                                        {
                                                            creditNote
                                                                ?.finance_director
                                                                ?.remarks
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </>
                                )}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                                {creditNote.status}

                                { creditNote.status === 'APPROVED' &&
                                    <Button
                                        variant="secondary"
                                        className="me-1 mb-1"
                                        onClick={() => downloadPDF(creditNote?.id, creditNote?.number)}
                                    >
                                        <Download className="feather" />
                                    </Button>
                                }
                            </td>
                            
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};
export default InvoiceCreditNotes;
