import React, { useEffect, useState } from "react";

import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";

import { Calendar, Filter } from "react-feather";

const Header = ({ getDashBoardData }) => {
    var dateObj = new Date();
    var month = ("0" + (dateObj.getMonth() + 1)).slice(-2); //months from 1-12

    var year = dateObj.getFullYear();

    const [inputField, setInput] = useState({
        month: `${year}-${month}`,
    });
    const searchData = () => {
        getDashBoardData(inputField);
    };
    useEffect(() => {
        getDashBoardData(inputField);
    }, [getDashBoardData, inputField]);
    return (
        <Row className="mb-2 mb-xl-3">
            <Col xs="auto" className="d-none d-sm-block">
                <h3>Dashboard</h3>
            </Col>

            <Col xs="auto" className="ms-auto text-end mt-n1">
                <Row>
                    <Col md={10}>
                        <Form.Group>
                            <Form.Control
                                type="month"
                                name="month"
                                min="2022-01"
                                max="2050-12"
                                value={inputField?.month}
                                size="lg"
                                onChange={(event) => {
                                    setInput({
                                        month: event.target.value,
                                    });
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={2}>
                        <Button variant="primary" onClick={searchData}>
                            <Filter className="feather" />
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default Header;
