import { Badge } from "react-bootstrap";

const tableColumns = [
    {
        Header: "S.no",
        width: "80",
        sticky: "left",
    },
    {
        Header: "Actions",
        accessor: "actions",
        width: "100",
        sticky: "left",
    },
    {
        Header: "Name",
        accessor: "name",
        width: "250",
        sticky: "left",
    },
    {
        Header: "FIN",
        accessor: "fin",
        width: "120",
        sticky: "left",
    },
    {
        Header: "Sector",
        accessor: "sector",
    },
    // {
    //     Header: "Work permit category",
    //     accessor: "student.work_permit_category",
    // },
    // {
    //     Header: "Coretrade/Multi-skilling/Direct R1 Reg. No.",
    //     accessor: "student.coretrade_multi_skill_direct",
    // },
    {
        Header: "Expire date",
        accessor: "work_permit_expire_date",
    },
    {
        Header: "Company",
        accessor: "company.name",
        width: "300",
    },
    {
        Header: "Sponsor company",
        id: "sponsor_company",
        accessor: (data) => {
            return (
                <div>
                    {data?.self_registration && (
                        <Badge bg="primary">SELF</Badge>
                    )}
                    {!data?.self_registration && (
                        <div>{data?.sponsor_company?.name}</div>
                    )}
                </div>
            );
        },
        width: "300",
    },
    {
        Header: "Course",
        id: "course_information",
        accessor: (data) => {
            return (
                <div>
                    {data?.type_of_trade?.toUpperCase() === "CET" && "-"}
                    {data?.type_of_trade?.toUpperCase() !== "CET" && (
                        <div>{data?.course?.name}</div>
                    )}
                </div>
            );
        },
        width: "250",
    },
    {
        Header: "Trade category",
        accessor: "trade_category",
        width: "200",
    },
    {
        Header: "Type of trade",
        accessor: "type_of_trade",
        width: "180",
    },
    {
        Header: "Application option",
        accessor: "application_option",
        width: "200",
    },
    {
        Header: "Application date",
        accessor: "date",
        width: "150",
    },
    // {
    //     Header: "Payment mode",
    //     accessor: "payment_mode",
    // },
    {
        Header: "Status",
        accessor: "status",
        width: "180",
    },
    {
        Header: "Test date",
        id: "test_date",
        accessor: (data) => {
            return <div>{data?.batch?.batch?.test_date || "-"}</div>;
        },
        width: "150",
    },
    {
        Header: "Attc test date",
        accessor: "attc_test_date",
        width: "150",
    },
    {
        Header: "Result date",
        // accessor: "result_date",
        id: "result_date",
        accessor: (data) => {
            return <div>{data?.result_date || "-"}</div>;
        },
        width: "150",
    },
    {
        Header: "Result",
        // accessor: "result_date",
        id: "result",
        accessor: (data) => {
            return <div>{data?.result || "-"}</div>;
        },
        width: "150",
    },
    {
        Header: "Renewal date",
        // accessor: "result_date",
        id: "renewal_date",
        accessor: (data) => {
            return <div>{data?.renewal_date || "-"}</div>;
        },
        width: "150",
    },
    {
        Header: "Invoice number",
        accessor: "invoice_no",
        width: "150",
    },
];

export { tableColumns };
