import React, { useEffect, useState } from "react";

import Select from "react-select";
import { Alert, Button, Card, Col, Form, Row, Table } from "react-bootstrap";

import DynamicTable from "../../../components/version-two-components/DynamicTable";
import { addDefaultOption } from "../../../utils/utility";
import { Trash } from "react-feather";
import api from "../../../service/api";
import EmailListTable from "../SendEmail/EmailListTable";
import ReactDatePicker from "react-datepicker";
import { UserDateFormat } from "../../../utils/dateFormat";
import moment from "moment";

const ResultBatchStudent = ({
    data,
    columns,
    batch,
    getBatchStudentsApi,
    setNotif = null,
    loading,
    searchValue,
}) => {
    //
    // States
    //
    const DateFormat = UserDateFormat();
    
    const [error, setError] = useState();
    const [resultData, setResultData] = useState({
        date: "",
        result: "",
    });
    const [forceSelectionIdxs, setForceSelectionIdxs] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toDeleteRow, setToDeleteRow] = useState("");

    //
    // Functions
    //

    const onChange = (options) => {
        setResultData({ ...resultData, result: options?.value });
    };

    const createAttendance = async () => {
        const students = selectedRows.map((d) => {
            return {
                student_id: d.student_id,
                enrolment_id: d.enrolment_id || d.id,
            };
        });

        if (resultData?.result === "") {
            alert("Select Result");
            return;
        }
        if (resultData?.date === "") {
            alert("Select Result date");
            return;
        }
        const postData = {
            students: students,
            result: resultData?.result,
            result_date: resultData?.date,
            batch_id: batch.id,
        };

        try {
            await api.post("tms/enrolments/results", postData);
            getBatchStudentsApi(searchValue);

            if (Boolean(setNotif)) {
                resetSelected();
                setToDeleteRow("ALL");
                setNotif({
                    notifMsg: "Successfully updated student result",
                    open: true,
                    severity: "success",
                });
            }
        } catch (error) {
            setError(error?.response?.data?.message);
        }
    };

    const resetSelected = () => {
        setSelectedRows([]);
        setForceSelectionIdxs({});
    };

    const resetSelectedIdxs = (indexs) => {
        
        var array = [...selectedRows]; // make a separate copy of the array
        console.log(array);
    };

    //
    // UseEffects
    //

    useEffect(() => {
        
        if (!data) return;
        const selected = data.filter((idxn, idx) => {
            return Object.keys(forceSelectionIdxs).some((id) => {
                return idx === Number(id);
            });
        });
        console.log('merged', selected)
        setSelectedRows(selected);
    }, [forceSelectionIdxs]);

    return (
        <Card>
            <Card.Body>
                {error && (
                    <Alert className="my-3" variant="danger">
                        <div className="alert-message">{error}</div>
                    </Alert>
                )}
                {loading && <div>Loading...</div>}
                {!loading && (
                    <>
                    <Row>
                        <Col md={4}>
                            <Form.Group className="mb-3">
                                <Select
                                    className="is-invalid react-select-container"
                                    classNamePrefix="react-select "
                                    options={addDefaultOption([
                                        {
                                            value: "pass",
                                            label: "PASS",
                                        },
                                        {
                                            value: "fail",
                                            label: "FAIL",
                                        },
                                        {
                                            value: "absent",
                                            label: "ABSENT",
                                        },
                                        {
                                            value: "nc",
                                            label: "NC",
                                        },
                                    ])}
                                    name="attendance"
                                    isClearable={true}
                                    onChange={onChange}
                                    defaultValue={[
                                        {
                                            value: resultData?.result,
                                            label: resultData?.result?.toUpperCase(),
                                        },
                                    ]}
                                    styles={{
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 9999,
                                        }),
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            {/* <Form.Control
                                size="lg"
                                type="date"
                                name="date"
                                placeholder="Result date"
                                value={resultData.date}
                                onChange={(event) => {
                                    setResultData({
                                        ...resultData,
                                        date: event.target.value,
                                    });
                                }}
                            /> */}
                            <ReactDatePicker
                                name="date"
                                className={`form-control form-control-lg`}
                                selected={resultData?.date && resultData?.date !== '' ? 
                                    new Date(
                                        moment(
                                            resultData.date,
                                            DateFormat.toUpperCase()
                                        ).format("YYYY-MM-DD")
                                    )
                                    :
                                    ''
                                }
                                onChange={(date) => {
                                    setResultData({
                                        ...resultData,
                                        date: date ?
                                        new Date(
                                            moment(
                                                date,
                                                DateFormat.toUpperCase()
                                            ).format("YYYY-MM-DD")
                                        )
                                        : '',
                                    });
                                }}
                                dateFormat={DateFormat}
                                placeholderText={DateFormat.toUpperCase()}
                            />
                        </Col>
                        <Col md={4}>
                            <Button
                                variant="info"
                                size="lg"
                                onClick={createAttendance}
                            >
                                Mark results
                            </Button>
                        </Col>
                    
                        <DynamicTable
                            dataPagination={{
                                data: data,
                                meta: "",
                                links: "",
                            }}
                            columns={columns}
                            className="table-layout-fixed"
                            isPaginated
                            withCheckbox
                            onChangeSelectedRowsId={(selectedIds) => {
                                setForceSelectionIdxs(selectedIds);
                            }}
                            toDeleteRow={toDeleteRow}
                            resetSelectedIdxs={resetSelectedIdxs}
                        />
                    </Row>
                    </>

                )}
                {/* {selectedRows.map((d) => d.student_id).length > 0 && (
                    <Row>
                        <Col md={12}>
                            <Form.Label className="font-weight-bold h4">
                                Mark students
                            </Form.Label>
                            <Table responsive striped bordered>
                                <thead>
                                    <tr>
                                        <th>
                                            <Trash
                                                className="align-middle text-danger"
                                                size={18}
                                                onClick={() => {
                                                    resetSelected();
                                                    setToDeleteRow("ALL");
                                                }}
                                            />
                                        </th>
                                        <th>Name</th>
                                        <th>FIN</th>
                                        <th>Trade category</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedRows.map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <Trash
                                                        className="align-middle text-danger"
                                                        size={18}
                                                        onClick={() => {
                                                            const filteredArr =
                                                                selectedRows.filter(
                                                                    (obj) =>
                                                                        obj.id !==
                                                                        data.id
                                                                );

                                                            delete forceSelectionIdxs[
                                                                index
                                                            ];

                                                            setSelectedRows(
                                                                filteredArr
                                                            );
                                                            setForceSelectionIdxs(
                                                                forceSelectionIdxs
                                                            );
                                                            setToDeleteRow(
                                                                data.id
                                                            );
                                                        }}
                                                    />
                                                </td>
                                                <td>{data.enrolment.name}</td>
                                                <td>{data.enrolment.fin}</td>
                                                <td>{data.enrolment.trade_category}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                        
                    </Row>
                )} */}

                
            </Card.Body>
        </Card>
    );
};

export default ResultBatchStudent;
