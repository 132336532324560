import React, { useState } from "react";
import { Alert, Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { useTable, useRowSelect } from "react-table";
import Select from "react-select";
import api from "../../../service/api";
import { Plus, Trash2 } from "react-feather";
import { addDefaultOption } from "../../../utils/utility";

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        );
    }
);
const BatchStudents = ({
    data,
    columns,
    batchDates,
    batch,
    getBatchStudentsApi,
    isSummaryPage,
}) => {
    const [error, setError] = useState();
    const [initialValue, setInitialValue] = useState({
        trainingData: [],
        dateSession: [],
    });
    const [dateSessionList, setdateSessionList] = useState([]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        // state: { selectedRowIds },
    } = useTable(
        {
            columns,
            data,
        },
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",

                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox
                                {...getToggleAllRowsSelectedProps()}
                            />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox
                                {...row.getToggleRowSelectedProps()}
                            />
                        </div>
                    ),
                },
                ...columns,
            ]);
        }
    );

    const onChange = (options) => {
        setdateSessionList([]);

        // const sessions = options?.session
        //     ?.filter((item) => item.value === true)
        //     .map((item) => {
        //         return {
        //             label: item?.name?.toUpperCase(),
        //             value: item?.name?.toUpperCase(),
        //         };
        //     });
        setdateSessionList(options?.session);
        // const selectedDate = options?.value;
        const selectedDate = options?.map((item) => {
            return item.value;
        });

        setInitialValue({
            ...initialValue,
            trainingData: selectedDate,
            dateSession: "",
        });
        console.log(initialValue?.trainingData);
    };
    const onSessionChange = (options) => {
        const selectedSessions = options?.map((item) => {
            return item.value;
        });

        setInitialValue({
            ...initialValue,
            dateSession: selectedSessions,
        });
    };
    const createTrainingDates = async () => {
        const students = selectedFlatRows.map((d) => {
            return {
                student_id: d.original.student_id,
                enrolment_id: d.original.enrolment_id,
                batch_student_id: d.original.id,
            };
        });

        const trainingDates = initialValue?.trainingData;
        if (
            trainingDates === "" ||
            students.length === 0
            // initialValue?.dateSession?.length === 0
        ) {
            alert("please select date");
            return;
        }
        const postData = {
            students: students,
            batch_date_id: trainingDates,
            batch_id: batch.id,
            // sessions: initialValue?.dateSession,
        };
        try {
            await api.post("tms/batch-training-dates/create", postData);
            getBatchStudentsApi();
        } catch (error) {
            setError(error?.message);
        }
    };

    const deleteBatchStudent = async () => {
        const students = selectedFlatRows.map((d) => {
            return {
                student_id: d.original.student_id,
                enrolment_id: d.original.enrolment_id,
                batch_id: batch.id,
            };
        });
        try {
            await api.post("tms/batch-students/delete", {
                students,
            });
            getBatchStudentsApi();
        } catch (error) {
            setError(error);
        }
    };
    const deleteTrainingDate = async () => {
        const students = selectedFlatRows.map((d) => {
            return {
                student_id: d.original.student_id,
                enrolment_id: d.original.enrolment_id,
                batch_id: batch.id,
            };
        });
        try {
            await api.post("tms/batch-training-dates/delete", {
                students,
            });
            getBatchStudentsApi();
        } catch (error) {
            setError(error);
        }
    };
    return (
        <Card>
            <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                    Class students
                </Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
                {error && (
                    <Alert className="my-3" variant="danger">
                        <div className="alert-message">{error}</div>
                    </Alert>
                )}

                {/**  enable only check box checked and batch status is "pending student allocation" */}
                {selectedFlatRows.map((d) => d.original.student_id).length >
                    0 &&
                    (batch?.status_key?.toLowerCase() === "pending" ||
                        isSummaryPage === "summary") && (
                        <Row>
                            <Col md={8}>
                                <Row>
                                    <Col md={8}>
                                        <Form.Group>
                                            <Select
                                                className="is-invalid react-select-container"
                                                classNamePrefix="react-select "
                                                options={addDefaultOption(
                                                    batchDates
                                                )}
                                                name="training_dates"
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={true}
                                                onChange={onChange}
                                                // defaultValue={{
                                                //     value: initialValue?.trainingData,
                                                //     label: initialValue?.trainingData,
                                                // }}
                                            />
                                        </Form.Group>
                                    </Col>

                                    {/* {dateSessionList?.length > 0 && (
                                        <Col md={4}>
                                            <Form.Group>
                                                <Select
                                                    className="is-invalid react-select-container"
                                                    classNamePrefix="react-select "
                                                    options={addDefaultOption(
                                                        dateSessionList
                                                    )}
                                                    name="date_sessions"
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    isMulti={true}
                                                    onChange={onSessionChange}
                                                    defaultValue={
                                                        initialValue?.dateSession &&
                                                        initialValue?.dateSession?.map(
                                                            (
                                                                defaultSession
                                                            ) => {
                                                                return [
                                                                    {
                                                                        value: defaultSession,
                                                                        label: defaultSession,
                                                                    },
                                                                ];
                                                            }
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    )} */}
                                    <Col md={4}>
                                        {initialValue?.trainingData?.length >
                                            0 && (
                                            <Button
                                                variant="info"
                                                size="lg"
                                                onClick={createTrainingDates}
                                            >
                                                <Plus size={16}></Plus>Training
                                                dates
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </Col>

                            <Col md={4}>
                                <Button
                                    variant="danger"
                                    size="lg"
                                    onClick={deleteBatchStudent}
                                    className="float-end"
                                >
                                    <Trash2 size={15}></Trash2>student
                                </Button>

                                <Button
                                    variant="danger"
                                    size="lg"
                                    onClick={deleteTrainingDate}
                                    className="float-end mx-2"
                                >
                                    <Trash2 size={15}></Trash2>date
                                </Button>
                            </Col>
                        </Row>
                    )}

                {data.length === 0 && (
                    <Alert className="my-3" variant="warning">
                        <div className="alert-message">
                            No students under this batch/class
                        </div>
                    </Alert>
                )}
                {data.length > 0 && (
                    <Table striped bordered {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell, index) => {
                                            if (index === 1) {
                                                return (
                                                    <td key={index}>
                                                        {parseInt(cell.row.id) +
                                                            1}
                                                    </td>
                                                );
                                            }
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                )}
            </Card.Body>
        </Card>
    );
};

export default BatchStudents;
