import { LOADING, SUCCEED, FAILED, CLEAR } from "./../action-types";
const companyReducer = (state = [], { type, payload }) => {
  switch (type) {
    case LOADING:
      return { loading: true, error: null, data: [] };

    case SUCCEED:
      return { loading: false, error: null, data: payload };

    case FAILED:
      return { loading: false, error: payload, data: [] };

    case CLEAR:
      return { loading: false, error: null, data: [] };
    default:
      return state;
  }
};
export default companyReducer;
