import React, { useEffect, useState } from "react";

import { Badge, Button, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";

import { AutoGrowTextareaV2 } from "../../../components/version-two-components/AutoGrowTextareaV2";
import { ErrorMessage } from "@hookform/error-message";
import { SnackbarAlert } from "../../../components/SnackbarAlert";
import api from "../../../service/api";
import * as yup from "yup";

export const SendEmailPreviewModal = ({ modalInfo, setModalInfo }) => {
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const schema = yup.object().shape({
        to: yup
            .array()
            .min(1, "This field is required")
            .required("This field is required")
            .nullable(),
        subject: yup.string().required("This field is required"),
        preview_temp: yup.string().required("This field is required"),
    });

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    //
    // States
    //
    const [sendTo, setSendTo] = useState({});
    const [sendTocc, setSendTocc] = useState({});
    const [sentFrom, setsentFrom] = useState({});
    const [locations, setLocations] = useState({});
    const [trainingVenue, setTrainingVenue] = useState({});
    const [loading, setLoading] = useState(false);

    //
    // Functions
    //
    const closeModal = () => {
        setModalInfo({
            ...modalInfo,
            open: false,
        });
        setLoading(false);
        reset({});
    };

    const sendEmail = async (data) => {
        try {
            setLoading(true);
            const selectedStudents = modalInfo?.selectedRows.map(
                (d) => d?.enrolment?.id
            );

            let toClone = [...data?.to];
            let ccClone = [...data?.cc];
            const toData = toClone?.map((d) => d?.value);
            const ccData = ccClone?.map((d) => d?.value);

            data.enrollments = selectedStudents;
            data.batch_id = modalInfo?.data?.batch_id;
            data.self_registration = modalInfo?.data?.self_registration;
            data.sponsor_company_id = modalInfo?.data?.sponsor_company_id;
            data.to = toData;
            data.cc = ccData;
            data.locations = data?.locations?.map((item => item?.value)) ?? null;
            data.training_venues = data?.training_venues?.map((item => item?.value)) ?? null;

            const response = await api.post(
                `tms/enrollment-emails/send-email`,
                data
            );

            if (response.status === "ok") {
                setLoading(false);
                setNotif({
                    notifMsg: "Send email successful",
                    open: true,
                    severity: "success",
                });
                closeModal();
            }
            setLoading (false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const formatSender = (data) => {
        let temp = {};

        temp = data?.map((item) => {
            return {
                value: item?.value,
                label: `${item?.label} (${item?.value})`,
            };
        });

        return temp;
    };

    //
    // UseEffects
    //

    useEffect(() => {
        const data = modalInfo?.data;

        setSendTo(formatSender(data?.send_to));
        setSendTocc(formatSender(data?.send_cc));
        setsentFrom(formatSender(data?.send_from));
        setLocations(data?.locations);
        setTrainingVenue(data?.training_venues);
        reset({
            from: data?.send_from ? data?.send_from[0] : "",
            to: "",
            subject: data?.subject || "",
            cc: "",
            preview_temp: data?.preview_temp || "",
            locations: data?.default_test_venue || null,
            training_venues: data?.default_training_venue || null,
            // locations: [data?.locations?.find(item => item.value === data?.default_test_venue?.toLowerCase())],
            // training_venues: [data?.locations?.find(item => item.value === data?.default_training_venue?.toLowerCase())],
        });
    }, [modalInfo?.data]);

    return (
        <>
            <Modal
                show={modalInfo.open}
                onHide={() => closeModal()}
                size="xl"
                centered
            >
                <Modal.Header closeButton>Preview email</Modal.Header>
                <Modal.Body className="my-2">
                    <Row>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="taskName"
                                    className="font-weight-bold"
                                >
                                    From
                                </Form.Label>

                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="from"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <Select
                                            classNamePrefix="react-select"
                                            options={sentFrom}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="taskName"
                                    className="font-weight-bold"
                                >
                                    To
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="to"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <Select
                                            classNamePrefix="react-select"
                                            options={sendTo}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="to"
                                    render={({ message }) => (
                                        <small className="text-danger">
                                            {message}
                                        </small>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="taskName"
                                    className="font-weight-bold"
                                >
                                    cc
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="cc"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <Select
                                            classNamePrefix="react-select"
                                            options={sendTocc}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>

                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="taskName"
                                    className="font-weight-bold"
                                >
                                    Subject
                                </Form.Label>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="subject"
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <Form.Control
                                            type="text"
                                            value={value || ""}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="subject"
                                    render={({ message }) => (
                                        <small className="text-danger">
                                            {message}
                                        </small>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="taskName"
                                    className="font-weight-bold"
                                >
                                    Introduction message
                                </Form.Label>

                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="preview_temp"
                                    render={({
                                        field: { value, onChange, onBlur },
                                    }) => (
                                        <AutoGrowTextareaV2
                                            fieldValue={value}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            className="new-line"
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="preview_temp"
                                    render={({ message }) => (
                                        <small className="text-danger">
                                            {message}
                                        </small>
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        {modalInfo.selectedRows && (
                            <Col>
                                <Form.Label
                                    htmlFor="taskName"
                                    className="font-weight-bold"
                                >
                                    Students
                                </Form.Label>

                                <Table bordered responsive striped>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Name of candidates</th>
                                            <th>IC no. / Fin no.</th>
                                            <th>
                                                {modalInfo.selectedRows[0]
                                                    .enrolment.course
                                                    ? "Course"
                                                    : "Trade category"}
                                            </th>
                                            <th>Type of trade</th>
                                            <th>Date & time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {modalInfo.selectedRows.map(
                                            (data, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{key + 1}</td>
                                                        <td>
                                                            {
                                                                data?.enrolment
                                                                    ?.name
                                                            }
                                                        </td>
                                                        <td>
                                                            {data?.enrolment?.fin}
                                                        </td>
                                                        <td>
                                                            {data?.enrolment
                                                                ?.course
                                                                ?.name ||
                                                                data?.enrolment
                                                                    ?.sponsor_company
                                                                    ?.name}
                                                        </td>
                                                        <td>
                                                            {
                                                                data?.enrolment
                                                                    ?.type_of_trade
                                                            }
                                                        </td>
                                                        <td>
                                                            {data?.training_dates
                                                                ? data?.training_dates.map(
                                                                      (
                                                                          data
                                                                      ) => {
                                                                          return (
                                                                              <p>
                                                                                  {
                                                                                      data
                                                                                  }
                                                                              </p>
                                                                          );
                                                                      }
                                                                  )
                                                                : ""}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        )}
                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label
                                    htmlFor="taskName"
                                    className="font-weight-bold"
                                >
                                    Body message
                                </Form.Label>
                                <div>
                                    <span className="bold_under_line">
                                        <b>
                                            <u> Attachments:</u>
                                        </b>
                                    </span>
                                    <br />
                                    1) Instructions to Test candidates. <br />
                                    
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label
                                                htmlFor="taskName"
                                                className="font-weight-bold"
                                            >
                                                2) Test venue :
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="locations"
                                                render={({
                                                    field: { value, onChange },
                                                }) => (
                                                    <Select
                                                        classNamePrefix="react-select"
                                                        options={locations}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        isMulti
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label
                                                htmlFor="taskName"
                                                className="font-weight-bold"
                                            >
                                                3) Training venue :
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="training_venues"
                                                render={({
                                                    field: { value, onChange },
                                                }) => (
                                                    <Select
                                                        classNamePrefix="react-select"
                                                        options={locations}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        isMulti
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <br />
                                    4) Administrative charges for any changes.
                                    <br />
                                    <br />
                                    <span className="bold_under_line">
                                        <b>
                                            <u> Important Notes </u>
                                        </b>
                                    </span>{" "}
                                    <br />
                                    1) All candidates undergo mandatory FC
                                    trainings are to report to our training
                                    centre at least 30 minutes before the
                                    training start time. All attendance for
                                    trainings will be captured through the new
                                    thumbprint verification system for
                                    submission to BCA <br />
                                    2) Candidates who failed to attend the
                                    mandatory FC trainings or did not fulfil the
                                    minimum attendance requirement will{" "}
                                    <u>NOT BE ALLOWED</u> to seat for the test
                                    on the allocated test day Course fee will be
                                    forfeited for such cases. <br />
                                    3) Any changes on particulars of candidates,
                                    due to change of passport, renewal of work
                                    permit. Please notify us & provide a new
                                    copy of document. Failure to do so, there
                                    will be a $102 rectification fee imposed by
                                    BCA. <br />
                                    4) Please monitor closely on the training &
                                    test dates of your worker(s) and remind Your
                                    worker(s) to be punctual. No further
                                    reminder will be given.. <br />
                                    5) Your worker(s) Renewal Status will be updated in CWRS website, after 7 working days if at least 75% course attendance is fulfilled and end-of course evaluation attempted by your worker(s).  <br/> <br />
                                    Please feel free to contact us if there’s
                                    any discrepancy in this email confirmation..{" "}
                                    <br />
                                     <br />
                                    Thank you & have a good day! <br /> <br />
                                    Best Regards, <br /> <br />
                                    Fonda Global Engineering Pte Ltd
                                    <br />
                                    <span className="text_danger">
                                        (BCA Approved Training and Testing
                                        Centre)
                                    </span>
                                    <br />
                                    No. 45 Sungei Kadut Loop Singapore 729495
                                    <br />
                                    Tel: 6515 5775
                                    <br />
                                    Fax: 6278 7359
                                    <br />
                                    Web: :
                                    <a href="http://www.fondacoretrade.com.sg">
                                        www.fondacoretrade.com.sg
                                    </a>
                                    <br />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer style={{ minHeight: '75px' }}>
                    <Row>
                        <Col md={12} className="text-center">
                            <Container className="d-flex justify-content-end">
                                {!loading && (
                                    <Button
                                        className="me-2"
                                        variant="primary"
                                        type="submit"
                                        onClick={handleSubmit(sendEmail)}
                                    >
                                        Send
                                    </Button>
                                )}
                                {loading && (
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                )}
                                <Button variant="link" onClick={() => closeModal()}>
                                    Cancel
                                </Button>
                            </Container>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};
