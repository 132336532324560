import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import avatar from "../../assets/img/default.png";
import { Formik } from "formik";
import * as Yup from "yup";
import api from "../../service/api";
import { SnackbarAlert } from "../../components/SnackbarAlert.js";
import { useActions } from "../../hooks/useAction";

const Account = (props) => {
    const [user, setUser] = useState([]);
    const { data } = props;
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const { userDetails } = useActions();

    const submitForm = (values, { setErrors }) => {
        api.put(`users/${user?.id}`, values)
            .then((response) => {
                if (!response.success) {
                    setNotif({
                        notifMsg: response.message,
                        open: true,
                        severity: "danger",
                    });
                }
                if (response.status === "ok") {
                    setNotif({
                        notifMsg: "Successfully saved",
                        open: true,
                        severity: "success",
                    });

                    updateUserDetails();
                }
            })
            .catch((error) => {
                setErrors({ email: "Email has already been taken" });

                setNotif({
                    notifMsg: error.message,
                    open: true,
                    severity: "danger",
                });
            });
    };

    const updateUserDetails = async () => {
        const userResponse = await api.get("users/verification", {});

        userDetails(userResponse.data);
    };

    useEffect(() => {
        setUser(data);
    }, [data]);

    return (
        <>
            <Container>
                <Card>
                    <Card.Header>
                        <Card.Title className="mb-0">Account</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Formik
                            initialValues={{
                                first_name: user?.first_name,
                                last_name: user?.last_name,
                                email: user?.email,
                            }}
                            enableReinitialize
                            validationSchema={Yup.object().shape({
                                first_name: Yup.string().required(
                                    "First name is required"
                                ),
                                last_name: Yup.string().required(
                                    "Last name is required"
                                ),
                                email: Yup.string()
                                    .email("Invalid email address")
                                    .required("This field is required"),
                            })}
                            onSubmit={(values, { setErrors }) =>
                                submitForm(values, { setErrors })
                            }
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                touched,
                                values,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={8}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    First name
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="first_name"
                                                    size="lg"
                                                    placeholder="First name"
                                                    value={
                                                        values?.first_name || ""
                                                    }
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={Boolean(
                                                        touched.first_name &&
                                                            errors.first_name
                                                    )}
                                                />
                                                {!!touched.first_name && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.first_name}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Last name
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="last_name"
                                                    size="lg"
                                                    placeholder="Last name"
                                                    value={
                                                        values?.last_name || ""
                                                    }
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={Boolean(
                                                        touched.last_name &&
                                                            errors.last_name
                                                    )}
                                                />
                                                {!!touched.last_name && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.last_name}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    size="lg"
                                                    placeholder="Email"
                                                    value={values?.email || ""}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={Boolean(
                                                        touched.email &&
                                                            errors.email
                                                    )}
                                                />
                                                {!!touched.email && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.email}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md="4">
                                            <div className="text-center">
                                                <img
                                                    alt=""
                                                    src={avatar}
                                                    className="rounded-circle img-responsive mt-2"
                                                    width="128"
                                                    height="128"
                                                />
                                            </div>
                                            <div className="text-center mt-2">
                                                <h5>
                                                    {data?.department?.join(
                                                        " | "
                                                    )}
                                                </h5>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <div className="d-flex flex-row align-items-center p-2">
                                                <Button
                                                    variant="primary"
                                                    size="lg"
                                                    type="submit"
                                                    className="col-md-3 "
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Card.Body>
                </Card>
            </Container>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export default Account;
