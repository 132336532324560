import React, { useState } from "react";

import { Alert, Button, Card, Col, Table } from "react-bootstrap";
import { useTable, useRowSelect } from "react-table";
import { Trash2 } from "react-feather";

import api from "../../../service/api";

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        );
    }
);
const AllocatedStudents = ({
    data,
    columns,
    batch,
    setRerender,
    rerender,
    setNotif,
}) => {
    const [error, setError] = useState();
    const [initialValue, setInitialValue] = useState({
        trainingData: [],
        dateSession: [],
    });
    const [dateSessionList, setdateSessionList] = useState([]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data,
        },
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",

                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox
                                {...getToggleAllRowsSelectedProps()}
                            />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox
                                {...row.getToggleRowSelectedProps()}
                            />
                        </div>
                    ),
                },
                ...columns,
            ]);
        }
    );

    const onChange = (options) => {
        setdateSessionList([]);

        // const sessions = options?.session
        //     ?.filter((item) => item.value === true)
        //     .map((item) => {
        //         return {
        //             label: item?.name?.toUpperCase(),
        //             value: item?.name?.toUpperCase(),
        //         };
        //     });
        setdateSessionList(options?.session);
        // const selectedDate = options?.value;
        const selectedDate = options?.map((item) => {
            return item.value;
        });

        setInitialValue({
            ...initialValue,
            trainingData: selectedDate,
            dateSession: "",
        });
        console.log(initialValue?.trainingData);
    };
    const onSessionChange = (options) => {
        const selectedSessions = options?.map((item) => {
            return item.value;
        });

        setInitialValue({
            ...initialValue,
            dateSession: selectedSessions,
        });
    };
    const createTrainingDates = async () => {
        const students = selectedFlatRows.map((d) => {
            return {
                student_id: d.original.student_id,
                enrolment_id: d.original.enrolment_id,
                batch_student_id: d.original.id,
            };
        });

        const trainingDates = initialValue?.trainingData;
        if (
            trainingDates === "" ||
            students.length === 0
            // initialValue?.dateSession?.length === 0
        ) {
            alert("please select date");
            return;
        }
        const postData = {
            students: students,
            batch_date_id: trainingDates,
            batch_id: batch.id,
            // sessions: initialValue?.dateSession,
        };
        try {
            await api.post("tms/batch-training-dates/create", postData);
            setRerender(!rerender);
        } catch (error) {
            setError(error?.message);
        }
    };

    const deleteBatchStudent = async () => {
        const students = selectedFlatRows.map((d) => {
            return {
                enrolment_id: d.original.enrolment_id,
                batch_id: batch.id,
            };
        });
        try {
            await api.post("tms/batch-students/delete", {
                students,
            });
            setRerender(!rerender);
            setNotif({
                notifMsg: "Successfully deleted student",
                open: true,
                severity: "success",
            });
        } catch (error) {
            setError(error);
        }
    };
    const deleteTrainingDate = async () => {
        const students = selectedFlatRows.map((d) => {
            return {
                student_id: d.original.student_id,
                enrolment_id: d.original.enrolment_id,
                batch_id: batch.id,
            };
        });
        try {
            await api.post("tms/batch-training-dates/delete", {
                students,
            });
            setRerender(!rerender);
        } catch (error) {
            setError(error);
        }
    };
    return (
        <Card.Body className="pt-0">
            <Col md="10">
                <h2 className="h3 mb-3">Students in batch/class</h2>
            </Col>
            {error && (
                <Alert className="my-3" variant="danger">
                    <div className="alert-message">{error}</div>
                </Alert>
            )}
            {selectedFlatRows.map((d) => d.original.student_id).length > 0 && (
                <Col md={4}>
                    <Button
                        className="mb-2"
                        variant="danger"
                        size="lg"
                        onClick={deleteBatchStudent}
                    >
                        <Trash2 size={15}></Trash2> Student
                    </Button>
                </Col>
            )}
            {/**  enable only check box checked and batch status is "pending student allocation" */}

            {data.length === 0 && (
                <Alert className="my-3" variant="warning">
                    <div className="alert-message">
                        No students under this batch/class
                    </div>
                </Alert>
            )}
            {data.length > 0 && (
                <Table striped bordered {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell, index) => {
                                        if (index === 1) {
                                            return (
                                                <td key={index}>
                                                    {parseInt(cell.row.id) + 1}
                                                </td>
                                            );
                                        }
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            )}
        </Card.Body>
    );
};

export default AllocatedStudents;
