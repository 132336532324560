import React, { useCallback, useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Container, Alert, Button, Col, Form, Row, Badge } from "react-bootstrap";
import { tableColumns } from "./EnrollmentsNotPaid/Data.js";
import { SnackbarAlert } from "../../../components/SnackbarAlert.js";
import api from "../../../service/api.js";
import { useSelector } from "react-redux";
import { Search } from "react-feather";
import { Lists } from "./EnrollmentsNotPaid/List.js";
import SearchFunc from "./EnrollmentsNotPaid/Search.js";
import download from "downloadjs";

const EnrollmentsNotPaid = ({ title }) => {
    /** Permissions */
    const permissionData = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = permissionData?.data?.permissions;

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [defaultConfig, setDefaultConfig] = useState();
    const { state } = useLocation();
    const previousSearchValue = state?.previousSearchValue
        ? state?.previousSearchValue
        : null;
    const [rerender, setRerender] = useState();

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const [searchButtonStatus, setSearchButtonStatusValue] = useState(true);

    const searchFields = {
        name: '',
        fin: '',
        sponsor_company_name: '',
        enrollment_status: '',
        start_date: '',
        end_date: '',
        inv_num: '',
    };
    const [searchValue, setSearchValue] = useState(
        previousSearchValue ? previousSearchValue : searchFields
    );

    const search = (page = 1) => {
        const searchParam = { ...searchValue, ...page };
        setSearchValue(searchParam);
        getEnrolments(searchParam);
    };

    const excel = useCallback(async (searchValue) => {
        try {
            setLoading(true);
            const response = await api.getexcel("tms/get-excel-enrollment-no-payment-statistics", searchValue);
            if(response && response.size !== 0){
                const excelFilename = `List of Students with Payment Pending_${new Date()
                    .toISOString()
                    .slice(0, 10)}`;
                download(response, excelFilename, "application/xlsx");
                setNotif({
                    notifMsg: "Excel exported",
                    open: true,
                    severity: "success"
                });
            }else{
                setNotif({
                    notifMsg: "No data to export",
                    open: true,
                    severity: "danger",
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error.response?.data?.message);
            setData([]);
        }

        setLoading(false);
    }, []);
    
    const getEnrolments = useCallback(async (search) => {
        try {
            setLoading(true);
            const response = await api.get("tms/enrollment-no-payment-statistics", search);
            setData(response);
            setError();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error.response?.data?.message);
            setData([]);
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        getEnrolments(searchValue);
    }, [getEnrolments, rerender]); //  eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state?.open) {
            setNotif({
                notifMsg: state.notifMsg,
                open: state.open,
                severity: state.severity,
            });
        }
        window.history.replaceState(null, "");
    }, [state]); //  eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <Helmet title={`Statistic > ${title}`} />
            <Container fluid className="p-0">
                {permission?.includes(
                                        "PAYMENT-PENDING"
                        ) && (
                        <>
                            <h1 className="h3 mb-3">{title}</h1>
                            <Card>
                                <Card.Header>
                                    {error && (
                                        <Alert className="my-3" variant="danger">
                                            <div className="alert-message">{error}</div>
                                        </Alert>
                                    )}
                                    
                                    <SearchFunc 
                                        setSearchValue={setSearchValue}
                                        searchValue={searchValue}
                                        search={search}
                                        excel={excel}
                                    />
                                </Card.Header>
                                <Card.Body>
                                    {loading && <div>Loading...</div>}
                                    {/* {JSON.stringify(data) } */}
                                    {!loading && data?.data && (
                                        <><Lists
                                            columns={tableColumns}
                                            rawData={data}
                                            searchApi={search}
                                            status={title}
                                            searchValue={searchValue}
                                            rerender={rerender}
                                            setRerender={setRerender}
                                        />
                                        </>
                                    )}
                                </Card.Body>
                            </Card>
                        </>     
                )}
            </Container>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </React.Fragment>
    );
};

export default EnrollmentsNotPaid;