const tableHeader = [
    {
      Header: "Slug",
      accessor: "slug",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Application",
      accessor: "app.name",
    },
    {
      Header: "Actions",
      accessor: "actions"
    }
];

export { tableHeader };
  