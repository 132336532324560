const tableColumns = [
    {
        Header: "S.no",
        width: "60",
        sticky: "left",
    },
    {
        Header: "Actions",
        width: "100",
        sticky: "left",
        accessor: "actions",
    },
    {
        Header: "Invoice No",
        accessor: "number",
        sticky: "left",
        width: "100",
        getProps: () => {
            return {
                style: {
                    whiteSpace: 'nowrap',
                },
            };
        },
    },
    {
        Header: "Invoice Type",
        accessor: "invoice_type",
        sticky: "left",
        width: "120",
    },
    {
        Header: "Company",
        width: "200",
        accessor: "company.name",
        sticky: "left",
    },
    {
        Header: "Course",
        width: "300",
        id: "",
        accessor: (data) => {
          return (
            <div>
                <ul className="list-unstyled">
                    
                    {
                        data?.invoice_courses.map((course, index) => {
                          return <>
                           <li style={{ listStyleType: "none" }}>{course?.course?.name}</li>
                          </>
                          
                        })
                    }
                    
                </ul>
                
            </div>
          );
        },
    },
    {
        Header: "Invoice Amount",
        accessor: "total_amount",
    },
    {
        Header: "Created at",
        width: "165",
        accessor: "date",
    },
    {
        Header: "Sales person",
        accessor: "sales_user.name",
    },
    {
        Header: "Created by",
        accessor: "created_by.name",
    },
    {
        Header: "Remarks",
        accessor: "director_approval_request.remarks",
    },
    
];

export { tableColumns };
