import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Card, Row, Col, Form, Button } from "react-bootstrap";
import api from "../../../service/api";
import { UsersTable } from "./UsersTable";
import { pressEnterToSearch } from "../../../utils/utility";

export const Users = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const app = props.app;
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [filter, setFilter] = useState({
        app_id: app ? app.id : null,
        search: "",
    });
    const getUsersApi = useCallback(async () => {
        const getUsers = async () => {
            setLoading(true);
            const response = await api.get("users", filter);
            setUsers(response.data);
            setLoading(false);
        };
        getUsers();
    }, [filter]);

    useEffect(() => {
        getUsersApi();
    }, [getUsersApi]);

    const search = () => {
        setFilter((prevState) => ({
            ...prevState,
            search: searchValue,
        }));
    };

    return (
        <React.Fragment>
            <Helmet title="Configuration > Users" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Users</h1>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={6}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Control
                                            className="d-inline-block"
                                            type="text"
                                            onChange={(e) =>
                                                setSearchValue(e.target.value)
                                            }
                                            onKeyDown={(e) => pressEnterToSearch(e, search)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Button
                                            variant="success"
                                            className="me-1 mb-1"
                                            onClick={() => search()}
                                        >
                                            Search
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Button
                                    variant="primary"
                                    className="me-1 float-end"
                                    onClick={() =>
                                        navigate(location.pathname + "/add", {
                                            state: {
                                                app_id: app?.id,
                                            },
                                        })
                                    }
                                >
                                    Add user
                                </Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        {loading && <div>Loading...</div>}
                        {!loading && (
                            <UsersTable users={users} app_id={app?.id} getUsersApi={getUsersApi}/>
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};
