import React, { useEffect, useContext, useState, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";
import { ReceivedPaymentListing } from "./ReceivedPaymentListing";
import { tableColumns } from "./tableDataReceivedPayment.js";
import { ActionRouteContext } from "../Documents/Documents";
import api from "../../../service/api";
import { LoadingContext } from "../../../App.js";
import { AddEditReceivedPayment } from "./AddEditReceivedPayment";
import { ViewReceivedPayment } from "./ViewReceivedPayment";

export const ReceivedPayment = ({ project }) => {
    //
    // States
    //
    const [receivedPayment, setReceivedPayment] = useState([]);
    const action = useContext(ActionRouteContext);
    const setIsLoadingActive = useContext(LoadingContext);

    //
    // Functions
    //

    const getReceivedPayments = useCallback(
        async (param = null) => {
            const response = await api.get("received-payments", { ...param });

            if (response) {
                setReceivedPayment(response);
                setIsLoadingActive(false);
            }
        },
        [setIsLoadingActive]
    );

    //
    // useEffects
    //

    useEffect(() => {
        setIsLoadingActive(true);
        getReceivedPayments();
    }, [getReceivedPayments, setIsLoadingActive]);

    return (
        <React.Fragment>
            {project && (
                <Helmet
                    title={`${project.name} > Received Payment`}
                />
            )}
            <Container fluid className="p-0">
                {
                    // Expense View List
                    action === "receivedPayment" &&
                    receivedPayment &&
                    receivedPayment.data ? (
                        <ReceivedPaymentListing
                            module="received payments"
                            columns={tableColumns}
                            rawData={receivedPayment}
                            parentApi={getReceivedPayments}
                        />
                    ) : // Create Edit Received Payment
                    action === "addEditReceivedPayment" ? (
                        <AddEditReceivedPayment project={project} />
                    ) : (
                        <ViewReceivedPayment project={project} />
                    )
                }
            </Container>
        </React.Fragment>
    );
};
