import React, { useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
// import { useNavigate, useParams } from "react-router-dom";
import { Table, Row, Col, Alert, Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";

import api from "../../../../service/api";

import {
    formatNumberWithCommas, listTableNumberFormat,
} from "../../../../utils/utility";
import { SnackbarAlert } from "../../../../components/SnackbarAlert";
import { DollarSign, FileText, Printer } from "react-feather";
import { Styles } from "./Styles";
import download from "downloadjs";

const Lists = ({ columns, rawData, searchApi, status, permission }) => {
    const moneyColumns = ["total", "course_fee", "registration_fee", 'discount'];
    const noWrapColumns = ['course_name', 'sales_person_name', 'customer_name', 'date', 'created_at', 'location'];

    // const navigate = useNavigate();
    // const params = useParams();
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });

    const [cloneModalInfo, setCloneModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "primary",
    })

    const { meta, links, data } = rawData;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: {
            pageSize,
            canNextPage,
            canPreviousPage,
            totalPage,
            currentPage,
            firstPage,
            lastPage,
            total,
        },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: meta?.per_page,
                canNextPage: links?.next !== null ? true : false,
                canPreviousPage: links?.prev !== null ? true : false,
                totalPage: meta?.last_page,
                currentPage: meta?.current_page,
                firstPage: meta?.first,
                lastPage: meta?.last_page,
                total: meta?.total
            },
        },
        useSortBy,
        usePagination
    );
    const gotoPage = (page) => {
        searchApi({
            page,
        });
    };

    // const openDeleteModal = (id) => {
    //     setModalInfo({
    //         id: id,
    //         notifMsg: "Are you sure you want to delete this item?",
    //         open: true,
    //         severity: "danger",
    //     });
    // };

    const generatePdf = async (id, roadshow_number, directDownload = true) => {
        try {
            const response = await api.getpdf("tms/roadshow/generate-pdf/" + id);
            if (response) {
                console.log("GENERATION response: ", response);
                if (directDownload) {
                    return download(response, "roadshow-receipt_" + roadshow_number, "application/pdf");
                }

                const blob = new Blob([response], {type: 'application/pdf'}); //this make the magic
                const blobURL = URL.createObjectURL(blob);

                const iframe =  document.createElement('iframe'); //load content in an iframe to print later
                document.body.appendChild(iframe);

                iframe.style.display = 'none';
                iframe.src = blobURL;
                iframe.onload = function() {
                    setTimeout(function() {
                        iframe.focus();
                        iframe.contentWindow.print();
                    }, 1);
                };

                // const file = new Blob([response], { type: "application/pdf" });
                // //Build a URL from the file
                // const fileURL = URL.createObjectURL(file);
                // //Open the URL on new Window
                // const pdfWindow = window.open();
                // pdfWindow.location.href = fileURL; 
            }
        } catch (error) {
            setNotif({
                notifMsg: error?.response?.data?.message ?? "Something went wrong with generating PDF.",
                open: true,
                severity: "danger",
            });
        }
    };

    return (
        <>
            {data.length > 0 && (
                <Row>
                    {totalPage > 1 && (
                        <Row>
                            <Col md="6">
                                <span className="mx-2">
                                    Page {""}
                                    <strong>
                                        {currentPage} of {totalPage}
                                    </strong>
                                </span>
                            </Col>
                            <Col md="6">
                                <Pagination className="float-end">
                                    <Pagination.First
                                        onClick={() => gotoPage(firstPage)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPage(currentPage - 1)
                                        }
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Item >{ data?.length }</Pagination.Item>
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPage(currentPage + 1)
                                        }
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(lastPage)}
                                        disabled={!canNextPage}
                                    />
                                </Pagination>
                            </Col>
                        </Row>
                    )}
                    <Styles>
                        <Table responsive striped bordered {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th
                                                {...column.getHeaderProps(
                                                    column.getSortByToggleProps()
                                                )}
                                            >
                                                {column.render("Header")}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell, index, row) => {
                                                if (index === 0) {
                                                    return (
                                                        <td key={index}>
                                                            {currentPage > 1
                                                                ? (currentPage -
                                                                    1) *
                                                                    pageSize +
                                                                i +
                                                                1
                                                                : i + 1}
                                                        </td>
                                                    );
                                                }
                                                if (moneyColumns.includes(cell.column.id)) {
                                                    return(listTableNumberFormat(cell, cell.value ?? 0));
                                                }
                                                if (index + 1 === 2) {
                                                    return (
                                                        <td key={index} className="text-nowrap">
                                                            {/* List action buttons here */}
                                                            {permission.includes('ROADSHOW-CREATE-PAYMENT') && (
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip>Payments</Tooltip>}
                                                                >
                                                                    <span>
                                                                        <a
                                                                            href={`/tms/roadshow/payments/${cell.row.original.id}`}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            <span>
                                                                                <DollarSign className="me-1" size="20" color="#6c757d" />
                                                                            </span>
                                                                        </a>
                                                                    </span>
                                                                </OverlayTrigger>
                                                            )}

                                                            {permission.includes('ROADSHOW-GENERATE-PDF') && (
                                                                <>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip>Download receipt</Tooltip>}
                                                                    >
                                                                        <FileText 
                                                                            size="20"
                                                                            className="me-1"
                                                                            color="#6c757d" 
                                                                            onClick={() => generatePdf(cell.row.original.id, cell.row.original.number)}
                                                                        />
                                                                    </OverlayTrigger>

                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip>Print receipt</Tooltip>}
                                                                    >
                                                                        <Printer 
                                                                            size="20"
                                                                            className="me-1"
                                                                            color="#6c757d" 
                                                                            onClick={() => generatePdf(cell.row.original.id, cell.row.original.number, false)}
                                                                        />
                                                                    </OverlayTrigger>
                                                                </>
                                                            )}
                                                        </td>
                                                    );
                                                }
                                                return (
                                                    <td {...cell.getCellProps()} className={noWrapColumns.includes(cell.column.id ) ? 'text-nowrap' : ''}>
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Styles>
                    {totalPage > 1 && (
                        <Row className="mt-2">
                            <Col md="6">
                                <span className="mx-2">
                                    Page {""}
                                    <strong>
                                        {currentPage} of {totalPage}
                                    </strong>
                                </span>
                            </Col>
                            <Col md="6">
                                <Pagination className="float-end">
                                    <Pagination.First
                                        onClick={() => gotoPage(firstPage)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPage(currentPage - 1)
                                        }
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Item >{ data?.length }</Pagination.Item>
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPage(currentPage + 1)
                                        }
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(lastPage)}
                                        disabled={!canNextPage}
                                    />
                                </Pagination>
                            </Col>
                        </Row>
                    )}
                </Row>
            )}
            {data.length === 0 && (
                <Alert variant="warning">
                    <div className="alert-message">No roadshow data</div>
                </Alert>
            )}
            {/* <DeleteModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={deleteCompany}
            />
            <CloneModal
                modalInfo={cloneModalInfo}
                setModalInfo={setCloneModalInfo}
            /> */}
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export { Lists };
