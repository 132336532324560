import React, { useEffect } from "react";
import { useTable, useRowSelect } from "react-table";
import { Table } from "react-bootstrap";
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        );
    }
);
const StudentList = ({ data, columns, setSelectedUser, invoice }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data,
        },
        useRowSelect,

        (hooks) => {
                hooks.visibleColumns.push((columns) => [
                    {
                        id: "selection",
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <div>
                                <IndeterminateCheckbox
                                    {...getToggleAllRowsSelectedProps()}
                                />
                            </div>
                        ),
                        Cell: ({ row }) => (
                            <div>
                                {!row.isDisabled && (
                                    <IndeterminateCheckbox
                                        {...row.getToggleRowSelectedProps()}
                                    />
                                )}
                            </div>
                        ),
                    },
                    ...columns,
                ]);
    
                hooks.prepareRow.push((row, { instance }) => {
                    row.isDisabled = hasPendingOrApprovedCN(row);
                });
        }
    );
    
    const hasPendingOrApprovedCN = (row) => {
        // console.log("INVOICE STUDENTS: ", data);
        if (invoice?.credit_notes?.length > 0) {
            const isDisabled = invoice.credit_notes.some((item) => {
                // When there is a pending or approved CN for cancellation
                // of the course; by right we cannot select any student for a new CN
                if (
                    (item.type.toLowerCase() === 'cancel-course' || item.type.toLowerCase() === 'cancel-invoice') && 
                    (item.status_key.toLowerCase() == 'pending' || item.status_key.toLowerCase() === 'approved')
                ){
                    return true;
                }

                if (
                    (item.type.toLowerCase() === 'withdraw-student'|| item.type.toLowerCase === 'cancellation') &&
                    (item.status_key.toLowerCase() === 'pending' || item.status_key.toLowerCase() === 'approved')
                ){
                    return item.invoice_course_students?.invoice_course_student_ids?.includes(row.original.id);
                }
        
                return false;
            });

            return isDisabled;
        }
        
        return false;
    }

    useEffect(() => {
        const filteredFlatRows = selectedFlatRows.filter((element) => {
            if (!element.isDisabled)
                return element;
        })
        setSelectedUser(filteredFlatRows);
        // console.log("selected flat rows", selectedFlatRows);
    }, [selectedFlatRows, setSelectedUser]);
    return (
        <>
            {data.length > 0 && (
                <Table responsive striped bordered {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            )}
        </>
    );
};
export default StudentList;
