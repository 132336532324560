import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import SearchStatistic from "../CommonComponents/SearchStatistic";
import { Card, Container, Alert, Button, Col, Row } from "react-bootstrap";
import { tableColumns } from "./SalesRevenue/Data.js";
import { SnackbarAlert } from "../../../components/SnackbarAlert";
import api from "../../../service/api";
import { useSelector } from "react-redux";
import { Plus } from "react-feather";
import { Lists } from "./SalesRevenue/ListSalesRevenue";

const SalesRevenue = ({ status, title }) => {
    /** Permissions */
    const permissionData = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = permissionData?.data?.permissions;

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [defaultConfig, setDefaultConfig] = useState();
    const { state } = useLocation();
    const previousSearchValue = state?.previousSearchValue
        ? state?.previousSearchValue
        : null;
    const [rerender, setRerender] = useState();

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const searchFields = {
        start_date: "",
        end_date: "",
        salesperson: ""
    };
    const [searchValue, setSearchValue] = useState(
        previousSearchValue ? previousSearchValue : searchFields
    );
    const search = (page) => {
        const searchParam = { ...searchValue, ...page };
        setSearchValue(searchParam);
        getEnrolments(searchParam);
    };
    const resetSearch = () => {
        setSearchValue(searchFields);
        getEnrolments(searchFields);
    };
    const getEnrolments = useCallback(async (search) => {
        if(search.end_date === '' && search.start_date !== ''){
            setError('Select end date')
        }else{
            setError(null)
            setLoading(true);
            try {
                // console.log(search);return;
                // const response = await api.get("tms/enrolments", search);
                const response = await api.get("tms/invoice/get-salesperson-summary", search);
                // console.log(response)
                setData(response);
                setError();
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setError(error.message);
                setData([]);
            }

            setLoading(false);
        }
    }, []);

    //
    // useEffects
    //

    useEffect(() => {
        getEnrolments(searchValue);
    }, [getEnrolments, rerender]); //  eslint-disable-line react-hooks/exhaustive-deps
    const getConfig = useCallback(async () => {
        const configResponse = await api.get(`tms/enrolments/config`, {});
        setDefaultConfig(configResponse.data);
    }, []);
    useEffect(() => {
        getConfig();
    }, [getConfig]); //  eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state?.open) {
            setNotif({
                notifMsg: state.notifMsg,
                open: state.open,
                severity: state.severity,
            });
        }
        window.history.replaceState(null, "");
    }, [state]); //  eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <Helmet title="Statistic > Sales revenue" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Sales revenue</h1>
                <Card>
                    <Card.Header>
                        {error && (
                            <Alert className="my-3" variant="danger">
                                <div className="alert-message">{error}</div>
                            </Alert>
                        )}
                        <Row>
                            <Col md={12}>
                                <SearchStatistic
                                    search={search}
                                    setSearchValue={setSearchValue}
                                    searchValue={searchValue}
                                    defaultConfig={defaultConfig}
                                    resetSearch={resetSearch}
                                    attcTestDate
                                />
                            </Col>
                        </Row>
                        
                    </Card.Header>
                    <Card.Body>
                        {loading && <div>Loading...</div>}
                        {/* {JSON.stringify(data) } */}
                        {!loading && data?.data && (
                            <Lists
                                columns={tableColumns}
                                rawData={data?.data}
                                enrolmentApi={search}
                                status={status}
                                searchValue={searchValue}
                                rerender={rerender}
                                setRerender={setRerender}
                            />
                            
                        )}
                    </Card.Body>
                </Card>
            </Container>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </React.Fragment>
    );
};

export default SalesRevenue;
