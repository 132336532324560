import { Alert } from "bootstrap";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { ArrowLeft } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../service/api";

const CompanyDetails = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [error, setError] = useState();
    const [hasAddtlAddress, setHasAddtlAddress] = useState([])

    const getCompany = async (id) => {
        try {
            setLoading(true);
            const response = await api.get(`tms/companies/${id}`, {});
            setData(response?.data);
            checkHasAddtlAddress(response?.data)
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error?.response?.data?.message);
        }
    };

    const checkHasAddtlAddress = async (data) => {
        if(data.addtnl_address?.length > 0){
            const default_address = data.addtnl_address.filter(function (add){
                if(add.default === false){
                    return add
                }
            });
            if(default_address && default_address.length > 0){
                setHasAddtlAddress(default_address)
            }
        }
    }

    useEffect(() => {
        getCompany(id);
    }, [id]); //  eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>
            <Card>
                <Card.Header>
                    {error && (
                        <Alert className="my-3" variant="danger">
                            <div className="alert-message">{error}</div>
                        </Alert>
                    )}
                    {data && !loading && (
                        <Row>
                            <Col md={6}>
                                <h2>{data.name}</h2>
                                <h5>UEN: {data.uen}</h5>
                                <h5>Customer code: {data.customer_code}</h5>
                                {data.user? (
                                    <h5>Last Modified By: {data.user.name}</h5>
                                    ) : (
                                    <h5>No Modification</h5>
                                )}

                            </Col>
                            <Col md={6}>
                                <Button
                                    variant="info"
                                    className="me-1 mb-1 float-end"
                                    onClick={() => {
                                        return navigate("/tms/companies");
                                    }}
                                >
                                    <ArrowLeft size={15} />
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Card.Header>
                <hr className="mt-0 mb-0" />
                <Card.Body>
                    {loading && <div>Loading...</div>}
                    {data && !loading && (
                        <>
                            {/* <h5 className="mb-3">Address</h5> */}
                            <Row>
                                <Col md={12}>
                                    <Row>
                                        {/* <Col md={2}>Full address: </Col> */}
                                        <Col md={12}>
                                            <b>Full address:</b>
                                            {" "}
                                            {data.full_address?.replace(/\n/g, ' ')}
                                        </Col>
                                    </Row>
                                    {hasAddtlAddress.length > 0 && !loading && (
                                        <Row>
                                            {/* <Col md={2}>Full address: </Col> */}
                                            <Col md={12}>
                                                <b>Additional address:</b>
                                                {data.addtnl_address.map(function(d){
                                                    if(!d?.default){
                                                        return (
                                                            <Col md={12}>
                                                                {d.address?.replace(/\n/g, ' ') ?? ''}
                                                            </Col>
                                                        )
                                                    }
                                                })} 
                                            </Col>
                                            {/* {data.addtnl_address.slice(1).map(function(d){
                                                return (
                                                    <Col md={12}>
                                                        {d.address.replace(/\n/g, ' ')}
                                                    </Col>
                                                )
                                            })} */}
                                            
                                        </Row>
                                    )}
                                    
                                    {/* <Row>
                                        <Col md={2}>Block/street </Col>
                                        <Col md={2}>
                                            {" "}
                                            {data.address?.blockOrStreetName}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>building/house </Col>
                                        <Col md={2}>
                                            {" "}
                                            {
                                                data.address
                                                    ?.buildingOrHouseNumber
                                            }
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={2}>Postal code </Col>
                                        <Col md={2}>
                                            {" "}
                                            {data.address?.postalCode}
                                        </Col>
                                    </Row> */}
                                </Col>
                            </Row>
                            <h5 className="mb-3 mt-3"><b>Contacts</b></h5>
                            {data.contacts && (
                                <table
                                    role="table"
                                    className="table table-striped table-bordered w-75 table-responsive"
                                >
                                    <tbody>
                                        <tr>
                                            <td className="py-3">Name</td>
                                            <td className="py-3">Email</td>
                                            <td className="py-3">
                                                Contact number
                                            </td>
                                            <td className="py-3">Fax</td>
                                            <td className="py-3">ATO</td>
                                            <td className="py-3">ATTC</td>
                                        </tr>

                                        {data.contacts?.map((contacts, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="py-3">
                                                        {" "}
                                                        {contacts.name}{" "}
                                                    </td>
                                                    <td className="py-3">
                                                        {" "}
                                                        {contacts.email}
                                                    </td>
                                                    <td className="py-3">
                                                        {" "}
                                                        {
                                                            contacts?.contact_number
                                                        }
                                                    </td>
                                                    <td className="py-3">
                                                        {" "}
                                                        {contacts?.fax}
                                                    </td>
                                                    <td className="py-3">
                                                        {" "}
                                                        {contacts?.ato ===
                                                            true && "Yes"}
                                                        {contacts?.ato ===
                                                            false && "-"}
                                                    </td>
                                                    <td className="py-3">
                                                        {contacts?.attc ===
                                                            true && "Yes"}
                                                        {contacts?.attc ===
                                                            false && "-"}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )}

                            <h5 className="mb-3 mt-3"><b>Sales person</b></h5>
                            {data.sales_persons && (
                                <table
                                    role="table"
                                    className="table table-striped table-bordered w-75 table-responsive"
                                >
                                    <tbody>
                                        <tr>
                                            <td className="py-3">Name</td>

                                            <td className="py-3">ATO</td>
                                            <td className="py-3">ATTC</td>
                                            <td className="py-3">Created at</td>
                                            <td className="py-3">Person In Charge</td>
                                        </tr>

                                        {data.sales_persons?.map(
                                            (salesPerson, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className="py-3">
                                                            {
                                                                salesPerson.user
                                                                    ?.full_name
                                                            }
                                                        </td>
                                                        <td className="py-3">
                                                            {" "}
                                                            {salesPerson?.ato ===
                                                                true && "Yes"}
                                                            {salesPerson?.ato ===
                                                                false && "-"}
                                                        </td>
                                                        <td className="py-3">
                                                            {salesPerson?.attc ===
                                                                true && "Yes"}
                                                            {salesPerson?.attc ===
                                                                false && "-"}
                                                        </td>
                                                        <td className="py-3">
                                                                {salesPerson?.created_at}
                                                        </td>
                                                        <td className="py-3">
                                                            {salesPerson?.person_in_charge ===
                                                                    true && "Yes"}
                                                                {salesPerson?.person_in_charge ===
                                                                    false && "-"}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            )}
                        </>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
};
export default CompanyDetails;
