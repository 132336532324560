import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";

import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { AutoGrowTextareaV2 } from "./version-two-components/AutoGrowTextareaV2";
import * as yup from "yup";

export const WithdrawModal = ({ modalInfo, setModalInfo, api }) => {
    const closeModal = () => {
        setModalInfo({
            ...modalInfo,
            open: false,
        });
    };

    const callApi = (data) => {
        api(modalInfo.id, data, modalInfo?.fromSummary);
        closeModal();
    };

    const schema = yup.object().shape({
        remarks: yup.string().required("This field is required"),
    });

    const {
        control,
        formState: { errors },
        handleSubmit
    } = useForm({
        resolver: yupResolver(schema),
    });

    return (
        <Modal
            show={modalInfo.open}
            onHide={() => closeModal()}
            size="sm"
            centered
        >
            <Modal.Body className="text-center m-3">
            <Row>
                <Col md={12}>
                    <Form.Group className="mb-3">
                        <Form.Label
                            htmlFor="taskName"
                            className="font-weight-bold"
                        >
                            Withdrawal remarks
                        </Form.Label>

                        <Controller
                            control={control}
                            defaultValue=""
                            name="remarks"
                            render={({
                                field: { value, onChange, onBlur },
                            }) => (
                                <AutoGrowTextareaV2
                                    fieldValue={value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    className="new-line"
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="remarks"
                            render={({ message }) => (
                                <small className="text-danger">
                                    {message}
                                </small>
                            )}
                        />
                    </Form.Group>
                </Col>
            </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => closeModal()}>
                    Close
                </Button>{" "}
                <Button variant={modalInfo.severity} onClick={handleSubmit(callApi)}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
