import React, { useEffect } from "react";

import { Helmet } from "react-helmet-async";
import { Card, Col, Row } from "react-bootstrap";
import SignIn from "../../components/auth/SignIn";
import Logo from "../../assets/img/fonda-logo.png";
import IntelliSolution from "../../assets/img/intellisolution.png";

import CookieService from "../../service/CookieService";
import { useNavigate } from "react-router-dom";

const SignInPage = () => {
    const navigate = useNavigate();
    const accessToken = CookieService.get("authToken");

    useEffect(() => {
        if (accessToken) navigate("/");
    }, [accessToken, navigate]);

    return (
        <React.Fragment>
            <Helmet title="Sign In" />
            <Card>
                <Card.Body>
                    <div className="m-sm-4">
                        <div className="text-center">
                            <Row className="justify-content-md-center">
                                <Col md={2}>
                                    <img
                                        src={Logo}
                                        alt="Moe Logo"
                                        style={{
                                            maxWidth: "110px",
                                        }}
                                    />
                                </Col>
                                <Col md={6}>
                                    <p
                                        style={{ fontSize: "19px" }}
                                        className="p-2 mb-0 mt-2 font-weight-bold"
                                    >
                                        Fonda Global Engineering Pte. Ltd.
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <SignIn />
                        <div className="text-left mt-1">
                            Empowered by Intellect Solution
                            <br />
                            <img
                                src={IntelliSolution}
                                alt="Intelli solution Logo"
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </React.Fragment>
    );
};

export default SignInPage;
