const tableColumns = [
  {
    Header: "S.no",
  },
  {
    Header: "Actions",
    // accessor: "registration_fee",
  },
  {
    Header: "Sales person name",
    accessor: "sales_person_name",
  },
  {
    Header: "Department",
    accessor: "department",
  },
  {
    Header: "Customer name",
    accessor: "customer_name",
  },
  {
    Header: "Customer FIN",
    accessor: "customer_fin",
  },
  {
    Header: "Item code",
    accessor: "item_code",
  },
  {
    Header: "Item type",
    accessor: "item_type",
  },
  {
    Header: "Course name",
    accessor: "course_name",
  },
  {
    Header: "Type of trade",
    accessor: "type_of_trade",
  },
  {
    Header: "Application Option",
    accessor: "application_option",
  },
  {
    Header: "Trade Category",
    accessor: "trade_category",
  },
  {
    Header: "Location",
    accessor: "location",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Record number",
    accessor: "number",
  },
  {
    Header: "Course fee",
    accessor: "course_fee",
  },
  {
    Header: "Registration fee",
    class: "text-noWrap",
    accessor: "registration_fee",
  },
  {
    Header: "Discount",
    accessor: "discount",
  },
//   {
//     Header: "Total amount",
//     accessor: "total",
//   },
//   {
//     Header: "Created at",
//     accessor: "created_at",
//   },
];

export { tableColumns };
