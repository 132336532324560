import { Alert } from "bootstrap";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import FormRow from "./FormRow";
import api from "../../../../service/api";

const AddEditRoadshow = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [courseFee, setCourseFee] = useState();

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const [configValues, setConfigValues] = useState({
        // tradeOptions: [],
        tradeOptions: [],
        // tradeApplication: [],
        form_courses: [],
        form_item_codes: [],
        // companies: [],
        departments: [],
        location: [],
    });

    /** use effect */
    useEffect(() => {
        const getConfig = async () => {
            try {
                setLoading(true);
                const configResponse = await api.get(
                    `tms/roadshow/config`,
                    {}
                );
                const courseResponse = await api.get(`tms/roadshow/get/selections`, {});
                // const courseFeeResponse = await api.get("tms/get_course_fee");
                // setCourseFee(courseFeeResponse.data);
                setCourseData(courseResponse?.data);
                setConfigValues({ ...configValues, ...configResponse.data });
                setLoading(false);
            } catch (error) {
                setError(error.message);
            }
        };
        // if (id && id !== "add") {
        //     const getQuotation = async () => {
        //         try {
        //             setLoading(true);
        //             const response = await api.get(
        //                 `tms/sales-quotations/${id}`,
        //                 {}
        //             );
        //             setQuotation(response.data);
        //             setLoading(false);
        //         } catch (error) {}
        //     };
        //     getQuotation();
        // }
        getConfig();
    }, [id]);

    const setCourseData = (data) => {
        const formatAto = data.courses
            // .filter((data, index) => {
            //     return data?.type?.toUpperCase() === "ATO";
            // })
            .map((data, index) => {
                return { value: data.id, label: data.name };
            });
        const formatAttc = data.item_codes
            // .filter((data, index) => {
            //     return data?.type?.toUpperCase() === "ATTC";
            // })
            // .map((data, index) => {
            //     return { value: data.id, label: data.name };
            // });
            .map((data, index) => {
                return { value: data.id, label: data.course_code, fee: data.fee, type: data?.type };
            });
        const { form_courses, form_item_codes } = { ...configValues };
        form_courses.push(...formatAto);
        form_item_codes.push(...formatAttc);
        setConfigValues({ form_courses, form_item_codes });
    };

    const helmetTitle = `${id !== "add" ? "Edit" : "Create"} roadshow record`;
    return (
        <React.Fragment>
            <Helmet title={"Roadshow > " + helmetTitle} />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">
                    {helmetTitle}
                </h1>
                <Card className="m-0 p-0">
                    <Card.Body>
                        {error && (
                            <Alert className="my-3" variant="danger">
                                <div className="alert-message">{error}</div>
                            </Alert>
                        )}
                        {loading && <div>Loading...</div>}
                        {!loading && (
                            <FormRow
                                configValues={configValues}
                                // quotation={quotation}
                                // courseFee={courseFee}
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

export default AddEditRoadshow;