import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import BatchFormRow from "./BatchFormRow";

const AddEditBatch = () => {
  let { id } = useParams();
  const helmetTitle = `${id === "add" ? "Add" : "Edit"} class`;
  return (
    <React.Fragment>
      <Helmet title={helmetTitle} />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{helmetTitle}</h1>

        <Row>
          <Col lg="12">
            <BatchFormRow />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AddEditBatch;
