import React from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { DollarSign } from "react-feather";
import { convertToDecimalWithComma } from "../../../utils/utility";

export const RevenueSection = ({ dashboardDetails }) => {
    return (
        <Row>
            <Col md="6" xl className="d-flex">
                <Card className="illustration flex-fill">
                    <Card.Body className="p-0 d-flex flex-fill">
                        <Row className="g-0 w-100">
                            <Col xs="12">
                                <div className="illustration-text p-3 m-1">
                                    <h4 className="illustration-text">
                                        Revenue
                                    </h4>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col md="6" xl className="d-flex">
                <Card className="flex-fill">
                    <Card.Body className=" py-4">
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <h3 className="mb-2">
                                    $
                                    {dashboardDetails.total_revenue
                                        ? convertToDecimalWithComma(
                                              dashboardDetails.total_revenue
                                          )
                                        : 0}
                                </h3>
                                <p className="mb-2">Total revenue</p>
                                <div className="mb-0">
                                    <Badge
                                        bg=""
                                        className={`me-2
                                         ${
                                             dashboardDetails.revenue_percent_difference <
                                             0
                                                 ? "badge-soft-danger"
                                                 : !dashboardDetails.revenue_percent_difference
                                                 ? "badge-soft-secondary"
                                                 : "badge-soft-success"
                                         }`}
                                    >
                                        {dashboardDetails.revenue_percent_difference >
                                            0 && "+"}
                                        {dashboardDetails.revenue_percent_difference ||
                                            0}
                                        %
                                    </Badge>
                                    <span className="text-muted">
                                        Since previous month
                                    </span>
                                </div>
                            </div>
                            <div className="d-inline-block ms-3">
                                <div className="stat">
                                    <DollarSign className="align-middle text-success" />
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col md="6" xl className="d-flex">
                <Card className="flex-fill">
                    <Card.Body className=" py-4">
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <h3 className="mb-2">
                                    $
                                    {dashboardDetails.total_paid
                                        ? convertToDecimalWithComma(
                                              dashboardDetails.total_paid
                                          )
                                        : 0}
                                </h3>
                                <p className="mb-2">Paid revenue</p>
                                <div className="mb-0">
                                    <Badge
                                        bg=""
                                        className={`me-2
                                         ${
                                             dashboardDetails.total_paid_percent_difference <
                                             0
                                                 ? "badge-soft-danger"
                                                 : !dashboardDetails.total_paid_percent_difference
                                                 ? "badge-soft-secondary"
                                                 : "badge-soft-success"
                                         }`}
                                    >
                                        {dashboardDetails.total_paid_percent_difference >
                                            0 && "+"}
                                        {dashboardDetails.total_paid_percent_difference ||
                                            0}
                                        %
                                    </Badge>
                                    <span className="text-muted">
                                        Since previous month
                                    </span>
                                </div>
                            </div>
                            <div className="d-inline-block ms-3">
                                <div className="stat">
                                    <DollarSign className="align-middle text-success" />
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col md="6" xl className="d-flex">
                <Card className="flex-fill">
                    <Card.Body className=" py-4">
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <h3 className="mb-2">
                                    $
                                    {dashboardDetails.total_unpaid
                                        ? convertToDecimalWithComma(
                                              dashboardDetails.total_unpaid
                                          )
                                        : 0}
                                </h3>
                                <p className="mb-2">Unclaimed revenue</p>
                                {/* <div className="mb-0">
                                    <Badge
                                        bg=""
                                        className={`me-2
                                         ${
                                             dashboardDetails.total_unpaid_percent_difference <
                                             0
                                                 ? "badge-soft-danger"
                                                 : !dashboardDetails.total_unpaid_percent_difference
                                                 ? "badge-soft-secondary"
                                                 : "badge-soft-success"
                                         }`}
                                    >
                                        {dashboardDetails.total_unpaid_percent_difference >
                                            0 && "+"}
                                        {dashboardDetails.total_unpaid_percent_difference ||
                                            0}
                                        %
                                    </Badge>
                                    <span className="text-muted">
                                        Since previous month
                                    </span>
                                </div> */}
                            </div>
                            <div className="d-inline-block ms-3">
                                <div className="stat">
                                    <DollarSign className="align-middle text-success" />
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};
