import { Formik } from "formik";
import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { SnackbarAlert } from "../../components/SnackbarAlert.js";
import api from "../../service/api";
import { useActions } from "../../hooks/useAction";

const Settings = (props) => {
    const { data } = props;
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    
    delete data?.setting?.date_javascript
    const [inputValues, setInputValues] = useState({
        ...data.setting
    })
    let params = {};
    const { userDetails } = useActions();

    const submitForm = (values, { setErrors }) => {
        params.setting = inputValues;

        api.put(`users/${data?.id}`, params)
            .then((response) => {
                if (!response.success) {
                    setNotif({
                        notifMsg: response.message,
                        open: true,
                        severity: "danger",
                    });
                }
                if (response.status === "ok") {
                    setNotif({
                        notifMsg: "Successfully saved",
                        open: true,
                        severity: "success",
                    });

                    updateUserDetails();
                }
            })
            .catch((error) => {
                setNotif({
                    notifMsg: error.message,
                    open: true,
                    severity: "danger",
                });
            });
    };

    const updateUserDetails = async () => {
        const userResponse = await api.get("users/verification", {});
        userDetails(userResponse.data);
    };

    return (
        <>
            <Container>
                <Card>
                    <Card.Header>
                        <Card.Title className="mb-0">Settings</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Formik
                            initialValues={{
                                setting: inputValues,
                            }}
                            enableReinitialize
                            onSubmit={(values, { setErrors }) =>
                                submitForm(values, { setErrors })
                            }
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                touched,
                                values,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Date</Form.Label>
                                                <br />
                                                <Form.Check
                                                    className="mb-2"
                                                    inline
                                                    label="dd-MM-yyyy"
                                                    name="setting"
                                                    onChange={(data) => {
                                                        setInputValues({
                                                            ...inputValues,
                                                            date: data.target.value
                                                        })
                                                    }}
                                                    type="radio"
                                                    value="d-m-Y"
                                                    checked={
                                                        values?.setting.date ===
                                                        "d-m-Y"
                                                    }
                                                />
                                                <Form.Check
                                                    checked={
                                                        values?.setting.date ===
                                                        "Y-m-d"
                                                    }
                                                    className="mb-2"
                                                    inline
                                                    label="yyyy-MM-dd"
                                                    name="setting"
                                                    onChange={(data) => {
                                                        setInputValues({
                                                            ...inputValues,
                                                            date: data.target.value
                                                        })
                                                    }}
                                                    type="radio"
                                                    value="Y-m-d"
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Default homepage</Form.Label>
                                                <Form.Control
                                                    name="default_homepage"
                                                    type="text"
                                                    onInput={(data) => {
                                                        setInputValues({
                                                            ...inputValues,
                                                            default_homepage: data.target.value
                                                        })
                                                    }}
                                                    defaultValue={values?.setting?.default_homepage}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <div className="d-flex flex-row align-items-center p-2">
                                                <Button
                                                    variant="primary"
                                                    size="lg"
                                                    type="submit"
                                                    className="col-md-3 "
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Card.Body>
                </Card>
            </Container>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export default Settings;
