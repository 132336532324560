import React, { useState, useEffect } from "react";
import { Alert, Button, Card, Form } from "react-bootstrap";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import api from "../../../service/api";
import { AddEditRevenueDetails } from "./AddEditRevenueDetails";
import { AddEditRevenueItems } from "./AddEditRevenueItems";
import { AddEditBilling } from "./AddEditBilling";

export const AddEditRevenue = ({ project }) => {
    const navigate = useNavigate();
    let { id, action } = useParams();

    const schema = Yup.object().shape({
        revenue_date: Yup.string().required(),
        revenue_to: Yup.string().required(),
        revenue_from: Yup.string().required(),
        total: Yup.string().required(),
        is_recurring: Yup.string(),
        interval: Yup.string()
            .nullable()
            .when("is_recurring", {
                is: "Yes",
                then: (schema) => schema.required(),
            }),
        start_date: Yup.string()
            .nullable()
            .when("is_recurring", {
                is: "Yes",
                then: (schema) => schema.required(),
            }),
        end_date: Yup.string()
            .nullable()
            .when("is_recurring", {
                is: "Yes",
                then: (schema) => schema.required(),
            }),
        revenue_items: Yup.array().of(
            Yup.object().shape({
                item: Yup.string().required(),
                description: Yup.string().required(),
                quantity: Yup.string().required(),
                rate: Yup.string().required(),
                amount: Yup.string().required(),
            })
        ),
    });

    //
    // States
    //

    const [editExpenses, setEditExpenses] = useState();
    // eslint-disable-next-line no-unused-vars
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [inputFields, setInputFields] = useState({
        revenue_date: "",
        revenue_to: "fonda",
        revenue_from: "intellect",
        gst: "7",
        discount: 0,
        subtotal: 0,
        total: 0,
        is_recurring: "Yes",
        interval: "",
        start_date: "",
        end_date: "",
        revenue_items: [
            {
                item: "",
                description: "",
                quantity: "",
                rate: "",
                amount: 0,
            },
        ],
    });

    //
    // Functions
    //

    const addFields = () => {
        let newfield = {
            item: "",
            description: "",
            quantity: "",
            rate: "",
            amount: 0,
        };

        setInputFields({
            ...inputFields,
            revenue_items: [...inputFields.revenue_items, newfield],
        });
    };

    const removeFields = (index) => {
        let data = [...inputFields.revenue_items];
        data.splice(index, 1);

        calculateRemovedFields(data);

        setInputFields({
            ...inputFields,
            revenue_items: data,
        });
    };

    const handleFormChangeItems = (index, event) => {
        let data = [...inputFields.revenue_items];
        data[index][event.target.name] = event.target.value;

        calculateTotalAmount(index, event);

        setInputFields({
            ...inputFields,
            revenue_items: data,
        });
    };

    // const handleFormChangeDetails = (event) => {
    //     setInputFields({
    //         ...inputFields,
    //         revenue_date: event.target.value,
    //     });
    // };

    const handleFormChangeDiscount = (event) => {
        inputFields[event.target.name] = event.target.value;

        let revenueItems = [...inputFields.revenue_items];
        let revenueItemsTotal = revenueItems.reduce(
            (total, current) => +total + +current.amount,
            0
        );

        const finalTotal = computeFinalTotal(revenueItemsTotal);

        inputFields["total"] = finalTotal > 0 ? finalTotal : 0;

        setInputFields({
            ...inputFields,
        });
    };

    const calculateTotalAmount = (index, event = null) => {
        let data = [...inputFields.revenue_items];
        data[index][event.target.name] = event.target.value;

        const itemTotalAmount =
            inputFields["revenue_items"][index]["quantity"] *
            inputFields["revenue_items"][index]["rate"];

        inputFields["revenue_items"][index]["amount"] = itemTotalAmount;

        let revenueItemsTotal = data.reduce(
            (total, current) => +total + +current.amount,
            0
        );

        const finalTotal = computeFinalTotal(revenueItemsTotal);

        inputFields["subtotal"] = revenueItemsTotal;
        inputFields["total"] = finalTotal > 0 ? finalTotal : 0;
    };

    const calculateRemovedFields = (data) => {
        let revenueItemsTotal = data.reduce(
            (total, current) => +total + +current.amount,
            0
        );

        const finalTotal = computeFinalTotal(revenueItemsTotal);

        inputFields["total"] = finalTotal > 0 ? finalTotal : 0;
    };

    const computeFinalTotal = (total) => {
        const gstRate = 7;

        return (gstRate / 100) * total + total - inputFields["discount"];
    };

    const submitCreateRevenue = (values) => {
        values["portfolio_id"] = project.portfolio_id;
        values["programme_id"] = project.programme_id;
        values["project_id"] = id;

        return api.post("revenues", values);
    };

    const submitEditRevenue = (values) => {
        try {
            return api.put(`revenues/${action}`, values);
        } catch {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    //
    // useEffects
    //

    useEffect(() => {
        if (action !== undefined && action !== "create") {
            const getRevenue = async () => {
                try {
                    const response = await api.get(`revenues/${action}`, {});

                    setEditExpenses(response.data);
                } catch (error) {
                    setNotif({
                        notifMsg: "Something went wrong with the server",
                        open: true,
                        severity: "danger",
                    });
                }
            };
            getRevenue();
        }
    }, [action]);

    useEffect(() => {
        if (editExpenses) {
            setInputFields({
                revenue_date: editExpenses.revenue_date,
                is_recurring: editExpenses.is_recurring,
                interval: editExpenses.interval,
                start_date: editExpenses.start_date,
                end_date: editExpenses.end_date,
                revenue_to: editExpenses.revenue_to,
                revenue_from: editExpenses.revenue_from,
                gst: editExpenses.gst,
                discount: editExpenses.discount,
                subtotal: editExpenses.subtotal,
                total: editExpenses.total,
                revenue_items: editExpenses.revenue_items,
            });
        }
    }, [editExpenses]);

    return (
        <Card className="shadow-none mb-0">
            <Card.Header className="shadow-none pb-0">
                <Card.Title tag="h5">
                    {action === "create" ? "Create" : "Edit"} Revenue
                </Card.Title>
            </Card.Header>
            <Card.Body className="pb-0">
                <Formik
                    enableReinitialize
                    initialValues={inputFields}
                    validationSchema={schema}
                    onSubmit={async (
                        values,
                        { setErrors, setStatus, setSubmitting }
                    ) => {
                        try {
                            action === "create"
                                ? await submitCreateRevenue(values)
                                : await submitEditRevenue(values);

                            navigate(`/intellidocs/process/revenues/${id}`, {
                                state: {
                                    open: true,
                                    notifMsg: `Successfully ${
                                        action === "create"
                                            ? "created"
                                            : "updated"
                                    } Revenue`,
                                    severity: "success",
                                },
                            });
                        } catch (error) {
                            const message =
                                error.message || "Something went wrong";

                            setStatus({ success: false });
                            setErrors({ submit: message });
                            setSubmitting(false);
                            setNotif({
                                notifMsg:
                                    "Something went wrong with the server",
                                open: true,
                                severity: "danger",
                            });
                        }
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            {errors.submit && (
                                <Alert className="my-3" variant="danger">
                                    <div className="alert-message">
                                        {errors.submit}
                                    </div>
                                </Alert>
                            )}
                            <h6>Revenue items</h6>
                            <div className="bg-light p-4 rounded mb-3">
                                <AddEditRevenueItems
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    handleBlur={handleBlur}
                                    handleFormChangeItems={
                                        handleFormChangeItems
                                    }
                                    removeFields={removeFields}
                                    addFields={addFields}
                                    handleFormChangeDiscount={
                                        handleFormChangeDiscount
                                    }
                                />
                            </div>
                            <h6>Revenue details</h6>
                            <div className="bg-light p-4 rounded mb-3">
                                <AddEditRevenueDetails
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    handleBlur={handleBlur}
                                    handleFormChangeDetails={handleChange}
                                    project={project}
                                />
                            </div>
                            <h6>Billing details</h6>
                            <div className="bg-light p-4 rounded">
                                <AddEditBilling project={project} />
                            </div>
                            <Card.Footer className="text-center pb-0">
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="me-2"
                                    size="lg"
                                >
                                    <FontAwesomeIcon icon={faPaperPlane} /> Save
                                </Button>
                                <Button
                                    variant="link"
                                    onClick={() =>
                                        navigate(
                                            `/intellidocs/process/revenues/${id}`
                                        )
                                    }
                                >
                                    Cancel
                                </Button>
                            </Card.Footer>
                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </Card>
    );
};
