import React from "react";
import { ProjectListButtons } from "./ProjectListButtons";

const tableColumns = [
    {
        Header: "S/N",
    },
    {
        Header: "Code",
        accessor: "code",
    },
    {
        Header: "Project name",
        accessor: "project_name",
    },
    {
        Header: "Status",
        accessor: "status",
    },
	{
        Header: "Start date",
        accessor: "start_date",
    },
	{
        Header: "End date",
        accessor: "end_date",
    },
    {
        Header: "Actions",
        accessor: "action",
        Cell: ({ row }) => {
            return <ProjectListButtons row={row} />;
        },
    },
];

const tableData = {
    data: [
        {
            code: "class-saftey-course-001",
            company: "Fonda",
            id: "509a8d06-dd83-11ec-9d64-0242ac120002",
            manager: "Cheekit",
            name: "Test name",
        },
        {
            code: "class-saftey-course-001",
            company: "Fonda",
            id: "5b5af582-dd83-11ec-9d64-0242ac120002",
            manager: "Cheekit",
            name: "Test name",
        },
        {
            code: "class-saftey-course-001",
            company: "Fonda",
            id: "61f27596-dd83-11ec-9d64-0242ac120002",
            manager: "Cheekit",
            name: "Test name",
        },
    ],
    meta: {
        current_page: 1,
        from: null,
        last_page: 1,
        path: "http://localhost:8081/intellisuite/intellisuite-api-v2/public/api/v1/tms/batches",
        per_page: 25,
        to: null,
        total: 0,
    },
};

export { tableColumns, tableData };
