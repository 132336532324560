import React from "react";
import { Badge, Card, Table, Row, Col } from "react-bootstrap";
import { formatNumberWithCommas, roundToTwoDecimalDigits } from "../../../../../utils/utility";

const CreditNote = ({ creditNotes }) => {
    return (
        <>
            <Card>
                <Card.Body>
                    {/* <Table responsive bordered size="sm">
                        <tbody>
                            {creditNotes?.invoice_course_students?.students
                                ?.length > 0 && (
                                <tr>
                                    <td width="50%">Student</td>
                                    <td>
                                        {creditNotes?.invoice_course_students?.students?.map(
                                            (name, index) => {
                                                return (
                                                    <div key={index}>
                                                        {index + 1}. {name}
                                                    </div>
                                                );
                                            }
                                        )}
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td>Type</td>
                                <td>
                                    <Badge style={{ textTransform: 'capitalize' }}>{creditNotes?.type}</Badge>
                                </td>
                            </tr>
                            <tr>
                                <td>Reason</td>
                                <td>{creditNotes?.reason}</td>
                            </tr>
                            <tr>
                                <td>Remarks</td>
                                <td>{creditNotes?.remarks}</td>
                            </tr>
                            <tr>
                                <td>Refund</td>
                                <td style={{ textTransform: 'capitalize' }}>
                                    <Badge> {creditNotes?.refund}</Badge>
                                </td>
                            </tr> */}
                    {/* {creditNotes?.refund?.toUpperCase() === "YES" && (
                                <>
                                    <tr>
                                        <td> Amountss</td>
                                        <td>${creditNotes?.amount}</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>Deduction fee</th>
                                    </tr>
                                    <tr>
                                        <td> Admin fee</td>
                                        <td>
                                            {creditNotes?.deduction?.admin_fee
                                                ? `$${creditNotes?.deduction?.admin_fee} `
                                                : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> Test fee</td>
                                        <td>
                                            {creditNotes?.deduction?.test_fee
                                                ? `$${creditNotes?.deduction?.test_fee} `
                                                : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> BCA fee</td>
                                        <td>
                                            {creditNotes?.deduction?.bca_fee
                                                ? `$${creditNotes?.deduction?.bca_fee} `
                                                : "-"}
                                        </td>
                                    </tr>
                                </>
                            )} */}
                    {/* <tr>
                                        <td> Amount</td>
                                        <td>${creditNotes?.amount}</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>Deduction fee</th>
                                    </tr>
                                    <tr>
                                        <td> Admin fee</td>
                                        <td>
                                            {creditNotes?.deduction?.admin_fee
                                                ? `$${creditNotes?.deduction?.admin_fee} `
                                                : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> Test fee</td>
                                        <td>
                                            {creditNotes?.deduction?.test_fee
                                                ? `$${creditNotes?.deduction?.test_fee} `
                                                : "-"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> BCA fee</td>
                                        <td>
                                            {creditNotes?.deduction?.bca_fee
                                                ? `$${creditNotes?.deduction?.bca_fee} `
                                                : "-"}
                                        </td>
                                    </tr>
                            <tr>
                                <td>Supporting documents</td>
                                <td>
                                    {creditNotes?.attachments?.length === 0 &&
                                        "-"}

                                    {creditNotes?.attachments?.map(
                                        (attachment, index) => {
                                            return (
                                                <div key={index}>
                                                    {index + 1} .{" "}
                                                    <a
                                                        href={
                                                            attachment?.full_path
                                                        }
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {attachment?.file_name}
                                                    </a>
                                                </div>
                                            );
                                        }
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th colSpan={2}>
                                                    Sales director status :
                                                </th>
                                            </tr>
                                            <tr>
                                                <td>Status :</td>
                                                <td>
                                                    {creditNotes?.sales_director_status
                                                        ? creditNotes?.sales_director_status.charAt(0).toUpperCase() + creditNotes?.sales_director_status.slice(1).toLowerCase()
                                                        : "-"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Date :</td>
                                                <td>
                                                    {creditNotes?.sales_director
                                                        ?.date
                                                        ? new Date(creditNotes?.sales_director?.date).toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: '2-digit'}).replace(/\//g, '-').replace(',', ', ')
                                                        : "-"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Remarks :</td>
                                                <td>
                                                    {creditNotes?.sales_director
                                                        ?.remarks
                                                        ? creditNotes
                                                              ?.sales_director
                                                              ?.remarks
                                                        : "-"}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th colSpan={2}>
                                                    Finance director status :
                                                </th>
                                            </tr>
                                            <tr>
                                                <td>Status :</td>
                                                <td>
                                                    {creditNotes?.finance_director_status
                                                        ? creditNotes?.finance_director_status.charAt(0).toUpperCase() + creditNotes?.finance_director_status.slice(1).toLowerCase()
                                                        : "-"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Date :</td>
                                                <td>
                                                    {creditNotes
                                                        ?.finance_director?.date
                                                        ? new Date(creditNotes?.finance_director?.date).toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'}).replace(/\//g, '-').replace(',', ', ')
                                                        : "-"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Remarks :</td>
                                                <td>
                                                    {creditNotes
                                                        ?.finance_director
                                                        ?.remarks
                                                        ? creditNotes
                                                              ?.finance_director
                                                              ?.remarks
                                                        : "-"}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            {creditNotes?.refund?.toUpperCase() !== "YES" && (
                                <>
                                    <tr>
                                        <th colSpan={2}>Status</th>
                                    </tr>
                                    
                                    <tr>
                                        <td>Status</td>
                                        <td>
                                            <Badge
                                                bg={
                                                    creditNotes?.status_key?.toUpperCase() ===
                                                    "PENDING"
                                                        ? "danger"
                                                        : "success"
                                                }
                                            >
                                                {creditNotes?.status}
                                            </Badge>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Status updated on</td>
                                        <td>
                                            { creditNotes?.status_date 
                                                ? new Date(creditNotes?.status_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }).replace(/\//g, '-')
                                                : '-'
                                            }
                                        </td>
                                                    
                                    </tr>
                                </>
                            )}
                            
                            {creditNotes?.refund?.toUpperCase() === "YES" && (
                                <>
                                    <tr>
                                        <th colSpan={2}>Refund status</th>
                                    </tr>
                                    <tr>
                                        <td> Status</td>
                                        <td><Badge
                                        bg={
                                            creditNotes?.status_key?.toUpperCase() ===
                                            "PENDING"
                                                ? "danger"
                                                : "success"
                                        }
                                    >
                                        {creditNotes?.status}
                                    </Badge></td>
                                    </tr>
                                    <tr>
                                        <td> Amount</td>
                                        <td>${creditNotes?.amount}</td> */}
                    {/* <td>{creditNotes?.refund_amount ?? "-"}</td> */}
                    {/* </tr>
                                    <tr>
                                        <td> Date</td>
                                        <td>
                                            { creditNotes?.refund_date
                                                ? new Date(creditNotes?.status_date).toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'}).replace(/\//g, '-').replace(',', ', ')
                                                : '-'
                                            }
                                        </td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </Table> */}
                    <Row>
                        <Table className="table table-borderless">
                            <tbody>
                                {/* <tr>
                                    <th width={100}>Attention:</th>
                                    <td className="align-top">{creditNotes?.invoice?.company_contact_person ? creditNotes?.invoice?.company_contact_person.toUpperCase() : ''}</td>
                                </tr>
                                <tr>
                                    <th width={150}>Contact number:</th>
                                    <td className="align-top">{creditNotes?.invoice?.company_contact_no ? creditNotes?.invoice?.company_contact_no.toUpperCase() : ''}</td>
                                </tr> */}

                                <tr>
                                    <th className="py-1" width={100}>
                                        Type:{" "}
                                    </th>
                                    <td className="align-top py-1">
                                        {creditNotes?.type
                                            ? creditNotes?.type.toUpperCase()
                                            : ""}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="py-1" width={100}>
                                        Reason:{" "}
                                    </th>
                                    <td className="align-top py-1">
                                        {creditNotes?.reason
                                            ? creditNotes?.reason.toUpperCase()
                                            : "-"}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="py-1 text-nowrap">
                                        Supporting documents:{" "}
                                    </th>
                                    <td className="align-top py-1">
                                        {creditNotes?.attachments?.length ===
                                            0 && "-"}
                                        {creditNotes?.attachments?.map(
                                            (attachment, index) => {
                                                return (
                                                    <div key={index}>
                                                        {index + 1} .{" "}
                                                        <a
                                                            href={
                                                                attachment?.full_path
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {
                                                                attachment?.file_name
                                                            }
                                                        </a>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="py-1">Refund: </th>
                                    <td
                                        className="align-top py-1"
                                        style={{ textTransform: "capitalize" }}
                                    >
                                        <Badge> {creditNotes?.refund}</Badge>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Remarks: </th>
                                    <td className="align-top text-break">{creditNotes?.remarks ?? '-'}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                    <Row>
                        <Col>
                            <Table className="table table-bordered border-dark">
                                <thead className="bg-secondary bg-opacity-25">
                                    <tr>
                                        <th width={300}>Description</th>
                                        <th width={10} className="text-center">
                                            Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {/* {creditNotes?.remarks} For Invoice
                                            no. {creditNotes?.invoice?.number}{" "}
                                            DATED {creditNotes?.invoice?.date} -{" "}
                                            {creditNotes?.type} */}
                                            {creditNotes?.description}
                                            <br />
                                            {creditNotes
                                                ?.invoice_course_students
                                                ?.students.length > 0 && (
                                                <Table className="table table-borderless">
                                                    <tbody>
                                                        <tr className="p-0">
                                                            <th
                                                                className="p-0"
                                                                width={100}
                                                            >
                                                                Students:{" "}
                                                            </th>
                                                            <td className="p-0"></td>
                                                        </tr>
                                                        <tr className="p-0">
                                                            <th className="p-0"></th>
                                                            <td className="p-0">
                                                                <table id="students-table">
                                                                    {creditNotes?.invoice_course_students?.students.map(
                                                                        (
                                                                            student,
                                                                            index
                                                                        ) => {
                                                                            let name =
                                                                                student.substring(
                                                                                    0,
                                                                                    student.indexOf(
                                                                                        "-"
                                                                                    )
                                                                                );
                                                                            let fin =
                                                                                student.substring(
                                                                                    student.indexOf(
                                                                                        "-"
                                                                                    ) +
                                                                                        1
                                                                                );
                                                                            let mask =
                                                                                fin
                                                                                    .substring(
                                                                                        fin.length -
                                                                                            4
                                                                                    )
                                                                                    .padStart(
                                                                                        fin.length,
                                                                                        "*"
                                                                                    );
                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td
                                                                                            className="p-0"
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            {index +
                                                                                                1}

                                                                                            .{" "}
                                                                                            {
                                                                                                name
                                                                                            }{" "}
                                                                                            {
                                                                                                fin
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            );
                                                                        }
                                                                    )}
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            )}
                                        </td>
                                        <td className="text-end">
                                            {creditNotes?.has_or_none_gst_amount
                                                ? "$" +
                                                  formatNumberWithCommas(
                                                      roundToTwoDecimalDigits(
                                                          parseFloat(
                                                              creditNotes?.has_or_none_gst_amount
                                                          )
                                                      )
                                                  )
                                                : "-"}
                                        </td>
                                    </tr>
                                    {creditNotes?.deduction?.bca_fee !==
                                        null && (
                                        <tr>
                                            <>
                                                <th>LESS: BCA Fee</th>
                                                <td className="text-end">
                                                    $
                                                    {formatNumberWithCommas(
                                                        roundToTwoDecimalDigits(
                                                            parseFloat(
                                                                creditNotes
                                                                    ?.deduction
                                                                    ?.bca_fee /
                                                                    parseFloat(
                                                                        creditNotes?.tax
                                                                    )
                                                            )
                                                        )
                                                    )}
                                                </td>
                                            </>
                                        </tr>
                                    )}
                                    {creditNotes?.deduction?.test_fee !==
                                        null && (
                                        <tr>
                                            <>
                                                <th>LESS: Test Fee</th>
                                                <td className="text-end">
                                                    $
                                                    {formatNumberWithCommas(
                                                        roundToTwoDecimalDigits(
                                                            parseFloat(
                                                                creditNotes
                                                                    ?.deduction
                                                                    ?.test_fee /
                                                                    parseFloat(
                                                                        creditNotes?.tax
                                                                    )
                                                            )
                                                        )
                                                    )}
                                                </td>
                                            </>
                                        </tr>
                                    )}
                                    {creditNotes?.deduction?.admin_fee !==
                                        null && (
                                        <tr>
                                            <>
                                                <th>LESS: Admin Fee</th>
                                                <td className="text-end">
                                                    $
                                                    {formatNumberWithCommas(
                                                        roundToTwoDecimalDigits(
                                                            parseFloat(
                                                                creditNotes
                                                                    ?.deduction
                                                                    ?.admin_fee /
                                                                    parseFloat(
                                                                        creditNotes?.tax
                                                                    )
                                                            )
                                                        )
                                                    )}
                                                </td>
                                            </>
                                        </tr>
                                    )}
                                    <tr>
                                        <th className="text-end">
                                            Invoice Subtotal
                                        </th>
                                        <td className="text-end">
                                            $
                                            {formatNumberWithCommas(
                                                roundToTwoDecimalDigits(
                                                    parseFloat(
                                                        creditNotes
                                                            ?.deduction_calculations
                                                            ?.subtotal
                                                    )
                                                )
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-end">
                                            Total Tax Before tax
                                        </th>
                                        <td className="text-end">
                                            $
                                            {formatNumberWithCommas(
                                                roundToTwoDecimalDigits(
                                                    parseFloat(
                                                        creditNotes
                                                            ?.deduction_calculations
                                                            ?.subtotal
                                                    )
                                                )
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-end">
                                            Total tax amount GST{" "}
                                            {(parseFloat(
                                                creditNotes?.invoice?.gst
                                            ) /
                                                100) *
                                                100}
                                            %
                                        </th>
                                        <td className="text-end">
                                            $
                                            {formatNumberWithCommas(
                                                roundToTwoDecimalDigits(
                                                    parseFloat(
                                                        creditNotes
                                                            ?.deduction_calculations
                                                            ?.gst_amount
                                                    )
                                                )
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="text-end">
                                            Total amount
                                        </th>
                                        <td className="text-end">
                                            $
                                            {formatNumberWithCommas(
                                                roundToTwoDecimalDigits(
                                                    parseFloat(
                                                        creditNotes
                                                            ?.deduction_calculations
                                                            ?.total_amount
                                                    )
                                                )
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4>Prepared by: </h4>
                            {creditNotes?.invoice?.sales_user?.name}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default CreditNote;
