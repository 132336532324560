const tableColumns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Project code",
    accessor: "project_code",
  },
  {
    Header: "Person incharge",
    accessor: "person_in_charge",
  },
  {
    Header: "Contract number",
    accessor: "contract_number",
  },
  {
    Header: "Details",
    accessor: "details",
  },
  {
    Header: "Project cost",
    accessor: "project_cost",
  },
  // {
  //   Header: "Portfolio",
  //   accessor: "portfolio",
  // },
  // {
  //   Header: "Programme",
  //   accessor: "programme",
  // },
  {
    Header: "Actions",
    accessor: "actions",
  },
];

export { tableColumns };
