import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import BatchDetails from "../CommonComponents/BatchDetails";

import api from "../../../service/api";
// import { tableColumn } from "./data";
import { ArrowLeft, MinusCircle, PlusCircle } from "react-feather";

import ViewBatchStudentList from "./ViewBatchStudentList";
const ViewBatchStudents = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [classStudents, setClassStudents] = useState([]);
    const [batch, setBatch] = useState({});
    const [loading, setLoading] = useState(false);

    const getBatchStudents = async () => {
        setLoading(true);
        const response = await api.get(`tms/batches/${id}/batch-students`, {});
        setClassStudents(response.data);
        setLoading(false);
    };
    const getBatch = async () => {
        const response = await api.get(`tms/batches/${id}`, {});
        setBatch(response.data);
    };

    useEffect(() => {
        setLoading(true);
        getBatch();
        getBatchStudents();
        setLoading(false);
    }, []); //  eslint-disable-line react-hooks/exhaustive-deps
    const renderRowSubComponent = React.useCallback(
        ({ row }) => (
            <>
                <h5>Training dates</h5>
                <table className="table table-bordered p-0">
                    <thead>
                        <tr>
                            <td>Date</td>
                            <td>Time</td>
                            <td>Session</td>
                            <td>Trainer</td>
                            <td>Attendance - AM </td>
                            <td>Attendance - PM </td>
                        </tr>
                    </thead>
                    <tbody>
                        {row.original?.student?.training_dates?.map(
                            (item, index) => {
                                return (
                                    <>
                                        <tr key={index}>
                                            <td>{item.date}</td>
                                            <td>{item.time}</td>
                                            <td>{item?.session.join()}</td>
                                            <td>Trainer 1</td>
                                            <td>{item?.attendance_am}</td>
                                            <td>{item?.attendance_pm}</td>
                                        </tr>
                                    </>
                                );
                            }
                        )}
                    </tbody>
                </table>
            </>
        ),
        []
    );
    const tableColumn = React.useMemo(
        () => [
            {
                // Make an expander cell
                Header: () => null, // No header
                id: "expander", // It needs an ID
                Cell: ({ row }) => (
                    // Use Cell to render an expander for each row.
                    // We can use the getToggleRowExpandedProps prop-getter
                    // to build the expander.
                    <span {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ? <MinusCircle /> : <PlusCircle />}
                    </span>
                ),
            },
            {
                Header: "S.no",
            },
            {
                Header: "Name",
                accessor: "enrolment.name",
            },
            {
                Header: "FIN",
                accessor: "enrolment.fin",
            },

            {
                Header: "Result",
                accessor: "enrolment.result",
            },
            {
                Header: "Result date",
                accessor: "enrolment.result_date",
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <Helmet title="View batch" />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h1 className="h3 mb-3">Class - students </h1>
                    </Col>
                    <Col md={6} className="text-end">
                        <Button
                            className="m-2"
                            onClick={() => navigate("/tms/batches/completed")}
                        >
                            <ArrowLeft className="feather" />
                        </Button>
                    </Col>
                </Row>
                {loading && <div>Loading...</div>}
                {!loading && classStudents.length > 0 && (
                    <Row>
                        <Col md="4" xl="3">
                            <BatchDetails batch={batch} type="attendance" />
                        </Col>
                        <Col md="8" xl="9">
                            <ViewBatchStudentList
                                data={classStudents}
                                columns={tableColumn}
                                batch={batch}
                                getBatchStudentsApi={getBatchStudents}
                                renderRowSubComponent={renderRowSubComponent}
                            />
                        </Col>
                    </Row>
                )}
                {classStudents.length === 0 && (
                    <Alert className="my-3" variant="warning">
                        <div className="alert-message">
                            No students under this batch/class
                        </div>
                    </Alert>
                )}
            </Container>
        </React.Fragment>
    );
};

export default ViewBatchStudents;
