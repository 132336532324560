import React, { useState } from "react";
import { Badge, Button, Col, Form, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import api from "../../../../../service/api";
import { addDefaultOption } from "../../../../../utils/utility";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import moment from "moment";
import { Filter } from "react-feather";

const Search = ({ setSearchValue, searchValue, search, salesPersons}) => {
    const navigate = useNavigate();
    const [hasMore, setHasMore] = useState(true);
    const handleLoadMore = async (search, { page }) => {
        if (!search) {
            return {
                options: [],
                hasMore: false,
            };
        }

        const response = await api.get("tms/companies-options", {
            search: search,
            page: page,
        });

        const { options: newOptions, hasMore: newHasMore } = response;

        setHasMore(newHasMore);

        return {
            options: newOptions,
            hasMore: newHasMore,
            additional: {
                page: page + 1,
            },
        };
    };
    return (
        <>
            <Row className="g-2 mb-2 d-flex justify-content-around">
                <Col md={4}>
                    <Select
                        isClearable
                        className="react-select-container  render-above-1"
                        classNamePrefix="react-select"
                        placeholder="Invoice Type"
                        options={addDefaultOption([
                            {
                                value: "AD-HOC",
                                label: "Ad-hoc invoice",
                            },
                            {
                                value: "ADDITIONAL",
                                label: "Additional invoice",
                            },
                            {
                                value: "SALES",
                                label: "Sales invoice",
                            }
                        ])}
                        name="invoice_type"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                invoice_type: event?.value,
                            });
                        }}
                    />
                </Col>
                <Col md={4}>
                    <Form.Control
                        type="text"
                        name="number"
                        placeholder="Invoice no"
                        size="lg"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                number: event.target.value,
                            });
                        }}
                    />
                </Col>
                <Col md={4}>
                    <AsyncPaginate
                        className="render-above"
                        isClearable
                        loadOptions={handleLoadMore}
                        hasMore={hasMore}
                        placeholder="Search company"
                        additional={{
                            page: 1,
                        }}
                        onChange={(value) => {
                            setSearchValue({
                                ...searchValue,
                                company: value ? value?.value : "",
                            });
                        }}
                        debounceTimeout={1000}
                    />
                </Col>
            </Row>

            <Row className="row-flex g-2 d-flex justify-content-around">
                <Col md={4}>
                    <Select
                        isClearable
                        className="react-select-container render-above"
                        classNamePrefix="react-select"
                        options={addDefaultOption(salesPersons)}
                        name="salesperson"
                        placeholder="Search salesperson"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                salesperson: event?.value,
                            });
                        }}
                    />
                </Col>
                <Col md={4} className="justify-content-end">
                    <Row className="flex-row flex-row ms-md-1">
                        <Col md={5} sm={12} className="p-md-0">
                            <DatePicker
                                id="from-date-picker"
                                name="from_date"
                                className={`form-control`}
                                placeholder="From date"
                                // defaultValue={moment().startOf('month')}
                                // allowClear={false}
                                onChange={(event) => {
                                    console.log(moment(event).format("YYYY-MM-DD"));
                                    setSearchValue({
                                        ...searchValue,
                                        start_date: event ? moment(event).format("YYYY-MM-DD") : null,
                                    });
                                }}
                                style={{minHeight: '2.5rem'}}
                            />
                            {/* <Form.Control
                                type="month"
                                name="start_date"
                                min={`${minSelectableYear}-01`}
                                max={`${new Date().getFullYear() + 1 }-12`}
                                size="lg"
                                onChange={(event) => {
                                    setSearchValue({
                                        ...searchValue,
                                        start_date: event.target.value,
                                    });
                                }}
                            /> */}
                        </Col>
                        <Col md={2} className="d-flex align-items-center justify-content-center m-0 p-0">
                            <Badge bg='info' className="d-flex align-items-center justify-content-center text-center w-100 h-100 mx-2 mx-md-0">
                                To
                            </Badge>
                        </Col>
                        <Col md={5} className="p-md-0">
                            <DatePicker
                                id="to-date-picker"
                                name="to_date"
                                className={`form-control`}
                                placeholder="To date"
                                // defaultValue={moment().endOf('month')}
                                // allowClear={false}
                                onChange={(event) => {
                                    console.log(moment(event).format("YYYY-MM-DD"), event);
                                    setSearchValue({
                                        ...searchValue,
                                        end_date: event ? moment(event).format("YYYY-MM-DD") : null,
                                    });
                                }}
                                style={{minHeight: '2.5rem'}}
                            />
                            {/* <Form.Control
                                type="month"
                                name="start_date"
                                min={`${minSelectableYear}-01`}
                                max={`${new Date().getFullYear() + 1}-12`}
                                size="lg"
                                onChange={(event) => {
                                    setSearchValue({
                                        ...searchValue,
                                        end_date: event.target.value,
                                    });
                                }}
                            /> */}
                        </Col>
                    </Row>
                </Col>
                <Col className="mb-2 d-flex ps-3 justify-content-end">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-top`}>Search filter</Tooltip>}
                    >
                        <Button className="" size="lg" variant="info" onClick={() => search()} title="Search filter">
                            <Filter size={15} />
                        </Button>
                    </OverlayTrigger>
                </Col>
            </Row>
        </>
    );
};
export default Search;
