import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AutoGrowTextarea } from "../../../components/AutoGrowTextarea";
import { FileUpload } from "../../../components/FileUpload";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { addDefaultOption } from "../../../utils/utility";

export const AddEditRequisitionDetails = ({
    values,
    errors,
    touched,
    handleBlur,
    handleFormChangeDetails,
    files,
    setFiles,
    requisition,
    intelliDocsUser,
    deleteUploadedFiles,
    quotations,
    quotationSelect,
    handleFormChangeQuotation,
    loading,
}) => {
    return (
        <>
            <Row>
                <Col md={6}>
                    <Row>
                        <Col md={10}>
                            <Form.Group className="mb-3">
                                <Form.Label>Requestor</Form.Label> <br />
                                <Form.Select
                                    name="requestor"
                                    id="requestor"
                                    value={values.requestor}
                                    isInvalid={Boolean(
                                        errors &&
                                            touched &&
                                            errors.requestor &&
                                            touched.requestor
                                    )}
                                    onBlur={handleBlur}
                                    onChange={handleFormChangeDetails}
                                >
                                    <option></option>
                                    {intelliDocsUser &&
                                        intelliDocsUser.map((data, index) => {
                                            return (
                                                <option
                                                    key={index}
                                                    value={data.name}
                                                >
                                                    {data.name}
                                                </option>
                                            );
                                        })}
                                </Form.Select>
                                {errors && errors?.requestor && (
                                    <Form.Control.Feedback type="invalid">
                                        Requestor is a required field
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <Form.Group>
                                <Form.Label>Request date</Form.Label> <br />
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="date"
                                        name="request_date"
                                        id="request_date"
                                        value={values.request_date}
                                        isInvalid={Boolean(
                                            errors &&
                                                touched &&
                                                errors.request_date &&
                                                touched.request_date
                                        )}
                                        onBlur={handleBlur}
                                        onChange={handleFormChangeDetails}
                                    />
                                    {errors && errors?.request_date && (
                                        <Form.Control.Feedback type="invalid">
                                            Request date is a required field
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Form.Group>
                        </Col>
                        <Col md={10}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Link quotation (Optional)
                                </Form.Label>{" "}
                                <br />
                                {loading && (
                                    <Select
                                        name="quotation"
                                        isSearchable={true}
                                        isClearable={true}
                                        options={addDefaultOption(quotations)}
                                        onChange={(event) => {
                                            handleFormChangeQuotation(
                                                event,
                                                values
                                            );
                                        }}
                                        defaultValue={[quotationSelect]}
                                    />
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Col md={10}>
                            <Form.Group>
                                <Form.Label>Purpose</Form.Label> <br />
                                <Form.Group className="mb-3">
                                    <AutoGrowTextarea
                                        name="purpose"
                                        id="purpose"
                                        fieldValue={values.purpose}
                                        handleBlur={handleBlur}
                                        handleFormChangeDetails={
                                            handleFormChangeDetails
                                        }
                                        isInvalid={Boolean(
                                            errors &&
                                                touched &&
                                                errors.purpose &&
                                                touched.purpose
                                        )}
                                    />
                                    {errors && errors.purpose && (
                                        <Form.Control.Feedback type="invalid">
                                            Purpose is a required field
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <Form.Group>
                                <Form.Label>Supporting documents</Form.Label>{" "}
                                <br />
                                <Form.Group>
                                    <FileUpload
                                        files={files}
                                        setFiles={setFiles}
                                    />
                                    {requisition &&
                                        requisition.attachments_support.map(
                                            (element, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {index === 0 && (
                                                            <>
                                                                Uploaded: <br />
                                                            </>
                                                        )}
                                                        <Button
                                                            href={
                                                                element.full_path
                                                            }
                                                            target="_blank"
                                                            variant="link"
                                                            className="m-0 px-0"
                                                        >
                                                            {element.file_name}
                                                        </Button>
                                                        <Button
                                                            variant="primary"
                                                            onClick={() =>
                                                                deleteUploadedFiles(
                                                                    element.id,
                                                                    requisition.attachments_support,
                                                                    element.full_path
                                                                )
                                                            }
                                                            className="ms-2"
                                                            size="sm"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faWindowClose
                                                                }
                                                            />
                                                        </Button>
                                                        <br />
                                                    </React.Fragment>
                                                );
                                            }
                                        )}
                                </Form.Group>
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
