import React, { useState, useEffect } from "react";
import {
    Button,
    Card,
    Col,
    Row,
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../service/api";
import download from "downloadjs";
import { Download } from "react-feather";
import { SnackbarAlert } from "../../../components/SnackbarAlert";

export const ViewReceivedPayment = ({ project }) => {
    const navigate = useNavigate();
    let { id, action, actiondo } = useParams();

    //
    // States
    //

    // eslint-disable-next-line no-unused-vars
    const [receivedPayment, setReceivedPayment] = useState();
    // eslint-disable-next-line no-unused-vars
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    //
    // Functions
    //

    const downloadPdf = async () => {
        const responsePdf = await api.getpdf(
            `generate-payment-pdf/${actiondo}`,
            {}
        );

        if (responsePdf)
            download(
                responsePdf,
                receivedPayment?.code || "pdf-file",
                "application/pdf"
            );
    };

    //
    // UseEffects
    //

    useEffect(() => {
        if (actiondo && actiondo !== "create") {
            const getExpense = async () => {
                try {
                    const response = await api.get(
                        `received-payments/${actiondo}`,
                        {}
                    );
                    setReceivedPayment(response.data);
                } catch (error) {
                    setNotif({
                        notifMsg: "Something went wrong with the server",
                        open: true,
                        severity: "danger",
                    });
                }
            };
            getExpense();
        }
    }, [actiondo]);

    return (
        <>
            <Card className="shadow-none mb-0">
                <Card.Header className="shadow-none pb-0">
                    <Card.Title tag="h5">
                        <Row className="mb-3">
                            <Col md={6}>View received payment</Col>
                            <Col md={6} className="text-end">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            Download received payment
                                        </Tooltip>
                                    }
                                >
                                    <Button
                                        variant="outline-primary"
                                        className="me-1 mb-1"
                                        onClick={() => downloadPdf()}
                                    >
                                        <Download
                                            className="align-middle"
                                            size={18}
                                        />
                                    </Button>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </Card.Title>
                </Card.Header>
                <Card.Body className="py-0">
                    <h6>Received payment items</h6>
                    <div className="bg-light p-4 rounded mb-3">
                        <Row className="mb-3">
                            <Col md={2} className="font-weight-bold">
                                Delivery ID
                            </Col>
                            <Col md={6}>{receivedPayment?.code}</Col>
                        </Row>
                        <Row className="mb-4">
                            <Col md={2} className="font-weight-bold">
                                Delivery date
                            </Col>
                            <Col md={6}>{receivedPayment?.payment_date}</Col>
                        </Row>
                        <Row className="mb-2">
                            <Col md={1}>S/N</Col>
                            <Col md={2}>Item</Col>
                            <Col md={4}>Description</Col>
                            <Col md={1}>Quantity</Col>
                            <Col md={2}>Rate (S$)</Col>
                            <Col md={2}>Amount (S$)</Col>
                        </Row>
                        <hr style={{ border: "1px solid" }} />
                        {receivedPayment &&
                            receivedPayment.received_payment_items.length &&
                            receivedPayment.received_payment_items.map(
                                (input, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Row className="mb-3">
                                                <Col md={1}>{index + 1}</Col>
                                                <Col md={2}>{input.item}</Col>
                                                <Col md={4}>
                                                    {input.description}
                                                </Col>
                                                <Col md={1}>
                                                    {input.quantity}
                                                </Col>
                                                <Col md={2}>{input.rate}</Col>
                                                <Col md={2}>{input.amount}</Col>
                                            </Row>
                                        </React.Fragment>
                                    );
                                }
                            )}
                        {receivedPayment && (
                            <Row className="mt-3">
                                <Col md={4}></Col>
                                <Col md={4}></Col>
                                <Col md={4}>
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <h4>Subtotal</h4>
                                        </Col>
                                        <Col md={6}>
                                            <h4 className="text-end">
                                                S${receivedPayment.subtotal}
                                            </h4>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <h4>GST</h4>
                                        </Col>
                                        <Col md={6}>
                                            <h4 className="text-end">
                                                {receivedPayment.gst}%
                                            </h4>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <h4>Total</h4>
                                        </Col>
                                        <Col md={6}>
                                            <h4 className="text-end">
                                                S$
                                                {Number(
                                                    receivedPayment.total
                                                ).toFixed(2)}
                                            </h4>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </div>
                    <h6>Billing details</h6>
                    <div className="bg-light p-4 rounded">
                        {receivedPayment && project && project.portfolio && (
                            <>
                                <Row className="my-4">
                                    <Col md={6}>
                                        <h4 className="mb-3 mx-4">
                                            Payment to:
                                        </h4>
                                        <Row className="mx-3 mt-3">
                                            <Col
                                                md={4}
                                                className="font-weight-bold"
                                            >
                                                Business name
                                            </Col>
                                            <Col md={5}>
                                                {project.portfolio.name || "-"}
                                            </Col>
                                        </Row>
                                        <Row className="mx-3 mt-3">
                                            <Col
                                                md={4}
                                                className="font-weight-bold"
                                            >
                                                Email
                                            </Col>
                                            <Col md={5}>
                                                {project.portfolio.email || "-"}
                                            </Col>
                                        </Row>
                                        <Row className="mx-3 mt-3">
                                            <Col
                                                md={4}
                                                className="font-weight-bold"
                                            >
                                                Contact number
                                            </Col>
                                            <Col md={5}>
                                                {project.portfolio
                                                    .contact_number || "-"}
                                            </Col>
                                        </Row>
                                        <Row className="mx-3 mt-3">
                                            <Col
                                                md={4}
                                                className="font-weight-bold"
                                            >
                                                Address
                                            </Col>
                                            <Col md={5}>
                                                {project.portfolio.address ||
                                                    "-"}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </div>
                    <Card.Footer className="text-center pb-0">
                        <Button
                            variant="link"
                            onClick={() =>
                                navigate(
                                    `/intellidocs/process/revenues/${id}/${action}/do`
                                )
                            }
                        >
                            Cancel
                        </Button>
                    </Card.Footer>
                </Card.Body>
            </Card>

            {/* Supporting components */}

            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};
