import React, { useState, useEffect, useContext } from "react";
import {
    Alert,
    Button,
    Card,
    Col,
    Form,
    Row,
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../service/api";
import download from "downloadjs";
import { Download } from "react-feather";
import { convertToDecimalWithComma } from "../../../utils/utility";
import { LoadingContext } from "../../../App";
import { SnackbarAlert } from "../../../components/SnackbarAlert.js";
import { Formik } from "formik";
import { faPaperPlane, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { FileUpload } from "../../../components/FileUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ViewRevenue = ({ project }) => {
    const navigate = useNavigate();
    let { id, action } = useParams();

    const schema = Yup.object().shape({
        status: Yup.string().required(),
    });

    //
    // States
    //

    const setIsLoadingActive = useContext(LoadingContext);
    // eslint-disable-next-line no-unused-vars
    const [revenue, setRevenue] = useState();
    // eslint-disable-next-line no-unused-vars
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [inputFields, setInputFields] = useState({
        status: "",
        remarks: "",
    });
    const [files, setFiles] = useState([]);
    const [deleteFiles, setDeleteFiles] = useState([]);
    const [deleteFilesPath, setDeleteFilePath] = useState([]);

    //
    // Functions
    //

    const downloadPdf = async () => {
        const responsePdf = await api.getpdf(
            `generate-revenue-pdf/${action}`,
            {}
        );

        if (responsePdf)
            download(
                responsePdf,
                revenue?.code || "pdf-file",
                "application/pdf"
            );
    };

    const submitEditRevenue = (values) => {
        try {
            const formData = new FormData();

            formData.append("status", values.status);
            formData.append("remarks", values.remarks || "");

            for (let key in deleteFiles) {
                formData.append(`delete_files[${key}]`, deleteFiles[key]);
            }

            for (let key in deleteFilesPath) {
                formData.append(
                    `delete_files_path[${key}]`,
                    deleteFilesPath[key]
                );
            }

            files.forEach((item) => {
                formData.append("attachments[]", item.file);
            });

            return api.post(`update-revenues/${action}`, formData);
        } catch {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    const deleteUploadedFiles = (fileId, data, fullPath) => {
        const newData = data.filter((item) => {
            return item.id !== fileId;
        });

        revenue.attachments = newData;

        setDeleteFiles([...deleteFiles, fileId]);
        setDeleteFilePath([...deleteFilesPath, fullPath]);
        setRevenue({
            ...revenue,
        });
    };

    //
    // useEffects
    //

    useEffect(() => {
        if (action !== undefined && action !== "create") {
            setIsLoadingActive(true);
            const getExpense = async () => {
                try {
                    const response = await api.get(`revenues/${action}`, {});

                    setRevenue(response.data);
                    setIsLoadingActive(false);
                    setInputFields({
                        status: response.data.status,
                        remarks: response.data.remarks,
                    });
                } catch (error) {
                    setIsLoadingActive(false);
                    setNotif({
                        notifMsg: "Something went wrong with the server",
                        open: true,
                        severity: "danger",
                    });
                }
            };
            getExpense();
        }
    }, [action, setIsLoadingActive]);

    return (
        <>
            <Card className="shadow-none mb-0">
                <Card.Header className="shadow-none pb-0">
                    <Card.Title tag="h5">
                        <Row className="mb-3">
                            <Col md={6}>View revenue</Col>
                            <Col md={6} className="text-end">
                                {revenue && revenue.expense && (
                                    <>
                                        <Button
                                            variant="primary"
                                            onClick={() =>
                                                navigate(
                                                    `/intellidocs/process/expenses/${id}/${revenue.expense.id}/view`
                                                )
                                            }
                                            className="mr-3"
                                        >
                                            {revenue.expense.code}
                                        </Button>
                                    </>
                                )}
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            Download revenue
                                        </Tooltip>
                                    }
                                >
                                    <Button
                                        variant="outline-primary"
                                        className="me-1 ms-1"
                                        onClick={() => downloadPdf()}
                                    >
                                        <Download
                                            className="align-middle"
                                            size={18}
                                        />
                                    </Button>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </Card.Title>
                </Card.Header>
                <Card.Body className="py-0">
                    <h6>Revenue items</h6>
                    <div className="bg-light p-4 rounded mb-3">
                        <Row className="mb-2">
                            <Col md={1}>S/N</Col>
                            <Col md={2}>Item</Col>
                            <Col md={4}>Description</Col>
                            <Col md={1}>Quantity</Col>
                            <Col md={2}>Rate (S$)</Col>
                            <Col md={2}>Amount (S$)</Col>
                        </Row>
                        <hr style={{ border: "1px solid" }} />
                        {revenue &&
                            revenue.revenue_items.length &&
                            revenue.revenue_items.map((input, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Row className="mb-3">
                                            <Col md={1}>{index + 1}</Col>
                                            <Col md={2}>{input.item}</Col>
                                            <Col md={4}>
                                                {input.description}
                                            </Col>
                                            <Col md={1}>{input.quantity}</Col>
                                            <Col md={2}>{input.rate}</Col>
                                            <Col md={2}>{input.amount}</Col>
                                        </Row>
                                    </React.Fragment>
                                );
                            })}
                        {revenue && (
                            <Row className="mt-3">
                                <Col md={4}></Col>
                                <Col md={4}></Col>
                                <Col md={4}>
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <h4>Subtotal</h4>
                                        </Col>
                                        <Col md={6}>
                                            <h4 className="text-end">
                                                S$
                                                {convertToDecimalWithComma(
                                                    revenue.subtotal
                                                )}
                                            </h4>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <h4>GST</h4>
                                        </Col>
                                        <Col md={6}>
                                            <h4 className="text-end">
                                                {revenue.gst}%
                                            </h4>
                                        </Col>
                                    </Row>
                                    {/* <Row className="mt-3">
                                        <Col md={6}>
                                            <h4>Discount</h4>
                                        </Col>
                                        <Col md={6}>
                                            <h4 className="text-end">
                                                S$
                                                {convertToDecimalWithComma(
                                                    revenue.discount
                                                )}
                                            </h4>
                                        </Col>
                                    </Row> */}
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <h4>Total</h4>
                                        </Col>
                                        <Col md={6}>
                                            <h4 className="text-end">
                                                S$
                                                {convertToDecimalWithComma(
                                                    revenue.total
                                                )}
                                            </h4>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </div>
                    <h6>Revenue details</h6>
                    <div className="bg-light p-4 rounded mb-3">
                        <Row className="mb-3">
                            <Col md={2} className="font-weight-bold">
                                Revenue code
                            </Col>
                            <Col md={6}>{revenue?.code}</Col>
                        </Row>
                        <Row className="mb-4">
                            <Col md={2} className="font-weight-bold">
                                Date
                            </Col>
                            <Col md={6}>{revenue?.revenue_date}</Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={2} className="font-weight-bold">
                                Is recurring:
                            </Col>
                            <Col md={2}>{revenue?.is_recurring}</Col>
                        </Row>
                        {revenue?.is_recurring === "Yes" && (
                            <>
                                <Row className="mt-3">
                                    <Col md={2} className="font-weight-bold">
                                        Interval:
                                    </Col>
                                    <Col md={2}>{revenue.interval}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={2} className="font-weight-bold">
                                        Start date:
                                    </Col>
                                    <Col md={2}>{revenue.start_date}</Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={2} className="font-weight-bold">
                                        End date:
                                    </Col>
                                    <Col md={2}>{revenue.end_date}</Col>
                                </Row>
                            </>
                        )}
                    </div>
                    <h6>Billing details</h6>
                    <div className="bg-light p-4 rounded mb-3">
                        {revenue && project && (
                            <>
                                <Row className="my-4">
                                    <Col md={6}>
                                        <h4 className="mb-3 mx-4">
                                            Revenue from:
                                        </h4>
                                        <Row className="mx-3 mt-3">
                                            <Col
                                                md={4}
                                                className="font-weight-bold"
                                            >
                                                Company name
                                            </Col>
                                            <Col md={6}>
                                                Intellect Engineering Pte Ltd
                                            </Col>
                                        </Row>
                                        <Row className="mx-3 mt-3">
                                            <Col
                                                md={4}
                                                className="font-weight-bold"
                                            >
                                                Fax
                                            </Col>
                                            <Col md={5}>(65) 6278 7359</Col>
                                        </Row>
                                        <Row className="mx-3 mt-3">
                                            <Col
                                                md={4}
                                                className="font-weight-bold"
                                            >
                                                Contact number
                                            </Col>
                                            <Col md={5}>(65) 6661 0671</Col>
                                        </Row>
                                        <Row className="mx-3 mt-3">
                                            <Col
                                                md={4}
                                                className="font-weight-bold"
                                            >
                                                Address
                                            </Col>
                                            <Col md={5}>
                                                4 Tuas View Square, 637577
                                                Singapore
                                            </Col>
                                        </Row>
                                    </Col>
                                    {project.portfolio && (
                                        <Col md={6}>
                                            <h4 className="mb-3 mx-4">
                                                Revenue to:
                                            </h4>
                                            <Row className="mx-3 mt-3">
                                                <Col
                                                    md={4}
                                                    className="font-weight-bold"
                                                >
                                                    Business name
                                                </Col>
                                                <Col md={5}>
                                                    {project.portfolio.name ||
                                                        "-"}
                                                </Col>
                                            </Row>
                                            <Row className="mx-3 mt-3">
                                                <Col
                                                    md={4}
                                                    className="font-weight-bold"
                                                >
                                                    Email
                                                </Col>
                                                <Col md={5}>
                                                    {project.portfolio.email ||
                                                        "-"}
                                                </Col>
                                            </Row>
                                            <Row className="mx-3 mt-3">
                                                <Col
                                                    md={4}
                                                    className="font-weight-bold"
                                                >
                                                    Contact number
                                                </Col>
                                                <Col md={5}>
                                                    {project.portfolio
                                                        .contact_number || "-"}
                                                </Col>
                                            </Row>
                                            <Row className="mx-3 mt-3">
                                                <Col
                                                    md={4}
                                                    className="font-weight-bold"
                                                >
                                                    Address
                                                </Col>
                                                <Col md={5}>
                                                    {project.portfolio
                                                        .address || "-"}
                                                </Col>
                                            </Row>
                                        </Col>
                                    )}
                                </Row>
                            </>
                        )}
                    </div>
                    <Formik
                        enableReinitialize
                        initialValues={inputFields}
                        validationSchema={schema}
                        onSubmit={async (
                            values,
                            { setErrors, setStatus, setSubmitting }
                        ) => {
                            try {
                                await submitEditRevenue(values);

                                navigate(
                                    `/intellidocs/process/revenues/${id}`,
                                    {
                                        state: {
                                            open: true,
                                            notifMsg: `Successfully updated revenue status`,
                                            severity: "success",
                                        },
                                    }
                                );
                            } catch (error) {
                                const message =
                                    error.message || "Something went wrong";

                                setStatus({ success: false });
                                setErrors({ submit: message });
                                setSubmitting(false);
                                setNotif({
                                    notifMsg:
                                        "Something went wrong with the server",
                                    open: true,
                                    severity: "danger",
                                });
                            }
                        }}
                    >
                        {({
                            errors,
                            handleChange,
                            handleSubmit,
                            touched,
                            values,
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                {errors.submit && (
                                    <Alert className="my-3" variant="danger">
                                        <div className="alert-message">
                                            {errors.submit}
                                        </div>
                                    </Alert>
                                )}
                                <h6>Revenue status</h6>
                                <div className="bg-light p-4 rounded">
                                    <Row className="mt-3">
                                        <Col
                                            md={3}
                                            className="font-weight-bold"
                                        >
                                            Status:
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3">
                                                <Form.Select
                                                    name="status"
                                                    id="status"
                                                    value={values.status}
                                                    isInvalid={Boolean(
                                                        errors.status &&
                                                            touched.status
                                                    )}
                                                    onChange={handleChange}
                                                >
                                                    <option value="Registered">
                                                        Registered
                                                    </option>
                                                    <option value="Payment confirmed">
                                                        Payment confirmed
                                                    </option>
                                                    <option value="Payment received">
                                                        Payment received
                                                    </option>
                                                </Form.Select>
                                                {errors.status && (
                                                    <Form.Control.Feedback type="invalid">
                                                        Status is a required
                                                        field
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col
                                            md={3}
                                            className="font-weight-bold"
                                        >
                                            Remarks:
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    name="remarks"
                                                    as="textarea"
                                                    type="text"
                                                    className="mb-3"
                                                    value={values.remarks || ""}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>{" "}
                                    <Row className="mt-3">
                                        <Col
                                            md={3}
                                            className="font-weight-bold"
                                        >
                                            Attachments: (Optional)
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <FileUpload
                                                    files={files}
                                                    setFiles={setFiles}
                                                />
                                                {revenue &&
                                                    revenue.attachments.map(
                                                        (element, index) => {
                                                            return (
                                                                <React.Fragment
                                                                    key={index}
                                                                >
                                                                    {index ===
                                                                        0 && (
                                                                        <>
                                                                            Uploaded:{" "}
                                                                            <br />
                                                                        </>
                                                                    )}
                                                                    <Button
                                                                        href={
                                                                            element.full_path
                                                                        }
                                                                        target="_blank"
                                                                        variant="link"
                                                                        className="m-0 px-0"
                                                                    >
                                                                        {
                                                                            element.file_name
                                                                        }
                                                                    </Button>
                                                                    <Button
                                                                        variant="primary"
                                                                        onClick={() =>
                                                                            deleteUploadedFiles(
                                                                                element.id,
                                                                                revenue.attachments,
                                                                                element.full_path
                                                                            )
                                                                        }
                                                                        className="ms-2"
                                                                        size="sm"
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faWindowClose
                                                                            }
                                                                        />
                                                                    </Button>
                                                                    <br />
                                                                </React.Fragment>
                                                            );
                                                        }
                                                    )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                                <Card.Footer className="text-center bg-none pb-0">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className="me-2"
                                        size="lg"
                                    >
                                        <FontAwesomeIcon icon={faPaperPlane} />{" "}
                                        Save
                                    </Button>
                                    <Button
                                        variant="link"
                                        onClick={() =>
                                            navigate(
                                                `/intellidocs/process/revenues/${id}`
                                            )
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </Card.Footer>
                            </Form>
                        )}
                    </Formik>
                </Card.Body>
            </Card>

            {/* Supporting components */}

            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};
