import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Edit2, Eye, Trash } from "react-feather";
import { useNavigate } from "react-router-dom";

export const SubModuleButtons = ({
    module,
    cellId,
    projectId = null,
    openDeleteModal,
    parentModule,
    subModule,
}) => {
    const navigate = useNavigate();

    return (
        <>
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id={`tooltip-top`}>
                        Edit {module.slice(0, -1)}
                    </Tooltip>
                }
            >
                <Button
                    variant="outline-primary"
                    className="me-1 mb-1"
                    onClick={() =>
                        navigate(
                            `/intellidocs/process/${parentModule}/${projectId}/${subModule}/do/${cellId}`
                        )
                    }
                >
                    <Edit2 className="align-middle" size={18} />
                </Button>
            </OverlayTrigger>
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id={`tooltip-top`}>
                        View {module.slice(0, -1)}
                    </Tooltip>
                }
            >
                <Button
                    variant="outline-primary"
                    className="me-1 mb-1"
                    onClick={() =>
                        navigate(
                            `/intellidocs/process/${parentModule}/${projectId}/${subModule}/do/${cellId}/view`
                        )
                    }
                >
                    <Eye className="align-middle" size={18} />
                </Button>
            </OverlayTrigger>
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id={`tooltip-top`}>
                        Delete {module.slice(0, -1)}
                    </Tooltip>
                }
            >
                <Button
                    variant="outline-primary"
                    className="me-1 mb-1"
                    onClick={() => openDeleteModal(cellId)}
                >
                    <Trash className="align-middle" size={18} />
                </Button>
            </OverlayTrigger>
        </>
    );
};
