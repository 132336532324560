import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Search } from "react-feather";
import Select from "react-select";
import { addDefaultOption } from "../../../utils/utility";

const SearchRenewal = ({
    setSearchValue,
    searchValue,
    search,
    defaultConfig,
    resetSearch,
}) => {
    return (
        <>
            <Row>
                <Col md={10}>
                    <Row>
                        <Col md={2}>
                            <Form.Control
                                size="lg"
                                type="text"
                                name="fin"
                                placeholder="NRIC/FIN"
                                onChange={(e) =>
                                    setSearchValue({
                                        ...searchValue,
                                        fin: e.target.value,
                                    })
                                }
                            />
                        </Col>
                        {/* <Col md={3}>
              <Form.Control
                size="lg"
                type="date"
                name="date"
                value={searchValue?.date}
                onChange={(e) =>
                  setSearchValue({ ...searchValue, date: e.target.value })
                }
              />
            </Col> */}
                        <Col md={3}>
                            <Select
                                className="is-invalid react-select-container"
                                classNamePrefix="react-select"
                                options={addDefaultOption(
                                    defaultConfig?.registrationOption
                                )}
                                name="registration_type"
                                onChange={(value) => {
                                    setSearchValue({
                                        ...searchValue,
                                        registration_type: value.value,
                                    });
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <Button
                                size="lg"
                                variant="info"
                                className="me-1 mb-1"
                                onClick={() => search({ page: 1 })}
                            >
                                <Search className="feather" />
                            </Button>
                            {(searchValue.fin !== "" ||
                                searchValue.registration_type !== "" ||
                                searchValue.date !== "") && (
                                <Button
                                    size="lg"
                                    variant="warning"
                                    className="me-1 mb-1"
                                    onClick={() => resetSearch()}
                                >
                                    Reset
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default SearchRenewal;
