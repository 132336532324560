import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { SnackbarAlert } from "../../../components/SnackbarAlert.js";
import { DeleteModal } from "../../../components/DeleteModal";
import api from "../../../service/api";
import _ from "lodash";
import { TablePagination } from "../../../components/TablePagination.js";
import { ReceivedPaymentSearch } from "./ReceivedPaymentSearch.js";

export const ReceivedPaymentListing = ({
    module,
    columns,
    rawData,
    parentApi = null,
}) => {
    const navigate = useNavigate();
    let { id, action } = useParams();
    const location = useLocation();
    const state = location.state;

    //
    // States
    //

    const [rerender, setRerender] = useState(false);
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const [searchValue, setSearchValue] = useState({
        payment_code: "",
        payment_date: "",
    });

    //
    // Functions
    //

    const search = (page) => {
        const searchParam = { ...searchValue, ...page };
        parentApi(searchParam);
    };

    const openDeleteModal = (id) => {
        setModalInfo({
            id: id,
            notifMsg: "Are you sure you want to void this item?",
            open: true,
            severity: "danger",
        });
    };

    const deleteApi = async (id) => {
        try {
            await api.delete(`received-payments/${id}`, {});
            setRerender(!rerender);
            setNotif({
                notifMsg: "Delete successful",
                open: true,
                severity: "danger",
            });
        } catch (error) {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    //
    // useEffects
    //

    useEffect(() => {
        if (parentApi !== null && rerender) {
            parentApi();
            setRerender(!rerender);
        }
    }, [rerender, parentApi]);

    useEffect(() => {
        if (state?.open) {
            setNotif({
                notifMsg: state.notifMsg,
                open: state.open,
                severity: state.severity,
            });
        }
        window.history.replaceState(null, "");
    }, [state]);

    return (
        <>
            <Card className="shadow-none">
                <Card.Header className="shadow-none pb-0">
                    <Card.Title tag="h5">
                        {_.capitalize(module)} list
                    </Card.Title>
                </Card.Header>
                <Card.Body className="pb-0">
                    <div className="bg-light p-4 rounded">
                        <ReceivedPaymentSearch
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                            search={search}
                        />

                        <TablePagination
                            project="intellidocs"
                            module={module}
                            columns={columns}
                            rawData={rawData}
                            parentApi={parentApi}
                            openDeleteModal={openDeleteModal}
                            parentModule="revenues"
                            subModule={action}
                        />
                    </div>
                </Card.Body>
                <Card.Footer className="text-center pb-0">
                    <Button
                        variant="primary"
                        className="me-2"
                        size="lg"
                        onClick={() =>
                            navigate(`/intellidocs/process/revenues/${id}`)
                        }
                    >
                        <FontAwesomeIcon icon={faArrowLeft} /> Revenue
                    </Button>
                </Card.Footer>
            </Card>

            {/* Modal Components */}

            <SnackbarAlert notif={notif} setNotif={setNotif} />
            <DeleteModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={deleteApi}
            />
        </>
    );
};
