import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Eye, Edit} from "react-feather";

import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthContext } from "../../../../../contexts/JWTContext";
import { setLocalStorageValue } from "../../../../../utils/utility";

const ListButton = ({setModalPopUp, rowData, setValue, courseList, setSelectedCourse,setFeeId, setHistory,selected,setSelected }) => {
    /** permissions */
    const { data } = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = data?.permissions;

    function handleReference (label) {
        const item = courseList.find(item => item.label === label);

        setSelectedCourse(item)
        return item ?? null;
    }
    // When setting react select it needs both label and value
    // band-aid solution for now
    function createArray(value){
        if(!value){
            return null;
        }
        return {
            'label': value,
            'value': value
        }
    }

    const setValues =()=> {
        //hardcoded for now
        //refer to TableColumns for index numbe
        const state = {
            type_of_trade: createArray(rowData[4]?.value),
            application_option:  createArray(rowData[5]?.value),
            trade_category: createArray(rowData[7]?.value)
        };
        setSelected(state)
        setFeeId(rowData[8]?.value)
        setValue("course_code", rowData[2]?.value)
        setValue("course", handleReference(rowData[6]?.value))
        setValue("fee", rowData[3]?.value) 
    }


    const setHistoryLogs =()=>{
        setHistory([...rowData[9]?.value])
    }
    

    return (
        <>
            {permission?.includes("FEE-READ") && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>View Changes</Tooltip>}
                >
                    <span>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            className="me-1"
                            onClick={() => {
                                setHistoryLogs()
                                setModalPopUp(true, "view");
                            }}
                        >
                            <span>
                                <Eye size="20" color="#6c757d" />
                            </span>
                        </a>
                    </span>
                </OverlayTrigger>
            )}
            {permission?.includes("FEE-EDIT") && (
                <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Edit</Tooltip>}
                >
                    <span>
                        <a
                            className="me-1"
                            onClick={() => {
                                setValues()
                                setModalPopUp(true, "edit");
                            }}
                        >
                            <span>
                                <Edit size="20" color="#6c757d" />
                            </span>
                        </a>
                    </span>
                </OverlayTrigger>
            )}
        </>
    );
};
export default ListButton;
