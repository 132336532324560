import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import Account from "./Account";
import Password from "./Password";
import Settings from "./Settings";
import { useSelector } from "react-redux";

const Profile = () => {
    const [activeTab, setActiveTab] = useState("account");

    const { data } = useSelector((state) => {
        return state?.user ? state?.user : state;
    });

    return (
        <React.Fragment>
            <Helmet title="Profile" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Profile</h1>
                <Row>
                    <Col md="3" xl="2">
                        <Card>
                            <ListGroup variant="flush">
                                <ListGroup.Item
                                    href=""
                                    action
                                    onClick={() => {
                                        setActiveTab("account");
                                    }}
                                    active={activeTab === "account"}
                                >
                                    Account
                                </ListGroup.Item>
                                <ListGroup.Item
                                    href=""
                                    action
                                    onClick={() => {
                                        setActiveTab("password");
                                    }}
                                    active={activeTab === "password"}
                                >
                                    Password
                                </ListGroup.Item>
                                <ListGroup.Item
                                    href=""
                                    action
                                    onClick={() => {
                                        setActiveTab("settings");
                                    }}
                                    active={activeTab === "settings"}
                                >
                                    Settings
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Col>
                    <Col md="9" xl="10">
                        {activeTab === "account" ? <Account data={data} /> : ""}
                        {activeTab === "password" ? (
                            <Password data={data} />
                        ) : (
                            ""
                        )}
                        {activeTab === "settings" ? (
                            <Settings data={data} />
                        ) : (
                            ""
                        )}
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default Profile;
