import React from "react";

import { Card, Col, Form, Row } from "react-bootstrap";

import { MOCK_USERS } from "../../../mockData";

import "react-datepicker/dist/react-datepicker.css";

export const AddEditRoles = ({ errors, register }) => {
    return (
        <Card>
            <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                    Roles
                </Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
                <Form.Group>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="ceo">CEO *</Form.Label>
                                <Form.Select
                                    id="ceo"
                                    isInvalid={Boolean(errors?.roles?.ceo)}
                                    {...register("roles.ceo")}
                                >
                                    <option value=""></option>
                                    {MOCK_USERS.map((data, index) => {
                                        return (
                                            <option key={index} value={data}>
                                                {data}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                                {errors?.roles?.ceo && (
                                    <Form.Control.Feedback type="invalid">
                                        CEO is a required field
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="projectManager">
                                    Project manager *
                                </Form.Label>
                                <Form.Select
                                    id="projectManager"
                                    isInvalid={Boolean(
                                        errors?.roles?.projectManager
                                    )}
                                    {...register("roles.projectManager")}
                                >
                                    <option value=""></option>
                                    {MOCK_USERS.map((data, index) => {
                                        return (
                                            <option key={index} value={data}>
                                                {data}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                                {errors?.roles?.projectManager && (
                                    <Form.Control.Feedback type="invalid">
                                        Project manager is a required field
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="projectDirector">
                                    Project director *
                                </Form.Label>
                                <Form.Select
                                    id="projectDirector"
                                    isInvalid={Boolean(
                                        errors?.roles?.projectDirector
                                    )}
                                    {...register("roles.projectDirector")}
                                >
                                    <option value=""></option>
                                    {MOCK_USERS.map((data, index) => {
                                        return (
                                            <option key={index} value={data}>
                                                {data}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                                {errors?.roles?.projectDirector && (
                                    <Form.Control.Feedback type="invalid">
                                        Project director is a required field
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="qsManager">
                                    QS Manager *
                                </Form.Label>
                                <Form.Select
                                    id="qsManager"
                                    isInvalid={Boolean(
                                        errors?.roles?.qsManager
                                    )}
                                    {...register("roles.qsManager")}
                                >
                                    <option value=""></option>
                                    {MOCK_USERS.map((data, index) => {
                                        return (
                                            <option key={index} value={data}>
                                                {data}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                                {errors?.roles?.qsManager && (
                                    <Form.Control.Feedback type="invalid">
                                        QS manager is a required field
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <small>* Required fields</small>
                        </Col>
                    </Row>
                </Form.Group>
            </Card.Body>
        </Card>
    );
};
