const tableColumns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "Person incharge",
    accessor: "person_in_charge",
  },
  {
    Header: "Programmes",
    accessor: "programmes",
  },
  {
    Header: "Actions",
    accessor: "actions"
  }
];

export { tableColumns };
