import React, { useEffect } from "react";
import { useTable, useRowSelect } from "react-table";
import { Table } from "react-bootstrap";
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        );
    }
);
const StudentList = ({ data, columns, setSelectedUser, setStudentTextbox }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data,
        },
        useRowSelect,

        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox
                                {...getToggleAllRowsSelectedProps()}
                            />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox
                                {...row.getToggleRowSelectedProps()}
                            />
                        </div>
                    ),
                },
                ...columns,
            ]);
        }
    );
    useEffect(() => {
        setSelectedUser(selectedFlatRows);
    }, [selectedFlatRows, setSelectedUser]);
    return (
        <>
            {data.length > 0 && (
                <Table responsive striped bordered {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((cell, i, row) => {
                            
                            prepareRow(cell);
                            return (
                                <tr {...cell.getRowProps()}>
                                    {cell.cells.map((cell, index, row) => {
                                        if (index + 1 === row.length) {
                                            return (
                                                <td>
                                                    <input
                                                        width={200}
                                                        type="number"
                                                        disabled={!selectedFlatRows[i]}
                                                        onChange={(event) => {
                                                            setStudentTextbox("additional_training", event, i);
                                                        }}
                                                    />
                                                </td>
                                            );
                                        }
                                        if (index + 2 === row.length) {
                                            return (
                                                <td>
                                                    <input
                                                        name="addit_training"
                                                        type="number"
                                                        disabled={!selectedFlatRows[i]}
                                                        onChange={(event) => {
                                                            setStudentTextbox("no_session", event, i);
                                                        }}
                                                    />
                                                </td>
                                               
                                            );
                                        }
                                        return (
                                            
                                            <td {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                       
                                    })}
                                    
                                </tr>
                            );
                            
                        })}
                    </tbody>
                </Table>
            )}
        </>
    );
};
export default StudentList;
