import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Form, Row } from "react-bootstrap";
import { Filter } from "react-feather";
import Select from "react-select";
import { addDefaultOption } from "../../../../utils/utility";
import { DatePicker } from "antd";
import moment from "moment";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Search = ({ setSearchValue, searchValue, search, configValues, generateRoadshowExcel}) => {
    return (
        <>
            <Row>
                {/* Sales person name */}
                {/* <Col md={2} className="mb-2">
                    <Form.Control
                        type="text"
                        name="sales_person_name"
                        placeholder="Sales person name"
                        size="lg"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                sales_person_name: event.target.value,
                            });
                        }}
                    />
                </Col> */}

                {/* Record number */}
                {/* <Col md={2} className="mb-2">
                    <Form.Control
                        type="text"
                        name="number"
                        placeholder="Record no"
                        size="lg"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                number: event.target.value,
                            });
                        }}
                    />
                </Col> */}

                {/* Customer name and fin */}
                <Col md={4} className="mb-2">
                    <Form.Control
                        type="text"
                        name="customer"
                        placeholder="Customer name/FIN"
                        size="lg"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                customer: event.target.value,
                            });
                        }}
                    />
                </Col>

                {/* Department */}
                {/* <Col md={2} className="mb-2">
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={addDefaultOption(configValues?.departments)}
                        name="department"
                        placeholder="Department"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                department: event.value,
                            });
                        }}
                    />
                </Col> */}

                {/* Course name */}
                <Col md={4} className="mb-2">
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={addDefaultOption(configValues?.courses)}
                        name="course_id"
                        placeholder="Course name"
                        isClearable={true}
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                course_id: event?.value,
                            });
                        }}
                    />
                </Col>

                {/* Type of trade */}
                {/* <Col md={2} className="mb-2">
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={addDefaultOption(configValues?.tradeOptions)}
                        name="type_of_trade"
                        placeholder="Type of trade"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                type_of_trade: event.value,
                            });
                        }}
                    />
                </Col> */}

                {/* Location */}
                <Col md={4} className="mb-2">
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={addDefaultOption(configValues?.location)}
                        name="location"
                        placeholder="Location"
                        isClearable={true}
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                location: event?.value,
                            });
                        }}
                    />
                </Col>
            </Row>
            <Row className="mt-1">
                {/* Department */}
                <Col md={4} className="mb-2">
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={addDefaultOption([
                            {
                                value: 'ATO',
                                label: 'ATO'
                            },
                            {
                                value: 'ATTC',
                                label: 'ATTC'
                            },
                        ])}
                        name="department"
                        placeholder="Department"
                        isClearable={true}
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                department: event?.value,
                            });
                        }}
                    />
                </Col>
                <Col md={4} className="mb-2">
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={addDefaultOption(configValues?.item_codes)}
                        name="item_code"
                        placeholder="Item code"
                        isClearable={true}
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                item_code: event?.value,
                            });
                        }}
                    />
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                    {/* Daterange */}
                    <Col>
                        <Row className="flex-row flex-row ms-md-1">
                            <Col md={5} className="p-md-0">
                                <DatePicker
                                    id="from-date-picker"
                                    name="from_date"
                                    className={`form-control`}
                                    placeholder="From date"
                                    // defaultValue={moment().startOf('month')}
                                    // allowClear={false}
                                    onChange={(event) => {
                                        setSearchValue({
                                            ...searchValue,
                                            start_date: event ? moment(event).format("YYYY-MM-DD") : null,
                                        });
                                    }}
                                    style={{minHeight: '2.5rem'}}
                                />
                            </Col>
                            <Col md={2} className="d-flex align-items-center justify-content-center m-0 p-0">
                                <Badge bg='info' className="d-flex align-items-center justify-content-center text-center w-100 h-100 mx-2 mx-md-0">
                                    To
                                </Badge>
                            </Col>
                            <Col md={5} className="p-md-0">
                                <DatePicker
                                    id="to-date-picker"
                                    name="to_date"
                                    className={`form-control`}
                                    placeholder="To date"
                                    // defaultValue={moment().endOf('month')}
                                    // allowClear={false}
                                    onChange={(event) => {
                                        setSearchValue({
                                            ...searchValue,
                                            end_date: event ? moment(event).format("YYYY-MM-DD") : null,
                                        });
                                    }}
                                    style={{minHeight: '2.5rem'}}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Col>
                
            </Row>
            <Row className="d-flex justify-content-end">
                <Col md={6} className="d-flex justify-content-start text-start">
                    <Col md={4}>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={addDefaultOption(configValues?.item_type)}
                            name="item_type"
                            placeholder="Item Type"
                            isClearable={true}
                            onChange={(event) => {
                                setSearchValue({
                                    ...searchValue,
                                    item_type: event?.value,
                                });
                            }}
                        />
                    </Col>
                </Col>
                <Col md={6} className="mb-2 d-flex justify-content-end text-end align-items-center d-md-block">
                    <Button size="lg" variant="info" onClick={() => search()}>
                        <Filter size={15} />
                    </Button>

                    <Button
                        className="ms-2"
                        variant="info"
                        type="submit"
                        size="lg"
                        onClick={() => generateRoadshowExcel()}
                    >
                        <FontAwesomeIcon icon={faFileExcel} />
                    </Button>
                </Col>
            </Row>
        </>
    );
};
export default Search;
