// import { LOADING, SUCCEED, FAILED } from "./../action-types";

const userDetails = (user) => {
  return async (dispatch) => {
    dispatch({
      type: "USER_LOADING",
    });
    try {
      dispatch({
        type: "USER_SUCCEED",
        payload: user,
      });
    } catch (error) {
      dispatch({
        type: "USER_FAILED",
        payload: error,
      });
    }
  };
};

export { userDetails };
