import React from "react";

import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { scrollToTop } from "../utils/utility";

export const HelperButtons = () => {
    return (
        <>
            <span
                style={{
                    position: "fixed",
                    bottom: 60,
                    right: 10,
                    cursor: "pointer",
                }}
                onClick={() => scrollToTop()}
            >
                <FontAwesomeIcon icon={faArrowUp} size="2x" />
            </span>
        </>
    );
};
