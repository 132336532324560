const tableColumns = [
    {
        Header: "S/N",
        accessor: "serial",
    },
    {
        Header: "Payment code",
        accessor: "code",
    },
    {
        Header: "To",
        accessor: "payment_to",
    },
    {
        Header: "Payment date",
        accessor: "payment_date",
    },
    {
        Header: "Total paid (S$)",
        accessor: "total",
    },
    {
        Header: "Actions",
        accessor: "action ",
    },
];

export { tableColumns };
