import React, { useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
// import { useNavigate, useParams } from "react-router-dom";
import { Table, Row, Col, Alert, Pagination } from "react-bootstrap";

import api from "../../../../service/api";

import { DeleteModal } from "../../../../components/DeleteModal";
import { CloneModal } from "../../../../components/CloneModal";
import { SnackbarAlert } from "../../../../components/SnackbarAlert";
import ListButton from "./ListButton";
import {
    formatNumberWithCommas, listTableNumberFormat,
} from "../../../../utils/utility";

const Lists = ({ columns, rawData, quotationApi, setLoading, setError, setData, setNotif }) => {
    // const navigate = useNavigate();
    // const params = useParams();
    // const [notif, setNotif] = useState({
    //     notifMsg: "",
    //     open: false,
    //     severity: "success",
    // });
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });

    const [cloneModalInfo, setCloneModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "primary",
    })

    const { meta, links, data } = rawData;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: {
            pageSize,
            canNextPage,
            canPreviousPage,
            totalPage,
            currentPage,
            firstPage,
            lastPage,
        },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: meta?.per_page,
                canNextPage: links?.next !== null ? true : false,
                canPreviousPage: links?.prev !== null ? true : false,
                totalPage: meta?.last_page,
                currentPage: meta?.current_page,
                firstPage: meta?.first,
                lastPage: meta?.last_page,
            },
        },
        useSortBy,
        usePagination
    );
    const gotoPage = (page) => {
        quotationApi({
            page,
        });
    };

    const deleteCompany = async (id) => {
        try {
            await api.delete(`tms/sales-quotations/${id}`, {});
            setNotif({
                id: id,
                notifMsg: "Deleted successfully",
                open: true,
                severity: "success",
            });
            quotationApi();
        } catch (error) {
            setNotif({
                id: id,
                notifMsg: error?.response?.data?.message ?? "Something went wrong.",
                open: true,
                severity: "danger",
            });
        }
    };

    const openDeleteModal = (id) => {
        setModalInfo({
            id: id,
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            severity: "danger",
        });
    };

    const openCloneModal = (id) => {
        setCloneModalInfo({
            id: id,
            notifMsg: "Are you sure you want to clone this item?",
            open: true,
            severity: "primary",
        })
    }

    return (
        <>
            {data.length > 0 && (
                <Row>
                    {totalPage > 1 && (
                        <Row>
                            <Col md="6">
                                <span className="mx-2">
                                    Page {""}
                                    <strong>
                                        {currentPage} of {totalPage}
                                    </strong>
                                </span>
                            </Col>
                            <Col md="6">
                                <Pagination className="float-end">
                                    <Pagination.First
                                        onClick={() => gotoPage(firstPage)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPage(currentPage - 1)
                                        }
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPage(currentPage + 1)
                                        }
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(lastPage)}
                                        disabled={!canNextPage}
                                    />
                                </Pagination>
                            </Col>
                        </Row>
                    )}
                    <Table responsive striped bordered {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps()
                                            )}
                                        >
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell, index, row) => {
                                            if (index === 0) {
                                                return (
                                                    <td key={index}>
                                                        {currentPage > 1
                                                            ? (currentPage -
                                                                  1) *
                                                                  pageSize +
                                                              i +
                                                              1
                                                            : i + 1}
                                                    </td>
                                                );
                                            }
                                            if (cell.column.Header.toLowerCase() == "amount") {
                                                return(listTableNumberFormat(cell, cell.value));
                                            }
                                            if (index + 1 === row.length) {
                                                return (
                                                    <td key={index}>
                                                        <ListButton
                                                            status={
                                                                cell.row
                                                                    .original
                                                                    .status_key
                                                            }
                                                            id={
                                                                cell.row
                                                                    .original.id
                                                            }
                                                            openDeleteModal={
                                                                openDeleteModal
                                                            }
                                                            openCloneModal={openCloneModal}
                                                            currentPage={
                                                                currentPage
                                                            }
                                                            invoice_number={
                                                                cell.row
                                                                    .original
                                                                    .number
                                                            }
                                                            rawData={
                                                                cell.row
                                                                    .original
                                                            }
                                                            refresh = {quotationApi}
                                                            setLoading={setLoading}
                                                            setError={setError}
                                                            setData={setData}
                                                            setNotif={setNotif}
                                                        />
                                                    </td>
                                                );
                                            }
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    {totalPage > 1 && (
                        <Row>
                            <Col md="6">
                                <span className="mx-2">
                                    Page {""}
                                    <strong>
                                        {currentPage} of {totalPage}
                                    </strong>
                                </span>
                            </Col>
                            <Col md="6">
                                <Pagination className="float-end">
                                    <Pagination.First
                                        onClick={() => gotoPage(firstPage)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPage(currentPage - 1)
                                        }
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPage(currentPage + 1)
                                        }
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(lastPage)}
                                        disabled={!canNextPage}
                                    />
                                </Pagination>
                            </Col>
                        </Row>
                    )}
                </Row>
            )}
            {data.length === 0 && (
                <Alert variant="warning">
                    <div className="alert-message">No sales quotations</div>
                </Alert>
            )}
            <DeleteModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={deleteCompany}
            />
            <CloneModal
                modalInfo={cloneModalInfo}
                setModalInfo={setCloneModalInfo}
            />
            {/* <SnackbarAlert notif={notif} setNotif={setNotif} /> */}
        </>
    );
};

export { Lists };
