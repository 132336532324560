import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
    calculateCommonSubtotal,
    commonBeforeGSTAmount,
    formatNumberWithCommas,
    roundToTwoDecimalDigits,
} from "../../../../../utils/utility";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";
import CourseInformation from "./CourseInformation";
import { Plus, Trash } from "react-feather";
import { useParams } from "react-router-dom";

const ItemRow = ({
    configValues,
    control,
    setValue,
    getValues,
    courseFee,
    errors,
    AdditionalInvoice,
    addtionalMainInvoice,
    additionalType,
    reset,
    invoiceStudent,
    mainInvoiceStudent,
    defaultStudentsData,
    userChangedAdditionalType,
    additionalCourses
}) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name: "items",
    });
    let { action } = useParams();
    const [subtotals, setSubtotals] = useState([]);
    const [originalSubtotals, setOriginalSubtotals] = useState([]);
    const [cumulativeSubtotal, setCumulativeSubtotal] = useState(0);
    const [gstAmount, setGstAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [noGST, setNoGST] = useState(false);
    const [sessionOptional, setSessionOptional] = useState([]);
    const gstRate = parseFloat(configValues.gst);
    // Add this state variable
    const [currentAdditionalType, setCurrentAdditionalType] = useState(additionalType);

    // Function to calculate the cumulative subtotal
    const calculateCumulativeSubtotal = () => {
        let newCumulativeSubtotal = 0;
        fields.forEach((field, index) => {
            const subtotal = calculateSubtotal(index);
            newCumulativeSubtotal += subtotal;
        });
        return newCumulativeSubtotal;
    };



    const calculateOriginalSubtotal = (index) => {
        const price = parseFloat(getValues(`items[${index}].original_price`));

        const totalStudents = parseFloat(
            getValues(`items[${index}].total_student`)
        );
        if (!isNaN(totalStudents)) {
            const subtotal = calculateCommonSubtotal(price, totalStudents);
            return roundToTwoDecimalDigits(subtotal);
        }

        return 0;
    };

    const calculateCumulativeOriginalSubtotal = () => {
        let newCumulativeSubtotal = 0;

        fields.forEach((field, index) => {
            const subtotal = calculateOriginalSubtotal(index);
            newCumulativeSubtotal += subtotal;
        });

        return newCumulativeSubtotal;
    };

    const updateTotals = async () => {
        const newCumulativeSubtotal = calculateCumulativeSubtotal();
        let newCumulativeOriginalSubtotal =
            calculateCumulativeOriginalSubtotal();
        const totalAmount = newCumulativeOriginalSubtotal;
        let newGstAmount = totalAmount - newCumulativeSubtotal;

        if (noGST || additionalType === "no_gst") {
            newGstAmount = 0;
        }
        // Calculate the total amount as the difference between

        setCumulativeSubtotal(newCumulativeSubtotal);
        setGstAmount(newGstAmount);
        setTotalAmount(totalAmount);
        // setTotalDiscount(newTotalDiscount);
        setValue("subtotals", newCumulativeSubtotal);
        setValue("gst_amount", roundToTwoDecimalDigits(newGstAmount));
        setValue("total_amount", totalAmount);
    };

    const setTotalandGst = (gst_amount) => {
        const newTotal = cumulativeSubtotal + gst_amount;
        setTotalAmount(newTotal);
        setValue("gst_amount", roundToTwoDecimalDigits(gst_amount));
        setValue("total_amount", newTotal);
    };

    const addItem = () => {
        append({ total_student: 1, price: 0, item_type: "course" });
        setSubtotals([...subtotals, 0]);
    };

    const updatePriceAndTotals = (index, fee, gstRate) => {
        setValue(`items[${index}].original_price`, fee);
        if (additionalType === "with_gst") {
            setValue(
                `items[${index}].price`,
                commonBeforeGSTAmount(fee, gstRate)
            );
        } else {
            setValue(`items[${index}].price`, fee);
        }

        updateSubtotal(index);
        updateTotals();
    };

    const handleCourseChange = async (courseCodeId, index) => {
        setValue(`items[${index}].price`, courseFee[courseCodeId]?.fee);

        if (courseFee[courseCodeId]?.session_optional) {
            handleStudentSelected(index)
        } else {
            handleNoOfSession(index);
        }

        setSessionOptional((prevSessionOptonal) => {
            const newSessionOptional = [...prevSessionOptonal];

            if (newSessionOptional[index]) {
                newSessionOptional[index] = courseFee[courseCodeId]?.session_optional;
            } else {
                // If no existing array, create a new one
                newSessionOptional[index] = courseFee[courseCodeId]?.session_optional;
            }

            return newSessionOptional;
        });

        // Update price and totals
        updatePriceAndTotals(index, courseFee[courseCodeId]?.fee, gstRate);
    };


    // Calculate subtotal for a specific item
    const calculateSubtotal = (index) => {
        const field = fields[index];
        const isOther = field?.item_type === "others";

        let price = "0";
        if (additionalType === "no_gst") {
            price = parseFloat(getValues(`items[${index}].original_price`));
        } else {
            price = parseFloat(
                isOther
                    ? commonBeforeGSTAmount(
                        getValues(`items[${index}].price`),
                        gstRate
                    )
                    : getValues(`items[${index}].price`)
            );
        }

        const totalStudents = parseFloat(
            getValues(`items[${index}].total_student`)
        );
        if (!isNaN(totalStudents)) {
            const subtotal = calculateCommonSubtotal(price, totalStudents);
            return roundToTwoDecimalDigits(subtotal);
        }

        return 0;
    };

    const getOriginalPrice = (index) => {
        let priceValue = getValues(`items[${index}].original_price`);

        if (
            isNaN(priceValue) ||
            priceValue === undefined ||
            priceValue === ""
        ) {
            return "";
        }
        return formatNumberWithCommas(priceValue);
    };


    const updateSubtotal = (index) => {
        const newSubtotals = [...subtotals];
        const newOriginalSubtotals = [...originalSubtotals];
        newSubtotals[index] = calculateSubtotal(index);
        newOriginalSubtotals[index] = calculateOriginalSubtotal(index);
        setSubtotals(newSubtotals);
        setOriginalSubtotals(newOriginalSubtotals);
        // Update all totals after changing the subtotal
        updateTotals();
    };
    // Function to update all subtotals when "Apply no GST" is toggled
    const updateAllSubtotals = () => {
        const newSubtotals = fields.map((_, index) => calculateSubtotal(index));
        const newOriginalSubtotals = fields.map((_, index) =>
            calculateOriginalSubtotal(index)
        );
        setSubtotals(newSubtotals);
        setOriginalSubtotals(newOriginalSubtotals);
        // Update all totals after toggling "Apply no GST"
        updateTotals();
    };

    const removeItem = (index) => {
        // Remove the item from the list
        remove(index);
        // Update the subtotal for the removed item
        const newSubtotals = [...subtotals];
        newSubtotals.splice(index, 1);
        setSubtotals(newSubtotals);
        setOriginalSubtotals([...originalSubtotals]);
        // Update all totals after removing the item
        updateTotals();
    };
    useEffect(() => {
        const fetchData = async () => {
            // Update all subtotals when "Apply no GST" is toggled
            await updateAllSubtotals();
            // Update GST-related values
            if (
                AdditionalInvoice &&
                AdditionalInvoice.gst_amount !== undefined &&
                !noGST
            ) {
                setValue("gst_amount", AdditionalInvoice.gst_amount);
                setGstAmount(AdditionalInvoice?.gst_amount);
            }
        };

        fetchData(); // Call the async function
    }, [fields]);

    useEffect(() => {
        const fetchData = async () => {

            // Update all subtotals when "Apply no GST" is toggled
            await updateAllSubtotals();

            // Update GST-related values
            if (AdditionalInvoice && AdditionalInvoice.gst_amount !== undefined && !noGST) {
                setValue("gst_amount", AdditionalInvoice.gst_amount);
                setGstAmount(AdditionalInvoice?.gst_amount);
            }
        };

        fetchData(); // Call the async function
    }, [noGST]);

    // useEffect(() => {

    //     if (userChangedAdditionalType) {
    //         console.log('Before removal:', getValues('items'));
    //         fields.forEach((_, index) => remove(index));
    //         console.log('After removal:', getValues('items'));
    //         // Use a setTimeout to ensure that the remove operation is complete
    //         setTimeout(() => {
    //             // Reset the entire form
    //             reset();
    //             console.log('After removal and reset:', getValues('items'));
    //         }, 100);
    //     }
    // }, [additionalType, userChangedAdditionalType, setValue, getValues]);



    useEffect(() => {
        // Update all subtotals when "Apply no GST" is toggled
        setTotalAmount(parseFloat(cumulativeSubtotal) + parseFloat(gstAmount));
    }, [gstAmount]);

    const setStudentSelected = (index, data, event) => {
        if (sessionOptional[index]) {
            handleStudentSelected(index)
        } else {
            handleNoOfSession(index);
        }
        
        setValue(
            `items[${index}].additional_invoice_students`,
            AdditionalInvoice
        );
        updateSubtotal(index);
    };

    const handleFormInputStudTableChange = (name, event, key, indexOf) => {
        setValue(
            `items[${indexOf}].students[${key}].${name}`,
            event.target.value
        );

        if (name?.toLowerCase() === 'no_session' && !sessionOptional[indexOf]) {
            handleNoOfSession(indexOf)
        }
    };

    const handleStudentSelected = (index) => {
        let list = getValues(`items[${index}].students`) || [];
        let countTrue = 0;
        for (let i = 0; i < list.length; i++) {
            setValue(
                `items[${index}].students[${i}].no_session`,
                null
            );
            if (list[i]?.checked === true) {
                countTrue++;
            }
        }
        setValue(`items[${index}].total_student`, countTrue);
    }

    const handleNoOfSession = (index) => {
        let list = getValues(`items[${index}].students`) || [];
        let sumOfSessions = 0;
        for (let i = 0; i < list.length; i++) {
            if (list[i]?.checked === true) {
                const parsedValue = parseInt(list[i].no_session);
                sumOfSessions += isNaN(parsedValue) ? 0 : parsedValue;
            }
        }
        setValue(`items[${index}].total_student`, sumOfSessions);
        updateSubtotal(index);
    }

    useEffect(() => {
        // Initialize the `no_gst` checkbox based on `quotation.no_gst`
        setNoGST(AdditionalInvoice?.no_gst || false);
    }, [AdditionalInvoice]); // Run this effect when `quotation` changes

    return (
        <div>
            <table className="table">
                <thead>
                    <tr>
                        <th width="1%">#</th>
                        <th width="35%">Course information</th>
                        <th width="5%">Quantity</th>
                        <th width="10%">Price</th>
                        <th width="3%">Subtotal</th>
                        <th width="2%">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {fields.length === 0 && (
                        <tr>
                            <td colSpan={6}>No items</td>
                        </tr>
                    )}
                    {fields.map((field, index) => (
                        <tr key={field.id}>
                            <td style={{ verticalAlign: "top" }}>
                                {index + 1}
                            </td>
                            <td style={{ verticalAlign: "top" }}>
                                <>

                                    <CourseInformation
                                        control={control}
                                        index={index}
                                        configValues={configValues}
                                        handleCourseChange={handleCourseChange}
                                        errors={errors}
                                        setValue={setValue}
                                        invoiceStudent={invoiceStudent}
                                        handleFormInputStudTableChange={
                                            handleFormInputStudTableChange
                                        }
                                        setStudentSelected={setStudentSelected}
                                        indexOf={index}
                                        mainInvoiceStudent={mainInvoiceStudent}
                                        defaultStudentsData={defaultStudentsData}
                                        additionalCourses={additionalCourses}
                                        invoiceCourseId={field?.invoice_course_id || null}
                                        sessionOptional={sessionOptional}
                                    />
                                </>


                            </td>
                            <td style={{ verticalAlign: "top" }}>
                                <Controller
                                    name={`items[${index}].total_student`}
                                    defaultValue={field.item_type === 1}
                                    control={control}
                                    render={({ field: inputProps }) => (
                                        <Form.Control
                                            type="number"
                                            readOnly={true}
                                            {...inputProps}
                                            onChange={(e) => {
                                                const newValue = parseFloat(
                                                    e.target.value
                                                );
                                                inputProps.onChange(newValue);
                                                updateSubtotal(index);
                                            }}
                                        />
                                    )}
                                />
                            </td>
                            <td style={{ verticalAlign: "top" }} className="">
                                <Controller
                                    name={`items[${index}].original_price`}
                                    control={control}
                                    defaultValue={0}
                                    render={({ field: inputProps }) => (
                                        <>
                                            <Form.Control
                                                style={{ width: "100%" }}
                                                type="hidden"
                                                step="0.01"
                                                {...inputProps}
                                            />
                                        </>
                                    )}
                                />

                                <Row>

                                    <Col md={field.item_type !== "others" ? 12 : 6} >
                                        <Controller
                                            name={`items[${index}].price`}
                                            control={control}
                                            defaultValue={0}
                                            render={({ field: inputProps }) => (
                                                <>
                                                    <Form.Control
                                                        type="number"
                                                        step="0.01"
                                                        readOnly={true}
                                                        {...inputProps}
                                                        onChange={(e) => {
                                                            inputProps.onChange(e);
                                                            setValue(
                                                                `items[${index}].original_price`,
                                                                e.target?.value
                                                            );
                                                            updateSubtotal(index);
                                                        }}
                                                    />
                                                </>
                                            )}
                                        />

                                    </Col>
                                </Row>
                                <small>
                                    {additionalType === "with_gst" && (
                                        <small>After GST </small>
                                    )}
                                    {additionalType === "no_gst" && (
                                        <small>Before GST </small>
                                    )}
                                    {getOriginalPrice(index)}
                                </small>
                            </td>
                            <td style={{ verticalAlign: "top" }} className="currency"  >
                                {formatNumberWithCommas(subtotals[index])}
                            </td>
                            <td style={{ verticalAlign: "top" }}>
                               { action==='add' &&  
                                    <>
                                        <Button
                                            variant="danger"
                                            onClick={() => {
                                                removeItem(index);
                                            }}
                                        >
                                            <Trash size={10} />
                                        </Button>
                                    </>
                                }
                            </td>
                        </tr>
                    ))}
                    <tr>
                        {additionalType  && action==='add' && (
                            <>
                                <td colSpan={2} className="border-0">
                                    <Button
                                        variant="secondary"
                                        onClick={addItem}
                                        className="mt-1 mx-1 "
                                    >
                                        <Plus size={15}></Plus> Add item
                                    </Button>
                                </td>
                            </>
                        )}
                        {fields.length > 0 && (
                            <>
                                <td colSpan={additionalType  && action==='add' ? 2 : 4} className="text-end fs-4 border-0">
                                    Subtotal
                                </td>
                                <td className=" currency border-0">
                                    {formatNumberWithCommas(cumulativeSubtotal)}
                                </td>
                            </>
                        )}
                    </tr>
                    {fields.length > 0 && (
                        <>
                            {additionalType === "with_gst" && (
                                <>
                                    <tr>
                                        <td colSpan={4} className=" border-0"></td>
                                        <td colSpan={2} className=" border-0">
                                            <Controller
                                                name="no_gst" // Name should match the field name in your form data
                                                control={control} // Use RHF control
                                                defaultValue={false} // Set the initial value of the checkbox
                                                render={({ field }) => (
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Apply no GST"
                                                        checked={field.value}
                                                        onChange={(e) => {
                                                            setNoGST(
                                                                e.target.checked
                                                            );
                                                            field.onChange(e);
                                                            // updateAllSubtotals(); // Recalculate totals when the option is toggled
                                                        }}
                                                    />
                                                )}
                                            />
                                        </td>
                                    </tr>
                                </>
                            )}
                            <tr>
                                <td
                                    colSpan={4}
                                    className="text-end fs-4 border-0"
                                >
                                    Total tax amount{" "}
                                    {noGST || additionalType !== "with_gst"
                                        ? 0
                                        : configValues.gst}
                                    %
                                </td>
                                <td className=" border-0">
                                    <Controller
                                        name="gst_amount" // Name should match the field name in your form data
                                        control={control} // Use RHF control
                                        render={({ field }) => (
                                            <Form.Control
                                                type="number"
                                                readOnly={noGST || additionalType !== "with_gst"}
                                                {...field}
                                                step="0.01"
                                                onChange={(e) => {
                                                    setGstAmount(e.target.value);
                                                    field.onChange(e);
                                                }}
                                            />
                                        )}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={4} className="text-end fs-4 border-0">
                                    Total amount
                                </td>
                                <td className="currency border-0">
                                    {formatNumberWithCommas(totalAmount)}
                                </td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>

            <Controller
                name="subtotals"
                control={control}
                defaultValue="" // Initial value of the hidden field
                render={({ field }) => <input type="hidden" {...field} />}
            />
            <Controller
                name="total_amount"
                control={control}
                defaultValue="" // Initial value of the hidden field
                render={({ field }) => <input type="hidden" {...field} />}
            />
        </div>
    );
};

export default ItemRow;
