import { Badge } from "react-bootstrap";
import { Check, X } from "react-feather";

const tableColumns = [
  {
    Header: "S.no",
  },
  {
    Header: "Name",
    accessor: "enrolment.name",
  },
  {
    Header: "FIN",
    accessor: "enrolment.fin",
  },

  {
    Header: "Sponsor company",
    id: "sponsor_company",
    accessor: (data) => {
      return (
        <div>
          {data?.enrolment?.self_registration && (
            <Badge bg="primary">SELF</Badge>
          )}
          {!data?.enrolment?.self_registration && (
            <div>{data?.enrolment?.sponsor_company?.name}</div>
          )}
        </div>
      );
    },
  },
  // {
  //   Header: "Email Ids",
  //   id: "email_ids",
  //   accessor: (data) => {
  //     return (
  //       <div>
  //         {data.emails?.map((item, index) => (
  //           <Badge key={index}>{item?.toUpperCase()}</Badge>
  //         ))}
  //       </div>
  //     );
  //   },
  // },
  {
    Header: "Course",
    accessor: "enrolment.course.name",
  },
  {
    Header: "Trade category",
    accessor: "enrolment.trade_category",
  },
  {
    Header: "Result",
    accessor: "enrolment.result",
  },
  {
    Header: "Result date",
    accessor: "enrolment.result_date",
  },
  {
    Header: "Renewal date",
    accessor: "enrolment.renewal_date",
  },
  // {
  //   Header: "Schedule At",
  //   id: "schedule_at",
  //   accessor: (data) => {
  //     return (
  //       <div>
  //         {data?.schedule_option?.toUpperCase() === "SCHEDULE" && (
  //           <Badge bg="primary">{data?.schedule}</Badge>
  //         )}
  //         {data?.schedule_option?.toUpperCase() === "REJECTED" && (
  //           <Badge bg="danger">{data?.schedule_option}</Badge>
  //         )}
  //       </div>
  //     );
  //   },
  // },
  {
    Header: "Email sent date",
    accessor: (data) => {
        return (
          <div>
            {data?.email_sent_date ?? '-'}
          </div>
        );
      },
  },
  {
    Header: "Email sent",
    id: "email_sent",
    accessor: (data) => {
      return (
        <div>
          {data?.email_sent === true && (
            <Badge bg="success">
              <Check size={10}></Check>
            </Badge>
          )}
          {data?.email_sent === false && (
            <Badge bg="danger">
              <X size={10}></X>
            </Badge>
          )}
        </div>
      );
    },
  },

//   {
//     Header: "Actions",
//     accessor: "actions",
//   },
];

export { tableColumns };
