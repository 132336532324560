import { Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";

import { SnackbarAlert } from "../../../../../components/SnackbarAlert";
import api from "../../../../../service/api";
import { addDefaultOption } from "../../../../../utils/utility";

const FormRow = ({ creditNotes, loading, setLoading }) => {
    const creditNoteId = creditNotes?.id;
    const refundAmount = creditNotes?.amount ?? 0;
    const customerCredits = creditNotes?.customer_credits ?? 0;
    const [error, setError] = useState();
    const [initValues, setInitValues] = useState({
        date: "",
        amount: creditNotes?.amount ?? '',
        reference_number: "",
    });
    /** Notification state */
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    /** validatiob schema */
    const schema = Yup.object().shape({
        amount: Yup.string().required(),
        date: Yup.string().required(),
        reference_number: Yup.string(),
        payment_method: Yup.string().required(),
    });

    const handleAmountChange = (event) => {
        const { name, value } = event.target;

        setError(false);
        
        if (/^\d+(\.\d+)?$/.test(value) && parseFloat(value) <= parseFloat(customerCredits)) {
            event.target.value = value;
        } else {
            event.target.value = '';
            let err_msg = `Please enter a amount not greater than available Customer Credits $${customerCredits}.`;
            setNotif({
                notifMsg: err_msg,
                open: true,
                severity: "danger",
            });
            setError(err_msg);

        }
        const amount = event.target.value ? (parseFloat(event.target.value)).toFixed(2) : '';
        setInitValues({
            ...initValues,
            amount: amount,
        });
    };

    /** Create payment */
    const create = async (values) => {
        try {
            setLoading(true);
            await api.post(`tms/credit-notes/refund/${creditNoteId}`, values);
            // await getPayments(id); // get the payment history
            // await getInvoice(id); // get invoice details
            setLoading(false);
        } catch (error) {
            const message = error?.message || "Something went wrong";
            setNotif({
                notifMsg: message,
                open: true,
                severity: "danger",
            });
            setError(error?.message);
            setLoading(false);
        }
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <Formik
                        enableReinitialize
                        initialValues={initValues}
                        validationSchema={schema}
                        onSubmit={async (
                            values,
                            { setErrors, setStatus, setSubmitting }
                        ) => {
                            try {
                                setLoading(true);
                                await create(values);
                                setSubmitting(true);
                            } catch (error) {
                                const message =
                                    error?.message || "Something went wrong";
                                setNotif({
                                    notifMsg: message,
                                    open: true,
                                    severity: "danger",
                                });
                            }
                        }}
                    >
                        {({
                            errors,
                            handleSubmit,
                            values,
                            setFieldValue,
                            handleChange,
                        }) =>
                            !loading && (
                                <>
                                    <Form noValidate onSubmit={handleSubmit}>
                                        {error && (
                                            <Alert
                                                className="my-3"
                                                variant="danger"
                                            >
                                                <div className="alert-message">
                                                    {error}
                                                </div>
                                            </Alert>
                                        )}

                                        <Row>
                                            <Col md={4}>Amount</Col>
                                            <Col md={8}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control
                                                        type="number"
                                                        name="amount"
                                                        isInvalid={
                                                            errors?.amount
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(event) => handleAmountChange(event)}
                                                        placeholder={customerCredits ?? ''}
                                                        value={refundAmount ?? ''}
                                                        disabled
                                                    />
                                                    {!!errors?.amount && (
                                                        <Form.Control.Feedback type="invalid">
                                                            amount is required
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>Date</Col>
                                            <Col md={8}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control
                                                        type="date"
                                                        name="date"
                                                        isInvalid={
                                                            errors?.date
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(event) => {
                                                            setInitValues({
                                                                ...initValues,
                                                                date: event
                                                                    .target
                                                                    .value,
                                                            });
                                                        }}
                                                    />
                                                    {!!errors?.date && (
                                                        <Form.Control.Feedback type="invalid">
                                                            date is required
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>Payment method</Col>
                                            <Col md={8}>
                                                <Select
                                                    className="is-invalid react-select-container mb-3"
                                                    classNamePrefix="react-select"
                                                    options={addDefaultOption([
                                                        {
                                                        label: "Cash",
                                                        value: "CASH",
                                                    },
                                                    {
                                                        label: "Cheque",
                                                        value: "CHEQUE",
                                                    },
                                                    {
                                                        label: "Nets",
                                                        value: "NETS",
                                                    },
                                                    {
                                                        label: "Paynow",
                                                        value: "PAY-NOW",
                                                    },
                                                    {
                                                        label: "Giro",
                                                        value: "GIRO",
                                                    },
                                                    {
                                                        label: "Fund Transfer",
                                                        value: "FUND-TRANSFER",
                                                    }
                                                    ])}
                                                    name="department"
                                                    onChange={(event) => {
                                                        setFieldValue(
                                                            "payment_method",
                                                            event.value
                                                        );
                                                        setInitValues({
                                                            ...initValues,
                                                            payment_method:
                                                                event.value,
                                                        });
                                                    }}
                                                />

                                                {!!errors?.payment_method && (
                                                    <Form.Control.Feedback type="invalid">
                                                        Payment method is
                                                        required
                                                    </Form.Control.Feedback>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>Reference number</Col>
                                            <Col md={8}>
                                                <Form.Group className="mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        name="reference_number"
                                                        isInvalid={
                                                            errors?.reference_number
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(event) => {
                                                            setInitValues({
                                                                ...initValues,
                                                                reference_number:
                                                                    event.target
                                                                        .value,
                                                            });
                                                        }}
                                                    />
                                                    {/* {!!errors?.reference_number && (
                                                        <Form.Control.Feedback type="invalid">
                                                            Reference number is
                                                            required
                                                        </Form.Control.Feedback>
                                                    )} */}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col md={5}>
                                                <Button
                                                    variant="primary"
                                                    size="lg"
                                                    type="submit"
                                                >
                                                    Save
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </>
                            )
                        }
                    </Formik>
                </Card.Body>
            </Card>

            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};
export default FormRow;
