import React, { useState, useEffect } from "react";
import { Table, Form, Card, Button, Col, Row } from "react-bootstrap";
import { FileMinus, Trash, Plus, Save, X } from "react-feather";
import api from "../../../../../service/api";
import { SnackbarAlert } from "../../../../../components/SnackbarAlert";
import { useNavigate, useParams } from "react-router-dom";
import { Controller } from "react-hook-form";
import { DeleteModal } from "../../../../../components/DeleteModal";

const InvoiceStudentsList = ({
    contacts,
    permission,
    control,
    setValue,
    selectedCompany,
    getCompanyData,
    adhocInvoice,
    defaultContactData,
    invoiceStudentSelected,
    mainInvoiceStudent,
    indexOf,
    setStudentSelected,
    setStudentTextbox,
    defaultStudentsData,
    invoiceCourseId,
    sessionOptional
}) => {
    let { action } = useParams();
    const enrollmentIdsWithCourseId = defaultStudentsData?.map(
        (item) => `${item?.data?.student_id}_${item?.invoice_course_id}`
    );

    const [preSelectedStudents, setPreSelectedStudents] = useState(
        new Set(enrollmentIdsWithCourseId)
    );


    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const preselectedDataItem = defaultStudentsData?.map((item) => {
        return {
            enrolment_id: item?.data?.enrolment_id,
            student_id: item?.data?.student_id,
            no_session: item?.data?.no_session,
            // additional_training: item?.data?.additional_training,
            indexOf: indexOf,
            invoiceCourseId: item?.data?.invoice_course_id

        };
    });
    let modifiedStudents = mainInvoiceStudent;

    useEffect(() => {
        // Your logic for setting values goes here

        modifiedStudents = mainInvoiceStudent?.map((data, key) => {
            // Find the matching preselectedDataItem based on indexOf and enrolment_id
            const matchingPreselectedItem = preselectedDataItem?.find(
                (item) =>
                    item?.indexOf === indexOf && item?.student_id === data?.student_id && item.invoiceCourseId === invoiceCourseId
            );


            const isChecked = preSelectedStudents.has(`${data.student_id}_${invoiceCourseId}`);
            setValue(
                `items[${indexOf}].students[${key}].enrolment_id`,
                data?.enrolment_id
            );
            setValue(`items[${indexOf}].students[${key}].checked`, isChecked);
            setValue(
                `items[${indexOf}].students[${key}].student_id`,
                data?.student_id
            );

            // Set additional_training and no_session values from matchingPreselectedItem
            // setValue(
            //     `items[${indexOf}].students[${key}].additional_training`,
            //     matchingPreselectedItem?.additional_training || ""
            // );
            setValue(
                `items[${indexOf}].students[${key}].no_session`,
                matchingPreselectedItem?.no_session || ""
            );

            return { ...data, checked: isChecked };



        });

        // Cleanup logic if needed
    }, [indexOf]); // Include indexOf in the dependency array

    // Render the table rows with editable fields for contacts

    const studentListing = modifiedStudents?.map((data, key) => (
        <tr key={key}>
            <td>
                <Controller
                    name={`items[${indexOf}].students[${key}].student_id`}
                    control={control}
                    render={({ field }) => (
                        <Form.Check
                            type="checkbox"
                            label={key + 1}
                            {...field}
                            checked={preSelectedStudents.has(`${data.student_id}_${invoiceCourseId}`)}

                            onChange={(event) => {
                                const isChecked = event.target.checked;
                                setValue(
                                    `items[${indexOf}].students[${key}].enrolment_id`,
                                    data?.enrolment_id
                                );
                                setValue(
                                    `items[${indexOf}].students[${key}].student_id`,
                                    data?.student_id
                                );
                                setValue(
                                    `items[${indexOf}].students[${key}].checked`,
                                    isChecked
                                );
                                setStudentSelected(indexOf, data, event);
                                // If the checkbox is checked, add to preSelectedStudents
                                if (isChecked) {
                                    setPreSelectedStudents((prevStudents) => {
                                        const newSet = new Set(prevStudents);
                                        newSet.add(`${data.student_id}_${invoiceCourseId}`);
                                        return newSet;
                                    });
                                } else {
                                    // If the checkbox is unchecked, remove from preSelectedStudents
                                    setPreSelectedStudents((prevStudents) => {
                                        const newSet = new Set(prevStudents);
                                        newSet.delete(`${data.student_id}_${invoiceCourseId}`);
                                        return newSet;
                                    });

                                }
                            }}
                            disabled={action!=='add'}
                        />
                    )}
                />
            </td>
            <td>{data.student?.name}</td>
            <td>{data.student?.fin}</td>

            {!sessionOptional[indexOf] && (
            <td>
                <Controller
                    name={`items[${indexOf}].students[${key}].no_session`}
                    control={control}
                    render={({ field }) => (
                        <Form.Control
                            type="number"
                            label={key + 1}
                            {...field}
                            value={field.value}
                            onChange={(event) => {
                                setStudentTextbox(
                                    "no_session",
                                    event,
                                    key,
                                    indexOf
                                );
                            }}
                        />
                    )}
                />
            </td>
            )}

            {/* <td>
                <Controller
                    name={`items[${indexOf}].students[${key}].additional_training`}
                    control={control}
                    render={({ field }) => (
                        <Form.Control
                            type="number"
                            label={key + 1}
                            {...field}
                            value={field.value}
                            onChange={(event) => {
                                setStudentTextbox(
                                    "additional_training",
                                    event,
                                    key,
                                    indexOf
                                );
                            }}
                        />
                    )}
                />
            </td> */}
        </tr>
    ));

    return (
        <div>
            <Table responsive striped bordered>
                <thead>
                    <tr>
                        <th>S/N</th>
                        <th>Name</th>
                        <th>FIN</th>
                        {!sessionOptional[indexOf] && (<th>No of days</th>)}
                        {/* <th>Additional training</th> */}
                    </tr>
                </thead>
                <tbody>{studentListing}</tbody>
            </Table>

            {/* SnackbarAlert */}
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </div>
    );
};

export default InvoiceStudentsList;
