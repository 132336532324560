import React, { useEffect, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { useLocation } from "react-router-dom";
import { Alert, Col, Table, Pagination, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { IntellidocsButtons } from "../modules/IntelliDocs/Components/TableButtons.js";

export const TablePagination = ({
    project,
    module,
    columns,
    rawData,
    parentApi = null,
    openDeleteModal,
    parentModule = null,
    subModule = null,
}) => {
    let { id } = useParams();
    const location = useLocation();
    const state = location.state;

    const { meta, links, data } = rawData;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: { totalPage },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: meta?.per_page,
                canNextPage: links?.next !== null ? true : false,
                canPreviousPage: links?.prev !== null ? true : false,
                totalPage: meta?.last_page,
                currentPage: meta?.current_page,
                firstPage: meta?.first,
                lastPage: meta?.last_page,
            },
        },
        useSortBy,
        usePagination
    );

    //
    // States
    //

    // eslint-disable-next-line no-unused-vars
    const [rerender, setRerender] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    //
    // Functions
    //

    const gotoPage = (page) => {
        parentApi({
            page,
        });
    };

    //
    // UseEffects
    //

    useEffect(() => {
        if (state?.open) {
            setNotif({
                notifMsg: state.notifMsg,
                open: state.open,
                severity: state.severity,
            });
            parentApi();
        }
        window.history.replaceState(null, "");
    }, [state, parentApi]);

    const defineTableButtons = (
        project,
        module,
        cellId,
        projectId,
        openDeleteModal,
        parentModule,
        subModule = null
    ) => {
        const lookup = {
            intellidocs: (
                <IntellidocsButtons
                    module={module}
                    cellId={cellId}
                    projectId={projectId}
                    openDeleteModal={openDeleteModal}
                    parentModule={parentModule}
                    subModule={subModule}
                />
            ),
            tms: "",
            intellisuite: "",
        };

        return lookup[project];
    };
    return (
        <>
            {data.length > 0 && (
                <Table striped bordered {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup, i) => (
                            <tr
                                index={i}
                                {...headerGroup.getHeaderGroupProps()}
                            >
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps()
                                        )}
                                    >
                                        {column.render("Header")}
                                        {/* {column.render("Header") !== "Actions" && (
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                        ) : (
                          <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} className="ms-2" />
                      )}
                    </span>
                  )} */}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell, index, row) => {
                                        if (index === 0 && meta) {
                                            return (
                                                <td key={index}>
                                                    {meta.current_page > 1
                                                        ? (meta.current_page -
                                                              1) *
                                                              meta.per_page +
                                                          i +
                                                          1
                                                        : i + 1}
                                                </td>
                                            );
                                        }
                                        if (index + 1 === row.length) {
                                            return (
                                                <td key={index}>
                                                    {defineTableButtons(
                                                        project,
                                                        module,
                                                        cell.row.original.id,
                                                        id,
                                                        openDeleteModal,
                                                        parentModule,
                                                        subModule
                                                    )}
                                                </td>
                                            );
                                        }
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            )}
            {data.length === 0 && (
                <Alert variant="warning">
                    <div className="alert-message">No {module}</div>
                </Alert>
            )}
            {totalPage > 1 && (
                <Row>
                    <Col md="6">
                        <span className="mx-2">
                            Page{" "}
                            <strong>
                                {meta?.current_page} of {meta?.last_page}
                            </strong>
                        </span>
                    </Col>
                    <Col md="6">
                        <Pagination className="float-end">
                            <Pagination.First
                                onClick={() => gotoPage(meta?.first)}
                                disabled={links?.prev === null}
                            />
                            <Pagination.Prev
                                onClick={() => gotoPage(meta?.current_page - 1)}
                                disabled={links?.prev === null}
                            />
                            <Pagination.Next
                                onClick={() => gotoPage(meta?.current_page + 1)}
                                disabled={links?.next === null}
                            />
                            <Pagination.Last
                                onClick={() => gotoPage(meta?.last)}
                                disabled={links?.next === null}
                            />
                        </Pagination>
                    </Col>
                </Row>
            )}
        </>
    );
};
