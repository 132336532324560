import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import api from "../../../../service/api";
import { Card, Container, Alert, Row, Col, Button } from "react-bootstrap";

import { Lists } from "./List";

import { tableColumns } from "./data.js";

import { SnackbarAlert } from "../../../../components/SnackbarAlert";
import Search from "./Search";

import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import download from "downloadjs";
const StudentChangeHistory = () => {
    // const navigate = useNavigate();
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    // const [companies, setCompanies] = useState([]);
    const location = useLocation();
    const state = location.state;
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [searchValue, setSearchValue] = useState({
        company: "",
        // month: "",
        start_date: "", 
        end_date: "",
        type: "",
    });
    const search = (page) => {
        const searchParam = { ...searchValue, ...page };
        // const searchParam = { ...page };
        getStudentHistory(searchParam);
    };

    const getStudentHistory = useCallback(async (search) => {
        try {
            setLoading(true);
            const response = await api.get(
                "tms/change-student-histories",
                search
            );
            setPayments(response);
            setError();
        } catch (error) {
            setLoading(false);
            setError(error.message);
            setPayments([]);
        }
        setLoading(false);
    }, []);

    // const getCompany = useCallback(async () => {
    //     try {
    //         setLoading(true);
    //         const response = await api.get("tms/companies?all=1");
    //         setCompanyData(response.data);
    //         setError();
    //     } catch (error) {
    //         setLoading(false);
    //         setError(error.message);
    //     }
    //     setLoading(false);
    // }, []);

    // const setCompanyData = (data) => {
    //     const formatData = data.map((data, index) => {
    //         return { value: data.id, label: data.name };
    //     });

    //     setCompanies(formatData);
    // };

    const generatePayments = async () => {
        try {
            setLoading(true);
            const excelFilename = `change-student-histories-${new Date()
                .toISOString()
                .slice(0, 10)}`;

            const response = await api.getpdf(
                "tms/export-excel-student-changes",
                searchValue
            );

            if (response.size !== 0) {
                download(response, excelFilename, "application/pdf");
            } else {
                setError("No data to be exported");
            }
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
        setLoading(false);
    };

    //
    // useEffects
    //
    useEffect(() => {
        /**
         * set page number
         */
        let pageNo = "";
        if (state?.page) {
            pageNo = state?.page;
        }
        getStudentHistory({ page: pageNo });
        // getCompany();
    }, []);

    useEffect(() => {
        if (state?.open) {
            setNotif({
                notifMsg: state.notifMsg,
                open: state.open,
                severity: state.severity,
            });
        }
        window.history.replaceState(null, "");
    }, [state]); //  eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <Helmet title="Reports > Student change history" />

            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Student change history</h1>
                {error && (
                    <Card.Header>
                        <Alert className="my-3" variant="danger">
                            <div className="alert-message">{error}</div>
                        </Alert>
                    </Card.Header>
                )}
                <Card>
                    <Card.Body className="mt-0">
                        <Row className="mb-4">
                            <Col>
                                <Search
                                    setSearchValue={setSearchValue}
                                    searchValue={searchValue}
                                    // companies={companies}
                                    search={search}
                                    generatePayments={generatePayments}
                                />
                            </Col>
                        </Row>

                        {loading && <div>Loading...</div>}
                        {!loading && payments?.data && (
                            <Lists
                                columns={tableColumns}
                                rawData={payments}
                                paymentApi={search}
                            />
                        )}
                    </Card.Body>
                </Card>
            </Container>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </React.Fragment>
    );
};

export default StudentChangeHistory;
