import React, { useCallback, useEffect, useState } from "react";

import { Badge, Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { ArrowLeft } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import api from "../../../../../service/api";
import ApprovalForm from "./ApprovalForm";
import QuotationCourseList from "./QuotationCourseList";
import SalesQuotationHistoryLog from "./SalesQuotationHistoryLog";

const QuotationsDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [quotation, setQuotation] = useState();
    const [loading, setLoading] = useState(false);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const option = urlParams.get("option");
    const page = urlParams.get("page");
    /** get the quotation */
    const getQuotation = useCallback(async (id) => {
        try {
            setLoading(true);
            const response = await api.get(`tms/sales-quotations/${id}?option=${option}`);
            setQuotation(response.data);
            setLoading(false);
        } catch (error) {
            navigate("/tms/401")
        }
    }, []);
    
    /**  use efffect to call the get quotation */
    useEffect(() => {
        getQuotation(id);
    }, [id, getQuotation]);

    return (
        <>
            <Helmet title="Sales > Quotation" />
            <Container fluid className="p-0">
                <Card>
                    <Card.Header>
                        <Row>
                            <Col xs={8}>
                                <h1 className="h3 mb-3">Quotation details </h1>
                            </Col>
                            <Col xs={4} className="text-end">
                                <Button
                                    className="m-2"
                                    onClick={() => navigate(`/tms/quotations/`)}
                                >
                                    <ArrowLeft className="feather" />
                                </Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="pt-0">
                        {loading && <div>Loading...</div>}
                        {!loading && quotation && (
                            <>
                                <Row>
                                    <Col md={6} sm={12} xs={12}>
                                        <Row >
                                            <Row className="p-0 xs-pb-5">
                                                <Col className="ps-4">
                                                    <h3 className="mb-0 pb-0">
                                                        {quotation?.company?.name}
                                                    </h3>
                                                    {
                                                        quotation?.company_addr
                                                    }
                                                </Col>
                                            </Row>
                                            <Container fluid>
                                            <Row className="p-0 xs-pt-5">
                                                <Row  className="font-weight-bold">
                                                    <Col md={3}>
                                                        <h5>Attention: </h5>
                                                    </Col>
                                                    <Col md={9}>
                                                        <h5>{quotation?.company_contact_person?.toUpperCase()}</h5>
                                                    </Col>
                                                </Row>

                                                <Row  className="font-weight-bold">
                                                    <Col md={3}>
                                                        <h5>Contact number: </h5>
                                                    </Col>
                                                    <Col md={9}>
                                                        <h5>{quotation?.company_contact_no}</h5>
                                                    </Col>
                                                </Row>
                                            </Row>

                                            </Container>
                                            
                                        </Row>
                                    </Col>
                                    

                                    <Col md={6} xs={12} sm={12}>
                                        <div className="float-none xs-float-end">
                                            <Table className="table" responsive>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Quotation number
                                                        </td>
                                                        <td>
                                                            {quotation?.number}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Status</td>
                                                        <td>
                                                            <Badge>
                                                                {
                                                                    quotation?.status
                                                                }
                                                            </Badge>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Quotation date</td>
                                                        <td>
                                                            {quotation?.date}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Prepared by</td>
                                                        <td>
                                                            {
                                                                quotation
                                                                    ?.sales_user
                                                                    ?.name
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                                {quotation?.sales_quotation_course?.length >
                                    0 && (
                                    <>
                                        <QuotationCourseList
                                            quotation={quotation}
                                        />
                                    </>
                                )}
                                <hr size={5} className="text-secondary" />
                                <Row>
                                    <Col md={6}>
                                        {option?.toLowerCase() === "approval" &&
                                            quotation?.status_key ===
                                                "PENDING" && (
                                                <>
                                                    <ApprovalForm
                                                        invoiceId={id}
                                                        getQuotation={
                                                            getQuotation
                                                        }
                                                        setLoading={setLoading}
                                                        system_remarks={quotation?.system_remarks ?? null}
                                                    />
                                                </>
                                            )}
                                            <Table className="table" responsive>
                                                <tbody>
                                                {quotation?.status_key !== "PENDING" && quotation?.status_key !== "DRAFT" && (
                                                    <>
                                                        <tr>
                                                            <td width="20%">
                                                                Status updated
                                                                by
                                                            </td>
                                                            <td>
                                                                {
                                                                    quotation
                                                                        ?.status_updated_by
                                                                        ?.name
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Date</td>
                                                            <td>
                                                                {
                                                                    quotation?.status_date
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Remarks</td>
                                                            <td>
                                                                {
                                                                    quotation?.remarks
                                                                }
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                                {quotation?.status_key !== "DRAFT" && (
                                                    <tr>
                                                        <td>System Remarks</td>
                                                        <td>
                                                            {
                                                                quotation?.system_remarks ?? '-'
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </Table>
                                    </Col>
                                </Row>

                                {quotation?.status_key !== "DRAFT" && (
                                    <SalesQuotationHistoryLog
                                        histories={quotation?.sales_quotation_history_logs ?? []}
                                    />
                                )}
                            </>
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
};

export default QuotationsDetails;
