import React, { useState, useEffect } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import api from "../../../service/api";
import { AddEditReceivedPaymentDetails } from "./AddEditReceivedPaymentDetails";
import { AddEditReceivedPaymentItems } from "./AddEditReceivedPaymentItems";

export const AddEditReceivedPayment = ({ project }) => {
    const navigate = useNavigate();
    let { id, action, actiondo } = useParams();

    const schema = Yup.object().shape({
        payment_date: Yup.string().required(),
        payment_to: Yup.string().required(),
        total: Yup.string().required(),
        received_payment_items: Yup.array().of(
            Yup.object().shape({
                item: Yup.string().required(),
                description: Yup.string().required(),
                quantity: Yup.string().required(),
                rate: Yup.string().required(),
                amount: Yup.string().required(),
            })
        ),
    });

    //
    // States
    //
    const [receivedPayment, setReceivedPayment] = useState();
    // eslint-disable-next-line no-unused-vars
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [inputFields, setInputFields] = useState({
        payment_date: "",
        payment_to: "fonda",
        gst: "7",
        subtotal: 0,
        total: 0,
        received_payment_items: [
            {
                item: "",
                description: "",
                quantity: "",
                rate: "",
                amount: 0,
            },
        ],
    });

    //
    // Functions
    //

    const addFields = () => {
        let newfield = {
            item: "",
            description: "",
            quantity: "",
            rate: "",
            amount: 0,
        };

        setInputFields({
            ...inputFields,
            received_payment_items: [
                ...inputFields.received_payment_items,
                newfield,
            ],
        });
    };

    const removeFields = (index) => {
        let data = [...inputFields.received_payment_items];
        data.splice(index, 1);

        calculateRemovedFields(data);

        setInputFields({
            ...inputFields,
            received_payment_items: data,
        });
    };

    const handleFormChangeItems = (index, event) => {
        let data = [...inputFields.received_payment_items];
        data[index][event.target.name] = event.target.value;

        calculateTotalAmount(index, event);

        setInputFields({
            ...inputFields,
            received_payment_items: data,
        });
    };

    const handleFormChangeDetails = (event) => {
        setInputFields({
            ...inputFields,
            payment_date: event.target.value,
        });
    };

    const calculateTotalAmount = (index, event = null) => {
        let data = [...inputFields.received_payment_items];
        data[index][event.target.name] = event.target.value;

        const itemTotalAmount =
            inputFields["received_payment_items"][index]["quantity"] *
            inputFields["received_payment_items"][index]["rate"];

        inputFields["received_payment_items"][index]["amount"] =
            itemTotalAmount;

        let quotationItemsTotal = data.reduce(
            (total, current) => +total + +current.amount,
            0
        );

        const finalTotal = computeFinalTotal(quotationItemsTotal);
        inputFields["subtotal"] = quotationItemsTotal;
        inputFields["total"] = finalTotal > 0 ? finalTotal : 0;
    };

    const calculateRemovedFields = (data) => {
        let quotationItemsTotal = data.reduce(
            (total, current) => +total + +current.amount,
            0
        );

        const finalTotal = computeFinalTotal(quotationItemsTotal);

        inputFields["total"] = finalTotal > 0 ? finalTotal : 0;
    };

    const computeFinalTotal = (total) => {
        const gstRate = 7;

        return (gstRate / 100) * total + total;
    };

    const submitCreateReceivedPayment = (values) => {
        return api.post("received-payments", values);
    };

    const submitEditReceivedPayment = (values) => {
        try {
            return api.put(`received-payments/${actiondo}`, values);
        } catch {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    //
    // UseEffects
    //

    useEffect(() => {
        if (actiondo !== undefined && actiondo === "create") {
            const getRevenue = async () => {
                try {
                    const response = await api.get(`revenues/${action}`, {});
                    setReceivedPayment(response.data);
                } catch (error) {
                    setNotif({
                        notifMsg: "Something went wrong with the server",
                        open: true,
                        severity: "danger",
                    });
                }
            };
            getRevenue();
        } else {
            const getReceivedPayments = async () => {
                try {
                    const response = await api.get(
                        `received-payments/${actiondo}`,
                        {}
                    );
                    setReceivedPayment(response.data);
                } catch (error) {
                    setNotif({
                        notifMsg: "Something went wrong with the server",
                        open: true,
                        severity: "danger",
                    });
                }
            };
            getReceivedPayments();
        }
    }, [action, actiondo]);

    useEffect(() => {
        if (receivedPayment) {
            setInputFields({
                payment_date: receivedPayment.payment_date || "",
                payment_to:
                    receivedPayment.revenue_to || receivedPayment.payment_to,
                gst: receivedPayment.gst,
                subtotal: receivedPayment.subtotal,
                total: receivedPayment.total,
                received_payment_items:
                    receivedPayment.revenue_items ||
                    receivedPayment.received_payment_items,
            });
        }
    }, [receivedPayment]);

    return (
        <>
            <Card className="shadow-none mb-0">
                <Card.Header className="shadow-none pb-0">
                    <Card.Title tag="h5">
                        {actiondo === "create" ? "Create" : "Edit"} received
                        payment
                    </Card.Title>
                </Card.Header>
                <Card.Body className="pb-0">
                    <Formik
                        enableReinitialize
                        initialValues={inputFields}
                        validationSchema={schema}
                        onSubmit={async (
                            values,
                            { setErrors, setStatus, setSubmitting }
                        ) => {
                            try {
                                actiondo === "create"
                                    ? await submitCreateReceivedPayment(values)
                                    : await submitEditReceivedPayment(values);

                                navigate(
                                    `/intellidocs/process/revenues/${id}/${action}/do`,
                                    {
                                        state: {
                                            open: true,
                                            notifMsg: `Successfully ${
                                                action === "create"
                                                    ? "created"
                                                    : "updated"
                                            } quotation`,
                                            severity: "success",
                                        },
                                    }
                                );
                            } catch (error) {
                                const message =
                                    error.message || "Something went wrong";

                                setStatus({ success: false });
                                setErrors({ submit: message });
                                setSubmitting(false);
                                setNotif({
                                    notifMsg:
                                        "Something went wrong with the server",
                                    open: true,
                                    severity: "danger",
                                });
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            touched,
                            values,
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                {errors.submit && (
                                    <Alert className="my-3" variant="danger">
                                        <div className="alert-message">
                                            {errors.submit}
                                        </div>
                                    </Alert>
                                )}
                                <h6>Received payment items</h6>
                                <div className="bg-light p-4 rounded mb-3">
                                    <Row className="mb-3">
                                        <Col md={3}>
                                            <Form.Label>
                                                Delivery date
                                            </Form.Label>{" "}
                                            <br />
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    type="date"
                                                    name="payment_date"
                                                    id="payment_date"
                                                    value={
                                                        values.payment_date ||
                                                        ""
                                                    }
                                                    isInvalid={Boolean(
                                                        errors &&
                                                            touched &&
                                                            errors.payment_date &&
                                                            touched.payment_date
                                                    )}
                                                    onBlur={handleBlur}
                                                    onChange={
                                                        handleFormChangeDetails
                                                    }
                                                />
                                                {errors &&
                                                    errors?.payment_date && (
                                                        <Form.Control.Feedback type="invalid">
                                                            Payment date is a
                                                            required field
                                                        </Form.Control.Feedback>
                                                    )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <AddEditReceivedPaymentItems
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        handleBlur={handleBlur}
                                        handleFormChangeItems={
                                            handleFormChangeItems
                                        }
                                        removeFields={removeFields}
                                        addFields={addFields}
                                    />
                                </div>
                                <h6>Billing details</h6>
                                <div className="bg-light p-4 rounded">
                                    <AddEditReceivedPaymentDetails
                                        project={project}
                                    />
                                </div>
                                <Card.Footer className="text-center pb-0">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className="me-2"
                                        size="lg"
                                    >
                                        <FontAwesomeIcon icon={faPaperPlane} />{" "}
                                        Save
                                    </Button>
                                    <Button
                                        variant="link"
                                        onClick={() =>
                                            navigate(
                                                `/intellidocs/process/revenues/${id}/${action}/do`
                                            )
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </Card.Footer>
                            </Form>
                        )}
                    </Formik>
                </Card.Body>
            </Card>
        </>
    );
};
