import React, { useCallback, useEffect, useState } from "react";
import { Col, Card, Row } from "react-bootstrap";

import { DollarSign, Users } from "react-feather";
import api from "../../../service/api";
import EnrolmentChart from "./EnrolmentChart";
import Header from "./Header";
import InvoiceChart from "./InvoiceChart";
import TypeofTradeChart from "./TypeofTradeChart";
import { SnackbarAlert } from "../../../components/SnackbarAlert";
import { formatNumberWithCommas, formatNumberWithCommasNoDecimal } from "../../../utils/utility";

const Statistics = () => {
    const [data, SetData] = useState();
    const [loading, setLoading] = useState(false);
    /** Notification state */
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const getDashBoardData = useCallback(async (search = {}) => {
        setLoading(true);
        const response = await api.get(`tms/dashboard`, search);
        SetData(response?.data);

        const queryParameters = new URLSearchParams(window.location.search)
        var type = queryParameters.get("pdf-bulk-download")
        if (type) {
            const bulk_download = await api.get(`tms/pdf-bulk-download/${type}`);
            if (bulk_download?.status === 'ok') {
                window.open(bulk_download?.data?.full_path, '_blank');
            }else {
                setNotif({
                    notifMsg: bulk_download?.message,
                    open: true,
                    severity: "danger",
                });
            }
        }
        type = queryParameters.get("excel-email-download")
        if (type) {
            const excelDownload = await api.get(`tms/excel-email-download/${type}`);
            if (excelDownload?.status === 'ok') {
                window.open(excelDownload?.data?.full_path, '_blank');
            }else {
                setNotif({
                    notifMsg: excelDownload?.message,
                    open: true,
                    severity: "danger",
                });
            }
        }
        setLoading(false);
    }, []);
   
    return (
        <>
            <Header getDashBoardData={getDashBoardData} />
            {loading && <Col md={12}>Loading...</Col>}
            {!loading && (
                <>
                    <Row>
                        <Col md="6" xl className="d-flex">
                            <Card className="flex-fill">
                                <Card.Body className=" py-4">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                            <h3 className="mb-2">
                                                {data?.enrolments?.attc ? formatNumberWithCommasNoDecimal(data?.enrolments?.attc) : 0}
                                            </h3>
                                            <p className="mb-2">
                                                ATTC Enrolments
                                            </p>
                                        </div>
                                        <div className="d-inline-block ms-3">
                                            <div className="stat">
                                                <Users className="align-middle text-success" />
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* <Col md="6" xl className="d-flex">
                            <Card className="flex-fill">
                                <Card.Body className=" py-4">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                            <h3 className="mb-2">
                                                {data?.enrolments?.ato}
                                            </h3>
                                            <p className="mb-2">
                                                ATO Enrolments
                                            </p>
                                        </div>
                                        <div className="d-inline-block ms-3">
                                            <div className="stat">
                                                <Users className="align-middle text-success" />
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col> */}
                        <Col md="6" xl className="d-flex">
                            <Card className="flex-fill">
                                <Card.Body className=" py-4">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                            <h3 className="mb-2">
                                                {data?.payments?.attc ? formatNumberWithCommas(data?.payments?.attc) : 0}
                                            </h3>
                                            <p className="mb-2">
                                                ATTC payments
                                            </p>
                                        </div>
                                        <div className="d-inline-block ms-3">
                                            <div className="stat">
                                                <DollarSign className="align-middle text-success" />
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* <Col md="6" xl className="d-flex">
                            <Card className="flex-fill">
                                <Card.Body className=" py-4">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                            <h3 className="mb-2">
                                                {data?.payments?.ato}
                                            </h3>
                                            <p className="mb-2">ATO payments</p>
                                        </div>
                                        <div className="d-inline-block ms-3">
                                            <div className="stat">
                                                <DollarSign className="align-middle text-success" />
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col md={6}>
                            {data?.enrolment_summary && (
                                <EnrolmentChart
                                    summaryData={data?.enrolment_summary}
                                />
                            )}
                        </Col>
                        <Col md={6}>
                            {data?.invoice_summary && (
                                <InvoiceChart
                                    summaryData={data?.invoice_summary}
                                />
                            )}
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                        <Col md={6}>
                            {data?.enrolment_summary && (
                                <TypeofTradeChart
                                    summaryData={data?.trade_summary}
                                />
                            )}
                        </Col>
                        {/* <Col md={6}>
                            {data?.invoice_summary && (
                                <InvoiceChart
                                    summaryData={data?.invoice_summary}
                                />
                            )}
                        </Col> */}
                    </Row>
                    
                </>
            )}
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export default Statistics;
