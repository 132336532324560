import { Button, Col, Form, Row } from "react-bootstrap";
import { Trash, Plus } from "react-feather";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import { UserDateFormat } from "../../../utils/dateFormat";
import { addDefaultOption, toUpperInput } from "../../../utils/utility";
import { AutoGrowTextarea } from "../../../components/AutoGrowTextarea";

const AddEditStudentDetails = ({
    values,
    handleFormChangeItems,
    handleFormSelectChangeItems,
    errors,
    handleBlur,
    setFieldValue,
    configValues,
    deleteEnrolement,
    addEnrolement,
    handleDateSelection,
    id,
}) => {
    const DateFormat = UserDateFormat();

    const identificationTypeOption = [
        { value: "EP", label: "EP" },
        { value: "WP", label: "WP" },
        { value: "SVP", label: "SVP" },
        { value: "S-PASS", label: "S Pass" },
        { value: "SINGAPOREAN", label: "Singaporean" },
        { value: "SINGAPORE-PR", label: "Singapore PR" },
        { value: "OTHERS", label: "Others" },
    ];

    const sectorOption = [
        { value: "CONSTRUCTION", label: "Construction" },
        { value: "SERVICE", label: "Service" },
        { value: "MANUFACTURING", label: "Manufacturing" },
        {
            value: "MARINE",
            label: "Marine",
        },
        {
            value: "LANDSCAPING",
            label: "Landscaping",
        },
        {
            value: "PROCESS-MAINTENANCE",
            label: "Process maintenance",
        },
        {
            value: "OTHERS",
            label: "Others",
        },
    ];

    return (
        <>
            <div className="card-title h5 mt-3 mb-3" tag="h5">
                Student particulars
            </div>
            {values?.students?.map((student, i) => {
                return (
                    <div key={i} className="bg-light p-2 mb-3">
                        <Row>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={student.name}
                                        onChange={(event) => {
                                            handleFormChangeItems(i, event);
                                            setFieldValue(
                                                `students.[${i}].name`,
                                                values.students[i]?.name
                                            );
                                        }}
                                        isInvalid={Boolean(
                                            errors.students &&
                                                errors.students[i]?.name
                                        )}
                                        onBlur={handleBlur}
                                    />
                                    {errors.students &&
                                        errors.students[i]?.name && (
                                            <Form.Control.Feedback type="invalid">
                                                Name is required
                                            </Form.Control.Feedback>
                                        )}
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>NRIC/FIN number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="fin"
                                        value={student.fin || ''}
                                        onChange={(event) => {
                                            handleFormChangeItems(i, event);
                                            setFieldValue(
                                                `students.[${i}].fin`,
                                                values.students[i]?.fin
                                            );
                                        }}
                                        onInput={toUpperInput}
                                        isInvalid={Boolean(
                                            errors.students &&
                                                errors.students[i]?.fin
                                        )}
                                    />
                                    {errors.students &&
                                        errors.students[i]?.fin && (
                                            <Form.Control.Feedback type="invalid">
                                                NRIC/FIN number is required
                                            </Form.Control.Feedback>
                                        )}
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Passport</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="passport"
                                        value={student?.passport || ''}
                                        onChange={(event) => {
                                            handleFormChangeItems(i, event);
                                        }}
                                        isInvalid={Boolean(
                                            errors.students &&
                                                errors.students[i]?.passport
                                        )}
                                        onInput={toUpperInput}
                                    />
                                    {errors.students &&
                                        errors.students[i]?.passport && (
                                            <Form.Control.Feedback type="invalid">
                                                Passport is a required field
                                            </Form.Control.Feedback>
                                        )}
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nationality</Form.Label>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={addDefaultOption(
                                            configValues?.nationality
                                        )}
                                        name="nationality"
                                        onChange={(event) => {
                                            handleFormSelectChangeItems(
                                                i,
                                                event,
                                                "nationality"
                                            );
                                            setFieldValue(
                                                `students.[${i}].nationality`,
                                                values.students[i]?.nationality
                                            );
                                        }}
                                        value={
                                            student?.nationality !== "" ?
                                            configValues?.nationality?.filter(
                                                (option) => {
                                                    return (
                                                        option.value?.toUpperCase() ===
                                                        student?.nationality?.toUpperCase()
                                                    );
                                                }
                                            ) : ''
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Date of birth</Form.Label>
                                    {/* <Form.Control
                                        type="date"
                                        format="YYYY/MM/DD"
                                        name="date_of_birth"
                                        value={student?.date_of_birth}
                                        onChange={(event) => {
                                            handleFormChangeItems(i, event);
                                            setFieldValue(
                                                `students.[${i}].date_of_birth`,
                                                values.students[i]
                                                    ?.date_of_birth
                                            );
                                        }}
                                    /> */}
                                    <DatePicker
                                        name="date_of_birth"
                                        selected={
                                            student?.date_of_birth !== "" &&
                                            new Date(
                                                moment(
                                                    student?.date_of_birth,
                                                    DateFormat.toUpperCase()
                                                ).format("YYYY-MM-DD")
                                            )
                                        }
                                        className={`form-control`}
                                        size="lg"
                                        onChange={(dates) => {
                                            handleDateSelection(
                                                i,
                                                "date_of_birth",
                                                dates
                                            );
                                            setFieldValue(
                                                `students.[${i}].date_of_birth`,
                                                values.students[i]
                                                    ?.date_of_birth
                                            );
                                        }}
                                        dateFormat={DateFormat}
                                        placeholderText={DateFormat.toUpperCase()}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Work permit number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="work_permit_number"
                                        value={student?.work_permit_number || ''}
                                        onChange={(event) => {
                                            handleFormChangeItems(i, event);
                                            setFieldValue(
                                                `students.[${i}].work_permit_number`,
                                                values.students[i]
                                                    ?.work_permit_number
                                            );
                                        }}
                                        isInvalid={Boolean(
                                            errors.students &&
                                                errors.students[i]
                                                    ?.work_permit_number
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Identification type</Form.Label>
                                    <Select
                                        className="is-invalid react-select-container"
                                        classNamePrefix="react-select"
                                        options={addDefaultOption(
                                            identificationTypeOption
                                        )}
                                        name="identification_type"
                                        onChange={(event) => {
                                            handleFormSelectChangeItems(
                                                i,
                                                event,
                                                "identification_type"
                                            );
                                            setFieldValue(
                                                `students.[${i}].identification_type`,
                                                values.students[i]
                                                    ?.identification_type
                                            );
                                        }}
                                        value={
                                            student?.identification_type !==
                                                "" ?
                                            identificationTypeOption?.filter(
                                                (option) => {
                                                    return (
                                                        option.value ===
                                                        student?.identification_type?.toUpperCase()
                                                    );
                                                }
                                            ) : ''
                                        }
                                    />
                                    {errors.students &&
                                        errors.students[i]
                                            ?.identification_type && (
                                            <Form.Control.Feedback type="invalid">
                                                Indentification type is a
                                                required field
                                            </Form.Control.Feedback>
                                        )}
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Contact number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="contact_number"
                                        value={student?.contact_number || ''}
                                        onChange={(event) => {
                                            handleFormChangeItems(i, event);
                                            setFieldValue(
                                                `students.[${i}].contact_number`,
                                                values.students[i]
                                                    ?.contact_number
                                            );
                                        }}
                                        isInvalid={Boolean(
                                            errors.students &&
                                                errors.students[i]
                                                    ?.contact_number
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Gender</Form.Label>

                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={addDefaultOption(
                                            configValues?.gender
                                        )}
                                        name="gender"
                                        onChange={(event) => {
                                            handleFormSelectChangeItems(
                                                i,
                                                event,
                                                "gender"
                                            );
                                            setFieldValue(
                                                `students.[${i}].gender`,
                                                values.students[i]?.gender
                                            );
                                        }}
                                        value={
                                            student?.gender ?
                                            configValues?.gender?.filter(
                                                (option) => {
                                                    return (
                                                        option.value ===
                                                        student?.gender?.toUpperCase()
                                                    );
                                                }
                                            ) : ''
                                        }
                                    />
                                    {errors.students &&
                                        errors.students[i]?.gender && (
                                            <Form.Control.Feedback type="invalid">
                                                Gender is a required field
                                            </Form.Control.Feedback>
                                        )}
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Work permit expiry date
                                    </Form.Label>
                                    {/* <Form.Control
                                        type="date"
                                        name="work_permit_expire_date"
                                        value={
                                            student?.work_permit_expire_date
                                                ? student?.work_permit_expire_date
                                                : ""
                                        }
                                        onChange={(event) => {
                                            handleFormChangeItems(i, event);
                                            setFieldValue(
                                                `students.[${i}].work_permit_expire_date`,
                                                values.students[i]
                                                    ?.work_permit_expire_date
                                            );
                                            
                                        }}
                                        isInvalid={Boolean(
                                            errors.students &&
                                                errors.students[i]
                                                    ?.work_permit_expire_date
                                        )}
                                    /> */}

                                    <DatePicker
                                        name="work_permit_expire_date"
                                        selected={
                                            student?.work_permit_expire_date &&
                                            new Date(
                                                moment(
                                                    student?.work_permit_expire_date,
                                                    DateFormat?.toUpperCase()
                                                ).format("YYYY-MM-DD")
                                            )
                                        }
                                        className={`form-control`}
                                        size="lg"
                                        onChange={(dates) => {
                                            handleDateSelection(
                                                i,
                                                "work_permit_expire_date",
                                                dates
                                            );
                                            setFieldValue(
                                                `students.[${i}].work_permit_expire_date`,
                                                values.students[i]
                                                    ?.work_permit_expire_date
                                            );
                                        }}
                                        dateFormat={DateFormat}
                                        placeholderText={DateFormat.toUpperCase()}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Sector</Form.Label>
                                    <Select
                                        className="is-invalid react-select-container"
                                        classNamePrefix="react-select"
                                        options={addDefaultOption(sectorOption)}
                                        name="sector"
                                        onChange={(event) => {
                                            handleFormSelectChangeItems(
                                                i,
                                                event,
                                                "sector"
                                            );
                                            setFieldValue(
                                                `students.[${i}].sector`,
                                                values.students[i]?.sector
                                            );
                                        }}
                                        value={
                                            student?.sector !== "" ?
                                            sectorOption?.filter((option) => {
                                                return (
                                                    option.value ===
                                                    student?.sector?.toUpperCase()
                                                );
                                            }) : ''
                                        }
                                        isInvalid={Boolean(
                                            errors.students &&
                                                errors.students[i]?.sector
                                        )}
                                    />
                                    {errors.students &&
                                        errors.students[i]?.sector && (
                                            <Form.Control.Feedback type="invalid">
                                                Sector type is a required field
                                            </Form.Control.Feedback>
                                        )}
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        value={student?.email || ''}
                                        onChange={(event) =>
                                            handleFormChangeItems(i, event)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            {/* <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Work permit category
                                    </Form.Label>

                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={configValues?.workPermits}
                                        name="work_permit_category"
                                        onChange={(event) => {
                                            handleFormSelectChangeItems(
                                                i,
                                                event,
                                                "work_permit_category"
                                            );
                                            handleFormSelectChangeItems(
                                                i,
                                                event,
                                                "work_permit_category_original"
                                            );
                                            setFieldValue(
                                                `students.[${i}].work_permit_category_original`,
                                                values.students[i]
                                                    ?.work_permit_category_original
                                            );
                                            setFieldValue(
                                                `students.[${i}].work_permit_category`,
                                                values.students[i]
                                                    ?.work_permit_category
                                            );
                                        }}
                                        value={
                                            student?.work_permit_category &&
                                            configValues?.workPermits?.filter(
                                                (option) => {
                                                    return (
                                                        option.value.toUpperCase() ===
                                                        student?.work_permit_category_original?.toUpperCase()
                                                    );
                                                }
                                            )
                                        }
                                    />
                                    {JSON.stringify()}
                                </Form.Group>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col md={3}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Eligibility period for AN
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="eligibility_period_for_an"
                                        value={
                                            student?.eligibility_period_for_an || ''
                                        }
                                        onChange={(event) => {
                                            handleFormChangeItems(i, event);
                                            setFieldValue(
                                                `students.[${i}].eligibility_period_for_an`,
                                                values.students[i]
                                                    ?.eligibility_period_for_an
                                            );
                                        }}
                                        isInvalid={Boolean(
                                            errors.students &&
                                                errors.students[i]
                                                    ?.eligibility_period_for_an
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Coretrade/Multi-skilling/Direct R1 Reg.
                                        No.
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="coretrade_multi_skill_direct"
                                        value={
                                            student?.coretrade_multi_skill_direct || ''
                                        }
                                        onChange={(event) => {
                                            handleFormChangeItems(i, event);
                                            setFieldValue(
                                                `students.[${i}].coretrade_multi_skill_direct`,
                                                values.students[i]
                                                    ?.coretrade_multi_skill_direct
                                            );
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col
                                md={1}
                                className="d-flex flex-row align-items-center"
                            >
                                {id === "add" && (
                                    <Button
                                        variant="danger"
                                        type="button"
                                        onClick={() => deleteEnrolement(i)}
                                    >
                                        <Trash size={15} className="m-0" />
                                    </Button>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Remarks</Form.Label>
                                    <AutoGrowTextarea
                                        name="remarks"
                                        id="remarks"
                                        fieldValue={values.remarks}
                                        handleFormChangeDetails={(event) => {
                                            setFieldValue(
                                                "remarks",
                                                event.target.value
                                            );
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                );
            })}
            {id === "add" && (
                <Button
                    variant="success"
                    className="mb-4"
                    type="button"
                    onClick={addEnrolement}
                >
                    <Plus size={15} className="m-0"></Plus>
                </Button>
            )}
        </>
    );
};

export default AddEditStudentDetails;
