// setInitialValues.js

import { commonBeforeGSTAmount } from "../../../../../utils/utility";

export const setInitialValues = (
    adhocInvoice,
    id,
    configValues,
    setValue,
    setSelectedCompany
) => {
    if (!adhocInvoice || id === "") {
        return; // No need to proceed if quotation or id is not available
    }

    // Preselect the company by setting its option
    const companyOption = {
        label: adhocInvoice?.company?.name,
        value: adhocInvoice?.company?.id,
    };
    setSelectedCompany(adhocInvoice?.company?.id);


    setValue("company_id", companyOption.value, { shouldValidate: false });
    const locationOption = configValues?.location?.find(
        (option) => option.value === adhocInvoice?.location
    );

    if (locationOption) {
        setValue("location", locationOption);
    }
    setValue("sales_user_id", {
        label: adhocInvoice?.sales_user.name,
        value: adhocInvoice?.sales_user.id,
    });
    // console.log(adhocInvoice.adhoc_type)
    setValue("update_address", adhocInvoice?.update_address);
    setValue("address", adhocInvoice?.company_addr);
    setValue("no_gst", adhocInvoice?.no_gst);

    const companyContactId = adhocInvoice?.company_contact_id;
    if (companyContactId) {
        setValue("company_contact_person", companyContactId);
    }

    // Initialize an empty array to store the initial items
    const initialItems = adhocInvoice?.invoice_courses.map((item) => {

        // const applicationOptionConfig = configValues?.tradeApplication?.find(
        //     (option) => option.value === item?.application_option
        // );

        // const tradeCategoryOptionConfig = configValues?.tradeCategory?.find(
        //     (option) => option.value === item?.trade_category
        // );
       
        const courseCodeOptionConfig = configValues?.adhoc?.find(
            (option) => option.value === item?.addtnl_invoice_item_code
        );

        // const tradeOptionConfig = configValues?.tradeOptions?.find(
        //     (option) => option.value === item?.type_of_trade
        // );

        return {
            id: item?.id,
            total_student: item?.total_student,
            price:
                item?.item_type !== "others"
                    ? commonBeforeGSTAmount(
                        parseFloat(item?.price),
                        adhocInvoice?.gst
                    )
                    : parseFloat(item?.price),
            original_price: parseFloat(item?.price),
            item_type: item?.item_type,
            other_item: item?.addtnl_invoice_item_code,
            // trade_option: tradeOptionConfig || null,
            course_code: courseCodeOptionConfig || null,
            // application_option: applicationOptionConfig || null,
            // trade_category: tradeCategoryOptionConfig || null,
            course: {
                label: item?.course?.name || "",
                value: item?.course?.id || "",
            },
            student_names: item?.additional_adhoc_student,
            discount_item: item?.discount_item_name,
        };
    });

    setValue("items", initialItems); // Use setValue to set initial values
};
