import React from "react";
import { Table } from "react-bootstrap";

const SalesQuotationHistoryLog = ({ histories }) => {
    return (
        <>
        {histories.length > 0 && (
            <>
            <h5>Sales quotation history log</h5>
            <Table responsive bordered striped>
                <thead>
                    <tr>
                        <td>S.no</td>
                        <td>Action</td>
                        <td>Performed by</td>
                        <td>Date</td>
                    </tr>
                </thead>
                <tbody>
                    {histories?.map((history, index) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{history?.action[0].toUpperCase() + history?.action.replace(/_/g, ' ').slice(1) }</td>
                                <td>{history?.user?.name}</td>
                                <td>{history?.created_at}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            </>
        )}
        </>
    );
};

export default SalesQuotationHistoryLog;
