import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import BatchDetails from "../CommonComponents/BatchDetails";
import { batchStudentTableColumns } from "./BatchStudentData";
import BatchStudents from "./BatchStudents";
import api from "../../../service/api";
import { ArrowLeft } from "react-feather";

const StudentAllocation = () => {
    let { id } = useParams();

    /** Get path from summary page  */
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const path = urlParams.get("status");
    let redirectPath = "/tms/batches";
    if (path) {
        redirectPath = `/tms/batches/${path}`;
    }
    /** Get path from summary page  */

    const navigate = useNavigate();
    const [classStudents, setClassStudents] = useState([]);

    const [loading, setLoading] = useState(false);

    const getBatchStudents = async () => {
        setLoading(true);
        const response = await api.get(`tms/batches/${id}/batch-students`, {});
        setClassStudents(response.data);
        setLoading(false);
    };

    const [batch, setBatch] = useState({});
    const [batchDates, setBatchDates] = useState([]);
    const getBatch = async () => {
        const response = await api.get(`tms/batches/${id}`, {});
        const batchDates = response.data?.training_dates?.map((dates) => {
            let tempSession = [];
            if (
                dates?.am?.from !== null &&
                dates?.am?.from !== undefined &&
                dates?.am?.from !== ""
            ) {
                tempSession.push({
                    label: "AM",
                    value: "am",
                });
            }
            if (
                dates?.pm?.from !== null &&
                dates?.pm?.from !== undefined &&
                dates?.pm?.from !== ""
            ) {
                tempSession.push({
                    label: "PM",
                    value: "pm",
                });
            }

            return {
                value: dates.id,
                label: dates.date + " - " + dates?.training_type?.toUpperCase(),
                session: tempSession,
            };
        });

        setBatchDates(batchDates);
        setBatch(response.data);
    };

    useEffect(() => {
        setLoading(true);
        getBatchStudents();
        getBatch();
        setLoading(false);
    }, []); //  eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <Helmet title="Allocate student" />
            <Container fluid className="p-0">
                <Row>
                    <Col md={6}>
                        <h1 className="h3 mb-3">Class - student allocation </h1>
                    </Col>
                    <Col md={6} className="text-end">
                        <Button
                            className="m-2"
                            onClick={() => navigate(redirectPath)}
                        >
                            <ArrowLeft className="feather" />
                        </Button>
                    </Col>
                </Row>
                {loading && <div>Loading...</div>}
                {!loading && (
                    <Row>
                        <Col md="4" xl="3">
                            <BatchDetails
                                batch={batch}
                                getBatchApi={getBatch}
                                getBatchStudentsApi={getBatchStudents}
                            />
                        </Col>
                        <Col md="8" xl="9">
                            <BatchStudents
                                batch={batch}
                                columns={batchStudentTableColumns}
                                data={classStudents}
                                batchDates={batchDates}
                                getBatchStudentsApi={getBatchStudents}
                                isSummaryPage={path} // summary page
                            />
                        </Col>
                    </Row>
                )}
            </Container>
        </React.Fragment>
    );
};

export default StudentAllocation;
