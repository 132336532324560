import React from "react";
import { Col, Form, FloatingLabel, Row } from "react-bootstrap";
import Select from "react-select";
import { addDefaultOption } from "../../../utils/utility";

export const AddEditExpenseDetails = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    intelliDocsUser,
    loading,
    handleFormChangeRevenue,
    revenue,
    revenueSelect,
}) => {
    return (
        <>
            <Row>
                <Col md={6}>
                    <Row>
                        <Col md={10}>
                            <Form.Group className="mb-3">
                                <Form.Label>User</Form.Label> <br />
                                <Form.Select
                                    name="user"
                                    id="user"
                                    value={values.user}
                                    isInvalid={Boolean(
                                        errors &&
                                            touched &&
                                            errors.user &&
                                            touched.user
                                    )}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                >
                                    <option></option>
                                    {intelliDocsUser &&
                                        intelliDocsUser.map((data, index) => {
                                            return (
                                                <option
                                                    key={index}
                                                    value={data.name}
                                                >
                                                    {data.name}
                                                </option>
                                            );
                                        })}
                                </Form.Select>
                                {errors && errors?.user && (
                                    <Form.Control.Feedback type="invalid">
                                        User is a required field
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <Form.Group>
                                <Form.Label>Expense date</Form.Label> <br />
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="date"
                                        name="expense_date"
                                        id="expense_date"
                                        value={values.expense_date}
                                        isInvalid={Boolean(
                                            errors &&
                                                touched &&
                                                errors.expense_date &&
                                                touched.expense_date
                                        )}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                    {errors && errors?.expense_date && (
                                        <Form.Control.Feedback type="invalid">
                                            Expense date is a required field
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Form.Group>
                        </Col>
                        <Col md={10}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Link to revenue (Optional)
                                </Form.Label>{" "}
                                <br />
                                {loading && (
                                    <Select
                                        name="revenue"
                                        isSearchable={true}
                                        isClearable={true}
                                        options={addDefaultOption(revenue)}
                                        onChange={(event) => {
                                            handleFormChangeRevenue(
                                                event,
                                                values
                                            );
                                        }}
                                        defaultValue={[revenueSelect]}
                                    />
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row className="mt-3">
                        <Col md={10}>
                            <Form.Group className="mb-1">
                                <Form.Label>Is recurring</Form.Label> <br />
                                <Form.Check
                                    checked={values.is_recurring === "Yes"}
                                    className="mb-2"
                                    id="is_recurring"
                                    inline
                                    label="Yes"
                                    name="is_recurring"
                                    onChange={handleChange}
                                    type="radio"
                                    value="Yes"
                                />
                                <Form.Check
                                    checked={values.is_recurring === "No"}
                                    className="mb-2"
                                    id="is_recurring"
                                    inline
                                    label="No"
                                    name="is_recurring"
                                    onChange={handleChange}
                                    type="radio"
                                    value="No"
                                />
                            </Form.Group>
                        </Col>
                        {values.is_recurring === "Yes" && (
                            <Row>
                                <Col md={10}>
                                    <Form.Group className="mb-1">
                                        <Form.Label>Frequency</Form.Label>
                                        <Form.Select
                                            name="interval"
                                            value={values.interval || ""}
                                            isInvalid={Boolean(
                                                errors &&
                                                    touched &&
                                                    errors.interval &&
                                                    touched.interval
                                            )}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            <option />
                                            <option value="monthly">
                                                Monthly
                                            </option>
                                            <option value="yearly">
                                                Yearly
                                            </option>
                                        </Form.Select>
                                        {errors && errors.interval && (
                                            <Form.Control.Feedback type="invalid">
                                                Interval is a required field
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <Form.Group>
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Start date"
                                                    className="mb-3"
                                                >
                                                    <Form.Control
                                                        type="date"
                                                        name="start_date"
                                                        value={
                                                            values.start_date ||
                                                            ""
                                                        }
                                                        isInvalid={Boolean(
                                                            errors &&
                                                                touched &&
                                                                errors.start_date &&
                                                                touched.start_date
                                                        )}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                    {errors &&
                                                        errors?.start_date && (
                                                            <Form.Control.Feedback type="invalid">
                                                                Start date is a
                                                                required field
                                                            </Form.Control.Feedback>
                                                        )}
                                                </FloatingLabel>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="End date"
                                                    className="mb-3"
                                                >
                                                    <Form.Control
                                                        type="date"
                                                        name="end_date"
                                                        value={
                                                            values.end_date ||
                                                            ""
                                                        }
                                                        isInvalid={Boolean(
                                                            errors &&
                                                                touched &&
                                                                errors.end_date &&
                                                                touched.end_date
                                                        )}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                    {errors &&
                                                        errors?.end_date && (
                                                            <Form.Control.Feedback type="invalid">
                                                                End date is a
                                                                required field
                                                            </Form.Control.Feedback>
                                                        )}
                                                </FloatingLabel>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </Row>
                </Col>
            </Row>
        </>
    );
};
