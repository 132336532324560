import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
    calculateCommonSubtotal,
    commonBeforeGSTAmount,
    formatNumberWithCommas,
    roundToTwoDecimalDigits,
    totalGST,
} from "../../../../../utils/utility";

import { Button, Col, Form, Row } from "react-bootstrap";
import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";
import CourseInformation from "./CourseInformation";
import { Plus, Trash } from "react-feather";

const ItemRow = ({
    configValues,
    control,
    setValue,
    getValues,
    courseFee,
    errors,
    quotation,
}) => {
    const { fields, append, insert, remove, update } = useFieldArray({
        control,
        name: "items",
    });

    const [subtotals, setSubtotals] = useState([]);
    const [originalSubtotals, setOriginalSubtotals] = useState([]);
    const [indexWithDiscount, setIndexWithDiscount] = useState([]);

    const [cumulativeSubtotal, setCumulativeSubtotal] = useState(0);
    const [gstAmount, setGstAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [noGST, setNoGST] = useState(false);
    const gstRate = parseFloat(configValues.gst);

    // Function to calculate the cumulative subtotal
    const calculateCumulativeSubtotal = () => {
        let newCumulativeSubtotal = 0;

        fields.forEach((field, index) => {
            const subtotal = calculateSubtotal(index);
            newCumulativeSubtotal += subtotal;
        });

        return newCumulativeSubtotal;
    };

    const calculateCumulativeOriginalSubtotal = () => {
        let newCumulativeSubtotal = 0;

        fields.forEach((field, index) => {
            const subtotal = calculateOriginalSubtotal(index);
            if (field.item_type !== "discount") {
                newCumulativeSubtotal += subtotal;
            }
        });

        return newCumulativeSubtotal;
    };

    // Function to calculate the cumulative subtotal
    const calculateCumulativeDiscountSubtotal = () => {
        let newCumulativeSubtotal = 0;

        fields.forEach((field, index) => {
            const price = parseFloat(getValues(`items[${index}].price`));
            const totalStudents = parseFloat(
                getValues(`items[${index}].total_student`)
            );
            const subtotal = calculateCommonSubtotal(price, totalStudents);
            // Only add subtotals for "course" items
            if (field.item_type !== "course") {
                newCumulativeSubtotal += subtotal;
            }
        });

        return parseFloat(newCumulativeSubtotal);
    };

    // Function to update all totals
    const updateTotals = async () => {
        const newCumulativeSubtotal = calculateCumulativeSubtotal();
        let newCumulativeOriginalSubtotal =
            calculateCumulativeOriginalSubtotal();
        const newTotalDiscount = calculateCumulativeDiscountSubtotal();

        let totalAmount = newCumulativeOriginalSubtotal + newTotalDiscount;
        let newGstAmount = totalAmount - newCumulativeSubtotal;

        if (noGST) {

            newGstAmount = 0;
            totalAmount = newCumulativeSubtotal;
        }

        // Calculate the total amount as the difference between
        // the sum of all original amounts and the sum of all discount original amounts

        setCumulativeSubtotal(newCumulativeSubtotal);
        setGstAmount(newGstAmount);
        setTotalAmount(totalAmount);
        setTotalDiscount(newTotalDiscount);
        setValue("subtotals", newCumulativeSubtotal);
        setValue("gst_amount", roundToTwoDecimalDigits(newGstAmount));
        setValue("total_amount", totalAmount);
    };

    // Function to add item
    const addItem = () => {
        append({ total_student: 1, price: 0, item_type: "course" });
        setSubtotals([...subtotals, 0]);
        setOriginalSubtotals([...originalSubtotals, 0]);
    };

    // Function to add discount row
    const addDiscountRow = (event) => {
        const index = parseInt(event.target.getAttribute('data-index')) + 1;
        // Use insert instead of append. The index is the index after the course_item of which the clicked button belongs to.
        insert(parseInt(index), {total_student: -1, price: 0, item_type: "discount"});
        // append({ total_student: -1, price: 0, item_type: "discount" });
        setSubtotals([...subtotals, 0]);
        setOriginalSubtotals([...originalSubtotals, 0]);
    };

    
    // Function to update price and totals
    // Function to update price and totals
    const updatePriceAndTotals = (index, fee, gstRate) => {
        setValue(`items[${index}].original_price`, fee);
        setValue(`items[${index}].price`, commonBeforeGSTAmount(fee, gstRate));

        updateSubtotal(index);
        updateTotals();
    };

    // Function to update course code selection on the item
    const updateCourseCode = (index, codeValue) => {
        const courseCodeOptionConfig = configValues?.course_code?.find(
            (option) => option.value === codeValue
        );
        setValue(`items[${index}].course_code`, courseCodeOptionConfig);
        handleCourseChange(courseCodeOptionConfig.value, index);
    };

    // Function to handle course code selection
    const handleCourseChange = async (courseCodeId, index) => {
        let courseInformationData = courseFee.find(
            (data) => data.id === courseCodeId
        );

        setValue(
            `items[${index}].trade_option`,
            configValues?.tradeOptions?.find(
                (data) => data.value === courseInformationData?.type_of_trade
            ) ?? ''
        );
        setValue(
            `items[${index}].application_option`,
            configValues?.tradeApplication?.find(
                (data) =>
                    data.value === courseInformationData?.application_option
            ) ?? ''
        );
        setValue(
            `items[${index}].trade_category`,
            configValues?.tradeCategory?.find(
                (data) => data.value === courseInformationData?.trade_category
            ) ?? ''
        );
        setValue(
            `items[${index}].course`,
            configValues?.attcCourses?.find(
                (data) => data.value === courseInformationData?.course_id
            ) ?? ''
        );
        // Update price and totals
        updatePriceAndTotals(index, courseInformationData?.fee, gstRate);
    };

    // Function to handle the dropdown selection trade option, application ,course and trade category
    const handleDropDownChange = (index) => {
        // Get the selected values from the form inputs
        let tradeOption = getValues(`items[${index}].trade_option`);
        let applicationOption = getValues(`items[${index}].application_option`);
        let course = getValues(`items[${index}].course`);
        let tradeCategory = getValues(`items[${index}].trade_category`);

        // Create a filter object for non-empty values
        let filterObject = {};

        if (tradeOption) {
            filterObject.type_of_trade = tradeOption?.value;
        }

        if (applicationOption) {
            filterObject.application_option = applicationOption?.value;
        }

        if (course) {
            filterObject.course_id = course?.value;
        }
        if (tradeCategory) {
            filterObject.trade_category = tradeCategory.value;
        }
        let courseFees = 0;
        updatePriceAndTotals(index, courseFees, gstRate);

        // Check if there are non-empty fields to filter
        if (Object.keys(filterObject).length > 0) {
            // Filter the courseFee array based on the non-empty fields
            let filteredCourseFee = courseFee.filter((data) => {
                for (let key in filterObject) {
                    if (data[key] !== filterObject[key]) {
                        return false;
                    }
                }
                return true;
            });
            let mergedData = {};

            filteredCourseFee?.forEach((data) => {
                mergedData = { ...mergedData, ...data };
            });

            if (mergedData?.fee) {
                courseFees = mergedData?.fee;
            }
            
            if (filteredCourseFee.length == 1){
                updatePriceAndTotals(index, courseFees, gstRate);
                updateCourseCode(index, mergedData?.id);
            }
        }
    };

    // Calculate subtotal for a specific item
    const calculateSubtotal = (index) => {
        const field = fields[index];
        const isDiscount = field?.item_type === "discount";

        const price = parseFloat(
            isDiscount
                ? commonBeforeGSTAmount(
                    getValues(`items[${index}].price`),
                    gstRate
                )
                : getValues(`items[${index}].price`)
        );

        const totalStudents = parseFloat(
            getValues(`items[${index}].total_student`)
        );
        if (!isNaN(totalStudents) && !isNaN(price)) {
            const subtotal = calculateCommonSubtotal(price, totalStudents);
            return roundToTwoDecimalDigits(subtotal);
        }

        return 0;
    };

    // Calculate subtotal for a specific item
    const calculateOriginalSubtotal = (index) => {
        const field = fields[index];

        const price = parseFloat(getValues(`items[${index}].original_price`));

        const totalStudents = parseFloat(
            getValues(`items[${index}].total_student`)
        );
        if (!isNaN(totalStudents)) {
            const subtotal = calculateCommonSubtotal(price, totalStudents);
            return roundToTwoDecimalDigits(subtotal);
        }

        return 0;
    };

    // function get the original price
    const getOriginalPrice = (index) => {
        let priceValue = getValues(`items[${index}].original_price`);
        if (
            isNaN(priceValue) ||
            priceValue === undefined ||
            priceValue === ""
        ) {
            return "";
        }
        return formatNumberWithCommas(priceValue);
    };

    // function get the before tax for Discount
    const discountBeforeGSTAmount = (index) => {
        let priceValue = getValues(`items[${index}].price`);
        if (
            isNaN(priceValue) ||
            priceValue === undefined ||
            priceValue === ""
        ) {
            return "";
        }
        return commonBeforeGSTAmount(priceValue, gstRate);
    };

    const updateSubtotal = (index) => {
        const newSubtotals = [...subtotals];
        const newOriginalSubtotals = [...originalSubtotals];
        newSubtotals[index] = calculateSubtotal(index);
        newOriginalSubtotals[index] = calculateOriginalSubtotal(index);
        setSubtotals(newSubtotals);
        setOriginalSubtotals(newOriginalSubtotals);

        // Update all totals after changing the subtotal
        updateTotals();
    };
    // Function to update all subtotals when "Apply no GST" is toggled
    const updateAllSubtotals = () => {
        const newSubtotals = fields.map((_, index) => calculateSubtotal(index));
        const newOriginalSubtotals = fields.map((_, index) =>
            calculateOriginalSubtotal(index)
        );
        setSubtotals(newSubtotals);
        setOriginalSubtotals(newOriginalSubtotals);
        // Update all totals after toggling "Apply no GST"
        updateTotals();
    };

    const removeItem = (index) => {
        // Remove the item from the list
        remove(index);

        // When the course item with discount is removed.
        if (indexWithDiscount.includes(index)){
            //removing discount row of the deleted course item.
            remove(index);
        }

        // Update the subtotal for the removed item
        const newSubtotals = [...subtotals];
        newSubtotals.splice(index, 1);
        setSubtotals(newSubtotals);
        setOriginalSubtotals([...originalSubtotals]);
        // Update all totals after removing the item
        updateTotals();
    };

    useEffect(() => {
        const fetchData = async () => {
            // Update all subtotals when "Apply no GST" is toggled
            await updateAllSubtotals();
            // Update GST-related values
            if (quotation && quotation?.gst_amount !== undefined && !noGST) {
                setValue("gst_amount", quotation.gst_amount);
                setGstAmount(quotation?.gst_amount);
            }
        };

        fetchData(); // Call the async function
    }, [noGST, fields]);

    useEffect(() => {
        // Update all subtotals when "Apply no GST" is toggled
        setTotalAmount(parseFloat(cumulativeSubtotal) + parseFloat(gstAmount));
    }, [gstAmount]);

    useEffect(() => {
        // Initialize the `no_gst` checkbox based on `quotation.no_gst`
        setNoGST(quotation?.no_gst || false);
    }, [quotation]); // Run this effect when `quotation` changes


    useEffect(() => {
        const withDiscount = fields.reduce((accumulator, value, index) => {
            if (value.item_type === 'discount') {
                // Since the discount row is always inserted after a course item, decrement index by 1.
                accumulator.push(index - 1);
            }
            return accumulator;
        }, []);
        setIndexWithDiscount(withDiscount);

        // Updating the total student of the discounts
        fields.forEach((field, index) => {
            if (field.item_type === 'discount') {
                // Getting the value of the discount's parent course_item total_student.
                const totalStudents = getValues(`items[${index-1}].total_student`);
                // Setting the discount's total_student value.
                setValue(`items[${index}].total_student`, -totalStudents); // Value of the discount should always be negative.
                updateSubtotal(index);
            }
        });

    }, [fields])

    return (
        <div>
            <table className="table">
                <thead>
                    <tr>
                        <th width="1%">#</th>
                        <th width="35%">Course information</th>
                        <th width="5%">Student</th>
                        <th width="10%">Price</th>
                        <th width="3%">Subtotal</th>
                        <th width="2%">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {fields.length === 0 && (
                        <tr>
                            <td colSpan={6}>No items</td>
                        </tr>
                    )}
                    {fields.map((field, index) => (
                        <tr key={field.id} className={(indexWithDiscount.includes(index)) ? "border-bottom-0" : ''}>
                            <td style={{ verticalAlign: "top" }} className={(indexWithDiscount.includes(index)) ? "border-bottom-0" : ''}>
                                {index + 1}
                            </td>
                            <td style={{ verticalAlign: "top"}} className={(indexWithDiscount.includes(index)) ? "border-bottom-0" : ''}>
                                {field.item_type === "course" && (
                                    <>
                                        <CourseInformation
                                            control={control}
                                            index={index}
                                            configValues={configValues}
                                            handleCourseChange={
                                                handleCourseChange
                                            }
                                            handleDropDownChange={
                                                handleDropDownChange
                                            }
                                            errors={errors}
                                            setValue={setValue}
                                            getValues={getValues}
                                        />
                                        {!indexWithDiscount.includes(index) && (
                                            <>
                                                <Button
                                                    variant="secondary"
                                                    onClick={addDiscountRow}
                                                    className="mt-1"
                                                    data-index={index}
                                                >
                                                    <Plus size={15}></Plus> Add discount
                                            </Button>
                                            </>
                                        )}
                                            
                                    </>
                                )}
                                {field.item_type === "discount" && (
                                    <Controller
                                        name={`items[${index}].discount_item`}
                                        control={control}
                                        defaultValue={
                                            "Less discount allowed" || ""
                                        }
                                        render={({ field }) => (
                                            <AutoGrowTextarea
                                                name={field.name}
                                                id={field.id}
                                                fieldValue={field.value}
                                                handleBlur={field.onBlur}
                                                isInvalid={
                                                    !!errors.discount_item
                                                }
                                                handleFormChangeDetails={
                                                    field.onChange
                                                }
                                            />
                                        )}
                                    />
                                )}
                            </td>
                            <td style={{ verticalAlign: "top" }} className={(indexWithDiscount.includes(index)) ? "border-bottom-0" : ''}>
                                <Controller
                                    name={`items[${index}].total_student`}
                                    defaultValue={
                                        field.item_type === "discount" ? -1 : 1
                                    }
                                    control={control}
                                    render={({ field: inputProps }) => (
                                        <Form.Control
                                            type="number"
                                            {...inputProps}
                                            readOnly={field.item_type === "discount"}
                                            // value={fields[index-1]}
                                            onChange={(e) => {
                                                // console.log("index", index);
                                                const newValue = parseFloat(
                                                    e.target.value
                                                );
                                                if (
                                                    field.item_type ===
                                                    "discount" &&
                                                    newValue > 0
                                                ) {
                                                    // If it's a discount, and the value is positive, set it to negative
                                                    inputProps.onChange(
                                                        -newValue
                                                    );
                                                    console.log("index", index);
                                                } else {
                                                    inputProps.onChange(
                                                        newValue
                                                    );
                                                }
                                                updateSubtotal(index);
                                                // Update the fields when the total_student of a course_item is changed.
                                                // this will inturn call the useEffect of fields to update the values of the
                                                // total_student of the course_item's respective discounts if it has any.
                                                update();
                                            }}
                                        />
                                    )}
                                />
                            </td>
                            <td style={{ verticalAlign: "top" }} className={(indexWithDiscount.includes(index)) ? "border-bottom-0" : ''}>
                                <Controller
                                    name={`items[${index}].original_price`}
                                    control={control}
                                    defaultValue={0}
                                    render={({ field: inputProps }) => (
                                        <>
                                            <Form.Control
                                                style={{ width: "100%" }}
                                                type="hidden"
                                                step="0.01"
                                                {...inputProps}
                                            />
                                        </>
                                    )}
                                />
                                {field.item_type === "discount" && (
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        value={discountBeforeGSTAmount(index)}
                                        readOnly={true}
                                    />
                                )}

                                <Row>
                                    {field.item_type === "discount" && (
                                        <Col md={6}>
                                            (<small>Enter after noGST</small>)
                                        </Col>
                                    )}
                                    <Col
                                        md={
                                            field.item_type !== "discount"
                                                ? 12
                                                : 6
                                        }
                                    >
                                        <Controller
                                            name={`items[${index}].price`}
                                            control={control}
                                            defaultValue={0}
                                            render={({ field: inputProps }) => (
                                                <>
                                                    <Form.Control
                                                        style={{
                                                            width:
                                                                field.item_type ===
                                                                    "discount"
                                                                    ? "100%"
                                                                    : "100%",
                                                        }}
                                                        type="number"
                                                        step="0.01"
                                                        readOnly={
                                                            field.item_type ===
                                                                "discount"
                                                                ? false
                                                                : true
                                                        }
                                                        {...inputProps}
                                                        onChange={(e) => {
                                                            inputProps.onChange(
                                                                e
                                                            );
                                                            updateSubtotal(
                                                                index
                                                            );
                                                        }}
                                                    />
                                                </>
                                            )}
                                        />
                                    </Col>
                                </Row>
                                {field.item_type !== "discount" && (
                                    <small>
                                        After GST {getOriginalPrice(index)}
                                    </small>
                                )}
                            </td>

                            <td
                                style={{ verticalAlign: "top" }}
                                className={(indexWithDiscount.includes(index)) ? " currency border-bottom-0" : 'currency'}
                            >
                                {formatNumberWithCommas(subtotals[index])}
                            </td>
                            <td style={{ verticalAlign: "top" }} className={(indexWithDiscount.includes(index)) ? "border-bottom-0" : ''}>
                                <Button
                                    variant="danger"
                                    onClick={() => {
                                        removeItem(index);
                                    }}
                                >
                                    <Trash size={10} />
                                </Button>
                            </td>
                        </tr>
                    ))}

                    <tr>
                        <td colSpan={2} className="border-0">
                            <Button
                                variant="secondary"
                                onClick={addItem}
                                className="mt-1 mx-1 "
                            >
                                <Plus size={15}></Plus> Add item
                            </Button>
                        </td>
                        {fields.length > 0 && (
                            <>
                                <td
                                    colSpan={2}
                                    className="text-end fs-4 border-0"
                                >
                                    Subtotal
                                </td>
                                <td className=" currency border-0">
                                    {formatNumberWithCommas(cumulativeSubtotal)}
                                </td>
                            </>
                        )}
                    </tr>
                    {fields.length > 0 && (
                        <>
                            <tr>
                                <td colSpan={4} className=" border-0"></td>
                                <td colSpan={2} className=" border-0">
                                    <Controller
                                        name="no_gst" // Name should match the field name in your form data
                                        control={control} // Use RHF control
                                        defaultValue={false} // Set the initial value of the checkbox
                                        render={({ field }) => (
                                            <Form.Check
                                                type="checkbox"
                                                label="Apply no GST"
                                                checked={field.value}
                                                onChange={(e) => {
                                                    setNoGST(e.target.checked);
                                                    field.onChange(e);
                                                    updateAllSubtotals(); // Recalculate totals when the option is toggled
                                                }}
                                            />
                                        )}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td
                                    colSpan={4}
                                    className="text-end fs-4 border-0"
                                >
                                    Total tax amount{" "}
                                    {noGST ? 0 : configValues.gst}%
                                </td>
                                <td className=" border-0">
                                    <Controller
                                        name="gst_amount" // Name should match the field name in your form data
                                        control={control} // Use RHF control
                                        render={({ field }) => (
                                            <Form.Control
                                                type="number"
                                                readOnly={noGST ? true : false}
                                                {...field}
                                                step="0.01"
                                                onChange={(e) => {
                                                    setGstAmount(
                                                        e.target.value
                                                    );
                                                    field.onChange(e);
                                                }}
                                                disabled={true}
                                            />
                                        )}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td
                                    colSpan={4}
                                    className="text-end fs-4 border-0"
                                >
                                    Total amount
                                </td>
                                <td className="currency border-0">
                                    {formatNumberWithCommas(totalAmount)}
                                </td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>

            <Controller
                name="subtotals"
                control={control}
                defaultValue="" // Initial value of the hidden field
                render={({ field }) => <input type="hidden" {...field} />}
            />

            <Controller
                name="total_amount"
                control={control}
                defaultValue="" // Initial value of the hidden field
                render={({ field }) => <input type="hidden" {...field} />}
            />
        </div>
    );
};

export default ItemRow;
