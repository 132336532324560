import React, { useEffect, useState, useCallback } from "react";
import { Container, Card, Col, Form, Row, Button, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { SnackbarAlert } from "../../../components/SnackbarAlert.js";
import api from "../../../service/api";

export const AddEditRole = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const add_page = id === 'add' ? true : false
    const schema = Yup.object().shape({
        app_id       : Yup.string().required('This field is required'),
        name         : Yup.string().required('This field is required'),
        description  : Yup.string().required('This field is required'),
    });

    // States
    const [applications, setApplications] = useState([]);
    const [role, setRole] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [notif, setNotif] = useState({
        notifMsg  : "",
        open      : false,
        severity  : "success",
    });
    const [initValues, setInitialValues] = useState({
        app_id: '',
        name: '',
        description: ''
    });

    // Functions
    const getApplications = useCallback(async () => {
        const response = await api.get('apps-with-permissions', {});
        response.data.forEach(app => {
            if (app.pretty_permissions) {
                Object.keys(app.pretty_permissions).forEach(key => {
                    app.pretty_permissions[key].forEach(permission => {
                        permission.checked = false;
                    })
                })
            }
            
        })
        setApplications(response.data);
    }, []);

    const getRole = useCallback(async () => {
        const response = await api.get('roles/'+id, {});
        setRole(response.data);
    }, [id]);

    // UseEffects
    useEffect(() => {
        getApplications();
        if (!add_page) getRole();
    }, [getApplications, getRole, add_page])

    useEffect(() => {
        if (role) {
            setInitialValues({
                app_id      : role.app_id,
                name        : role.name,
                description : role.description,
                permissions : role.permissions
            })
        }
    }, [role])

    useEffect(() => {
        if (initValues.app_id && applications) {
            let selected_app = applications.find(app => app.id === initValues.app_id) 
            
            if (selected_app) {
                let permissions = selected_app.pretty_permissions
                //EDIT
                if (!add_page) {
                    Object.keys(permissions).forEach(key => {
                        permissions[key].forEach(permission => {
                            let found = initValues.permissions.find(item => item.id === permission.id)
                            if (found !== undefined) {
                                permission.checked = true
                            }
                        })
                    })
                }
                setPermissions(permissions)
            } else {
                setPermissions([])
            }    
        }
    }, [initValues, applications, add_page])

    const applicationChangeHandler = (e, setFieldValue) => {
        let selected_app_id = e.target.value
        if (selected_app_id) {
            setFieldValue("app_id", selected_app_id)
            
            let selected_app = applications.find(app => app.id === selected_app_id) 
            
            if (selected_app.pretty_permissions) {
                 let permissions = selected_app.pretty_permissions
                //EDIT
                if (!add_page) {
                    Object.keys(permissions).forEach(key => {
                        permissions[key].forEach(permission => {
                            let found = initValues.permissions.find(item => item.id === permission.id)
                            if (found !== undefined) {
                                permission.checked = true
                            }
                        })
                    })
                }
                setPermissions(permissions)
            } else {
                setPermissions([])
            }
           
        }
    }

    const submitForm = (values, actions) => {
        
        // Get all checked permissions
        let permission_ids=[]
        Object.keys(permissions).forEach(key => {
            permissions[key].filter(permission => {
                if (permission.checked) {
                    permission_ids.push(permission.id)
                }
                return true;
            })
        })

        values.permission_ids = permission_ids
        let subscribe = add_page
                        ? api.post('roles', values)
                        : api.put('roles/' + id, values)
        
        subscribe.then(response => {
            if (!response.success) {
                setNotif({
                    notifMsg: response.message,
                    open: true,
                    severity: "danger",
                });
            }
            if (response.status === 'ok') {
                setNotif({
                    notifMsg: 'Successfully saved',
                    open: true,
                    severity: "success",
                });

                if (add_page) {
                    navigate("/roles")
                }
            }
        })
        .catch(error => {
            actions.setSubmitting(false)
            setNotif({
                notifMsg: error.message,
                open: true,
                severity: "danger",
            });
        })
    }

    const permissionChangeHandler = (e, category, permission_index) => {
        let checkedValue = e.target.checked
        let temp_permissions = {...permissions}
        temp_permissions[category][permission_index].checked = checkedValue
        setPermissions(temp_permissions)
    }

    return (
        <>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">{ add_page ? 'Add role' : 'Edit role'}</h1>
                <Card>
                    <Card.Body>
                        <Formik
                            enableReinitialize
                            initialValues={initValues}
                            validationSchema={schema}
                            onSubmit={(values, actions) => submitForm(values, actions)}
                        >
                            {
                                ({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        {errors.submit && (
                                        <Alert className="my-3" variant="danger">
                                            <div className="alert-message">{errors.submit}</div>
                                        </Alert>
                                        )}

                                        <Row>
                                            <Col md={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Application</Form.Label>
                                                    <Form.Select
                                                        name="app_id"
                                                        value={values.app_id}
                                                        isInvalid={Boolean(touched.app_id && errors.app_id)}
                                                        onBlur={handleBlur}
                                                        onChange={(e) => applicationChangeHandler(e, setFieldValue)} 
                                                    >
                                                        <option value="">Choose an option</option>
                                                        {
                                                            applications.map( (app, index) => {
                                                                return (
                                                                    <option key={index} value={app.id}>{app.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                    {/* {!!touched.app_id && (
                                                        <Form.Select.Feedback type="invalid">
                                                        {errors.app_id}
                                                        </Form.Select.Feedback>
                                                    )}
                                                    */}
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Role name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        value={values.name}
                                                        isInvalid={Boolean(touched.name && errors.name)}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                    />
                                                    {!!touched.name && (
                                                        <Form.Control.Feedback type="invalid">
                                                        {errors.name}
                                                        </Form.Control.Feedback>
                                                    )}
                                                   
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Description</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="description"
                                                        value={values.description}
                                                        isInvalid={Boolean(touched.description && errors.description)}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}   
                                                    />
                                                    {!!touched.description && (
                                                        <Form.Control.Feedback type="invalid">
                                                        {errors.description}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        
                                        <Row>
                                            <Col md={12}>
                                                <Form.Label>Permissions</Form.Label>
                                                <Row>
                                                    {permissions !== undefined &&
                                                        Object.keys(permissions).map((key, i) => {
                                                        return (
                                                            <Col md={3} key={i}>
                                                                <Form.Group className="mb-3">
                                                                    {permissions[key].length > 0 &&
                                                                        <h5>{key.toUpperCase()}</h5>
                                                                    }
                                                                    {permissions[key].map((permission, i) => {
                                                                            return (
                                                                                <Form.Check
                                                                                    key={permission.id}
                                                                                    type="checkbox"
                                                                                    id={permission.id}
                                                                                    label={permission.name}
                                                                                    checked={permission.checked}
                                                                                    onChange={(e) => permissionChangeHandler(e, key, i)}
                                                                                />
                                                                            )
                                                                    })}
                                                                </Form.Group>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>    
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={{ span: 3, offset: 9 }} className="text-end">
                                                <Button
                                                    variant="secondary"
                                                    className="me-2"
                                                    onClick={() => navigate(-1)}
                                                    >
                                                    Back
                                                </Button>
                                                <Button variant="primary" type="submit">
                                                    Submit
                                                </Button>
                                            </Col>
                                        </Row> 
                                    </Form>    
                                )
                            }
                              
                        </Formik>   
                    </Card.Body>
                </Card>
                <SnackbarAlert notif={notif} setNotif={setNotif} />
            </Container>
        </>
    )
}