import React from "react";
import { Table } from "react-bootstrap";
import { tradeCategoryDisplay } from "../../../../../utils/utility";

const StudentChangeHistory = ({ histories }) => {
    return (
        <>
            <Table responsive bordered striped>
                <thead>
                    <tr>
                        <td>Old student</td>
                        <td>New student</td>
                        <td>Old values</td>
                        <td>New values</td>
                        <td>Remarks</td>
                        <td>Updated by</td>
                        <td>Date</td>
                    </tr>
                </thead>
                <tbody>
                    {histories?.map((history, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    {history?.students?.existing?.name && (<>
                                    Name: {history?.students?.existing?.name}<br />
                                    </>)}
                                    {history?.students?.existing?.fin && (<>
                                    Fin: {history?.students?.existing?.fin}
                                    </>)}
                                </td>
                                <td>
                                    {history?.students?.new?.name && (<>
                                    Name: {history?.students?.new?.name} <br/>
                                    </>)}
                                    {history?.students?.new?.fin && (<>
                                    Fin: {history?.students?.new?.fin}
                                    </>)}
                                </td>
                                <td>{tradeCategoryDisplay(history?.old_values)}</td>
                                <td>{tradeCategoryDisplay(history?.new_values)}</td>
                                <td>{history?.remarks}</td>
                                <td>{history?.user?.name}</td>
                                <td>{history?.created_at}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </>
    );
};

export default StudentChangeHistory;
