import * as yup from "yup";

export const schema = yup.object().shape({
    sales_person_name: yup.string().required(),
    department: yup.object().required(),
    location: yup.object().required(),
    course: yup.object().required(),
    //type_of_trade: yup.object().required(),
    date: yup.date().required(),
    customer_name: yup.string().required(),
    customer_fin: yup.string().required(),
    course_fee: yup.number().required(),
});
