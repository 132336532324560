import React from "react";
import { Button, Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { addDefaultOption } from "../../../../../utils/utility";
import { Filter, FilePlus } from "react-feather";

const Search = ({ setSearchValue, searchValue, search, config, setModalPopUp, resetValues}) => {
    return (
        <>
            <Row className="g-2 mb-2 d-flex justify-content-around">
                <Col md={4}>
                    <Select
                        isClearable
                        className="react-select-container render-above-1"
                        classNamePrefix="react-select"
                        placeholder="Course code"
                        options={addDefaultOption(config?.course_code)}
                        name="course_code"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                course_code: event?.value,
                            });
                        }}
                    />
                </Col>
                <Col md={4}>
                    <Select
                        isClearable
                        className="react-select-container render-above-1"
                        classNamePrefix="react-select"
                        placeholder="Course"
                        options={addDefaultOption(config?.course)}
                        name="course"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                course: event?.value,
                            });
                        }}
                    />
                </Col>
                <Col md={4}>
                    <Select
                        isClearable
                        className="react-select-container render-above-1"
                        classNamePrefix="react-select"
                        placeholder="Type of Trade"
                        options={addDefaultOption(config?.type_of_trade)}
                        name="type_of_trade"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                type_of_trade: event?.value,
                            });
                        }}
                    />
                </Col>
            </Row>

            <Row className="row-flex g-2 d-flex justify-content-around">
                <Col md={4}>
                    <Select
                        isClearable
                        className="react-select-container render-above"
                        classNamePrefix="react-select"
                        placeholder="Application option"
                        options={addDefaultOption(config?.application_option)}
                        name="application_option"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                application_option: event?.value,
                            });
                        }}
                    />
                </Col>
                <Col md={4} className="justify-content-end">
                    <Select
                        isClearable
                        className="react-select-container render-above"
                        classNamePrefix="react-select"
                        placeholder="Trade category"
                        options={addDefaultOption(config?.trade_category)}
                        name="trade_category"
                        onChange={(event) => {
                            setSearchValue({
                                ...searchValue,
                                trade_category: event?.value,
                            });
                        }}
                    />
                </Col>
                <Col className="mb-2 d-flex ps-3 justify-content-end">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-top`}>Search filter</Tooltip>}
                    >
                        <Button className="me-1" size="lg" variant="info" onClick={() => search()} title="Search filter">
                            <Filter size={15} />
                        </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-top`}>Add New Course Fee</Tooltip>}
                    >
                        <Button className="ms-1" 
                                size="lg" 
                                variant="info" 
                                title="Add New Course Fee" 
                                onClick={() => {
                                    resetValues()
                                    setModalPopUp(true, "add");
                        }}>
                            <FilePlus size={15} />
                        </Button>
                    </OverlayTrigger>
                </Col>
            </Row>
        </>
    );
};
export default Search;
