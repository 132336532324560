import React, { useCallback, useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Container, Alert, Button, Col, Form, Row, Badge } from "react-bootstrap";
import { SnackbarAlert } from "../../../components/SnackbarAlert.js";
import api from "../../../service/api.js";
import { useSelector } from "react-redux";
import { Search } from "react-feather";
import { Lists } from "./ReportCETCoretrade/List.js";
import SearchFunc from "./ReportCETCoretrade/Search.js";
import download from "downloadjs";

const ReportCETCoretrade = ({ title }) => {
    /** Permissions */
    const permissionData = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = permissionData?.data?.permissions;

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [overAll, setOverAll] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [defaultConfig, setDefaultConfig] = useState();
    const { state } = useLocation();
    const previousSearchValue = state?.previousSearchValue
        ? state?.previousSearchValue
        : null;
    const [rerender, setRerender] = useState();

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const [searchButtonStatus, setSearchButtonStatusValue] = useState(true);

    const searchFields = {
        start_date: new Date().getFullYear()+'-01-01',
        end_date: '',
    };
    const [searchValue, setSearchValue] = useState(
        previousSearchValue ? previousSearchValue : searchFields
    );

    const search = (page = 1) => {
        const searchParam = { ...searchValue, ...page };
        setSearchValue(searchParam);
        getEnrolments(searchParam);
    };

    // const excel = useCallback(async (searchValue) => {
    //     try {
    //         setLoading(true);
    //         const response = await api.getexcel("tms/get-excel-enrollment-no-payment-statistics", searchValue);
    //         if(response && response.size !== 0){
    //             const excelFilename = `List of Students with Payment Pending_${new Date()
    //                 .toISOString()
    //                 .slice(0, 10)}`;
    //             download(response, excelFilename, "application/xlsx");
    //             setNotif({
    //                 notifMsg: "Excel exported",
    //                 open: true,
    //                 severity: "success"
    //             });
    //         }else{
    //             setNotif({
    //                 notifMsg: "No data to export",
    //                 open: true,
    //                 severity: "danger",
    //             });
    //         }
    //         setLoading(false);
    //     } catch (error) {
    //         setLoading(false);
    //         setError(error.response?.data?.message);
    //         setData([]);
    //     }

    //     setLoading(false);
    // }, []);
    
    const getEnrolments = useCallback(async (search) => {
        try {
            setLoading(true);
            const response = await api.get("tms/enrollments/statistic/monthly-cet-coretrade", search);
            if(response){
                setData(response?.data?.data)
                setTotal(response?.data?.total_values)
                setOverAll(response?.data?.overall_value)
                setError();
                setLoading(false);
            }else{
                setLoading(false);
                setError('Missing data.');
                setData([]);
            }
        } catch (error) {
            setLoading(false);
            setError(error.response?.data?.message);
            setData([]);
        }

        setLoading(false);
    }, []);
    
    useEffect(() => {
        getEnrolments(searchValue);
    }, [getEnrolments, rerender]); //  eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state?.open) {
            setNotif({
                notifMsg: state.notifMsg,
                open: state.open,
                severity: state.severity,
            });
        }
        window.history.replaceState(null, "");
    }, [state]); //  eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <Helmet title={`Statistic > ${title}`} />
            <Container fluid className="p-0">
                {permission?.includes(
                                        "MONTHLY-REPORT"
                        ) && (
                        <>
                            <h1 className="h3 mb-3">{title}</h1>
                            <Card>
                                <Card.Header>
                                    {error && (
                                        <Alert className="my-3" variant="danger">
                                            <div className="alert-message">{error}</div>
                                        </Alert>
                                    )}
                                    
                                    <SearchFunc 
                                        setSearchValue={setSearchValue}
                                        searchValue={searchValue}
                                        search={search}
                                        // excel={excel}
                                    />
                                </Card.Header>
                                <Card.Body>
                                    {loading && <div>Loading...</div>}
                                    {/* {JSON.stringify(data) } */}
                                    {!loading && data && (
                                        <Lists rawData={data} totalValues={total} overAllTotal={overAll}/>
                                    )}
                                </Card.Body>
                            </Card>
                        </>     
                )}
            </Container>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </React.Fragment>
    );
};

export default ReportCETCoretrade;