import React, { forwardRef, useEffect, useRef } from "react";

import styled from "styled-components";
import { Col, Form, Pagination, Row, Table } from "react-bootstrap";
import { useSticky } from "react-table-sticky";
import {
    useTable,
    usePagination,
    useRowSelect,
    useExpanded,
    useMountedLayoutEffect,
    useGlobalFilter,
    useBlockLayout,
} from "react-table";

const DynamicTable = (props) => {
    const {
        columns,
        dataPagination,
        api,
        className,
        withCheckbox,
        hiddenColumns,
        singleSelectCheckbox,
        setSelectedRows = false,
        isPaginated = true,
        isStriped = true,
        isExpanded = false,
        updateData = null,
        updateSort = null,
        canViewRow = null,
        canSignRow = null,
        defaultSelectedRows,
        getRowId,
        onChangeSelectedRowsId = false,
        toDeleteRow = false,
    } = props;

    const { data, meta, links } = dataPagination;

    const IndeterminateCheckbox = forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = useRef();
            const resolvedRef = ref || defaultRef;

            useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            return (
                <>
                    <Form.Check type="checkbox" ref={resolvedRef} {...rest} />
                </>
            );
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        toggleAllRowsExpanded,
        isAllRowsExpanded,
        selectedFlatRows,
        state: {
            pageIndex,
            apiNextPage,
            apiPreviousPage,
            totalPage,
            currentPage,
            firstPage,
            lastPage,
            selectedRowIds,
        },
        toggleRowSelected,
        toggleAllRowsSelected,
    } = useTable(
        {
            columns: columns,
            data: data,
            getRowId,
            initialState: {
                pageIndex: 0,
                hiddenColumns: hiddenColumns ? hiddenColumns : [],
                autoResetExpanded: false,
                apiNextPage: links?.next !== null ? true : false,
                apiPreviousPage: links?.prev !== null ? true : false,
                pageSize: meta?.total ? meta?.total : 200,
                totalPage: meta?.last_page,
                currentPage: meta?.current_page,
                firstPage: meta?.first,
                lastPage: meta?.last_page,
                selectedRowIds: defaultSelectedRows || {},
            },
            stateReducer: singleSelectCheckbox
                ? (newState, action) => {
                      if (action.type === "toggleRowSelected") {
                          newState.selectedRowIds = {
                              [action.id]: true,
                          };
                      }

                      return newState;
                  }
                : () => {},
            updateData: updateData,
            updateSort: updateSort,
            canViewRow: canViewRow,
            canSignRow: canSignRow,
        },
        useGlobalFilter,
        useExpanded,
        usePagination,
        useRowSelect,
        useBlockLayout,
        useSticky,
        (hooks) => {
            if (withCheckbox && data && data.length > 0) {
                hooks.visibleColumns.push((columns) => [
                    // Let's make a column for selection
                    {
                        id: "selection",
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllPageRowsSelectedProps }) =>
                            !singleSelectCheckbox && (
                                <div>
                                    <IndeterminateCheckbox
                                        {...getToggleAllPageRowsSelectedProps()}
                                    />
                                </div>
                            ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }) => {
                            return (
                                row.depth === 0 && (
                                    <div>
                                        <IndeterminateCheckbox
                                            {...row.getToggleRowSelectedProps()}
                                        />
                                    </div>
                                )
                            );
                        },
                        width: 40,
                        sticky: "left",
                    },
                    ...columns,
                ]);
            }
        }
    );

    const gotoPageApi = (page) => {
        api({
            page,
        });
    };

    const deselectSelectedRow = (id) => {
        const rowToDelete = page.find((data) => {
            return data.original.id === id;
        });

        rowToDelete && toggleRowSelected(rowToDelete.id, false);
    };

    const deselectAllRows = () => {
        toggleAllRowsSelected(false);
    };

    useEffect(() => {
        if (isExpanded) toggleAllRowsExpanded(true);
    }, [isAllRowsExpanded, isExpanded, toggleAllRowsExpanded]);

    useEffect(() => {
        if (setSelectedRows) {
            const mappedSelectedFlatRows = selectedFlatRows.map(
                (row) => row.original
            );

            setSelectedRows(mappedSelectedFlatRows);
        }
    }, [setSelectedRows, selectedFlatRows]);

    useEffect(() => {
        if (toDeleteRow === "ALL") {
            deselectAllRows();
            return;
        }

        if (toDeleteRow.length) {
            deselectSelectedRow(toDeleteRow);
        }
    }, [toDeleteRow]);

    useMountedLayoutEffect(() => {
        onChangeSelectedRowsId && onChangeSelectedRowsId(selectedRowIds);
    }, [selectedRowIds]);

    const Styles = styled.div`
        .table {
            .th,
            .td {
                background-color: #fff;
                overflow: hidden;
                border-top: none;
            }

            &.sticky {
                overflow: scroll;
                .header,
                .footer {
                    position: sticky;
                    z-index: 1;
                    width: fit-content;
                }
            }
        }
        .table > :not(:first-child) {
            border-top: none;
        }
    `;

    return (
        <>
            {isPaginated && (
                <Row className="mt-2">
                    <Col md="6">
                        <span className="mx-2">
                            Page{" "}
                            <strong>
                                {api && `${currentPage} of ${totalPage}`}
                                {!api &&
                                    `${pageIndex + 1} of  ${
                                        pageOptions.length
                                    }`}
                            </strong>
                        </span>
                    </Col>
                    <Col md="6">
                        <Pagination className="float-end">
                            {!api && (
                                <>
                                    {" "}
                                    <Pagination.First
                                        onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={() => previousPage()}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() => nextPage()}
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}
                                    />
                                </>
                            )}

                            {api && (
                                <>
                                    <Pagination.First
                                        onClick={() => gotoPageApi(firstPage)}
                                        disabled={!apiPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPageApi(currentPage - 1)
                                        }
                                        disabled={!apiPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPageApi(currentPage + 1)
                                        }
                                        disabled={!apiNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPageApi(lastPage)}
                                        disabled={!apiNextPage}
                                    />
                                </>
                            )}
                        </Pagination>
                    </Col>
                </Row>
            )}

            <Styles>
                <Table
                    striped={isStriped}
                    bordered
                    responsive
                    {...getTableProps()}
                    className={`table-layout-fixed ${className}`}
                >
                    <thead className="header">
                        {headerGroups.map((headerGroup) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                className="tr"
                            >
                                {headerGroup.headers.map((column) => (
                                    <th
                                        width={column.width}
                                        {...column.getHeaderProps()}
                                        className="th"
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {data && data.length < 1 && (
                            <tr>
                                <td colSpan={columns.length}>
                                    No records found
                                </td>
                            </tr>
                        )}
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <React.Fragment key={i}>
                                    {row.original.header ? (
                                        <tr className="tr">
                                            <td
                                                colSpan={columns.length}
                                                className="header td"
                                            >
                                                {row.original.header}
                                            </td>
                                        </tr>
                                    ) : (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell, index) => {
                                                const checkbox = withCheckbox
                                                    ? 1
                                                    : 0;

                                                if (index === checkbox) {
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                            key={index}
                                                            className="td"
                                                        >
                                                            {parseInt(
                                                                cell.row.id
                                                            ) + 1}
                                                        </td>
                                                    );
                                                }
                                                return (
                                                    <td
                                                        className={`td
                                                            ${
                                                                cell.column
                                                                    .id ===
                                                                "actions"
                                                                    ? "table-action"
                                                                    : ""
                                                            }`}
                                                        {...cell.getCellProps()}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </Table>
            </Styles>

            {isPaginated && (
                <Row className="mt-2">
                    <Col md="6">
                        <span className="mx-2">
                            Page{" "}
                            <strong>
                                {api && `${currentPage} of ${totalPage}`}
                                {!api &&
                                    `${pageIndex + 1} of  ${
                                        pageOptions.length
                                    }`}
                            </strong>
                        </span>
                    </Col>
                    <Col md="6">
                        <Pagination className="float-end">
                            {!api && (
                                <>
                                    {" "}
                                    <Pagination.First
                                        onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={() => previousPage()}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() => nextPage()}
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}
                                    />
                                </>
                            )}

                            {api && (
                                <>
                                    <Pagination.First
                                        onClick={() => gotoPageApi(firstPage)}
                                        disabled={!apiPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPageApi(currentPage - 1)
                                        }
                                        disabled={!apiPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPageApi(currentPage + 1)
                                        }
                                        disabled={!apiNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPageApi(lastPage)}
                                        disabled={!apiNextPage}
                                    />
                                </>
                            )}
                        </Pagination>
                    </Col>
                </Row>
            )}
        </>
    );
};
export default DynamicTable;
