import React from "react";
import { Col, Row } from "react-bootstrap";

export const AddEditReceivedPaymentDetails = ({ project }) => {
    return (
        <>
            {project && project.portfolio && (
                <Row>
                    <Col md={6}>
                        <h4 className="mb-3 mx-4">Order to:</h4>
                        <Row className="mx-3 mt-3">
                            <Col md={4} className="font-weight-bold">
                                Company name
                            </Col>
                            <Col md={6}>{project.portfolio.name || "-"}</Col>
                        </Row>
                        <Row className="mx-3 mt-3">
                            <Col md={4} className="font-weight-bold">
                                Email
                            </Col>
                            <Col md={5}>{project.portfolio.email || "-"}</Col>
                        </Row>
                        <Row className="mx-3 mt-3">
                            <Col md={4} className="font-weight-bold">
                                Contact number
                            </Col>
                            <Col md={5}>
                                {project.portfolio.contact_number || "-"}
                            </Col>
                        </Row>
                        <Row className="mx-3 mt-3">
                            <Col md={4} className="font-weight-bold">
                                Address
                            </Col>
                            <Col md={5}>{project.portfolio.address || "-"}</Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </>
    );
};
