import React from "react";
import { Badge } from "react-bootstrap";

const VerifiedStudentsTable = ({ studentArray, onNameChange, onIdNumberChange, onRemove, showRemove, isDisabled }) => {
    return (
        <table className="table-sm">
            {studentArray.map((student, studentIndex) => (
                <tr key={studentIndex}>
                    <td>
                        <input
                            type="text"
                            className="form-control"
                            value={student.name}
                            onChange={(e) => onNameChange(studentIndex, e)}
                            style={{width: "100%"}}
                            disabled={student.enrollment_id || isDisabled ? true : false}
                        />
                    </td>
                    <td style={{width: "20%"}}>
                        <input
                            type="text"
                            className="form-control"
                            value={student.id_number}
                            onChange={(e) => onIdNumberChange(studentIndex, e)}
                            disabled={student.enrollment_id || isDisabled ? true : false}
                        />
                    </td>
                    {student?.enrollment_id && (
                        <div className="m-2">
                         <Badge bg="danger">Student already enrolled</Badge>
                        </div>
                    )}
                    {showRemove && (
                         <td style={{width: "10%"}}>
                         <button type="button" className="btn btn-outline-secondary" onClick={() => onRemove(studentIndex)}>
                             Remove
                         </button>
                        </td>
                    )}
                   
                </tr>
            ))}
        </table>
    );
};

export default VerifiedStudentsTable;
