import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ArrowLeft } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../../../../service/api";
import CreditNote from "../CreditNoteDetails/CreditNote";
import InvoiceData from "../CreditNoteDetails/InvoiceData";
import FormRow from "./FormRow";

const CreditNotePayment = () => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state;
    const [loading, setLoading] = useState(false);
    const [creditNotes, setCreditNote] = useState();
    const getCreditNotes = useCallback(async (id) => {
        try {
            const response = await api.get(`tms/credit-notes/${id}`);
            setCreditNote(response.data);
        } catch (error) {}
    }, []);

    /** use effect  */
    useEffect(() => {
        getCreditNotes(id);
    }, [id, getCreditNotes, loading]);
    return (
        <>
            <>
                <React.Fragment>
                    <Helmet title="Credit note - refund" />
                    <Container fluid className="p-0">
                        <Row>
                            <Col md={6}>
                                <h1 className="h3 mb-3">
                                    Credit Note - refund
                                </h1>
                            </Col>
                            {console.log(state)}
                            <Col md={6} className="text-end">
                                <Button
                                    className="ms-2 "
                                    onClick={() =>
                                        navigate(
                                            `/tms/approved-credit-notes`
                                        )
                                    }
                                >
                                    <ArrowLeft className="feather" />
                                </Button>
                            </Col>
                        </Row>

                        {loading && (<>Loading...</>)}
                        {!loading && (<Row>
                            <Col md="4" xl="3">
                                <InvoiceData creditNotes={creditNotes} />
                            </Col>
                            <Col md="8" xl="9">
                                <CreditNote creditNotes={creditNotes} />
                                <br />

                                {(creditNotes?.refund_status === null ||
                                    creditNotes?.refund_status === "") &&
                                    creditNotes?.status_key?.toUpperCase() ===
                                        "APPROVED" &&
                                    creditNotes?.refund?.toUpperCase() ===
                                        "YES" && <FormRow creditNotes={creditNotes} loading={loading} setLoading={setLoading} />}
                            </Col>
                        </Row>)}
                    </Container>
                </React.Fragment>
            </>
        </>
    );
};

export default CreditNotePayment;
