const tableColumns = [
    {
        Header: "S/N",
        accessor: "serial",
    },
    {
        Header: "Revenue code",
        accessor: "code",
    },
    {
        Header: "Revenue date",
        accessor: "revenue_date",
    },
    {
        Header: "Total (S$)",
        accessor: "total",
    },
    {
        Header: "Status",
        accessor: "status",
    },
    {
        Header: "Actions",
        accessor: "action ",
    },
];

export { tableColumns };
