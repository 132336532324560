import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Container, Alert, Button, Col, Row } from "react-bootstrap";
import { tableColumns } from "./PaidCompany/Data.js";
import { SnackbarAlert } from "../../../components/SnackbarAlert";
import api from "../../../service/api";
import { useSelector } from "react-redux";
import { Plus } from "react-feather";
import { Lists } from "./PaidCompany/ListSalesRevenue";
import SearchCompanyStatistic from "../CommonComponents/SearchCompanyStatistic.js";
import download from "downloadjs";

const PaidCompany = ({ status, title }) => {
    /** Permissions */
    const permissionData = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = permissionData?.data?.permissions;

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [defaultConfig, setDefaultConfig] = useState();
    const { state } = useLocation();
    const [message, setMessage] = useState('Loading...')
    const previousSearchValue = state?.previousSearchValue
        ? state?.previousSearchValue
        : null;
    const [rerender, setRerender] = useState();

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const searchFields = {
        start_date: "",
        end_date: "",
        // sponsor_company_id: "",
        company: ""
    };
    const [searchValue, setSearchValue] = useState(
        previousSearchValue ? previousSearchValue : searchFields
    );
    const search = (page) => {
        const searchParam = { ...searchValue, ...page };
        setSearchValue(searchParam);
        getEnrolments(searchParam);
    };
    const excel = (search) => {
        exportExcel(search);
    };
    const exportExcel = useCallback(async(search) => {
        try{
            setLoading(true)
            const response = await api.getexcel("tms/invoice/get-list-company-no-test-date", search)
            if(response.size === 0){
                setError('No records found')
            }else{
                const excelFilename = `List of Payments Without Test date_${new Date()
                    .toISOString()
                    .slice(0, 10)}`;
                download(response, excelFilename, "application/xlsx");
            }
            setLoading(false)
        }catch(error){
            setLoading(false)
            setError(error.message)
            setData([])
        }
    }, [])

    const resetSearch = () => {
        setSearchValue(searchFields);
        getEnrolments(searchFields);
    };
const getEnrolments = useCallback(async (search) => {
        setLoading(true)
        if((search.start_date === '' || search.end_date === '') && search.company === ''){
            setMessage('*Select start date and end date or by company')
            setData([])
            setTotal(0)
        }else{
            setMessage('Loading...')
            try {
                // console.log('response', search);return;
                const response = await api.get("tms/invoice/get-company-no-test-date", search);
                setData(response?.data?.data);
                // setData(response?.data)
                setTotal(response?.data?.total)
                setError();
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setError(error.message);
                setData([]);
            }
            setLoading(false);
        }
    }, []);

    //
    // useEffects
    //

    useEffect(() => {
        getEnrolments(searchValue);
    }, [getEnrolments, rerender]); //  eslint-disable-line react-hooks/exhaustive-deps
    const getConfig = useCallback(async () => {
        const configResponse = await api.get(`tms/enrolments/config`, {});
        setDefaultConfig(configResponse.data);
    }, []);
    useEffect(() => {
        getConfig();
    }, [getConfig]); //  eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state?.open) {
            setNotif({
                notifMsg: state.notifMsg,
                open: state.open,
                severity: state.severity,
            });
        }
        window.history.replaceState(null, "");
    }, [state]); //  eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <Helmet title="Statistic > Paid company" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Payment received without test date</h1>
                <Card>
                    <Card.Header>
                        {error && (
                            <Alert className="my-3" variant="danger">
                                <div className="alert-message">{error}</div>
                            </Alert>
                        )}
                        <Row>
                            <Col md={12}>
                                <SearchCompanyStatistic
                                    search={search}
                                    exportExcel={excel}
                                    setSearchValue={setSearchValue}
                                    searchValue={searchValue}
                                    defaultConfig={defaultConfig}
                                    resetSearch={resetSearch}
                                    total={total}
                                    attcTestDate
                                />
                            </Col>
                        </Row>
                        
                    </Card.Header>
                    <Card.Body>
                        {loading && <div className="text-danger">{message}</div>}
                        {/* {JSON.stringify(data) } */}
                        {!loading && data?.data && (
                            <Lists
                                columns={tableColumns}
                                rawData={data}
                                enrolmentApi={search}
                                status={status}
                                searchValue={searchValue}
                                rerender={rerender}
                                setRerender={setRerender}
                            />
                            
                        )}
                    </Card.Body>
                </Card>
            </Container>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </React.Fragment>
    );
};

export default PaidCompany;
