import {
    createContext,
    useContext,
    useEffect,
    useReducer,
    useState,
} from "react";

import api from "../service/api";
import CookieService from "../service/CookieService";
import { isValidToken, setSession } from "../utils/jwt";
import { useLocation, useNavigate } from "react-router-dom";
import { SnackbarAlert } from "../components/SnackbarAlert";
import { useActions } from "../hooks/useAction";
import { LoadingContext } from "../App";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

const JWTReducer = (state, action) => {
    switch (action.type) {
        case INITIALIZE:
            return {
                isAuthenticated: action.payload.isAuthenticated,
                isInitialized: true,
                user: action.payload.user,
            };
        case SIGN_IN:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
            };
        case SIGN_OUT:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };

        case SIGN_UP:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
            };

        default:
            return state;
    }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
    const location = useLocation();
    const [state, dispatch] = useReducer(JWTReducer, initialState);
    const navigate = useNavigate();
    const { userDetails } = useActions();
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [isLoadingActive, setIsLoadingActive] = useContext(LoadingContext);

    useEffect(() => {
        const initialize = async () => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const googleToken = urlParams.get('token') || null;

                if (googleToken) {
                    console.log("G token: ", googleToken, typeof(googleToken));
                    
                    const googleLoginResponse = await api.get(
                        "sso/login",
                        {
                            token: googleToken
                        }
                    );
                    console.log("response: ", googleLoginResponse);
                    if (googleLoginResponse.success || googleLoginResponse.status === 'ok') {
                        console.log("SUCCESS G LOGIN");
                        setSession(googleLoginResponse.data.token.accessToken);
                        userDetails(googleLoginResponse.data);
                        // dispatch({
                        //     type: SIGN_IN,
                        //     payload: {
                        //         user: googleLoginResponse.data,
                        //     },
                        // });
                        dispatch({
                            type: INITIALIZE,
                            payload: {
                                isAuthenticated: true,
                                user: googleLoginResponse.data,
                            },
                        });

                        // redirection(googleLoginResponse.data);
                        redirect(googleLoginResponse.data);
                    } else {
                        setNotif({
                            notifMsg: googleLoginResponse.message,
                            open: true,
                            severity: "danger",
                        });
                    }
                    // userDetails(userResponse.data);

                    // dispatch({
                    //     type: SIGN_IN,
                    //     payload: {
                    //         user: userResponse.data,
                    //     },
                    // });
                } else {
                    const accessToken = CookieService.get("authToken");
                    if (accessToken && isValidToken(accessToken) ) {
                        setSession(accessToken);
    
                        const userResponse = await api.get(
                            "users/verification",
                            {}
                        );
                        userDetails(userResponse.data);
    
                        dispatch({
                            type: INITIALIZE,
                            payload: {
                                isAuthenticated: true,
                                user: userResponse.data,
                            },
                        });
                    } else {
                        dispatch({
                            type: INITIALIZE,
                            payload: {
                                isAuthenticated: false,
                                user: null,
                            },
                        });
                    }
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: INITIALIZE,
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
                CookieService.remove("authToken");
            }
        };

        initialize();
    }, []);

    const signIn = async (email, password) => {
        const accessToken = CookieService.get("authToken");
        setIsLoadingActive(true);

        try {
            const response = await api.post("login", {
                email,
                password,
            });
            const { token, full_name } = response.data;

            if (!accessToken) setSession(token.accessToken);
            const userResponse = await api.get("users/verification", {});
            console.log(location);
            userDetails(userResponse.data);

            dispatch({
                type: SIGN_IN,
                payload: {
                    user: userResponse.data,
                },
            });

            // setNotif({
            //     notifMsg: "Login successful",
            //     open: true,
            //     severity: "success",
            // });

            // redirection(userResponse.data);
            redirect(userResponse.data);

            setIsLoadingActive(false);
        } catch (error) {
            let message = "Username/password is invalid";
            // Error code 403 is Unauthorized access
            if (error.response.data.error_code == 403){
                message = "This account is deactivated";
            }

            setNotif({
                notifMsg: message,
                open: true,
                severity: "danger",
            });
            setIsLoadingActive(false);
        }
    };

    const signOut = async () => {
        try {
            await api.get("logout");

            setSession(null);
            CookieService.remove("authToken");

            const accessToken = CookieService.get("authToken");

            if (!accessToken) {
                dispatch({ type: SIGN_OUT });
                setNotif({
                    notifMsg: "Logout successful",
                    open: true,
                    severity: "success",
                });

                navigate("/auth/sign-in");
            }
        } catch (error) {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    const redirection = (userResponse) => {
        let appListArray = [];
        if (userResponse && userResponse.apps) {
            if (userResponse.apps.length > 0) {
                appListArray = userResponse.apps.map((item) => {
                    return item?.slug?.toLowerCase();
                });
            }
        }
        if (appListArray.length === 1) {
            navigate(`/${appListArray[0]}`);
        } else {
            navigate(`/`);
        }
    };

    const redirect = (data) => {
        if (
            location?.state?.page_redirect !== "" &&
            location?.state?.page_redirect !== undefined
        ) {
            const navUrl = location?.state?.page_redirect.replace(
                origin,
                ""
            );
            
            if (navUrl === '/'){
                navigate(data.setting['default_homepage'] ?? "/tms");
            } else {
                navigate(navUrl);
            }
        } else {
            navigate(data.setting['default_homepage'] ?? "/tms");
        }
    }
    // const signUp = async (email, password, firstName, lastName) => {
    //   const response = await api.post("/api/auth/sign-up", {
    //     email,
    //     password,
    //     firstName,
    //     lastName,
    //   });
    //   const { accessToken, user } = response.data;

    //   window.localStorage.setItem("accessToken", accessToken);
    //   dispatch({
    //     type: SIGN_UP,
    //     payload: {
    //       user,
    //     },
    //   });
    // };

    //const resetPassword = (email) => console.log(email);

    return (
        <>
            <AuthContext.Provider
                value={{
                    ...state,
                    method: "jwt",
                    signIn,
                    signOut,
                    // signUp,
                    // resetPassword,
                }}
            >
                {children}
            </AuthContext.Provider>
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
}

export { AuthContext, AuthProvider };
