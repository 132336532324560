import React, { useEffect, useContext, useState, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";
import { tableColumns } from "./tableDataQuotation.js";
import { ActionRouteContext } from "../Documents/Documents";
import { AddEditQuotation } from "./AddEditQuotation.js";
import { ViewQuotation } from "./ViewQuotation.js";
import api from "../../../service/api";
import { QuotationListing } from "./QuotationListing.js";
import { LoadingContext } from "../../../App.js";
import { useParams } from "react-router-dom";

export const Quotation = ({ project }) => {
    let { id } = useParams();

    //
    // States
    //

    const [quotation, setQuotation] = useState([]);
    const action = useContext(ActionRouteContext);
    const setIsLoadingActive = useContext(LoadingContext);

    //
    // Functions
    //

    const getQuotations = useCallback(
        async (param) => {
            const response = await api.get("quotations", {
                project_id: id,
                ...param,
            });

            if (response) {
                setQuotation(response);
                setIsLoadingActive(false);
            }
        },
        [setIsLoadingActive, id]
    );

    //
    // useEffects
    //

    useEffect(() => {
        setIsLoadingActive(true);
        getQuotations();
    }, [getQuotations, setIsLoadingActive]);

    return (
        <React.Fragment>
            {project && (
                <Helmet title={`${project.name} > Quotation`} />
            )}
            <Container fluid className="p-0">
                {
                    // Expense View List
                    action === "list" && quotation && quotation.data ? (
                        <QuotationListing
                            module="quotations"
                            columns={tableColumns}
                            rawData={quotation}
                            parentApi={getQuotations}
                        />
                    ) : // Create Edit Expense
                    action === "addEdit" ? (
                        <AddEditQuotation project={project} />
                    ) : (
                        <ViewQuotation project={project} />
                    )
                }
            </Container>
        </React.Fragment>
    );
};
