import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";
import api from "../../../../../service/api";
import { addDefaultOption } from "../../../../../utils/utility";
import { useNavigate } from "react-router-dom";

const ApprovalForm = ({
    creditNoteId,
    getCreditNote,
    setLoading,
    approvalType,
    canView,
    checkIfApprovalFormView
}) => {
    const navigate = useNavigate();
    const [approvalInput, setApprovalInput] = useState({
        status: "",
        remarks: "",
    });
    const [errorMessage, setErrorMessage] = useState(null)

    const handleSubmit = (event) => {
        event.preventDefault()
        if(approvalInput.status.length === 0){
            setErrorMessage('Please select a status')
            setLoading(false)
        }else{
            setLoading(true)
            if(approvalType === "sales_director")
                creditNoteSalesStatusUpdate()
            if(approvalType === "finance_director")
                creditNoteFianceStatusUpdate()
        }
        
    }

    /** update credit note status */
    const creditNoteSalesStatusUpdate = async () => {
        try {
            await api.post(
                `tms/credit-notes/sales-status/${creditNoteId}`,
                approvalInput
            );
            getCreditNote(creditNoteId);
            setLoading(false);
        } catch (error) {
            if(error.response.status === 422){
                //if payload "status" is empty
                setLoading(false)
                alert(error.response.data.message)
            }else if(error.response.status === 401){
                //if unauthorized
                navigate("/tms/401")
            }
            else{
                //if other errors
                setLoading(false)
                alert(error.response.data.message)
            }
        }
    };

    const creditNoteFianceStatusUpdate = async () => {
        try {
            await api.post(
                `tms/credit-notes/finance-status/${creditNoteId}`,
                approvalInput
            );
            getCreditNote(creditNoteId);
            setLoading(false);
        } catch (error) {
            if(error.response.status === 422){
                //if payload "status" is empty
                setLoading(false)
                alert(error.response.data.message)
            }else if(error.response.status === 401){
                //if unauthorized
                navigate("/tms/401")
            }
            else{
                //if other errors
                setLoading(false)
                alert(error.response.data.message)
            }
        }
    };

    useEffect(() => {
        checkIfApprovalFormView()
    }, [])
    
    return (
        <>
            {canView && (
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Status</Form.Label>
                        <Select
                            className="is-invalid react-select-container"
                            classNamePrefix="react-select"
                            options={addDefaultOption([
                                {
                                    value: "APPROVED",
                                    label: "Approved",
                                },
                                {
                                    value: "REJECTED",
                                    label: "Reject",
                                },
                            ])}
                            name="status"
                            onChange={(event) => {
                                setApprovalInput({
                                    ...approvalInput,
                                    status: event.value,
                                });
                                if(event.value !== ""){
                                    setErrorMessage(null)
                                }
                            }}
                        />
                        {errorMessage !== null && (
                            <span className="pt-1"><strong className="text-danger">{errorMessage}</strong></span>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Remarks</Form.Label>
                        <AutoGrowTextarea
                            type="textarea"
                            name="remarks"
                            handleFormChangeDetails={(event) => {
                                setApprovalInput({
                                    ...approvalInput,
                                    remarks: event.target.value,
                                });
                            }}
                        />
                    </Form.Group>
                    <Button
                        variant="primary"
                        className="col-md-12"
                        size="lg"
                        type="submit"
                    >
                        Update
                    </Button>
                    {/* {approvalType === "sales_director" && (
                        <Button
                            variant="primary"
                            className="col-md-12"
                            size="lg"
                            onClick={creditNoteSalesStatusUpdate}
                            type="button"
                        >
                            Update
                        </Button>
                    )} */}

                    {/* {approvalType === "finance_director" && (
                        <Button
                            variant="primary"
                            className="col-md-12"
                            size="lg"
                            onClick={creditNoteFianceStatusUpdate}
                            type="submit"
                        >
                            Update
                        </Button>
                    )} */}
                </Form>
            )}
        </>
    );
};
export default ApprovalForm;
