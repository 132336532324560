const tableColumns = [
  {
    Header: "S.no",
  },

  {
    Header: "Student",
    accessor: "student.name",
  },
  {
    Header: "FIN/NRIC",
    accessor: "student.fin",
  },

  {
    Header: "Status",
    id: "status",
    accessor: (data) => {
      return (
        <>
          <div> {data?.status}</div>
          {data?.credit_note?.reasons && (
            <>
              <b>Reason</b>
              <hr />
              <div>{data?.credit_note?.reasons}</div>
            </>
          )}
        </>
      );
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];

export { tableColumns };
