import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row, Form } from "react-bootstrap";
import { PlusCircle, Search } from "react-feather";
import { useParams } from "react-router-dom";

export const RequisitionSearch = ({
    setSearchValue,
    searchValue,
    search,
    module,
}) => {
    const navigate = useNavigate();
    let { id } = useParams();

    return (
        <Row className="mb-3">
            <Col md={10}>
                <Row>
                    <Col md={3}>
                        <Form.Control
                            className="d-inline-block"
                            type="text"
                            placeholder="Requisition code"
                            onChange={(e) =>
                                setSearchValue({
                                    ...searchValue,
                                    requisition_code: e.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col md={3}>
                        <Form.Control
                            className="d-inline-block"
                            type="date"
                            onChange={(e) =>
                                setSearchValue({
                                    ...searchValue,
                                    request_date: e.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col md={3}>
                        <Form.Control
                            className="d-inline-block"
                            type="text"
                            placeholder="Status"
                            onChange={(e) =>
                                setSearchValue({
                                    ...searchValue,
                                    status: e.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col md={3}>
                        <Button
                            variant="primary"
                            className="me-1 mb-1"
                            onClick={() => search({ page: 1 })}
                        >
                            <Search className="feather" />
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Col md={2}>
                <Button
                    variant="primary"
                    className="me-1 float-end"
                    onClick={() =>
                        navigate(`/intellidocs/process/${module}/${id}/create`)
                    }
                >
                    <PlusCircle className="feather" />
                </Button>
            </Col>
        </Row>
    );
};
