import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card } from "react-bootstrap";
import { SnackbarAlert } from "../../../components/SnackbarAlert.js";
import { DeleteModal } from "../../../components/DeleteModal";
import api from "../../../service/api";
import _ from "lodash";
import { TablePagination } from "../../../components/TablePagination.js";
import { RevenueSearch } from "./RevenueSearch.js";

export const RevenueListing = ({
    module,
    columns,
    rawData,
    parentApi = null,
}) => {
    const location = useLocation();
    const state = location.state;

    //
    // States
    //

    const [rerender, setRerender] = useState(false);
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const [searchValue, setSearchValue] = useState({
        revenue_code: "",
        revenue_date: "",
    });

    //
    // Functions
    //

    const search = (page) => {
        const searchParam = { ...searchValue, ...page };
        parentApi(searchParam);
    };

    const openDeleteModal = (id) => {
        setModalInfo({
            id: id,
            notifMsg: "Are you sure you want to void this item?",
            open: true,
            severity: "danger",
        });
    };

    const deleteApi = async (id) => {
        try {
            await api.delete(`revenues/${id}`, {});
            setRerender(!rerender);
            setNotif({
                notifMsg: "Delete successful",
                open: true,
                severity: "danger",
            });
        } catch (error) {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    //
    // UseEffects
    //

    useEffect(() => {
        if (parentApi !== null && rerender) {
            parentApi();
            setRerender(!rerender);
        }
    }, [rerender, parentApi]);

    useEffect(() => {
        if (state?.open) {
            setNotif({
                notifMsg: state.notifMsg,
                open: state.open,
                severity: state.severity,
            });
        }
        window.history.replaceState(null, "");
    }, [state]);

    return (
        <>
            <Card className="shadow-none mb-0">
                <Card.Header className="shadow-none pb-0">
                    <Card.Title tag="h5">
                        {_.capitalize(module)} list
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <div className="bg-light p-4 rounded">
                        <RevenueSearch
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                            search={search}
                            module={module}
                        />
                        <TablePagination
                            project="intellidocs"
                            module={module}
                            columns={columns}
                            rawData={rawData}
                            parentApi={parentApi}
                            openDeleteModal={openDeleteModal}
                        />
                    </div>
                </Card.Body>
            </Card>

            {/* Modal Components */}

            <SnackbarAlert notif={notif} setNotif={setNotif} />
            <DeleteModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={deleteApi}
            />
        </>
    );
};
