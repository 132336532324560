import React, { useContext, useEffect, useState } from "react";

import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import moment from "moment";

import { AddEditBasic } from "./AddEditBasic";
import { AddEditRoles } from "./AddEditRoles";
import { AddEditBudget } from "./AddEditBudget";
import { BUDGET_TREND } from "../../../config";
import api from "../../../../../service/api";
import { LoadingContext } from "../../../../../App";

export const AddEditProject = () => {
    const navigate = useNavigate();
    let { action } = useParams();

    //
    // States
    //

    const setIsLoadingActive = useContext(LoadingContext);
    const [activeTab, setActiveTab] = useState("basic");
    const [dateRange, setDateRange] = useState([]);
    const [trends, setTrends] = useState(BUDGET_TREND);
    const [storeLockValue, setStoreLockValue] = useState();
    const [yupContractValue, setYupContractValue] = useState({
        preliminary: Yup.number().required(),
        substructure: Yup.number().required(),
        superstructure: Yup.number().required(),
        architectural: Yup.number().required(),
        mAndE: Yup.number().required(),
        externalWork: Yup.number().required(),
    });
    const [yupWorkingBudget, setYupWorkingBudget] = useState({
        preliminary: Yup.number().required(),
        substructure: Yup.number().required(),
        superstructure: Yup.number().required(),
        architectural: Yup.number().required(),
        mAndE: Yup.number().required(),
        externalWork: Yup.number().required(),
    });
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    //
    // Functions
    //

    const onSubmit = (data) => {
        const formData = {
            cctv_link: data.cctvLink,
            claim_name: data.claimName,
            code: data.projectCode,
            end_date: moment(new Date(data.duration[1])).format("YYYY/MM/DD"),
            lock_budget: data.lockBudget,
            project_name: data.projectName,
            roles_budgets: {
                budget: [
                    {
                        contract_value: data.contractValue,
                        working_budget: data.workingBudget,
                    },
                ],
                roles: [data.roles],
            },
            start_date: moment(new Date(data.duration[0])).format("YYYY/MM/DD"),
            status: data.status,
            trends: JSON.stringify(trends),
        };

        try {
            action === "add"
                ? api.post("intellibuild/project-set-ups", formData)
                : api.put(`intellibuild/project-set-ups/${action}`, formData);

            navigate(`/intellibuild/projects`, {
                state: {
                    open: true,
                    notifMsg: `Successfully ${
                        action === "add" ? "created" : "updated"
                    } project`,
                    severity: "success",
                },
            });
        } catch {
            setNotif({
                notifMsg: "Something went wrong with the server",
                open: true,
                severity: "danger",
            });
        }
    };

    const setInitialValues = (data) => {
        reset({
            cctvLink: data.cctv_link,
            claimName: data.claim_name,
            contractValue: data.roles_budgets.budget[0].contract_value,
            duration: [new Date(data.start_date), new Date(data.end_date)],
            lockBudget: data.lock_budget,
            projectCode: data.code,
            projectName: data.project_name,
            roles: data.roles_budgets.roles[0],
            status: data.status,
            workingBudget: data.roles_budgets.budget[0].working_budget,
        });
        setDateRange([new Date(data.start_date), new Date(data.end_date)]);
        setTrends(JSON.parse(data.trends));
    };

    const schema = Yup.object({
        // Basic
        cctvLink: Yup.string().required(),
        claimName: Yup.string().required(),
        duration: Yup.array().of(Yup.string()).required(),
        projectCode: Yup.string().required(),
        projectName: Yup.string().required(),
        status: Yup.string().required(),

        // Roles
        roles: Yup.object().shape({
            ceo: Yup.string().required(),
            projectManager: Yup.string().required(),
            projectDirector: Yup.string().required(),
            qsManager: Yup.string().required(),
        }),

        // Budget
        contractValue: Yup.object().shape(yupContractValue),
        workingBudget: Yup.object().shape(yupWorkingBudget),
    });

    const {
        control,
        getValues,
        handleSubmit,
        register,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    //
    // UseEffects
    //

    useEffect(() => {
        if (action !== "add") {
			setIsLoadingActive(true)
            const getProject = async () => {
                try {
                    const response = await api.get(
                        `intellibuild/project-set-ups/${action}`,
                        {}
                    );

                    setInitialValues(response.data);
                    setStoreLockValue(response.data.lock_budget);
					setIsLoadingActive(false)
                } catch (error) {
                    setNotif({
                        notifMsg: "Something went wrong with the server",
                        open: true,
                        severity: "danger",
                    });
                }
            };
            getProject();
        }
    }, [action]);

    return (
        <React.Fragment>
            <Helmet title="Projects" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Projects</h1>

                <Row>
                    <Col md="3" xl="2">
                        <Card>
                            <Card.Header>
                                <Card.Title tag="h5" className="mb-0">
                                    Project set up
                                </Card.Title>
                            </Card.Header>
                            <ListGroup variant="flush">
                                <ListGroup.Item
                                    tag="a"
                                    onClick={() => setActiveTab("basic")}
                                    action
                                    active={activeTab === "basic"}
                                >
                                    Basic
                                </ListGroup.Item>
                                <ListGroup.Item
                                    tag="a"
                                    onClick={() => setActiveTab("roles")}
                                    action
                                    active={activeTab === "roles"}
                                >
                                    Roles
                                </ListGroup.Item>
                                <ListGroup.Item
                                    tag="a"
                                    onClick={() => setActiveTab("budget")}
                                    action
                                    active={activeTab === "budget"}
                                >
                                    Budget
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Col>
                    <Col md="9" xl="10">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {activeTab === "basic" && (
                                <AddEditBasic
                                    register={register}
                                    control={control}
                                    errors={errors}
                                    dateRange={dateRange}
                                    setDateRange={setDateRange}
                                />
                            )}
                            {activeTab === "roles" && (
                                <AddEditRoles
                                    register={register}
                                    errors={errors}
                                />
                            )}
                            {activeTab === "budget" && (
                                <AddEditBudget
                                    errors={errors}
                                    getValues={getValues}
                                    register={register}
                                    setTrends={setTrends}
                                    setYupContractValue={setYupContractValue}
                                    setYupWorkingBudget={setYupWorkingBudget}
                                    trends={trends}
                                    yupContractValue={yupContractValue}
                                    yupWorkingBudget={yupWorkingBudget}
                                    storeLockValue={storeLockValue}
                                />
                            )}
                            <Card>
                                <Card.Body className="text-center">
                                    <Button variant="primary" type="submit">
                                        {" "}
                                        <FontAwesomeIcon
                                            icon={faPaperPlane}
                                        />{" "}
                                        Save
                                    </Button>
                                    <Button
                                        variant="link"
                                        onClick={() =>
                                            navigate(`/intellibuild/projects`)
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </Card.Body>
                            </Card>
                        </form>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};
