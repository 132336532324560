import React, { useState } from "react";
import { useTable, usePagination, useRowSelect } from "react-table";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Table,
    Row,
    Col,
    Alert,
    Pagination,
    Form,
} from "react-bootstrap";
import { SnackbarAlert } from "../../../components/SnackbarAlert";
import { Eye } from "react-feather";
import Select from "react-select";
import api from "../../../service/api";
import { addDefaultOption } from "../../../utils/utility";
import DynamicTable from "../../../components/version-two-components/DynamicTable";
import SendEmailList from "../SendEmail/SendEmailList";

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        );
    }
);
const Lists = ({ columns, rawData, enrolmentApi, status }) => {
    const [loading, setLoading] = useState(false);
    const [students, setStudents] = useState({});
    const navigate = useNavigate();
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const [selectedSchedule, setSelected] = useState("");

    const createSchedule = async () => {
        const postData = {
            schedule_option: selectedSchedule,
            selected_renewals: selectedFlatRows.map((d) => d.original.id),
        };
        try {
            await api.post(`tms/renewals`, postData);
        } catch (error) {}
    };
    const { meta, links, data } = rawData;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        selectedFlatRows,
        page,
        state: {
            pageSize,
            canNextPage,
            canPreviousPage,
            totalPage,
            currentPage,
            firstPage,
            lastPage,
        },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: meta?.per_page,
                canNextPage: links?.next !== null ? true : false,
                canPreviousPage: links?.prev !== null ? true : false,
                totalPage: meta?.last_page,
                currentPage: meta?.current_page,
                firstPage: meta?.first,
                lastPage: meta?.last_page,
            },
        },

        usePagination,
        useRowSelect,
        (hooks) => {
            status === "pending" &&
                hooks.visibleColumns.push((columns) => [
                    {
                        id: "selection",

                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <div>
                                <IndeterminateCheckbox
                                    {...getToggleAllRowsSelectedProps()}
                                />
                            </div>
                        ),
                        Cell: ({ row }) => (
                            <div>
                                <IndeterminateCheckbox
                                    {...row.getToggleRowSelectedProps()}
                                />
                            </div>
                        ),
                    },
                    ...columns,
                ]);
        }
    );
    const gotoPage = (page) => {
        enrolmentApi({
            page,
        });
    };

    return (
        <>
            {selectedFlatRows.map((d) => d.original.id).length > 0 && (
                <>
                    <hr />
                    <Row>
                        
                        <Col md={6}>
                            <Button
                                variant="info"
                                size="lg"
                                onClick={createSchedule}
                            >
                                Preview renewal
                            </Button>
                        </Col>

                    </Row><br></br>
                </>
            )}

            {data.length > 0 && (
                <Row>
                    {totalPage > 1 && (
                        <Row>
                            <Col md="6">
                                <span className="mx-2">
                                    Page {""}
                                    <strong>
                                        {currentPage} of {totalPage}
                                    </strong>
                                </span>
                            </Col>
                            <Col md="6">
                                <Pagination className="float-end">
                                    <Pagination.First
                                        onClick={() => gotoPage(firstPage)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPage(currentPage - 1)
                                        }
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPage(currentPage + 1)
                                        }
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(lastPage)}
                                        disabled={!canNextPage}
                                    />
                                </Pagination>
                            </Col>
                        </Row>
                    )}
                    <Table responsive striped bordered {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell, index, row) => {
                                            if (
                                                index === 1 &&
                                                status === "pending"
                                            ) {
                                                return (
                                                    <td key={index}>
                                                        {currentPage > 1
                                                            ? (currentPage -
                                                                  1) *
                                                                  pageSize +
                                                              i +
                                                              1
                                                            : i + 1}
                                                    </td>
                                                );
                                            } else if (
                                                index === 0 &&
                                                (status === "sent" ||
                                                    status === "rejected")
                                            ) {
                                                return (
                                                    <td key={index}>
                                                        {currentPage > 1
                                                            ? (currentPage -
                                                                  1) *
                                                                  pageSize +
                                                              i +
                                                              1
                                                            : i + 1}
                                                    </td>
                                                );
                                            }
                                            // if (index + 1 === row.length) {
                                            //     return (
                                            //         <td key={index}>
                                            //             <Button
                                            //                 variant="info"
                                            //                 className="me-1 mb-1"
                                            //                 onClick={() =>
                                            //                     navigate(
                                            //                         `/tms/renewals/${cell.row.original.enrolment?.id}`
                                            //                     )
                                            //                 }
                                            //             >
                                            //                 <Eye className="feather" />
                                            //             </Button>
                                            //         </td>
                                            //     );
                                            // }
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    {totalPage > 1 && (
                        <Row>
                            <Col md="6">
                                <span className="mx-2">
                                    Page {""}
                                    <strong>
                                        {currentPage} of {totalPage}
                                    </strong>
                                </span>
                            </Col>
                            <Col md="6">
                                <Pagination className="float-end">
                                    <Pagination.First
                                        onClick={() => gotoPage(firstPage)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPage(currentPage - 1)
                                        }
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPage(currentPage + 1)
                                        }
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(lastPage)}
                                        disabled={!canNextPage}
                                    />
                                </Pagination>
                            </Col>
                        </Row>
                    )}
                </Row>
            )}
            {data.length === 0 && (
                <Alert variant="warning">
                    <div className="alert-message">No enrolment</div>
                </Alert>
            )}

            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export { Lists };
