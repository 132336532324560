import React, { useState, useEffect, createContext, useCallback } from "react";
import { Container, Tab, Nav } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Expenses } from "../Expenses/Expenses";
import { Requisition } from "../Requisition/Requisition";
import api from "../../../service/api";
import AuthGuard from "../../../components/guards/AuthGuard";
import { Quotation } from "../Quotation/Quotation";
import { DeliveryOrder } from "../DeliveryOrder/DeliveryOrder";
import { Dashboard } from "../Dashboard/Dashboard";
import { Revenue } from "../Revenue/Revenue";
import { ReceivedPayment } from "../ReceivedPayment/ReceivedPayment";

export const ActionRouteContext = createContext();

export const Documents = ({ action = "list", tabPage = "dashboard" }) => {
    //
    // States
    //
    const [project, setProject] = useState([]);

    let { id } = useParams();
    const navigate = useNavigate();

    //
    // Functions
    //

    const getProject = useCallback(async () => {
        const response = await api.get(`projects/${id}`, {});

        setProject(response.data);
    }, [id]);

    //
    // useEffects
    //

    useEffect(() => {
        getProject();
    }, [id, getProject, action]);

    return (
        <>
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">{project.name}</h1>
                <div className="tab">
                    <Tab.Container id="left-tabs-example" activeKey={tabPage}>
                        <Nav variant="tabs">
                            <Nav.Item>
                                <Nav.Link
                                    eventKey="dashboard"
                                    onClick={() =>
                                        navigate(
                                            `/intellidocs/process/dashboard/${id}`
                                        )
                                    }
                                >
                                    Dashboard
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    eventKey="expenses"
                                    onClick={() =>
                                        navigate(
                                            `/intellidocs/process/expenses/${id}`
                                        )
                                    }
                                >
                                    Expenses
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    eventKey="revenues"
                                    onClick={() =>
                                        navigate(
                                            `/intellidocs/process/revenues/${id}`
                                        )
                                    }
                                >
                                    Revenue
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    eventKey="requisitions"
                                    onClick={() =>
                                        navigate(
                                            `/intellidocs/process/requisitions/${id}`
                                        )
                                    }
                                >
                                    Requisition
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    eventKey="quotations"
                                    onClick={() =>
                                        navigate(
                                            `/intellidocs/process/quotations/${id}`
                                        )
                                    }
                                >
                                    Quotation
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            {tabPage === "dashboard" && (
                                <Tab.Pane eventKey="dashboard">
                                    <AuthGuard>
                                        <Dashboard />
                                    </AuthGuard>
                                </Tab.Pane>
                            )}
                            <ActionRouteContext.Provider value={action}>
                                {tabPage === "expenses" && (
                                    <Tab.Pane eventKey="expenses">
                                        <AuthGuard>
                                            <Expenses project={project} />
                                        </AuthGuard>
                                    </Tab.Pane>
                                )}
                            </ActionRouteContext.Provider>
                            <ActionRouteContext.Provider value={action}>
                                {tabPage === "requisitions" && (
                                    <Tab.Pane eventKey="requisitions">
                                        <AuthGuard>
                                            <Requisition project={project} />
                                        </AuthGuard>
                                    </Tab.Pane>
                                )}
                            </ActionRouteContext.Provider>
                            <ActionRouteContext.Provider value={action}>
                                {tabPage === "quotations" && (
                                    <Tab.Pane eventKey="quotations">
                                        <AuthGuard>
                                            {action === "deliveryOrder" ||
                                            action === "addEditDeliveryOrder" ||
                                            action === "viewDeliveryOrder" ? (
                                                <DeliveryOrder
                                                    project={project}
                                                />
                                            ) : (
                                                <Quotation project={project} />
                                            )}
                                        </AuthGuard>
                                    </Tab.Pane>
                                )}
                            </ActionRouteContext.Provider>
                            <ActionRouteContext.Provider value={action}>
                                {tabPage === "revenues" && (
                                    <Tab.Pane eventKey="revenues">
                                        <AuthGuard>
                                            {action === "receivedPayment" ||
                                            action ===
                                                "addEditReceivedPayment" ||
                                            action === "viewReceivedPayment" ? (
                                                <ReceivedPayment
                                                    project={project}
                                                />
                                            ) : (
                                                <Revenue project={project} />
                                            )}
                                        </AuthGuard>
                                    </Tab.Pane>
                                )}
                            </ActionRouteContext.Provider>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </Container>
        </>
    );
};
