import React from "react";

import { Bar } from "react-chartjs-2";

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "top",
        },
        title: {
            display: true,
            text: "Chart.js Bar Chart",
        },
    },
};

export default function InvoiceChart({ summaryData }) {
    // const labels = Object.keys(summaryData);
    const labels = ['Payments'];
    let formatArray = [];
    const getRandomColor = () => {
        var letters = "0123456789ABCDEF";
        var color = "#";
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    Object.keys(summaryData["ATTC"])?.forEach((item) => {
        let tempDataSet = {
            label: item,
            data: [summaryData["ATTC"][item]],
            backgroundColor: getRandomColor(),
        };
        formatArray.push(tempDataSet);
    });

    const data = {
        labels,
        datasets: formatArray,
    };
    return <Bar options={options} data={data} />;
}
