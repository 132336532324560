import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Container, Alert, Row, Col, Button } from "react-bootstrap";
import { SnackbarAlert } from "../../../../components/SnackbarAlert.js";
import { useLocation, useNavigate } from "react-router-dom";
import { Plus } from "react-feather";
import api from "../../../../service/api.js";
import { Lists } from "./List.js";
import { tableColumns } from "./TableColumns.js";
import { useSelector } from "react-redux";
import Search from "./Search.js";
import download from "downloadjs";

const Roadshow = ({ type, title }) => {
    const navigate = useNavigate();
    const [roadshows, setRoadshows] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });
    const location = useLocation();
    const state = location.state;
    const { data } = useSelector((state) => (state.user ? state.user : state));
    const permission = data?.permissions;

    const [searchValue, setSearchValue] = useState({});

    const search = (page) => {
        const searchParam = { ...searchValue, ...page };
        // const searchParam = { ...page };
        getRoadshowData(searchParam);
    };

    const [configValues, setConfigValues] = useState({
        tradeOptions: [],
        location: [],
        courses: [],
    });
    
    const getConfig = async () => {
        const configResponse = await api.get(
            `tms/roadshow/config`,
            {}
        );
        // const courseResponse = await api.get(`tms/courses?all=1`, {});
        const courseResponse = await api.get(`tms/roadshow/get/selections`, {});
        const coursesList = courseResponse?.data?.courses.map((data, index) => {
            return { value: data.id, label: data.name };
        });
        const { courses } = { ...configValues };
        courses.push(...coursesList);
        setConfigValues({ courses });
        setConfigValues({ ...configValues, ...configResponse.data });
    };

    const generateRoadshowExcel = async () => {
        try {
            setLoading(true);
            const excelFilename = `roadshow-export-${new Date()
                .toISOString()
                .slice(0, 10)}`;

            const response = await api.getexcel(
                "tms/roadshow/excel-export",
                searchValue
            );

            if (response.size !== 0) {
                download(response, excelFilename, "application/pdf");
            } else {
                setError("No data to be exported");
            }

        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (state?.open) {
            setNotif({
                notifMsg: state.notifMsg,
                open: state.open,
                severity: state.severity,
            });
        }
        window.history.replaceState(null, "");
    }, [state]); //  eslint-disable-line react-hooks/exhaustive-deps

    const getRoadshowData = useCallback(async (search) => {
        try {
            setLoading(true);
            const response = await api.get("tms/roadshow", search);
            setRoadshows(response);
            setError();
        } catch (error) {
            setLoading(false);
            setError(error.message);
            setRoadshows([]);
        }
        setLoading(false);
    })

    useEffect(() => {
        /**
         * set page number
         */
        let pageNo = "";
        if (state?.page) {
            pageNo = state?.page;
        }
        getConfig();
        getRoadshowData({ page: pageNo });
    }, []);

    let helmentTitle = "Roadshow > " + title;

    return (
        <React.Fragment>
            <Helmet title={helmentTitle} />
            {!permission.includes('ROADSHOW-READ') && (
                <Alert className="my-3" variant="warning">
                    <div className="alert-message">
                        You don't have permission to access this page
                    </div>
                </Alert>
            )}

            {permission.includes('ROADSHOW-READ') && (
                <Container fluid className="p-0">
                    <h1 className="h3 mb-3">{title}</h1>
                    {error && (
                        <Card.Header>
                            <Alert className="my-3" variant="danger">
                                <div className="alert-message">{error}</div>
                            </Alert>
                        </Card.Header>
                    )}
                    <Card>
                        <Card.Body className="mt-0">
                            <Row>
                                <Search
                                    setSearchValue={setSearchValue}
                                    searchValue={searchValue}
                                    search={search}
                                    type={type}
                                    configValues={configValues}
                                    generateRoadshowExcel={generateRoadshowExcel}
                                />
                            </Row>
                            
                            {permission?.includes("ROADSHOW-CREATE") && (
                                <Row className="mb-2 mx-2 d-flex justify-content-end">   
                                    <Col className="col-auto d-flex justify-content-end p-0">
                                        <Button
                                            className="float-end mt-1 mx-1"
                                            onClick={() =>
                                                navigate("/tms/roadshow/add")
                                            }
                                            variant="success"
                                            type="button"
                                            size="lg"
                                        ><Plus size={15} /> Record</Button>
                                    </Col>
                                </Row>
                            )}
                            {loading && <div>Loading...</div>}
                            {!loading && roadshows?.data && (
                                <Lists
                                    columns={tableColumns}
                                    rawData={roadshows}
                                    searchApi={search}
                                    permission={permission}
                                    // type={type}
                                />
                            )}
                        </Card.Body>
                    </Card>
                </Container>
            )}
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </React.Fragment>
    );
};

export default Roadshow;
