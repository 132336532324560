import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import { UserDateFormat } from "../../../utils/dateFormat";
import { addDefaultOption } from "../../../utils/utility";

const AddEditPaymentDetails = ({
    handleBlur,
    handleChange,
    values,
    configValues,
    setFieldValue,
    editEnrolment,
    errors,
}) => {
    const DateFormat = UserDateFormat();
    const isCheque = Boolean(
        values.payment_mode && values.payment_mode?.toUpperCase() === "CHEQUE"
    );
    return (
        <>
            <div className="card-title h5 mt-3 mb-3" tag="h5">
                Payment details
            </div>
            <Row>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Course Fee </Form.Label>
                        <Form.Control
                            type="text"
                            name="course_fee"
                            value={values.course_fee}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Additional fee</Form.Label>
                        <Form.Control
                            type="text"
                            name="additional_fee"
                            value={values.additional_fee}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Discount fee</Form.Label>
                        <Form.Control
                            type="text"
                            name="discount_fee"
                            value={values.discount_fee}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Additional training </Form.Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={addDefaultOption(
                                configValues?.additionalTraining
                            )}
                            name="additional_training"
                            //onBlur={() => setFieldTouched("additional_training", true)}
                            onChange={(value) =>
                                setFieldValue(
                                    "additional_training",
                                    value.value
                                )
                            }
                            isSearchable="true"
                            value={
                                editEnrolment?.additional_training && [
                                    {
                                        value: editEnrolment?.additional_training,
                                        label: editEnrolment?.additional_training,
                                    },
                                ]
                            }
                        />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Invoice number</Form.Label>
                        <Form.Control
                            type="text"
                            name="invoice_no"
                            value={values.invoice_no}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={Boolean(errors.invoice_no)}
                        />
                        {errors.invoice_no && (
                            <Form.Control.Feedback type="invalid">
                                Invoice number is a required field
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label> Payment mode </Form.Label>

                        <Select
                            className="is-invalid react-select-container"
                            classNamePrefix="react-select "
                            options={addDefaultOption(
                                configValues?.paymentMode
                            )}
                            name="payment_mode"
                            onChange={(value) => {
                                setFieldValue("payment_mode", value.value);
                            }}
                            isSearchable="true"
                            defaultValue={
                                editEnrolment?.payment_mode && [
                                    {
                                        label: editEnrolment?.payment_mode,
                                        value: editEnrolment?.payment_mode,
                                    },
                                ]
                            }
                        />
                        {!!errors.payment_mode && (
                            <Form.Control.Feedback type="invalid">
                                {errors.payment_mode}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
                <>
                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                {isCheque ? "Cheque" : "Reference"} number
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="reference_no"
                                placeholder={`${
                                    isCheque ? "Cheque" : "Reference"
                                } number`}
                                value={values?.reference_no}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                {isCheque ? "Cheque" : "Reference"} date
                            </Form.Label>

                            <DatePicker
                                name="reference_date"
                                selected={
                                    values?.reference_date !== "" &&
                                    values?.reference_date !== null &&
                                    new Date(
                                        moment(
                                            values?.reference_date,
                                            DateFormat?.toUpperCase()
                                        ).format("YYYY-MM-DD")
                                    )
                                }
                                className={`form-control`}
                                onChange={(dates) => {
                                    setFieldValue("reference_date", dates);
                                }}
                                dateFormat={DateFormat}
                                placeholderText={DateFormat?.toUpperCase()}
                            />
                        </Form.Group>
                    </Col>
                </>
            </Row>
        </>
    );
};
export default AddEditPaymentDetails;
