import React from "react";
import { Card } from "react-bootstrap";

const InvoiceData = ({ creditNotes }) => {
    return (
        <>
            <Card>
                <Card.Body>
                    <Card.Title tag="h5">Invoice</Card.Title>
                    <hr />
                    <ul className="list-unstyled mb-0">
                        <li className="mb-1">
                            <b>Credit note number: </b>{" "}
                            {creditNotes?.number}
                        </li>

                        <li className="mb-1">
                            <b>Invoice number: </b>{" "}
                            {creditNotes?.invoice?.number}
                        </li>

                        <li className="mb-1">
                            <b>Total amount :</b> $
                            {creditNotes?.invoice?.total_amount}
                        </li>
                        <li className="mb-1">
                            <b>Total paid :</b> $
                            {creditNotes?.total_paid_amount}
                        </li>
                    </ul>
                    <br />
                    <Card.Title tag="h5">Customer</Card.Title>
                    <hr />
                    <ul className="list-unstyled mb-0">
                        <li className="mb-1">
                            <b>Name: </b> {creditNotes?.invoice?.company?.name}
                        </li>

                        <li className="mb-1">
                            <b>Customer code: </b>
                            {creditNotes?.invoice?.company?.customer_code}
                        </li>

                        <li className="mb-1">
                            <b>UEN: </b>
                            {creditNotes?.invoice?.company?.uen}
                        </li>
                    </ul>

                    <br />
                    <Card.Title tag="h5">Sales person</Card.Title>
                    <hr />
                    <ul className="list-unstyled mb-0">
                        <li className="mb-1">
                            {creditNotes?.invoice?.sales_user?.name}
                        </li>
                    </ul>
                    <br />
                    <Card.Title tag="h5">CN Requested by</Card.Title>
                    <hr />
                    <ul className="list-unstyled mb-0">
                        <li className="mb-1">{creditNotes?.user?.name}</li>
                    </ul>
                </Card.Body>
            </Card>
            <hr className="my-0" />
        </>
    );
};
export default InvoiceData;
