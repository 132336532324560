import React from "react";
import { Form } from "react-bootstrap";

const MIN_TEXTAREA_HEIGHT = 32;

export const AutoGrowTextarea = ({
    name,
    id,
    fieldValue,
    handleBlur,
    isInvalid,
    index = null,
    handleFormChangeItems = null,
    handleFormChangeDetails = null,
    setFieldValue = null,
    readOnly = false,
    width, // New width prop
}) => {
    const textareaRef = React.useRef(null);
    const [value, setValue] = React.useState("");

    const onChangeTextArea = (event) => setValue(event.target.value);

    React.useLayoutEffect(() => {
        textareaRef.current.style.height = "inherit";
        textareaRef.current.style.height = `${Math.max(
            textareaRef.current.scrollHeight,
            MIN_TEXTAREA_HEIGHT
        )}px`;
    }, [value]);

    return (
        <Form.Control
            as="textarea"
            name={name}
            id={id}
            ref={textareaRef}
            style={{
                minHeight: MIN_TEXTAREA_HEIGHT,
                resize: "none",
                width: width || "100%", // Use the width prop or default to 100%
            }}
            value={fieldValue}
            isInvalid={isInvalid}
            onBlur={handleBlur}
            onChange={(event) => {
                handleFormChangeItems && handleFormChangeItems(index, event);
                handleFormChangeDetails && handleFormChangeDetails(event);
                setFieldValue && setFieldValue();
                onChangeTextArea(event);
            }}
            readOnly={readOnly}
        />
    );
};
