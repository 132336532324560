import React, { useEffect, useState, useContext, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";
import { tableColumns } from "./tableDataExpense.js";
import { AddEditExpense } from "./AddEditExpense.js";
import { ViewExpense } from "./ViewExpense.js";
import { ActionRouteContext } from "../Documents/Documents";
import api from "../../../service/api";
import { ExpenseListing } from "./ExpenseListing.js";
import { LoadingContext } from "../../../App.js";
import { useParams } from "react-router-dom";

export const Expenses = ({ project }) => {
    let { id } = useParams();
	
    //
    // States
    //

    const [expenses, setExpenses] = useState([]);
    const action = useContext(ActionRouteContext);
    const setIsLoadingActive = useContext(LoadingContext);

    //
    // Functions
    //

    const getExpenses = useCallback(
        async (param = null) => {
            const response = await api.get("expenses", {
                project_id: id,
                ...param,
            });

            if (response) {
                setExpenses(response);
                setIsLoadingActive(false);
            }
        },
        [setIsLoadingActive, id]
    );

    //
    // useEffects
    //

    useEffect(() => {
        setIsLoadingActive(true);
        getExpenses();
    }, [setIsLoadingActive, getExpenses]);

    return (
        <React.Fragment>
            {project && (
                <Helmet title={`${project.name} > Expenses`} />
            )}
            <Container fluid className="p-0">
                {
                    // Expense View List
                    action === "list" && expenses && expenses.data ? (
                        <ExpenseListing
                            module="expenses"
                            columns={tableColumns}
                            rawData={expenses}
                            parentApi={getExpenses}
                        />
                    ) : // Create Edit Expense
                    action === "addEdit" ? (
                        <AddEditExpense project={project} />
                    ) : (
                        // View Expense
                        <ViewExpense />
                    )
                }
            </Container>
        </React.Fragment>
    );
};
