import { Badge } from "react-bootstrap";
import { formatNumberWithCommas } from "../../../../utils/utility";

const tableColumns = [
    {
        Header: "S.no",
        width: "70",
        sticky: "left",
    },
    {
        Header: "Salesperson",
        accessor: (data) => {
            return <div>{data?.name || "-"}</div>;
        },
        // accessor: "name",
        width: "500",
        sticky: "left",
    },
    // {
    //     Header: "Sub total",
    //     accessor: (data) => {
    //         return <div>{ data?.subtotal ? formatNumberWithCommas(data?.subtotal) : 0}</div>;
    //     },
    //     // accessor: "total",
    //     width: "500",
    //     sticky: "left",
    // },
    {
        Header: "Invoice Amount",
        accessor: (data) => {
            return <div>{ data?.inv_total ? formatNumberWithCommas(data?.inv_total) : 0}</div>;
        },
        // accessor: "total",
        width: "500",
        sticky: "left",
    },
    {
        Header: "CN Request Amount",
        accessor: (data) => {
            return <div>{ data?.creditNotes ? formatNumberWithCommas(data?.creditNotes) : 0}</div>;
        },
        // accessor: "total",
        width: "500",
        sticky: "left",
    },
    {
        Header: "Paid amount",
        accessor: (data) => {
            return <div>{ data?.payment ? formatNumberWithCommas(data?.payment) : 0}</div>;
        },
        // accessor: "total",
        width: "500",
        sticky: "left",
    },
    
];

export { tableColumns };
