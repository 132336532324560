const TableColumns = [
    {
        Header: "Name",
        // accessor: "student.name",
        accessor: (data) => {
            return (
                <div>
                    {data?.student_name ?? data?.student?.name}
                </div>
            );
        },
    },
    {
        Header: "FIN",
        // accessor: "student.fin",
        accessor: (data) => {
            return (
                <div>
                    {data?.fin ?? data?.student?.fin}
                </div>
            );
        },
    },
    {
        Header: "Individual price",
        accessor: "individual_student_price",
    },
    {
        Header: "Remaining price",
        accessor: "remaining_price",
    },
    {
        Header: "Enrolment status",
        id: "enrolment_status",
        accessor: (data) => {
            return (
                <div>
                    {!data?.enrolment && (
                        data?.status?.toLowerCase() !== 'cancelled' ? 'Pending for enrolment' : 'CANCELLED'
                    )}
                    {data?.enrolment && (
                        <div>{data?.enrolment?.status_key}</div>
                    )}
                </div>
            );
        },
        width: "300",
    },
];

export { TableColumns };
