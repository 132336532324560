import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { faWindowClose, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AutoGrowTextarea } from "../../../components/AutoGrowTextarea";

export const AddEditRequisitionItems = ({
    values,
    errors,
    touched,
    handleBlur,
    handleFormChangeItems,
    addFields,
    removeFields,
}) => {
    return (
        <>
            <Row className="mb-2">
                <Col md={1}>S/N</Col>
                <Col md={2}>Item</Col>
                <Col md={4}>Description</Col>
                <Col md={2}>Quantity</Col>
                <Col md={2}>Unit</Col>
            </Row>
            <hr style={{ border: "1px solid" }} />
            {values.requisition_items.length ? (
                values.requisition_items.map((input, index) => {
                    return (
                        <Row key={index}>
                            <Col md={1}>{index + 1}</Col>
                            <Col md={2}>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        name="item"
                                        id="item"
                                        value={
                                            values.requisition_items[index]
                                                ?.item
                                        }
                                        isInvalid={Boolean(
                                            errors.requisition_items &&
                                                touched.requisition_items &&
                                                errors.requisition_items[index]
                                                    ?.item &&
                                                touched.requisition_items[index]
                                                    ?.item
                                        )}
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            handleFormChangeItems(index, event);
                                        }}
                                    />
                                    {errors.requisition_items &&
                                        errors.requisition_items[index]
                                            ?.item && (
                                            <Form.Control.Feedback type="invalid">
                                                Item is a required field
                                            </Form.Control.Feedback>
                                        )}
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <AutoGrowTextarea
                                        name="description"
                                        id="description"
                                        fieldValue={
                                            values.requisition_items[index]
                                                ?.description
                                        }
                                        handleBlur={handleBlur}
                                        index={index}
                                        handleFormChangeItems={
                                            handleFormChangeItems
                                        }
                                        isInvalid={Boolean(
                                            errors.requisition_items &&
                                                touched.requisition_items &&
                                                errors.requisition_items[index]
                                                    ?.description &&
                                                touched.requisition_items[index]
                                                    ?.description
                                        )}
                                    />
                                    {errors.requisition_items &&
                                        errors.requisition_items[index]
                                            ?.description && (
                                            <Form.Control.Feedback type="invalid">
                                                Description is a required field
                                            </Form.Control.Feedback>
                                        )}
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="number"
                                        name="quantity"
                                        id="quantity"
                                        min="0"
                                        value={
                                            values.requisition_items[index]
                                                ?.quantity
                                        }
                                        isInvalid={Boolean(
                                            errors.requisition_items &&
                                                touched.requisition_items &&
                                                errors.requisition_items[index]
                                                    ?.quantity &&
                                                touched.requisition_items[index]
                                                    ?.quantity
                                        )}
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            handleFormChangeItems(index, event);
                                        }}
                                    />
                                    {errors.requisition_items &&
                                        errors.requisition_items[index]
                                            ?.quantity && (
                                            <Form.Control.Feedback type="invalid">
                                                Quantity is a required field
                                            </Form.Control.Feedback>
                                        )}
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        name="unit"
                                        id="unit"
                                        value={
                                            values.requisition_items[index]
                                                ?.unit
                                        }
                                        isInvalid={Boolean(
                                            errors.requisition_items &&
                                                touched.requisition_items &&
                                                errors.requisition_items[index]
                                                    ?.unit &&
                                                touched.requisition_items[index]
                                                    ?.unit
                                        )}
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            handleFormChangeItems(index, event);
                                        }}
                                    />
                                    {errors.requisition_items &&
                                        errors.requisition_items[index]
                                            ?.unit && (
                                            <Form.Control.Feedback type="invalid">
                                                Unit is a required field
                                            </Form.Control.Feedback>
                                        )}
                                </Form.Group>
                            </Col>
                            <Col md={1}>
                                <Button
                                    variant="primary"
                                    onClick={() => removeFields(index)}
                                    className=""
                                >
                                    <FontAwesomeIcon icon={faWindowClose} />
                                </Button>
                            </Col>
                        </Row>
                    );
                })
            ) : (
                <Row index="1">
                    <Col md={12} className="text-center">
                        Add a new item to create an requisition
                    </Col>
                </Row>
            )}
            <Button variant="secondary" onClick={addFields} className="mt-3">
                <FontAwesomeIcon icon={faPlusSquare} /> Add new item
            </Button>
        </>
    );
};
