import React, { useState } from "react";

import { useTable, usePagination, useSortBy } from "react-table";
import { useParams } from "react-router-dom";
import {
    Table,
    Alert,
} from "react-bootstrap";
import { DatePicker } from "antd";
import { useSelector } from "react-redux";
import { SnackbarAlert } from "../../../../components/SnackbarAlert";
import { formatNumberWithCommasNoDecimal } from "../../../../utils/utility";

const Lists = ({
    rawData,
    totalValues,
    overAllTotal
}) => {
    /** Permissions */
    const permissionData = useSelector((state) => {
        return state.user ? state.user : state;
    });
    const permission = permissionData?.data?.permissions;
    const params = useParams();

    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const [loading, setLoading] = useState(true);
    return (
        <>
            {Object.keys(rawData).length > 0 && (
                <Table className="table table-responsive">
                    <thead>
                        <tr>
                            <th width="5%">Month/Year</th>
                            <th className="text-end">CET</th>
                            <th className="text-end">Others</th>
                            <th className="text-end">Total</th>
                            <th className="text-nowrap text-end">Re Test</th>
                            <th className="text-end">New</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(rawData).map((key, index) => (
                            <React.Fragment key={key}>
                                <tr key={index}>
                                    <th colSpan={6} className="table-secondary">{key}</th>
                                </tr>
                                { rawData[key].map((data) => (
                                    <tr>
                                        <td>{data.month}</td>
                                        <td className="text-end">{formatNumberWithCommasNoDecimal(data.cet) ?? ''}</td>
                                        <td className="text-end">{formatNumberWithCommasNoDecimal(data.others) ?? ''}</td>
                                        <td className="text-end">{formatNumberWithCommasNoDecimal(data.total) ?? ''}</td>
                                        <td className="text-end">{formatNumberWithCommasNoDecimal(data.retest) ?? ''}</td>
                                        <td className="text-end">{formatNumberWithCommasNoDecimal(data.new) ?? ''}</td>
                                    </tr>
                                 ))}
                            </React.Fragment>
                        ))}
                        <tr>
                            <th>Subtotals</th>
                            <td className="text-end">{formatNumberWithCommasNoDecimal(totalValues.cet_subtotal)}</td>
                            <td className="text-end">{formatNumberWithCommasNoDecimal(totalValues.others_subtotal)}</td>
                            <td className="text-end">{formatNumberWithCommasNoDecimal(totalValues.total_month)}</td>
                            <td className="text-end">{formatNumberWithCommasNoDecimal(totalValues.retest_subtotal)}</td>
                            <td className="text-end">{formatNumberWithCommasNoDecimal(totalValues.new_subtotal)}</td>
                        </tr>
                        <tr>
                            <th className="text-nowrap">Overall Total:</th>
                            <td className="text-end" colSpan={5}>{formatNumberWithCommasNoDecimal(overAllTotal)}</td>
                        </tr>
                    </tbody>
                </Table>
            )}
            {Object.keys(rawData).length === 0 && (
                <Alert variant="warning">
                    <div className="alert-message">No data found.</div>
                </Alert>
            )}
            <SnackbarAlert notif={notif} setNotif={setNotif} />
        </>
    );
};

export { Lists };
