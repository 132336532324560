const tableColumns = [
    {
        Header: "S/N",
        accessor: "serial",
    },
    {
        Header: "Order code",
        accessor: "code",
    },
    {
        Header: "To",
        accessor: "order_to",
    },
    {
        Header: "Order date",
        accessor: "order_date",
    },
    {
        Header: "Total delivered (S$)",
        accessor: "total",
    },
    {
        Header: "Actions",
        accessor: "action",
    },
];

export { tableColumns };
