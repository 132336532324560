import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import api from "../../../service/api";
import { SnackbarAlert } from "../../../components/SnackbarAlert.js";

export const AddEditPortfolio = () => {
  let { id } = useParams();

  return (
    <React.Fragment>
      <Helmet title="Portfolio" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{id === "add" ? "Add" : "Edit"} Portfolio</h1>

        <Row>
          <Col lg="12">
            <FormRow />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const FormRow = () => {
  const [editPortfolio, setEditPortfolio] = useState();
  const [initValues, setInitialValues] = useState({
    name: "",
    person_in_charge: "",
    address: "",
    submit: false,
  });
  const [notif, setNotif] = useState({
    notifMsg: "",
    open: false,
    severity: "success",
  });

  let { id } = useParams();
  const navigate = useNavigate();

  const schema = Yup.object().shape({
    name: Yup.string().required(),
    person_in_charge: Yup.string().required(),
    address: Yup.string().required(),
  });

  //
  // Functions
  //

  const submitAddPortfolio = (values) => {
    return api.post("portfolios", {
      name: values.name,
      person_in_charge: values.person_in_charge,
      address: values.address,
    });
  };

  const submitEditPortfolio = (values) => {
    try {
      return api.put(`portfolios/${id}`, {
        name: values.name,
        person_in_charge: values.person_in_charge,
        address: values.address,
      });
    } catch {
      setNotif({
        notifMsg: "Something went wrong with the server",
        open: true,
        severity: "danger",
      });
    }
  };

  // 
  // useEffects
  //

  useEffect(() => {
    if (id !== "add") {
      const getPortfolio = async () => {
        try {
          const response = await api.get(`portfolios/${id}`, {});

          setEditPortfolio(response.data);
        } catch (error) {
          setNotif({
            notifMsg: "Something went wrong with the server",
            open: true,
            severity: "danger",
          });
        }
      };
      getPortfolio();
    }
  }, [id]);

  useEffect(() => {
    if (editPortfolio) {
      setInitialValues({
        name: editPortfolio.name,
        person_in_charge: editPortfolio.person_in_charge,
        address: editPortfolio.address,
        submit: false,
      });
    }
  }, [editPortfolio]);

  return (
    <>
      <Card>
        <Card.Body>
          <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={schema}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                id !== "add"
                  ? await submitEditPortfolio(values)
                  : await submitAddPortfolio(values);

                navigate("/intellidocs/portfolio", {
                  state: {
                    open: true,
                    notifMsg: `Successfully ${
                      id === "add" ? "added" : "edited"
                    } permission`,
                    severity: "success",
                  },
                });
              } catch (error) {
                const message = error.message || "Something went wrong";

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
                setNotif({
                  notifMsg: "Something went wrong with the server",
                  open: true,
                  severity: "danger",
                });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert className="my-3" variant="danger">
                    <div className="alert-message">{errors.submit}</div>
                  </Alert>
                )}
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={values.name}
                        isInvalid={Boolean(touched.name && errors.name)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.name && (
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Person incharge</Form.Label>
                      <Form.Control
                        type="text"
                        name="person_in_charge"
                        placeholder="Person incharge"
                        value={values.person_in_charge}
                        isInvalid={Boolean(
                          touched.person_in_charge && errors.person_in_charge
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.person_in_charge && (
                        <Form.Control.Feedback type="invalid">
                          Person incharge
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    placeholder="Address"
                    value={values.address}
                    isInvalid={Boolean(touched.address && errors.address)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.address && (
                    <Form.Control.Feedback type="invalid">
                      {errors.address}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Button
                  variant="secondary"
                  className="me-2"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
      <SnackbarAlert notif={notif} setNotif={setNotif} />
    </>
  );
};
