const TableColumns = [
    {
        Header: "Name",
        accessor: "enrolment.name",
    },
    {
        Header: "FIN",
        accessor: "enrolment.fin",
    },
    {
        Header: "No. of Session",
        accessor: "",
    },
    {
        Header: "Additional Training",
        accessor: "",
    },
];

export { TableColumns };
