import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import Statistics from "./Statistics";

export default function CommonDashboard() {
    return (
        <React.Fragment>
            <Helmet title="Dashboard" />
            <Container fluid className="p-0">
                <Statistics />
            </Container>
        </React.Fragment>
    );
}
