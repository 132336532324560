import React, { forwardRef, useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { Button, Col, Form, OverlayTrigger, Pagination, Row, Table, Tooltip } from "react-bootstrap";
import { useSticky } from "react-table-sticky";
import {
    useTable,
    usePagination,
    useRowSelect,
    useExpanded,
    useMountedLayoutEffect,
    useGlobalFilter,
    useBlockLayout,
} from "react-table";
import api from "../../../service/api";
import { SnackbarAlert } from "../../../components/SnackbarAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faArrowAltCircleRight, faFileExcel, faWindowClose } from "@fortawesome/free-regular-svg-icons";
import { Eye, Trash } from "react-feather";
import { DeleteModal } from "../../../components/DeleteModal";
import DoubleScrollbar from "react-double-scrollbar";
import { scrollButtonFunction } from "../../../utils/utility";

const EmailListTable = (props) => {
    const {
        columns,
        dataPagination,
        apis,
        className,
        withCheckbox,
        hiddenColumns,
        singleSelectCheckbox,
        setSelectedRows = false,
        isPaginated = true,
        isStriped = true,
        isExpanded = false,
        updateData = null,
        updateSort = null,
        canViewRow = null,
        canSignRow = null,
        defaultSelectedRows,
        getRowId,
        onChangeSelectedRowsId = false,
        toDeleteRow = false,
        getStudents,
    } = props;

    const { data, meta, links } = dataPagination;
    // const [attachment, setAttachment] = useState(true);
    const [loading, setLoading] = useState(false);
    const [notif, setNotif] = useState({
        notifMsg: "",
        open: false,
        severity: "success",
    });

    const fileChange = async (event, student_id) => {
        try {
            setLoading(true);
            const attachment = event.target.files[0]
            if (attachment.type !== "application/pdf") {
                alert('Please upload only PDF file');return
            }
            const data = new FormData();
                data.append('file', attachment);
                data.append('fileName', attachment.name);
                data.append('student_id', student_id);
                const response = await api.postUpload(
                    `tms/enrolments/results-attachment`,
                    data
                );
                // const obj = JSON.parse(response)
                if (response.success) {
                    // setNotif({
                    //     notifMsg: response.message,
                    //     open: true,
                    //     severity: "success",
                    // });
                    getStudents();
                    // window.location.reload();
                    // setTimeout(function(){
                    //  }, 5000);
                }else{
                    setNotif({
                        notifMsg: response.message,
                        open: true,
                        severity: "danger",
                    });
                    setLoading(false);
                }
                // if (window.confirm('Are you sure you want to upload this file?')) {
                //     // Save it!
                //     const response = await api.postUpload(
                //         `tms/enrolments/results-attachment`,
                //         data
                //     );
                //     // const obj = JSON.parse(response)
                //     if (response.success) {
                //         setLoading(!loading);
                //         setNotif({
                //             notifMsg: response.message,
                //             open: true,
                //             severity: "success",
                //         });
                //         window.location.reload();
                //         // setTimeout(function(){
                //         //  }, 5000);
                //     }else{
                //         setNotif({
                //             notifMsg: response.message,
                //             open: true,
                //             severity: "danger",
                //         });
                //     }
                // } else {
                // // Do nothing!
                // console.log('Thing was not saved to the database.');
                // window.location.reload();
                // }  

            // setLoading(false);
        } catch (error) {}
    };


    const IndeterminateCheckbox = forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = useRef();
            const resolvedRef = ref || defaultRef;

            useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            return (
                <>
                    <Form.Check type="checkbox" ref={resolvedRef} {...rest} />
                </>
            );
        }
    );

    useEffect(() => {
        setLoading(false);
    }, [dataPagination])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        toggleAllRowsExpanded,
        isAllRowsExpanded,
        selectedFlatRows,
        state: {
            pageIndex,
            apiNextPage,
            apiPreviousPage,
            totalPage,
            currentPage,
            firstPage,
            lastPage,
            selectedRowIds,
        },
        toggleRowSelected,
        toggleAllRowsSelected,
    } = useTable(
        {
            columns: columns,
            data: data,
            getRowId,
            initialState: {
                pageIndex: 0,
                hiddenColumns: hiddenColumns ? hiddenColumns : [],
                autoResetExpanded: false,
                apiNextPage: links?.next !== null ? true : false,
                apiPreviousPage: links?.prev !== null ? true : false,
                pageSize: meta?.total ? meta?.total : 200,
                totalPage: meta?.last_page,
                currentPage: meta?.current_page,
                firstPage: meta?.first,
                lastPage: meta?.last_page,
                selectedRowIds: defaultSelectedRows || {},
            },
            stateReducer: singleSelectCheckbox
                ? (newState, action) => {
                      if (action.type === "toggleRowSelected") {
                          newState.selectedRowIds = {
                              [action.id]: true,
                          };
                      }

                      return newState;
                  }
                : () => {},
            updateData: updateData,
            updateSort: updateSort,
            canViewRow: canViewRow,
            canSignRow: canSignRow,
        },
        useGlobalFilter,
        useExpanded,
        usePagination,
        useRowSelect,
        useBlockLayout,
        useSticky,
        (hooks) => {
            if (withCheckbox && data && data.length > 0) {
                hooks.visibleColumns.push((columns) => [
                    // Let's make a column for selection
                    {
                        id: "selection",
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllPageRowsSelectedProps }) =>
                            !singleSelectCheckbox && (
                                <div>
                                    <IndeterminateCheckbox
                                        {...getToggleAllPageRowsSelectedProps()}
                                    />
                                </div>
                            ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }) => {
                            return (
                                row.depth === 0 && (
                                    <div>
                                        <IndeterminateCheckbox
                                            {...row.getToggleRowSelectedProps()}
                                        />
                                    </div>
                                )
                            );
                        },
                        width: 40,
                        sticky: "left",
                    },
                    ...columns,
                ]);
            }
        }
    );

    const gotoPageApi = (page) => {
        apis({
            page,
        });
    };

    const deselectSelectedRow = (id) => {
        const rowToDelete = page.find((data) => {
            return data.original.id === id;
        });

        rowToDelete && toggleRowSelected(rowToDelete.id, false);
    };

    const deselectAllRows = () => {
        toggleAllRowsSelected(false);
    };

    useEffect(() => {
        if (isExpanded) toggleAllRowsExpanded(true);
    }, [isAllRowsExpanded, isExpanded, toggleAllRowsExpanded]);

    useEffect(() => {
        if (setSelectedRows) {
            const mappedSelectedFlatRows = selectedFlatRows.map(
                (row) => row.original
            );

            setSelectedRows(mappedSelectedFlatRows);
        }
    }, [setSelectedRows, selectedFlatRows]);

    useEffect(() => {
        if (toDeleteRow === "ALL") {
            deselectAllRows();
            return;
        }

        if (toDeleteRow.length) {
            deselectSelectedRow(toDeleteRow);
        }
    }, [toDeleteRow]);

    useMountedLayoutEffect(() => {
        onChangeSelectedRowsId && onChangeSelectedRowsId(selectedRowIds);
    }, [selectedRowIds]);

    const Styles = styled.div`
        .table {
            .th,
            .td {
                background-color: #fff;
                overflow: hidden;
                border-top: none;
            }

            &.sticky {
                overflow: scroll;
                .header,
                .footer {
                    position: sticky;
                    z-index: 1;
                    width: fit-content;
                }
            }
        }
        .table > :not(:first-child) {
            border-top: none;
        }
    `;
    const [modalInfo, setModalInfo] = useState({
        id: null,
        notifMsg: "",
        open: false,
        severity: "danger",
    });
    const openDeleteModal = (id) => {
        setModalInfo({
            id: id,
            notifMsg: "Are you sure you want to delete this item?",
            open: true,
            severity: "danger",
        });
    };
    
    const deleteAttachment = async (id) => {
        try {
            setLoading(true);
            await api.post(`tms/renewals/${id}`, {});
            // setNotif({
            //     id: id,
            //     notifMsg: "Deleted successfully",
            //     open: true,
            //     severity: "success",
            // });
            getStudents();
            // window.location.reload();
        } catch (error) {
            setNotif({
                id: id,
                notifMsg: error.message,
                open: true,
                severity: "danger",
            });
        }
    };
    return (
        <>
        <Row className="fixed-header">
            <Col>
                <div className="scroll-buttons pb-2 px-0">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-top`}>Seek Left</Tooltip>}
                    >
                        <Button
                            onClick={scrollButtonFunction('.table-target-for-scroll', false, "auto") }
                            variant="secondary"
                            size="md"
                        >
                            <FontAwesomeIcon
                                icon={faArrowAltCircleLeft}
                            />
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-top`}>Seek Right</Tooltip>}
                    >
                        <Button
                            variant="secondary"
                            size="md"
                            onClick={scrollButtonFunction('.table-target-for-scroll', true, "auto") }
                            className="mx-3 info"
                        >
                            <FontAwesomeIcon
                                icon={faArrowAltCircleRight}
                            />
                        </Button>
                    </OverlayTrigger>
                </div>
            </Col>
            {isPaginated && (
                <Col>
                    <Col className="text-end align-middle">
                        <span className="mx-2">
                            Page{" "}
                            <strong>
                                {apis && `${currentPage} of ${totalPage}`}
                                {!apis &&
                                    `${pageIndex + 1} of  ${
                                        pageOptions.length
                                    }`}
                            </strong>
                        </span>
                        <Pagination className="float-end">
                            {!apis && (
                                <>
                                    {" "}
                                    <Pagination.First
                                        onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={() => previousPage()}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() => nextPage()}
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}
                                    />
                                </>
                            )}

                            {apis && (
                                <>
                                    <Pagination.First
                                        onClick={() => gotoPageApi(firstPage)}
                                        disabled={!apiPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPageApi(currentPage - 1)
                                        }
                                        disabled={!apiPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPageApi(currentPage + 1)
                                        }
                                        disabled={!apiNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPageApi(lastPage)}
                                        disabled={!apiNextPage}
                                    />
                                </>
                                )}
                            </Pagination>
                        </Col>
                    </Col>
                )}
        </Row>  

            <Styles>
                <div className="table-responsive">
                <DoubleScrollbar>
                    <br></br>
                    <Table
                        striped={isStriped}
                        bordered
                        {...getTableProps()}
                        className={`table table-to-scroll table-layout-fixed table-target-for-scroll ${className}`}
                    >
                        <thead className="header">
                            {headerGroups.map((headerGroup) => (
                                <tr
                                    {...headerGroup.getHeaderGroupProps()}
                                    className="tr"
                                >
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            width={column.width}
                                            {...column.getHeaderProps()}
                                            className="th"
                                        >
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {data && data.length < 1 && (
                                <tr>
                                    <td colSpan={columns.length}>
                                        No records found
                                    </td>
                                </tr>
                            )}
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <React.Fragment key={i}>
                                        {row.original.header ? (
                                        
                                            <tr className="tr">
                                                <td
                                                    colSpan={columns.length}
                                                    className="header td"
                                                >
                                                    {row.original.header}
                                                </td>
                                            </tr>
                                        ) : (

                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell, index) => {
                                                    const checkbox = withCheckbox
                                                        ? 1
                                                        : 0;

                                                    if (index === checkbox) {
                                                        return (
                                                            <td
                                                                {...cell.getCellProps()}
                                                                key={index}
                                                                className="td"
                                                            >
                                                                {parseInt(
                                                                    cell.row.id
                                                                ) + 1}
                                                            </td>
                                                        );
                                                    }
                                                    if (cell.column.id === "attachment" &&
                                                    cell?.row?.original?.enrolment?.status_key === 'COMPLETED')  {
                                                        return (
                                                            <td
                                                                {...cell.getCellProps()}
                                                                key={index}
                                                                className="td"
                                                            >
                                                            {!loading && (
                                                                <>
                                                                {
                                                                    cell?.row?.original?.enrolment?.result_attachment ? (
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip>View</Tooltip>}
                                                                        >
                                                                            <a
                                                                                href={cell?.row?.original?.enrolment?.result_attachment}
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                                className="me-1"
                                                                            >
                                                                                <span>
                                                                                    <Eye size="20" />
                                                                                </span>
                                                                            </a>
                                                                        </OverlayTrigger>
                                                            
                                                                    ) : (
                                                                            <input type="file" onChange={(e) => {e.preventDefault(); fileChange(e, cell?.row?.original?.enrolment?.id)}}/>
                                                                    )
                                                                }
                                                                {
                                                                    cell?.row?.original?.enrolment?.result_attachment ? (
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip>Delete</Tooltip>}
                                                                        >
                                                                            <span>
                                                                                <Trash
                                                                                    style={{ cursor: "pointer" }}
                                                                                    className="me-1 text-danger"
                                                                                    size="20"
                                                                                    onClick={() => openDeleteModal(cell?.row?.original?.enrolment?.id)}
                                                                                />
                                                                            </span>
                                                                        </OverlayTrigger>
                                                            
                                                                    ) : ""
                                                                }
                                                                </>
                                                            )}

                                                            {loading && (
                                                                <div className="spinner-border" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                            )}
                                                            </td>
                                                        );
                                                    }
                                                    return (
                                                        <td
                                                            className={`td
                                                                ${
                                                                    cell.column
                                                                        .id ===
                                                                    "actions"
                                                                        ? "table-action"
                                                                        : ""
                                                                }`}
                                                            {...cell.getCellProps()}
                                                        >
                                                            {cell.render("Cell")}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </Table>
                </DoubleScrollbar>
                </div>
            </Styles>

            {isPaginated && (
                <Row className="mt-2">
                    <Col md="6">
                        <span className="mx-2">
                            Page{" "}
                            <strong>
                                {apis && `${currentPage} of ${totalPage}`}
                                {!apis &&
                                    `${pageIndex + 1} of  ${
                                        pageOptions.length
                                    }`}
                            </strong>
                        </span>
                    </Col>
                    <Col md="6">
                        <Pagination className="float-end">
                            {!apis && (
                                <>
                                    {" "}
                                    <Pagination.First
                                        onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={() => previousPage()}
                                        disabled={!canPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() => nextPage()}
                                        disabled={!canNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}
                                    />
                                </>
                            )}

                            {apis && (
                                <>
                                    <Pagination.First
                                        onClick={() => gotoPageApi(firstPage)}
                                        disabled={!apiPreviousPage}
                                    />
                                    <Pagination.Prev
                                        onClick={(i) =>
                                            gotoPageApi(currentPage - 1)
                                        }
                                        disabled={!apiPreviousPage}
                                    />
                                    <Pagination.Next
                                        onClick={() =>
                                            gotoPageApi(currentPage + 1)
                                        }
                                        disabled={!apiNextPage}
                                    />
                                    <Pagination.Last
                                        onClick={() => gotoPageApi(lastPage)}
                                        disabled={!apiNextPage}
                                    />
                                </>
                            )}
                        </Pagination>
                    </Col>
                </Row>
            )}
        <SnackbarAlert notif={notif} setNotif={setNotif} />
        <DeleteModal
                modalInfo={modalInfo}
                setModalInfo={setModalInfo}
                api={deleteAttachment}
            />
        </>
    );
};
export default EmailListTable;
