import React, { useEffect, useContext, useState, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";
import { tableColumns } from "./tableDataRevenue.js";
import { ActionRouteContext } from "../Documents/Documents";
import { AddEditRevenue } from "./AddEditRevenue.js";
import { ViewRevenue } from "./ViewRevenue.js";
import api from "../../../service/api";
import { RevenueListing } from "./RevenueListing.js";
import { LoadingContext } from "../../../App.js";
import { useParams } from "react-router-dom";

export const Revenue = ({ project }) => {
    let { id } = useParams();

    //
    // States
    //

    const [revenue, setRevenue] = useState([]);
    const action = useContext(ActionRouteContext);
    const setIsLoadingActive = useContext(LoadingContext);

    //
    // Functions
    //

    const getRevenues = useCallback(
        async (param) => {
            const response = await api.get("revenues", {
                project_id: id,
                ...param,
            });

            if (response) {
                setRevenue(response);
                setIsLoadingActive(false);
            }
        },
        [setIsLoadingActive, id]
    );

    //
    // useEffects
    //

    useEffect(() => {
        setIsLoadingActive(true);
        getRevenues();
    }, [getRevenues, setIsLoadingActive]);

    return (
        <React.Fragment>
            {project && (
                <Helmet title={`${project.name} > Revenue`} />
            )}
            <Container fluid className="p-0">
                {
                    // Expense View List
                    action === "list" && revenue && revenue.data ? (
                        <RevenueListing
                            module="revenues"
                            columns={tableColumns}
                            rawData={revenue}
                            parentApi={getRevenues}
                        />
                    ) : // Create Edit Expense
                    action === "addEdit" ? (
                        <AddEditRevenue project={project} />
                    ) : (
                        <ViewRevenue project={project} />
                    )
                }
            </Container>
        </React.Fragment>
    );
};
