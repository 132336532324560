const MOCK_USERS = [
    "Carmen Weissnat",
    "Darby Huel",
    "Marquise Harber",
    "Ewell Ferry",
    "Davonte Reichel",
    "Jeffery Morar",
    "Icie Kirlin",
];

export { MOCK_USERS };
