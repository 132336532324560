import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Roles } from "../../IntelliSuite/Roles/Roles";

export const TmsRoles = () => {
    const [application, setApplication] = useState({});
    const { data } = useSelector((state) => {
        return state.user ? state.user : state;
    });

    useEffect(() => {
        if (data && data.apps) {
            if (data.apps.length > 0) {
                const app = data.apps.find(
                    (app) => app.name.toUpperCase() === "INTELLITMS"
                );
                setApplication(app);
            }
        }
    }, [data]);

    return (
        <>
            {application && Object.entries(application).length !== 0 ? (
                <Roles app={application} />
            ) : (
                <Alert className="my-3" variant="warning">
                    <div className="alert-message">
                        You don't have permission to access this page
                    </div>
                </Alert>
            )}
        </>
    );
};
