import React from "react";
import Modal from "react-overlays/Modal";
import {
    Alert,
    Card,
    Col,
    Form,
    Row,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import { Formik } from "formik";
import Select from "react-select";

import { AutoGrowTextarea } from "../../../../../components/AutoGrowTextarea";

const EditCompanyModal = ({
    showModalEditCompany, handleCloseEditCompany, 
    renderBackdrop, loading,
    invoice, salesPerson, 
    setEditCompany, EditCompany, 
    handleCheckBoxCompAdd, handleFormInputChangeCompanyAddr,
    handleSuccessEditCompany
}) => {
    return(
        <>
            <Modal
                className="modal modal_additional"
                show={showModalEditCompany}
                onHide={handleCloseEditCompany}
                renderBackdrop={renderBackdrop}
            >
                <div>
                    <div className="modal-header">
                        <div className="modal-title">Edit Invoice</div>
                        <div>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id={`tooltip-top`}>Close</Tooltip>}
                            >
                            <span
                                className="close-button"
                                onClick={handleCloseEditCompany}
                            >
                                x
                            </span>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="modal-desc">
                        <Card>
                            <Card.Body>
                                {loading && <div>Loading...</div>}
                                {!loading && invoice && (
                                    <Formik>
                                        {({
                                            errors,
                                            handleSubmit,
                                            values,
                                            setFieldValue,
                                        }) => (
                                            <Form
                                                noValidate
                                                onSubmit={handleSubmit}
                                            >
                                                {errors.submit && (
                                                    <Alert
                                                        className="my-3"
                                                        variant="danger"
                                                    >
                                                        <div className="alert-message">
                                                            {errors.submit}
                                                        </div>
                                                    </Alert>
                                                )}

                                                <Row>
                                                    <Col md={4}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>
                                                                Salesperson
                                                            </Form.Label>
                                                            <Select
                                                                className="react-select-container"
                                                                classNamePrefix="react-select"
                                                                options={
                                                                    salesPerson
                                                                }
                                                                name="salesperson"
                                                                onChange={(
                                                                    value
                                                                ) => {
                                                                    // setFieldValue(
                                                                    //     "salesperson",
                                                                    //     value.value
                                                                    // );
                                                                    setEditCompany(
                                                                        {
                                                                            ...EditCompany,
                                                                            sales_user_id:
                                                                                value.value,
                                                                        }
                                                                    );
                                                                }}

                                                                // value={
                                                                //     invoice?.sales_user_id &&
                                                                //     invoice?.sales_user?.filter(
                                                                //         (option) => {
                                                                //             return (
                                                                //                 option.id ===
                                                                //                 EditCompany?.sales_user_id
                                                                //             );
                                                                //         }
                                                                //     )
                                                                // }
                                                            />
                                                            {/* {console.log(invoice?.sales_user)} */}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={8}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>
                                                                Address
                                                            </Form.Label>
                                                            <Form.Check
                                                                type="checkbox"
                                                                label="Sync to company address"
                                                                name="sync_addr"
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    handleCheckBoxCompAdd(
                                                                        event
                                                                    );
                                                                }}
                                                            />
                                                            <AutoGrowTextarea
                                                                name="company_addr"
                                                                className="mt-10"
                                                                handleFormChangeDetails={(
                                                                    event
                                                                ) => {
                                                                    handleFormInputChangeCompanyAddr(
                                                                        event
                                                                    );
                                                                }}
                                                                fieldValue={
                                                                    EditCompany?.company_addr
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                )}
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="modal-footer">
                        <button
                            className="secondary-button"
                            onClick={handleCloseEditCompany}
                        >
                            Close
                        </button>
                        <button
                            className="primary-button"
                            onClick={handleSuccessEditCompany}
                        >
                            Update
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default EditCompanyModal;