import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ArrowLeft, Eye } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../../service/api";
import InvoiceInfo from "./InvoiceInfo";
import PaymentForm from "./PaymentForm";
import PaymentHistory from "./PaymentHistory";

const Payment = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [invoice, setInvoice] = useState();
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(false);

    // const [notif, setNotif] = useState({
    //     notifMsg: "",
    //     open: false,
    //     severity: "success",
    // });

    /** get the invoices */
    const getInvoice = useCallback(async (id) => {
        try {
            setLoading(true);
            const response = await api.get(`tms/invoices/${id}`);
            setInvoice(response.data);
            setLoading(false);
        } catch (error) {}
    }, []);

    /** get the invoice payment */
    const getPayments = useCallback(async (id) => {
        try {
            setLoading(true);
            const response = await api.get(`tms/invoices/${id}/payments?all=1`);
            setPayments(response.data);
            setLoading(false);
        } catch (error) {}
    }, []);

    /**  use efffect to call the get quotation */
    useEffect(() => {
        getInvoice(id);
        getPayments(id);
    }, [id, getInvoice, getPayments]);

    return (
        <>
            <Helmet title="Invoice payment" />
            <Container fluid className="p-0">
                <Card>
                    {loading && <div>Loading...</div>}
                    {!loading && (
                        <>
                            <Card.Header>
                                <Row>
                                    <Col md={6}>
                                        <h1 className="h3 mb-3">
                                            Invoice payment{" "}
                                        </h1>
                                    </Col>
                                    <Col md={6} className="text-end">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip-top`}>Return to list</Tooltip>}
                                        >
                                            <Button
                                                className="ms-2"
                                                onClick={() =>
                                                    navigate(`/tms/invoices/`)
                                                }
                                            >
                                                <ArrowLeft className="feather" />
                                            </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip-top`}>View Invoice</Tooltip>}
                                        >
                                            <Button
                                                variant="info"
                                                className="ms-2"
                                                onClick={() =>
                                                    navigate(
                                                        `/tms/invoices/view/${invoice?.id}`
                                                    )
                                                }
                                            >
                                                <Eye className="feather" />
                                            </Button>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body className="pt-0">
                                <Row>
                                    <Col xl="7">
                                        <InvoiceInfo invoice={invoice} />
                                        <hr />
                                        <PaymentHistory
                                            payments={payments}
                                            getInvoice={getInvoice}
                                            getPayments={getPayments}
                                            invoiceId={id}
                                        />
                                    </Col>
                                    <Col xl="5">
                                        <PaymentForm
                                            getPayments={getPayments}
                                            getInvoice={getInvoice}
                                            invoice={invoice}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </>
                    )}
                </Card>
            </Container>
        </>
    );
};
export default Payment;
