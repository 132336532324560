import * as React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();

  if (isInitialized && !isAuthenticated) {
    return (
      <Navigate 
          to="/auth/sign-in" 
          state={{
                page_redirect: window.location.href,
            }}
        />
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
